import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";

export type ShakaState = {
    source: string;
}

/**
 * Create and configure an html video component bound to Shaka for hls streaming.
 */
export class ShakaComponent extends MyOptyxComponent {

    protected override state: ShakaState = {
        source: ''
    };

    // Pending state initialized to match current state.
    override pendingState: ShakaState = {
        source: ''
    };

    // Events
    private readonly _videoCreated = new Subject<HTMLVideoElement | null>();
    readonly videoCreated$ = this._videoCreated.asObservable();
    private readonly _videoAspect = new Subject<number>();
    readonly onVideoAspect$ = this._videoAspect.asObservable();

    private _logger = getLogger();
    private _video!: HTMLVideoElement;
    // https://stackoverflow.com/questions/42285032/how-do-i-import-shaka-player-into-an-angular-2-application
    //private shaka = require('../../../../node_modules/shaka-player/dist/shaka-player.compiled');
    private shaka = require('../../../../node_modules/shaka-player/dist/shaka-player.compiled.debug');
    private _shaka: any;
    private _isAttached = false;


    constructor(destroyRef: DestroyRef) {
        super(destroyRef);

        this.shaka.log.setLevel(this.shaka.log.Level.WARNING);
        if (window.HTMLMediaElement) {
            // eslint-disable-next-line no-restricted-syntax
            const originalPlay = HTMLMediaElement.prototype.play;
            // eslint-disable-next-line no-restricted-syntax
            HTMLMediaElement.prototype.play = function () {
                // eslint-disable-next-line no-restricted-syntax
                const p = originalPlay.apply(this);
                if (p) {
                    // This browser is returning a Promise from play().
                    // If the play() call fails or is interrupted, the Promise will be
                    // rejected.  Some apps, however, don't listen to this Promise,
                    // especially since it is not available cross-browser.  If the Promise
                    // is rejected without anyone listening for the failure, an error will
                    // appear in the JS console.
                    // To avoid confusion over this innocuous "error", we will install a
                    // catch handler on the Promise.  This does not prevent the app from
                    // also catching failures and handling them.  It only prevents the
                    // console message.
                    p.catch(() => { });
                }
                return p;
            };
        }
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {

        if (this.pendingState.source !== this.state.source) {

            this.setupStream(this.pendingState);
        }
    }


    override getState(): ShakaState {

        return this.state;
    }


    override async onDestroy(): Promise<void> {

        await this.releaseResources();
        if (this._shaka) {

            await this._shaka.destroy();
            this._shaka = null;
        }
    }


    private readonly errorEventCallback = this.onError.bind(this);
    private onErrorEvent(event: any) {

        // Extract the shaka.util.Error object from the event.
        this.onError(event.detail);
    }


    private onError(error: any) {

        if (error.code && 7000 === error.code) {

            console.log('Video load interrupted');
        } else {

            console.error(error.message);
        }
    }


    private async releaseResources(): Promise<void> {

        if (this._shaka) {

            if (this._video && this._isAttached) {

                await this._shaka.unload(this._video);
                await this._shaka.detach(this._video);
                this._isAttached = false;
            }
            this._shaka.removeEventListener(this.errorEventCallback);
        }

        if (this._video) {

            this._video.onloadedmetadata = null;
            this._video.src = '';
            this._video.load();
        }
    }


    async preload(source: string): Promise<void> {

        if (9 > source.length) {
            
            return;
        }

        const video = document.createElement('video');
        video.crossOrigin = 'anonymous';
        const player = new this.shaka.Player();
        await player.attach(video);
        await player.preload(source);
        await player.unload(video);
        await player.detach(video);
        video.src = '';
        video.load();
    }


    private async setupStream(state: ShakaState): Promise<void> {

        await this.releaseResources();

        if (!state.source || 1 > state.source.length) {

            this._videoCreated.next(null);
            return;
        }

        //this._shaka.polyfill.VideoPlayPromise.install();
        await this._shaka.attach(this._video);
        this._isAttached = true;

        // Attach player to the window to make it easy to access in the JS console.
        (window as any).player = this._shaka;

        // Listen for error events.
        this._shaka.addEventListener('error', this.errorEventCallback);

        // Track video dimensions to set aspect downstream.
        const that = this;
        this._video.onloadedmetadata = function (e) {

            if (that._video && state.source && 0 < state.source.length) {

                const aspect = that._video.videoWidth / that._video.videoHeight;
                that._logger.trace(`Video aspect: ${aspect}`);

                that._videoAspect.next(aspect);
            }
        };

        // Try to load a manifest.
        // This is an asynchronous process.
        try {

            //this._logger.warn(`--> shakaPlayer.load(${this.inputs.src})`);
            await this._shaka.load(state.source);
            // This runs if the asynchronous load is successful.
            this._videoCreated.next(this._video);
        } catch (e) {

            // onError is executed if the asynchronous load fails.
            this.onError(e);
        }

    }


    // If overriding be sure to call base method.
    override init(): ShakaComponent {
        super.init();

        this._video = document.createElement('video');
        this._video.crossOrigin = 'anonymous';
        this._video.playsInline = true;
        this._shaka = new this.shaka.Player();

        return this;
    }

}