import { AnimationMixer, Object3D, Vector3 } from "three";
import { Vector3Obj } from "../../util/utils";
import { ActiconPosition } from "../..";
import { IWorld, addComponent, addEntity, removeEntity } from "bitecs";
import { PropType } from "../../model";
import {
    ActiconBaseComponent, ActiconComponent, ActiconType, AdjustableComponent, ChildObjectEntityIdMap, DestinationPositionComponent, DestinationRotationComponent,
    DestinationScaleComponent, DisplayPlaneComponent, FromPositionComponent, FromRotationComponent, FromScaleComponent, IActiconState,
    IAdjustablePropEntity,
    PositionAdjustmentComponent, PositionComponent, RotationAdjustmentComponent, RotationComponent, ScaleAdjustmentComponent, ScaleComponent, acticonChildrenEntityMap, object3dByEntityId
} from "..";
import {
    getEidForActiconByParentAndType, removeActiconChildEntityIdFromProp, updateActiconComponentValues, upsertActiconEntity
} from "./acticon.entity";
import { AdjustmentInitState } from "../system/adjustment-init.state";
import { getEidForAdjustableProp, removeAdjustmentsForProp } from "./adjustable.entity";
import { WorldState } from "../ecs-world.state";
import { animationMixerByEntityId } from "./object-prop.entity";
import { TransitionAnimationComponent, transitionAnimationQuery } from "../component/transition-animation.component";
import { TransitionPlaneComponent } from "../component/transition-plane.component";


export interface ImagePropEntity extends IAdjustablePropEntity {

    acticonAlignment: ActiconPosition;
    acticonMargin: number;
    acticonZ: number;
    /**
     * The base aspect of the Prop and its backing plane
     */
    baseAspect: number;
    /**
     * The aspect of the current image
     */
    displayAspect: number;
    /**
     * The primary image display plane
     */
    displayPlane: Object3D;
    position: Vector3Obj;   // base
    rotation: Vector3Obj;   // base
    scale: Vector3Obj;      // base
    // maskLoader: MaskLoaderComponent;
    // infoActicon: Object3D;
    // infoActiconAnimationDuration: number;
    // infoActiconPerpendicularMargin: number;
    // infoActiconScale: Vector3Obj;
    // infoActiconSize: Vector3;
    nextActicon: Object3D;
    nextActiconAnimationDuration: number;
    nextActiconAnimationMixer?: AnimationMixer;
    nextActiconPerpendicularMargin: number;
    nextActiconScale: Vector3Obj;
    nextActiconSize: Vector3;
    planeGroup: Object3D;
    /**
     * The aspect of the transition image
     */
    transitionAspect: number;
    /**
     * The primary display aspect, either image or video
     */
    transitionPlane: Object3D;
}


export function removeImageProp(world: IWorld, imagePropId: number): void {

    const eidForAdjustable = getEidForAdjustableProp(world, imagePropId, PropType.IMAGE);
    if (0 > eidForAdjustable) {

        WorldState.logger.error(`Image Prop id: ${imagePropId} not found`);
        return;
    }

    removeAdjustmentsForProp(world, imagePropId, PropType.IMAGE);

    // Find the Acticon entity id
    for (let eidForActicon of acticonChildrenEntityMap[eidForAdjustable]) {

        removeActiconChildEntityIdFromProp(world, eidForAdjustable, eidForActicon);
    }

    // Remove reference
    delete object3dByEntityId[eidForAdjustable];
    // delete maskLoaderByEntityId[eidForAdjustable];
    delete object3dByEntityId[DisplayPlaneComponent.eid[eidForAdjustable]];
    delete object3dByEntityId[TransitionPlaneComponent.eid[eidForAdjustable]];

    removeEntity(world, eidForAdjustable);
}


export function transitionImageProp(world: IWorld, imagePropId: number): void {
    
    const eidForAdjustable = getEidForAdjustableProp(world, imagePropId, PropType.IMAGE);
    if (0 > eidForAdjustable) {

        WorldState.logger.error(`Image Prop id: ${imagePropId} not found`);
        return;
    }

    const isTransitioning = transitionAnimationQuery(world).find(eid => eid === eidForAdjustable);
    if (isTransitioning) {

        return;
    }
    
    addComponent(world, TransitionAnimationComponent, eidForAdjustable);
    TransitionAnimationComponent.duration[eidForAdjustable] = 1;
    TransitionAnimationComponent.elapsed[eidForAdjustable] = 0;
}


/**
 * Update component values for Image Prop entity.
 * @param imageEntity 
 */
function updateImagePropComponentValues(world: IWorld, imageEntity: ImagePropEntity, eidForAdjustableProp: number) {

    // Update object references
    object3dByEntityId[eidForAdjustableProp] = imageEntity.planeGroup;
    // maskLoaderByEntityId[eidForAdjustableProp] = imageEntity.maskLoader;
    const eidForNextActicon = upsertActiconEntity(world, eidForAdjustableProp, imageEntity.nextActicon, ActiconType.Next);    
    if (imageEntity.nextActiconAnimationMixer) {
        
        animationMixerByEntityId[eidForNextActicon] = imageEntity.nextActiconAnimationMixer;
    }
    object3dByEntityId[DisplayPlaneComponent.eid[eidForAdjustableProp]] = imageEntity.displayPlane;
    object3dByEntityId[TransitionPlaneComponent.eid[eidForAdjustableProp]] = imageEntity.transitionPlane;

    // Set component values
    AdjustableComponent.aspect[eidForAdjustableProp] = imageEntity.baseAspect;
    PositionComponent.x[eidForAdjustableProp] = imageEntity.position.x;
    PositionComponent.y[eidForAdjustableProp] = imageEntity.position.y;
    PositionComponent.z[eidForAdjustableProp] = imageEntity.position.z;
    RotationComponent.x[eidForAdjustableProp] = imageEntity.rotation.x;
    RotationComponent.y[eidForAdjustableProp] = imageEntity.rotation.y;
    RotationComponent.z[eidForAdjustableProp] = imageEntity.rotation.z;
    ScaleComponent.x[eidForAdjustableProp] = imageEntity.scale.x;
    ScaleComponent.y[eidForAdjustableProp] = imageEntity.scale.y;
    ScaleComponent.z[eidForAdjustableProp] = imageEntity.scale.z;
    ActiconBaseComponent.acticonAlignment[eidForAdjustableProp] = imageEntity.acticonAlignment;
    ActiconBaseComponent.acticonMargin[eidForAdjustableProp] = imageEntity.acticonMargin;
    ActiconBaseComponent.acticonZ[eidForAdjustableProp] = imageEntity.acticonZ;

    // Set Acticon component values
    let eidForActicon = getEidForActiconByParentAndType(eidForAdjustableProp, ActiconType.Next);
    if (eidForActicon) {
        let resetAnimationTimer = imageEntity.nextActiconScale.x !== ActiconComponent.scale.x[eidForActicon]
            || imageEntity.nextActiconScale.y !== ActiconComponent.scale.y[eidForActicon]
            || imageEntity.nextActiconScale.z !== ActiconComponent.scale.z[eidForActicon];
        let acticonState: IActiconState = {
            animationDuration: imageEntity.nextActiconAnimationDuration,
            animationTimer: resetAnimationTimer ? 0 : ActiconComponent.animationTimer[eidForActicon],
            perpendicularMargin: imageEntity.nextActiconPerpendicularMargin,
            scaleFrom: {
                x: resetAnimationTimer ? imageEntity.nextActicon.scale.x : ActiconComponent.scaleFrom.x[eidForActicon],
                y: resetAnimationTimer ? imageEntity.nextActicon.scale.y : ActiconComponent.scaleFrom.y[eidForActicon],
                z: resetAnimationTimer ? imageEntity.nextActicon.scale.z : ActiconComponent.scaleFrom.z[eidForActicon],
            },
            scaleTo: {
                x: imageEntity.nextActiconScale.x,
                y: imageEntity.nextActiconScale.y,
                z: imageEntity.nextActiconScale.z
            },
            size: {
                h: imageEntity.nextActiconSize.y / 2,
                w: imageEntity.nextActiconSize.x / 2
            },
            sort: 0
        }

        //if (0 < imageEntity.displayAspect) {    // If content aspect is defined

        //     if (imageEntity.displayAspect < imageEntity.baseAspect) {

        //         DisplayPlaneComponent.scaleFactor[eidForAdjustableProp] = imageEntity.displayAspect / imageEntity.baseAspect;
        //     } else {

        //         DisplayPlaneComponent.scaleFactor[eidForAdjustableProp] = 1; // Does nothing when multiplies with scale.
        //         contentHeight = (1 / imageEntity.displayAspect) * imageEntity.scale.y;
        //     }
        // }

        DisplayPlaneComponent.aspect[eidForAdjustableProp] = imageEntity.displayAspect;
        DisplayPlaneComponent.scaleFactor[eidForAdjustableProp] = 1; // Default  
        if (0 < imageEntity.displayAspect) {    // If content aspect is defined

            if (imageEntity.displayAspect < imageEntity.baseAspect) {

                DisplayPlaneComponent.scaleFactor[eidForAdjustableProp] = imageEntity.displayAspect / imageEntity.baseAspect;
            } 
        } 

        TransitionPlaneComponent.aspect[eidForAdjustableProp] = imageEntity.transitionAspect;
        TransitionPlaneComponent.scaleFactor[eidForAdjustableProp] = 1; // Default  
        if (0 < imageEntity.transitionAspect) {    // If content aspect is defined

            if (imageEntity.transitionAspect < imageEntity.baseAspect) {

                TransitionPlaneComponent.scaleFactor[eidForAdjustableProp] = imageEntity.transitionAspect / imageEntity.baseAspect;
            } 
        }

        updateActiconComponentValues(eidForAdjustableProp, eidForActicon, acticonState);
    }

    // Invalidate position id to force analysis of adjustments.
    AdjustmentInitState.currentShowroomPositionId = -1;

    // eidForActicon = infoActiconEidByParentEid[eidForAdjustable];
    // resetAnimationTimer = imageEntity.infoActiconScale.x !== ActiconComponent.scaleX[eidForActicon]
    //     || imageEntity.infoActiconScale.y !== ActiconComponent.scaleY[eidForActicon]
    //     || imageEntity.infoActiconScale.z !== ActiconComponent.scaleZ[eidForActicon];
    // acticonState = {
    //     animationDuration: imageEntity.infoActiconAnimationDuration,
    //     animationTimer: resetAnimationTimer ? 0 : ActiconComponent.animationTimer[eidForActicon],
    //     height: imageEntity.infoActiconSize.y / 2,
    //     perpendicularMargin: imageEntity.infoActiconPerpendicularMargin,
    //     scaleFromX: resetAnimationTimer ? imageEntity.infoActicon.scale.x : ActiconComponent.scaleFromX[eidForActicon],
    //     scaleFromY: resetAnimationTimer ? imageEntity.infoActicon.scale.y : ActiconComponent.scaleFromY[eidForActicon],
    //     scaleFromZ: resetAnimationTimer ? imageEntity.infoActicon.scale.z : ActiconComponent.scaleFromZ[eidForActicon],
    //     scaleToX: imageEntity.infoActiconScale.x,
    //     scaleToY: imageEntity.infoActiconScale.y,
    //     scaleToZ: imageEntity.infoActiconScale.z,
    //     width: imageEntity.infoActiconSize.x / 2
    //}
    //updateActiconComponentValues(eidForAdjustable, eidForActicon, acticonState);   

}


/**
 * Update component values for Image Prop entity.
 * If entity is not yet registered then register entity in ECS system.
 * @param imageProp 
 */
export function upsertImageProp(world: IWorld, imageProp: ImagePropEntity) {

    let eidForAdjustableProp = getEidForAdjustableProp(world, imageProp.id, PropType.IMAGE);

    if (0 > eidForAdjustableProp) {

        // Get an ECS entity id for the adjustable image object.
        do eidForAdjustableProp = addEntity(world) 
        while (!eidForAdjustableProp || 0 > eidForAdjustableProp)

        // Flag as an adjustable entity for queries.
        addComponent(world, AdjustableComponent, eidForAdjustableProp);

        // Base adjustable component values.
        AdjustableComponent.id[eidForAdjustableProp] = imageProp.id;
        AdjustableComponent.type[eidForAdjustableProp] = PropType.IMAGE;

        addComponent(world, DisplayPlaneComponent, eidForAdjustableProp);

        let eidForDisplayPlane: number;
        do eidForDisplayPlane = addEntity(world)
        while (!eidForDisplayPlane || 0 > eidForDisplayPlane);

        DisplayPlaneComponent.eid[eidForAdjustableProp] = eidForDisplayPlane;
        object3dByEntityId[eidForDisplayPlane] = imageProp.displayPlane;

        addComponent(world, TransitionPlaneComponent, eidForAdjustableProp);

        let eidForTransitionPlane: number;
        do eidForTransitionPlane = addEntity(world)
        while (!eidForTransitionPlane || 0 > eidForTransitionPlane);

        TransitionPlaneComponent.eid[eidForAdjustableProp] = eidForTransitionPlane;
        object3dByEntityId[eidForTransitionPlane] = imageProp.transitionPlane;

        addComponent(world, PositionComponent, eidForAdjustableProp);
        addComponent(world, PositionAdjustmentComponent, eidForAdjustableProp);
        addComponent(world, FromPositionComponent, eidForAdjustableProp);
        addComponent(world, DestinationPositionComponent, eidForAdjustableProp);

        addComponent(world, RotationComponent, eidForAdjustableProp);
        addComponent(world, RotationAdjustmentComponent, eidForAdjustableProp);
        addComponent(world, FromRotationComponent, eidForAdjustableProp);
        addComponent(world, DestinationRotationComponent, eidForAdjustableProp);

        addComponent(world, ScaleComponent, eidForAdjustableProp);
        addComponent(world, ScaleAdjustmentComponent, eidForAdjustableProp);
        addComponent(world, FromScaleComponent, eidForAdjustableProp);
        addComponent(world, DestinationScaleComponent, eidForAdjustableProp);

        // addComponent(world, FromMaskComponent, eidForAdjustableProp);
        // addComponent(world, DestinationMaskComponent, eidForAdjustableProp);
        // addComponent(world, HorizontalMaskComponent, eidForAdjustableProp);
        // addComponent(world, FromHorizontalMaskComponent, eidForAdjustableProp);
        // addComponent(world, DestinationHorizontalMaskComponent, eidForAdjustableProp);

        // addComponent(world, VerticalMaskComponent, eidForAdjustableProp);
        // addComponent(world, FromVerticalMaskComponent, eidForAdjustableProp);
        // addComponent(world, DestinationVerticalMaskComponent, eidForAdjustableProp);

        addComponent(world, ActiconBaseComponent, eidForAdjustableProp);

        // Record relationship between next Acticon and image entity
        // eidForActicon = createActiconEntity(world, imageProp.infoActicon);
        // infoActiconEidByParentEid[eidForAdjustable] = eidForActicon;
    }

    updateImagePropComponentValues(world, imageProp, eidForAdjustableProp);
}
