import { deepCopy } from "projects/mp-core/src/lib/util";

export enum HorizontalClipMode { NONE, LEFT, RIGHT, CENTER, EDGES }
export enum VerticalClipMode { NONE, TOP, BOTTOM, CENTER, EDGES }
export const OffsetBasedHorizontalClipModes = new Set([HorizontalClipMode.CENTER, HorizontalClipMode.RIGHT, HorizontalClipMode.EDGES]);
export const OffsetBasedVerticalClipModes = new Set([VerticalClipMode.CENTER, VerticalClipMode.TOP, VerticalClipMode.EDGES]);

export class HorizontalMask {
  private _mode: HorizontalClipMode = HorizontalClipMode.NONE;
  /**
   * % from left value, 0 - 1
   */
  offset: number = 0;
  /**
   * % of width value, 0 - 1
   */
  width: number = 1;

  public get mode(): HorizontalClipMode {
    return this._mode;
  }
  /**
   * Setting mode resets the values for offest and width.
   * Make sure it is set first if manually setting any combination of the 3.
   */
  public set mode(v: HorizontalClipMode) {
    this._mode = v;
    if (v === HorizontalClipMode.RIGHT) { // Clips to the right of offset
      this.offset = 1;
      this.width = 1;
    } else if (v === HorizontalClipMode.EDGES) {  // Clips to the left and right of width
      this.offset = 0;
      this.width = 1;
    } else if (v === HorizontalClipMode.LEFT || v === HorizontalClipMode.CENTER) {  // Clips within width
      this.offset = 0;
      this.width = 0;
    }
  }


  copy(mask: HorizontalMask): void {
    Object.keys(mask).forEach((key) => {
      (this as any)[key as keyof HorizontalMask] = deepCopy(mask[key as keyof HorizontalMask]);
    });
  }


  equals(horizontalMask: HorizontalMask): boolean {
    return horizontalMask
      && this._mode === horizontalMask.mode
      && this.offset === horizontalMask.offset
      && this.width === horizontalMask.width;
  }

}


export class VerticalMask {
  private _mode: VerticalClipMode = VerticalClipMode.NONE;
  /**
   * % from top value, 0 - 1
   */
  offset: number = 0;
  /**
   * % of height value, 0 - 1
   */
  height: number = 1;

  public get mode(): VerticalClipMode {
    return this._mode;
  }
  /**
   * Setting mode resets the values for offest and height.
   * Make sure it is set first if manually setting any combination of the 3.
   */
  public set mode(v: VerticalClipMode) {
    this._mode = v;
    if (v === VerticalClipMode.TOP) { // Clips below the offset
      this.offset = 0;
      this.height = 1;
    } else if (v === VerticalClipMode.EDGES) {  // Clips above and below height
      this.offset = 0;
      this.height = 1;
    } else if (v === VerticalClipMode.BOTTOM || v === VerticalClipMode.CENTER) {  // Clips within height
      this.offset = 0;
      this.height = 0;
    }
  }


  copy(mask: VerticalMask): void {
    Object.keys(mask).forEach((key) => {
      (this as any)[key as keyof VerticalMask] = deepCopy(mask[key as keyof VerticalMask]);
    });
  }


  equals(verticalMask: VerticalMask): boolean {
    return verticalMask
      && this.height === verticalMask.height
      && this._mode === verticalMask._mode
      && this.offset === verticalMask.offset;
  }

}
