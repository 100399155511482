<div class="flex flex-col space-y-2 w-full">
    <select (change)="onChange($event)"
        class="block w-full text-black rounded-md focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500">

        @for (venueEvent of sortedVenueEvents(); track $index) {

            <option [selected]="venueEvent.id === selectedVenueEventId()">
                {{ venueEvent.name }}
            </option>

        }
        
    </select>
    @if (DisplayHelp()) {
    
        <p>Events represent an engagement. The Event Sponsor evaluates the Showroom options to decide upon the final
            package.</p>

    }
</div>