import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectAssignment } from 'projects/my-common/src/model';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapCaretLeft } from '@ng-icons/bootstrap-icons';
import { NGXLogger } from 'ngx-logger';
import { ButtonDropownItem, ButtonDropdownComponent } from 'src/app/components/shared/button-dropdown/button-dropdown.component';

enum PropAction { GOTO = "Go to prop", SELECT = "Select prop" }


@Component({
  selector: 'app-object-prop-action-card',
  standalone: true,
  templateUrl: './object-prop-action-card.component.html',
  styleUrls: ['./object-prop-action-card.component.scss'],
  providers: [provideIcons({ bootstrapCaretLeft })],
  imports: [CommonModule, NgIconComponent, ButtonDropdownComponent]
})
export class ObjectPropActionCardComponent {
  
  readonly ObjectAssignment = input.required<ObjectAssignment>();

  readonly SelectObject = output<ObjectAssignment>();
  readonly GoToObject = output<ObjectAssignment>();

  propActions: ButtonDropownItem[] = [
    [0, 0, PropAction.GOTO],
    [0, 1, PropAction.SELECT],
  ];


  constructor(private readonly logger: NGXLogger) { }


  onPropAction(action: ButtonDropownItem) {

    switch (action[2]) {
      
      case PropAction.GOTO:
        this.GoToObject.emit(this.ObjectAssignment());
        break;
      case PropAction.SELECT:
        this.SelectObject.emit(this.ObjectAssignment());
        break;
    }
  }

}
