<div
    class="flex rounded-lg group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
    <div class="min-h-44 flex grow flex-col items-center justify-center overflow-hidden">
        <img class="w-full max-h-72 object-scale-down transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
            src="{{DesignImage().imageUrl}}"
            alt="" />
    </div>
    <div class="absolute inset-0 p-4">
        <div class="italic w-full overflow-hidden whitespace-nowrap overflow-ellipsis group-hover:hidden">
            {{DesignImage().uploadFileName}}
        </div>
    </div>
    <div class="absolute inset-0 flex translate-y-full overflow-hidden flex-col items-center p-4 transition-all duration-500 group-hover:translate-y-0 bg-gradient-to-b from-zinc-700 via-transparent to-zinc-900 group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70">
        <div class="italic w-full overflow-hidden whitespace-nowrap overflow-ellipsis">{{DesignImage().uploadFileName}}</div>
        <div class="italic w-full overflow-hidden whitespace-nowrap overflow-ellipsis">{{DesignImage().venueName}}</div>
        <div class="italic w-full overflow-hidden whitespace-nowrap overflow-ellipsis">{{DesignImage().venueEventName}}</div>
        <div class="italic w-full pb-4 overflow-hidden whitespace-nowrap overflow-ellipsis">{{DesignImage().eventDesignName}}</div>
        <button class="btn btn-accent w-[190px]" (click)="onImport($event)">
            Import
        </button>
    </div>
</div>
