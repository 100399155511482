import {defineComponent, Types} from 'bitecs';

/**
 * Base rotation without any adjustments.
 */
export const RotationComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})


/**
 * Adjustment to the base rotation. Changes with move from position to position.
 */
export const RotationAdjustmentComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})


/**
 * Track the original rotation adjustment as it transitions to the destination adjustment.
 */
export const FromRotationComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})


/**
 * The target destination rotation adjustment.
 */
export const DestinationRotationComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})