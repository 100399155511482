<div class="flex flex-col justify-center items-center w-full min-w-screen min-h-screen">
    <!-- <iframe src="http://localhost:4200/embed/41-324a8a02-c7b3-4532-826b-7786a2877bf4" width="800" height="600" 
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; geolocation; fullscreen; autoplay; xr-spatial-tracking"
        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen frameBorder="0"></iframe> -->
    <div class="flex flex-col space-y-4 mx-4 md:mx-0 max-w-3xl">
        <div>
            <div class="flex flex-row space-x-4 items-center pb-2">
                <h1 class="text-3xl font-bold">Join the revolution</h1>
            </div>
            <p class="text-lg text-gray-400">We're moving fast and would love to work with you on the next big thing
                as part of our mission to transform the web experience of tomorrow!</p>
        </div>
        <div class="font-semibold text-lg text-accent">sales&#64;myoptyx.com</div>
        <div>
            <div class="font-semibold text-lg text-accent">8605 SANTA MONICA BLVD #793242</div>
            <div class="font-semibold text-lg text-accent">WEST HOLLYWOOD, CA 90069</div>
        </div>
    </div>
</div>