import { computed, effect, Injectable, signal } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { DataService } from '../data/data.service';
import { Observable, Subject, Subscriber, Subscription, firstValueFrom, lastValueFrom, map, switchMap, tap } from 'rxjs';
import {
  IDesignImage, IEventDesign, IImageAssignment, PositionAdjustment, IVenueEvent, IVenueEventSummary, IVenue,
  IDesignImageUpload, IGuestLink, IImageProp, IObjectAssignment, IObjectProp, IDesignObjectUpload, IDesignObject, ISpaceProviderSetting,
  IVideoProp, IDesignVideoUpload, IDesignVideo, IVideoAssignment, IPositionAdjustmentUpload, IImagePropUpload,
  IDesignObjectMaterial, IDesignObjectMaterialUpload, IObjectAssignmentMaterialUpload, IObjectAssignmentMaterial, IImagePropOptions,
  IVideoPropOptions, IPriceOption, IVideoOrchestrationParent, IClippingPlane, IClippingPlaneAssignment, DesignVideo, Venue,
  EventDesign, VenueEvent, DesignImage, DesignObject, ObjectAssignment, PropClass, ImageAssignment, ImagePropOptions,
  VideoAssignment, ClippingPlaneAssignment, IPositionAdjustment, ClippingPlane, VideoPropOptions, IVideoPropUpload,
  imagePropFields
} from 'projects/my-common/src/model';
import { NGXLogger } from 'ngx-logger';
import { URL_BASE } from 'src/environments/environment';
import { ImageProp, ObjectProp, VideoProp } from 'projects/my-common/src/model';
import { ShowroomService } from '../showroom/showroom.service';
import { ShowroomMode } from 'src/app/state/showroom-state';
import { IVenueService } from './interface/IVenueService';
import { SHOWROOM_ENDPOINT } from 'src/environments/interfaces/IEnvironment';

export interface VenueState {

  currentDesignImageId: number
  currentDesignObjectId: number
  currentDesignVideoId: number
  currentEventDesignId: number
  currentImageAssignmentId: number
  currentObjectAssignmentId: number
  currentVideoAssignmentId: number
  currentImagePropId: number
  currentObjectPropId: number
  currentVideoPropId: number
  currentVenue: Venue
  currentVenueEventId: number
  errorMessage?: string
}

/**
 * Aggregate service for Venue.
 * It manages details down to the the Event Design before handing off to the Event Design service (work in progress)
 * Venue
 *  -> Venue Event 1
 *    -> Event Design 1
 *    -> Event Design 2
 *  -> Venue Event 2
 *    -> etc
 */
@Injectable({
  providedIn: 'root'
})
export class VenueService implements IVenueService {

  private _state = signal<VenueState>({

    currentDesignImageId: 0,
    currentDesignObjectId: 0,
    currentDesignVideoId: 0,
    currentEventDesignId: 0,
    currentImageAssignmentId: 0,
    currentObjectAssignmentId: 0,
    currentVideoAssignmentId: 0,
    currentImagePropId: 0,
    currentObjectPropId: 0,
    currentVideoPropId: 0,
    currentVenue: new Venue(),
    currentVenueEventId: 0
  })

  // Redux Selectors
  readonly assignedImageProps = computed(() => this.imageProps()
    .filter(ip => this.currentEventDesign().imageAssignments.some(ia => ia.imagePropId === ip.id)));
  readonly assignedObjectProps = computed(() => this.objectProps()
    .filter(op => this.currentEventDesign().objectAssignments.some(oa => oa.objectPropId === op.id)));
  readonly assignedVideoProps = computed(() => this.videoProps()
    .filter(vp => this.currentEventDesign().videoAssignments.some(va => va.videoPropId === vp.id)));
  readonly assignedObjectPropCount = computed(() => this.assignedObjectProps().length);
  readonly currentDefaultImageAssignment = computed(() => this.defaultImageAssignments()
    .find(ia => ia.id === this._state().currentImageAssignmentId) ?? new ImageAssignment());
  readonly currentDefaultObjectAssignment = computed(() => this.defaultObjectAssignments()
    .find(oa => oa.id === this._state().currentObjectAssignmentId) ?? new ObjectAssignment());
  readonly currentDefaultDesignImage = computed(() => this.defaultDesignImages()
    .find(di => di.id === this._state().currentDesignImageId) ?? new DesignImage());
  readonly currentDefaultDesignObject = computed(() => this.defaultDesignObjects()
    .find(_do => _do.id === this._state().currentDesignObjectId) ?? new DesignObject());
  readonly currentDesignImage = computed(() => this.designImages()
    .find(di => di.id === this._state().currentDesignImageId) ?? this.currentDefaultDesignImage());
  readonly currentDesignObject = computed(() => this.designObjects()
    .find(_do => _do.id === this._state().currentDesignObjectId) ?? this.currentDefaultDesignObject());
  readonly currentDesignVideo = computed(() => this.designVideos()
    .find(dv => dv.id === this._state().currentDesignVideoId) ?? new DesignVideo());
  readonly currentEventDesign = computed(() => this.eventDesigns()
    .find(ed => ed.id === this._state().currentEventDesignId) ?? new EventDesign());
  readonly currentImageAssignment = computed(() => this.imageAssignments()
    .find(ia => ia.id === this._state().currentImageAssignmentId) ?? this.currentDefaultImageAssignment());
  readonly currentObjectAssignment = computed(() => this.objectAssignments()
    .find(oa => oa.id === this._state().currentObjectAssignmentId) ?? this.currentDefaultObjectAssignment());
  readonly currentVideoAssignment = computed(() => this.videoAssignments()
    .find(va => va.id === this._state().currentVideoAssignmentId) ?? new VideoAssignment());
  readonly currentImageProp = computed(() => this.venue().imageProps.
    find(ip => ip.id === this._state().currentImagePropId) ?? new ImageProp())
  readonly currentObjectProp = computed(() => this.venue().objectProps.
    find(op => op.id === this._state().currentObjectPropId) ?? new ObjectProp())
  readonly currentVideoProp = computed(() => this.venue().videoProps.
    find(vp => vp.id === this._state().currentVideoPropId) ?? new VideoProp())
  readonly currentVenueEvent = computed(() => this.venue().venueEvents
    .find(ve => ve.id === this._state().currentVenueEventId) ?? new VenueEvent());
  readonly defaultDesignImages = computed(() => this.defaultEventDesign().designImages);
  readonly defaultDesignObjects = computed(() => this.defaultEventDesign().designObjects);
  readonly defaultEventDesign = computed(() => this.defaultVenueEvent().eventDesigns
    .find(ed => ed.isDefault) ?? new EventDesign());
  readonly defaultImageAssignments = computed(() => this.defaultEventDesign().imageAssignments);
  readonly defaultImagePropOptions = computed(() => this.defaultEventDesign().imagePropOptions
    .find(ipo => ipo.imagePropId === this.currentImageProp().id) ?? new ImagePropOptions());
  readonly defaultObjectAssignments = computed(() => this.defaultEventDesign().objectAssignments);
  readonly defaultVenueEvent = computed(() => this.venue().venueEvents
    .find(ve => ve.isDefault) ?? new VenueEvent());
  readonly designImages = computed(() => this.currentEventDesign().designImages);
  readonly designObjects = computed(() => this.currentEventDesign().designObjects);
  readonly designVideos = computed(() => this.currentEventDesign().designVideos);
  readonly eventDesigns = computed(() => this.currentVenueEvent().eventDesigns);
  readonly errorMessage = computed(() => this._state().errorMessage);
  readonly guestLinks = computed(() => this.currentVenueEvent().guestLinks);
  readonly imageAssignments = computed(() => this.currentEventDesign().imageAssignments);
  readonly imageProps = computed(() => this.venue().imageProps);
  /**
   * Includes only assigned Props when Showroom mode is Showroom,
   * else assigned and staged Props when Showroom mode is Configuration or Administration.
   */
  readonly imagePropsForCurrentMode = computed(() => ShowroomMode.SHOWROOM === this.showroomState().instance.showroomMode ?
    this.imageProps()
      .filter(ip => this.currentEventDesign().imageAssignments.some(ia => ia.imagePropId === ip.id)) :
    this.imageProps()
      .filter(ip => ip.propClass === PropClass.Staged || this.currentEventDesign().imageAssignments.some(ia => ia.imagePropId === ip.id))
  );
  readonly imagePropOptions = computed(() => this.currentEventDesign().imagePropOptions
    .find(ipo => ipo.imagePropId === this.currentImageProp().id) ?? new ImagePropOptions());
  readonly objectAssignments = computed(() => this.currentEventDesign().objectAssignments);
  readonly objectProps = computed(() => this.venue().objectProps);
  readonly objectPropCount = computed(() => this.objectProps().length);
  readonly showroomState = this.showroomService.state;
  // Ordering by Id ascending maximizes references to early Prop Id before referencing newer Props
  readonly unassignedObjectProps = computed(() => this.objectProps()
    .filter(op => !this.currentEventDesign().objectAssignments.some(oa => oa.objectPropId === op.id))
    .sort((op1, op2) => op1.id - op2.id));
  readonly venue = computed(() => this._state().currentVenue);
  readonly venueEvents = computed(() => this.venue().venueEvents);
  readonly videoAssignments = computed(() => this.currentEventDesign().videoAssignments);
  readonly videoProps = computed(() => this.venue().videoProps);
  /**
   * Includes only assigned Props when Showroom mode is Showroom,
   * else assigned and staged Props when Showroom mode is Configuration or Administration.
   */
  readonly videoPropsForCurrentMode = computed(() => ShowroomMode.SHOWROOM === this.showroomState().instance.showroomMode ?
    this.videoProps()
      .filter(vp => this.currentEventDesign().videoAssignments.some(va => va.videoPropId === vp.id)) :
    this.videoProps()
      .filter(vp => vp.propClass === PropClass.Staged || this.currentEventDesign().videoAssignments.some(va => va.videoPropId === vp.id))
  );

  private _eventDesignUrl = '';
  private _venueUrl = '';
  private _venueEventUrl = '';

  isReady: boolean = false;
  // observable that is fired when urls are set
  private readonly _urlsSetSource = new Subject<any>();
  private readonly urlsSet$ = this._urlsSetSource.asObservable();
  private _gettingReady = false;
  private _whenReadyQueue: Subscriber<unknown>[] = [];
  readonly whenReady$ = new Observable((observer) => {

    if (this.isReady) {

      observer.next();
      return;
    }
    this._whenReadyQueue.push(observer);
    if (this._gettingReady) {

      return;
    }
    this._gettingReady = true;

    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.urlsSet$.subscribe(() => {
      this._whenReadyQueue.forEach(o => o.next());
      this._whenReadyQueue = [];
      this._gettingReady = false;

      unsubscribe();
    });
  });


  constructor(private readonly configurationService: ConfigurationService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly showroomService: ShowroomService) {

    this.setUrls();
  }


  copyImageProp(imagePropId: number): Observable<ImageProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/${imagePropId}`;

          return this.dataService.post<IImageProp>(url, imagePropId)
            .pipe(
              map((response: IImageProp) => this.upsertImagePropState(response))
            )
        })
      );
  }


  createDesignImage(designImage: IDesignImageUpload, progressCallback?: ((percentDone: number) => void)): Observable<DesignImage> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', '0');
          formData.append('eventDesignId', JSON.stringify(designImage.eventDesignId));
          formData.append('file', designImage.file);

          const url = `${this._eventDesignUrl}/${DesignImage.URI}`;

          return this.dataService.postWithProgress<IDesignImage>(url, formData, undefined, progressCallback)
            .pipe(
              map((response: IDesignImage) => this.upsertDesignImageState(response))
            )
        })
      );
  }


  createDesignObject(designObject: IDesignObjectUpload, progressCallback?: ((percentDone: number) => void)): Observable<DesignObject> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', '0');
          formData.append('eventDesignId', JSON.stringify(designObject.eventDesignId));
          formData.append('file', designObject.file);

          const url = `${this._eventDesignUrl}/${DesignObject.URI}`

          return this.dataService.postWithProgress<IDesignObject>(url, formData, undefined, progressCallback)
            .pipe(
              map((response: IDesignObject) => this.upsertDesignObjectState(response))
            )
        })
      );
  }


  /**
   * In addition to the file, only the eventDesignId is required
   */
  createDesignVideo(designVideo: IDesignVideoUpload, progressCallback?: ((percentDone: number) => void)): Observable<DesignVideo> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', '0');
          formData.append('eventDesignId', JSON.stringify(designVideo.eventDesignId));
          formData.append('file', designVideo.file);

          const url = `${this._eventDesignUrl}/${DesignVideo.URI}`;

          return this.dataService.postWithProgress<IDesignVideo>(url, formData, undefined, progressCallback)
            .pipe(
              map((response: IDesignVideo) => this.upsertDesignVideoState(response))
            )
        })
      );
  }


  createDesignVideoLink(designVideo: DesignVideo): Observable<DesignVideo> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const iDesignVideo = designVideo.data;

          const url = `${this._eventDesignUrl}/${DesignVideo.URI}/link`;

          return this.dataService.post<IDesignVideo>(url, iDesignVideo)
            .pipe(
              map((response: IDesignVideo) => this.upsertDesignVideoState(response))
            )
        })
      );
  }


  createEventDesign(eventDesign: EventDesign): Observable<EventDesign> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.post<IEventDesign>(this._eventDesignUrl, eventDesign)
            .pipe(
              map((response: IEventDesign) => this.upsertEventDesignState(response))
            )
        })
      );
  }


  createGuestLink(guest: IGuestLink): Observable<IGuestLink> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueEventUrl}/guest-link`

          return this.dataService.post<IGuestLink>(url, guest)
            .pipe(
              map((response: IGuestLink) => this.upsertGuestLinkState(response))
            )
        })
      );
  }


  createImageAssignment(imageAssignment: ImageAssignment): Observable<ImageAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ImageAssignment.URI}`

          return this.dataService.post<IImageAssignment>(url, imageAssignment)
            .pipe(
              map((response: IImageAssignment) => this.upsertImageAssignmentState(response))
            )
        })
      );
  }


  createImageProp(imageProp: ImageProp): Observable<ImageProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}`;

          return this.dataService.post<IImageProp>(url, imageProp)
            .pipe(
              map((response: IImageProp) => this.upsertImagePropState(response))
            )
        })
      );
  }


  createObjectAssignment(objectAssignment: ObjectAssignment): Observable<ObjectAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ObjectAssignment.URI}`;

          return this.dataService.post<IObjectAssignment>(url, objectAssignment)
            .pipe(
              map((response: IObjectAssignment) => this.upsertObjectAssignmentState(response))
            )
        })
      );
  }


  createObjectProp(objectProp: ObjectProp): Observable<ObjectProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ObjectProp.URI}`;

          return this.dataService.post<IObjectProp>(url, objectProp)
            .pipe(
              map((response: IObjectProp) => this.upsertObjectPropState(response))
            )
        })
      );
  }


  createVideoAssignment(videoAssignment: VideoAssignment): Observable<VideoAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${VideoAssignment.URI}`;

          return this.dataService.post<IVideoAssignment>(url, videoAssignment)
            .pipe(
              map((response: IVideoAssignment) => this.upsertVideoAssignmentState(response))
            )
        })
      );
  }


  createVenueEvent(venueEvent: VenueEvent): Observable<VenueEvent> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.post<IVenueEvent>(this._venueEventUrl, venueEvent)
            .pipe(
              map((response: IVenueEvent) => this.upsertVenueEventState(response))
            )
        })
      );
  }


  createVideoProp(videoProp: VideoProp): Observable<VideoProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}`;

          return this.dataService.post<IVideoProp>(url, videoProp)
            .pipe(
              map((response: IVideoProp) => this.upsertVideoPropState(response)))
        })
      );
  }


  deleteImageAdjustmentCustomMask(positionAdjustmentId: number): Observable<PositionAdjustment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/${PositionAdjustment.URI}/mask/${positionAdjustmentId}`;

          return this.dataService.delete<IPositionAdjustment>(url, {})
            .pipe(
              map((response: IPositionAdjustment) => this.upsertImageAdjustmentState(response))
            )
        })
      );
  }


  deleteImagePropCustomMask(imagePropId: number): Observable<ImageProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/mask/${imagePropId}`;

          return this.dataService.delete<IImageProp>(url, {})
            .pipe(
              map((response: IImageProp) => this.upsertImagePropState(response))
            )
        })
      );
  }


  deleteDesignImage(designImage: DesignImage): Observable<IDesignImage> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${DesignImage.URI}/${designImage.id}`;

          return this.dataService.delete<IDesignImage>(url, designImage)
            .pipe(
              map((response: IDesignImage) => {

                this._state.update(s => {

                  const eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
                  if (eventDesign && eventDesign.removeDesignImage(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteDesignObject(designObject: DesignObject): Observable<IDesignObject> {

    designObject.materials = [];

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${DesignObject.URI}/${designObject.id}`;

          return this.dataService.delete<IDesignObject>(url, designObject)
            .pipe(
              map((response: IDesignObject) => {

                this._state.update(s => {

                  const eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
                  if (eventDesign && eventDesign.removeDesignObject(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteDesignObjectMaterialAlphaMap(designObjectMaterial: IDesignObjectMaterial): Observable<IDesignObjectMaterial> {

    designObjectMaterial.alphaMapUrl = designObjectMaterial.mapUrl = '';

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${DesignObject.URI}/material/alpha/${designObjectMaterial.id}`;

          return this.dataService.delete<IDesignObjectMaterial>(url, designObjectMaterial)
            .pipe(
              map((response: IDesignObjectMaterial) => {

                let material = response;
                this._state.update(s => {

                  const designObject = s.currentVenue.getDesignObject(response.designObjectId);
                  if (designObject) {

                    // The material object may still hold reference to a map image
                    if (0 < response.mapFileName.length) {

                      material = designObject.upsertMaterial(response);
                    } else {

                      material = designObject.removeMaterial(response);
                    }

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return material;
              })
            )
        })
      );
  }


  deleteDesignObjectMaterialMap(designObjectMaterial: IDesignObjectMaterial): Observable<IDesignObjectMaterial> {

    designObjectMaterial.alphaMapUrl = designObjectMaterial.mapUrl = '';

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${DesignObject.URI}/material/map/${designObjectMaterial.id}`;

          return this.dataService.delete<IDesignObjectMaterial>(url, designObjectMaterial)
            .pipe(
              map((response: IDesignObjectMaterial) => {

                let material = response;
                this._state.update(s => {

                  const designObject = s.currentVenue.getDesignObject(response.designObjectId);
                  if (designObject) {

                    // The material object may still hold reference to a map image
                    if (0 < response.alphaMapFileName.length) {

                      material = designObject.upsertMaterial(response);
                    } else {

                      material = designObject.removeMaterial(response);
                    }

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return material;
              })
            )
        })
      );
  }


  deleteDesignVideo(designVideo: DesignVideo): Observable<IDesignVideo> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${DesignVideo.URI}/${designVideo.id}`;

          return this.dataService.delete<IDesignVideo>(url, designVideo.data)
            .pipe(
              map((response: IDesignVideo) => {

                this._state.update(s => {

                  const eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
                  if (eventDesign && eventDesign.removeDesignVideo(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteEventDesign(eventDesignId: number): Observable<IEventDesign> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${eventDesignId}`;

          return this.dataService.delete<IEventDesign>(url, {})
            .pipe(
              map((response: IEventDesign) => {

                this._state.update(s => {

                  const venueEvent = s.currentVenue.getVenueEvent(response.venueEventId);
                  if (venueEvent && venueEvent.removeEventDesign(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteImageAdjustment(adjustment: PositionAdjustment): Observable<IPositionAdjustment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/${PositionAdjustment.URI}/${adjustment.id}`;

          return this.dataService.delete<PositionAdjustment>(url, adjustment)
            .pipe(
              map((response: IPositionAdjustment) => {

                this._state.update(s => {

                  const prop = s.currentVenue.getImageProp(response.parentPropId);
                  if (prop && prop.removeAdjustment(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue),
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteImageAssignment(assignment: ImageAssignment): Observable<IImageAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ImageAssignment.URI}/${assignment.id}`;

          return this.dataService.delete<IImageAssignment>(url, assignment)
            .pipe(
              map((response: IImageAssignment) => {

                this._state.update(s => {

                  const eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
                  if (eventDesign && eventDesign.removeImageAssignment(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteImageClippingPlane(clippingPlaneId: number): Observable<IClippingPlane> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/${ClippingPlane.URI}/${clippingPlaneId}`;

          return this.dataService.delete<IClippingPlane>(url, clippingPlaneId)
            .pipe(
              map((response: IClippingPlane) => {

                this._state.update(s => {

                  const prop = s.currentVenue.getImageProp(response.parentPropId);
                  if (prop && prop.removeClippingPlane(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteImageClippingAssignment(clippingAssignmentId: number): Observable<IClippingPlaneAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/${PositionAdjustment.URI}/${ClippingPlaneAssignment.URI}/${clippingAssignmentId}`;

          return this.dataService.delete<IClippingPlaneAssignment>(url, clippingAssignmentId)
            .pipe(
              map((response: IClippingPlaneAssignment) => {

                this._state.update(s => {

                  let adjustment = s.currentVenue.getImageAdjustment(response.adjustmentId);
                  if (adjustment && adjustment.removeClippingAssignment(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteImagePriceOption(imagePriceOptionId: number): Observable<IPriceOption> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ImageAssignment.URI}/price-option/${imagePriceOptionId}`;

          return this.dataService.delete<IPriceOption>(url, imagePriceOptionId)
            .pipe(
              map((response: IPriceOption) => {

                this._state.update(s => {

                  let assignment = s.currentVenue.getImageAssignment(response.parentId);
                  if (assignment && assignment.removePriceOption(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteImageProp(imagePropId: number): Observable<IImageProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/${imagePropId}`;

          return this.dataService.delete<IImageProp>(url, imagePropId)
            .pipe(
              map((response: IImageProp) => {

                this._state.update(s => {

                  if (s.currentVenue.removeImageProp(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue),
                    }
                  }

                  return s;
                })

                return response
              })
            )
        })
      );
  }


  deleteObjectAssignment(assignment: ObjectAssignment): Observable<IObjectAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ObjectAssignment.URI}/${assignment.id}`;

          return this.dataService.delete<IObjectAssignment>(url, assignment)
            .pipe(
              map((response: IObjectAssignment) => {

                this._state.update(s => {

                  let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
                  if (eventDesign && eventDesign.removeObjectAssignment(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteObjectAssignmentMaterialAlphaMap(objectAssignmentMaterial: IObjectAssignmentMaterial): Observable<IObjectAssignmentMaterial> {

    objectAssignmentMaterial.alphaMapUrl = objectAssignmentMaterial.mapUrl = '';

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ObjectAssignment.URI}/material/alpha/${objectAssignmentMaterial.id}`;

          return this.dataService.delete<IObjectAssignmentMaterial>(url, objectAssignmentMaterial)
            .pipe(
              map((response: IObjectAssignmentMaterial) => {

                let material = response;
                this._state.update(s => {

                  let assignment = s.currentVenue.getObjectAssignment(objectAssignmentMaterial.objectAssignmentId);
                  if (assignment) {

                    // The material object may still hold reference to a map image
                    if (0 < response.mapFileName.length) {

                      material = assignment.upsertMaterial(response);
                    } else {

                      material = assignment.removeMaterial(response);
                    }

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return material;
              })
            )
        })
      );
  }


  deleteObjectAssignmentMaterialMap(objectAssignmentMaterial: IObjectAssignmentMaterial): Observable<IObjectAssignmentMaterial> {

    objectAssignmentMaterial.alphaMapUrl = objectAssignmentMaterial.mapUrl = '';

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ObjectAssignment.URI}/material/map/${objectAssignmentMaterial.id}`;

          return this.dataService.delete<IObjectAssignmentMaterial>(url, objectAssignmentMaterial)
            .pipe(
              map((response: IObjectAssignmentMaterial) => {

                let material = response;
                this._state.update(s => {

                  let assignment = s.currentVenue.getObjectAssignment(objectAssignmentMaterial.objectAssignmentId);
                  if (assignment) {

                    // The material object may still hold reference to an alpha map image
                    if (0 < response.alphaMapFileName.length) {

                      material = assignment.upsertMaterial(response);
                    } else {

                      material = assignment.removeMaterial(response);
                    }

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return material;
              })
            )
        })
      );
  }


  deleteObjectPriceOption(objectPriceOptionId: number): Observable<IPriceOption> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ObjectAssignment.URI}/price-option/${objectPriceOptionId}`;

          return this.dataService.delete<IPriceOption>(url, objectPriceOptionId)
            .pipe(
              map((response: IPriceOption) => {

                this._state.update(s => {

                  let assignment = s.currentVenue.getObjectAssignment(response.parentId);
                  if (assignment && assignment.removePriceOption(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteObjectProp(objectPropId: number): Observable<IObjectProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ObjectProp.URI}/${objectPropId}`;

          return this.dataService.delete<IObjectProp>(url, objectPropId)
            .pipe(
              map((response: IObjectProp) => {

                this._state.update(s => {

                  if (s.currentVenue.removeObjectProp(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue),
                      currentObjectPropId: s.currentObjectPropId === response.id ? 0 : s.currentObjectPropId
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteVenueEvent(venueEventId: number): Observable<IVenueEvent> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueEventUrl}/${venueEventId}`

          return this.dataService.delete<IVenueEvent>(url, {})
            .pipe(
              map((response: IVenueEvent) => {

                this._state.update(s => {

                  if (s.currentVenue.removeVenueEvent(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue),
                      currentVenueEventId: s.currentVenueEventId === response.id ? 0 : s.currentVenueEventId
                    }
                  }

                  return s
                })

                return response;
              })
            )
        })
      );
  }


  deleteVideoAdjustmentCustomMask(positionAdjustmentId: number): Observable<PositionAdjustment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}/${PositionAdjustment.URI}/mask/${positionAdjustmentId}`;

          return this.dataService.delete<IPositionAdjustment>(url, {})
            .pipe(
              map((response: IPositionAdjustment) => this.upsertVideoAdjustmentState(response))
            )
        })
      );
  }


  deleteVideoAssignment(assignment: IVideoAssignment): Observable<IVideoAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${VideoAssignment.URI}/${assignment.id}`;

          return this.dataService.delete<IVideoAssignment>(url, assignment)
            .pipe(
              map((response: IVideoAssignment) => {

                this._state.update(s => {

                  let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
                  if (eventDesign && eventDesign.removeVideoAssignment(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteVideoClippingPlane(clippingPlaneId: number): Observable<IClippingPlane> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}/${ClippingPlane.URI}/${clippingPlaneId}`;

          return this.dataService.delete<IClippingPlane>(url, clippingPlaneId)
            .pipe(
              map((response: IClippingPlane) => {

                this._state.update(s => {

                  const prop = s.currentVenue.getVideoProp(response.parentPropId);
                  if (prop && prop.removeClippingPlane(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteVideoClippingAssignment(clippingAssignmentId: number): Observable<IClippingPlaneAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}/${PositionAdjustment.URI}/${ClippingPlaneAssignment.URI}/${clippingAssignmentId}`;

          return this.dataService.delete<IClippingPlaneAssignment>(url, clippingAssignmentId)
            .pipe(
              map((response: IClippingPlaneAssignment) => {

                this._state.update(s => {

                  let adjustment = s.currentVenue.getVideoAdjustment(response.adjustmentId);
                  if (adjustment && adjustment.removeClippingAssignment(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteVideoAdjustment(adjustment: PositionAdjustment): Observable<IPositionAdjustment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}/${PositionAdjustment.URI}/${adjustment.id}`;

          return this.dataService.delete<PositionAdjustment>(url, adjustment)
            .pipe(
              map((response: IPositionAdjustment) => {

                this._state.update(s => {

                  const prop = s.currentVenue.getVideoProp(response.parentPropId);
                  if (prop && prop.removeAdjustment(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue),
                    }
                  }

                  return s;
                })

                return response;
              })
            )
        })
      );
  }


  deleteVideoProp(videoPropId: number): Observable<IVideoProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}/${videoPropId}`;

          return this.dataService.delete<IVideoProp>(url, videoPropId)
            .pipe(
              map((response: IVideoProp) => {

                this._state.update(s => {

                  if (s.currentVenue.removeVideoProp(response)) {

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue),
                    }
                  }

                  return s;
                })

                return response;
              })
            );
        })
      );
  }


  deleteVideoPropCustomMask(videoPropId: number): Observable<VideoProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}/mask/${videoPropId}`;

          return this.dataService.delete<IVideoProp>(url, {})
            .pipe(
              map((response: IVideoProp) => this.upsertVideoPropState(response))
            )
        })
      );
  }


  getDesignImageOrDefaultForImageAssignment(imageAssignment: ImageAssignment): DesignImage | undefined {

    return this.currentEventDesign().getDesignImageForAssignment(imageAssignment) ??
      this.defaultEventDesign().getDesignImageForAssignment(imageAssignment);
  }


  getDesignObjectOrDefaultForObjectAssignment(assignment: ObjectAssignment): DesignObject | undefined {

    return this.currentEventDesign().getDesignObjectForAssignment(assignment) ??
      this.defaultEventDesign().getDesignObjectForAssignment(assignment);
  }


  getDesignVideoOrDefaultForVideoAssignment(assignment: VideoAssignment): DesignVideo | undefined {

    return this.currentEventDesign().getDesignVideoForAssignment(assignment) ??
      this.defaultEventDesign().getDesignVideoForAssignment(assignment);
  }


  getImageAssignmentsOrDefaultForImageProp(imageProp: ImageProp): ImageAssignment[] {

    let imageAssignments = this.currentEventDesign().imageAssignments.filter(ia => ia.imagePropId === imageProp.id);
    if (0 < imageAssignments.length) {

      return imageAssignments;
    }

    return this.defaultEventDesign().imageAssignments.filter(ia => ia.imagePropId === imageProp.id);
  }


  getObjectAssignmentOrDefaultForObjectProp(objectProp: ObjectProp): ObjectAssignment | undefined {

    return this.currentEventDesign().getObjectAssignmentForObjectProp(objectProp) ??
      this.defaultEventDesign().getObjectAssignmentForObjectProp(objectProp);
  }


  getVideoAssignmentsOrDefaultForVideoProp(videoProp: VideoProp): VideoAssignment[] {

    let videoAssignments = this.currentEventDesign().videoAssignments.filter(va => va.videoPropId === videoProp.id);
    if (0 < videoAssignments.length) {

      return videoAssignments;
    }

    return this.defaultEventDesign().videoAssignments.filter(va => va.videoPropId === videoProp.id);
  }


  /**
   * Full EventDesign with DesignImages and ImageAssignments
   * @param eventDesignId 
   * @returns 
   */
  getEventDesign(eventDesignId: number): Observable<EventDesign> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          let url = `${this._eventDesignUrl}/${eventDesignId}`;

          return this.dataService.get<IEventDesign>(url).pipe(
            map((response: IEventDesign) => this.upsertEventDesignState(response))
          )
        })
      );
  }


  /**
   * EventDesigns without children (DesignImages and ImageAssignment)  
   * @param venueEventId 
   * @returns 
   */
  getEventDesigns(venueEventId: number): Observable<EventDesign[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          let url = `${this._venueEventUrl}/${EventDesign.URI}/${venueEventId}`;

          return this.dataService.get<IEventDesign[]>(url)
            .pipe(
              map((response: IEventDesign[]) => {

                const eventDesigns = response.map(ed => new EventDesign(ed));

                const venueEventIndex = this.venue().venueEvents.findIndex(ve => ve.id === venueEventId);
                if (-1 < venueEventIndex) {

                  this._state.update(s => {

                    s.currentVenue.venueEvents[venueEventIndex].eventDesigns = eventDesigns;
                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    };
                  })
                }

                return eventDesigns;
              })
            );
        })
      );
  }


  getGuestLink(guestLinkId: number): Observable<IGuestLink> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueEventUrl}/guest-link/${guestLinkId}`;

          return this.dataService.get<IGuestLink>(url)
            .pipe(
              map((response: IGuestLink) => this.upsertGuestLinkState(response))
            )
        })
      );
  }


  /**
   * Includes EventDesigns and Guests
   * @param venueEventId 
   * @returns 
   */
  getVenueEvent(venueEventId: number): Observable<VenueEvent> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          let url = `${this._venueEventUrl}/${venueEventId}`;

          return this.dataService.get<IVenueEvent>(url)
            .pipe(
              map((response: IVenueEvent) => this.upsertVenueEventState(response))
            );
        })
      );
  }


  getVenueEventSummaries(venueId: number): Observable<IVenueEventSummary[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueEventUrl}/summary/${venueId}`;

          return this.dataService.get<IVenueEventSummary[]>(url);
        })
      );
  }


  /**
   * Video Props identified as Orchestration parents via their VideoPropOptions per Event Design 
   * @param eventDesignId 
   * @returns 
   */
  getVideoOrchestrationParents(eventDesignId: number): Observable<IVideoOrchestrationParent[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          let url = `${this._showroomUrl}/video-orchestration-parents/${eventDesignId}`;

          return this.dataService.get<IVideoOrchestrationParent[]>(url);
        })
      );
  }


  /**
   * Import design image from extrnal event design into the target event design.
   * @param designImageId source
   * @param eventDesignId target
   * @returns 
   */
  importDesignImage(designImageId: number, eventDesignId: number): Observable<DesignImage> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${eventDesignId}/import/${DesignImage.URI}/${designImageId}`;

          return this.dataService.post<IDesignImage>(url, null)
            .pipe(
              map((response: IDesignImage) => this.upsertDesignImageState(response))
            )
        })
      );
  }


  /**
   * Import design object from extrnal event design into the target event design.
   * @param designObjectId source
   * @param eventDesignId target
   * @returns 
   */
  importDesignObject(designObjectId: number, eventDesignId: number): Observable<DesignObject> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${eventDesignId}/import/${DesignObject.URI}/${designObjectId}`;

          return this.dataService.post<IDesignObject>(url, null)
            .pipe(
              map((response: IDesignObject) => this.upsertDesignObjectState(response))
            )
        })
      );
  }


  /**
   * Import design video from extrnal event design into the target event design.
   * @param designVideoId source
   * @param eventDesignId target
   * @returns 
   */
  importDesignVideo(designVideoId: number, eventDesignId: number): Observable<DesignVideo> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${eventDesignId}/import/${DesignVideo.URI}/${designVideoId}`;

          return this.dataService.post<IDesignVideo>(url, null)
            .pipe(
              map((response: IDesignVideo) => this.upsertDesignVideoState(response))
            )
        })
      );
  }


  /**
   * Is user allowed to access Event Design
   * @param eventDesignId 
   * @returns 
   */
  isEventDesignAuthorized(eventDesignId: number): Observable<boolean> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/auth/${eventDesignId}`;

          return this.dataService.get<boolean>(url, eventDesignId);
        })
      );
  }


  /**
   * Is user allowed to access Venue
   * @param venueId 
   * @returns 
   */
  isVenueAuthorized(venueId: number): Observable<boolean> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/auth/${venueId}`;

          return this.dataService.get<boolean>(url, venueId);
        })
      );
  }


  /**
   * Is user allowed to access Venue event
   * @param venueEventId 
   * @returns 
   */
  isVenueEventAuthorized(venueEventId: number): Observable<boolean> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueEventUrl}/auth/${venueEventId}`;

          return this.dataService.get<boolean>(url, venueEventId);
        })
      );
  }


  setCurrentDesignImage(designImage: DesignImage) {

    if (this._state().currentDesignImageId !== designImage.id) {

      this._state.update(s => ({
        ...s,
        currentDesignImageId: designImage.id
      }));
    }
  }


  setCurrentDesignObject(designObject: DesignObject) {

    if (this._state().currentDesignObjectId !== designObject.id) {

      this._state.update(s => ({
        ...s,
        currentDesignObjectId: designObject.id
      }));
    }
  }


  setCurrentDesignVideo(designVideo: DesignVideo) {

    if (this._state().currentDesignVideoId !== designVideo.id) {

      this._state.update(s => ({
        ...s,
        currentDesignVideoId: designVideo.id
      }));
    }
  }


  setCurrentEventDesign(eventDesignId: number): void {

    if (this._state().currentEventDesignId !== eventDesignId) {

      this._state.update(s => ({
        ...s,
        currentEventDesignId: eventDesignId
      }));
    }
  }


  setCurrentImageAssignment(imageAssignment: ImageAssignment) {

    if (this._state().currentImageAssignmentId !== imageAssignment.id) {

      this._state.update(s => ({
        ...s,
        currentImageAssignmentId: imageAssignment.id
      }));
    }
  }


  setCurrentObjectAssignment(objectAssignment: ObjectAssignment) {

    if (this._state().currentObjectAssignmentId !== objectAssignment.id) {

      this._state.update(s => ({
        ...s,
        currentObjectAssignmentId: objectAssignment.id
      }));
    }
  }


  setCurrentVideoAssignment(videoAssignment: VideoAssignment) {

    if (this._state().currentVideoAssignmentId !== videoAssignment.id) {

      this._state.update(s => ({
        ...s,
        currentVideoAssignmentId: videoAssignment.id
      }));
    }
  }


  setCurrentImageProp(imagePropId: number) {

    if (this._state().currentImagePropId !== imagePropId) {

      this._state.update(s => ({
        ...s,
        currentImagePropId: imagePropId
      }));
    }
  }


  setCurrentObjectProp(objectPropId: number) {

    if (this._state().currentObjectPropId !== objectPropId) {

      this._state.update(s => ({
        ...s,
        currentObjectPropId: objectPropId
      }));
    }
  }


  setCurrentVideoProp(videoPropId: number) {

    if (this._state().currentVideoPropId !== videoPropId) {

      this._state.update(s => ({
        ...s,
        currentVideoPropId: videoPropId
      }));
    }
  }


  /**
   * Venue with ImageProp and VenueEvent children, no deeper
   * @param venueId 
   * @param propActive  0 = inactiveOnly, (default) 1 = activeOnly, else all 
   * @param venueEventActive  0 = inactiveOnly, (default) 1 = activeOnly, else all
   * @param includePositionAdjustments  (default) 0 = no, else yes
   * @returns 
   */
  async setCurrentVenue(venueId: number, propActive: number = 1, venueEventActive: number = 1, includePositionAdjustments: number = 0): Promise<void> {

    if (1 > venueId) {

      this._state.update(s => ({
        currentDesignImageId: 0,
        currentDesignObjectId: 0,
        currentDesignVideoId: 0,
        currentEventDesignId: 0,
        currentImageAssignmentId: 0,
        currentImagePropId: 0,
        currentObjectAssignmentId: 0,
        currentObjectPropId: 0,
        currentVenue: new Venue(),
        currentVenueEventId: 0,
        currentVideoAssignmentId: 0,
        currentVideoPropId: 0
      }));

      return;
    }

    await firstValueFrom(this.whenReady$);

    let url = `${this._venueUrl}/${venueId}?ipa=${propActive}&vea=${venueEventActive}&includePa=${includePositionAdjustments}`;

    const currentVenue = await firstValueFrom(this.dataService.get<IVenue>(url));
    this._state.update(s => ({
      ...s,
      currentVenue: new Venue(currentVenue)
    }));
  }


  /**
   * Venue with Props and VenueEvent + EventDesign with Design children for the specified Event Design
   * @param eventDesignId 
   * @returns 
   */
  async setCurrentVenueByEventDesignId(eventDesignId: number): Promise<void> {

    await firstValueFrom(this.whenReady$);

    let url = `${this._venueUrl}/${EventDesign.URI}/${eventDesignId}`;

    const currentVenue = await firstValueFrom(this.dataService.get<IVenue>(url));
    this._state.update(s => ({
      ...s,
      currentEventDesignId: eventDesignId,
      currentVenue: new Venue(currentVenue),
      currentVenueEventId: currentVenue.venueEvents[0].id
    }));
  }


  setCurrentVenueEvent(venueEventId: number) {

    if (this._state().currentVenueEventId !== venueEventId) {

      this._state.update(s => ({
        ...s,
        currentVenueEventId: venueEventId
      }))
    }
  }


  private _showroomUrl = '';
  private async setUrls(): Promise<void> {

    if (!this.configurationService.isReady) {

      await firstValueFrom(this.configurationService.whenReady$);
    }

    this._showroomUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Showroom}`;

    this._eventDesignUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.EventDesign}`;
    this._venueUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Venue}`;
    this._venueEventUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.VenueEvent}`;

    this.isReady = true;
    this._urlsSetSource.next(true);
  }


  private _isSwappingAssignments = false;
  get isSwappingAssignments(): boolean {

    return this._isSwappingAssignments;
  }
  /**
   * Switch the DesignImages associated with the specified imageAssignments.
   * This supports changes in the order of DesignImages by the assignments they are associated with.
   * @param imageAssignments 
   * @returns 
   */
  swapImageAssignments(imageAssignments: ImageAssignment[]): Observable<ImageAssignment[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          this._isSwappingAssignments = true;
          const url = `${this._eventDesignUrl}/${ImageAssignment.URI}/swap`

          return this.dataService.put<IImageAssignment[]>(url, imageAssignments)
            .pipe(
              map((response: IImageAssignment[]) => {

                let assignments: ImageAssignment[] = [];
                this._state.update(s => {

                  let eventDesign = s.currentVenue.getEventDesign(response[0].eventDesignId);
                  if (eventDesign) {

                    assignments = response.map(ia => eventDesign.upsertImageAssignment(ia));

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  assignments = response.map(ia => new ImageAssignment(ia));

                  return s;
                })

                setTimeout(() => this._isSwappingAssignments = false);
                return assignments;
              })
            )
        })
      );
  }


  /**
   * Switch the DesignVideos associated with the specified videoAssignments.
   * This supports changes in the order of DesignVideos by the assignments they are associated with.
   * @param videoAssignments 
   * @returns 
   */
  swapVideoAssignments(videoAssignments: VideoAssignment[]): Observable<VideoAssignment[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          this._isSwappingAssignments = true;
          const url = `${this._eventDesignUrl}/${VideoAssignment.URI}/swap`

          return this.dataService.put<IVideoAssignment[]>(url, videoAssignments)
          .pipe(
            map((response: IVideoAssignment[]) => {

              let assignments: VideoAssignment[] = [];
              this._state.update(s => {

                let eventDesign = s.currentVenue.getEventDesign(response[0].eventDesignId);
                if (eventDesign) {

                  assignments = response.map(va => eventDesign.upsertVideoAssignment(va));

                  return {
                    ...s,
                    currentVenue: new Venue(s.currentVenue)
                  }
                }

                assignments = response.map(ia => new VideoAssignment(ia));

                return s;
              })

              setTimeout(() => this._isSwappingAssignments = false);
              return assignments;
            })
          )
        })
      );
  }


  /**
   * Excludes materials.
   * @param designObject 
   * @returns 
   */
  updateDesignObject(designObject: DesignObject): Observable<DesignObject> {

    designObject.materials = [];

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${DesignObject.URI}`

          return this.dataService.put<IDesignObject>(url, designObject)
            .pipe(
              map((response: IDesignObject) => this.upsertDesignObjectState(response))
            )
        })
      );
  }


  /**
   * Only AlphaMapFlip and MapFlip properties.
   * @param designObjectMaterial 
   * @returns 
   */
  updateDesignObjectMaterial(designObjectMaterial: IDesignObjectMaterial): Observable<IDesignObjectMaterial> {

    designObjectMaterial.alphaMapUrl = designObjectMaterial.mapUrl = '';

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${DesignObject.URI}/material`;

          return this.dataService.put<IDesignObjectMaterial>(url, designObjectMaterial)
            .pipe(
              map((response: IDesignObjectMaterial) => this.upsertDesignObjectMaterialState(response))
            )
        })
      );
  }


  /**
   * Top level Event Design update.
   * Child entities are removed before PUT command.
   * @param eventDesign
   * @returns Event Design from getEventDesign() 
   */
  updateEventDesign(eventDesign: EventDesign): Observable<EventDesign> {

    eventDesign.designImages = [];
    eventDesign.designObjects = [];
    eventDesign.designVideos = [];
    eventDesign.imageAssignments = [];
    eventDesign.objectAssignments = [];
    eventDesign.videoAssignments = [];

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.put<IEventDesign>(this._eventDesignUrl, eventDesign)
            .pipe(
              map((response: IEventDesign) => this.upsertEventDesignState(response))
            )
        })
      );
  }


  updateGuestLink(guestLink: IGuestLink): Observable<IGuestLink> {

    guestLink.visitHistory = [];

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueEventUrl}/guest-link`

          return this.dataService.put<IGuestLink>(url, guestLink)
            .pipe(
              map((response: IGuestLink) => this.upsertGuestLinkState(response))
            )
        })
      );
  }


  updateImageAssignment(imageAssignment: ImageAssignment): Observable<ImageAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ImageAssignment.URI}`;

          return this.dataService.put<IImageAssignment>(url, imageAssignment)
            .pipe(
              map((response: IImageAssignment) => this.upsertImageAssignmentState(response))
            )
        })
      );
  }


  async updateImageProp(imageProp: IImageProp): Promise<ImageProp> {

    await firstValueFrom(this.whenReady$);

    const url = `${this._venueUrl}/${ImageProp.URI}`;

    return await firstValueFrom(this.dataService.put<IImageProp>(url, imageProp)
      .pipe(
        map((response: IImageProp) => this.upsertImagePropState(response))
      )
    );
  }


  updateObjectAssignment(objectAssignment: ObjectAssignment): Observable<ObjectAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ObjectAssignment.URI}`;

          return this.dataService.put<IObjectAssignment>(url, objectAssignment)
            .pipe(
              map((response: IObjectAssignment) => this.upsertObjectAssignmentState(response))
            )
        })
      );
  }


  /**
   * Only AlphaMapFlip and MapFlip properties.
   * @param objectAssignmentMaterial 
   * @returns 
   */
  updateObjectAssignmentMaterial(objectAssignmentMaterial: IObjectAssignmentMaterial): Observable<IObjectAssignmentMaterial> {

    objectAssignmentMaterial.alphaMapUrl = objectAssignmentMaterial.mapUrl = '';

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ObjectAssignment.URI}/material`;

          return this.dataService.put<IObjectAssignmentMaterial>(url, objectAssignmentMaterial)
            .pipe(
              map((response: IObjectAssignmentMaterial) => {

                return this.upsertObjectAssignmentMaterialState(response)
              })
            )
        })
      );
  }


  async updateObjectProp(objectProp: ObjectProp): Promise<ObjectProp> {

    await firstValueFrom(this.whenReady$);

    const url = `${this._venueUrl}/${ObjectProp.URI}`

    return await lastValueFrom(this.dataService.put<IObjectProp>(url, objectProp)
      .pipe(
        map((response: IObjectProp) => this.upsertObjectPropState(response))
      )
    );
  }


  async updateSpaceProviderSettings(venueId: number, spaceProviderSettings: ISpaceProviderSetting[]): Promise<ISpaceProviderSetting[]> {

    await firstValueFrom(this.whenReady$);

    const url = `${this._venueUrl}/provider-settings/${venueId}`;

    return await lastValueFrom(this.dataService.put<ISpaceProviderSetting[]>(url, spaceProviderSettings)
      .pipe(
        tap((response: ISpaceProviderSetting[]) => {

          this._state.update(s => {

            s.currentVenue.spaceProviderSettings = response;

            return {
              ...s,
              currentVenue: new Venue(s.currentVenue)
            }
          })
        })
      )
    );
  }


  /**
   * Does not send or receive VenueEvent child entities
   * (see addDesignImage())
   * @param venueEvent
   * @returns 
   */
  updateVenueEvent(venueEvent: VenueEvent): Observable<VenueEvent> {

    // Event designs might be fully populated or in an edited state so we are preserving them
    const eventDesigns = venueEvent.eventDesigns;
    // Event designs are not updated
    venueEvent.eventDesigns = [];
    // Guest links are not updated. Current guest links are returned
    venueEvent.guestLinks = []

    return this.whenReady$
      .pipe(
        switchMap(x =>

          this.dataService.put<IVenueEvent>(this._venueEventUrl, venueEvent)
            .pipe(
              map((response: IVenueEvent) => {

                // Restore event designs that were preserved
                response.eventDesigns = eventDesigns;
                return this.upsertVenueEventState(response)
              })
            )
        )
      );
  }


  updateVideoAssignment(videoAssignment: VideoAssignment): Observable<VideoAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${VideoAssignment.URI}`

          return this.dataService.put<IVideoAssignment>(url, videoAssignment)
            .pipe(
              map((response: IVideoAssignment) => this.upsertVideoAssignmentState(response))
            )
        })
      );
  }


  async updateVideoProp(videoProp: VideoProp): Promise<VideoProp> {

    await firstValueFrom(this.whenReady$);

    const url = `${this._venueUrl}/${VideoProp.URI}`

    return await lastValueFrom(this.dataService.put<IVideoProp>(url, videoProp)
      .pipe(
        map((response: IVideoProp) => this.upsertVideoPropState(response))
      )
    );
  }


  upsertDesignObjectMaterialAlphaMap(designObjectMaterialMap: IDesignObjectMaterialUpload): Observable<IDesignObjectMaterial> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', JSON.stringify(designObjectMaterialMap.id));
          formData.append('designObjectId', JSON.stringify(designObjectMaterialMap.designObjectId));
          formData.append('name', designObjectMaterialMap.name);
          formData.append('file', designObjectMaterialMap.file);
          formData.append('alphaMapFileName', designObjectMaterialMap.file.name);
          formData.append('alphaMapFileSize', JSON.stringify(designObjectMaterialMap.file.size));

          const url = `${this._eventDesignUrl}/${DesignObject.URI}/material/alpha-map`;

          return this.dataService.post<IDesignObjectMaterial>(url, formData)
            .pipe(
              tap((response: IDesignObjectMaterial) => this.upsertDesignObjectMaterialState(response))
            )
        })
      );
  }


  upsertDesignObjectMaterialMap(designObjectMaterialMap: IDesignObjectMaterialUpload): Observable<IDesignObjectMaterial> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', JSON.stringify(designObjectMaterialMap.id));
          formData.append('designObjectId', JSON.stringify(designObjectMaterialMap.designObjectId));
          formData.append('name', designObjectMaterialMap.name);
          formData.append('file', designObjectMaterialMap.file);
          formData.append('mapFileName', designObjectMaterialMap.file.name);
          formData.append('mapFileSize', JSON.stringify(designObjectMaterialMap.file.size));

          const url = `${this._eventDesignUrl}/${DesignObject.URI}/material/map`;

          return this.dataService.post<IDesignObjectMaterial>(url, formData)
            .pipe(
              map((response: IDesignObjectMaterial) => this.upsertDesignObjectMaterialState(response))
            )
        })
      );
  }


  private upsertDesignImageState(response: IDesignImage): DesignImage {

    let designImage = new DesignImage(response);
    this._state.update(s => {

      let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
      if (eventDesign) {

        designImage = eventDesign.upsertDesignImage(response);

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      }

      return s;
    })

    return designImage;
  }


  private upsertDesignObjectState(response: IDesignObject): DesignObject {

    let designObject = new DesignObject(response);
    this._state.update(s => {

      let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
      if (eventDesign) {

        designObject = eventDesign.upsertDesignObject(response);

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      }

      return s;
    })

    return designObject;
  }


  private upsertDesignObjectMaterialState(response: IDesignObjectMaterial): IDesignObjectMaterial {

    let material = response;
    this._state.update(s => {

      const designObject = s.currentVenue.getDesignObject(response.designObjectId);
      if (designObject) {

        material = designObject.upsertMaterial(response);

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      }

      return s;
    })

    return material;
  }


  private upsertDesignVideoState(response: IDesignVideo): DesignVideo {

    let designVideo = new DesignVideo(response);
    this._state.update(s => {

      let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
      if (eventDesign) {

        designVideo = eventDesign.upsertDesignVideo(response);
      }

      return {
        ...s,
        currentVenue: new Venue(s.currentVenue)
      }
    })

    return designVideo;
  }


  private upsertEventDesignState(response: IEventDesign): EventDesign {

    let upsertedEventDesign = response;

    let venueEvent = this.venue().getVenueEvent(response.venueEventId);
    if (venueEvent) {

      this._state.update(s => {

        upsertedEventDesign = venueEvent.upsertEventDesign(response);

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      });
    }

    return new EventDesign(upsertedEventDesign);
  }


  private upsertGuestLinkState(response: IGuestLink): IGuestLink {

    let upsertedGuestLink = response;
    const venueEvent = this.venue().getVenueEvent(response.venueEventId);
    if (venueEvent) {

      this._state.update(s => {

        upsertedGuestLink = venueEvent.upsertGuestLink(upsertedGuestLink);

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      })
    }

    return upsertedGuestLink;
  }


  upsertImageAdjustment(adjustment: IPositionAdjustment): Observable<PositionAdjustment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/${PositionAdjustment.URI}`;

          return this.dataService.post<IPositionAdjustment>(url, adjustment)
            .pipe(
              map((response: IPositionAdjustment) => this.upsertImageAdjustmentState(response))
            )
        })
      );
  }


  upsertImageAdjustmentCustomMask(adjustmentUpload: IPositionAdjustmentUpload): Observable<PositionAdjustment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', JSON.stringify(adjustmentUpload.id));
          formData.append('file', adjustmentUpload.file);
          formData.append('parentPropId', JSON.stringify(adjustmentUpload.parentPropId));

          const url = `${this._venueUrl}/${ImageProp.URI}/${PositionAdjustment.URI}/mask`;

          return this.dataService.post<IPositionAdjustment>(url, formData)
            .pipe(
              map((response: IPositionAdjustment) => this.upsertImageAdjustmentState(response))
            )
        })
      );
  }


  private upsertImageAdjustmentState(response: IPositionAdjustment): PositionAdjustment {

    let adjustment = new PositionAdjustment(response);
    this._state.update(s => {

      adjustment = s.currentVenue.upsertImagePropAdjustment(response);

      return {
        ...s,
        currentVenue: new Venue(s.currentVenue)
      }
    })

    return adjustment;
  }


  private upsertImageAssignmentState(response: IImageAssignment): ImageAssignment {

    let imageAssignment = new ImageAssignment(response);
    this._state.update(s => {

      let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
      if (eventDesign) {

        imageAssignment = eventDesign.upsertImageAssignment(response);

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      }

      return s;
    })

    return imageAssignment;
  }


  upsertImageClippingPlane(clippingPlane: IClippingPlane): Observable<ClippingPlane> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/${ClippingPlane.URI}`;

          return this.dataService.put<IClippingPlane>(url, clippingPlane)
            .pipe(
              map((response: IClippingPlane) => {

                let clippingPlane = new ClippingPlane(response);
                this._state.update(s => {

                  clippingPlane = s.currentVenue.upsertImageClippingPlane(response);

                  return {
                    ...s,
                    currentVenue: new Venue(s.currentVenue)
                  }
                })

                return clippingPlane;
              })
            )
        })
      );
  }


  upsertImageClippingAssignment(clippingPlaneAssignment: ClippingPlaneAssignment): Observable<ClippingPlaneAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${ImageProp.URI}/${PositionAdjustment.URI}/${ClippingPlaneAssignment.URI}`;

          return this.dataService.put<IClippingPlaneAssignment>(url, clippingPlaneAssignment)
            .pipe(
              map((response: IClippingPlaneAssignment) => this.upsertImageClippingAssignmentState(response))
            )
        })
      );
  }


  
  private upsertImageClippingAssignmentState(response: IClippingPlaneAssignment): ClippingPlaneAssignment {

    let assignment = new ClippingPlaneAssignment(response);
    this._state.update(s => {

      let adjustment = s.currentVenue.getImageAdjustment(response.adjustmentId);
      if (adjustment) {

        assignment = adjustment.upsertClippingAssignment(response)

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      }

      return s;
    })

    return assignment;    
  }


  upsertImagePropMask(imagePropUpload: IImagePropUpload): Observable<ImageProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append(imagePropFields.id, JSON.stringify(imagePropUpload.id));
          formData.append('file', imagePropUpload.file);
          formData.append(imagePropFields.venueId, JSON.stringify(imagePropUpload.venueId));

          const url = `${this._venueUrl}/${ImageProp.URI}/mask`;

          return this.dataService.post<IImageProp>(url, formData)
            .pipe(
              map((response: IImageProp) => this.upsertImagePropState(response))
            )
        })
      );
  }


  upsertImagePropOptions(options: IImagePropOptions): Observable<ImagePropOptions> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${ImagePropOptions.URI}`

          return this.dataService.post<IImagePropOptions>(url, options)
            .pipe(
              map((response: IImagePropOptions) => {

                let options = new ImagePropOptions(response);
                this._state.update(s => {

                  let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
                  if (eventDesign) {

                    options = eventDesign.upsertImagePropOptions(response);

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return options;
              })
            )
        })
      );
  }


  private upsertImagePropState(target: IImageProp): ImageProp {

    let upsertedProp = target;
    this._state.update(s => {

      upsertedProp = s.currentVenue.upsertImageProp(target);

      return {
        ...s,
        currentVenue: new Venue(s.currentVenue)
      }
    })

    return new ImageProp(upsertedProp);
  }


  upsertObjectAssignmentMaterialAlphaMap(objectAssignmentMaterialMap: IObjectAssignmentMaterialUpload): Observable<IObjectAssignmentMaterial> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', JSON.stringify(objectAssignmentMaterialMap.id));
          formData.append('objectAssignmentId', JSON.stringify(objectAssignmentMaterialMap.objectAssignmentId));
          formData.append('name', objectAssignmentMaterialMap.name);
          formData.append('file', objectAssignmentMaterialMap.file);
          formData.append('alphaMapFileName', objectAssignmentMaterialMap.file.name);
          formData.append('alphaMapFileSize', JSON.stringify(objectAssignmentMaterialMap.file.size));

          const url = `${this._eventDesignUrl}/${ObjectAssignment.URI}/material/alpha-map`;

          return this.dataService.post<IObjectAssignmentMaterial>(url, formData)
            .pipe(
              map((response: IObjectAssignmentMaterial) => this.upsertObjectAssignmentMaterialState(response))
            )
        })
      );
  }


  upsertObjectAssignmentMaterialMap(objectAssignmentMaterialMap: IObjectAssignmentMaterialUpload): Observable<IObjectAssignmentMaterial> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', JSON.stringify(objectAssignmentMaterialMap.id));
          formData.append('objectAssignmentId', JSON.stringify(objectAssignmentMaterialMap.objectAssignmentId));
          formData.append('name', objectAssignmentMaterialMap.name);
          formData.append('file', objectAssignmentMaterialMap.file);
          formData.append('mapFileName', objectAssignmentMaterialMap.file.name);
          formData.append('mapFileSize', JSON.stringify(objectAssignmentMaterialMap.file.size));

          const url = `${this._eventDesignUrl}/${ObjectAssignment.URI}/material/map`;

          return this.dataService.post<IObjectAssignmentMaterial>(url, formData)
            .pipe(
              map((response: IObjectAssignmentMaterial) => this.upsertObjectAssignmentMaterialState(response))
            )
        })
      );
  }


  private upsertObjectAssignmentState(response: IObjectAssignment): ObjectAssignment {

    let objectAssignment = new ObjectAssignment(response);
    this._state.update(s => {

      let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
      if (eventDesign) {

        objectAssignment = eventDesign.upsertObjectAssignment(response);

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      }

      return s;
    })

    return objectAssignment;
  }


  private upsertObjectAssignmentMaterialState(response: IObjectAssignmentMaterial): IObjectAssignmentMaterial {

    let material = response;
    this._state.update(s => {

      const objectAssignment = s.currentVenue.getObjectAssignment(response.objectAssignmentId);
      if (objectAssignment) {

        material = objectAssignment.upsertMaterial(response);

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      }

      return s;
    })

    return material;
  }


  private upsertObjectPropState(target: IObjectProp): ObjectProp {

    let upsertedProp = target;
    this._state.update(s => {

      upsertedProp = s.currentVenue.upsertObjectProp(target);

      return {
        ...s,
        currentVenue: new Venue(s.currentVenue)
      }
    })

    return new ObjectProp(upsertedProp);
  }


  private upsertVenueEventState(target: IVenueEvent): VenueEvent {

    let upsertedVenueEvent = target;
    this._state.update(s => {

      upsertedVenueEvent = s.currentVenue.upsertVenueEvent(target);

      return {
        ...s,
        currentVenue: new Venue(s.currentVenue)
      }
    })

    return new VenueEvent(upsertedVenueEvent);
  }


  upsertVideoAdjustment(adjustment: IPositionAdjustment): Observable<PositionAdjustment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}/${PositionAdjustment.URI}`;

          return this.dataService.post<IPositionAdjustment>(url, adjustment)
            .pipe(
              map((response: IPositionAdjustment) => this.upsertVideoAdjustmentState(response))
            )
        })
      );
  }


  upsertVideoAdjustmentCustomMask(adjustmentUpload: IPositionAdjustmentUpload): Observable<PositionAdjustment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', JSON.stringify(adjustmentUpload.id));
          formData.append('file', adjustmentUpload.file);
          formData.append('parentPropId', JSON.stringify(adjustmentUpload.parentPropId));

          const url = `${this._venueUrl}/${VideoProp.URI}/${PositionAdjustment.URI}/mask`;

          return this.dataService.post<IPositionAdjustment>(url, formData)
            .pipe(
              map((response: IPositionAdjustment) => this.upsertVideoAdjustmentState(response))
            )
        })
      );
  }


  private upsertVideoAdjustmentState(response: IPositionAdjustment): PositionAdjustment {

    let adjustment = new PositionAdjustment(response);
    this._state.update(s => {

      adjustment = s.currentVenue.upsertVideoPropAdjustment(response);

      return {
        ...s,
        currentVenue: new Venue(s.currentVenue)
      }
    })

    return adjustment;
  }


  private upsertVideoAssignmentState(response: IVideoAssignment): VideoAssignment {

    let assignment = new VideoAssignment(response);
    this._state.update(s => {

      let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
      if (eventDesign) {

        assignment = eventDesign.upsertVideoAssignment(assignment);

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      }

      return s;
    })

    return assignment;
  }


  upsertVideoClippingPlane(clippingPlane: IClippingPlane): Observable<ClippingPlane> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}/${ClippingPlane.URI}`;

          return this.dataService.put<IClippingPlane>(url, clippingPlane)
            .pipe(
              map((response: IClippingPlane) => {

                let clippingPlane = new ClippingPlane(response);
                this._state.update(s => {

                  clippingPlane = s.currentVenue.upsertVideoClippingPlane(response);

                  return {
                    ...s,
                    currentVenue: new Venue(s.currentVenue)
                  }
                })

                return clippingPlane;
              })
            )
        })
      );
  }


  upsertVideoClippingPlaneAssignment(clippingPlaneAssignment: ClippingPlaneAssignment): Observable<ClippingPlaneAssignment> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venueUrl}/${VideoProp.URI}/${PositionAdjustment.URI}/${ClippingPlaneAssignment.URI}`;

          return this.dataService.put<IClippingPlaneAssignment>(url, clippingPlaneAssignment)
            .pipe(
              map((response: IClippingPlaneAssignment) => this.upsertVideoClippingAssignmentState(response))
            )
        })
      );
  }

  
  private upsertVideoClippingAssignmentState(response: IClippingPlaneAssignment): ClippingPlaneAssignment {

    let assignment = new ClippingPlaneAssignment(response);
    this._state.update(s => {

      let adjustment = s.currentVenue.getVideoAdjustment(response.adjustmentId);
      if (adjustment) {

        assignment = adjustment.upsertClippingAssignment(response)

        return {
          ...s,
          currentVenue: new Venue(s.currentVenue)
        }
      }

      return s;
    })

    return assignment;    
  }


  upsertVideoPropMask(videoPropUpload: IVideoPropUpload): Observable<VideoProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const formData = new FormData();
          formData.append('id', JSON.stringify(videoPropUpload.id));
          formData.append('file', videoPropUpload.file);
          formData.append('vanueId', JSON.stringify(videoPropUpload.venueId));

          const url = `${this._venueUrl}/${VideoProp.URI}/mask`;

          return this.dataService.post<IVideoProp>(url, formData)
            .pipe(
              map((response: IVideoProp) => this.upsertVideoPropState(response))
            )
        })
      );
  }


  upsertVideoPropOptions(videoPropOptions: IVideoPropOptions): Observable<VideoPropOptions> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._eventDesignUrl}/${VideoPropOptions.URI}`

          return this.dataService.post<IVideoPropOptions>(url, videoPropOptions)
            .pipe(
              map((response: IVideoPropOptions) => {

                let options = new VideoPropOptions(response);
                this._state.update(s => {

                  let eventDesign = s.currentVenue.getEventDesign(response.eventDesignId);
                  if (eventDesign) {

                    options = eventDesign.upsertVideoPropOptions(response);

                    return {
                      ...s,
                      currentVenue: new Venue(s.currentVenue)
                    }
                  }

                  return s;
                })

                return options;
              })
            )
        })
      );
  }


  private upsertVideoPropState(target: IVideoProp): VideoProp {

    let upsertedProp = target;
    this._state.update(s => {

      upsertedProp = s.currentVenue.upsertVideoProp(target);

      return {
        ...s,
        currentVenue: new Venue(s.currentVenue)
      }
    })

    return new VideoProp(upsertedProp);
  }


}

