<div class="flex flex-col space-y-6 justify-center items-center max-w-4xl mx-auto min-h-screen">
    <div class="text-3xl italic text-center">Launching AR experience ...</div>

    <button class="btn btn-accent hover:bg-accent-hover text-primary" (click)="handleReturnToShowroom()">
        Return to Showroom
    </button>

    <model-viewer alt="3D Object viewer" [src]="objectUrl()" (load)="onModelViewerLoaded($event)"
        ar shadow-intensity="1" camera-controls [ar-placement]="placement()">
    </model-viewer>
</div>