import { effect, Injectable, signal, WritableSignal } from '@angular/core';
import { Observable, Subject, Subscriber, firstValueFrom, switchMap, tap } from 'rxjs';
import { ConfigurationService } from '../configuration/configuration.service';
import { DataService } from '../data/data.service';
import { IImportableDesignImages, IImportableDesignObjects, IImportableDesignVideos, ISearchResult, IVenue } from 'projects/my-common/src/model';
import { NGXLogger } from 'ngx-logger';
import { IRecentVenueEvent } from 'src/app/components/myoptyx/recent-events-card/recent-events-card.component';
import { IRecentEventDesign } from 'src/app/components/myoptyx/recent-designs-card/recent-designs-card.component';
import { URL_BASE } from 'src/environments/environment';
import { SHOWROOM_ENDPOINT } from 'src/environments/interfaces/IEnvironment';
import { AccountService } from '../myoptyx/account.service';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private _searchUrl = '';

  isReady = false;
  // observable that is fired when urls are set
  private readonly _urlsSetSource = new Subject<any>();
  private readonly urlsSet$ = this._urlsSetSource.asObservable();
  private _gettingReady = false;
  private _whenReadyQueue: Subscriber<unknown>[] = [];
  readonly whenReady$ = new Observable((observer) => {
    if (this.isReady) {
      observer.next();
      return;
    }

    this._whenReadyQueue.push(observer);
    if (!this._gettingReady) {
      this._gettingReady = true;
      this.urlsSet$.subscribe(() => {
        this._whenReadyQueue.forEach(o => o.next());
        this._whenReadyQueue = [];
        this._gettingReady = false;
      });
    }
  });


  readonly recentDesigns: WritableSignal<IRecentEventDesign[]> = signal([]);
  readonly recentEvents: WritableSignal<IRecentVenueEvent[]> = signal([]);


  constructor(private readonly accountService: AccountService,
    private readonly configurationService: ConfigurationService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger) {

    this.setUrls();

    effect(() => {

      if (accountService.subscriptionAccount().isValid) {

        this.setRecentEventDesigns(accountService.subscriptionAccount().accountNumber);
        this.setRecentVenueEvents(accountService.subscriptionAccount().accountNumber);
      } else {

        this.recentDesigns.set([]);
        this.recentEvents.set([]);
      }
    }, { allowSignalWrites: true });
  }


  importableDesignImages(eventDesignId: number, includeThisDesignsImages: boolean = false, pageSize: number = 10, pageIndex: number = 0): Observable<IImportableDesignImages> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/eventDesign/images/${eventDesignId}?pageSize=${pageSize}&pageIndex=${pageIndex}&include=${includeThisDesignsImages ? 1 : 0}`;

          return this.dataService.get<IImportableDesignImages>(url)
            .pipe(
              tap((response: IImportableDesignImages) => { })
            )
        })
      );
  }


  importableDesignObjects(eventDesignId: number, includeThisDesignsImages: boolean = false, pageSize: number = 10, pageIndex: number = 0): Observable<IImportableDesignObjects> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/eventDesign/objects/${eventDesignId}?pageSize=${pageSize}&pageIndex=${pageIndex}&include=${includeThisDesignsImages ? 1 : 0}`;

          return this.dataService.get<IImportableDesignObjects>(url)
            .pipe(
              tap((response: IImportableDesignObjects) => { })
            )
        })
      );
  }


  importableDesignVideos(eventDesignId: number, includeThisDesignsImages: boolean = false, pageSize: number = 10, pageIndex: number = 0): Observable<IImportableDesignVideos> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/eventDesign/videos/${eventDesignId}?pageSize=${pageSize}&pageIndex=${pageIndex}&include=${includeThisDesignsImages ? 1 : 0}`;

          return this.dataService.get<IImportableDesignVideos>(url)
            .pipe(
              tap((response: IImportableDesignVideos) => { })
            )
        })
      );
  }


  /**
   * Personalization
   * @param accountNumber
   * @returns Up to five Venues recently updated by current user
   */
  recentVenues(accountNumber: string): Observable<IVenue[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/venues/recent/${accountNumber}`;

          return this.dataService.get<IVenue[]>(url)
            .pipe(
              tap((response: IVenue[]) => { })
            )
        })
      );
  }


  /**
   * Breadcrumb
   * @param accountNumber 
   * @returns first search result with matching Account number
   */
  searchByAccountNumber(accountNumber: string): Observable<ISearchResult> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/account/${accountNumber}`;

          return this.dataService.get<ISearchResult>(url)
            .pipe(
              tap((response: ISearchResult) => { })
            )
        })
      );
  }


  /**
   * Breadcrumb
   * @param eventDesignId 
   * @returns first search result with matching Event Design id
   */
  searchByEventDesignId(eventDesignId: number): Observable<ISearchResult> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/eventDesign/${eventDesignId}`;

          return this.dataService.get<ISearchResult>(url)
            .pipe(
              tap((response: ISearchResult) => { })
            )
        })
      );
  }


  /**
   * Breadcrumb
   * @param userName 
   * @returns first search result with matching User id
   */
  searchByUserId(userName: string): Observable<ISearchResult> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/user/${userName}`;

          return this.dataService.get<ISearchResult>(url)
            .pipe(
              tap((response: ISearchResult) => { })
            )
        })
      );
  }


  /**
   * Breadcrumb
   * @param venueId 
   * @returns first search result with matching Venue id
   */
  searchByVenueId(venueId: number): Observable<ISearchResult> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/venue/${venueId}`;

          return this.dataService.get<ISearchResult>(url)
            .pipe(
              tap((response: ISearchResult) => { })
            )
        })
      );
  }


  /**
   * Breadcrumb
   * @param venueEventId 
   * @returns first search result with matching Venue Event id
   */
  searchByVenueEventId(venueEventId: number): Observable<ISearchResult> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/venueEvent/${venueEventId}`;

          return this.dataService.get<ISearchResult>(url)
            .pipe(
              tap((response: ISearchResult) => { })
            )
        })
      );
  }


  /**
   * Personalization
   * @param accountNumber
   * @returns Up to five Event Designs recently updated by current user
   */
  async setRecentEventDesigns(accountNumber: string): Promise<void> {

    await firstValueFrom(this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/eventDesigns/recent/${accountNumber}`;

          return this.dataService.get<IRecentEventDesign[]>(url)
            .pipe(
              tap((response: IRecentEventDesign[]) => this.recentDesigns.set(response))
            )
        })
      )
    );
  }


  /**
   * Personalization
   * @param accountNumber
   * @returns Up to five Venue Events recently updated by current user
   */
  async setRecentVenueEvents(accountNumber: string): Promise<void> {

    await firstValueFrom(this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._searchUrl}/venueEvents/recent/${accountNumber}`;

          return this.dataService.get<IRecentVenueEvent[]>(url)
            .pipe(
              tap((response: IRecentVenueEvent[]) => this.recentEvents.set(response))
            )
        })
      )
    );
  }


  private async setUrls(): Promise<void> {

    if (!this.configurationService.isReady) {

      await firstValueFrom(this.configurationService.whenReady$);
    }

    this._searchUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Search}`;
    this.isReady = true;
    this._urlsSetSource.next(true);
  }

}
