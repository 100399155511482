import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren, computed, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapArrowCounterclockwise, bootstrapArrowLeft, bootstrapArrowUp, bootstrapCheckLg, bootstrapTrash, bootstrapPlusLg, bootstrapXLg, bootstrapPen } from "@ng-icons/bootstrap-icons";
import { equalsImagePropOptions, IImageProp, ImageAssignment, ImagePropOptions } from 'projects/my-common/src/model';
import { Vector3InputComponent } from "../../../../shared/vector3-input/vector3-input.component";
import { PositionAdjustmentComponent } from "../../../../shared/position-adjustment/position-adjustment.component";
import { Subscription } from 'rxjs/internal/Subscription';
import { NGXLogger } from 'ngx-logger';
import { IStage } from 'src/app/core/model/showroom/showroom.model';
import { VenueService } from 'src/app/core/service/venue/venue.service';
import { firstValueFrom } from 'rxjs';
import { FloatingSliderComponent } from 'src/app/components/shared/floating-slider/floating-slider.component';
import { NumberInputScrollDirective } from 'src/app/shared/directives/number-input-scroll.directive';
import { TextEditorComponent } from "../../../../shared/text-editor/text-editor.component";
import { ActiconPosition } from 'projects/my-common/src';
import { CheckboxComponent } from "../../../../shared/checkbox/checkbox.component";
import { NoCommaPipe } from 'src/app/shared/pipes/no-comma.pipe';
import { ProgressBarComponent } from "../../../../shared/progress-bar/progress-bar.component";
import { ColorPickerModule } from 'ngx-color-picker';
import { ConfigureImageAssignmentComponent } from "../configure-image-assignment/configure-image-assignment.component";
import { ShowroomService } from 'src/app/core/service/showroom/showroom.service';

export const CONFIGURE_IMAGE_PROP_SIDEBAR_ID = 'e07bd2bc-b490-4c38-a8c6-31449c58cbf7';


/**
 * Configure the design and interactions of the specified ImageProp in the specified EventDesign.
 * We don't delete DesignImages because they might be referenced by other ImageProps.
 * To remove a DesignImage we delete the ImageAssignment.
 */
@Component({
  selector: 'app-configure-image-prop-sidebar',
  standalone: true,
  templateUrl: './configure-image-prop-sidebar.component.html',
  styleUrls: ['./configure-image-prop-sidebar.component.scss'],
  providers: [provideIcons({ bootstrapArrowCounterclockwise, bootstrapArrowLeft, bootstrapArrowUp, bootstrapCheckLg, bootstrapPen, bootstrapPlusLg, bootstrapTrash, bootstrapXLg })],
  imports: [CommonModule, ColorPickerModule, FloatingSliderComponent, NgIconComponent, NoCommaPipe, NumberInputScrollDirective, Vector3InputComponent, PositionAdjustmentComponent, TextEditorComponent,
    CheckboxComponent, ProgressBarComponent, ConfigureImageAssignmentComponent]
})
export class ConfigureImagePropSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  readonly acticonPosition = ActiconPosition;
  readonly controlAlignment = Object.values(ActiconPosition).filter(value => typeof value !== 'number');
  readonly sidebarId: string = CONFIGURE_IMAGE_PROP_SIDEBAR_ID;

  id = crypto.randomUUID();
  private _lock = 0;
  private readonly _subscriptions: Subscription[] = [];

  readonly closing = signal(false);
  readonly interactionDistanceInitializer = signal(0);
  readonly isOpen = signal(false);
  readonly localImagePropOptions = signal(new ImagePropOptions());
  //
  // Input initializers
  //
  readonly depthInitializer = signal(0);
  readonly marginInitializer = signal(0);
  readonly priceInitializer = signal(0);
  readonly uploadingProgress = signal(0);
  readonly uploadingFileName = signal('');

  /**
   * Setup for slider close on blur when user clicks away from this component.
   */
  @ViewChildren('configureImagePropSidebarComponent') elementRef!: QueryList<HTMLInputElement>;

  //
  // Inputs
  //
  @Input({ required: true }) set ImageProp(value: IImageProp) {

    this.venueService.setCurrentImageProp((value.id));
    this.setImagePropOptions();
  }
  readonly Stage = input.required<IStage>();

  //
  // Outputs
  //
  /**
   * Broadcast data changes as Event Design changes so that Showroom will apply aggregate updates to the stage
   */
  EventDesignChanged = output<number>();

  //
  // Selectors
  //
  readonly currentDesignImage = this.venueService.currentDesignImage;
  readonly isConfiguringDefault = computed(() => this.eventDesign().id == this.defaultEventDesign().id)
  readonly defaultDesignImages = this.venueService.defaultDesignImages;
  readonly defaultEventDesign = this.venueService.defaultEventDesign;
  readonly defaultImageAssignments = this.venueService.defaultImageAssignments;
  /**
   * The DesignImages in Default EventDesign that are assigned to ImageProp, ordered by assignment id
   */
  readonly defaultImageAssignmentsForProp = computed(() =>
    this.defaultImageAssignments().filter(ia => ia.imagePropId === this.imageProp().id)
      .sort((ia1, ia2) => ia1.id < ia2.id ? -1 : 1));
  readonly defaultImagePropOptions = this.venueService.defaultImagePropOptions;
  readonly designImages = this.venueService.designImages;
  readonly enableSave = computed(() => !equalsImagePropOptions(this.localImagePropOptions(), this.imagePropOptions()));
  readonly eventDesign = this.venueService.currentEventDesign;
  readonly imageAssignments = this.venueService.imageAssignments;
  /**
   * The DesignImages in EventDesign that are assigned to ImageProp, ordered by assignment id
   */
  readonly imageAssignmentsForProp = computed(() =>
    this.imageAssignments().filter(ia => ia.imagePropId === this.imageProp().id)
      .sort((ia1, ia2) => ia1.id - ia2.id));
  readonly imageNode = computed(() => this.Stage() && this.Stage().getImageNode ?
    this.Stage().getImageNode(this.imageProp().id) : undefined);
  readonly imageProp = this.venueService.currentImageProp;
  readonly imagePropOptions = this.venueService.imagePropOptions;
  readonly isLocked = computed(() => this.isLockedInDefault() || this.localImagePropOptions().isLocked);
  readonly isLockedInDefault = computed(() => this.defaultImagePropOptions().isLocked)
  readonly showroomUser = this.showroomService.showroomUser;


  constructor(
    private readonly logger: NGXLogger,
    private readonly sidebarService: SidebarService,
    private readonly showroomService: ShowroomService,
    private readonly venueService: VenueService) {

    sidebarService.add(this);
  }


  camelCaseToSpaced(camelCase: any): string {

    return camelCase.replace(/([a-z])([A-Z])/g, '$1 $2');
  }


  private applyImagePropOptions() {

    this.imageNode()?.setOptions(this.localImagePropOptions());
  }


  handleBlurDepth() {

    this.depthInitializer.set(this.localImagePropOptions().controlsZ);
  }


  handleBlurInteractionDistance() {

    this.interactionDistanceInitializer.set(this.localImagePropOptions().interactionDistance);
  }


  handleBlurMargin() {

    this.marginInitializer.set(this.localImagePropOptions().controlsMargin);
  }


  handleControlsDepthInput(event: any): void {

    const newDepthValue = Number(event.target.value);
    this.setControlsDepth(newDepthValue)
  }


  handleControlsMarginInput(event: any): void {

    const newMarginValue = Number(event.target.value);
    this.setControlsMargin(newMarginValue)
  }


  handleInteractionDistanceInput(interactionDistanceInputEvent: any) {

    const newInteractionDistance = Number(interactionDistanceInputEvent.target.value);

    this.localImagePropOptions.update(ipo => {

      ipo.interactionDistance = newInteractionDistance

      return new ImagePropOptions(ipo);
    });

    this.applyImagePropOptions();
  }


  handleSelectControlsAlignment(controlsAlignmentEvent: any): void {

    let selectedIndex = Number(controlsAlignmentEvent.target['options'].selectedIndex);
    this.logger.trace(`selected: ${ActiconPosition[selectedIndex]}`);

    this.localImagePropOptions.update(ipo => {

      ipo.controlsPosition = selectedIndex;

      return new ImagePropOptions(ipo);
    });

    this.applyImagePropOptions()
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);
    this._subscriptions.forEach(s => s.unsubscribe());

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  /**
   * Auto close is a little annoying on highly interactive, configuration type slide outs.
   */
  private readonly handleBlurCallback = this.onBlur.bind(this);
  onBlur() {

    // if (this.enableSave() || this.enableDesignImageSave() || this._handlingBlur) {

    //   return
    // };
    // this._handlingBlur = true;

    // const elementRef = (this.elementRef.first as any).nativeElement;
    // if (!elementRef) {
    //   this._handlingBlur = false;
    //   return;
    // }

    // // Timeout give cycle for focusIn/Out combination to complete
    // setTimeout(() => {
    //   if (!isChildElement(elementRef, document.activeElement)) {
    //     this.isOpen.set(false);
    //   }
    //   this._handlingBlur = false;
    // }, 1);
  }


  /**
   * Should we warn of unsaved changes before closing?
   * @returns 
   */
  onClose() {

    this.closing.set(true);
    setTimeout(() => {
      this.isOpen.set(false);
      this.closing.set(false);
    }, 200);
  }


  async onCreateAssignment(): Promise<void> {

    if (0 < this._lock++) {

      return;
    }

    const assignment = new ImageAssignment();
    assignment.imagePropId = this.imageProp().id;
    assignment.eventDesignId = this.eventDesign().id;
    assignment.name = this.imageProp().name;
    const newAssignment = await firstValueFrom(this.venueService.createImageAssignment(assignment));

    this.imageNode()?.upsertAssignment(newAssignment, this.designImages());
    this.venueService.setCurrentImageAssignment(newAssignment);
    this._lock = 0;
  }


  async onSave(): Promise<void> {

    if (0 < this._lock++) {

      return;
    }

    const imagePropOptions = this.localImagePropOptions();

    imagePropOptions.eventDesignId = this.eventDesign().id;
    imagePropOptions.imagePropId = this.imageProp().id;
    const updatedOptions = await firstValueFrom(this.venueService.upsertImagePropOptions(imagePropOptions));
    this.localImagePropOptions.set(updatedOptions);

    this._lock = 0;
  }


  onScrollToPosition(id: string): void {

    const target = document.getElementById(id);
    const scrollContainer = document.getElementById(this.id);
    if (target && scrollContainer) {

      const count = target.offsetTop - scrollContainer.scrollTop - 85;
      scrollContainer.scrollBy({ top: count, left: 0, behavior: 'smooth' });
    }
  }


  onSelectBackgroundColor(hexColor: string) {

    this.localImagePropOptions.update(ipo => {

      ipo.backgroundColor = hexColor;

      return new ImagePropOptions(ipo);
    });

    this.applyImagePropOptions();
  }


  onToggleLockProp(): void {

    this.localImagePropOptions.update(ipo => {

      ipo.isLocked = !ipo.isLocked;

      return new ImagePropOptions(ipo);
    });
    this.applyImagePropOptions();
  }


  onUndo() {

    if (0 < this._lock++) {

      return;
    }

    this.localImagePropOptions.set(new ImagePropOptions(this.imagePropOptions()));
    this.marginInitializer.set(this.imagePropOptions().controlsMargin);
    this.depthInitializer.set(this.imagePropOptions().controlsZ);

    this.imageNode()?.setOptions(this.imagePropOptions());
    this._lock = 0;
  }


  setControlsDepth(newControlsDepth: number): void {

    this.localImagePropOptions.update(ipo => {

      ipo.controlsZ = newControlsDepth;

      return new ImagePropOptions(ipo);
    });

    this.applyImagePropOptions();
  }


  setControlsMargin(newControlsMargin: number): void {

    this.localImagePropOptions.update(ipo => {

      ipo.controlsMargin = newControlsMargin;

      return new ImagePropOptions(ipo);
    });

    this.applyImagePropOptions();
  }


  private setImagePropOptions() {

    this.localImagePropOptions.set(new ImagePropOptions(this.imagePropOptions()));
    this.marginInitializer.set(this.imagePropOptions().controlsMargin);
    this.depthInitializer.set(this.imagePropOptions().controlsZ);
    this.interactionDistanceInitializer.set(this.imagePropOptions().interactionDistance);

    this.applyImagePropOptions()
  }


}
