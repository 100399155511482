<div
    class="w-full rounded-lg group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
    <div class="w-full max-h-80 overflow-hidden">
        <video #video [id]="id" aria-hidden="true" class="w-full max-h-80 object-scale-down" loop controls
            crossorigin="anonymous">
            <source src=""> Your browser does not support the video tag.
        </video>
    </div>
    <div class="absolute inset-0 px-4 pt-20 -translate-y-16 group-hover:hidden">
        <div class="italic w-full overflow-hidden whitespace-nowrap overflow-ellipsis group-hover:hidden">
            {{designVideo().uploadFileName}}
        </div>
    </div>
    <div
        class="absolute inset-0 flex flex-row justify-between -translate-y-full overflow-hidden items-center px-4 pt-20 transition-all duration-500 group-hover:-translate-y-16 bg-gradient-to-b from-zinc-700 via-transparent to-zinc-900 group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70">
        <div class="w-full overflow-hidden whitespace-nowrap">
            <div class="italic w-full overflow-hidden whitespace-nowrap overflow-ellipsis">{{designVideo().uploadFileName}}
            </div>
            <div class="italic w-full overflow-hidden whitespace-nowrap overflow-ellipsis">
                {{importableDesignVideo().venueName}}
            </div>
            <div class="italic w-full overflow-hidden whitespace-nowrap overflow-ellipsis">
                {{importableDesignVideo().venueEventName}}</div>
            <div class="italic w-full pb-4 overflow-hidden whitespace-nowrap overflow-ellipsis">
                {{importableDesignVideo().eventDesignName}}</div>
        </div>
        <div class="cursor-pointer group/inner relative">
            <button (click)="onAddToDesign()"
                class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                <label class="flex justify-center">
                    <ng-icon class="cursor-pointer" size="22" name="bootstrapCaretLeft" />
                </label>
            </button>
        </div>
    </div>
</div>