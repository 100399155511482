import { Component, OnDestroy, QueryList, ViewChildren, signal } from '@angular/core';
import { FloatingSliderComponent, SliderCurrentValue } from "../../../shared/floating-slider/floating-slider.component";
import { ISlider, SliderService } from 'src/app/core/service/ui/slider/slider.service';
import { NGXLogger } from 'ngx-logger';
import { isChildElement, timeout } from 'projects/my-common/src';

export const SHOWROOM_COMMON_SLIDER_ID: string = 'SHOWROOM_COMMON_SLIDER_ID';

@Component({
  selector: 'app-common-slider',
  standalone: true,
  templateUrl: './common-slider.component.html',
  styleUrl: './common-slider.component.scss',
  imports: [FloatingSliderComponent]
})
export class CommonSliderComponent implements ISlider, OnDestroy {

  sliderId = SHOWROOM_COMMON_SLIDER_ID;

  readonly isOpen = signal(false);
  readonly label = signal('');
  readonly onChange = signal((value: number) => { });
  readonly onClose = signal(() => { });
  readonly onBlur = signal(() => { });
  readonly multiplier = signal(0);
  readonly currentValue = signal(<SliderCurrentValue>{ value: 0 })

  @ViewChildren('commonSliderComponentSidebar') elementRef!: QueryList<HTMLInputElement>;


  constructor(private readonly _sliderService: SliderService,
    private readonly _logger: NGXLogger) {

    _sliderService.add(this);
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.handleBlur.bind(this);
  /**
   * Slider should close when focus changes away from sliderBasedInputs
   * but not when the focus changes to the slider itself. 
   */
  handleBlur() {

    if (this._handlingBlur) {

      return;
    }
    this._handlingBlur = true;

    const commonSlider = (this.elementRef?.first as any).nativeElement;
    if (!commonSlider) {

      this._logger.error(`this commonSlider elementRef not found`);
      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {
      
      if (!isChildElement(commonSlider, document.activeElement)) {

        this.onBlur()();
      }
      this._handlingBlur = false;
    }, 1);
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this._sliderService.remove(this);

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  async reset(): Promise<void> {

    this.onChange.set((value: number) => { });
    this.onClose.set(() => { });
    this.onBlur.set(() => { });
    // Reset values
    //this.isOpen.set(false);
    this.label.set('');

    // Needs a cycle for updates to flush through before binding to them again.
    await timeout();
  }


  setCurrentValue(value: number): void {

    this.currentValue.set({ value: value });
  }

}
