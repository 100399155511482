import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import { HorizontalClipMode, OffsetBasedHorizontalClipModes, OffsetBasedVerticalClipModes, VerticalClipMode } from "../scene/image-mask";
import { TextureManager } from "../my-three/texture-manager";
import { DataTexture, LinearSRGBColorSpace } from "three";

export enum MaskLoaderSource { NONE, ADJUSTMENT, PROP }

const DEFAULT_WIDTH = 512;
const DEFAULT_HEIGHT = 512;

export type MaskLoaderState = {
    horizontalClipMode: HorizontalClipMode;
    /**
     * Value from 0 - 1 representing the percentage of the overall height.
     */
    clipHeight: number,
    /**
     * Value from 0 - 1 representing the percentage of the overall width.
     */
    clipWidth: number,
    enabled: boolean;
    /**
     * Defines the mask dimension. Changing requires rebuilding the mask which is expensive.
     * Overriden by custom mask if defined.
     */
    maskWidth: number,
    /**
     * Defines the mask dimension. Changing requires rebuilding the mask which is expensive.
     * Overridden by custom mask if defined.
     */
    maskHeight: number,
    /**
     * Optional, custom mask uri. If defined, the mask width and height will override maskWidth and maskHeight.
     */
    source?: string;
    maskOrigin: MaskLoaderSource;
    suppressCustomMask: boolean;
    verticalClipMode: VerticalClipMode;
    /**
     * Value from 0 - 1 representing the percentage of the overall width.
     */
    xOffset: number,
    /**
     * Value from 0 - 1 representing the percentage of the overall height.
     */
    yOffset: number
}


export class MaskLoaderComponent extends MyOptyxComponent {

    private readonly _logger = getLogger();
    private _alphaMap?: DataTexture;
    private _data: Uint8ClampedArray | null = null;
    private _imageData?: ImageData;
    private _updatingAlphaMap = false;
    private _rerunUpdateAlphaMap = false;
    private _width = DEFAULT_WIDTH;
    private _height = DEFAULT_HEIGHT;
    private _clipHeight = 0;
    private _clipWidth = 0;
    private _xOffset = 0;
    private _yOffset = 0;

    protected override state: MaskLoaderState = {
        clipHeight: 0,
        clipWidth: 0,
        enabled: false,
        horizontalClipMode: HorizontalClipMode.NONE,
        maskWidth: DEFAULT_WIDTH,
        maskHeight: DEFAULT_HEIGHT,
        maskOrigin: MaskLoaderSource.NONE,
        source: undefined,
        suppressCustomMask: false,
        verticalClipMode: VerticalClipMode.NONE,
        xOffset: 0,
        yOffset: 0
    };
    // Pending state initialized to match current state.
    override pendingState: MaskLoaderState = {
        clipHeight: 0,
        clipWidth: 0,
        enabled: false,
        horizontalClipMode: HorizontalClipMode.NONE,
        maskWidth: DEFAULT_WIDTH,
        maskHeight: DEFAULT_HEIGHT,
        maskOrigin: MaskLoaderSource.NONE,
        source: undefined,
        suppressCustomMask: false,
        verticalClipMode: VerticalClipMode.NONE,
        xOffset: 0,
        yOffset: 0
    };

    // Events
    private readonly _alphaMapUpdated = new Subject<DataTexture | undefined>();
    readonly alphaMapUpdated$ = this._alphaMapUpdated.asObservable();


    constructor(destroyRef: DestroyRef,
        private readonly textureManager: TextureManager) {
        super(destroyRef);
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {        

        //if (this.pendingStateChanges.find(psc => psc === 'urls')) { }
        // If custom mask src changed then prioritize its work above other changes.
        if (this.state.source !== this.pendingState.source) {

            // Was mask removed
            if (!this.pendingState.source || 1 > this.pendingState.source.length) {

                this._imageData = undefined;
            } else {

                // Mask was added or changed
                this.tryLoadMask(this.pendingState);
                return;
            }
        } else if (!this._data || 1 > this._data.length
            || this.state.maskWidth !== this.pendingState.maskWidth
            || this.state.maskHeight !== this.pendingState.maskHeight) {

            this._width = this.pendingState.maskWidth;
            this._height = this.pendingState.maskHeight;
            this.initializeAlphaMap();
        }

        if (this.state.clipHeight !== this.pendingState.clipHeight
            || this.state.clipWidth !== this.pendingState.clipWidth
            || this.state.xOffset !== this.pendingState.xOffset
            || this.state.yOffset !== this.pendingState.yOffset
            || this.state.horizontalClipMode !== this.pendingState.horizontalClipMode
            || this.state.verticalClipMode !== this.pendingState.verticalClipMode) {

            this.setMaskDimensions(this.pendingState);
        }

        this.updateAlphaMap(this.pendingState);
    }


    private disposeAlphaMap(): void {

        if (this._alphaMap) {

            const temp = this._alphaMap;
            this._alphaMapUpdated.next(undefined);

            temp.dispose();
        }
    }


    getAlphaMap(): DataTexture | undefined {

        return this._alphaMap;
    }


    override getState(): MaskLoaderState {

        // Might want to deepCopy depending on properties.
        return this.state;
    }


    /**
     * Initialize the data array used as the source for the DataTexture (alphaMap).
     */
    private initializeAlphaMap(): void {

        if (!this.pendingState.enabled) {

            return;
        }

        const MAP_SIZE = this._width * this._height;

        if (this._data && 4 * MAP_SIZE === this._data.length && this._alphaMap) {

            return
        };

        this.disposeAlphaMap();

        this._data = new Uint8ClampedArray(4 * MAP_SIZE);

        // Initialize alpha bit
        for (let i = 0; i < MAP_SIZE; i++) {

            this._data[(i * 4) + 4] = 255;
        }

        this._alphaMap = new this.textureManager.three.DataTexture(this._data, this._width, this._height);
        this._alphaMap.colorSpace = LinearSRGBColorSpace;
    }


    override onDestroy(): void {

        this.disposeAlphaMap();
        this._imageData = undefined;
    }


    // If overriding be sure to call base method.
    override init(): MaskLoaderComponent {
        super.init();

        return this;
    }


    /**
     * Calculate and store actual width, height and offset dimensions from the percentages supplied as inputs.
     */
    setMaskDimensions(state: MaskLoaderState): void {

        if (HorizontalClipMode.NONE !== state.horizontalClipMode) {

            this._clipWidth = Math.round(this._width * state.clipWidth);
            if (OffsetBasedHorizontalClipModes.has(state.horizontalClipMode)) {

                this._xOffset = Math.round(this._width * state.xOffset);
            }
        }

        if (VerticalClipMode.NONE !== state.verticalClipMode) {

            this._clipHeight = Math.round(this._height * state.clipHeight);
            if (OffsetBasedVerticalClipModes.has(state.verticalClipMode)) {

                this._yOffset = Math.round(this._height * (1 - state.yOffset));
            }
        }
    }


    private tryLoadMask(state: MaskLoaderState): void {

        if (!state.source || 1 > state.source.trim().length) {

            return;
        }

        const that = this;

        this.textureManager.loadMask(
            // resource URL
            state.source,
            // onLoad callback
            function (imageData: ImageData) {

                that._imageData = imageData;
                that._width = imageData.width;
                that._height = imageData.height;

                that.initializeAlphaMap();
                that.setMaskDimensions(state);

                that.updateAlphaMap(state);
            },
            // onError callback
            function (err: any) {
                
                that._logger.error(`error loading mask`);
            }
        );
    }


    /**
     * Data driven mask creation.
     * For each pixel in MAP_SIZE, evaluate and generate black/white pixel based upon mask definition.
     * @returns 
     */
    private updateAlphaMap(state: MaskLoaderState): void {

        // Handle concurrency
        if (this._updatingAlphaMap) {

            this._rerunUpdateAlphaMap = true;
            return;
        }
        this._updatingAlphaMap = true;

        const CLIP_HEIGHT = this._clipHeight;
        const CLIP_WIDTH = this._clipWidth;
        const MASK_HEIGHT = this._height;
        const MASK_WIDTH = this._width;
        const MAP_SIZE = MASK_WIDTH * MASK_HEIGHT;
        const X_OFFSET = this._xOffset;
        const Y_OFFSET = this._yOffset;

        if (!this._data) {

            this._updatingAlphaMap = false;
            if (this._rerunUpdateAlphaMap) {

                this._rerunUpdateAlphaMap = false;
                this.updateAlphaMap;
            }

            return;
        }

        if (4 * MAP_SIZE !== this._data.length) {

            this._updatingAlphaMap = false;
            if (this._rerunUpdateAlphaMap) {

                this._rerunUpdateAlphaMap = false;
                this.updateAlphaMap;
            } else {

                this._logger.error('--> Incorrect data size.')
            }

            return;
        }

        // if (X_OFFSET === 115 && this.inputs.horizontalClipMode === HorizontalClipMode.RIGHT) {
        //   this._logger.error()
        // }

        let i = 0, row = 0, column = 0, blackOrWhite = 0;

        for (; i < MAP_SIZE; i++) {

            row = Math.floor(i / MASK_WIDTH);
            column = i % MASK_WIDTH;
            blackOrWhite = 255;   // Default to white i.e. show

            // Check horizontal bounds (from left to right)
            switch (state.horizontalClipMode) {

                case HorizontalClipMode.LEFT:
                    if (column < CLIP_WIDTH) blackOrWhite = 0;
                    break;
                case HorizontalClipMode.CENTER:
                    if (0 !== CLIP_WIDTH && column > X_OFFSET && column < (X_OFFSET + CLIP_WIDTH)) blackOrWhite = 0;
                    break;
                case HorizontalClipMode.RIGHT:
                    if (column > X_OFFSET) blackOrWhite = 0;
                    break;
                case HorizontalClipMode.EDGES:
                    if (0 === CLIP_WIDTH || column < X_OFFSET || column > (X_OFFSET + CLIP_WIDTH)) blackOrWhite = 0;
                    break;
            }

            // Check vertical bounds (from bottom up)
            switch (state.verticalClipMode) {

                case VerticalClipMode.TOP:      // Behaves like Horizontal Right
                    if (row > Y_OFFSET) blackOrWhite = 0;
                    break;
                case VerticalClipMode.CENTER:
                    if (0 !== CLIP_HEIGHT && row < Y_OFFSET && row > (Y_OFFSET - CLIP_HEIGHT)) blackOrWhite = 0;
                    break;
                case VerticalClipMode.BOTTOM:   // Behaves like Horizontal left
                    if (row < CLIP_HEIGHT) blackOrWhite = 0;
                    break;
                case VerticalClipMode.EDGES:
                    if (0 === CLIP_HEIGHT || row > Y_OFFSET || row < (Y_OFFSET - CLIP_HEIGHT)) blackOrWhite = 0;
                    break;
            }

            const stride = i * 4;
            // When procedural mask is set to 0 (hide) it takes precendence.
            if (0 === blackOrWhite || !this._imageData || state.suppressCustomMask) {

                this._data[stride] = this._data[stride + 1] = this._data[stride + 2] = blackOrWhite;
            } else {

                // We have an image mask and the procedural mask is set to 255 (show). Apply image mask.
                this._data[stride] = this._imageData.data[stride];
                this._data[stride + 1] = this._imageData.data[stride + 1];
                this._data[stride + 2] = this._imageData.data[stride + 2];
            }
        }

        if (!this._alphaMap) {

            this._alphaMap = new this.textureManager.three.DataTexture(this._data, MASK_WIDTH, MASK_HEIGHT);
        }
        this._alphaMap.needsUpdate = true;

        // Handle concurrency
        this._updatingAlphaMap = false;
        if (this._rerunUpdateAlphaMap) {

            this._rerunUpdateAlphaMap = false;
            this.updateAlphaMap(state);
        } else {

            this._alphaMapUpdated.next(this._alphaMap);
        }
    }

}