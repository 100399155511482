import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import { AlwaysStencilFunc, Color, ColorRepresentation, FrontSide, GreaterEqualStencilFunc, GreaterStencilFunc, Group, LineBasicMaterial, LineSegments, Material, Mesh, MeshBasicMaterial, MeshStandardMaterial, NormalBlending, NotEqualStencilFunc, Object3D, ReplaceStencilOp, Side, Texture } from "three";
import { Vector3Obj, vector3OneObj, vector3ZeroObj } from "../util/utils";
import * as THREE from 'three';
import { colorFromHexString, opacityFromHexString } from "../my-three/utils";
import { ClippingPlaneType, IClippingPlane, ClippingPlaneAssignment, PropType } from "../model";
import { EDGES_NAME } from "./plane-renderer.component";
import { EcsWorld } from "../ecs";
import { deepCopy } from "projects/mp-core/src/lib/util";

export const VIDEO_RENDERER_DEFAUT_RENDER_ORDER = 899;

export type VideoRendererState = {
    adminMode: boolean
    alphaMap?: Texture
    aspect: number
    backingColor: string
    clippingPlanes: IClippingPlane[]
    clippingAssignments: ClippingPlaneAssignment[]
    color: ColorRepresentation
    disableDepth: boolean
    enableInteraction: boolean
    logMaterial: boolean
    opacity: number
    polygonOffset: boolean
    polygonOffsetFactor: number
    polygonOffsetUnits: number
    position: Vector3Obj
    renderOrder: number
    rotation: Vector3Obj
    scale: Vector3Obj
    selected: boolean
    /**
     * Defaults to FrontSide (0) to reduce the likelihood of planes showing through walls.
     */
    side: Side
    simulateDepth: boolean
    snapshotAspect: number
    snapshotTexture?: Texture
    snapshotVisible: boolean
    stencilRef: number
    transparent: boolean
    videoAspect: number
    videoTexture?: Texture
    videoVisible: boolean
    visible: boolean
}


export class VideoRendererComponent extends MyOptyxComponent {

    protected override state: VideoRendererState = {
        adminMode: false,
        alphaMap: undefined,
        aspect: 1,
        backingColor: '#000000FF',
        clippingPlanes: [],
        clippingAssignments: [],
        color: 0xffffff,
        disableDepth: false,
        enableInteraction: true,
        logMaterial: false,
        opacity: 1,
        polygonOffset: false,
        polygonOffsetFactor: 0,
        polygonOffsetUnits: 0,
        position: vector3ZeroObj,
        renderOrder: VIDEO_RENDERER_DEFAUT_RENDER_ORDER,
        rotation: vector3ZeroObj,
        scale: vector3OneObj,
        selected: false,
        side: FrontSide,
        simulateDepth: false,
        snapshotAspect: 0,
        snapshotTexture: undefined,
        snapshotVisible: true,
        stencilRef: 1,
        videoTexture: undefined,
        transparent: true,
        videoAspect: 0,
        videoVisible: false,
        visible: true,
    };

    // Pending state initialized to match current state.
    override pendingState: VideoRendererState = {
        adminMode: false,
        alphaMap: undefined,
        aspect: 1,
        backingColor: '#000000FF',
        clippingPlanes: [],
        clippingAssignments: [],
        color: 0xffffff,
        disableDepth: false,
        enableInteraction: true,
        logMaterial: false,
        opacity: 1,
        polygonOffset: false,
        polygonOffsetFactor: 0,
        polygonOffsetUnits: 0,
        position: vector3ZeroObj,
        renderOrder: VIDEO_RENDERER_DEFAUT_RENDER_ORDER,
        rotation: vector3ZeroObj,
        scale: vector3OneObj,
        selected: false,
        side: FrontSide,
        simulateDepth: false,
        snapshotAspect: 0,
        snapshotTexture: undefined,
        snapshotVisible: true,
        stencilRef: 1,
        transparent: true,
        videoAspect: 0,
        videoTexture: undefined,
        videoVisible: false,
        visible: true,
    };


    // Events
    private _clippingPlanesUpdatedSource = new Subject<boolean>();
    clippingPlanesUpdatedUpdated$ = this._clippingPlanesUpdatedSource.asObservable();
    private readonly _groupCreated = new Subject<Group>();
    readonly groupCreated$ = this._groupCreated.asObservable();
    private _textureUpdatedSource = new Subject<Texture | undefined>();
    textureUpdated$ = this._textureUpdatedSource.asObservable();
    private _enableInteractionSource = new Subject<boolean>();
    enableInteractionUpdated$ = this._enableInteractionSource.asObservable();

    private _edges?: LineSegments;
    private lineOpacity = 1;
    private _lineColor = 0xff0000;
    private _logger = getLogger();
    private _backingPlane?: Mesh;
    private _backingPlaneMaterial?: MeshBasicMaterial;
    private _planeRendererGroup!: Group;
    private _snapshotPlane!: Mesh;
    private _snapshotPlaneMaterial?: MeshStandardMaterial;
    private _stencilRef = 1;
    private _videoPlane!: Mesh;
    private _videoPlaneMaterial?: MeshStandardMaterial;


    constructor(destroyRef: DestroyRef,
        private readonly three: typeof THREE,
        private readonly world: EcsWorld) {
        super(destroyRef);
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     * if (this.pendingStateChanges.find(psc => psc === 'urls')) {}
     */
    protected override applyPendingState(): void {

        // Changes that require recreation of backing plane
        if (this.state.aspect !== this.pendingState.aspect) {

            this.createBackingPlane();
        }

        // Changes that require recreation of planes
        if (this.state.aspect !== this.pendingState.aspect
            || this.state.videoAspect !== this.pendingState.videoAspect
            || this.state.snapshotAspect !== this.pendingState.snapshotAspect) {   // Recreating object is the only way to apply aspect changes

            this.createDisplayPlanes();
        }

        if (this.state.adminMode !== this.pendingState.adminMode
            || this.state.selected !== this.pendingState.selected) {

            if (this.pendingState.adminMode) {

                this.createEdges();
            } else {

                this.disposeEdges();
            }
        }

        this.updatePlaneMaterial();

        this.createClippingPlanes();

        if (this.state.videoTexture !== this.pendingState.videoTexture) {

            this._textureUpdatedSource.next(this.pendingState.videoTexture);
        }

        this._planeRendererGroup.visible = this.pendingState.visible;
        this._enableInteractionSource.next(this.pendingState.enableInteraction || this.pendingState.adminMode);
    }


    /**
     * The backing plane supports transparency along with color selection.
     * The backing plane is always the full size of the plane renderer.
     * three.js renders opaque objects first, followed by transparent objects.
     * This causes the backing plane, which supports transparency, to be rendered in front of the display pane.
     * To mitigate that, the display plane is positioned slightly in front of the backing plane and writes to the stencil buffer.
     * The backing plane will not render to the stencil buffers set by the display plane.
     * @param state 
     */
    private createBackingPlane() {

        this.disposeBackingPlane();

        const backingPlaneRendererGeometry = new this.three.PlaneGeometry(1.0, 1.0 / this.pendingState.aspect);
        this._backingPlaneMaterial = new this.three.MeshBasicMaterial({
            alphaTest: .01,
            color: colorFromHexString(this.pendingState.backingColor),
            opacity: this.pendingState.opacity != 1 ? this.pendingState.opacity : opacityFromHexString(this.pendingState.backingColor),
            transparent: true,
            depthWrite: false,
            stencilFunc: NotEqualStencilFunc,
            stencilRef: this.pendingState.stencilRef,
            stencilWrite: true,
            stencilZPass: ReplaceStencilOp
        });
        this._backingPlane = new this.three.Mesh(backingPlaneRendererGeometry, this._backingPlaneMaterial);
        this._backingPlane.castShadow = false;

        this._planeRendererGroup.add(this._backingPlane);
    }


    /**
     * Clipping planes are affixed to the front of the plane to provide flexible clipping options.
     * Square, triangle and circle shapes are supported 
     * As 2D planes intended to remain infront of the plane renderer, they can only be rotated around the z axis.
     * They can also be scaled to help acieve the desired clipping goals.
     * Clipping Id (0 - 255) is configurable to differentiate and prevent clipping other plane renderers behind this one.
     */
    private createClippingPlanes(): void {

        if (1 > this.pendingState.clippingPlanes.length) {

            this.disposeClippingPlanes();
            return;
        }

        // Remove Clipping Planes that are no longer in the Clipping Planes list
        for (const child of this._planeRendererGroup.children) {

            if (child === this._backingPlane || child === this._videoPlane || child === this._snapshotPlane) {

                continue;
            }

            // If Clipping Plane is no longer in the list then dispose it
            if (0 > this.pendingState.clippingPlanes.findIndex(cp => cp.id === Number(child.name))) {

                this.disposeClippingPlaneGroup(child.name);
            }
        }

        let existingClippingPlane: Object3D<THREE.Object3DEventMap> | undefined;
        let newClippingPlanesAdded = false;
        // Add Clipping Planes that are not created yet
        for (const clippingPlane of this.pendingState.clippingPlanes) {

            // If Clipping Plane is already created then update world values and continue
            existingClippingPlane = this._planeRendererGroup.children.find(c => c.name === `${clippingPlane.id}`);
            if (existingClippingPlane) {

                this.world.upsertClippingPlane({
                    properties: clippingPlane,
                    plane: existingClippingPlane
                }, PropType.VIDEO);
                continue;
            }

            let geometry: any;
            let vertices: Float32Array;
            switch (clippingPlane.planeType) {
                case ClippingPlaneType.Square:
                    geometry = new this.three.PlaneGeometry(1.0, 1.0);
                    break;
                case ClippingPlaneType.Triangle:
                    vertices = new Float32Array([
                        -0.5, -0.5, 0.0, // Vertex 1 (x, y, z)
                        0.5, -0.5, 0.0, // Vertex 2 (x, y, z)
                        0.0, 0.5, 0.0, // Vertex 3 (x, y, z)
                    ]);
                    geometry = new this.three.BufferGeometry();
                    // BufferAttribute not supported by Matterport need to use the older Float32BufferAttribute
                    geometry.setAttribute('position', new this.three.Float32BufferAttribute(vertices, 3));
                    break;
                case ClippingPlaneType.Circle:
                    geometry = new this.three.CircleGeometry(0.2);
                    break;
                case ClippingPlaneType.RightTriangle:
                    vertices = new Float32Array([
                        -0.5, -0.5, 0.0, // Vertex 1 (x, y, z)
                        0.5, -0.5, 0.0, // Vertex 2 (x, y, z)
                        0.5, 0.5, 0.0, // Vertex 3 (x, y, z)
                    ]);
                    geometry = new this.three.BufferGeometry();
                    // BufferAttribute not supported by Matterport need to use the older Float32BufferAttribute
                    geometry.setAttribute('position', new this.three.Float32BufferAttribute(vertices, 3));
                    break;
            }

            const planeMaterial = new MeshBasicMaterial({

                // Stencil buffer: https://www.youtube.com/watch?v=X93GxW84t84
                stencilFunc: GreaterEqualStencilFunc,
                stencilRef: this.pendingState.stencilRef,
                stencilWrite: true,
                stencilZPass: THREE.ReplaceStencilOp,

                // Object occlusion: https://www.youtube.com/watch?v=tv_VTWlpE0w&t=52s
                colorWrite: false,

            });
            const clippingPlaneMesh = new this.three.Mesh(geometry, planeMaterial);
            clippingPlaneMesh.name = `${clippingPlane.id}`;

            // Place within Group
            const clippingPlaneGroup = new this.three.Group();
            clippingPlaneGroup.name = `${clippingPlane.id}`;
            clippingPlaneGroup.scale.set(0, 0, 0);
            clippingPlaneGroup.add(clippingPlaneMesh);

            this._planeRendererGroup?.add(clippingPlaneGroup);
            this.world.upsertClippingPlane({
                properties: clippingPlane,
                plane: clippingPlaneGroup
            }, PropType.VIDEO)

            newClippingPlanesAdded = true;
        }

        if (newClippingPlanesAdded && this.pendingState.adminMode) {

            this.createEdges();
        }
    }


    /**
     * The image plane is always 1 x 1.
     * Aspect defines its base "Unassigned" shape.
     * ImageAspect (if provided) will alter the shape.
     * See scale for adjustments based upon changes in aspect.
     * @returns 
     */
    private createDisplayPlanes(): void {

        // Clear old references
        this.disposeDisplayPlanes();

        this.createSnapshotPlane();

        //this._logger.error(`inputs.aspect: ${this.inputs.aspect}`);
        //this._logger.trace(`inputs.imageAspect: ${this.inputs.imageAspect}`);
        // In Stage Admin mode we always want to see the Props natural shape.
        const adjustedAspect = 0 < this.pendingState.videoAspect ? this.pendingState.videoAspect : this.pendingState.aspect;
        //this._logger.trace(`selected aspect: ${aspect}`);
        const planeRendererGeometry = new this.three.PlaneGeometry(1.0, 1.0 / adjustedAspect);

        //this._logger.error(`Backing plane aspect: ${1.0 / this.pendingState.aspect}, Video display aspect: ${1.0 / adjustedAspect}`);

        this._videoPlaneMaterial = new this.three.MeshStandardMaterial({

            //blendColor: new Color(0x000000),
            blending: NormalBlending,
            displacementBias: 0,
            displacementMap: null,
            displacementScale: 1,
            dithering: false,
            emissive: new Color(0x000000),
            emissiveIntensity: 1,
            emissiveMap: null,
            envMap: null,
            envMapIntensity: 1,
            flatShading: false,
            fog: true,
            forceSinglePass: false,
            lightMap: null,
            lightMapIntensity: 1,
            metalness: 0,
            metalnessMap: null,
            normalMap: null,
            premultipliedAlpha: false,
            roughness: 0.5,
            roughnessMap: null,
            shadowSide: 0,
            side: this.pendingState.side,

            depthWrite: true, // If false, renderOrder value determines who's in front, else renderer depth determine who's in front
            //stencilFunc: AlwaysStencilFunc,     // works
            //stencilFunc: NotEqualStencilFunc,   // works
            //stencilFunc: LessStencilFunc,       // Doesn't work
            //stencilFunc: LessEqualStencilFunc,       // Doesn't work
            stencilFunc: GreaterStencilFunc,       // Works because stencil ref is greater than 0
            stencilRef: this.pendingState.stencilRef,
            stencilWrite: true,
            stencilZPass: THREE.ReplaceStencilOp,

            toneMapped: true,
            color: this.pendingState.color,
            transparent: this.pendingState.opacity !== 1,    // this.inputs.transparent,
            map: this.pendingState.videoTexture ?? null,
            opacity: this.pendingState.opacity,
            polygonOffset: this.pendingState.polygonOffset,
            polygonOffsetFactor: this.pendingState.polygonOffsetFactor,
            polygonOffsetUnits: this.pendingState.polygonOffsetUnits,
            alphaTest: .01,
            visible: (this.pendingState.visible && this.pendingState.videoVisible)
        });

        this._videoPlane = new this.three.Mesh(planeRendererGeometry, this._videoPlaneMaterial);
        this._planeRendererGroup.add(this._videoPlane);

        //this._videoPlane.renderOrder = 900
        this._planeRendererGroup.traverse((node) => {
            node.renderOrder = 799;
        });

        this._groupCreated.next(this._planeRendererGroup)
    }


    private createEdges(): void {

        if (this._backingPlane) {

            if (0 > this._backingPlane.children.findIndex(c => EDGES_NAME === c.name)) {

                // Add a border of lines
                const edgesGeometry = new this.three.WireframeGeometry(this._backingPlane.geometry);
                const edges = new this.three.LineSegments(edgesGeometry);
                edges.name = EDGES_NAME;
                //(edges.material as LineBasicMaterial).transparent = true;
                //(edges.material as LineBasicMaterial).depthTest = false;
                //(edges.material as any).depthTest = false
                //     , new this.three.LineBasicMaterial({
                //     transparent: true,
                //     color: this._lineColor,
                //     linewidth: 1,
                //     opacity: this.lineOpacity
                // }));
                // Make child of plan so transforms are applied.
                this._backingPlane.add(edges);
            }
        }

        //
        // Creating edges around the ALL clipping planes in the scene causes raycasting and selection to become unstable.
        // Only display clipping plane edges if prop selected.
        //
        if (this.pendingState.selected) {
            // Each ClippingPlane assignment has a group added to the plane renderer group
            // That group holds the clipping plane and it's edges.
            for (const clippingPlaneGroup of this._planeRendererGroup.children) {

                // Skip the backing plane, display plane and clipping plane groups that already have edges.
                if (clippingPlaneGroup === this._backingPlane
                    || clippingPlaneGroup === this._videoPlane
                    || clippingPlaneGroup === this._snapshotPlane
                    || 1 < clippingPlaneGroup.children.length) {

                    continue;
                }

                const clippingPlaneMesh = clippingPlaneGroup.children.find(c => clippingPlaneGroup.name === c.name) as Mesh;
                if (clippingPlaneMesh) {

                    const edgesGeometry = new this.three.WireframeGeometry(clippingPlaneMesh.geometry);
                    const edges = new this.three.LineSegments(edgesGeometry);
                    (edges.material as LineBasicMaterial).color = new Color(Color.NAMES.green);
                    (edges.material as LineBasicMaterial).needsUpdate = true;
                    edges.name = `${EDGES_NAME}${clippingPlaneGroup.name}`;

                    clippingPlaneGroup.add(edges);
                }
            }
        } else {

            this.disposeClippingPlaneEdges();
        }
    }


    private createSnapshotPlane() {

        //this._logger.error(`inputs.aspect: ${this.inputs.aspect}`);
        //this._logger.trace(`inputs.imageAspect: ${this.inputs.imageAspect}`);
        // In Stage Admin mode we always want to see the Props natural shape.
        const snapshotAdjustedAspect = 0 < this.pendingState.snapshotAspect ? this.pendingState.snapshotAspect : this.pendingState.aspect;
        //this._logger.trace(`selected aspect: ${aspect}`);
        const snapshotPlaneRendererGeometry = new this.three.PlaneGeometry(1.0, 1.0 / snapshotAdjustedAspect);

        this._snapshotPlaneMaterial = new this.three.MeshStandardMaterial({

            //blendColor: new Color(0x000000),
            blending: NormalBlending,
            displacementBias: 0,
            displacementMap: null,
            displacementScale: 1,
            dithering: false,
            emissive: new Color(0x000000),
            emissiveIntensity: 1,
            emissiveMap: null,
            envMap: null,
            envMapIntensity: 1,
            flatShading: false,
            fog: true,
            forceSinglePass: false,
            lightMap: null,
            lightMapIntensity: 1,
            metalness: 0,
            metalnessMap: null,
            normalMap: null,
            premultipliedAlpha: false,
            roughness: 0.5,
            roughnessMap: null,
            shadowSide: 0,
            side: this.pendingState.side,

            depthWrite: true, // If false, renderOrder value determines who's in front, else renderer depth determine who's in front
            //stencilFunc: AlwaysStencilFunc,     // works
            //stencilFunc: NotEqualStencilFunc,   // works
            //stencilFunc: LessStencilFunc,       // Doesn't work
            //stencilFunc: LessEqualStencilFunc,       // Doesn't work
            stencilFunc: GreaterStencilFunc,       // Works because stencil ref is greater than 0
            stencilRef: this.pendingState.stencilRef,
            stencilWrite: true,
            stencilZPass: THREE.ReplaceStencilOp,

            toneMapped: true,
            color: this.pendingState.color,
            transparent: this.pendingState.opacity !== 1,    // this.inputs.transparent,
            map: this.pendingState.snapshotTexture ?? null,
            opacity: this.pendingState.opacity,
            polygonOffset: this.pendingState.polygonOffset,
            polygonOffsetFactor: this.pendingState.polygonOffsetFactor,
            polygonOffsetUnits: this.pendingState.polygonOffsetUnits,
            alphaTest: .01,
            visible: this.pendingState.visible && this.pendingState.snapshotVisible
        });

        this._snapshotPlane = new this.three.Mesh(snapshotPlaneRendererGeometry, this._snapshotPlaneMaterial);
        this._planeRendererGroup.add(this._snapshotPlane);
    }


    getClippingPlane(name: string): Object3D | undefined {

        return this._backingPlane?.children.find(c => c.name === name);
    }


    getGroup(): Object3D {

        return this._planeRendererGroup;
    }


    getSnapshotPlane(): Mesh {

        return this._snapshotPlane;
    }


    getVideoPlane(): Mesh {

        return this._videoPlane;
    }


    override getState(): VideoRendererState {

        // Might want to deepCopy depending on properties.
        return this.state;
    }


    override onDestroy(): void {

        this.disposeEdges();
        this.disposeClippingPlanes();
        this.disposeBackingPlane();
        this.disposeDisplayPlanes();
    }


    // If overriding be sure to call base method.
    override init(): VideoRendererComponent {
        super.init();

        this._planeRendererGroup = new this.three.Group();
        this._planeRendererGroup.renderOrder = VIDEO_RENDERER_DEFAUT_RENDER_ORDER;
        this._groupCreated.next(this._planeRendererGroup);

        this.createBackingPlane();
        this.createDisplayPlanes();

        return this;
    }


    private updatePlaneMaterial() {

        if (this._backingPlaneMaterial) {

            this._backingPlaneMaterial.alphaMap = this.pendingState.alphaMap ?? null;
            this._backingPlaneMaterial.depthWrite = !this.pendingState.disableDepth;
            this._backingPlaneMaterial.color = colorFromHexString(this.pendingState.backingColor);
            this._backingPlaneMaterial.opacity = this.pendingState.opacity != 1 ? this.pendingState.opacity : opacityFromHexString(this.pendingState.backingColor);
            this._backingPlaneMaterial.stencilRef = this.pendingState.stencilRef;
            this._backingPlaneMaterial.transparent = true;
            this._backingPlaneMaterial.needsUpdate = true;
        }

        if (this._snapshotPlaneMaterial) {

            this._snapshotPlaneMaterial.color = new Color(this.pendingState.color);
            this._snapshotPlaneMaterial.map = !this.pendingState.snapshotTexture ? null : this.pendingState.snapshotTexture;
            this._snapshotPlaneMaterial.opacity = this.pendingState.opacity;
            this._snapshotPlaneMaterial.alphaMap = this.pendingState.alphaMap ?? null;
            this._snapshotPlaneMaterial.polygonOffset = this.pendingState.polygonOffset;
            this._snapshotPlaneMaterial.polygonOffsetFactor = this.pendingState.polygonOffsetFactor;
            this._snapshotPlaneMaterial.polygonOffsetUnits = this.pendingState.polygonOffsetUnits;
            this._snapshotPlaneMaterial.side = this.pendingState.side;
            this._snapshotPlaneMaterial.stencilRef = this.pendingState.stencilRef;
            this._snapshotPlaneMaterial.transparent = this.pendingState.opacity != 1;
            this._snapshotPlaneMaterial.visible = this.pendingState.visible && this.pendingState.snapshotVisible;
            this._snapshotPlaneMaterial.needsUpdate = true;
        }

        if (this._videoPlaneMaterial) {

            this._videoPlaneMaterial.depthWrite = !this.pendingState.disableDepth;
            this._videoPlaneMaterial.map = !this.pendingState.videoTexture ? null : this.pendingState.videoTexture;
            this._videoPlaneMaterial.opacity = this.pendingState.visible ? this.pendingState.opacity : 0;
            this._videoPlaneMaterial.alphaMap = this.pendingState.alphaMap ?? null;
            this._videoPlaneMaterial.polygonOffset = this.pendingState.polygonOffset;
            this._videoPlaneMaterial.polygonOffsetFactor = this.pendingState.polygonOffsetFactor;
            this._videoPlaneMaterial.polygonOffsetUnits = this.pendingState.polygonOffsetUnits;
            this._videoPlaneMaterial.side = this.pendingState.side;
            this._videoPlaneMaterial.stencilRef = this.pendingState.stencilRef;
            this._videoPlaneMaterial.transparent = this.pendingState.opacity != 1;
            this._videoPlaneMaterial.visible = this.pendingState.visible && this.pendingState.videoVisible;
            this._videoPlaneMaterial.needsUpdate = true;
        }

        if (this._backingPlane && 0 < this._backingPlane.children.length) {

            const mesh = this._backingPlane.children.find(c => EDGES_NAME === c.name) as Mesh;
            if (mesh) {

                (mesh.material as LineBasicMaterial).color = this.pendingState.selected ? new Color(Color.NAMES.black) : new Color(Color.NAMES.red);
                (mesh.material as LineBasicMaterial).opacity = this.pendingState.selected ? 1 : 0.4;
                (mesh.material as LineBasicMaterial).needsUpdate = true;
            }
        }

        for (const clippingPlaneGroup of this._planeRendererGroup.children) {

            if (clippingPlaneGroup === this._backingPlane || clippingPlaneGroup === this._videoPlane || clippingPlaneGroup === this._snapshotPlane) {

                continue;
            }

            const clippingPlaneMesh = clippingPlaneGroup.children.find(c => !c.name.startsWith(EDGES_NAME)) as Mesh;
            if (clippingPlaneMesh) {

                (clippingPlaneMesh.material as Material).depthWrite = !this.pendingState.disableDepth;
                (clippingPlaneMesh.material as Material).stencilRef = this.pendingState.stencilRef;
                (clippingPlaneMesh.material as Material).needsUpdate = true;
            }
        }

    }


    // 
    // Dispose stuff
    //


    private disposeBackingPlane() {

        if (this._backingPlane) {

            this.disposeEdges();
            this._planeRendererGroup.remove(this._backingPlane);
            this._backingPlane.geometry.dispose();
            (this._backingPlane.material as MeshStandardMaterial).dispose();
            this._backingPlane = undefined;
        }
    }


    private disposeEdges() {

        if (this._backingPlane && 0 < this._backingPlane.children.length) {

            const mesh = this._backingPlane.children[0] as Mesh;
            this._backingPlane.remove(mesh);
            mesh.geometry.dispose();
            (mesh.material as LineBasicMaterial).dispose();
        }

        this.disposeClippingPlaneEdges();
    }


    private disposeClippingPlaneEdges() {

        for (const child of this._planeRendererGroup.children) {

            for (const edges of child.children) {

                if (edges.name.startsWith(EDGES_NAME)) {

                    child.remove(edges);
                    (edges as Mesh).geometry.dispose();
                    ((edges as Mesh).material as LineBasicMaterial).dispose;
                }
            }
        }
    }


    private disposeClippingPlaneGroup(name: string): void {

        const clippingPlaneGroup = this._planeRendererGroup.children.find(c => c.name === name);

        if (clippingPlaneGroup) {

            this.world.removeClippingPlane(Number(clippingPlaneGroup.name), PropType.VIDEO);
            this._planeRendererGroup.remove(clippingPlaneGroup);
            for (const child of clippingPlaneGroup.children) {

                (child as Mesh).geometry.dispose();
                ((child as Mesh).material as MeshBasicMaterial).dispose();
            }
        }
    }


    private disposeClippingPlanes() {

        for (const child of this._planeRendererGroup.children) {

            if (child !== this._backingPlane && child !== this._videoPlane && child !== this._snapshotPlane) {

                this.disposeClippingPlaneGroup(child.name);
            }
        }
    }


    private disposeDisplayPlanes() {

        if (this._snapshotPlane) {

            this._planeRendererGroup.remove(this._snapshotPlane);
            this._snapshotPlane.geometry.dispose();
            (this._snapshotPlane.material as MeshStandardMaterial).dispose();
        }

        if (this._videoPlane) {

            this._planeRendererGroup.remove(this._videoPlane);
            this._videoPlane.geometry.dispose();
            (this._videoPlane.material as MeshStandardMaterial).dispose();
        }
    }

}