import { defineSystem, IWorld } from 'bitecs'
import { PositionAdjustmentComponent } from '../component/position.component';
import { lerp } from 'three/src/math/MathUtils';
import {
    DestinationPositionComponent, DestinationRotationComponent, DestinationScaleComponent, FromPositionComponent, FromRotationComponent,
    FromScaleComponent, RotationAdjustmentComponent, ScaleAdjustmentComponent
} from '..';
import { WorldState } from '../ecs-world.state';
import { Vector3 } from 'three';
import { ShowroomPositionComponent } from '../component/showroom-position.component';
import { AdjustableTransitionInitState } from './adjustable-transition-init.state';
import { adjustableEntityQuery, clippingPlaneEntityQuery } from '../entity/adjustable.entity';


/**
 * System factory.
 * Triggered by change in the destination position Id set by the transition init system.
 * Processes the relevant transition values based upon the stage's current transition progression.
 * @param stage 
 * @returns The system initialized with the stage.
 */
export const adjustableTransitionSystem = defineSystem(world => {

    // If we're not transitioning then we're done.
    if (WorldState.currentPositionEntityId === WorldState.destinationPositionEntityId) {

        return world;
    }

    // Get transition progression.
    const ratio = new Vector3(
        ShowroomPositionComponent.x[WorldState.currentPositionEntityId],
        ShowroomPositionComponent.y[WorldState.currentPositionEntityId],
        ShowroomPositionComponent.z[WorldState.currentPositionEntityId]).distanceTo({
            x: WorldState.cameraPose.position.x,
            y: WorldState.cameraPose.position.y,
            z: WorldState.cameraPose.position.z
        }) / AdjustableTransitionInitState.transitionDistance;

    transitionAdjustables(world, ratio);
    transitionClippingPlanes(world, ratio);

    //resources.logger.error(`Transition to ${destinationPosition.id}, processed ${adjustableEntities.length} entites`);

    return world;
});


function setAdjustmentValues(eidForAdjustable: number, ratio: number) {

    PositionAdjustmentComponent.x[eidForAdjustable] = lerp(FromPositionComponent.x[eidForAdjustable], DestinationPositionComponent.x[eidForAdjustable], ratio);
    PositionAdjustmentComponent.y[eidForAdjustable] = lerp(FromPositionComponent.y[eidForAdjustable], DestinationPositionComponent.y[eidForAdjustable], ratio);
    PositionAdjustmentComponent.z[eidForAdjustable] = lerp(FromPositionComponent.z[eidForAdjustable], DestinationPositionComponent.z[eidForAdjustable], ratio);
    // if (18 === AdjustableComponent.id[entityId]) {
    //     resources.logger.error(`${entityId} POSITION x: ${PositionAdjustmentComponent.x[entityId]}, y: ${PositionAdjustmentComponent.y[entityId]}, z: ${PositionAdjustmentComponent.z[entityId]}`);
    // }
    RotationAdjustmentComponent.x[eidForAdjustable] = lerp(FromRotationComponent.x[eidForAdjustable], DestinationRotationComponent.x[eidForAdjustable], ratio);
    RotationAdjustmentComponent.y[eidForAdjustable] = lerp(FromRotationComponent.y[eidForAdjustable], DestinationRotationComponent.y[eidForAdjustable], ratio);
    RotationAdjustmentComponent.z[eidForAdjustable] = lerp(FromRotationComponent.z[eidForAdjustable], DestinationRotationComponent.z[eidForAdjustable], ratio);
    ScaleAdjustmentComponent.x[eidForAdjustable] = lerp(FromScaleComponent.x[eidForAdjustable], DestinationScaleComponent.x[eidForAdjustable], ratio);
    ScaleAdjustmentComponent.y[eidForAdjustable] = lerp(FromScaleComponent.y[eidForAdjustable], DestinationScaleComponent.y[eidForAdjustable], ratio);
    ScaleAdjustmentComponent.z[eidForAdjustable] = lerp(FromScaleComponent.z[eidForAdjustable], DestinationScaleComponent.z[eidForAdjustable], ratio);
}


// function setHorizontalMaskAdjustments(entityId: number, ratio: number) {

//     // If original position had no mask ...
//     if (FromHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.NONE) {

//         // Set original position mask to match destination mask.
//         HorizontalMaskComponent.mode[entityId] = DestinationHorizontalMaskComponent.mode[entityId];
//         // Set orginal position mask properties to transition to the destination mask starting with values that 
//         // simulate no mask given the specific mask type.
//         if (HorizontalMaskComponent.mode[entityId] === HorizontalClipMode.RIGHT) {
//             HorizontalMaskComponent.offset[entityId] = lerp(1, DestinationHorizontalMaskComponent.offset[entityId], ratio);
//         } else {
//             HorizontalMaskComponent.offset[entityId] = lerp(0, DestinationHorizontalMaskComponent.offset[entityId], ratio);
//         }
//         if (HorizontalMaskComponent.mode[entityId] === HorizontalClipMode.CENTER || HorizontalMaskComponent.mode[entityId] == HorizontalClipMode.LEFT) {
//             HorizontalMaskComponent.width[entityId] = lerp(0, DestinationHorizontalMaskComponent.width[entityId], ratio);
//         } else {
//             HorizontalMaskComponent.width[entityId] = lerp(1, DestinationHorizontalMaskComponent.width[entityId], ratio);
//         }

//     } else if (DestinationHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.NONE) {

//         if (FromHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.RIGHT) {
//             // Expand the offset to fill the space to eliminate the right clipping
//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], 1, ratio);
//         } else if (FromHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.CENTER) {
//             // Converge toward center
//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], .5, ratio);
//         } else {
//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], 0, ratio);
//         }
//         if (FromHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.CENTER || FromHorizontalMaskComponent.mode[entityId] == HorizontalClipMode.LEFT) {
//             HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], 0, ratio);
//         } else {
//             HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], 1, ratio);
//         }

//     } else if (FromHorizontalMaskComponent.mode[entityId] === DestinationHorizontalMaskComponent.mode[entityId]) {

//         HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], DestinationHorizontalMaskComponent.offset[entityId], ratio);
//         HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], DestinationHorizontalMaskComponent.width[entityId], ratio);
//         //resources.logger.error(`Offset: ${HorizontalMaskComponent.offset[entityId]}, width: ${HorizontalMaskComponent.width[entityId]}`);

//     } else if (FromHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.LEFT) {

//         if (DestinationHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.RIGHT) {

//             // Treat as EDGES until transition is complete
//             HorizontalMaskComponent.mode[entityId] = HorizontalClipMode.EDGES;
//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], 0, ratio);
//             HorizontalMaskComponent.width[entityId] = lerp(1, DestinationHorizontalMaskComponent.offset[entityId], ratio);

//         } else if (DestinationHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.CENTER) {

//             HorizontalMaskComponent.mode[entityId] = HorizontalClipMode.CENTER;
//             HorizontalMaskComponent.offset[entityId] = lerp(0, DestinationHorizontalMaskComponent.offset[entityId], ratio);
//             HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], DestinationHorizontalMaskComponent.width[entityId], ratio);

//         } else {    // Going to EDGES

//             // Treat as EDGES until transition is complete
//             HorizontalMaskComponent.mode[entityId] = HorizontalClipMode.EDGES;
//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], DestinationHorizontalMaskComponent.offset[entityId], ratio);
//             HorizontalMaskComponent.width[entityId] = lerp(1 - FromHorizontalMaskComponent.width[entityId], DestinationHorizontalMaskComponent.width[entityId], ratio);

//         }

//     } else if (FromHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.RIGHT) {

//         if (DestinationHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.LEFT) {

//             // Treat as EDGES until transition is complete.
//             HorizontalMaskComponent.mode[entityId] = HorizontalClipMode.EDGES;
//             // Start creating the left clip by expanding the offset toward the expected left clip width.
//             HorizontalMaskComponent.offset[entityId] = lerp(0, DestinationHorizontalMaskComponent.width[entityId], ratio);
//             // Eliminate the right side clipping
//             HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], 1, ratio);

//         } else if (DestinationHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.EDGES) {

//             // Treat as EDGES until transition is complete.
//             HorizontalMaskComponent.mode[entityId] = HorizontalClipMode.EDGES;
//             // Start creating the left clip by expanding the offset toward the expected left clip width.
//             HorizontalMaskComponent.offset[entityId] = lerp(0, DestinationHorizontalMaskComponent.offset[entityId], ratio);
//             // Eliminate the right side clipping
//             HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], DestinationHorizontalMaskComponent.width[entityId], ratio);

//         } else { // Going to CENTER mode

//             HorizontalMaskComponent.mode[entityId] = HorizontalClipMode.CENTER;
//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], DestinationHorizontalMaskComponent.offset[entityId], ratio);
//             HorizontalMaskComponent.width[entityId] = lerp(1 - FromHorizontalMaskComponent.offset[entityId], DestinationHorizontalMaskComponent.width[entityId], ratio);

//         }

//     } else if (FromHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.CENTER) {

//         if (DestinationHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.RIGHT) {

//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], DestinationHorizontalMaskComponent.offset[entityId], ratio);
//             HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], 1, ratio);

//         } else if (DestinationHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.LEFT) {

//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], 0, ratio);
//             HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], DestinationHorizontalMaskComponent.width[entityId], ratio);

//         } else { // Going to LEFT mode

//             if (ratio * 2 < 1) {
//                 HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], 0, ratio * 2);
//                 HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], DestinationHorizontalMaskComponent.offset[entityId], ratio * 2);
//             } else {
//                 // Treat as EDGES until transition is complete.
//                 HorizontalMaskComponent.mode[entityId] = VerticalClipMode.EDGES;
//                 HorizontalMaskComponent.offset[entityId] = DestinationVerticalMaskComponent.offset[entityId];
//                 HorizontalMaskComponent.width[entityId] = lerp(1 - DestinationHorizontalMaskComponent.offset[entityId], DestinationHorizontalMaskComponent.width[entityId], ratio);
//             }
//         }

//     } else if (FromHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.EDGES) {

//         if (DestinationHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.RIGHT) {

//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], 0, ratio);
//             HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], DestinationHorizontalMaskComponent.offset[entityId], ratio);

//         } else if (DestinationHorizontalMaskComponent.mode[entityId] === HorizontalClipMode.LEFT) {

//             HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], DestinationHorizontalMaskComponent.width[entityId], ratio);
//             HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], 1, ratio);

//         } else { // Going to CENTER

//             if (ratio * 2 < 1) {
//                 HorizontalMaskComponent.offset[entityId] = lerp(FromHorizontalMaskComponent.offset[entityId], 0, ratio * 2);
//                 HorizontalMaskComponent.width[entityId] = lerp(FromHorizontalMaskComponent.width[entityId], DestinationHorizontalMaskComponent.offset[entityId], ratio * 2);
//             } else {
//                 // Treat as EDGES until transition is complete.
//                 HorizontalMaskComponent.mode[entityId] = VerticalClipMode.CENTER;
//                 HorizontalMaskComponent.offset[entityId] = DestinationHorizontalMaskComponent.offset[entityId];
//                 HorizontalMaskComponent.width[entityId] = lerp(1 - DestinationHorizontalMaskComponent.offset[entityId], DestinationHorizontalMaskComponent.width[entityId], ratio);
//             }

//         }

//     }
// }


// function setVerticalMaskAdjustments(entityId: number, ratio: number) {

//     // If original position had no mask ...
//     if (FromVerticalMaskComponent.mode[entityId] === VerticalClipMode.NONE) {   // Assumes not hidden

//         // Set original position mask to match destination mask.
//         VerticalMaskComponent.mode[entityId] = DestinationVerticalMaskComponent.mode[entityId];
//         // Set orginal position mask properties to transition to the destination mask starting with values that 
//         // simulate no mask given the specific mask type.
//         if (VerticalMaskComponent.mode[entityId] === VerticalClipMode.TOP || VerticalMaskComponent.mode[entityId] === VerticalClipMode.EDGES) {
//             VerticalMaskComponent.offset[entityId] = lerp(0, DestinationVerticalMaskComponent.offset[entityId], ratio);
//         } else {
//             VerticalMaskComponent.offset[entityId] = lerp(1, DestinationVerticalMaskComponent.offset[entityId], ratio);
//         }
//         if (VerticalMaskComponent.mode[entityId] === VerticalClipMode.CENTER || VerticalMaskComponent.mode[entityId] === VerticalClipMode.BOTTOM) {
//             VerticalMaskComponent.height[entityId] = lerp(0, DestinationVerticalMaskComponent.height[entityId], ratio);
//         } else {
//             VerticalMaskComponent.height[entityId] = lerp(1, DestinationVerticalMaskComponent.height[entityId], ratio);
//         }

//     } else if (DestinationVerticalMaskComponent.mode[entityId] === VerticalClipMode.NONE) {  // Assumes not hidden

//         if (FromVerticalMaskComponent.mode[entityId] === VerticalClipMode.CENTER) {
//             // Converge toward center
//             VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], .5, ratio);
//         } else {
//             VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], 0, ratio);
//         }
//         if (FromVerticalMaskComponent.mode[entityId] === VerticalClipMode.CENTER || FromVerticalMaskComponent.mode[entityId] == VerticalClipMode.TOP
//             || FromVerticalMaskComponent.mode[entityId] === VerticalClipMode.BOTTOM) {
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.height[entityId], 0, ratio);
//         } else {
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.height[entityId], 1, ratio);
//         }

//     } else if (FromVerticalMaskComponent.mode[entityId] === DestinationVerticalMaskComponent.mode[entityId]) {

//         VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], DestinationVerticalMaskComponent.offset[entityId], ratio);
//         VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.height[entityId], DestinationVerticalMaskComponent.height[entityId], ratio);

//     } else if (FromVerticalMaskComponent.mode[entityId] === VerticalClipMode.TOP) {

//         if (DestinationVerticalMaskComponent.mode[entityId] === VerticalClipMode.BOTTOM) {

//             // Treat as EDGES until transition is complete
//             VerticalMaskComponent.mode[entityId] = VerticalClipMode.EDGES;
//             VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], 0, ratio);
//             VerticalMaskComponent.height[entityId] = lerp(1, 1 - DestinationVerticalMaskComponent.height[entityId], ratio);

//         } else if (DestinationVerticalMaskComponent.mode[entityId] === VerticalClipMode.EDGES) {

//             VerticalMaskComponent.mode[entityId] = VerticalClipMode.EDGES;
//             VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], DestinationVerticalMaskComponent.offset[entityId], ratio);
//             VerticalMaskComponent.height[entityId] = lerp(1, DestinationVerticalMaskComponent.height[entityId], ratio);

//         } else { // Going to CENTER mode

//             VerticalMaskComponent.mode[entityId] = VerticalClipMode.CENTER;
//             VerticalMaskComponent.offset[entityId] = lerp(0, DestinationVerticalMaskComponent.offset[entityId], ratio);
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], DestinationVerticalMaskComponent.height[entityId], ratio);

//         }

//     } else if (FromVerticalMaskComponent.mode[entityId] === VerticalClipMode.BOTTOM) {

//         if (DestinationVerticalMaskComponent.mode[entityId] === VerticalClipMode.TOP) {

//             // Treat as EDGES until transition is complete.
//             VerticalMaskComponent.mode[entityId] = VerticalClipMode.EDGES;
//             // Start creating the left clip by expanding the offset toward the expected left clip height.
//             VerticalMaskComponent.offset[entityId] = lerp(0, DestinationVerticalMaskComponent.height[entityId], ratio);
//             // Eliminate the right side clipping
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], 1, ratio);

//         } else if (DestinationVerticalMaskComponent.mode[entityId] === VerticalClipMode.EDGES) {

//             // Treat as EDGES until transition is complete.
//             VerticalMaskComponent.mode[entityId] = VerticalClipMode.EDGES;
//             // Start creating the left clip by expanding the offset toward the expected left clip height.
//             VerticalMaskComponent.offset[entityId] = lerp(0, DestinationVerticalMaskComponent.offset[entityId], ratio);
//             // Eliminate the right side clipping
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], DestinationVerticalMaskComponent.height[entityId], ratio);

//         } else { // Going to CENTER mode

//             VerticalMaskComponent.mode[entityId] = VerticalClipMode.CENTER;
//             VerticalMaskComponent.offset[entityId] = lerp(0, DestinationVerticalMaskComponent.offset[entityId], ratio);
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], DestinationVerticalMaskComponent.height[entityId], ratio);

//         }

//     } else if (FromVerticalMaskComponent.mode[entityId] === VerticalClipMode.CENTER) {      // TODO: Define strategy for transitioning from CENTER to EDGES (2 part - ratio * 2 till ratio = .5, then ratio)

//         if (DestinationVerticalMaskComponent.mode[entityId] === VerticalClipMode.BOTTOM) {

//             VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], 1 - DestinationVerticalMaskComponent.height[entityId], ratio);
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.height[entityId], DestinationVerticalMaskComponent.height[entityId], ratio);

//         } else if (DestinationVerticalMaskComponent.mode[entityId] === VerticalClipMode.TOP) {

//             VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], 0, ratio);
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.height[entityId], DestinationVerticalMaskComponent.offset[entityId], ratio);

//         } else {    // Going to EDGES

//             if (ratio * 2 < 1) {
//                 VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], 0, ratio * 2);
//                 VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.height[entityId], DestinationVerticalMaskComponent.offset[entityId], ratio * 2);
//             } else {
//                 // Treat as EDGES until transition is complete.
//                 VerticalMaskComponent.mode[entityId] = VerticalClipMode.EDGES;
//                 VerticalMaskComponent.offset[entityId] = DestinationVerticalMaskComponent.offset[entityId];
//                 VerticalMaskComponent.height[entityId] = lerp(1 - DestinationVerticalMaskComponent.offset[entityId], DestinationVerticalMaskComponent.height[entityId], ratio);
//             }

//         }

//     } else if (FromVerticalMaskComponent.mode[entityId] === VerticalClipMode.EDGES) {

//         if (DestinationVerticalMaskComponent.mode[entityId] === VerticalClipMode.BOTTOM) {

//             VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], 0, ratio);
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.height[entityId], 1 - DestinationVerticalMaskComponent.height[entityId], ratio);

//         } else if (DestinationVerticalMaskComponent.mode[entityId] === VerticalClipMode.TOP) { // Going to TOP mode

//             VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], DestinationVerticalMaskComponent.offset[entityId], ratio);
//             VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.height[entityId], 1, ratio);

//         } else {    // Going to CENTER

//             if (ratio * 2 < 1) {
//                 VerticalMaskComponent.offset[entityId] = lerp(FromVerticalMaskComponent.offset[entityId], 0, ratio * 2);
//                 VerticalMaskComponent.height[entityId] = lerp(FromVerticalMaskComponent.height[entityId], DestinationVerticalMaskComponent.offset[entityId], ratio * 2);
//             } else {
//                 // Treat as EDGES until transition is complete.
//                 VerticalMaskComponent.mode[entityId] = VerticalClipMode.CENTER;
//                 VerticalMaskComponent.offset[entityId] = DestinationVerticalMaskComponent.offset[entityId];
//                 VerticalMaskComponent.height[entityId] = lerp(1 - DestinationVerticalMaskComponent.offset[entityId], DestinationVerticalMaskComponent.height[entityId], ratio);
//             }

//         }

//     }

// }


function transitionAdjustables(world: IWorld, ratio: number) {
    
    let entityIdForAdjustable = 0;
    const adjustableEntities = adjustableEntityQuery(world);
    for (let i = 0; i < adjustableEntities.length; i++) {

        entityIdForAdjustable = adjustableEntities[i];

        setAdjustmentValues(entityIdForAdjustable, ratio);
        // if (22 === entityId) {
        //     resources.logger.error(`${entityId} POS LERP (${ratio}) FROM x: ${PositionAdjustmentComponent.x[entityId]}, y: ${PositionAdjustmentComponent.y[entityId]}, z: ${PositionAdjustmentComponent.z[entityId]}`);
        //     resources.logger.error(`${entityId} POS LERP (${ratio}) TO   x: ${LerpAdjustmentComponent.x[entityId]}, y: ${LerpAdjustmentComponent.y[entityId]}, z: ${LerpAdjustmentComponent.z[entityId]}`);
        //     resources.logger.error(`${entityId} RESULT                   x: ${DestinationAdjustmentComponent.x[entityId]}, y: ${DestinationAdjustmentComponent.y[entityId]}, z: ${DestinationAdjustmentComponent.z[entityId]}`);
        // }

        // If from and destination mask adjustments are hidden or all clip modes are NONE. No need to adjust mask.
        // if ((0 !== FromMaskComponent.hide[entityIdForAdjustable] && 0 !== DestinationMaskComponent.hide[entityIdForAdjustable])
        //     || (HorizontalClipMode.NONE === FromHorizontalMaskComponent.mode[entityIdForAdjustable]) && HorizontalClipMode.NONE === DestinationHorizontalMaskComponent.mode[entityIdForAdjustable]
        //     && VerticalClipMode.NONE === FromVerticalMaskComponent.mode[entityIdForAdjustable] && VerticalClipMode.NONE === DestinationVerticalMaskComponent.mode[entityIdForAdjustable]) {

        //     continue;
        // }

        // setHorizontalMaskAdjustments(entityIdForAdjustable, ratio);
        // setVerticalMaskAdjustments(entityIdForAdjustable, ratio);
    }
}


function transitionClippingPlanes(world: IWorld, ratio: number) {

    let entityIdForClippingPlane = 0;
    const clippingPlaneEntities = clippingPlaneEntityQuery(world);
    for (let i = 0; i < clippingPlaneEntities.length; i++) {

        entityIdForClippingPlane = clippingPlaneEntities[i];
        setAdjustmentValues(entityIdForClippingPlane, ratio);
    }
}