//import { ItemDesc, Category } from "./types";
//import { cdnUrl } from 'projects/mp-common/src';
import { Category, ItemDesc } from "src/app/core/model/matterport/matterport.model";

// @ts-ignore
import THREE from '/static/vendors/three/0.151.3/three.min.js';
//declare const THREE: any;

export class AppState {
  sid: string = '';
  items: Map<Category, ItemDesc[]> = new Map();
  slots: Map<string, Category> = new Map();
}


export const stageObjectCategories: Map<string, Category> = new Map();
stageObjectCategories.set('slot0', Category.Sofa);
stageObjectCategories.set('slot1', Category.EndTable);
stageObjectCategories.set('slot2', Category.ArmChair);
stageObjectCategories.set('slot3', Category.CoffeTable);

const sofaItems: ItemDesc[] = [
  // {
  //   name: 'Sofa 1',
  //   url: cdnUrl + '/models/sofa/7/scene.gltf',
  //   categories: [ Category.Sofa ],
  //   position: new THREE.Vector3(5.2, -0.5, 0),
  //   rotation: new THREE.Vector3(0, 0, 0),
  //   scale: new THREE.Vector3(1, 1, 1),
  // },
  // {
  //   name: 'Sofa 2',
  //   url: cdnUrl + '/models/sofa/9/scene.gltf',
  //   categories: [ Category.Sofa ],
  //   position: new THREE.Vector3(0, 0, 0),
  //   rotation: new THREE.Vector3(0, 90, 0),
  //   scale: new THREE.Vector3(1, 1, 1),
  // },
  // {
  //   name: 'Sofa 3',
  //   url: cdnUrl + '/models/sofa/11/scene.gltf',
  //   categories: [ Category.Sofa ],
  //   position: new THREE.Vector3(0, -0.5, 0),
  //   rotation: new THREE.Vector3(0, 90, 0),
  //   scale: new THREE.Vector3(0.009, 0.009, 0.009),
  // }  
];

const endTableItems: ItemDesc[] = [
//   {
//     name: 'Arwin',
//     url: cdnUrl + '/models/end-table/4/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.32, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Aula',
//     url: cdnUrl + '/models/end-table/3/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.32, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Aula 2',
//     url: cdnUrl + '/models/end-table/6/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.32, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Aula 3',
//     url: cdnUrl + '/models/end-table/9/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.32, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Contrast Chess',
//     url: cdnUrl + '/models/end-table/7/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.32, 0),
//     rotation: new THREE.Vector3(0, 90, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Edelweiss',
//     url: cdnUrl + '/models/end-table/5/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.32, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'End table 1',
//     url: cdnUrl + '/models/end-table/2/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.32, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Global',
//     url: cdnUrl + '/models/end-table/8/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(-0.32, -0.32, -0.5),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.001, 0.001, 0.001),
//   }
];

const armChairItems: ItemDesc[] = [
//   {
//     name: 'Arm chair 1',
//     url: cdnUrl + '/models/arm-chair/2/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(-0.27, -0.51, -0.35),
//     rotation: new THREE.Vector3(0, -130, 0),
//     scale: new THREE.Vector3(0.001, 0.001, 0.001),
//   },
//   {
//     name: 'Arm chair 2',
//     url: cdnUrl + '/models/arm-chair/4/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.51, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.008, 0.008, 0.008),
//   },
//   {
//     name: 'Arm chair 3',
//     url: cdnUrl + '/models/arm-chair/5/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.51, 0),
//     rotation: new THREE.Vector3(0, -90, 0),
//     scale: new THREE.Vector3(0.0019, 0.0019, 0.0019),
//   },
//   {
//     name: 'Arm chair 4',
//     url: cdnUrl + '/models/arm-chair/6/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.51, 0),
//     rotation: new THREE.Vector3(0, -90, 0),
//     scale: new THREE.Vector3(1.3, 1.3, 1.3),
//   },
//   {
//     name: 'Arm chair 5',
//     url: cdnUrl + '/models/arm-chair/7/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.51, 0),
//     rotation: new THREE.Vector3(0, -90, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Arm chair 6',
//     url: cdnUrl + '/models/arm-chair/8/scene.gltf',
//     categories: [ Category.EndTable ],
//     position: new THREE.Vector3(0, -0.51, 0),
//     rotation: new THREE.Vector3(0, 90, 0),
//     scale: new THREE.Vector3(0.011, 0.011, 0.011),
//   },
];

const coffeeTableItems: ItemDesc[] = [
//   {
//     name: 'Coffee table 1',
//     url: cdnUrl + '/models/coffee-table/2/scene.gltf',
//     categories: [ Category.CoffeTable ],
//     position: new THREE.Vector3(0, 0, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.6, 0.6, 0.6),
//   },
//   {
//     name: 'Coffee table 2',
//     url: cdnUrl + '/models/coffee-table/3/scene.gltf',
//     categories: [ Category.CoffeTable ],
//     position: new THREE.Vector3(0, 0, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.4, 0.4, 0.4),
//   },
//   {
//     name: 'Coffee table 3',
//     url: cdnUrl + '/models/coffee-table/6/scene.gltf',
//     categories: [ Category.CoffeTable ],
//     position: new THREE.Vector3(-0.15, -0.2, 0.4),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Coffee table 4',
//     url: cdnUrl + '/models/coffee-table/7/scene.gltf',
//     categories: [ Category.CoffeTable ],
//     position: new THREE.Vector3(0, -0.26, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Coffee table 5',
//     url: cdnUrl + '/models/coffee-table/8/scene.gltf',
//     categories: [ Category.CoffeTable ],
//     position: new THREE.Vector3(0, -0.26, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Coffee table 6',
//     url: cdnUrl + '/models/coffee-table/9/scene.gltf',
//     categories: [ Category.CoffeTable ],
//     position: new THREE.Vector3(0, -0.255, 0),
//     rotation: new THREE.Vector3(0, 0, 0),
//     scale: new THREE.Vector3(0.01, 0.01, 0.01),
//   },
//   {
//     name: 'Coffee table 7',
//     url: cdnUrl + '/models/coffee-table/10/scene.gltf',
//     categories: [ Category.CoffeTable ],
//     position: new THREE.Vector3(1.3, -0.255, 0.2),
//     rotation: new THREE.Vector3(0, 90, 0),
//     scale: new THREE.Vector3(0.2, 0.2, 0.2),
//   },
];

export const stageObjectOptions: Map<Category, ItemDesc[]> = new Map();
stageObjectOptions.set(Category.Sofa, sofaItems);
stageObjectOptions.set(Category.EndTable, endTableItems);
stageObjectOptions.set(Category.ArmChair, armChairItems);
stageObjectOptions.set(Category.CoffeTable, coffeeTableItems);
