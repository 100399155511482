import { CommonModule } from '@angular/common';
import { Component, WritableSignal, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { MpShowroomComponent } from 'src/app/components/matterport/mp-showroom/mp-showroom.component';
import { PROP_SELECT_SIDEBAR_ID, PropSelectSidebarComponent } from 'src/app/components/showroom/prop-select-sidebar/prop-select-sidebar.component';
import { ShowroomGuestOptionsSidebarComponent } from 'src/app/components/showroom/guest/showroom-guest-options-sidebar/showroom-guest-options-sidebar.component';
import { ItemDesc } from 'src/app/core/model/matterport/matterport.model';
import { IStage } from 'src/app/core/model/showroom/showroom.model';
import {
  DesignImage, DesignObject, DesignVideo, EventDesign, ImageAssignment, ImageProp, IVenue,
  VideoAssignment, IShowroomPosition, ObjectAssignment, ObjectProp, VideoProp
} from 'projects/my-common/src/model';
import { SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { GuestService } from 'src/app/core/service/venue/guest.service';
import { ShowroomService } from 'src/app/core/service/showroom/showroom.service';
import { InitializeShowroomState, ShowroomMode } from 'src/app/state/showroom-state';

/**
 * We share a version of THREE with model-viewer which is more current than Matterports version of THREE.
 * When working with Matterport, we use thier version of THREE.
 */
import * as THREE from 'three';
import { EcsWorld } from 'projects/my-common/src/ecs';
import { deepCopy } from 'projects/mp-core/src/lib/util';
import { TextureManager, vector3ObjFromTuple } from 'projects/my-common/src';
import { EnvironmentNode } from 'src/app/core/model/matterport/environment-node.model';
import { ImagePropOptionsSidebarComponent } from "../../../components/showroom/shared/options/image-prop-options-sidebar/image-prop-options-sidebar.component";
import { ObjectPropOptionsSidebarComponent } from "../../../components/showroom/shared/options/object-prop-options-sidebar/object-prop-options-sidebar.component";
import { ImagePropsSidebarComponent } from "../../../components/showroom/shared/image-props-sidebar/image-props-sidebar.component";
import { ObjectPropsSidebarComponent } from "../../../components/showroom/shared/object-props-sidebar/object-props-sidebar.component";
import { VideoPropsSidebarComponent } from "../../../components/showroom/shared/video-props-sidebar/video-props-sidebar.component";
import { PositionsSidebarComponent } from "../../../components/showroom/shared/positions-sidebar/positions-sidebar.component";
import { VideoPropOptionsSidebarComponent } from "../../../components/showroom/shared/options/video-prop-options-sidebar/video-prop-options-sidebar.component";
import { deleteWorld } from 'bitecs';
import { MyOptyxBadgeComponent } from "../../../components/myoptyx/myoptyx-badge/myoptyx-badge.component";


@Component({
  selector: 'app-guest-showroom',
  standalone: true,
  templateUrl: './guest-showroom.component.html',
  styleUrl: './guest-showroom.component.scss',
  imports: [CommonModule, ShowroomGuestOptionsSidebarComponent, MpShowroomComponent, PropSelectSidebarComponent, ImagePropOptionsSidebarComponent, ObjectPropOptionsSidebarComponent, ImagePropsSidebarComponent, ObjectPropsSidebarComponent, VideoPropsSidebarComponent, PositionsSidebarComponent, VideoPropOptionsSidebarComponent, MyOptyxBadgeComponent]
})
export class GuestShowroomComponent {
  private readonly _subscriptions: Subscription[] = [];
  private _defaultEventDesign?: EventDesign;

  textureManager = new TextureManager(THREE);

  readonly assignedImageProps = this.guestService.assignedImageProps;
  readonly assignedObjectProps = this.guestService.assignedObjectProps;
  readonly assignedVideoProps = this.guestService.assignedVideoProps;
  readonly currentDesignImage = this.guestService.currentDesignImage;
  readonly currentDesignObject = this.guestService.currentDesignObject;
  readonly currentDesignVideo = this.guestService.currentDesignVideo;
  readonly currentEventDesign = this.guestService.currentEventDesign;
  readonly currentImageAssignment = this.guestService.currentImageAssignment;
  readonly currentObjectAssignment = this.guestService.currentObjectAssignment;
  readonly currentVideoAssignment = this.guestService.currentVideoAssignment;
  readonly currentImageProp = this.guestService.currentImageProp;
  readonly currentObjectProp = this.guestService.currentObjectProp;
  readonly currentVideoProp = this.guestService.currentVideoProp;
  readonly currentState = this.showroomService.state;
  readonly currentVenueEvent = this.guestService.currentVenueEvent;
  readonly objectAssignments = this.guestService.objectAssignments;
  readonly venue = this.guestService.venue;

  private _environmentNode?: EnvironmentNode;
  showroomPositions: WritableSignal<IShowroomPosition[]> = signal([]);
  readonly propOptions: WritableSignal<ItemDesc[]> = signal([]);

  private _invitationGuid: string = '';
  stage = signal(<IStage>{});
  private _stage!: IStage;

  private _selectedVenueEventId: number = 0;
  private _selectedEventDesignId: number = 0;
  private _selectEventDesignInitialized = false;

  private _defaultObjectUrl = '/assets/models/MyOptyxGlasses.glb';

  private _world = new EcsWorld();


  constructor(private readonly route: ActivatedRoute,
    private readonly logger: NGXLogger,
    readonly guestService: GuestService,
    private readonly sidebarService: SidebarService,
    private readonly showroomService: ShowroomService) {

    InitializeShowroomState((newState) => { });

    // Initialize default InteractionMode else it can get out of sync when moving in and out of Showrooms
    this.currentState().instance.setShowroomMode(ShowroomMode.SHOWROOM);
  }


  /**
   * Create Props on the Stage
   * @returns 
   */
  private addImageProps(): void {

    if (!this.isReady(this.addImageProps.name, true, true)) {

      return;
    }
    this.logger.trace(`venue`, this.venue());

    // If Venue has no Props then return.
    if (1 > this.venue().imageProps.length) {

      return;
    }

    // Setup the Props on the Showroom Stage
    let imageProp: ImageProp;
    for (imageProp of this.venue().imageProps) {

      const imagePropNode = this._stage.addImageProp(imageProp, this._world);
    }
  }


  private addObjectProps(): void {

    // if (!this.isReady(this.addObjectProps.name, true, true)) {

    //   return;
    // }
    // this._logger.trace(`venue`, this.venue());

    // // If Venue has no Props then return.
    // if (1 > this.venue().objectProps.length) {

    //   return;
    // }

    // // Setup the Props on the Showroom Stage
    // let objectProp: ObjectProp;
    // for (objectProp of this.venue().objectProps) {

    //   const objectPropNode = this._stage.addObjectProp(new ObjectProp(objectProp));
    //   objectPropNode.updateWorld(this._world);
    // }
  }


  /**
   * Create Props on the Stage
   * @returns 
   */
  private addVideoProps(): void {

    if (!this.isReady(this.addVideoProps.name, true, true)) {

      return;
    }
    this.logger.trace(`venue`, this.venue());

    // If Venue has no Props then return.
    if (1 > this.venue().videoProps.length) {

      return;
    }

    // Setup the Props on the Showroom Stage
    let videoProp: VideoProp;
    for (videoProp of this.venue().videoProps) {

      const videoPropNode = this._stage.addVideoProp(videoProp, this._world);
      videoPropNode.updateWorld(this._world);
    }
  }


  /**
   * Update the Showroom experience
   * For each Venue Prop, set texture based on ImageAssignments in EventDesign.
   * If EventDesign does not have an ImageAssignment for the Prop then use _defaultVenueEvent EventDesign ImageAssignment (if defined)
   * @returns 
   */
  applyEventDesignToStage() {

    // Everything is required here - Stage, Venue, VenueEvent and EventDesign
    if (!this.isReady(this.applyEventDesignToStage.name, true, true, true)) {

      return;
    }

    this.applyImagePropAssignments();
    this.applyObjectPropAssignments();
    this.applyVideoPropAssignments();

    this._stage.applyPositionAdjustments();
  }


  /**
   * For each prop, apply Prop, Assignments and Designs (if they exist) to the inputs of the Showroom Node.
   */
  private applyImagePropAssignments() {

    let i = 0;
    const imageProps = this.venue().imageProps;

    for (; i < imageProps.length; i++) {

      const imageProp = imageProps[i];
      const imageNode = this._stage.getImageNode(imageProp.id);
      // If the node doesn't exist on Stage then no assignments can happen.
      if (!imageNode) {

        this.logger.trace(`Image node for Image Prop id: ${imageProp.id} not found. Skipping assignments.`);
        continue;
      }

      const imageAssignments = this.getImageAssignmentsOrDefaultForImageProp(imageProp).sort((ia1, ia2) => ia1.id < ia2.id ? -1 : 1);
      const designImages: DesignImage[] = [];
      if (0 < imageAssignments.length) {

        let imageAssignment: ImageAssignment;
        for (imageAssignment of imageAssignments) {

          const designImage = this.getDesignImageOrDefaultForImageAssignment(imageAssignment);
          if (designImage) {

            designImages.push(designImage);
            this.logger.trace(`adding texture: ${designImage.imageUrl} for Prop Id: ${imageProp.id}`);
          }
        }
      } else {

        this.logger.trace(`no Assignment for Image Prop Id: ${imageProp.id}, applying defaults`);
      }

      imageNode.setInputs(imageProp, imageAssignments, designImages);

      let imagePropOptions = this.currentEventDesign().imagePropOptions.find(ipo => ipo.imagePropId === imageProp.id);
      if (!imagePropOptions && this._defaultEventDesign) {

        imagePropOptions = this._defaultEventDesign?.imagePropOptions.find(ipo => ipo.imagePropId === imageProp.id);
      }
      if (imagePropOptions) {

        imageNode.setOptions(imagePropOptions);
      }
    }
  }


  /**
   * For each Object Prop,
   * if, Assignment exists
   * ensure Node is created and apply assignment to the inputs of the Showroom Node
   * else, ensure Node does not exist
   */
  private applyObjectPropAssignments() {

    let i = 0;
    const objectProps = this.venue().objectProps;

    for (; i < objectProps.length; i++) {

      const objectProp = objectProps[i];
      let objectNode = this._stage.getObjectNode(objectProp.id);
      const objectAssignment = this.getObjectAssignmentOrDefaultForObjectProp(objectProp);
      let designObject: DesignObject | undefined = undefined;

      if (objectAssignment) {

        designObject = this.getDesignObjectOrDefaultForObjectAssignment(objectAssignment);
      } else {

        this.logger.trace(`no assignment for Object Prop Id: ${objectProp.id}`);
        if (objectNode) {

          this._stage.removeObjectProp(objectProp)
        }
        continue;
      }

      if (!objectNode) {

        objectNode = this._stage.addObjectProp(objectProp);
        objectNode.updateWorld(this._world);
      }
      objectNode.setInputs(objectProp, objectAssignment, designObject);
    }
  }


  /**
   * For each prop, apply Prop, Assignments and Design (if they exist) to the inputs of the Showroom Node.
   */
  private applyVideoPropAssignments() {

    let i = 0;
    const videoProps = this.venue().videoProps;

    this.logger.trace(`applying currentEventDesign: ${this.currentVenueEvent().name} - ${this.currentEventDesign().name}`);
    for (; i < videoProps.length; i++) {

      const videoProp = videoProps[i];
      const videoNode = this._stage.getVideoNode(videoProp.id);
      // If the node doesn't exist on Stage then no assignments can happen.
      if (!videoNode) {

        this.logger.trace(`Video node for Video Prop id: ${videoProp.id} not found. Skipping assignment.`);
        continue;
      }

      const videoAssignments = this.getVideoAssignmentsOrDefaultForVideoProp(videoProp).sort((va1, va2) => va1.id < va2.id ? -1 : 1);
      const designVideos: DesignVideo[] = [];
      if (0 < videoAssignments.length) {

        let videoAssignment: VideoAssignment;
        for (videoAssignment of videoAssignments) {

          const designVideo = this.getDesignVideoOrDefaultForVideoAssignment(videoAssignment);
          if (designVideo) {

            designVideos.push(designVideo);
            this.logger.trace(`setting video: ${designVideo.videoUrl} for Prop Id: ${videoProp.id}`);
          } else {

            this.logger.error(`DesignVideo id: ${videoAssignment.designVideoId} missing for VideoAssignment id: ${videoAssignment.id}`);
          }
        }
      } else {

        this.logger.trace(`no Assignment for Video Prop Id: ${videoProps[i].id}, applying defaults`);
      }

      videoNode.setInputs(videoProp, videoAssignments, designVideos);

      let videoPropOptions = this.currentEventDesign().videoPropOptions.find(vpo => vpo.videoPropId === videoProp.id);
      if (!videoPropOptions && this._defaultEventDesign) {

        videoPropOptions = this._defaultEventDesign?.videoPropOptions.find(vpo => vpo.videoPropId === videoProp.id);
      }
      if (videoPropOptions) {

        videoNode.setOptions(videoPropOptions);
      }
    }
  }


  private ensureDefaultVenueEvent(venue: IVenue) {

    if (1 > venue.venueEvents.length) {

      return;
    }
    if (0 < this._selectedVenueEventId) {

      this.selectVenueEvent(this._selectedVenueEventId);
      return;
    }

    const firstNonDefaultVenueEvent = venue.venueEvents.find(ve => !ve.isDefault);
    if (firstNonDefaultVenueEvent) {

      this.selectVenueEvent(firstNonDefaultVenueEvent.id);
    } else {

      this.selectVenueEvent(venue.venueEvents[0].id);
    }
  }


  private getDesignImageOrDefaultForImageAssignment(imageAssignment: ImageAssignment): DesignImage | undefined {

    return this.currentEventDesign().getDesignImageForAssignment(imageAssignment) ??
      this._defaultEventDesign?.getDesignImageForAssignment(imageAssignment);
  }


  private getDesignObjectOrDefaultForObjectAssignment(assignment: ObjectAssignment): DesignObject | undefined {

    return this.currentEventDesign().getDesignObjectForAssignment(assignment) ??
      this._defaultEventDesign?.getDesignObjectForAssignment(assignment);
  }


  private getDesignVideoOrDefaultForVideoAssignment(videoAssignment: VideoAssignment): DesignVideo | undefined {

    return this.currentEventDesign().getDesignVideoForAssignment(videoAssignment) ??
      this._defaultEventDesign?.designVideos.find(dv => dv.id === videoAssignment.designVideoId);
  }


  private getImageAssignmentOrDefaultForDesignImage(designImageId: number): ImageAssignment | undefined {

    return this.currentEventDesign().getImageAssignmentForDesignImage(designImageId, this.currentImageProp().id) ??
      this._defaultEventDesign?.getImageAssignmentForDesignImage(designImageId, this.currentImageProp().id);
  }


  private getImageAssignmentsOrDefaultForImageProp(imageProp: ImageProp): ImageAssignment[] {

    let imageAssignments = this.currentEventDesign().imageAssignments.filter(ia => ia.imagePropId === imageProp.id);
    if (0 < imageAssignments.length) {

      return imageAssignments;
    }

    return this._defaultEventDesign?.imageAssignments.filter(ia => ia.imagePropId === imageProp.id) ?? [];
  }


  private getObjectAssignmentOrDefaultForObjectProp(objectProp: ObjectProp): ObjectAssignment | undefined {

    return this.currentEventDesign().getObjectAssignmentForObjectProp(objectProp) ??
      this._defaultEventDesign?.getObjectAssignmentForObjectProp(objectProp)
  }


  private getVideoAssignmentsOrDefaultForVideoProp(videoProp: VideoProp): VideoAssignment[] {

    let videoAssignments = this.currentEventDesign().videoAssignments.filter(va => va.videoPropId === videoProp.id);
    if (0 < videoAssignments.length) {

      return videoAssignments;
    }

    return this._defaultEventDesign?.videoAssignments.filter(va => va.videoPropId === videoProp.id) ?? [];
  }


  handlePropListSelection(item: ItemDesc) {

    const propNode = this._stage.currentObjectProp;
    if (!propNode) {

      return;
    }

    // Change the Props inputs based upon selection
    // if (propNode.gltfComponent.inputs) {
    //   propNode.gltfComponent.inputs['url'] = item.url;
    //   (propNode.gltfComponent.inputs['localPosition'] as any).x = item.position.x;
    //   (propNode.gltfComponent.inputs['localPosition'] as any).y = item.position.y;
    //   (propNode.gltfComponent.inputs['localPosition'] as any).z = item.position.z;
    //   (propNode.gltfComponent.inputs['localRotation'] as any).x = item.rotation.x;
    //   (propNode.gltfComponent.inputs['localRotation'] as any).y = item.rotation.y;
    //   (propNode.gltfComponent.inputs['localRotation'] as any).z = item.rotation.z;
    //   (propNode.gltfComponent.inputs['localScale'] as any).x = item.scale.x;
    //   (propNode.gltfComponent.inputs['localScale'] as any).y = item.scale.y;
    //   (propNode.gltfComponent.inputs['localScale'] as any).z = item.scale.z;
    // }
  }


  /**
   * Check dependencies at each method build up state
   * @param callingMethodName who wants to know
   * @param stageExists 
   * @param venueExists 
   * @param eventDesignExists 
   * @param venueEventExists 
   * @returns 
   */
  private isReady(callingMethodName: string, stageExists: boolean, venueExists: boolean = false, eventDesignExists: boolean = false, venueEventExists: boolean = false): boolean {

    if (stageExists && !this._stage) {
      this.logger.trace(`--> ${callingMethodName} - Stage not defined`);
      return false;
    }
    if (venueExists && 1 > this.venue().id) {
      this.logger.trace(`--> ${callingMethodName} - Venue not defined`);
      return false;
    }
    if (eventDesignExists && 1 > this.currentEventDesign().id) {
      this.logger.trace(`--> ${callingMethodName} - EventDesign not defined`, this.currentEventDesign());
      return false;
    }
    if (venueEventExists && 1 > this.currentVenueEvent().id) {
      this.logger.trace(`--> ${callingMethodName} - VenueEvent not defined`);
      return false;
    }

    return true;
  }


  private async loadVenue(invitationGuid: string) {

    const venue = await firstValueFrom(this.guestService.getVenue(invitationGuid));

    this.logger.trace(`venue`, this.guestService.venue());

    // Try to find and set the default EventDesign
    const defaultVenueEventIndex = venue.venueEvents.findIndex(ve => ve.isDefault);
    if (-1 < defaultVenueEventIndex) {

      const defaultVenueEvent = venue.venueEvents[defaultVenueEventIndex];
      this.logger.trace(`defaultVenueEvent`, defaultVenueEvent);
      const eventDesigns = defaultVenueEvent.eventDesigns;
      const defaultEventDesignIndex = eventDesigns.findIndex(ed => ed.isDefault);
      if (-1 < defaultEventDesignIndex) {

        this._defaultEventDesign = new EventDesign(eventDesigns[defaultEventDesignIndex]);
        this.logger.trace(`defaultEventDesign`, this._defaultEventDesign);
      } else {

        this.logger.trace(`no defaultEventDesign under defaultVenueEvent`, defaultVenueEvent);
      }
    } else {

      this.logger.trace(`no defaultVenueEvent`);
    }

    this.logger.trace(`updated Venue`, venue);
    this.ensureDefaultVenueEvent(venue);

    this.tryLoadingProps();
  }


  private monitorCameraPosition(): void {

    this._subscriptions.push(
      this._stage.cameraPoseChanged$.subscribe((position) => {

        this._world.inputCameraPose(position);
      })
    );
  }


  private monitorImageAssignmentSelection() {

    this._subscriptions.push(
      this._stage.imageAssignmentChanged$
        .subscribe((imageAssignment: ImageAssignment) => {

          this.guestService.setCurrentImageAssignment(imageAssignment);
          const designImage = this.guestService.getDesignImageOrDefaultForImageAssignment(imageAssignment);
          if (designImage) {

            this.logger.trace(`Design Image for Image Assignment id: ${imageAssignment.id}`, designImage);
            this.guestService.setCurrentDesignImage(designImage);
          } else {

            this.guestService.setCurrentDesignImage(new DesignImage());
          }
        })
    );
  }


  private monitorImagePropSelection() {

    this._subscriptions.push(
      this._stage.imagePropSelected$
        .subscribe((imagePropId: number) => this.selectImageProp(imagePropId))
    );
  }


  private monitorObjectPropSelection() {

    this._subscriptions.push(
      this._stage.objectPropSelected$
        .subscribe((objectPropId: number) => {

          this.logger.trace(`Selected ObjectProp name ${objectPropId}`);
          this.setCurrentObjectProp(objectPropId);
          this.setCurrentObjectAssignment(objectPropId);

          // setTimeout needed for sidebar inputs to sync before opening sidebar
          this.currentState().instance.handlePropClickInteraction(this.currentObjectProp());
        })
    );
  }


  monitorPlaceholderPropSelection() {
    this._subscriptions.push(
      this._stage.placeholderPropSelected$
        .subscribe((propOptions: ItemDesc[]) => {

          this.logger.trace(`setting prop options`);
          this.propOptions.set(propOptions);
          this.sidebarService.open(PROP_SELECT_SIDEBAR_ID);
        })
    );
  }


  private monitorPositionTransitionComplete(): void {

    this._subscriptions.push(
      this._stage.transitionFinished$.subscribe((finalPositionId) => {

        this._world.inputShowroomPositions(finalPositionId, finalPositionId);
      })
    );
  }


  private monitorPositionTransitions(): void {

    this._subscriptions.push(
      this._stage.transitionStarted$.subscribe((transition) => {

        this._world.inputShowroomPositions(transition.fromPositionId, transition.destinationPositionId);
      })
    );
  }


  private monitorVideoPropSelection() {

    this._subscriptions.push(
      this._stage.videoPropSelected$
        .subscribe((videoPropId: number) => this.selectVideoProp(videoPropId))
    );
  }


  ngOnDestroy(): void {

    this.stage().clear();
    this._world.stop();
    deleteWorld(this._world);
    this._subscriptions.forEach(s => s.unsubscribe());
  }


  isValid = signal(false);
  /**
   * Grab url param and load the Venue
   */
  async ngOnInit(): Promise<void> {

    const params = await firstValueFrom(this.route.paramMap);
    this._invitationGuid = params.get('invitationGuid') ?? '';

    if (1 > this._invitationGuid.length) {

      // Routing not working when embedded in iFrame
      this._invitationGuid = window.location.href.split('embed/')[1] ?? '';
      console.error(`glid: ${this._invitationGuid}`);
    }
    if (1 < this._invitationGuid.length) {

      this.isValid.set(true);
      this.loadVenue(this._invitationGuid);
    } else {

      throw new Error('Invalid url');
    }
  }


  // onAddToCart(cartItem: IShowroomCartItem) {

  //   cartItem.venueId = this.venue().id;
  //   cartItem.venueName = this.venue().name;
  //   cartItem.venueEventId = this.currentVenueEvent().id;
  //   cartItem.venueEventName = this.currentVenueEvent().name;
  //   cartItem.eventDesignId = this.currentEventDesign().id;
  //   cartItem.eventDesignName = this.currentEventDesign().name;

  //   this.cartService.addShowroomCartItem(cartItem);
  // }


  async onGoToImage(imageProp: ImageProp): Promise<void> {

    const imagePosition = vector3ObjFromTuple(imageProp.position);

    let positionId: string | undefined = imageProp.viewPositionId;
    if (!positionId || 1 > positionId.length) {

      positionId = this._stage.getClosestPositionId(imagePosition);
    }

    if (positionId) {

      await this._stage.goToPosition(positionId, imagePosition);
    }
  }


  async onGoToObject(objectAssignment: ObjectAssignment): Promise<void> {

    const objectPosition = vector3ObjFromTuple(objectAssignment.position);
    const positionId = this._stage.getClosestPositionId(objectPosition);
    if (positionId) {

      await this._stage.goToPosition(positionId, objectPosition);
    }
  }


  async onGoToPosition(showroomPosition: IShowroomPosition): Promise<void> {

    await this._stage.goToPosition(showroomPosition.id, showroomPosition.position);
  }


  async onGoToVideo(videoProp: VideoProp): Promise<void> {

    const videoPosition = vector3ObjFromTuple(videoProp.position);

    let positionId: string | undefined = videoProp.viewPositionId;
    if (!positionId || 1 > positionId.length) {

      positionId = this._stage.getClosestPositionId(videoPosition);
    }

    if (positionId) {

      await this._stage.goToPosition(positionId, videoPosition);
    }
  }


  async onSelectImageProp(imageProp: ImageProp) {

    this.selectImageProp(imageProp.id);
  }


  async onSelectObject(objectAssignment: ObjectAssignment) {

    const objectProp = this.guestService.objectProps().find(op => op.id === objectAssignment.objectPropId);
    if (!objectProp) {

      return;
    }

    this.setCurrentObjectProp(objectProp.id);
    this.setCurrentObjectAssignment(objectAssignment.id);
    this.currentState().instance.handlePropClickInteraction(this.currentObjectProp());
  }


  async onSelectVideo(videoProp: VideoProp) {

    this.selectVideoProp(videoProp.id);
  }


  /**
   * Called when the virtual environment is ready to start loading Props
   * @param stage 
   */
  async onStageInitialized(stage: IStage) {

    this._stage = stage;
    this.monitorCameraPosition();

    this._environmentNode = stage.addEnvironment();
    this._environmentNode.updateWorld(this._world);

    // Get position data        
    let showroomPositions = await stage.getShowroomPositions();
    if (1 > this.showroomPositions.length) {

      await firstValueFrom(stage.positionsLoaded$);
      showroomPositions = await stage.getShowroomPositions();
    }
    this.showroomPositions.set(showroomPositions);

    // Register position data with ECS
    let showroomPosition: IShowroomPosition;
    for (showroomPosition of showroomPositions) {

      const entityPosition = this._world.addShowroomPosition(showroomPosition.id, showroomPosition.position);
      showroomPosition.entityId = entityPosition.entityId;
    }

    // Establish base position
    if (!stage.currentPlayerPosition) {

      await firstValueFrom(stage.playerPositionUpdate$);
    }
    this._world.inputShowroomPositions(stage.currentPlayerPosition?.id ?? '', stage.currentPlayerPosition?.id ?? '');

    this.monitorImageAssignmentSelection();
    this.monitorImagePropSelection();
    this.monitorObjectPropSelection();
    this.monitorPlaceholderPropSelection();
    this.monitorPositionTransitionComplete();
    this.monitorPositionTransitions();
    this.monitorVideoPropSelection();

    this.tryLoadingProps();

    this.stage.set(stage);
  }


  private selectImageProp(imagePropId: number): void {

    this.logger.trace(`Selected ImageProp id: ${imagePropId}`);
    const imageProp = this.setCurrentImageProp(imagePropId);

    if (imageProp) {

      const imagePropNode = this._stage.getImageNode(imagePropId);
      imagePropNode?.setSelected(true);
      const currentImageAssignment = imagePropNode?.currentAssignment;
      if (currentImageAssignment) {

        this.guestService.setCurrentImageAssignment(currentImageAssignment);

        const designImage = this.getDesignImageOrDefaultForImageAssignment(currentImageAssignment);
        if (designImage) {

          this.guestService.setCurrentDesignImage(designImage);
        }
      } else {

        this.setDefaultImageAssignmentAndDesignForProp(imageProp);
      }
      this.logger.trace(`Current state - imageProp, imageAssignment, designImage`, this.currentImageProp(), this.currentImageAssignment(), this.currentDesignImage());
      this.currentState().instance.handlePropClickInteraction(this.currentImageProp());
    } else {

      this.logger.error(` ImageProp id: ${imagePropId} not found`);
    }
  }


  private selectVideoProp(videoPropId: number): void {

    this.logger.trace(`Selected VideoProp id: ${videoPropId}`);
    const videoProp = this.setCurrentVideoProp(videoPropId);

    if (videoProp) {

      const videoPropNode = this._stage.getVideoNode(videoPropId);
      const currentVideoAssignment = videoPropNode?.currentVideoAssignment;
      if (currentVideoAssignment) {

        this.guestService.setCurrentVideoAssignment(currentVideoAssignment);

        const designVideo = this.getDesignVideoOrDefaultForVideoAssignment(currentVideoAssignment);
        if (designVideo) {

          this.guestService.setCurrentDesignVideo(new DesignVideo(designVideo));
        }
      } else {

        this.setDefaultVideoAssignmentAndDesignForProp(videoProp);
      }
      this.logger.trace(`Current state - videoProp, videoAssignment, designVideo`, this.currentVideoProp(), this.currentVideoAssignment(), this.currentDesignVideo());
      this.currentState().instance.handlePropClickInteraction(this.currentVideoProp());
    } else {

      this.logger.error(`Video Prop id: ${videoPropId} not found`);
    }
  }


  setCurrentDesignImageAndAssignment(designImageId: number) {

    if (!this.isReady(this.setCurrentDesignImageAndAssignment.name, false, true, false, true)) {

      return;
    }

    const imageAssignment = this.getImageAssignmentOrDefaultForDesignImage(designImageId);
    if (imageAssignment) {

      const designImage = this.getDesignImageOrDefaultForImageAssignment(imageAssignment);
      if (designImage) {

        this.guestService.setCurrentImageAssignment(imageAssignment);
        this.guestService.setCurrentDesignImage(designImage);
      }
    }
  }


  setCurrentImageProp(imagePropId: number): ImageProp | undefined {

    if (!this.isReady(this.setCurrentImageProp.name, false, true, false, false)) {

      return;
    }

    const imageProp = this.venue().imageProps.find(ip => ip.id === imagePropId);

    if (imageProp) {

      this.guestService.setCurrentImageProp(imageProp.id);
    } else {

      this.logger.trace(`ImageProp id ${imagePropId} not found in Venue`);
    }

    return imageProp;
  }


  setCurrentObjectAssignment(objectPropId: number) {

    if (!this.isReady(this.setCurrentObjectAssignment.name, false, true, false, true)) {

      return;
    }
    const objectProp = this.venue().objectProps.find(op => op.id === objectPropId);
    if (!objectProp) {

      return;
    }

    const objectAssignment = this.getObjectAssignmentOrDefaultForObjectProp(objectProp);
    if (objectAssignment) {

      this.guestService.setCurrentObjectAssignment(objectAssignment);

      const designObject = this.getDesignObjectOrDefaultForObjectAssignment(new ObjectAssignment(objectAssignment));
      if (designObject) {

        this.logger.trace(`designObject`, designObject);
        this.guestService.setCurrentDesignObject(designObject);
      } else {

        this.logger.trace(`DesignObject for ObjectAssignment id ${objectAssignment.id} not found in current EventDesign`);
        this.guestService.setCurrentDesignObject(new DesignObject());
      }
    } else {

      this.guestService.setCurrentObjectAssignment(new ObjectAssignment());
      this.guestService.setCurrentDesignObject(new DesignObject());
      this.logger.trace(`ObjectAssignment for ObjectProp id ${objectPropId} not found in current EventDesign`);
    }
  }


  setCurrentObjectProp(objectPropId: number) {

    if (!this.isReady(this.setCurrentObjectProp.name, false, true, false, true)) {

      return;
    }

    const objectProp = this.venue().objectProps.find(op => op.id === objectPropId);
    if (objectProp) {

      this.guestService.setCurrentObjectProp(objectProp.id);
      return;
    } else {

      this.logger.trace(`ObjectProp id ${objectPropId} not found in Venue`);
    }
  }


  setCurrentVideoAssignments(videoPropId: number) {

    if (!this.isReady(this.setCurrentVideoAssignments.name, false, true, false, true)) {

      return;
    }
    const videoProp = this.venue().videoProps.find(vp => vp.id === videoPropId);
    if (!videoProp) {

      return;
    }

    const videoAssignments = this.getVideoAssignmentsOrDefaultForVideoProp(videoProp);
    if (0 < videoAssignments.length) {

      // Ensure currentImageAssignment is in imageAssignments.
      if (0 > videoAssignments.findIndex(ia => ia.id === this.currentVideoAssignment().id)) {

        this.guestService.setCurrentVideoAssignment(videoAssignments[0]);
      }

      const designVideo = this.getDesignVideoOrDefaultForVideoAssignment(this.currentVideoAssignment());
      if (designVideo) {

        this.logger.trace(`designVideo`, designVideo);
        this.guestService.setCurrentDesignVideo(designVideo);
      } else {

        this.logger.trace(`DesignVideo for VideoAssignment id ${videoAssignments[0].id} not found in current EventDesign`);
      }

      return;
    } else {

      this.guestService.setCurrentVideoAssignment(new VideoAssignment());
      this.logger.trace(`VideoAssignment for VideoProp id ${videoPropId} not found in current EventDesign`);
    }
  }


  setCurrentVideoProp(videoPropId: number): VideoProp | undefined {

    if (!this.isReady(this.setCurrentVideoProp.name, false, true, false, false)) {

      return;
    }

    const videoProp = this.venue().videoProps.find(vp => vp.id === videoPropId);

    if (videoProp) {

      this.guestService.setCurrentVideoProp(videoProp.id);
    } else {

      this.logger.trace(`VideoProp id ${videoPropId} not found in Venue`);
    }

    return videoProp;
  }


  setDefaultImageAssignmentAndDesignForProp(imageProp: ImageProp) {

    if (!this.isReady(this.setDefaultImageAssignmentAndDesignForProp.name, false, true, false, true)) {

      return;
    }

    const imageAssignments = this.getImageAssignmentsOrDefaultForImageProp(imageProp);
    if (0 < imageAssignments.length) {

      imageAssignments.sort((ia1, ia2) => ia1.id - ia2.id);
      this.logger.trace(`Image Assignments for Image Prop id: {imageProp.id}`, imageAssignments);

      // Ensure currentImageAssignment is in imageAssignments.
      if (0 > imageAssignments.findIndex(ia => ia.id === this.currentImageAssignment().id)) {

        this.logger.trace(`Changing current Image Assignment from - to`, deepCopy(this.currentImageAssignment()), imageAssignments[0]);
        this.guestService.setCurrentImageAssignment(imageAssignments[0]);
      }

      const designImage = this.getDesignImageOrDefaultForImageAssignment(this.currentImageAssignment());
      if (designImage) {

        this.logger.trace(`Setting current Design Image`, designImage);
        this.guestService.setCurrentDesignImage(designImage);
      } else {

        this.logger.error(`DesignImage for ImageAssignment id ${imageAssignments[0].id} not found in current EventDesign`);
      }
    } else {

      this.logger.trace(`ImageProp id ${imageProp.id} has no Image Assignments`);
      this.guestService.setCurrentImageAssignment(new ImageAssignment());
      this.guestService.setCurrentDesignImage(new DesignImage());
    }
  }


  setDefaultVideoAssignmentAndDesignForProp(videoProp: VideoProp) {

    if (!this.isReady(this.setDefaultVideoAssignmentAndDesignForProp.name, false, true, false, true)) {

      return;
    }

    const videoAssignments = this.getVideoAssignmentsOrDefaultForVideoProp(videoProp);
    if (0 < videoAssignments.length) {

      videoAssignments.sort((va1, va2) => va1.id - va2.id);
      this.logger.trace(`Video Assignments for Video Prop id: {imageProp.id}`, videoAssignments);

      // Ensure currentImageAssignment is in videoAssignments.
      if (0 > videoAssignments.findIndex(va => va.id === this.currentVideoAssignment().id)) {

        this.logger.trace(`Changing current Video Assignment from - to`, deepCopy(this.currentVideoAssignment()), videoAssignments[0]);
        this.guestService.setCurrentVideoAssignment(videoAssignments[0]);
      }

      const designVideo = this.getDesignVideoOrDefaultForVideoAssignment(this.currentVideoAssignment());
      if (designVideo) {

        this.logger.trace(`Setting current Design Video`, designVideo);
        this.guestService.setCurrentDesignVideo(designVideo);
      } else {

        this.logger.error(`DesignVideo for VideoAssignment id ${videoAssignments[0].id} not found in current EventDesign`);
      }
    } else {

      this.logger.trace(`Video Prop id ${videoProp.id} has no Video Assignments`);
      this.guestService.setCurrentVideoAssignment(new VideoAssignment());
      this.guestService.setCurrentDesignImage(new DesignImage());
    }
  }


  /**
   * Guest EventDesigns are eagerly downloaded with Venue.
   * @param eventDesignId
   * @returns 
   */
  async selectEventDesign(eventDesignId: number) {

    // If EventDesign is already current then we're done
    if (this.currentEventDesign().id === eventDesignId) {

      return;
    }
    this.logger.trace(`EventDesign id: ${eventDesignId}`);

    // If we're not ready, the value is stored and will be called when ready.
    this._selectedEventDesignId = eventDesignId;
    if (!this.isReady(this.selectEventDesign.name, false, false, false, true)) return;
    this._selectEventDesignInitialized = true;

    if (1 > eventDesignId) {
      this.logger.trace(`invalid eventDesignId: ${eventDesignId}`);
      // Invoke apply design anyway so that defaults can be applied.
      this.applyEventDesignToStage();
      return;
    }

    // Use EventDesign already downloaded.
    const eventDesign = this.currentVenueEvent().eventDesigns.find(ed => ed.id === eventDesignId);
    if (eventDesign) {

      this.guestService.setCurrentEventDesign(eventDesign.id);
      this.logger.trace(`using existing EventDesign: ${this.currentEventDesign().name}`, eventDesign);
      this.applyEventDesignToStage();
    }
  }


  /**
   * VenueEvents will request EventDesigns and cache as they are reference.
   * @param venueEventId 
   * @returns 
   */
  async selectVenueEvent(venueEventId: number) {

    // If VenueEvent is already current then we're done
    if (this.currentVenueEvent().id === venueEventId) {

      return;
    }
    this.logger.trace(`VenueEvent id: ${venueEventId}`);

    // If we're not ready, the value is stored and will be called when ready.
    this._selectedVenueEventId = venueEventId;
    if (!this.isReady(this.selectVenueEvent.name, false, true)) {

      return;
    }

    this.logger.trace(`venueEventId: ${venueEventId}`);
    const venueEventIndex = this.venue().venueEvents.findIndex(ve => ve.id === venueEventId);
    if (-1 < venueEventIndex) {

      this.guestService.setCurrentVenueEvent(this.venue().venueEvents[venueEventIndex].id);
      this.logger.trace(`current VenueEvent: ${this.currentVenueEvent().name}`, this.currentVenueEvent());

      let eventDesigns = this.currentVenueEvent().eventDesigns;

      // Ensure default EventDesign
      if (!this._selectEventDesignInitialized && 0 < this._selectedEventDesignId) {

        this.selectEventDesign(this._selectedEventDesignId);
      } else {

        this.logger.trace(`trying to choose the first, non-default EventDesign`, eventDesigns);
        const firstNonDefaultEventDesignIndex = eventDesigns.findIndex(ed => !ed.isDefault);
        if (-1 < firstNonDefaultEventDesignIndex) {

          this.selectEventDesign(eventDesigns[firstNonDefaultEventDesignIndex].id);
        } else {

          this.selectEventDesign(eventDesigns[0].id);
        }
      }
    }
  }


  private tryLoadingProps() {

    if (!this._stage) {

      return;
    }

    this._stage.clear();
    this.addImageProps();
    this.addObjectProps();
    this.addVideoProps();

    this.applyEventDesignToStage();
  }


}
