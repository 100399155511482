import { CUSTOM_ELEMENTS_SCHEMA, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignObject, IDesignObject, IImportableObjectSearchResult} from 'projects/my-common/src/model';
import { NGXLogger } from 'ngx-logger';
import { DesignObjectService } from 'src/app/core/service/venue/design-object.service';
import { SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { IMPORT_OBJECT_SIDEBAR_ID } from '../import-object-sidebar/import-object-sidebar.component';

@Component({
  selector: 'app-import-design-object-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './import-design-object-card.component.html',
  styleUrls: ['./import-design-object-card.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImportDesignObjectCardComponent {
  
  readonly DesignObject = input.required<IImportableObjectSearchResult>();


  constructor(private designObjectService: DesignObjectService,
    private sidebarService: SidebarService,
    private logger: NGXLogger) { }


  onAddToDesign() {

    this.sidebarService.close(IMPORT_OBJECT_SIDEBAR_ID);
    const designObject = this.DesignObject();
    const importDesignObject = <IDesignObject>{
      eventDesignId: designObject.eventDesignId,
      fileName: designObject.fileName,
      id: designObject.designObjectId,
      objectUrl: designObject.objectUrl,
      uploadFileName: designObject.uploadFileName
    }
    this.designObjectService.broadcastImportDesignObject(new DesignObject(importDesignObject));
  }
}
