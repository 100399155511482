import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnDestroy, QueryList, ViewChildren, computed, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapBagPlus, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { DesignVideo, VideoAssignment } from 'projects/my-common/src/model';
import { NGXLogger } from 'ngx-logger';
import { IStage } from 'src/app/core/model/showroom/showroom.model';
import { isChildElement } from 'projects/my-common/src/util/utils';
import { IShowroomCartItem } from 'src/app/core/model/cart.model';

export const VIDEO_PROP_OPTIONS_SIDEBAR_ID = 'ff8764e3-62f5-40a3-80b3-38bf292e45c3';


@Component({
  selector: 'app-video-prop-options-sidebar',
  standalone: true,
  templateUrl: './video-prop-options-sidebar.component.html',
  styleUrl: './video-prop-options-sidebar.component.scss',
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons({ bootstrapBagPlus, bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VideoPropOptionsSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  sidebarId: string = VIDEO_PROP_OPTIONS_SIDEBAR_ID;
  id = crypto.randomUUID();

  readonly isOpen = signal(false);
  readonly closing = signal(false);

  readonly approvedOpen = computed(() => this.isOpen() && 0 < this.VideoAssignment().id);
  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('videoPropOptionsSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  /**
   * Template need to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.approvedOpen()) {

      const elementRef = (this.elementRef.first as any).nativeElement;
      elementRef?.focus();

      this.pause();
      return true;
    }
    return false;
  });

  readonly designVideo = signal(new DesignVideo());
  @Input() set DesignVideo(value: DesignVideo) {

    this.designVideo().dispose();
    this.designVideo.set(value);
    this.initializeVideo();
  }

  readonly VideoAssignment = input.required<VideoAssignment>()
  readonly Stage = input.required<IStage>()

  readonly videoNode = computed(() => 0 < this.VideoAssignment().id && this.Stage().getVideoNode ?
    this.Stage().getVideoNode(this.VideoAssignment().videoPropId) : undefined);

  readonly AddToCart = output<IShowroomCartItem>();



  constructor(private readonly sidebarService: SidebarService,
    private readonly logger: NGXLogger) {

    sidebarService.add(this);
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.handleBlur.bind(this);
  handleBlur(event: any) {

    const elementRef = (this.elementRef.first as any).nativeElement;
    if (this._handlingPlayEvent) {

      this._handlingPlayEvent = false;
      elementRef?.focus();

      return;
    }
    if (this._handlingBlur) {

      return;
    }
    this._handlingBlur = true;

    if (!elementRef) {

      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {

      if (!isChildElement(elementRef, document.activeElement)) {

        // If sidebar video control is playing then don't auto-close.
        const video = document.getElementById(this.id) as HTMLVideoElement;
        video?.pause();
        this.handleClose();
      }
      this._handlingBlur = false;
    }, 1);
  }


  handleClose() {

    // Make sure this sidebar's video control stops playing upon close.
    this.pause();
    this.closing.set(true);
    setTimeout(() => {

      this.isOpen.set(false);
      this.closing.set(false);
    }, 200);
  }


  async initializeVideo(): Promise<void> {

    if (0 < this.designVideo().id) {

      const video = document.getElementById(this.id) as HTMLVideoElement;
      if (video) {

        this.logger.trace(`Setting video source for: video${this.designVideo().id}`, video, this.VideoAssignment());
        await this.designVideo().setVideoSource(video);
      }
    }
  }


  private pause(): void {

    if (0 < this.designVideo().id) {

      const video = document.getElementById(this.id) as HTMLVideoElement;
      video?.pause();
    }
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);

    this.designVideo().dispose();
  }


  private _handlingPlayEvent = false;
  onPlay(): void {

    this._handlingPlayEvent = true;
    this.videoNode()?.pause();
  }


  // onAddToCart() {

  //   const cartItem: IShowroomCartItem = {
  //     venueId: 0,
  //     venueName: '',
  //     venueEventId: 0,
  //     venueEventName: '',
  //     eventDesignId: 0,
  //     eventDesignName: '',
  //     designFileName: this.designVideo().uploadFileName,
  //     designId: this.designVideo().id,
  //     designUrl: this.designVideo().imageUrl,
  //     assignmentDescription: this.videoAssignment().description,
  //     assignmentId: this.videoAssignment().id,
  //     itemType: ShowroomItemType.Graphic,
  //     price: this.videoAssignment().price,
  //     propDescription: this.videoAssignment().propDescription,
  //     propId: this.videoAssignment().imagePropId,
  //     priceOptionLabel: ''   
  //   }

  //   // Showroom adds Venue and Venue Event properties.
  //   this.AddToCart.emit(cartItem);
  //   //this._cartService.addShowroomCartItem(cartItem);

  //   this.handleClose();
  // }


}
