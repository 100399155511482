import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import { equalsTextTexture, ITextTexture, TextureManager } from "../my-three/texture-manager";
import { Texture } from "three";

export interface ITextureLoaderState {

    aspect: number
    source?: string
    textTexture?: ITextTexture
    maskSrc?: string
}

/**
 * Retrieve and dispose of textures for a single Prop.
 */
export class TextureLoaderComponent extends MyOptyxComponent {

    protected override state: ITextureLoaderState = {

        aspect: 1,
        source: undefined,
        textTexture: undefined,
        maskSrc: undefined
    }
    // Pending state initialized to match current state.
    override pendingState: ITextureLoaderState = {

        aspect: 1,
        source: undefined,
        textTexture: undefined,
        maskSrc: undefined
    }

    // Events
    private readonly _textureUpdated = new Subject<Texture | undefined>();
    readonly textureLoaded$ = this._textureUpdated.asObservable();

    private _logger = getLogger();
    private _texture?: Texture;


    constructor(destroyRef: DestroyRef,
        private readonly textureManager: TextureManager) {
        super(destroyRef);
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {

        //if (this.pendingStateChanges.find(psc => psc === 'urls')) {}
        if (this.state.source !== this.pendingState.source
            || !equalsTextTexture(this.state.textTexture, this.pendingState.textTexture)) {

            this.tryLoadTexture();
        }
    }


    override getState(): ITextureLoaderState {

        // Might want to deepCopy depending on properties.
        return this.state;
    }


    override onDestroy(): void { 
        
        this._texture?.dispose();
    }


    // If overriding be sure to call base method.
    override init(): TextureLoaderComponent {
        super.init();

        return this;
    }


    private _loadingFromSource?: string;
    private tryLoadTexture(): void {

        if (!this.pendingState.source || 1 > this.pendingState.source.trim().length) {

            this.tryLoadTextTexture();
            return;
        } else if (this.pendingState.source === this._loadingFromSource) {

            return;
        }
        this._loadingFromSource = this.pendingState.source;
        const that = this;

        this.textureManager.loadTexture(
            // resource URL
            this.pendingState.source,
            // onLoad callback
            function (texture: Texture) {

                that._textureUpdated.next(texture);
                that._texture?.dispose();
                that._texture = texture;
                that._loadingFromSource = undefined;
            },
            // onError callback
            function (err: any) {
                
                that._logger.error('Error loading texture');
                that._loadingFromSource = undefined;
            },
            this.pendingState.maskSrc
        );
    }


    private _loadingText?: string;
    private async tryLoadTextTexture(): Promise<void> {

        if (!this.pendingState.textTexture) {

            if (!this._loadingFromSource) {

                this._textureUpdated.next(undefined);
            }
            return;
        } else if (this.pendingState.textTexture.text === this._loadingText) {

            return;
        }
        this._loadingText = this.pendingState.textTexture.text;

        const texture = await this.textureManager.createText(this.pendingState.textTexture, this.pendingState.aspect);
        if (this._loadingFromSource) {

            this._loadingText = undefined;
            return;
        }
        this._textureUpdated.next(texture);
        this._texture?.dispose();
        this._texture = texture;
        this._loadingText = undefined;
    }


}

