@if (isMobile) {

    @if (isOpen()) {

        <div [id]="id" class="fixed h-screen w-screen flex flex-col items-center justify-center top-0 left-0 z-50 overflow-y-auto divide-y divide-gray-100 bg-white bg-opacity-60 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1" (click)="closeOverlay()">

            @for (groupItem of groupedItems(); track $index; let first = $first, last = $last) {
    
                <div class="py-1" role="none" class="w-full max-w-2xl bg-white {{ first ? 'rounded-t-md' : '' }} {{ last ? 'rounded-b-md' : '' }}">
        
                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                    @for (item of groupItem.groupItems; track $index) {
        
                        <div (click)="selectItem(item)" href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
                            tabindex="-1" id="menu-item-0">{{item[2]}}</div>
                    }
    
                </div>
        
            }
        
        </div>

    }

} @else {

    <!--
      Dropdown menu, show/hide based on menu state.
  
      Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
    <div [id]="id" tabindex="0" [autofocus]="setFocus()" class="absolute {{ AlignRight() ? 'right-0' : 'left-0' }} z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">

        @if (isOpen()) {

            @for (groupItem of groupedItems(); track $index) {
    
                <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
        
                    @for (item of groupItem.groupItems; track $index) {
            
                        <div (click)="selectItem(item)" href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
                            tabindex="-1" id="menu-item-0">{{item[2]}}</div>
            
                    }
    
                </div>
            }

        }

    </div>

}
