import { Types, defineComponent, defineQuery } from 'bitecs';

export const TransitionAnimationComponent = defineComponent({
    duration: Types.f32,
    elapsed: Types.f32
});

/**
 * Props that are transitioning have this component added.
 * When transition is complete this component is removed.
 */
export const transitionAnimationQuery = defineQuery([TransitionAnimationComponent]);