<div
    class="rounded-lg group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
    <div class="h-72 w-full">
        <model-viewer [alt]="DesignObject().fileName" class="h-full w-full" [src]="DesignObject().objectUrl" ar
            shadow-intensity="1" camera-controls touch-action="pan-y" autoplay auto-rotate>
            <button slot="ar-button"
                style="background-color: white; color: black; border-radius: 4px; border: none; position: absolute; top: 16px; right: 16px; padding: 4px; ">
                👋 Activate AR
            </button>
        </model-viewer>
    </div>
    <!-- <div
        class="absolute inset-0 bg-gradient-to-b from-zinc-700 via-transparent to-zinc-900 group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70">
    </div> -->
    <div
        class="absolute inset-0 flex translate-y-[83%] overflow-hidden flex-col items-center px-4 transition-all duration-500 group-hover:translate-y-0">
        <div class="italic w-full">{{DesignObject().uploadFileName}}</div>
        <div class="italic w-full">{{DesignObject().venueName}}</div>
        <div class="italic w-full">{{DesignObject().venueEventName}}</div>
        <div class="italic w-full pb-4">{{DesignObject().eventDesignName}}</div>
        <button class="btn btn-accent w-[190px]" (click)="onAddToDesign()">
            Import
        </button>
    </div>
</div>