import { defineQuery, defineSystem, IWorld } from 'bitecs'
import {
    AdjustableComponent, ClippingAssignmentComponent, ClippingPlaneComponent, FromPositionComponent, FromRotationComponent, FromScaleComponent,
    PositionAdjustmentComponent, RotationAdjustmentComponent, ScaleAdjustmentComponent
} from '..';
import { AdjustmentInitState, getAdjustmentEidForCurrentPosition, getClippingAssignmentEidForCurrentPosition } from './adjustment-init.state';
import { WorldState } from '../ecs-world.state';
import { AdjustmentComponent } from '../component/adjustment.component';
import { getClippingAssignmentEidForDestinationPosition } from './adjustable-transition-init.state';

const adjustableEntityQuery = defineQuery([AdjustableComponent]);

const clippingPlaneEntityQuery = defineQuery([ClippingPlaneComponent]);

/**
 * Triggered by change in the current position from stage.
 * Initialize the 'current' and 'from' position adjustment values in preparation for subsequent transition systems.
 */
export const adjustmentInitSystem = defineSystem(world => {

    if (AdjustmentInitState.currentShowroomPositionId === WorldState.currentPositionEntityId) {

        return world;
    }
    AdjustmentInitState.currentShowroomPositionId = WorldState.currentPositionEntityId;

    initAdjustables(world);
    initAdjustmentLevelClippingAssignments(world);

    return world;
});


/**
 * Set From and Base values from Adjustment entity or default values
 * Initial From value = Base value = value from Adjustment
 * @param world 
 */
function initAdjustables(world: IWorld) {

    let eidForAdjustable = 0, eidForAdjustment = 0;
    const adjustableEntities = adjustableEntityQuery(world);
    for (let i = 0; i < adjustableEntities.length; i++) {

        eidForAdjustable = adjustableEntities[i];
        eidForAdjustment = getAdjustmentEidForCurrentPosition(eidForAdjustable);
        if (-1 < eidForAdjustment) {

            // if (AdjustmentComponent.hide[eidForAdjustment]) {

            //     FromMaskComponent.hide[eidForAdjustable] = 1;
            //     FromHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.LEFT;
            //     FromHorizontalMaskComponent.offset[eidForAdjustable] = HorizontalMaskComponent.offset[eidForAdjustable] = 0;
            //     FromHorizontalMaskComponent.width[eidForAdjustable] = HorizontalMaskComponent.width[eidForAdjustable] = 1;
            //     FromVerticalMaskComponent.mode[eidForAdjustable] = HorizontalMaskComponent.mode[eidForAdjustable] = VerticalClipMode.TOP;
            //     FromVerticalMaskComponent.offset[eidForAdjustable] = HorizontalMaskComponent.offset[eidForAdjustable] = 0;
            //     FromVerticalMaskComponent.height[eidForAdjustable] = HorizontalMaskComponent.width[eidForAdjustable] = 1;
            // } else {

            //     FromMaskComponent.hide[eidForAdjustable] = 0;
            //     FromHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalMaskComponent.mode[eidForAdjustable] = AdjustmentComponent.horizontalMaskMode[eidForAdjustment];
            //     FromHorizontalMaskComponent.offset[eidForAdjustable] = HorizontalMaskComponent.offset[eidForAdjustable] = AdjustmentComponent.horizontalMaskOffset[eidForAdjustment];
            //     FromHorizontalMaskComponent.width[eidForAdjustable] = HorizontalMaskComponent.width[eidForAdjustable] = AdjustmentComponent.horizontalMaskWidth[eidForAdjustment];

            //     FromVerticalMaskComponent.mode[eidForAdjustable] = VerticalMaskComponent.mode[eidForAdjustable] = AdjustmentComponent.verticalMaskMode[eidForAdjustment];
            //     FromVerticalMaskComponent.offset[eidForAdjustable] = VerticalMaskComponent.offset[eidForAdjustable] = AdjustmentComponent.verticalMaskOffset[eidForAdjustment];
            //     FromVerticalMaskComponent.height[eidForAdjustable] = VerticalMaskComponent.height[eidForAdjustable] = AdjustmentComponent.verticalMaskHeight[eidForAdjustment];
            // }

            // From value                                              Base value                                             Adjustment
            FromPositionComponent.x[eidForAdjustable] = PositionAdjustmentComponent.x[eidForAdjustable] = AdjustmentComponent.position.x[eidForAdjustment];
            FromPositionComponent.y[eidForAdjustable] = PositionAdjustmentComponent.y[eidForAdjustable] = AdjustmentComponent.position.y[eidForAdjustment];
            FromPositionComponent.z[eidForAdjustable] = PositionAdjustmentComponent.z[eidForAdjustable] = AdjustmentComponent.position.z[eidForAdjustment];

            FromRotationComponent.x[eidForAdjustable] = RotationAdjustmentComponent.x[eidForAdjustable] = AdjustmentComponent.rotation.x[eidForAdjustment];
            FromRotationComponent.y[eidForAdjustable] = RotationAdjustmentComponent.y[eidForAdjustable] = AdjustmentComponent.rotation.y[eidForAdjustment];
            FromRotationComponent.z[eidForAdjustable] = RotationAdjustmentComponent.z[eidForAdjustable] = AdjustmentComponent.rotation.z[eidForAdjustment];

            FromScaleComponent.x[eidForAdjustable] = ScaleAdjustmentComponent.x[eidForAdjustable] = AdjustmentComponent.scale.x[eidForAdjustment];
            FromScaleComponent.y[eidForAdjustable] = ScaleAdjustmentComponent.y[eidForAdjustable] = AdjustmentComponent.scale.y[eidForAdjustment];
            FromScaleComponent.z[eidForAdjustable] = ScaleAdjustmentComponent.z[eidForAdjustable] = AdjustmentComponent.scale.z[eidForAdjustment];

        } else {

            FromPositionComponent.x[eidForAdjustable] = FromPositionComponent.y[eidForAdjustable] = FromPositionComponent.z[eidForAdjustable] =
                PositionAdjustmentComponent.x[eidForAdjustable] = PositionAdjustmentComponent.y[eidForAdjustable] = PositionAdjustmentComponent.z[eidForAdjustable] =
                FromRotationComponent.x[eidForAdjustable] = FromRotationComponent.y[eidForAdjustable] = FromRotationComponent.z[eidForAdjustable] =
                RotationAdjustmentComponent.x[eidForAdjustable] = RotationAdjustmentComponent.y[eidForAdjustable] = RotationAdjustmentComponent.z[eidForAdjustable] =
                FromScaleComponent.x[eidForAdjustable] = FromScaleComponent.y[eidForAdjustable] = FromScaleComponent.z[eidForAdjustable] =
                ScaleAdjustmentComponent.x[eidForAdjustable] = ScaleAdjustmentComponent.y[eidForAdjustable] = ScaleAdjustmentComponent.z[eidForAdjustable] = 0;

            // FromMaskComponent.hide[eidForAdjustable] = 0;
            // FromHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.NONE;
            // FromHorizontalMaskComponent.offset[eidForAdjustable] = HorizontalMaskComponent.offset[eidForAdjustable] = 0;
            // FromHorizontalMaskComponent.width[eidForAdjustable] = HorizontalMaskComponent.width[eidForAdjustable] = 1;

            // FromVerticalMaskComponent.mode[eidForAdjustable] = VerticalMaskComponent.mode[eidForAdjustable] = VerticalClipMode.NONE;
            // FromVerticalMaskComponent.offset[eidForAdjustable] = VerticalMaskComponent.offset[eidForAdjustable] = 0;
            // FromVerticalMaskComponent.height[eidForAdjustable] = VerticalMaskComponent.height[eidForAdjustable] = 1;
        }

    }
}


/**
 * Set From and Base values from Assignment entity or default values
 * Initial From value = Base value = value from Adjustment
 * @param world 
 */
function initAdjustmentLevelClippingAssignments(world: IWorld) {

    let eidForClippingPlane = 0, eidForClippingAssignment = 0;
    const clippingPlaneEntities = clippingPlaneEntityQuery(world);
    for (let i = 0; i < clippingPlaneEntities.length; i++) {

        eidForClippingPlane = clippingPlaneEntities[i];
        eidForClippingAssignment = getClippingAssignmentEidForCurrentPosition(eidForClippingPlane);

        // If we have an Adjustment level assignment then use its values.
        if (-1 < eidForClippingAssignment) {

            // From value                                                 Base value                                                Assignment
            FromPositionComponent.x[eidForClippingPlane] = PositionAdjustmentComponent.x[eidForClippingPlane] = ClippingAssignmentComponent.position.x[eidForClippingAssignment];
            FromPositionComponent.y[eidForClippingPlane] = PositionAdjustmentComponent.y[eidForClippingPlane] = ClippingAssignmentComponent.position.y[eidForClippingAssignment];
            FromPositionComponent.z[eidForClippingPlane] = PositionAdjustmentComponent.z[eidForClippingPlane] = 0;

            FromRotationComponent.x[eidForClippingPlane] = RotationAdjustmentComponent.x[eidForClippingPlane] = 0;
            FromRotationComponent.y[eidForClippingPlane] = RotationAdjustmentComponent.y[eidForClippingPlane] = 0;
            FromRotationComponent.z[eidForClippingPlane] = RotationAdjustmentComponent.z[eidForClippingPlane] = ClippingAssignmentComponent.rotationZ[eidForClippingAssignment];

            FromScaleComponent.x[eidForClippingPlane] = ScaleAdjustmentComponent.x[eidForClippingPlane] = ClippingAssignmentComponent.scale.x[eidForClippingAssignment];
            FromScaleComponent.y[eidForClippingPlane] = ScaleAdjustmentComponent.y[eidForClippingPlane] = ClippingAssignmentComponent.scale.y[eidForClippingAssignment];
            FromScaleComponent.z[eidForClippingPlane] = ScaleAdjustmentComponent.z[eidForClippingPlane] = 0;
        } else if (0 !== ClippingPlaneComponent.activatePropLevel[eidForClippingPlane]) {

            // If we have a Prop level assignment then use its values
            // From value                                                 Base value                                                Assignment
            FromPositionComponent.x[eidForClippingPlane] = PositionAdjustmentComponent.x[eidForClippingPlane] = ClippingPlaneComponent.position.x[eidForClippingPlane];
            FromPositionComponent.y[eidForClippingPlane] = PositionAdjustmentComponent.y[eidForClippingPlane] = ClippingPlaneComponent.position.y[eidForClippingPlane];
            FromPositionComponent.z[eidForClippingPlane] = PositionAdjustmentComponent.z[eidForClippingPlane] = 0;

            FromRotationComponent.x[eidForClippingPlane] = RotationAdjustmentComponent.x[eidForClippingPlane] = 0;
            FromRotationComponent.y[eidForClippingPlane] = RotationAdjustmentComponent.y[eidForClippingPlane] = 0;
            FromRotationComponent.z[eidForClippingPlane] = RotationAdjustmentComponent.z[eidForClippingPlane] = ClippingPlaneComponent.rotationZ[eidForClippingPlane];

            FromScaleComponent.x[eidForClippingPlane] = ScaleAdjustmentComponent.x[eidForClippingPlane] = ClippingPlaneComponent.scale.x[eidForClippingPlane];
            FromScaleComponent.y[eidForClippingPlane] = ScaleAdjustmentComponent.y[eidForClippingPlane] = ClippingPlaneComponent.scale.y[eidForClippingPlane];
            FromScaleComponent.z[eidForClippingPlane] = ScaleAdjustmentComponent.z[eidForClippingPlane] = 0;
        } else {

            setDefaultFromValues(eidForClippingPlane);
        }
    }
}


/**
 * Called when there is not From Clipping Assignment defined
 * @param entityIdForClippingPlane 
 */
function setDefaultFromValues(entityIdForClippingPlane: number) {

    // Try setting the postion and rotation to the Destination Clipping Assignment position and rotation if it is defined.
    const entityIdForDestinationClippingAssignment = getClippingAssignmentEidForDestinationPosition(entityIdForClippingPlane);
    if (-1 < entityIdForDestinationClippingAssignment) {

        FromPositionComponent.x[entityIdForClippingPlane] = PositionAdjustmentComponent.x[entityIdForClippingPlane] = ClippingAssignmentComponent.position.x[entityIdForDestinationClippingAssignment];
        FromPositionComponent.y[entityIdForClippingPlane] = PositionAdjustmentComponent.y[entityIdForClippingPlane] = ClippingAssignmentComponent.position.y[entityIdForDestinationClippingAssignment];
        FromPositionComponent.z[entityIdForClippingPlane] = PositionAdjustmentComponent.z[entityIdForClippingPlane] = 0;

        FromRotationComponent.x[entityIdForClippingPlane] = RotationAdjustmentComponent.x[entityIdForClippingPlane] = 0;
        FromRotationComponent.y[entityIdForClippingPlane] = RotationAdjustmentComponent.y[entityIdForClippingPlane] = 0;
        FromRotationComponent.z[entityIdForClippingPlane] = RotationAdjustmentComponent.z[entityIdForClippingPlane] = ClippingAssignmentComponent.rotationZ[entityIdForDestinationClippingAssignment];
    } else {

        FromPositionComponent.x[entityIdForClippingPlane] = FromPositionComponent.y[entityIdForClippingPlane] = FromPositionComponent.z[entityIdForClippingPlane] =
            FromRotationComponent.x[entityIdForClippingPlane] = FromRotationComponent.y[entityIdForClippingPlane] = FromRotationComponent.z[entityIdForClippingPlane] = 0
    }

    // Scale is always 0 if From Clipping Assignment is not defined.
    FromScaleComponent.y[entityIdForClippingPlane] = FromScaleComponent.x[entityIdForClippingPlane] = FromScaleComponent.y[entityIdForClippingPlane] = 0;
}