import { DestroyRef, Injectable, inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ComponentInteractionType, registerSceneComponentTypes } from 'projects/mp-common/src';
import { Subject } from 'rxjs/internal/Subject';
import { IPlayerPosition, IStage, PositionTransition } from 'src/app/core/model/showroom/showroom.model';
import sceneJson from 'src/assets/vs-app.json';
import { ImageAssignment, IImageProp, IObjectProp, IProp, IVideoProp } from 'projects/my-common/src/model';
import { ThreeHelper } from 'src/app/helpers/three-helper/three.helper';
import { UnselectedColor, UnselectedOpacity, UnselectedLineOpacity, SelectedColor, SelectedOpacity, SelectedLineOpacity, ItemDesc, SCENE_UNDEFINED_MESSAGE, MP_PlaceholderProp as PlaceholderPropNode } from 'src/app/core/model/matterport/matterport.model';
import { Subscription } from 'rxjs';

import { stageObjectCategories, stageObjectOptions } from '../matterport/app-state';
import { environment } from 'src/environments/environment';
import { Camera, IObservable, ISubscription, MpSdk, Scene } from 'static/sdk';

import { ImagePropNode } from 'src/app/core/model/matterport/image-prop-node.model';
import { ObjectPropNode } from 'src/app/core/model/matterport/object-prop-node.model';
import { VideoPropNode } from 'src/app/core/model/matterport/video-prop-node.model';
import { IShowroomImageProp } from 'src/app/core/model/showroom/image-prop.model';
import { IShowroomVideoProp } from 'src/app/core/model/showroom/video-prop.model';
import { IShowroomObjectProp } from 'src/app/core/model/showroom/object-prop.model';
import { FORWARD, GltfManager, TextureManager, Vector3Obj, Vector3Tuple, debounce, equals, getNormalizedDirectionFromQuaternion, getPosition } from 'projects/my-common/src';
import { ACESFilmicToneMapping, Euler, Quaternion, Vector3, WebGLRenderer } from 'three';
import { EnvironmentNode } from 'src/app/core/model/matterport/environment-node.model';

import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ICameraPose, ImageProp, ObjectProp, IShowroomPosition, VideoProp } from 'projects/my-common/src/model';
import { ShowroomMode } from 'src/app/state/showroom-state';
import { EcsWorld } from 'projects/my-common/src/ecs';

export type ConfigureThreeCallback = (renderer: any, three: any) => void;
const NOT_INITIALIZED_MESSAGE = 'MatterportService not initialized';


/**
 * Matterport implementation of Showroom Stage
 */
@Injectable({
  providedIn: 'root'
})
export class MatterportService implements IStage {

  private readonly _destroying$ = new Subject<void>();
  private _destroyRef!: DestroyRef;
  private readonly _subscriptions: Subscription[] = [];
  private readonly _iSubscriptions: ISubscription[] = [];
  private _mpSdk!: MpSdk;
  private _sceneObject!: Scene.IObject;
  private _threeHelper!: ThreeHelper;
  private _sweepData?: MpSdk.Dictionary<MpSdk.Sweep.ObservableSweepData>;
  isInitialized: boolean = false;


  private _cameraPositionChangedSource = new Subject<ICameraPose>();
  cameraPoseChanged$ = this._cameraPositionChangedSource.asObservable();

  // For UI, observable fired when ImageProp is selected
  private _imagePropSelectedSource = new Subject<number>();
  imagePropSelected$ = this._imagePropSelectedSource.asObservable();

  private _imageAssignmentChangedSource = new Subject<ImageAssignment>();
  imageAssignmentChanged$ = this._imageAssignmentChangedSource.asObservable();

  // For UI, observable fired when Prop is selected
  // Provides list of object options for the stage object
  private _objectPropSelectedSource = new Subject<number>();
  objectPropSelected$ = this._objectPropSelectedSource.asObservable();

  // For UI, observable fired when PlaceholderProp is selected
  // Provides list of object options for the object
  private _placeholderPropSelectedSource = new Subject<ItemDesc[]>();
  placeholderPropSelected$ = this._placeholderPropSelectedSource.asObservable();

  // Observable fired when the player position changes
  private _playerPositionSource = new Subject<IPlayerPosition>();
  playerPositionUpdate$ = this._playerPositionSource.asObservable();

  private _positionsLoadedSource = new Subject<object>();
  positionsLoaded$ = this._positionsLoadedSource.asObservable();

  // For UI, observable fired when VideoProp is selected
  private _videoPropSelectedSource = new Subject<number>();
  videoPropSelected$ = this._videoPropSelectedSource.asObservable();

  private _transitionFinishedSource = new Subject<string>();
  transitionFinished$ = this._transitionFinishedSource.asObservable();

  private _transitionStartedSource = new Subject<PositionTransition>();
  transitionStarted$ = this._transitionStartedSource.asObservable();

  private _videoPropAddedSource = new Subject<VideoPropNode>();
  videoPropAdded$ = this._videoPropAddedSource.asObservable();

  // @ts-ignore
  three!: typeof THREE;
  private _textureManager!: TextureManager;
  private _gltfManager!: GltfManager;

  currentPlayerPosition?: IPlayerPosition;

  private readonly _baseNodes: Scene.INode[] = [];
  private readonly _imagePropNodes: ImagePropNode[] = [];

  private _objectPropNodes: ObjectPropNode[] = [];
  private _currentObjectProp?: ObjectPropNode;
  get currentObjectProp(): ObjectPropNode | undefined {
    return this._currentObjectProp;
  }

  private _objectPlaceholders: PlaceholderPropNode[] = [];
  private _currentPlaceholderObject?: PlaceholderPropNode;
  get currentObjectPlaceholder(): PlaceholderPropNode | undefined {
    return this._currentPlaceholderObject;
  }

  private readonly _videoPropNodes: VideoPropNode[] = [];

  /**
   * Used to calculate new Prop positioning
   */
  private _currentPose!: Camera.Pose;


  constructor(private readonly logger: NGXLogger) {

    this._destroyRef = inject(DestroyRef);
    this._destroyRef.onDestroy(() => this.onDestroy());
  }


  private _environmentNode!: EnvironmentNode;
  addEnvironment(): EnvironmentNode {

    if (this._environmentNode) {

      return this._environmentNode;
    }

    this._environmentNode = new EnvironmentNode(this._destroyRef, this._sceneObject, this._textureManager, this._mpSdk);
    return this._environmentNode;
  }


  /**
   * Create Matterport image prop node from Showroom ImageProp definition
   * @param imageProp 
   */
  addImageProp(imageProp: ImageProp, world: EcsWorld): ImagePropNode {

    const imagePropNode = new ImagePropNode(this._destroyRef, imageProp, this._sceneObject, this._textureManager, this._gltfManager, this, world);
    imagePropNode.setInputs(imageProp);  // Ensure initial position gets updated.

    this._subscriptions.push(imagePropNode.imagePropClicked$.subscribe((imagePropId: number) => this._imagePropSelectedSource.next(imagePropId)));
    this._subscriptions.push(imagePropNode.assignmentChanged$.subscribe((imageAssignment: ImageAssignment) => this._imageAssignmentChangedSource.next(imageAssignment)));
    this._imagePropNodes.push(imagePropNode);

    if (this._currentPose && this._currentPose.position) {

      imagePropNode.onCameraPositionChanged({
        x: this._currentPose.position.x,
        y: this._currentPose.position.y,
        z: this._currentPose.position.z,
      });
    }

    return imagePropNode;
  }


  /**
   * This is called by ThreeHelper
   * @param name 
   * @returns 
   */
  async addNode(name?: string): Promise<Scene.INode> {

    if (!this._sceneObject) {

      throw new Error(SCENE_UNDEFINED_MESSAGE);
    }

    const node = this._sceneObject.addNode();
    if (node && name) {

      node.name = name;
    }

    return node;
  }


  /**
   * Create Matterport image object from Showroom ImageProp definition
   * @param imageProp 
   */
  addObjectProp(objectProp: ObjectProp): IShowroomObjectProp {

    const objectPropNode = new ObjectPropNode(this._destroyRef, objectProp, this._sceneObject, this._gltfManager, this._textureManager);

    this._subscriptions.push(objectPropNode.objectPropSelected$.subscribe((objectPropId: number) => this._objectPropSelectedSource.next(objectPropId)));
    this._objectPropNodes.push(objectPropNode);

    if (this._currentPose && this._currentPose.position) {

      objectPropNode.onCameraPositionChanged({
        x: this._currentPose.position.x,
        y: this._currentPose.position.y,
        z: this._currentPose.position.z,
      });
    }

    return objectPropNode;
  }


  // async addPlaceholderObject(inputs: PlaceholderObjectInputs, name: string | undefined) {
  //   const propNode = await this._deserializedSceneObject.addNode();

  //   if (name) propNode.name = name;

  //   propNode.position.set(5.041770935058594, .57, -13.720016479492188);

  //   const boxComponent = propNode.addComponent(ORIENTED_BOX_COMPONENT_TYPE, {
  //     color: inputs.orientedBoxColor,
  //     lineColor: inputs.orientedBoxLineColor,
  //     lineOpacity: inputs.orientedBoxLineOpacity,
  //     opacity: inputs.orientedBoxOpacity,
  //     size: inputs.objectSize,
  //     transitionTime: inputs.orientedBoxTransitionTime,
  //     visible: inputs.orientedBoxVisible
  //   }) as OrientedBox;
  //   const gltfLoader = propNode.addComponent('mp.gltfLoader', <Scene.SceneComponentOptions[Scene.Component.GLTF_LOADER]>{
  //     colliderEnabled: inputs.gltfLoaderColliderEnabled,
  //     localPosition: inputs.gltfLoaderLocalPosition,
  //     localRotation: inputs.gltfLoaderLocalRotation,
  //     localScale: inputs.gltfLoaderLocalScale,
  //     url: inputs.gltfLoaderUrl,
  //     visible: inputs.gltfLoaderVisible
  //   });
  //   const objLoader = propNode.addComponent('mp.objLoader', <Scene.SceneComponentOptions[Scene.Component.OBJ_LOADER]>{
  //     colliderEnabled: inputs.objLoaderColliderEnabled,
  //     localPosition: inputs.objLoaderLocalPosition,
  //     localRotation: inputs.objLoaderLocalRotation,
  //     localScale: inputs.objLoaderLocalScale,
  //     materialUrl: inputs.objLoaderMaterialUrl,
  //     url: inputs.objLoaderUrl,
  //     visible: inputs.objLoaderVisible
  //   });
  //   const loadingIndicator = propNode.addComponent(LOADING_INDICATOR_COMPONENT_TYPE, {
  //     loadingState: inputs.loadingIndicatorLoadingState,
  //     period: inputs.loadingIndicatorPeriod,
  //     size: inputs.loadingIndicatorSize,
  //     transitionInDuration: inputs.loadingIndicatorTransitionInDuration,
  //     color: inputs.loadingIndicatorColor,
  //     logo: inputs.loadingIndicatorLogo
  //   });

  //   // Bindings
  //   const o_objectLoaderObjectRoot = this._deserializedSceneObject.addOutputPath(objLoader, 'objectRoot');
  //   const i_loadingIndicatorLogo = this._deserializedSceneObject.addInputPath(loadingIndicator, 'logo');
  //   o_objectLoaderObjectRoot.bind(i_loadingIndicatorLogo);
  //   const o_gltfLoaderLoadingState = this._deserializedSceneObject.addOutputPath(gltfLoader, 'loadingState');
  //   const i_loadingIndicatorLoadingState = this._deserializedSceneObject.addInputPath(loadingIndicator, 'loadingState');
  //   o_gltfLoaderLoadingState.bind(i_loadingIndicatorLoadingState);

  //   // ThreeHelper is an IComponentEventSpy
  //   // Events are tracked at the node level now, not components
  //   //boxComponent.spyOnEvent(this._threeHelper);
  //   boxComponent.inputs.color = UnselectedColor;
  //   boxComponent.inputs.opacity = UnselectedOpacity;

  //   const newPlaceholderObject = <PlaceholderPropNode>{
  //     node: propNode,
  //     modelComponent: gltfLoader,
  //     boxComponent: boxComponent,
  //     inputs: inputs
  //   }

  //   const that = this;
  //   const hoverEventPath = this._deserializedSceneObject.addEventPath(boxComponent as SceneComponent, ComponentInteractionType.HOVER);
  //   class PropHoverSpy {
  //     readonly path = hoverEventPath;
  //     constructor(private _matterportService: MatterportService, private _prop: PlaceholderPropNode) { }
  //     // TODO: Formalize payload into a strongly typed return struct or just let this ClickSpy handle the values via constructor
  //     onEvent(payload: any) {
  //       //this._matterportService.handlePropInteraction(this._prop, ComponentInteractionType.HOVER);
  //     }
  //   }
  //   this._iSubscriptions.push(
  //     this.spyOnEvent(new PropHoverSpy(this, newPlaceholderObject))
  //   );

  //   const clickEventPath = this._deserializedSceneObject.addEventPath(boxComponent as SceneComponent, ComponentInteractionType.CLICK);
  //   class PropClickSpy {
  //     readonly path = clickEventPath;
  //     constructor(private _matterportService: MatterportService, private _placeholderObject: PlaceholderPropNode) { }
  //     // TODO: Formalize payload into a strongly typed return struct or just let this ClickSpy handle the values via constructor
  //     onEvent(payload: any) {
  //       this._matterportService.handlePlaceholderPropInteraction(this._placeholderObject, ComponentInteractionType.CLICK);
  //     }
  //   }
  //   this._iSubscriptions.push(
  //     this.spyOnEvent(new PropClickSpy(this, newPlaceholderObject))
  //   );

  //   this._objectPlaceholders.push(newPlaceholderObject)
  //   propNode.start();
  // }


  /**
   * Create Matterport video object from Showroom VideoProp definition
   * @param videoProp 
   */
  addVideoProp(videoProp: VideoProp, world: EcsWorld): IShowroomVideoProp {

    const videoPropNode = new VideoPropNode(this._destroyRef, videoProp, this._sceneObject, this._textureManager, this._gltfManager, this, world);

    this._subscriptions.push(videoPropNode.videoPropSelected$.subscribe((videoPropId: number) => this._videoPropSelectedSource.next(videoPropId)));
    this._videoPropNodes.push(videoPropNode);
    this._videoPropAddedSource.next(videoPropNode);

    if (this._currentPose && this._currentPose.position) {

      videoPropNode.onCameraPositionChanged({
        x: this._currentPose.position.x,
        y: this._currentPose.position.y,
        z: this._currentPose.position.z,
      });
    }

    return videoPropNode;
  }


  applyPositionAdjustments(): void {

    if (!this.currentPlayerPosition) {

      this.logger.trace('currentPlayerPosition is undefined');
      return;
    }

    let ipn: ImagePropNode;
    for (ipn of this._imagePropNodes) {

      ipn.applyPositionAdjustment()
    }
    let vpn: VideoPropNode;
    for (vpn of this._videoPropNodes) {

      vpn.applyPositionAdjustment()
    }
  }


  private captureSweepData(matterportSdk: MpSdk): void {

    // One time capture information on all sweeps in the Model
    let sweepDataSubscription: ISubscription;
    const unsubscribe = () => { setTimeout(() => sweepDataSubscription.cancel()) };

    sweepDataSubscription = matterportSdk.Sweep.data.subscribe({
      onCollectionUpdated: async (collection) => {

        this._sweepData = collection
        // for (const [key, item] of collection) {
        //   this._logger.trace(`the collection item at the index ${key}`, item);
        //   this._logger.trace('the label for this item', await this._mpSdk?.Sweep.Conversion.getLabelFromId(key));
        // }
        this._positionsLoadedSource.next({ positions: collection });
        unsubscribe();
      }
    });
  }



  /**
   * Stop all nodes except the base nodes;
   */
  clear(isDestroy: boolean = false) {

    if (!this._mpSdk) throw new Error(NOT_INITIALIZED_MESSAGE);
    if (!this._sceneObject) throw new Error(SCENE_UNDEFINED_MESSAGE);

    let node: MpSdk.Scene.INode;
    for (node of this._sceneObject.nodeIterator()) {

      const baseNodeIndex = this._baseNodes.findIndex(bn => bn === node);

      if (isDestroy || 0 > baseNodeIndex) node.stop();
    }
  }


  deselectAllProps(): void {

    let ipn: ImagePropNode;
    for (ipn of this._imagePropNodes) {

      ipn.setSelected(false);
    };
    let opn: ObjectPropNode
    for (opn of this._objectPropNodes) {

      opn.setSelected(false);
    };
    let vpn: VideoPropNode
    for (vpn of this._videoPropNodes) {

      vpn.setSelected(false);
    };
  }


  handlePlaceholderPropInteraction(prop: PlaceholderPropNode, interactionType: ComponentInteractionType) {
    const component = prop.boxComponent;
    if (interactionType === ComponentInteractionType.CLICK) {
      // select this node
      let placeholderObject: PlaceholderPropNode;
      for (placeholderObject of this._objectPlaceholders) {
        if (placeholderObject.boxComponent === component) {
          const _lastSelectedPlaceholderObject = this._currentPlaceholderObject;
          if (_lastSelectedPlaceholderObject) {
            _lastSelectedPlaceholderObject.boxComponent.inputs.color = UnselectedColor;
            _lastSelectedPlaceholderObject.boxComponent.inputs.opacity = UnselectedOpacity;
            _lastSelectedPlaceholderObject.boxComponent.inputs.lineOpacity = UnselectedLineOpacity;
          }

          if (_lastSelectedPlaceholderObject === placeholderObject) {
            this._threeHelper.setCameraFocus(null);
          } else {
            this._currentPlaceholderObject = placeholderObject;
            placeholderObject.boxComponent.inputs.color = SelectedColor;
            placeholderObject.boxComponent.inputs.opacity = SelectedOpacity;
            placeholderObject.boxComponent.inputs.lineOpacity = SelectedLineOpacity;
            this._threeHelper.setCameraFocus(placeholderObject.node.position);
          }
        }
      }

      if (this._currentPlaceholderObject) {
        const category = stageObjectCategories.get(this._currentPlaceholderObject.node.name);
        if (category) {
          const selectedStageObjectOptions = stageObjectOptions.get(category);
          if (selectedStageObjectOptions) {
            /**
             * Notify listeners
             * Events initiated by Matterport need to be run in zone
             */
            this._placeholderPropSelectedSource.next(selectedStageObjectOptions);
          }
        }
      }
    }
  }


  private _hidingPosition = false;
  async hideAdjustedPositions(prop: IProp): Promise<void> {

    if (this._hidingPosition) {

      return;
    }
    this._hidingPosition = true;

    const positionIds = prop.positionAdjustments.map(pa => pa.positionId);
    await this._mpSdk.Sweep.disable(...positionIds);

    setTimeout(async () => {

      await this._mpSdk.Sweep.enable(...positionIds);
      this._hidingPosition = false;
    }, 10000);
  }


  getCameraPose(): IObservable<Camera.Pose> {
    if (!this._mpSdk) throw new Error(NOT_INITIALIZED_MESSAGE);
    return this._mpSdk.Camera.pose;
  }


  getClosestPositionId(position: Vector3Obj): string | undefined {

    if (!this._sweepData) {

      return;
    }
    let closestId: string | undefined = undefined;
    let smallestDistance = Number.MAX_SAFE_INTEGER;

    let record: [key: string, value: MpSdk.Sweep.ObservableSweepData];
    for (record of this._sweepData) {
      const distance = new this.three.Vector3(record[1].position.x, record[1].position.y, record[1].position.z).distanceTo(new this.three.Vector3(position.x, position.y, position.z));

      if (distance < smallestDistance) {
        closestId = record[0];
        smallestDistance = distance;
      }
    }

    return closestId;
  }


  getImageNode(imagePropId: number): IShowroomImageProp | undefined {

    return this._imagePropNodes.find(ipn => ipn.propId === imagePropId);
  }


  /**
   * Calculate the horizontal rotation in degrees for the camera to "look at" the specified target position.
   * @param targetPosition 
   * @param cameraPosition 
   * @returns 
   */
  private getLookAtRotation(targetPosition: Vector3Obj, cameraPosition: MpSdk.Vector3): number {

    const rotationInDegrees = Math.atan2(cameraPosition.x - targetPosition.x, cameraPosition.z - targetPosition.z) * (180 / Math.PI);
    this.logger.trace(`rotationInDegrees: ${rotationInDegrees}`);

    return rotationInDegrees;
  }


  /**
   * Identify a THREE position in front of the camera to instantiate a new Prop.
   * @param distanceInFrontOfCamera 
   * @returns 
   */
  getNewPropPlacementPosition(distanceInFrontOfCamera: number = 2): Vector3Tuple {

    // Translate the camera pose rotation into Quaternion
    const horizontalFocusedQuaternion = this.poseRotationToQuaternion(this._currentPose.rotation, true);
    // Get forward direction given our current rotation.
    const direction = getNormalizedDirectionFromQuaternion(horizontalFocusedQuaternion, FORWARD);
    // Calculate position
    const placementPosition = getPosition(this._currentPose.position, direction, distanceInFrontOfCamera);

    return [placementPosition.x, placementPosition.y, placementPosition.z];
  }


  /**
   * Identify a THREE rotation for a new Prop that faces the camera
   * @param distanceInFrontOfCamera 
   * @returns 
   */
  getNewPropRotation(): Vector3Tuple {

    // Translate the camera pose rotation into Quaternion
    const horizontalFocusedQuaternion = this.poseRotationToQuaternion(this._currentPose.rotation, true);
    const rotation = new Euler().setFromQuaternion(horizontalFocusedQuaternion, 'YXZ');

    return [0, rotation.y, 0];
  }


  getObjectNode(objectPropId: number): IShowroomObjectProp | undefined {

    return this._objectPropNodes.find(opn => opn.propId === objectPropId);
  }


  getVideoNode(videoPropId: number): IShowroomVideoProp | undefined {

    return this._videoPropNodes.find(vpn => vpn.propId === videoPropId);
  }


  /**
   * Go to the specified positionId and look at the specified targetPosition.
   * @param positionId 
   * @returns 
   */
  async goToPosition(positionId: string, lookAtPosition: Vector3Obj): Promise<void> {

    if (!this._mpSdk || !this._sweepData) {

      return;
    }

    const transition: any = "transition.instant";
    const transitionTime = 2000; // in milliseconds
    // Use the position that the camera will be in AFTER the move when calculating the rotation.
    const positionLocation = this._sweepData[positionId].position;

    this.logger.trace(`Moving to sweepId: ${positionId}`);
    await this._mpSdk.Sweep.moveTo(positionId, {
      rotation: { x: 0, y: this.getLookAtRotation(lookAtPosition, positionLocation) }, // in degrees 
      transition: transition,
      transitionTime: transitionTime
    })
      .then(function (sweepId) {
        // Move successful.
        //console.log('Arrived at sweep ' + sweepId);
      })
      .catch(function (error) {
        // Error with moveTo command
      });
  }


  /**
   * Using 2D x, y coordinates, calculate and apply camera rotation to look at target.
   * rotation.x: is the amount the camera will rotate up/down, in the range between [-90…90] with -90 being straight down and 90 being straight up, 
   * 45 would be looking up at a 45 degree angle., -45 down etc.. 
   * rotation.y: is the amount the camera rotate around horizontally, between [-360…0…360], negative values to rotate to the left, positive to rotate to the right.
   * @param targetPosition 
   * @returns 
   */
  async lookAtPosition(targetPosition: Vector3Obj): Promise<void> {
    if (!this._mpSdk) return;

    await this._mpSdk.Camera.rotate(this.getLookAtRotation(targetPosition, this._currentPose.position), 0, { speed: 100 });
  }


  async getShowroomPositions(): Promise<IShowroomPosition[]> {

    const showroomPositions: IShowroomPosition[] = [];

    if (!this._sweepData) {

      return showroomPositions;
    }

    let record: [key: string, value: MpSdk.Sweep.ObservableSweepData];
    for (record of this._sweepData) {

      showroomPositions.push({
        id: record[0],
        entityId: 0,
        labelId: await this._mpSdk?.Sweep.Conversion.getLabelFromId(record[0]),
        position: {
          x: record[1].position.x,
          y: record[1].position.y,
          z: record[1].position.z
        }
      });
    }

    return showroomPositions;
  }


  async inititialize(matterportSdk: MpSdk) {

    if (!matterportSdk) {

      return;
    }
    if (this._mpSdk) {

      this.reset()
    };

    this._mpSdk = matterportSdk;

    // Needs to occur before other operations.
    await registerSceneComponentTypes(matterportSdk);

    this.captureSweepData(matterportSdk);

    await matterportSdk.Scene.configure((renderer: WebGLRenderer, three: typeof THREE, effectComposer: EffectComposer | null) => {

      this.three = three;
      this._textureManager = new TextureManager(this.three);
      this._gltfManager = new GltfManager(this._textureManager);

      //renderer.autoClear = false;

      //this._logger.error('EffectComposer', effectComposer);
      if (effectComposer) {

        effectComposer.renderTarget1.samples = 8;
        effectComposer.renderTarget2.samples = 8;
      }

      // Three version comapatibility issues
      renderer.useLegacyLights = false;
      // .outputEncoding property not recognized in current version of WebGLRenderer
      // renderer.outputEncoding = THREE.SRGBColorSpace;    // Not recognized in MATTERPORT VERSION of three
      (renderer as any).outputEncoding = (three as any).sRGBEncoding;         // sRGBEncoding (Value 3001), not recognized in CURRENT VERSION of three
      renderer.shadowMap.enabled = true;
      (renderer as any).shadowMap.bias = 0.0001;
      renderer.shadowMap.type = three.BasicShadowMap;  //three.PCFSoftShadowMap;
      // configure PBR
      renderer.toneMapping = ACESFilmicToneMapping; // Breaks effectComposer multisampled renderbuffers in MPSceneComponent

      //if (effectComposer) {
      // add a custom pass here
      //}
      //renderer.sortObjects = false;
    });

    // We load base environment programmatically rather than loading from json config.
    //await this.loadBaseSceneFromJson(JSON.stringify(sceneJson))
    const [sceneObject] = await this._mpSdk.Scene.createObjects(1);
    this._sceneObject = sceneObject;

    this._threeHelper = new ThreeHelper(this);

    await this._threeHelper.createCameraControl();

    // Make sure we always have a reference to the current pose handy


    // Apply any updates needed related to changes in Matterport SDK.
    await this.updateShowroom();

    // Leaving sweep event. Transition started.
    this._mpSdk.on(this._mpSdk.Sweep.Event.EXIT, (fromSweep, toSweep) => {

      if (this._sweepData && toSweep) {

        this._destinationPositionId = toSweep;
        this._transitionStartedSource.next({
          destinationPositionId: toSweep,
          fromPositionId: fromSweep
        });
      }
    });

    // Launch animation to record video.
    // setTimeout(() => {
    //    this.kickOffCameraVideoCapture();
    // }, 10000);

    // Switched from Dollhouse to floor plan to ...
    // this._mpSdk.Mode.current.subscribe(function (mode) {
    //   // the view mode has changed
    //   this._logger.trace('Current view mode is is ', mode);
    // });

    // Transitioning from Dollhouse to floor plan to ...
    //this._mpSdk.Mode.transition.subscribe(function (transition) {
    // the transition has changed
    //that._logger.trace(transition.from, transition.to, transition);
    //});

    this.monitorSweeps();
    this.monitorCameraPositionChanges();
  }


  private async monitorCameraPositionChanges(): Promise<void> {
    
    let lastPosition = { x: 0, y: 0, z: 0 } as Vector3Obj;
    let i = 0;

    await this._mpSdk.App.state.waitUntil(
      (appState) => appState.phase === "appphase.playing"
    );

    this._iSubscriptions.push(
      this._mpSdk.Camera.pose
        .subscribe(pose => {

          this._currentPose = pose;
          this._cameraPositionChangedSource.next(pose);

          if (!equals(pose.position, lastPosition)) {

            lastPosition.x = pose.position.x;
            lastPosition.y = pose.position.y;
            lastPosition.z = pose.position.z;
            for (i = 0; i < this._imagePropNodes.length; i++) {

              this._imagePropNodes[i].onCameraPositionChanged(pose.position);
            };
            for (i = 0; i < this._objectPropNodes.length; i++) {

              this._objectPropNodes[i].onCameraPositionChanged(pose.position);
            };
            for (i = 0; i < this._videoPropNodes.length; i++) {

              this._videoPropNodes[i].onCameraPositionChanged(pose.position);
            };
          }
        })
    );
  }


  private _destinationPositionId?: string;

  async kickOffCameraVideoCapture() {
    let i = 0;
    let increasing = true;
    let intervalId: NodeJS.Timeout = setInterval(() => {
      if (increasing && i >= 2.0) {
        i = 0;
        increasing = false;
      }
      if (!increasing && i <= -2) {
        i = 0;
        increasing = true;
      }
      if (increasing) {
        this._mpSdk?.Camera.zoomBy(.001);
        this._mpSdk?.Camera.rotate(.05, .005);
        i += .001;
      } else {
        this._mpSdk?.Camera.zoomBy(-.001);
        this._mpSdk?.Camera.rotate(.05, -.005);
        i -= .001
      }
    }, 5);
    // Stop the interval after 10 seconds
    setTimeout(() => {
      clearInterval(intervalId);
    }, 120000);
  }


  /**
   * Load (i.e. rehydrate) a Scene from json.
   * @param sceneJson 
   * @param onSuccess 
   */
  private async loadBaseSceneFromJson(sceneJson: string): Promise<void> {

    if (!this._mpSdk) {

      throw Error('mpSdk is undefined');
    }
    const sceneResult = await this._mpSdk.Scene.deserialize(sceneJson);  // This await is not awaiting

    if (!sceneResult) {

      throw new Error('unable to rehydrate base scene');
    }
    this._sceneObject = sceneResult;
    this.logger.trace('rehydrated sceneObjects with methods from SDK', await this._mpSdk?.Scene.serialize(this._sceneObject));

    let node: MpSdk.Scene.INode;
    for (node of this._sceneObject.nodeIterator()) {

      this._baseNodes.push(node);
      node.start();
    }
  }


  private debounceLogPose = debounce(() => {

    if (!this._currentPose) {

      return;
    }
    const currentYRotation = ((this._currentPose.rotation.y * Math.PI) / 180) % (2 * Math.PI);
    this.logger.error(`MP rotation y: ${currentYRotation}`);
    const rotationEuler = new Euler(
      0,  // Set to 0 to focus on y rotation only
      currentYRotation,
      0,
      'YXZ');
    const quaternion = new Quaternion().setFromEuler(rotationEuler);
    // this._logger.error('My rotation', new Euler(
    //   0,  // Set to 0 to focus on y rotation only
    //   this._currentPose.rotation.y * Math.PI / 180,
    //   0,
    //   'YXZ'));
    //console.log('Sweep UUID is ', pose.sweep);
    //console.log('View mode is ', pose.mode);

    // Translate the camera pose rotation into Quaternion gives same results.
    // const horizontalFocusedQuaternion = this.poseRotationToThreeQuaternion(this._currentPose.rotation, true);
    // const rotation = new Euler().setFromQuaternion(horizontalFocusedQuaternion,
    //   'YXZ');
    // this._logger.error('Calculated rotation', rotation);

    const currentPosition = new Vector3().copy(this._currentPose.position)
    const normalizedDirection = new Vector3(0, 0, -1).applyQuaternion(quaternion); // getNormalizedDirectionFromQuaternion(quaternion, FORWARD);
    this.logger.error('Normalized direction', normalizedDirection);
    this.logger.error('Current position', this._currentPose.position);
    this.logger.error('Projected position', getPosition(currentPosition, normalizedDirection, 1));
  }, 900);
  logPose() {
    this.debounceLogPose[0](undefined);
  }


  /**
   * Respond to changes in Matterpoint Sweeps
   * @returns 
   */
  private monitorSweeps() {

    if (!this._mpSdk) {

      throw new Error(NOT_INITIALIZED_MESSAGE);
    }
    const that = this;

    this._iSubscriptions.push(
      this._mpSdk.Sweep.current.subscribe(async (currentSweep) => {

        if (1 > currentSweep.id.length || currentSweep.id === that.currentPlayerPosition?.id) {

          return;
        }

        that.currentPlayerPosition = {
          id: currentSweep.id,
          playerPosition: [currentSweep.position.x, currentSweep.position.y, currentSweep.position.z]
        }

        // If lerping, make sure final lerping is applied before applying position adjustments.
        if (that._destinationPositionId && that._destinationPositionId == currentSweep.id) {

          // Turn off the transitioning flag.
          const tempId = that._destinationPositionId;
          that._destinationPositionId = undefined;
          that._transitionFinishedSource.next(currentSweep.id);
        }

        // Apply position adjustments.
        for (const ipn of that._imagePropNodes) {

          ipn.applyPositionAdjustment();
          ipn.onCameraPositionChanged({ x: currentSweep.position.x, y: currentSweep.position.y, z: currentSweep.position.z });
        };
        for (const opn of that._objectPropNodes) {

          opn.onCameraPositionChanged({ x: currentSweep.position.x, y: currentSweep.position.y, z: currentSweep.position.z });
        };
        for (const vpn of that._videoPropNodes) {

          vpn.applyPositionAdjustment();
          vpn.onCameraPositionChanged({ x: currentSweep.position.x, y: currentSweep.position.y, z: currentSweep.position.z });
        };

        that._playerPositionSource.next(<IPlayerPosition>{ id: currentSweep.id });
        // that._logger.trace(`Current sweep: ${currentSweep.id}`);
        // that._logger.trace(`Current position`, currentSweep.position);
        // that._logger.trace(`On floor`, currentSweep.floorInfo.sequence);
      })
    );
  }


  posePositionToThreeVector3(position: MpSdk.Vector3): Vector3 {

    return new Vector3(position.x, position.y, position.z);
  }


  poseRotationToQuaternion(rotation: MpSdk.Vector2, horizontalLock: boolean = true): Quaternion {

    const x = horizontalLock ? 0 : rotation.x;
    const y = rotation.y;
    return new Quaternion().setFromEuler(
      new Euler(
        x * Math.PI / 180,
        y * Math.PI / 180,
        0,
        'YXZ')    // Note the order of application
    );
  }


  /**
   * Buggy - use log() for a dump
   * serialize(sceneObject: Scene.IObject): Promise<string>
   * serialize(sceneNodes: Scene.INode[]): Promise<string>
   * @param scene or node[]
   * @returns 
   */
  async serialize(scene: any | any[]): Promise<string> {
    if (!this._mpSdk) throw new Error(NOT_INITIALIZED_MESSAGE);
    return await this._mpSdk.Scene.serialize(scene);
  }


  removeImageProp(imageProp: IImageProp): void {

    const imagePropNodeIndex = this._imagePropNodes.findIndex(ipn => ipn.propId === imageProp.id);
    if (0 > imagePropNodeIndex) {

      return;
    }

    const removedProps = this._imagePropNodes.splice(imagePropNodeIndex, 1);
    for (const rp of removedProps) {
      
      rp.onDestroy();
    }
  }


  removeObjectProp(objectProp: IObjectProp): void {

    const objectPropNodeIndex = this._objectPropNodes.findIndex(opn => opn.propId === objectProp.id);
    if (0 > objectPropNodeIndex) {

      return;
    }

    const removedProps = this._objectPropNodes.splice(objectPropNodeIndex, 1);
    for (const rp of removedProps) {
      
      rp.onDestroy();
    }
  }


  removeVideoProp(videoProp: IVideoProp): void {

    const videoPropNodeIndex = this._videoPropNodes.findIndex(vpn => vpn.propId === videoProp.id);
    if (0 > videoPropNodeIndex) {

      return;
    }

    const removedProps = this._videoPropNodes.splice(videoPropNodeIndex, 1);
    for (const rp of removedProps) {
      
      rp.onDestroy();
    }
  }


  setShowroomMode(mode: ShowroomMode): void {

    let ipn: ImagePropNode;
    for (ipn of this._imagePropNodes) {

      ipn.setShowroomMode(mode);
    }
    let opn: ObjectPropNode;
    for (opn of this._objectPropNodes) {

      opn.setShowroomMode(mode);
    }
    let vpn: VideoPropNode;
    for (vpn of this._videoPropNodes) {

      vpn.setShowroomMode(mode);
    }
  }


  spyOnEvent(spyImplementation: any): ISubscription {
    if (!this._mpSdk) throw new Error(NOT_INITIALIZED_MESSAGE);
    if (!this._sceneObject) throw new Error(SCENE_UNDEFINED_MESSAGE);

    return this._sceneObject.spyOnEvent(spyImplementation);
  }


  log(): void {

    if (!this._sceneObject) {

      throw new Error(SCENE_UNDEFINED_MESSAGE);
    }

    let node: MpSdk.Scene.INode;
    for (node of this._sceneObject.nodeIterator()) {

      const componentIterator: IterableIterator<Scene.IComponent> = node.componentIterator();
      let component: MpSdk.Scene.IComponent;
      for (component of componentIterator) {

        this.logger.trace(`componentType: ${component.componentType}`);
      }
    }
  }


  /**
   * In case we need to apply updates to the Showroom based upon changes in Matterport SDK
   * @returns 
   */
  private async updateShowroom(): Promise<void> {

    if (!environment.applyMatterportUpdates) {

      return;
    }
    this.logger.trace('Applying Matterport updates');

    // We needed to convert Matterport sweep ids.
    // const mapping = await this._mpSdk.Sweep.Conversion.createIdMap();
    // this._logger.trace('Sweep ID Map', mapping);

    // const venues = await firstValueFrom(this._clientService.getVenues(SpaceProvider.MATTERPORT));

    // for (const venue of venues) {

    //   const imageProps = venue.imageProps;

    //   for (const imageProp of imageProps) {
    //     const positionAdjustments = imageProp.positionAdjustments;

    //     for (let positionAdjustment of positionAdjustments) {
    //       const key = Object.keys(mapping).find(key => mapping[key] === positionAdjustment.positionId);

    //       if (key) {
    //         this._logger.trace(`positionAdjustment id: ${positionAdjustment.id} need positionId: ${positionAdjustment.positionId} changed to ${key}`);
    //         positionAdjustment.positionId = key;
    //         await this._venueService.updatePositionAdjustment(positionAdjustment);
    //       }
    //     };
    //   };
    // };
  }


  private onDestroy(): void {

    this.reset(true);

    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  /**
   * Called upon initialize and destroy.
   */
  private reset(isDestroy: boolean = false) {
    this.clear(isDestroy);
    this._mpSdk?.disconnect();
    this._subscriptions.forEach(s => s.unsubscribe());
    this._iSubscriptions.forEach(s => s.cancel());
    this._threeHelper.dispose();
    this._textureManager.dispose();
    this._gltfManager.dispose();
  }


}
