import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren, WritableSignal, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { DesignVideoService } from 'src/app/core/service/venue/design-video.service';
import { ImportDesignVideoCardComponent } from "../import-design-video-card/import-design-video-card.component";
import { isChildElement } from 'projects/my-common/src/util/utils';
import { NGXLogger } from 'ngx-logger';
import { VideoAssignment, VideoProp } from 'projects/my-common/src/model';
import { PaginationComponent } from "../../../shared/pagination/pagination.component";

export const IMPORT_VIDEO_SIDEBAR_ID = '0cb60ae3-46b3-4bf4-b88f-2352d8a17cb5';

@Component({
  selector: 'app-import-video-sidebar',
  standalone: true,
  providers: [provideIcons({ bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
  templateUrl: './import-video-sidebar.component.html',
  styleUrls: ['./import-video-sidebar.component.scss'],
  imports: [CommonModule, NgIconComponent, ImportDesignVideoCardComponent, PaginationComponent]
})
export class ImportVideoSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {
  sidebarId: string = IMPORT_VIDEO_SIDEBAR_ID;
  readonly isOpen: WritableSignal<boolean> = signal(false);
  readonly closeClicked: WritableSignal<boolean> = signal(false);

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('importVideoSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  /**
   * Template needs to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.isOpen()) {

      const elementRef = (this.elementRef.first as any).nativeElement;
      elementRef?.focus();

      return true;
    }
    return false;
  })

  currentVideoProp = signal(new VideoProp());
  @Input() set CurrentVideoProp(value: VideoProp) {

    this.currentVideoProp.set(value);
  }

  assignedDesignVideos = signal(<VideoAssignment[]>([]));
  @Input() set VideoAssignments(value: VideoAssignment[]) {

    this.assignedDesignVideos.set(value);
  }

  importableDesignVideos = computed(() =>
    this.designVideoService.importableDesignVideos().data
      .filter(dv => -1 === this.assignedDesignVideos().findIndex(
        idv => idv.designVideoId === dv.designVideoId && idv.videoPropId === this.currentVideoProp().id)))


  constructor(private readonly _sidebarService: SidebarService,
    readonly designVideoService: DesignVideoService,
    private readonly _logger: NGXLogger) {

    _sidebarService.add(this);
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.handleBlur.bind(this);
  handleBlur() {

    if (this._handlingBlur) return;
    this._handlingBlur = true;

    const elementRef = (this.elementRef.first as any).nativeElement;
    if (!elementRef) {

      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {

      if (!isChildElement(elementRef, document.activeElement)) {

        this.isOpen.set(false);
      }
      this._handlingBlur = false;
    }, 1);
  }


  handleClose(): void {

    this.closeClicked.set(true);
    setTimeout(() => {

      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 200);
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this._sidebarService.remove(this);

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


}
