import { defineSystem, removeComponent } from 'bitecs'
import { WorldState } from '../ecs-world.state';
import { AdjustableComponent, DisplayPlaneComponent, object3dByEntityId } from '..';
import { TransitionAnimationComponent, transitionAnimationQuery } from '../component/transition-animation.component';
import { Euler } from 'three';
import { Subject } from 'rxjs';
import { TransitionPlaneComponent } from '../component/transition-plane.component';

export const ActiconState = {

    counter: 0
}


const euler = new Euler(0, 0, 0, 'YXZ');
const _transitionAnimationCompleteSource = new Subject<number>();
export const transitionAnimationComplete$ = _transitionAnimationCompleteSource.asObservable();

/**
 * Acticon specific position, rotation, scale and animation updates.
 */
export const transitionAnimationSystem = defineSystem(world => {

    const transitionAnimationEntities = transitionAnimationQuery(world);
    for (let i = 0; i < transitionAnimationEntities.length; i++) {

        const eidForTransitioningProp = transitionAnimationEntities[i];
        const duration = TransitionAnimationComponent.duration[eidForTransitioningProp];
        TransitionAnimationComponent.elapsed[eidForTransitioningProp] += WorldState.delta;
        const elapsed = TransitionAnimationComponent.elapsed[eidForTransitioningProp];

        if (elapsed > duration) {

            _transitionAnimationCompleteSource.next(AdjustableComponent.id[eidForTransitioningProp]);
            removeComponent(world, TransitionAnimationComponent, eidForTransitioningProp);
            animateTransition(transitionAnimationEntities[i], 1);
            continue;
        }

        animateTransition(transitionAnimationEntities[i], elapsed / duration);
    }

    return world;
})


/**
 * Exploring ECS animation options https://forum.unity.com/threads/dots-animation-options-wiki.1339196/
 * @param eidForTransitioningProp 
 */
function animateTransition(eidForTransitioningProp: number, ratio: number) {

    const displayPlane = object3dByEntityId[DisplayPlaneComponent.eid[eidForTransitioningProp]];
    const displayParentGroup = displayPlane.parent;
    const transitionPlane = object3dByEntityId[TransitionPlaneComponent.eid[eidForTransitioningProp]];
    const transitionParentGroup = transitionPlane.parent;
    if (!displayParentGroup || !transitionParentGroup) {

        return;
    }

    // Calculate display plane dimensions
    //const parentScaleX = ScaleComponent.x[eidForTransitioningProp] + ScaleAdjustmentComponent.x[eidForTransitioningProp];
    // const parentScaleY = ScaleComponent.y[eidForTransitioningProp] + ScaleAdjustmentComponent.y[eidForTransitioningProp];
    // let contentHeight = (1 / AdjustableComponent.aspect[eidForTransitioningProp]) * parentScaleY; // Base height
    // if (0 < DisplayPlaneComponent.aspect[eidForTransitioningProp]) {    // If content aspect is defined

    //     if (DisplayPlaneComponent.aspect[eidForTransitioningProp] >= AdjustableComponent.aspect[eidForTransitioningProp]) {

    //         contentHeight = (1 / DisplayPlaneComponent.aspect[eidForTransitioningProp]) * parentScaleY;
    //     }
    // }
    // const yOffest = contentHeight / 2;
    //const xOffset = (parentScaleX * DisplayPlaneComponent.scaleFactor[eidForTransitioningProp]) / 2;

    if (1 > ratio) {

        displayParentGroup.position.x = transitionParentGroup.position.x = .5;
        displayPlane.position.x = transitionPlane.position.x = -.5;

        displayParentGroup?.quaternion.setFromEuler(euler.set(
            0,
            -(Math.PI * ratio),
            0, 'YXZ')); //+= Math.PI * 2 * ratio;

        transitionParentGroup?.quaternion.setFromEuler(euler.set(
            0,
            Math.PI - (Math.PI * ratio),
            0, 'YXZ')); //+= Math.PI * 2 * ratio;

    } else {

        displayParentGroup.position.x = transitionParentGroup.position.x = 0;
        displayPlane.position.x = transitionPlane.position.x = 0;

        displayParentGroup?.quaternion.setFromEuler(euler.set(
            0,
            0,
            0, 'YXZ')); //+= Math.PI * 2 * ratio;

        transitionParentGroup?.quaternion.setFromEuler(euler.set(
            0,
            Math.PI,
            0, 'YXZ')); //+= Math.PI * 2 * ratio;    
    }
    // Math based animations
    // switch (AnimationComponent.type[eidTransitioningProp]) {

    //     case AnimationType.ROTATE:
    //         object3dByEntityId[eidTransitioningProp].rotation.y -= AnimationComponent.rotationY[eidTransitioningProp];
    //         break;
    // }

    // Embedded GTLF animations
    //animationMixerByEntityId[eidTransitioningProp]?.update(WorldState.delta);    
}

