import { WorldState } from "../ecs-world.state";
import {
    getAdjustmentEidForPositionAndAdjustable,
    getClippingAssignmentEidForPositionAndPlane
} from "../entity/adjustable.entity";

/**
 * Set by Adjustment Init System when it detects the Position has changed.
 * Also, reset by particular world updates to force an adjustment refresh.
 */
export const AdjustmentInitState = {
    
    currentShowroomPositionId: -1,
    debugPositionId: -1,
    debugEid: -1
}



/**
 * For the states current Showroom Position and the given Parent Prop entity Id
 * @param entityIdForParentProp 
 * @returns Adjustment entity Id
 */
export function getAdjustmentEidForCurrentPosition(entityIdForParentProp: number): number {

    return getAdjustmentEidForPositionAndAdjustable(AdjustmentInitState.currentShowroomPositionId, entityIdForParentProp);
}

/**
 * For the states current Showroom Position and the given Parent Prop entity Id
 * @param entityIdForParentProp 
 * @returns Adjustment entity Id
 */
export function getClippingAssignmentEidForCurrentPosition(entityIdForParentProp: number): number {

    return getClippingAssignmentEidForPositionAndPlane(AdjustmentInitState.currentShowroomPositionId, entityIdForParentProp);
}