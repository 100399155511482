import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { MainHomeComponent } from '../pages/main/main-home/main-home.component';
import { AboutComponent } from '../pages/main/about/about.component';
import { PrivacyComponent } from '../pages/compliance/privacy/privacy.component';
import { TosComponent } from '../pages/compliance/tos/tos.component';
import { DisplayComponent } from '../pages/main/display/display.component';
import { ContactComponent } from '../pages/main/contact/contact.component';

/**
 * https://angular.io/guide/router
 * https://www.angulararchitects.io/blog/routing-and-lazy-loading-with-standalone-components/
 */
export const MAIN_ROUTES: Routes = [
    {
        path: '',
        component: MainComponent,
        title: 'My\u{A66A}ptyx',
        children: [
            {
                path: '',
                component: MainHomeComponent,
                title: 'My\u{A66A}ptyx'
            },
            {
                path: 'about',
                component: AboutComponent,
                title: 'About My\u{A66A}ptyx',
            },
            {
                path: 'privacy',
                component: PrivacyComponent,
                title: 'My\u{A66A}ptyx privacy',
            },
            {
                path: 'tos',
                component: TosComponent,
                title: 'My\u{A66A}ptyx terms',
            },
            // {
            //     path: '3D',
            //     loadComponent: () => import('../pages/main/three-d/three-d.component')
            //         .then((mod) => mod.ThreeDComponent),
            //     title: 'A/R Test'
            // },
            {
                path: 'contact',
                component: ContactComponent,
                title: 'Contact My\u{A66A}ptyx',
            },
            {
                path: 'display',
                component: DisplayComponent,
                title: 'Contact My\u{A66A}ptyx',
            }
        ]
    }
];
