import { AfterViewInit, Component, OnDestroy, QueryList, ViewChildren, computed, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { ObjectPropActionCardComponent } from '../object-prop-action-card/object-prop-action-card.component';
import { ObjectAssignment } from 'projects/my-common/src/model';

export const OBJECT_PROPS_SIDEBAR_ID = '1ce564bf-b43f-493e-af10-88bdde23b560';


@Component({
  selector: 'app-object-props-sidebar',
  standalone: true,
  providers: [provideIcons({ bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
  templateUrl: './object-props-sidebar.component.html',
  styleUrls: ['./object-props-sidebar.component.scss'],
  imports: [CommonModule, NgIconComponent, ObjectPropActionCardComponent]
})
export class ObjectPropsSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  sidebarId: string = OBJECT_PROPS_SIDEBAR_ID;
  readonly isOpen = signal(false);
  readonly closeClicked = signal(false);

  readonly objectProps = computed(() => this.ObjectAssignments()
    .sort((oa1, oa2) => oa1.name.localeCompare(oa2.name)));

  /**
   * From Venue Service if client or admin.
   * From Guest Service if guest.
   */
  readonly ObjectAssignments = input.required<ObjectAssignment[]>();

  readonly SelectObject = output<ObjectAssignment>();
  readonly GoToObject = output<ObjectAssignment>();

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('objectPropsSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  /**
   * Template needs to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.isOpen()) {

      const elementRef = (this.elementRef.first as any).nativeElement;
      elementRef?.focus();

      return true;
    }
    return false;
  })


  constructor(private readonly sidebarService: SidebarService) {

    sidebarService.add(this);
  }


  private readonly handleBlurCallback = this.handleBlur.bind(this);
  handleBlur(blurEvent: any): void {

    if (blurEvent.relatedTarget) {

      return;
    }
    this.isOpen.set(false);
  }


  handleClose(): void {

    this.closeClicked.set(true);
    setTimeout(() => {

      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 200);
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  onGoToObject(objectAssignment: ObjectAssignment) {

    this.GoToObject.emit(objectAssignment);
  }


  onSelectObject(objectAssignment: ObjectAssignment) {

    this.SelectObject.emit(objectAssignment);
  }


}
