import { Color, ColorRepresentation, FrontSide, GreaterEqualStencilFunc, Group, Material, Mesh, NormalBlending, ReplaceStencilOp, Texture } from "three";
import * as THREE from 'three';
import { isMeshStandardMaterial } from "../..";

/**
 * The image plane starts as 1 x 1.
 * Changes in aspect, image aspect or stretch to fit properties will recreate the plane.
 * If stretch to fit is enabled or image aspect is not defined, then the plane will be sized based on the base aspect ...
 * else, if image aspect is different than the base aspect, the display plane will be recreated to accomodate the image aspect.
 * @returns 
 */
export class ImagePlane {

    private _needsUpdate = false;
    private _createPlane = false;

    private _alphaMap?: Texture;
    public get alphaMap(): Texture | undefined {
        return this._alphaMap;
    }
    public set alphaMap(v: Texture | undefined) {

        //if (this._alphaMap !== v) console.error('alphaMap');
        this._needsUpdate = this._needsUpdate || this._alphaMap !== v;
        this._alphaMap = v;
    }

    private _aspect = 1;
    public get aspect(): number {
        return this._aspect;
    }
    public set aspect(v: number) {

        this._createPlane = this._createPlane || this._aspect !== v;
        this._aspect = v;
    }

    private _color: ColorRepresentation = 0xffffff;
    public get color(): ColorRepresentation {
        return this._color;
    }
    public set color(v: ColorRepresentation) {

        //if (this._color !== v) console.error('color');
        this._needsUpdate = this._needsUpdate || this._color !== v;
        this._color = v;
    }

    private _disableDepth = false;
    public get disableDepth(): boolean {
        return this._disableDepth;
    }
    public set disableDepth(v: boolean) {

        //if (this._disableDepth !== v) console.error('disableDepth');
        this._needsUpdate = this._needsUpdate || this._disableDepth !== v;
        this._disableDepth = v;
    }

    private _group!: Group;
    public get group(): Group {

        return this._group;
    }

    private _imageAspect = 0;
    public get imageAspect(): number {
        return this._imageAspect;
    }
    public set imageAspect(v: number) {

        this._createPlane = this._createPlane || this._imageAspect !== v;
        this._imageAspect = v;
    }

    private _opacity = 1;
    public get opacity(): number {
        return this._opacity;
    }
    public set opacity(v: number) {

        //if (this._opacity !== v) console.error('opacity');
        this._needsUpdate = this._needsUpdate || this._opacity !== v;
        this._opacity = v;
    }

    private _plane?: Mesh;
    public get plane(): Mesh | undefined {

        return this._plane;
    }

    private _material?: Material;
    public get material(): Material | undefined {

        return this._material;
    }

    private _stencilRef = 1;
    public get stencilRef(): number {
        return this._stencilRef;
    }
    public set stencilRef(v: number) {

        //if (this._stencilRef !== v) console.error('stencilRef');
        this._needsUpdate = this._needsUpdate || this._stencilRef !== v;
        this._stencilRef = v;
    }

    private _stretchToFit = true;
    public get stretchToFit(): boolean {
        return this._stretchToFit;
    }
    public set stretchToFit(v: boolean) {

        this._createPlane = this._createPlane || this._stretchToFit !== v;
        this._stretchToFit = v;
    }

    private _texture?: Texture;
    public get texture(): Texture | undefined {
        return this._texture;
    }
    public set texture(v: Texture | undefined) {

        //if (this._texture !== v) console.error('texture');
        this._needsUpdate = this._needsUpdate || this._texture !== v;
        this._texture = v;
    }

    private _transparent = true;
    public get transparent(): boolean {
        return this._transparent;
    }
    public set transparent(v: boolean) {

        //if (this._transparent !== v) console.error('transparent');
        this._needsUpdate = this._needsUpdate || this._transparent !== v;
        this._transparent = v;
    }

    private _visible = true;
    public get visible(): boolean {
        return this._visible;
    }
    public set visible(v: boolean) {

        //if (this._visible !== v) console.error('visible');
        this._needsUpdate = this._needsUpdate || this._visible !== v;
        this._visible = v;
    }


    /**
     *
     */
    constructor(private readonly three: typeof THREE) { 

        this._group = new three.Group();
    }


    applyChanges() {

        if (this._createPlane) {

            this._createPlane = false;
            this.createDisplayPlane();
        }

        this.updateMaterial();
    }


    /**
     * The image plane is always 1 x 1.
     * Aspect defines its base shape.
     * If stretch to fit is enabled, images will be resized to fit the base shape ...
     * else, if image aspect is defined and different than the display plane aspect, the display plane will be recreated to match the image aspect.
     * See scale for adjustments based upon changes in aspect.
     * @returns 
     */
    private createDisplayPlane(): void {

        //console.error('creating plane');

        // Clear old references
        this.dispose();

        const aspect = 0 < this.imageAspect && !this.stretchToFit ?
            this.imageAspect : this.aspect;
        const planeRendererGeometry = new this.three.PlaneGeometry(1.0, 1.0 / aspect);
        this._plane = new this.three.Mesh(planeRendererGeometry);
        this._group.add(this._plane);
        this._plane.castShadow = false;
    }


    private setPlaneMaterial(): void {

        // If we have a texture and the right material for it then there is nothing to do.
        if (!this._plane ||
            (this._material && this._plane.material === this._material
                && (this.texture || this._alphaMap)
                && isMeshStandardMaterial(this._material as Material))) {

            return;
        }

        const oldMaterial = this._material;

        if (this.texture) {

            this._material = new this.three.MeshStandardMaterial({

                //blendColor: new Color(0x000000),
                blending: NormalBlending,
                displacementBias: 0,
                displacementMap: null,
                displacementScale: 1,
                dithering: false,
                emissive: new Color(0x000000),
                emissiveIntensity: 1,
                emissiveMap: null,
                envMap: null,
                envMapIntensity: 1,
                flatShading: false,
                fog: true,
                forceSinglePass: false,
                lightMap: null,
                lightMapIntensity: 1,
                metalness: 0,
                metalnessMap: null,
                normalMap: null,
                premultipliedAlpha: false,
                roughness: 0.9,
                roughnessMap: null,
                shadowSide: 0,
                side: FrontSide,

                depthWrite: true, // If false, renderOrder value determines who's in front, else renderer depth determine who's in front
                //stencilFunc: AlwaysStencilFunc,     // works
                //stencilFunc: NotEqualStencilFunc,   // works
                //stencilFunc: LessStencilFunc,       // Doesn't work
                //stencilFunc: LessEqualStencilFunc,       // Doesn't work
                stencilFunc: GreaterEqualStencilFunc,       // Works because stencil ref is greater than 0
                stencilRef: this.stencilRef,
                stencilWrite: true,
                stencilZPass: ReplaceStencilOp,

                toneMapped: true,
                color: this.color,
                transparent: this.opacity !== 1,    // this.inputs.transparent,
                map: this.texture ?? null,
                opacity: this.visible ? this.opacity : 0,
                alphaTest: .01
            });
        } else {

            this._material = new this.three.MeshBasicMaterial();
        }

        this._plane.material = this._material;
        (oldMaterial as Material)?.dispose();
    }


    private updateMaterial() {

        this.setPlaneMaterial();

        if (!this._visible) {

            this._group.visible = false;
            return;
        }
        this._group.visible = true;

        if (!this._material || !this._needsUpdate) {

            return;
        }
        this._needsUpdate = false;

        this._material.depthWrite = !this.disableDepth;
        this._material.opacity = this.visible ? this.opacity : 0;
        this._material.stencilRef = this.stencilRef;
        this._material.transparent = this.transparent;

        if (isMeshStandardMaterial(this._material)) {

            this._material.color = new Color(this.color);
            this._material.map = this.texture ?? null;
            this._material.alphaMap = this.alphaMap ?? null;
        }

        this._material.needsUpdate = true;
    }


    dispose() {

        if (this._plane) {

            this._group.remove(this._plane);
            this._plane.geometry.dispose();
            (this._plane.material as Material).dispose();
        }
        this._plane = this._material = undefined;
    }

}