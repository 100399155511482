import { ICameraPose } from "../model";
import { getLogger } from "../util/log";
import { Vector3Obj, vector3ZeroObj } from "../util/utils";

export const WorldState = {

    cameraPose: <ICameraPose>{
        position: vector3ZeroObj,
        rotation: { x: 0, y: 0 }
    } ,
    cameraRotation: vector3ZeroObj as Vector3Obj,
    counter: 0,
    currentPositionEntityId: -1,
    delta: 0.0,
    destinationPositionEntityId: -1,
    logger: getLogger()
}