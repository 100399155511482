@if (isOpen()) {
<!-- modal-dialog defines initial height and is resized/positioned thereafter by app-resize-border -->
<div class="modal-dialog fixed z-50 bottom-0 left-0 w-screen overflow-hidden bg-black" [ngStyle]="{'height.px': 90}">
    <app-resize-border [dragHolder]="drag">
        <div class="modal-body h-full flex-grow-1 overflow-hidden rounded-lg text-left shadow-xl transition-all">

            <div #drag class="flex flex-row justify-between items-center h-10">
                <!-- Drag icon -->
                <div class="w-11 h-[22px] flex justify-center items-center cursor-pointer transition">
                    <ng-icon class="text-3xl text-center transition" name="bootstrapArrowsMove" />
                </div>
                <div>{{Label() && 0 < Label().length ? Label() + ': ' : ''}}{{currentValue}}</div>
                <!-- close icon -->
                <div class="w-11 h-[22px] flex justify-center items-center cursor-pointer transition"
                    (click)="handleClose()">
                    <ng-icon class="text-3xl text-center transition {{ closeClicked() ? 'rotate-[2turn]' : 'rotate-0' }}"
                        name="bootstrapXLg" />
                </div>
            </div>
            <div class="mt-1 flex flex-row items-center">
                <input type="range" min="-100" max="100" [value]="inputValue()" class="mt-1 block w-full mx-2" step="any" 
                    (input)="handleInputChange($event)" (mouseup)="handleMouseUp()"/>
            </div>
        </div>
    </app-resize-border>
</div>
}