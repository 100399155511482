import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import { FlipType, IMaterialBase } from "projects/my-common/src/model";
import * as THREE from 'three';
import { TextureManager } from "../my-three/texture-manager";
import { Texture } from "three";

export type MaterialDataLoaderState = {

  materialDefinitionsToLoad?: IMaterialBase[];
}

/**
 * Apply to material by material name.
 */
export type MaterialData = {

  name: string;
  map: THREE.Texture | null;
  mapFlip: FlipType,
  alphaMap: THREE.Texture | null;
  alphaMapFlip: FlipType
}


export class MaterialDataLoaderComponent extends MyOptyxComponent {

  protected override state: MaterialDataLoaderState = {

    materialDefinitionsToLoad: undefined
  };

  // Pending state initialized to match current state.
  override pendingState: MaterialDataLoaderState = {

    materialDefinitionsToLoad: undefined
  }

  // Events
  private readonly _materialDataUpdated = new Subject<MaterialData[]>();
  readonly materialDataUpdated$ = this._materialDataUpdated.asObservable();

  private _logger = getLogger();
  private _loadingMaterials = false;
  private _loadedMaterialData: MaterialData[] = [];
  private _currentMaterialDefinitionToLoad: IMaterialBase | null = null;
  private _currentMaterialToLoadIndex = -1;
  private _currentMaterialsToLoad: IMaterialBase[] = [];


  constructor(destroyRef: DestroyRef,
    private readonly textureManager: TextureManager) {
    super(destroyRef);
  }


  /**
   * Primitive state values can be compared with pendingState values directly to evaluate changes.
   * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
   * Use that to evaluate if shallow reference values have changed.
   */
  protected override applyPendingState(): void {

    //if (this.pendingStateChanges.find(psc => psc === 'urls')) {}
    this.tryLoadMaterialData(this.pendingState);
  }


  override getState(): MaterialDataLoaderState {

    // Might want to deepCopy depending on properties.
    return this.state;
  }


  private loadMaterialData(materialDefinition: IMaterialBase, state: MaterialDataLoaderState) {

    // If material name is not defined or there is not textures to load then return.
    if (!materialDefinition
      || 1 > materialDefinition.name.length
      || (1 > materialDefinition.alphaMapUrl.length && 1 > materialDefinition.mapUrl.length)) {

      this._logger.error(`Invalid material definition`, materialDefinition);

      this.loadMaterialDataComplete(state);
      return;
    }

    const that = this;
    const materialData: MaterialData = <MaterialData>{
      name: materialDefinition.name,
      alphaMap: null,
      map: null,
    };

    if (0 < materialDefinition.mapUrl.length) {

      this._logger.trace(`Loading material map texture`, materialDefinition);
      this.textureManager.loadTexture(materialDefinition.mapUrl,
        (texture: Texture) => {

          texture.name = materialDefinition.mapFileName;
          // TODO: Evalutate if map and mask flipping need to handled separately.
          texture.flipY = (FlipType.Vertical === materialDefinition.mapFlip
            || FlipType.Both === materialDefinition.mapFlip);
          if (FlipType.Horizontal === materialDefinition.mapFlip
            || FlipType.Both === materialDefinition.mapFlip) {

            texture.wrapS = THREE.RepeatWrapping;
            texture.repeat.x = -1;
          }
          texture.needsUpdate = true;

          materialData.map = texture;
          this._loadedMaterialData.push(materialData);
          this._logger.trace(`Material map texture loaded. Broadcasting materialDataUpdated event`, materialData);
          this.loadMaterialDataComplete(state);
        },
        (err: any) => this._logger.error(err),
        materialDefinition.alphaMapUrl);

    } else if (0 < materialDefinition.alphaMapUrl.length) {

      this._logger.trace(`Loading material alpha map`, materialDefinition);
      this.textureManager.loadTexture(materialDefinition.alphaMapUrl,
        (mask: THREE.Texture) => {

          mask.flipY = (FlipType.Vertical === materialDefinition.alphaMapFlip
            || FlipType.Both === materialDefinition.alphaMapFlip);
          if (FlipType.Horizontal === materialDefinition.alphaMapFlip
            || FlipType.Both === materialDefinition.alphaMapFlip) {

            mask.wrapS = THREE.RepeatWrapping;
            mask.repeat.x = -1;
          }
          mask.needsUpdate = true;

          materialData.alphaMap = mask;
          this._loadedMaterialData.push(materialData);
          this.loadMaterialDataComplete(state);
        },
        (err: any) => this._logger.error(err));
    } else {

      this._logger.error(`Material had neither a map or alphaMap url defined.`);
      this.loadMaterialDataComplete(state);
    }

  }


  loadMaterialDataComplete(state: MaterialDataLoaderState) {

    const materialDefinitionToLoad = this.nextMaterialDefinition(state);

    if (materialDefinitionToLoad) {

      this.loadMaterialData(materialDefinitionToLoad, state);
    } else {

      this._logger.trace(`loading is complete`, this._loadedMaterialData);
      this._materialDataUpdated.next(this._loadedMaterialData);
      this._loadingMaterials = false;
    }
  }


  private nextMaterialDefinition(state: MaterialDataLoaderState): IMaterialBase | null {

    if (!state.materialDefinitionsToLoad || 0 === state.materialDefinitionsToLoad.length) {

      this._logger.trace(`No material definitions to load`);
      if (this._currentMaterialDefinitionToLoad) {

        // Inputs changed. We had MaterialDefinitions, now we don't.
        this._currentMaterialToLoadIndex = -1;
        this._currentMaterialDefinitionToLoad = null;
        //return { source: '', animationType: AnimationType.NONE } as GltfProperties;
      }

      return null;
    }

    if (state.materialDefinitionsToLoad !== this._currentMaterialsToLoad) {

      if (0 < this._currentMaterialsToLoad?.length) {
        
        this._logger.trace(`Material definitions to load changed from - to`, this._currentMaterialsToLoad, state.materialDefinitionsToLoad);
        this._logger.trace(`Resetting`);
      }
      // Inputs changed. We have new DesignObjectMaterials to load.
      this._currentMaterialToLoadIndex = -1;
      this._currentMaterialsToLoad = state.materialDefinitionsToLoad;
      this._loadedMaterialData = [];
      this._materialDataUpdated.next(this._loadedMaterialData);
    }

    this._currentMaterialToLoadIndex++;

    if (this._currentMaterialToLoadIndex >= this._currentMaterialsToLoad.length) {

      // All of the DesignObjectMaterials have been loaded
      // Sanity check
      if (this._currentMaterialDefinitionToLoad !== this._currentMaterialsToLoad[this._currentMaterialsToLoad.length - 1]) {

        this._logger.warn('Materials to load is finished but current DesignObjectMaterial does not match the last DesignObjectMaterial to load');
      }

      return null;
    }

    // Return the next DesignObjectMaterial to load.
    this._currentMaterialDefinitionToLoad = this._currentMaterialsToLoad[this._currentMaterialToLoadIndex];
    return this._currentMaterialDefinitionToLoad;
  }


  override onDestroy(): void { }


  // If overriding be sure to call base method.
  override init(): MaterialDataLoaderComponent {
    super.init();
    return this;
  }


  private tryLoadMaterialData(state: MaterialDataLoaderState) {

    const materialDefinition = this.nextMaterialDefinition(state);
    if (materialDefinition) {

      this._logger.trace(`Loading material: ${materialDefinition.name}`, materialDefinition);
      this.loadMaterialData(materialDefinition, state);
    } 
  }


}

