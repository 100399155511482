import { Component, Input, OnDestroy, QueryList, ViewChildren, computed, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VenueEventSelectComponent } from "../../venue-event/venue-event-select/venue-event-select.component";
import { EventDesignSelectComponent } from "../../event-design/event-design-select/event-design-select.component";
import { IVenue, IVenueEvent } from 'projects/my-common/src/model';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { UserService } from 'src/app/core/service/myoptyx/user.service';
import { NGXLogger } from 'ngx-logger';
import { isChildElement } from 'projects/my-common/src/util/utils';
import { IMAGE_PROPS_SIDEBAR_ID } from '../../shared/image-props-sidebar/image-props-sidebar.component';
import { OBJECT_PROPS_SIDEBAR_ID } from '../../shared/object-props-sidebar/object-props-sidebar.component';
import { VIDEO_PROPS_SIDEBAR_ID } from '../../shared/video-props-sidebar/video-props-sidebar.component';
import { POSITIONS_SIDEBAR_ID } from '../../shared/positions-sidebar/positions-sidebar.component';
import { GuestService } from 'src/app/core/service/venue/guest.service';


export const SHOWROOM_GUEST_OPTIONS_SIDEBAR_ID = '16ab1d08-4783-4bfb-871b-17d1577f05be';

/**
 * To maintain a clear separation of concerns and User Experience, the Admins, Subscribers and Guests will have separate sidebars
 */
@Component({
  selector: 'app-showroom-guest-options-sidebar',
  standalone: true,
  providers: [provideIcons({ bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
  imports: [CommonModule, VenueEventSelectComponent, EventDesignSelectComponent, NgIconComponent],
  templateUrl: './showroom-guest-options-sidebar.component.html',
  styleUrls: ['./showroom-guest-options-sidebar.component.scss']
})
export class ShowroomGuestOptionsSidebarComponent implements ISidebar, OnDestroy {

  sidebarId: string = SHOWROOM_GUEST_OPTIONS_SIDEBAR_ID;
  readonly isOpen = signal(false);
  readonly closeClicked = signal(false);
  readonly selectedVenueEvent = signal(<IVenueEvent>{});

  readonly eventDesigns = this._guestService.eventDesigns;
  readonly selectedEventDesign = this._guestService.currentEventDesign;

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('showroomGuestOptionsSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  /**
   * Template need to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {
    if (this.isOpen()) {
      if (this.elementRef && 0 < this.elementRef.length) {
        (this.elementRef.first as any).nativeElement.focus();
      }
      return true;
    }
    return false;
  });

  readonly venue = signal(<IVenue>{});
  @Input() set Venue(value: IVenue) {

    this.venue.set(value);
    if (value && 0 < value.venueEvents.length) {

      const venueEvent = value.venueEvents.find(ve => !ve.isDefault);
      if (venueEvent) {

        this.selectVenueEvent(venueEvent.id);
      } else {

        this.selectVenueEvent(value.venueEvents[0].id);
      }
    }
  }

  readonly CreateImageProp = output<boolean>();
  readonly OnEventDesignSelect = output<number>();


  constructor(private readonly _sidebarService: SidebarService,
    private readonly _guestService: GuestService,
    private readonly _logger: NGXLogger,
    readonly _userService: UserService) {
    _sidebarService.add(this);
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.handleBlur.bind(this);
  handleBlur() {
    if (this._handlingBlur) return;
    this._handlingBlur = true;

    const elementRef = (this.elementRef.first as any).nativeElement;
    if (!elementRef) {
      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {
      if (!isChildElement(elementRef, document.activeElement)) {
        this.isOpen.set(false);
      }
      this._handlingBlur = false;
    }, 1);
  }


  handleClose(): void {

    this.closeClicked.set(true);
    setTimeout(() => {
      
      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 200);
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {
    this._sidebarService.remove(this);

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  onListImageProps() {

    this._sidebarService.open(IMAGE_PROPS_SIDEBAR_ID);
  }


  onListObjectProps() {

    this._sidebarService.open(OBJECT_PROPS_SIDEBAR_ID);
  }


  onListShowroomPositions() {

    this._sidebarService.open(POSITIONS_SIDEBAR_ID);
  }


  onListVideoProps() {

    this._sidebarService.open(VIDEO_PROPS_SIDEBAR_ID);
  }


  selectEventDesign(eventDesignId: number) {

    this._logger.trace(`id: ${eventDesignId}`);
    const selectedEventDesign = this.eventDesigns().find(ed => ed.id == eventDesignId);

    if (selectedEventDesign) {

      this._logger.trace(`broadcasting id: ${eventDesignId}`, selectedEventDesign);
      this.OnEventDesignSelect.emit(eventDesignId);
    } else {

      this._logger.error(`EventDesign id: ${eventDesignId} not found in eventDesigns`, this.eventDesigns());
    }
  }


  selectVenueEvent(venueEventId: number) {

    this._logger.trace(`id: ${venueEventId}`);
    const venueEvents = this.venue().venueEvents;
    const selectedVenueEvent = venueEvents.find(ve => ve.id == venueEventId);

    if (selectedVenueEvent) {

      this._logger.trace(`emit venueEventId: ${venueEventId}`);
      this.selectedVenueEvent.set(selectedVenueEvent);
    }
  }


}
