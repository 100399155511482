import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";

export type PlayListState<T> = {
    
    autoNext: boolean
    playListItems: T[]
    /**
     * Set the desired start item.
     * Automatically reset to undefined after applying.
     */
    selectItem?: T
}


export class PlayListComponent<T> extends MyOptyxComponent {

    private _playListItemIndex = -1;

    protected override state: PlayListState<T> = {

        autoNext: false,
        playListItems: [],
        selectItem: undefined
    };

    // Pending state initialized to match current state.
    override pendingState: PlayListState<T> = {
        
        autoNext: false,
        playListItems: [],
        selectItem: undefined
    };

    firstDisplay = false;

    // Events
    private readonly _source = new Subject<T | undefined>();
    readonly source$ = this._source.asObservable();
    
    private _logger = getLogger();


    constructor(destroyRef: DestroyRef) {
        super(destroyRef);
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {

        if (this.pendingStateChanges.find(psc => psc === 'playListItems')) {

            this.state.playListItems = this.pendingState.playListItems;
            this._playListItemIndex = -1;

            if (1 > this.pendingState.playListItems.length || this.pendingState.selectItem) {

                this.next();
                return;
            }
        }

        if (this.pendingState.autoNext) {
            
            this.next();
        }
    }


    broadcastCurrentItem() {

        const currentItem = this.getCurrentItem();
        if (currentItem) {
            
            this._source.next(currentItem);
        }
    }


    getCurrentItem(): T | undefined {

        if (-1 < this._playListItemIndex && this._playListItemIndex < this.state.playListItems.length) {

            return this.state.playListItems[this._playListItemIndex];
        }

        return undefined;
    }


    override getState(): PlayListState<T> {

        // Might want to deepCopy depending on properties.
        return this.state;
    }


    // If overriding be sure to call base method.
    override init(): PlayListComponent<T> {
        super.init();
        
        return this;
    }


    override onDestroy(): void {  }


    next(): void {

        if (1 > this.state.playListItems.length) {

            this._playListItemIndex = -1;
            this._source.next(undefined);
            return;
        }

        this.firstDisplay = !this.firstDisplay && -1 === this._playListItemIndex;

        if (this.pendingState.selectItem) {

            const newIndex = this.pendingState.playListItems.findIndex(pli => pli === this.pendingState.selectItem);
            this.pendingState.selectItem = undefined;
            this._playListItemIndex = -1 < newIndex ? newIndex : (this._playListItemIndex + 1) % this.state.playListItems.length;
        } else {
        
            this._playListItemIndex = (this._playListItemIndex + 1) % this.state.playListItems.length;
        }

        this._source.next(this.state.playListItems[this._playListItemIndex]);
    }

}