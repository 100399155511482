import { defineSystem } from 'bitecs'
import { ScaleComponent } from '../component/scale.component';
import { PositionComponent } from '../component/position.component';
import { Euler, Object3D, Object3DEventMap } from 'three';
import { RotationComponent } from '../component/rotation.component';
import { object3dByEntityId } from '..';
import { objectEntityQuery } from '../entity/object-prop.entity';


const euler = new Euler(0, 0, 0, 'YXZ');

export const objectUpdateSystem = defineSystem(world => {

    let object3d: Object3D<Object3DEventMap>;
    const objectEntities = objectEntityQuery(world);
    for (let i = 0; i < objectEntities.length; i++) {

        object3d = object3dByEntityId[objectEntities[i]];
        setCommonAdjustments(object3d, objectEntities[i]);
    }

    return world;
})


/**
 * Update the position, rotation, scale values of the object group.
 * @param entity 
 * @param entityId 
 */
function setCommonAdjustments(object3d: Object3D, entityId: number) {

    object3d.position.set(
        PositionComponent.x[entityId],
        PositionComponent.y[entityId],
        PositionComponent.z[entityId]);

    object3d.quaternion.setFromEuler(euler.set(
        RotationComponent.x[entityId],
        RotationComponent.y[entityId],
        RotationComponent.z[entityId], 'YXZ'));

    object3d.scale.set(
        ScaleComponent.x[entityId],
        ScaleComponent.y[entityId],
        ScaleComponent.z[entityId]);
}