<div #videoPropOptionsSidebarComponent tabindex="0" [autofocus]="setFocus()" (blur)="handleBlur($event)"
    class="fixed h-screen flex flex-col overflow-hidden top-0 bottom-0 w-full z-50 md:max-w-[500px] bg-[#0e0f10] shadow-xl 
    {{ approvedOpen() ? 'right-0' : '-right-full' }} transition-all duration-300 px-4 bg-opacity-80 border-l-[1px] border-accent">

    <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row">
            <!-- close icon -->
            <div class=" text-4xl w-11 h-[70px] flex justify-start items-center cursor-pointer transition"
                (click)="handleClose()">
                <ng-icon class="text-3xl transition {{ closing() ? 'rotate-[2turn]' : 'rotate-0' }}"
                    name="bootstrapXLg" />
            </div>
            <div class="flex items-center text-accent text-2xl font-semibold overflow-hidden">
                Details
            </div>
        </div>
    </div>

    <div class="flex-1 flex overflow-hidden">
        <div
            class="flex-1 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:[width:15px] [&::-webkit-scrollbar-thumb]:bg-accent">

            <div class="flex flex-col space-y-4 pb-36 mr-4 w-full">
                <div class="flex items-center text-xl font-semibold overflow-hidden">
                    {{VideoAssignment().name}}
                </div>

                @if (!VideoAssignment().suppressSidebarVideo) {

                <div class="w-full max-h-80 overflow-hidden">

                    <video [id]="id" aria-hidden="true" class="w-full max-h-80 object-scale-down" loop controls
                        crossorigin="anonymous" (play)="onPlay()">
                        <source src="">
                    </video>

                </div>

                }

                @if (designVideo().description && 0 < designVideo().description.length) { 
                    
                    <div
                    class="flex flex-col text-lg overflow-hidden" [innerHTML]="designVideo().description">
                    </div>

                }

            @if (VideoAssignment().description && 0 < VideoAssignment().description.length) { 
                
                <div [innerHTML]="VideoAssignment().description"
                    class="flex flex-col text-lg overflow-hidden">
                </div>

            }

    </div>

</div>

</div>
</div>