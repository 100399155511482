import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalInterceptor } from "@azure/msal-angular";
import { getLogger } from "projects/my-common/src/util/log";
import { Observable } from "rxjs";

export const BYPASS_MSAL = new HttpContextToken(() => false);

@Injectable()
export class MeInterceptor extends MsalInterceptor implements HttpInterceptor {
  private readonly _logger = getLogger();
    
  override intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    //this._logger.trace(request);

    if (request.context.get(BYPASS_MSAL)) {
        this._logger.trace("--> BYPASS");
      return next.handle(request);
    }

    return super.intercept(request, next);
  }

  
}