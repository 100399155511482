import { Component, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapCart3 } from '@ng-icons/bootstrap-icons';
import { CartService } from 'src/app/core/service/cart/cart.service';
import { SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { CART_SIDEBAR_ID } from 'src/app/components/cart/cart-sidebar/cart-sidebar.component';


@Component({
  selector: 'app-cart-status',
  standalone: true,
  templateUrl: './cart-status.component.html',
  styleUrls: ['./cart-status.component.scss'],
  providers: [provideIcons({ bootstrapCart3 })],
  imports: [
    CommonModule,
    NgIconComponent,
    RouterLink,
    RouterLinkActive],
})
export class CartStatusComponent {

  readonly badge = computed(() => this.cartService.showroomCart().items.length);

  constructor(
    readonly cartService: CartService,
    private readonly _sidebarService: SidebarService
  ) { }


  openCartSidebar() {
    
    this._sidebarService.open(CART_SIDEBAR_ID);
  }

}
