import { ISubscription, MpSdk, Scene } from "static/sdk";
import { MPSceneComponent } from "projects/mp-common/src";
import { Subscription } from "rxjs";
import { DestroyRef } from '@angular/core';
import { EnvironmentComponent, TextureManager, Vector3Obj, getLogger } from "projects/my-common/src";
import { SCENE_COMPONENT_TYPE } from "projects/mp-common/src/sdk-components/MPScene";
import { EcsWorld } from "projects/my-common/src/ecs";
import { EnvironmentEntity } from "projects/my-common/src/ecs/entity/environment.entity";


export class EnvironmentNodeInputs { }


/**
 * The Matterport node, components, bindings and events required to implement a Showroom ennvironment.
 */
export class EnvironmentNode {

    inputs!: EnvironmentNodeInputs;
    node: Scene.INode;
    environmentComponent!: EnvironmentComponent;
    mpSceneComponent!: MPSceneComponent;
    gltfLoaderComponent!: Scene.IComponent;

    private _logger = getLogger();
    private readonly _iSubscriptions: ISubscription[] = [];
    private readonly _subscriptions: Subscription[] = [];

    // 
    // Events
    //

    private _worldSubscription?: Subscription;
    private _world?: EcsWorld;


    constructor(private readonly destroyRef: DestroyRef,
        private readonly scene: MpSdk.Scene.IObject,
        private readonly textureManager: TextureManager,
        private readonly mpSdk: MpSdk) {

        destroyRef.onDestroy(() => this.onDestroy());

        this.node = scene.addNode();

        this.addComponents();
        // Effect composer causes mouse scroll zoom to fail.
        //this.mpSceneComponent.configureEffectComposer(mpSdk);
        this.node.start();
        this.mpSceneComponent.inputs.object = this.environmentComponent.getObjectGroup();
    }


    private addComponents(): void {

        this.environmentComponent = new EnvironmentComponent(this.destroyRef, this.textureManager.three).init();
        this.mpSceneComponent = this.node.addComponent(SCENE_COMPONENT_TYPE, {}) as MPSceneComponent;

        // This is just to ensure GltfLoader is available in three from Matterport.
        this.gltfLoaderComponent = this.node.addComponent('mp.gltfLoader', {});
    }



    private onDestroy() {

        this._worldSubscription?.unsubscribe();
        this._iSubscriptions.forEach(_is => _is.cancel());
        this._subscriptions.forEach(s => s.unsubscribe());
    }


    updateWorld(world?: EcsWorld): void {

        if (!world) {

            return;
        }
        if (this._world !== world) {

            this._world = world;
        }

        const environmentEntity: EnvironmentEntity = {

            objectGroup: this.environmentComponent.getObjectGroup(),
            position: this.environmentComponent.pendingState.position,
            rotation: this.environmentComponent.pendingState.rotation,
            scale: this.environmentComponent.pendingState.scale
        }

        world.upsertEnvironment(environmentEntity);
    }


}
