
export enum SignalREntityType { Unknown = 0, PurchaseOrder = 1}

export interface ISignalREvent {
    entityType: SignalREntityType
    entityId: number,
    message: string,
    status: string
}

