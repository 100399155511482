import { defineSystem, IWorld } from 'bitecs'
import {
    ClippingAssignmentComponent, ClippingPlaneComponent, DestinationPositionComponent, DestinationRotationComponent, DestinationScaleComponent,
    FromPositionComponent, FromRotationComponent
} from '..';
import { WorldState } from '../ecs-world.state';
import { AdjustableTransitionInitState, getAdjustmentEidForDestinationPosition, getClippingAssignmentEidForDestinationPosition } from './adjustable-transition-init.state';
import { AdjustmentComponent } from '../component/adjustment.component';
import { ShowroomPositionComponent } from '../component/showroom-position.component';
import { Vector3 } from 'three';
import { showroomPositionByEntityId } from '../entity/showroom-position.entity';
import { adjustableEntityQuery, clippingPlaneEntityQuery } from '../entity/adjustable.entity';
import { getClippingAssignmentEidForCurrentPosition } from './adjustment-init.state';

/**
 * System factory.
 * A transition is initated when the stage sets a desitnation position Id.
 * Initialize the destination adjustement values.
 * @param stage 
 * @returns The system initialized with the stage.
 */
export const adjustableTransitionInitSystem = defineSystem(world => {

    if (WorldState.currentPositionEntityId !== WorldState.destinationPositionEntityId
        && WorldState.destinationPositionEntityId !== AdjustableTransitionInitState.destinationShowroomPositionId) {

        AdjustableTransitionInitState.destinationShowroomPositionId = WorldState.destinationPositionEntityId;
        const destinationShowroomPosition = showroomPositionByEntityId[AdjustableTransitionInitState.destinationShowroomPositionId];
        if (!destinationShowroomPosition) {

            return world;
        }
    } else {

        return world;
    }

    AdjustableTransitionInitState.transitionDistance = new Vector3(
        ShowroomPositionComponent.x[WorldState.currentPositionEntityId],
        ShowroomPositionComponent.y[WorldState.currentPositionEntityId],
        ShowroomPositionComponent.z[WorldState.currentPositionEntityId]).distanceTo({
            x: ShowroomPositionComponent.x[AdjustableTransitionInitState.destinationShowroomPositionId],
            y: ShowroomPositionComponent.y[AdjustableTransitionInitState.destinationShowroomPositionId],
            z: ShowroomPositionComponent.z[AdjustableTransitionInitState.destinationShowroomPositionId],
        })

    initializeAdjustables(world);
    initializeClippingPlanes(world);

    return world;
});


function initializeAdjustables(world: IWorld) {

    // Initialize the transition values.
    let eidForAdjustable = 0, eidForAdjustment = 0;
    const adjustableEntities = adjustableEntityQuery(world);
    for (let i = 0; i < adjustableEntities.length; i++) {

        eidForAdjustable = adjustableEntities[i];
        eidForAdjustment = getAdjustmentEidForDestinationPosition(eidForAdjustable);
        if (-1 < eidForAdjustment) {

            // if (AdjustmentComponent.hide[eidForAdjustment]) {

            //     DestinationMaskComponent.hide[eidForAdjustable] = 1;
            //     if (0 !== FromMaskComponent.hide[eidForAdjustable]) {

            //         // Both adjustments are hidden. Nothing to do.
            //         continue;
            //     }
            // } else {

            //     DestinationMaskComponent.hide[eidForAdjustable] = 0;
            // }
            setDestinationAdjustmentValues(eidForAdjustable, eidForAdjustment);
            // setMaskDestinationValues(eidForAdjustable, eidForAdjustment);
        } else {
            
            // DestinationMaskComponent.hide[eidForAdjustable] = 0;
            setDefaultDestinationAdjustmentValues(eidForAdjustable);
            // setDefaultMaskDestinationValues(eidForAdjustable);
        }
    }
}


function initializeClippingPlanes(world: IWorld) {


    // Initialize the transition values.
    let eidForClippingPlane = 0, eidForClippingAssignment = 0;
    const clippingPlaneEntities = clippingPlaneEntityQuery(world);
    for (let i = 0; i < clippingPlaneEntities.length; i++) {

        eidForClippingPlane = clippingPlaneEntities[i];
        eidForClippingAssignment = getClippingAssignmentEidForDestinationPosition(eidForClippingPlane);

        // If we have an Adjustment level assignment then use its values.
        if (-1 < eidForClippingAssignment) {

            setDestinationClippingAssignmentValues(eidForClippingPlane, eidForClippingAssignment);
        } else if (0 !== ClippingPlaneComponent.activatePropLevel[eidForClippingPlane]) {

            // If we have a Prop level assignment then use its values
            DestinationPositionComponent.x[eidForClippingPlane] = ClippingPlaneComponent.position.x[eidForClippingPlane];
            DestinationPositionComponent.y[eidForClippingPlane] = ClippingPlaneComponent.position.y[eidForClippingPlane];
            DestinationPositionComponent.z[eidForClippingPlane] = 0;

            DestinationRotationComponent.x[eidForClippingPlane] = 0;
            DestinationRotationComponent.y[eidForClippingPlane] = 0;
            DestinationRotationComponent.z[eidForClippingPlane] = ClippingPlaneComponent.rotationZ[eidForClippingPlane];

            DestinationScaleComponent.x[eidForClippingPlane] = ClippingPlaneComponent.scale.x[eidForClippingPlane];
            DestinationScaleComponent.y[eidForClippingPlane] = ClippingPlaneComponent.scale.y[eidForClippingPlane];
            DestinationScaleComponent.z[eidForClippingPlane] = 0;
        } else {

            setDefaultDestinationClippingPlaneValues(eidForClippingPlane);
        }
    }
}


function setDefaultDestinationAdjustmentValues(eidForAdjustable: number) {

    // If no adjustment values are found then destination values are 0 (no adjustment).
    DestinationPositionComponent.x[eidForAdjustable] = DestinationPositionComponent.y[eidForAdjustable] = DestinationPositionComponent.z[eidForAdjustable] =
        DestinationRotationComponent.x[eidForAdjustable] = DestinationRotationComponent.y[eidForAdjustable] = DestinationRotationComponent.z[eidForAdjustable] =
        DestinationScaleComponent.x[eidForAdjustable] = DestinationScaleComponent.y[eidForAdjustable] = DestinationScaleComponent.z[eidForAdjustable] = 0;
}


/**
 * Called when there is not Destination Clipping Assignment defined
 * @param eidForClippingPlane 
 */
function setDefaultDestinationClippingPlaneValues(eidForClippingPlane: number) {

    const eidForCurrentClippingAssignment = getClippingAssignmentEidForCurrentPosition(eidForClippingPlane);
    if (-1 < eidForCurrentClippingAssignment) {

        DestinationPositionComponent.x[eidForClippingPlane] = FromPositionComponent.x[eidForClippingPlane];
        DestinationPositionComponent.y[eidForClippingPlane] = FromPositionComponent.y[eidForClippingPlane];
        DestinationPositionComponent.z[eidForClippingPlane] = FromPositionComponent.z[eidForClippingPlane];

        DestinationRotationComponent.x[eidForClippingPlane] = FromRotationComponent.x[eidForClippingPlane];
        DestinationRotationComponent.y[eidForClippingPlane] = FromRotationComponent.y[eidForClippingPlane];
        DestinationRotationComponent.z[eidForClippingPlane] = FromRotationComponent.z[eidForClippingPlane];
    }
    
    // Scale is always 0 if From Clipping Assignment is not defined.
    DestinationScaleComponent.y[eidForClippingPlane] = DestinationScaleComponent.x[eidForClippingPlane] = DestinationScaleComponent.y[eidForClippingPlane] = 0;
}


function setDestinationAdjustmentValues(eidForAdjustable: number, eidForAdjustment: number) {

    DestinationPositionComponent.x[eidForAdjustable] = AdjustmentComponent.position.x[eidForAdjustment];
    DestinationPositionComponent.y[eidForAdjustable] = AdjustmentComponent.position.y[eidForAdjustment];
    DestinationPositionComponent.z[eidForAdjustable] = AdjustmentComponent.position.z[eidForAdjustment];

    DestinationRotationComponent.x[eidForAdjustable] = AdjustmentComponent.rotation.x[eidForAdjustment];
    DestinationRotationComponent.y[eidForAdjustable] = AdjustmentComponent.rotation.y[eidForAdjustment];
    DestinationRotationComponent.z[eidForAdjustable] = AdjustmentComponent.rotation.z[eidForAdjustment];

    DestinationScaleComponent.x[eidForAdjustable] = AdjustmentComponent.scale.x[eidForAdjustment];
    DestinationScaleComponent.y[eidForAdjustable] = AdjustmentComponent.scale.y[eidForAdjustment];
    DestinationScaleComponent.z[eidForAdjustable] = AdjustmentComponent.scale.z[eidForAdjustment];
}


function setDestinationClippingAssignmentValues(eidForClippingPlane: number, eidForClippingAssignment: number) {

    DestinationPositionComponent.x[eidForClippingPlane] = ClippingAssignmentComponent.position.x[eidForClippingAssignment];
    DestinationPositionComponent.y[eidForClippingPlane] = ClippingAssignmentComponent.position.y[eidForClippingAssignment];
    DestinationPositionComponent.z[eidForClippingPlane] = 0;

    DestinationRotationComponent.x[eidForClippingPlane] = 0;
    DestinationRotationComponent.y[eidForClippingPlane] = 0;
    DestinationRotationComponent.z[eidForClippingPlane] = ClippingAssignmentComponent.rotationZ[eidForClippingAssignment];

    DestinationScaleComponent.x[eidForClippingPlane] = ClippingAssignmentComponent.scale.x[eidForClippingAssignment];
    DestinationScaleComponent.y[eidForClippingPlane] = ClippingAssignmentComponent.scale.y[eidForClippingAssignment];
    DestinationScaleComponent.z[eidForClippingPlane] = 0;
}


// function setDefaultMaskDestinationValues(eidForAdjustable: number) {

//     DestinationHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.NONE;
//     DestinationHorizontalMaskComponent.offset[eidForAdjustable] = 0;
//     DestinationHorizontalMaskComponent.width[eidForAdjustable] = 1;

//     DestinationVerticalMaskComponent.mode[eidForAdjustable] = VerticalClipMode.NONE;
//     DestinationVerticalMaskComponent.offset[eidForAdjustable] = 0;
//     DestinationVerticalMaskComponent.height[eidForAdjustable] = 1;
// }


// export function isFromAndDestinationMaskBothNone(eidForAdjustable: number) {

//     return HorizontalClipMode.NONE === FromHorizontalMaskComponent.mode[eidForAdjustable]
//         && VerticalClipMode.NONE === FromVerticalMaskComponent.mode[eidForAdjustable]
//         && HorizontalClipMode.NONE === DestinationHorizontalMaskComponent.mode[eidForAdjustable]
//         && VerticalClipMode.NONE === DestinationVerticalMaskComponent.mode[eidForAdjustable];
// }


// function setMaskDestinationValues(eidForAdjustable: number, eidForAdjustment: number) {

//     if (isFromAndDestinationMaskBothNone(eidForAdjustable)) {

//         return;
//     }

//     // If destination is hidden then destination mask is set based upon the current mask.
//     // RIGHT = BOTTOM, LEFT = TOP
//     if (AdjustmentComponent.hide[eidForAdjustment]) {

//         if (HorizontalClipMode.RIGHT === FromHorizontalMaskComponent.mode[eidForAdjustable]) {

//             DestinationHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.RIGHT;
//             DestinationHorizontalMaskComponent.offset[eidForAdjustable] = 0;
//         } else if (HorizontalClipMode.LEFT === FromHorizontalMaskComponent.mode[eidForAdjustable]) {

//             DestinationHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.LEFT;
//             DestinationHorizontalMaskComponent.width[eidForAdjustable] = 1;
//         } else if (HorizontalClipMode.CENTER === FromHorizontalMaskComponent.mode[eidForAdjustable]) {

//             DestinationHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.CENTER;
//             DestinationHorizontalMaskComponent.width[eidForAdjustable] = 1;
//         } else if (HorizontalClipMode.EDGES === FromHorizontalMaskComponent.mode[eidForAdjustable]
//             || HorizontalClipMode.NONE === FromHorizontalMaskComponent.mode[eidForAdjustable]) {

//             DestinationHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.EDGES;
//             DestinationHorizontalMaskComponent.width[eidForAdjustable] = 0;
//             DestinationHorizontalMaskComponent.offset[eidForAdjustable] = .5;
//         }

//         if (VerticalClipMode.BOTTOM === FromVerticalMaskComponent.mode[eidForAdjustable]) {

//             DestinationVerticalMaskComponent.mode[eidForAdjustable] = VerticalClipMode.BOTTOM;
//             DestinationVerticalMaskComponent.offset[eidForAdjustable] = 0;
//         } else if (VerticalClipMode.TOP === FromVerticalMaskComponent.mode[eidForAdjustable]) {

//             DestinationVerticalMaskComponent.mode[eidForAdjustable] = VerticalClipMode.TOP;
//             DestinationVerticalMaskComponent.height[eidForAdjustable] = 1;
//         } else if (HorizontalClipMode.CENTER === FromVerticalMaskComponent.mode[eidForAdjustable]) {

//             DestinationVerticalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.CENTER;
//             DestinationVerticalMaskComponent.height[eidForAdjustable] = 1;
//         } else if (HorizontalClipMode.EDGES === FromVerticalMaskComponent.mode[eidForAdjustable]) {

//             DestinationVerticalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.EDGES;
//             DestinationVerticalMaskComponent.height[eidForAdjustable] = 0;
//             DestinationVerticalMaskComponent.offset[eidForAdjustable] = .5;
//         }

//     } else {

//         DestinationHorizontalMaskComponent.mode[eidForAdjustable] = AdjustmentComponent.horizontalMaskMode[eidForAdjustment];
//         DestinationHorizontalMaskComponent.offset[eidForAdjustable] = AdjustmentComponent.horizontalMaskOffset[eidForAdjustment];
//         DestinationHorizontalMaskComponent.width[eidForAdjustable] = AdjustmentComponent.horizontalMaskWidth[eidForAdjustment];

//         DestinationVerticalMaskComponent.mode[eidForAdjustable] = AdjustmentComponent.verticalMaskMode[eidForAdjustment];
//         DestinationVerticalMaskComponent.offset[eidForAdjustable] = AdjustmentComponent.verticalMaskOffset[eidForAdjustment];
//         DestinationVerticalMaskComponent.height[eidForAdjustable] = AdjustmentComponent.verticalMaskHeight[eidForAdjustment];
//     }


//     // If from mask is hidden change its values based upon the destination values.
//     if (0 !== FromMaskComponent.hide[eidForAdjustable]) {

//         if (HorizontalClipMode.RIGHT === AdjustmentComponent.horizontalMaskMode[eidForAdjustment]) {

//             HorizontalMaskComponent.mode[eidForAdjustable] = FromHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.RIGHT;
//             HorizontalMaskComponent.offset[eidForAdjustable] = FromHorizontalMaskComponent.offset[eidForAdjustable] = 0;
//         } else if (HorizontalClipMode.LEFT === AdjustmentComponent.horizontalMaskMode[eidForAdjustment]) {

//             HorizontalMaskComponent.mode[eidForAdjustable] = FromHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.LEFT;
//             HorizontalMaskComponent.width[eidForAdjustable] = FromHorizontalMaskComponent.width[eidForAdjustable] = 1;
//         } else if (HorizontalClipMode.CENTER === AdjustmentComponent.horizontalMaskMode[eidForAdjustment]) {

//             HorizontalMaskComponent.mode[eidForAdjustable] = FromHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.CENTER;
//             HorizontalMaskComponent.width[eidForAdjustable] = FromHorizontalMaskComponent.width[eidForAdjustable] = 1;
//         } else if (HorizontalClipMode.EDGES === AdjustmentComponent.horizontalMaskMode[eidForAdjustment]
//             || HorizontalClipMode.NONE === AdjustmentComponent.horizontalMaskMode[eidForAdjustment]) {

//             HorizontalMaskComponent.mode[eidForAdjustable] = FromHorizontalMaskComponent.mode[eidForAdjustable] = HorizontalClipMode.EDGES;
//             HorizontalMaskComponent.width[eidForAdjustable] = FromHorizontalMaskComponent.width[eidForAdjustable] = 0;
//             HorizontalMaskComponent.offset[eidForAdjustable] = FromHorizontalMaskComponent.offset[eidForAdjustable] = .5;
//         }

//         if (VerticalClipMode.BOTTOM === AdjustmentComponent.verticalMaskMode[eidForAdjustment]) {

//             VerticalMaskComponent.mode[eidForAdjustable] = FromVerticalMaskComponent.mode[eidForAdjustable] = VerticalClipMode.BOTTOM;
//             VerticalMaskComponent.offset[eidForAdjustable] = FromVerticalMaskComponent.offset[eidForAdjustable] = 0;
//         } else if (VerticalClipMode.TOP === AdjustmentComponent.verticalMaskMode[eidForAdjustment]) {

//             VerticalMaskComponent.mode[eidForAdjustable] = FromVerticalMaskComponent.mode[eidForAdjustable] = VerticalClipMode.TOP;
//             VerticalMaskComponent.height[eidForAdjustable] = FromVerticalMaskComponent.height[eidForAdjustable] = 1;
//         } else if (VerticalClipMode.CENTER === AdjustmentComponent.verticalMaskMode[eidForAdjustment]) {

//             VerticalMaskComponent.mode[eidForAdjustable] = FromVerticalMaskComponent.mode[eidForAdjustable] = VerticalClipMode.CENTER;
//             VerticalMaskComponent.height[eidForAdjustable] = FromVerticalMaskComponent.height[eidForAdjustable] = 1;
//         } else if (VerticalClipMode.EDGES === AdjustmentComponent.verticalMaskMode[eidForAdjustment]) {

//             VerticalMaskComponent.mode[eidForAdjustable] = FromVerticalMaskComponent.mode[eidForAdjustable] = VerticalClipMode.EDGES;
//             VerticalMaskComponent.height[eidForAdjustable] = FromVerticalMaskComponent.height[eidForAdjustable] = 0;
//         }
//     }
// }

