import { AfterViewInit, Component, OnDestroy, QueryList, ViewChildren, computed, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VenueEventSelectComponent } from "../../venue-event/venue-event-select/venue-event-select.component";
import { EventDesignSelectComponent } from "../../event-design/event-design-select/event-design-select.component";
import { VenueService } from 'src/app/core/service/venue/venue.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { InteractionModeSelectComponent } from "../../shared/interaction-mode-select/interaction-mode-select.component";
import { ShowroomMode } from 'src/app/state/showroom-state';
import { ShowroomService } from 'src/app/core/service/showroom/showroom.service';
import { isChildElement } from 'projects/my-common/src/util/utils';
import { OBJECT_PROPS_SIDEBAR_ID } from '../../shared/object-props-sidebar/object-props-sidebar.component';
import { IMAGE_PROPS_SIDEBAR_ID } from '../../shared/image-props-sidebar/image-props-sidebar.component';
import { VIDEO_PROPS_SIDEBAR_ID } from '../../shared/video-props-sidebar/video-props-sidebar.component';
import { POSITIONS_SIDEBAR_ID } from '../../shared/positions-sidebar/positions-sidebar.component';


export const SHOWROOM_ADMIN_OPTIONS_SIDEBAR_ID = '7d72c66d-7c57-4a31-80d2-faea895b1c48';

// NgxLoggerLevel.ERROR
// LoggerModule.forRoot({
//serverLoggingUrl: '/api/logs',
//level: environment.logLevel,
//serverLogLevel: NgxLoggerLevel.ERROR
//})),
// logger.updateConfig({level: NgxLoggerLevel.TRACE });


/**
 * To maintain a clear separation of concerns and User Experience, the Admins, Subscribers and Guests will have separate sidebars
 */
@Component({
  selector: 'app-showroom-admin-options-sidebar',
  standalone: true,
  templateUrl: './showroom-admin-options-sidebar.component.html',
  styleUrls: ['./showroom-admin-options-sidebar.component.scss'],
  imports: [CommonModule, VenueEventSelectComponent, EventDesignSelectComponent, NgIconComponent, InteractionModeSelectComponent],
  providers: [provideIcons({ bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
})
export class ShowroomAdminOptionsSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  private readonly _subscriptions: Subscription[] = [];
  sidebarId: string = SHOWROOM_ADMIN_OPTIONS_SIDEBAR_ID;
  private _lock = 0;
  readonly isOpen = signal(false);
  readonly closeClicked = signal(false);

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('showroomAdminOptionsSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  /**
   * Template need to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.isOpen()) {

      if (this.elementRef && 0 < this.elementRef.length) {

        (this.elementRef.first as any).nativeElement.focus();
      }
      return true;
    }
    return false;
  });

  readonly logLevels = Object.values(NgxLoggerLevel).filter(value => typeof value !== 'number');

  readonly configActionsEnabled = computed(() => ShowroomMode.CONFIGURATION === this.currentState().instance.showroomMode);
  readonly currentLogLevel = signal(NgxLoggerLevel[this._logger.level]);
  readonly stageActionsEnabled = computed(() => ShowroomMode.STAGE === this.currentState().instance.showroomMode);

  readonly assignedObjectPropCount = this._venueService.assignedObjectPropCount;
  readonly currentEventDesign = this._venueService.currentEventDesign;
  readonly currentState = this._showroomService.state;
  readonly currentVenueEvent = this._venueService.currentVenueEvent;
  readonly eventDesigns = this._venueService.eventDesigns;
  readonly objectPropCount = this._venueService.objectPropCount;
  readonly venueEvents = this._venueService.venueEvents;
  readonly venue = this._venueService.venue;

  readonly AddObjectProp = output<boolean>();
  readonly CreateImageProp = output<boolean>();
  readonly CreateVideoProp = output<boolean>();
  // readonly CreateObjectProp = output<boolean>();   // Object Props are no longer staged
  readonly OnEventDesignSelect = output<number>();
  readonly OnVenueEventSelect = output<number>();


  constructor(private readonly _route: ActivatedRoute,
    private readonly _sidebarService: SidebarService,
    private readonly _venueService: VenueService,
    private readonly _logger: NGXLogger,
    private readonly _showroomService: ShowroomService) {

    _sidebarService.add(this);
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.handleBlur.bind(this);
  handleBlur() {
    if (this._handlingBlur) return;
    this._handlingBlur = true;

    const elementRef = (this.elementRef.first as any).nativeElement;
    if (!elementRef) {
      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {
      if (!isChildElement(elementRef, document.activeElement)) {
        this.isOpen.set(false);
      }
      this._handlingBlur = false;
    }, 1);
  }


  handleClose() {
    this.closeClicked.set(true);
    setTimeout(() => {
      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 200);
  }


  handleSelectLogLevel(logLevelEvent: any) {

    let selectedIndex = Number(logLevelEvent.target['options'].selectedIndex);
    this._logger.updateConfig({ level: selectedIndex });
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._sidebarService.remove(this);

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  /**
   * Add an Object Prop from the pool of unused licensed props onto the stage
   */
  onAddObjectProp() {

    if (this.assignedObjectPropCount() >= this.objectPropCount() || 0 < this._lock++) {

      return;
    }

    this.AddObjectProp.emit(true);
    this._lock = 0;
  }


  onStageImageProp() {

    if (0 < this._lock++) {

      return;
    }
    
    this.CreateImageProp.emit(true);
    this._lock = 0;
  }


  // Object Props are no longer staged
  // onStageObjectProp() {

  //   if (this._processing) {

  //     return;
  //   }
  //   this._processing = true;    
  //   this.CreateObjectProp.emit(true);
  //   this._processing = false;
  // }


  onStageVideoProp() {

    if (0 < this._lock++) {

      return;
    }
    
    this.CreateVideoProp.emit(true);
    this._lock = 0;
  }


  onListImageProps() {

    this._sidebarService.open(IMAGE_PROPS_SIDEBAR_ID);
  }


  onListObjectProps() {

    this._sidebarService.open(OBJECT_PROPS_SIDEBAR_ID);
  }


  onListShowroomPositions() {

    this._sidebarService.open(POSITIONS_SIDEBAR_ID);
  }


  onListVideoProps() {

    this._sidebarService.open(VIDEO_PROPS_SIDEBAR_ID);
  }


  selectEventDesign(eventDesignId: number) {

    this._logger.trace(`id: ${eventDesignId}`);
    const eventDesigns = this.eventDesigns();
    const selectedEventDesign = eventDesigns.find(ed => ed.id == eventDesignId);

    if (selectedEventDesign) {

      this._logger.trace(`broadcasting id: ${eventDesignId}`, selectedEventDesign);
      this.OnEventDesignSelect.emit(eventDesignId);
    } else {
      this._logger.error(`EventDesign id: ${eventDesignId} not found in eventDesigns`, eventDesigns);
    }
  }


  selectVenueEvent(venueEventId: number) {

    const venueEvents = this.venue().venueEvents;
    const selectedVenueEvent = venueEvents.find(ve => ve.id == venueEventId);

    if (selectedVenueEvent) {

      this._logger.trace(`emit venueEventId: ${venueEventId}`);
      this.OnVenueEventSelect.emit(venueEventId);
    }
  }


}
