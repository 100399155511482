import { Component, Input, OnDestroy, QueryList, ViewChildren, WritableSignal, computed, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapXLg } from "@ng-icons/bootstrap-icons";
import { ItemDesc } from 'src/app/core/model/matterport/matterport.model';

export const PROP_SELECT_SIDEBAR_ID = '7d3d55cf-88d8-411b-bcb4-09a4e527850b';

@Component({
  selector: 'app-prop-select-sidebar',
  standalone: true,
  providers: [provideIcons({ bootstrapXLg })],
  imports: [CommonModule, NgIconComponent],
  templateUrl: './prop-select-sidebar.component.html',
  styleUrls: ['./prop-select-sidebar.component.scss']
})
export class PropSelectSidebarComponent implements ISidebar, OnDestroy {

  readonly sidebarId: string = PROP_SELECT_SIDEBAR_ID;
  readonly isOpen: WritableSignal<boolean> = signal(false);
  readonly closeClicked: WritableSignal<boolean> = signal(false);

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('sidebar') sidebar!: QueryList<HTMLInputElement>;
  /**
   * Template need to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {
    if (this.isOpen())
    {
      if (this.sidebar && 0 < this.sidebar.length) {
        (this.sidebar.first as any).nativeElement.focus();
      }
      return true;
    }
    return false;
  });

  readonly stageObjectCategory: WritableSignal<string> = signal('');
  readonly stageObjectOptions: WritableSignal<ItemDesc[]> = signal([]);
  @Input() set PropOptions(value: ItemDesc[]) {

    this.stageObjectOptions.set(value);
    if (0 < value.length && 0 < value[0].categories.length) {

      this.stageObjectCategory.set(value[0].categories[0]);
    }
  }

  readonly onSelect = output<ItemDesc>();

  
  constructor(private _sidebarService: SidebarService) {

    _sidebarService.add(this);
  }


  handleBlur(blurEvent: any) {  

    if (blurEvent.relatedTarget) {
      
      return;
    }  
    this.isOpen.set(false);
  }


  handleClose() {
    
    this.closeClicked.set(true);
    setTimeout(() => {
      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 200);
  }


  selectStageObjectOption(stageObjectOption: ItemDesc) {
    this.onSelect.emit(stageObjectOption);
    this.handleClose();
  }


  ngOnDestroy(): void {
    this._sidebarService.remove(this);
  }


}
