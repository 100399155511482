import { AfterViewInit, Component, OnDestroy, QueryList, ViewChildren, WritableSignal, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { DesignImageService } from 'src/app/core/service/venue/design-image.service';
import { ImportDesignImageCardComponent } from "../import-design-image-card/import-design-image-card.component";
import { isChildElement } from 'projects/my-common/src/util/utils';
import { PaginationComponent } from "../../../shared/pagination/pagination.component";

export const IMPORT_IMAGE_SIDEBAR_ID = '71d399e3-c6b6-4efb-8229-39dbc5fa0fa3';

@Component({
  selector: 'app-import-image-sidebar',
  standalone: true,
  providers: [provideIcons({ bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
  templateUrl: './import-image-sidebar.component.html',
  styleUrls: ['./import-image-sidebar.component.scss'],
  imports: [CommonModule, NgIconComponent, ImportDesignImageCardComponent, PaginationComponent]
})
export class ImportImageSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  sidebarId: string = IMPORT_IMAGE_SIDEBAR_ID;
  readonly isOpen: WritableSignal<boolean> = signal(false);
  readonly closeClicked: WritableSignal<boolean> = signal(false);

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('importImageSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  /**
   * Template needs to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.isOpen()) {

      const elementRef = (this.elementRef.first as any).nativeElement;
      elementRef?.focus();

      return true;
    }
    return false;
  })


  constructor(private readonly sidebarService: SidebarService,
    public readonly designImageService: DesignImageService) {
    sidebarService.add(this);
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.handleBlur.bind(this);
  handleBlur() {

    if (this._handlingBlur) {

      return;
    }
    this._handlingBlur = true;

    const elementRef = (this.elementRef.first as any).nativeElement;
    if (!elementRef) {

      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {

      if (!isChildElement(elementRef, document.activeElement)) {

        this.isOpen.set(false);
      }
      this._handlingBlur = false;
    }, 1);
  }


  handleClose(): void {

    this.closeClicked.set(true);
    setTimeout(() => {

      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 200);
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


}
