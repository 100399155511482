<div #objectPropsSidebarComponent tabindex="0" [autofocus]="setFocus()" (blur)="handleBlur($event)" 
    class="fixed h-screen flex flex-col overflow-hidden top-0 bottom-0 w-full z-50 md:max-w-[500px] bg-[#0e0f10] shadow-xl 
            {{ isOpen() ? 'right-0' : '-right-full' }} bg-opacity-80 transition-all duration-300">
    <div class="flex flex-row">
        <!-- close icon -->
        <div class=" text-4xl w-16 h-[70px] pl-4 flex justify-start items-center cursor-pointer transition"
            (click)="handleClose()">
            <ng-icon class="text-3xl transition {{ closeClicked() ? 'rotate-[2turn]' : 'rotate-0' }}"
                name="bootstrapXLg" />
        </div>
        <div class="flex items-center text-accent text-xl font-semibold overflow-hidden">
            Object props ({{objectProps().length}})
        </div>
    </div>
    <div class="flex-1 flex overflow-hidden">
        <div class="flex-1 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:[width:15px] [&::-webkit-scrollbar-thumb]:bg-accent">
            <!-- The following padding seems to adjust for stuff at top of sidebar. If scrollable area matched heigh of sidebar, don't think it would be needed -->
            <div class="flex flex-col space-y-4 pt-3 pb-28">
                
                @for (objectProp of objectProps(); track $index) {

                    <app-object-prop-action-card [ObjectAssignment]="objectProp" (SelectObject)="onSelectObject($event)"
                        (GoToObject)="onGoToObject($event)" />
                
                }
                
            </div>
        </div>
    </div>
</div>