import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren, computed, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapArrowCounterclockwise, bootstrapCheckLg, bootstrapEye, bootstrapEyeSlash, bootstrapPlusLg, bootstrapTrash, bootstrapUnlock, bootstrapXLg } from "@ng-icons/bootstrap-icons";
import { Vector3InputComponent } from "../../../shared/vector3-input/vector3-input.component";
import { PositionAdjustmentComponent } from "../../../shared/position-adjustment/position-adjustment.component";
import { Subscription } from 'rxjs/internal/Subscription';
import { NGXLogger } from 'ngx-logger';
import { IStage } from 'src/app/core/model/showroom/showroom.model';
import { isChildElement, Vector3Obj } from 'projects/my-common/src/util/utils';
import { FloatingSliderComponent } from 'src/app/components/shared/floating-slider/floating-slider.component';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { VenueService } from 'src/app/core/service/venue/venue.service';
import { IShowroomVideoProp } from 'src/app/core/model/showroom/video-prop.model';
import { NumberInputScrollDirective } from 'src/app/shared/directives/number-input-scroll.directive';
import { CommonVector3InputComponent } from "../../shared/common-vector3-input/common-vector3-input.component";
import { ISlider, SliderService } from 'src/app/core/service/ui/slider/slider.service';
import { SHOWROOM_COMMON_SLIDER_ID } from '../../shared/common-slider/common-slider.component';
import { TransformMode, TransformSpace } from 'projects/my-common/src';
import { NoCommaPipe } from 'src/app/shared//pipes/no-comma.pipe';
import { AccountService } from 'src/app/core/service/myoptyx/account.service';
import { CrudState, ClippingPlane, PositionAdjustment, IClippingPlane, VideoProp, equalsVideoProp, IPositionAdjustmentUpload, IVideoPropUpload } from 'projects/my-common/src/model';
import { ClippingPlaneComponent } from "../clipping-plane/clipping-plane.component";
import { CheckboxComponent } from "../../../shared/checkbox/checkbox.component";
import { ModalService } from 'src/app/core/service/ui/modal/modal.service';
import { ALERT_MODAL_ID } from 'src/app/components/shared/alert-modal/alert-modal.component';

export const STAGE_VIDEO_PROP_SIDEBAR_ID = '389033b7-b907-4b68-b3e2-0b6d731d07ab';


@Component({
  selector: 'app-stage-video-prop-sidebar',
  standalone: true,
  templateUrl: './stage-video-prop-sidebar.component.html',
  styleUrls: ['./stage-video-prop-sidebar.component.scss'],
  providers: [provideIcons({ bootstrapArrowCounterclockwise, bootstrapCheckLg, bootstrapEye, bootstrapEyeSlash, bootstrapPlusLg, bootstrapTrash, bootstrapUnlock, bootstrapXLg })],
  imports: [CommonModule, FloatingSliderComponent, NgIconComponent, NoCommaPipe, NumberInputScrollDirective, Vector3InputComponent, PositionAdjustmentComponent, PositionAdjustmentComponent, CommonVector3InputComponent, ClippingPlaneComponent, CheckboxComponent]
})
export class StageVideoPropSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  inputId = crypto.randomUUID();
  private _handlingBlur = false;
  private _lock = 0;
  private readonly _subscriptions: Subscription[] = [];
  readonly sidebarId: string = STAGE_VIDEO_PROP_SIDEBAR_ID;
  private _updateInitializers = true;
  private _videoNode?: IShowroomVideoProp;

  readonly closing = signal(false);
  readonly copyingVideoProp = signal(false);
  readonly currentPlayerPositionId = signal('');
  readonly enableCopy = signal(false);
  readonly isOpen = signal(false);
  readonly lockBaseValues = signal(true);
  readonly propReferenceCount = signal(-1);
  readonly selectedClippingPlaneId = signal(0);
  readonly selectedTab = signal(1);
  readonly videoProp = this.venueService.currentVideoProp;


  /**
   * Setup for slider close on blur when user clicks away from this component.
   */
  @ViewChildren('stageVideoPropSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  @ViewChildren('sliderBasedInputsStageVideoPropSidebar') sliderBasedInputs!: QueryList<HTMLInputElement>;

  readonly localVideoProp = signal(new VideoProp());
  @Input() set VideoProp(value: VideoProp) {

    if (value.id !== this.localVideoProp().id) {

      this.onVideoPropChanging();
    }

    this.localVideoProp.set(new VideoProp(value));
    this.setInitializers();

    // Update reference to the working Video Node
    this.setVideoNode();
    this.makeCurrentPositionAdjustmentFirst();
    this.loadPropReferences();
  }

  private _stage?: IStage;
  @Input({ required: true }) set Stage(value: IStage) {

    if (!value.getVideoNode) {

      return;
    }

    this._stage = value;
    this.setVideoNode();
    this.monitorPlayerPosition();
  }


  //
  // Selectors
  //
  readonly clippingPlanes = computed(() => this.localVideoProp().clippingPlanes
    .filter(vp => CrudState.DELETED !== vp.crudState));
  readonly enableSave = computed(() => !equalsVideoProp(this.localVideoProp(), this.videoProp())
    || this.localVideoProp().clippingPlanes.some(cp => cp.crudState !== CrudState.NONE)
    || this.localVideoProp().positionAdjustments.some(pa => pa.crudState !== CrudState.NONE)
    || this.localVideoProp().positionAdjustments.some(pa => pa.clippingAssignments.some(cpa => cpa.crudState !== CrudState.NONE)));
  readonly isCustomMaskFile = computed(() => 0 < this.localVideoProp().maskUrl.length);
  readonly isScaleEqual = computed(() => this.localVideoProp().scale[0] === this.localVideoProp().scale[1] && this.localVideoProp().scale[1] === this.localVideoProp().scale[2]);
  /**
   * @returns true if positionId is undefined or positionAdjustments are undefined or positionId is found in positionAdjustments, else false
   */
  readonly hasPositionAdjustment = computed(() => !this.currentPlayerPositionId()
    || !this.localVideoProp().positionAdjustments
    || this.localVideoProp().positionAdjustments.some(pa => pa.positionId === this.currentPlayerPositionId()
      && CrudState.DELETED !== pa.crudState));
  readonly positionAdjustments = computed(() => this.localVideoProp().positionAdjustments
    .map(pa => new PositionAdjustment(pa))
    .filter(pa => CrudState.DELETED !== pa.crudState));
  //
  // End selectors
  //

  //
  // Input initializers
  //
  readonly aspectInitializer = signal(0);

  readonly Copy = output<number>();
  readonly DeleteVideoProp = output<VideoProp>();
  readonly Save = output<VideoProp>();

  private _commonSlider?: ISlider;


  constructor(
    private readonly accountService: AccountService,
    private readonly logger: NGXLogger,
    private readonly modalService: ModalService,
    private readonly sidebarService: SidebarService,
    private readonly sliderService: SliderService,
    private readonly venueService: VenueService) {

    sidebarService.add(this);
  }


  /**
   * Mask file changes are saved on the server immdiately so they can be references via url.
   * Assumes prop already exists on the server.
   * @param event 
   * @returns 
   */
  async addCustomMask(event: any): Promise<void> {

    if (!event || 0 < this._lock++) {

      return;
    }

    const file = event.target.files[0];
    var sFileName = file.name;
    var iFileSize = file.size;
    var iConvert = (file.size / 1048578).toFixed(2);

    if (iFileSize > 29360128) {

      let txt = `File size: ${iConvert} MB. Please make sure your image file is less than 28 MB.`;
      this.modalService.setTarget(ALERT_MODAL_ID, txt);
      this.modalService.open(ALERT_MODAL_ID);
      this._lock = 0;
      return;
    }

    let prop = this.localVideoProp().data;
    prop.maskFileName = sFileName;
    (prop as IVideoPropUpload).file = file;

    // Persist the image mask on the server to make it accessible in Showroom
    const updatedProp = await firstValueFrom(this.venueService.upsertVideoPropMask(prop as IVideoPropUpload));
    // Update mask properties, keep any other changes until save
    this.localVideoProp.update(vp => {

      vp.maskFileName = updatedProp.maskFileName;
      vp.maskUrl = updatedProp.maskUrl;

      return new VideoProp(vp);
    });

    this._videoNode?.updateVideoProp(updatedProp);
    this._lock = 0;
  }


  private changeAspect(newAspect: number): void {

    if (this._updateInitializers) {

      this.aspectInitializer.set(newAspect);
    }
    this.localVideoProp.update(vp => {

      vp.aspect = newAspect;

      return new VideoProp(vp);
    });

    // Display change on Stage
    this._videoNode?.setAspect(newAspect);
  }


  clearViewPositionId() {

    this.localVideoProp.update(vp => {

      vp.viewPositionId = '';

      return new VideoProp(vp);
    })
  }


  /**
   * If PositionAdjustment change includes mask file upload then post and save changes immediately.
   * Otherwise, locally capture adjustment change and apply to stage (supports undo). 
   * @param positionAdjustment 
   */
  async handleAdjustmentChange(changedAdjustment: PositionAdjustment): Promise<void> {

    if (changedAdjustment.parentPropId !== this.localVideoProp().id) {

      return;
    }

    let positionAdjustment = changedAdjustment;
    let fileChange = false;

    // Mask files need to be uploaded to server in order to be usable on stage
    if ((changedAdjustment as IPositionAdjustmentUpload).file) {

      fileChange = true;
      let adjustmentId = changedAdjustment.id;
      // If new Position Adustment then it must be created first before uploading file
      if (1 > changedAdjustment.id) {

        const newAdjustment = await firstValueFrom(this.venueService.upsertVideoAdjustment(changedAdjustment));
        adjustmentId = newAdjustment.id;
      }

      // Update mask file of existing adjustment
      changedAdjustment.id = adjustmentId;
      positionAdjustment = await firstValueFrom(this.venueService.upsertVideoAdjustmentCustomMask(changedAdjustment as IPositionAdjustmentUpload));
    }

    this.localVideoProp.update(vp => {

      const index = vp.positionAdjustments.findIndex(sa => sa.positionId === positionAdjustment.positionId);
      if (0 > index) {

        vp.positionAdjustments.push(positionAdjustment);
      } else {

        const originalAdjustment = vp.positionAdjustments[index];
        if (fileChange) {

          // If Adjustment was created on the server then any changes were saved
          if (originalAdjustment.id !== positionAdjustment.id) {

            vp.positionAdjustments[index] = positionAdjustment;

          } else {  // Only mask file has been updated on the server

            originalAdjustment.maskFileName = positionAdjustment.maskFileName;
            originalAdjustment.maskUrl = positionAdjustment.maskUrl;
            positionAdjustment = originalAdjustment
          }
        } else {

          vp.positionAdjustments[index] = positionAdjustment;
        }
      }

      return new VideoProp(vp);
    });

    // Display change on Stage
    this._videoNode?.upsertPositionAdjustment(positionAdjustment)
  }


  /**
   * Mask file changes are removed on server immediately
   * @param positionAdjustment 
   */
  async handleAdjustmentMaskDeleted(positionAdjustment: PositionAdjustment): Promise<void> {

    if (0 < this._lock++) {

      return;
    }

    const updatedAdjustment = await firstValueFrom(this.venueService.deleteVideoAdjustmentCustomMask(positionAdjustment.id));
    this.localVideoProp.update(ip => {

      const index = ip.positionAdjustments.findIndex(sa => sa.positionId === positionAdjustment.positionId);
      if (-1 < index) {

        ip.positionAdjustments[index].maskFileName = updatedAdjustment.maskFileName;
        ip.positionAdjustments[index].maskUrl = updatedAdjustment.maskUrl;

        // Display change on Stage
        this._videoNode?.upsertPositionAdjustment(ip.positionAdjustments[index]);
      }

      return new VideoProp(ip);
    });

    this._lock = 0;
  }


  /**
   * Manual input
   * @param aspectInputEvent 
   */
  handleAspectInput(aspectInputEvent: any) {

    const newAspect = Number(aspectInputEvent.target.value);
    this._updateInitializers = false;
    this.changeAspect(newAspect);
    this._commonSlider?.setCurrentValue(newAspect);
    this._updateInitializers = true;
  }


  /**
   * Otherwise, locally capture adjustment change and apply to stage (supports undo). 
   * @param changeClippingPlane 
   */
  async handleClippingPlaneChange(clippingPlane: IClippingPlane): Promise<void> {

    this.localVideoProp.update(vp => {

      const clippingPlaneIndex = vp.clippingPlanes.findIndex(cp => cp.id === clippingPlane.id);
      if (-1 < clippingPlaneIndex) {

        const newClippingPlane = new ClippingPlane(clippingPlane);
        if (CrudState.NONE === newClippingPlane.crudState) {

          newClippingPlane.crudState = CrudState.UPDATED;
        }
        vp.clippingPlanes[clippingPlaneIndex] = newClippingPlane;
      }

      return new VideoProp(vp);
    });

    this._videoNode?.updateVideoProp(this.localVideoProp());
  }


  handleNameInput(nameEvent: any): void {

    const newName = nameEvent.target.value;

    this.localVideoProp.update(vp => {

      vp.name = newName;

      return new VideoProp(vp);
    });
  }


  handlePositionChange(position: Vector3Obj): void {

    // Update client
    this.localVideoProp.update(vp => {

      vp.position = [position.x, position.y, position.z];

      return new VideoProp(vp);
    });

    // Display change on Stage
    this._videoNode?.setPosition(position);
  }


  handleRotationChange(rotation: Vector3Obj): void {

    this.localVideoProp.update(vp => {

      vp.rotation = [rotation.x, rotation.y, rotation.z];

      return new VideoProp(vp);
    });

    // Display change on Stage
    this._videoNode?.setRotation(rotation);
  }


  handleScaleChange(scale: Vector3Obj): void {

    this.localVideoProp.update(vp => {

      vp.scale = [scale.x, scale.y, scale.z];

      return new VideoProp(vp);
    });

    // Display change on Stage
    this._videoNode?.setScale(scale);
  }


  private async loadPropReferences(): Promise<void> {

    if (1 > this.localVideoProp().id) {

      return;
    }
    const propReferences = await firstValueFrom(this.accountService.getVideoPropReferenceCount(this.localVideoProp().id));
    this.logger.trace('Video Prop references', propReferences);
    this.propReferenceCount.set(propReferences)
  }


  private makeCurrentPositionAdjustmentFirst(): void {

    if (0 < this.localVideoProp().positionAdjustments.length
      && this.localVideoProp().positionAdjustments[0].positionId !== this.currentPlayerPositionId()) {

      const positionIndex = this.localVideoProp().positionAdjustments.findIndex(pa => pa.positionId === this.currentPlayerPositionId());
      if (-1 < positionIndex) {

        this.localVideoProp.update(vp => {

          const adjustment = vp.positionAdjustments.splice(positionIndex, 1);
          vp.positionAdjustments = adjustment.concat(vp.positionAdjustments);

          return new VideoProp(vp);
        })
      }
    }
  }


  _monitorPlayerPositionSubscription?: Subscription;
  private monitorPlayerPosition() {

    this.currentPlayerPositionId.set(this._stage?.currentPlayerPosition?.id ?? '');
    this.makeCurrentPositionAdjustmentFirst();

    const that = this;
    this._monitorPlayerPositionSubscription?.unsubscribe();
    this._monitorPlayerPositionSubscription = this._stage?.playerPositionUpdate$.subscribe(function (currentSweep) {

      that.currentPlayerPositionId.set(currentSweep.id);
      that.makeCurrentPositionAdjustmentFirst();
    })
  }


  _monitorPositionChangedSubscription?: Subscription;
  private monitorPositionChanged() {

    this._monitorPositionChangedSubscription?.unsubscribe();
    this._monitorPositionChangedSubscription = this._videoNode?.positionChanged$.subscribe((newPosition) => {

      this.handlePositionChange(newPosition);
    });
  }


  _monitorRotationChangedSubscription?: Subscription;
  private monitorRotationChanged() {

    this._monitorRotationChangedSubscription?.unsubscribe();
    this._monitorRotationChangedSubscription = this._videoNode?.rotationChanged$.subscribe((newRotation) => {

      this.handleRotationChange(newRotation);
    });
  }


  _monitorScaleChangedSubscription?: Subscription;
  private monitorScaleChanged() {

    this._monitorScaleChangedSubscription?.unsubscribe();
    this._monitorScaleChangedSubscription = this._videoNode?.scaleChanged$.subscribe((newScale) => {

      this.handleScaleChange(newScale);
    });
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);

    this._commonSlider = this.sliderService.get(SHOWROOM_COMMON_SLIDER_ID);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);
    this._subscriptions.forEach(s => s.unsubscribe());
    this._videoNode?.detachTransformControls();
    this._monitorPlayerPositionSubscription?.unsubscribe();
    this._monitorPositionChangedSubscription?.unsubscribe();
    this._monitorRotationChangedSubscription?.unsubscribe();
    this._monitorScaleChangedSubscription?.unsubscribe();

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  /**
   * Client side VideoPositionAdjustment addition. Enables visualization.
   * A local change which must be saved if desired.
   * Pushed to the server upon Save clicked event
   */
  onAddAdjustment() {

    const positionId = this.currentPlayerPositionId();
    const newAdjustment = new PositionAdjustment();

    newAdjustment.parentPropId = this.localVideoProp().id;
    newAdjustment.positionId = positionId;
    newAdjustment.crudState = CrudState.CREATED;

    this.localVideoProp.update(vp => {

      const positionIndex = vp.positionAdjustments.findIndex(pa => pa.positionId === newAdjustment.positionId);
      if (-1 < positionIndex) {

        vp.positionAdjustments[positionIndex] = newAdjustment;
      } else {

        vp.positionAdjustments.push(newAdjustment);
      }

      return new VideoProp(vp);
    });

    this.makeCurrentPositionAdjustmentFirst();
    // Display change on Stage
    this._videoNode?.upsertPositionAdjustment(newAdjustment);
  }


  /**
   * Client side Clipping Plane addition. Enables visualization.
   * Considered a change which must be saved.
   * Pushed to the server upon Save clicked event.
   */
  async onAddClippingPlane(): Promise<void> {

    const newClippingPlane = new ClippingPlane();

    newClippingPlane.parentPropId = this.localVideoProp().id;

    const clippingPlane = await firstValueFrom(this.venueService.upsertVideoClippingPlane(newClippingPlane));
    this.localVideoProp.update(vp => {

      vp.clippingPlanes.push(clippingPlane);

      return new VideoProp(vp);
    });

    // A new Clipping Plane will not be available for use in Adjustments until it is saved with a real Id.
    this._videoNode?.updateVideoProp(this.localVideoProp());
  }


  private readonly handleBlurCallback = this.onBlur.bind(this);
  /**
  * Slider should close when focus changes away from sliderBasedInputs
  * but not when the focus changes to the slider itself. 
  */
  onBlur() {

    if (this._handlingBlur) {

      return;
    }
    this._handlingBlur = true;

    const commonSlider = (this._commonSlider?.elementRef.first as any).nativeElement;
    const sliderBasedInputs = (this.sliderBasedInputs.first as any).nativeElement;
    if (!commonSlider || !sliderBasedInputs) {

      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {
      if (!isChildElement(commonSlider, document.activeElement) && !isChildElement(sliderBasedInputs, document.activeElement)) {

        this._commonSlider?.isOpen.set(false);
      }
      this._handlingBlur = false;
    }, 1);
  }


  onClose() {

    this.closing.set(true);
    this.lockBaseValues.set(true);
    this._videoNode?.detachTransformControls();
    setTimeout(() => {
      this.isOpen.set(false);
      this.closing.set(false);
    }, 200);
  }


  onCopyVideoProp() {

    if (this.copyingVideoProp()) {

      return;
    }

    this.copyingVideoProp.set(true);
    this.Copy.emit(this.localVideoProp().id);

    // copyingImageProp is reset in onImagePropChanging() but we also reset it here in case of error
    setTimeout(() => this.copyingVideoProp.set(false), 1000);
  }


  /**
   * @param positionAdjustment
   */
  async onDeleteAdjustment(positionAdjustment: PositionAdjustment): Promise<void> {

    this.localVideoProp.update(vp => {

      const index = vp.positionAdjustments.findIndex(pa => pa.positionId === positionAdjustment.positionId);
      if (-1 < index) {

        // Id adjustment was created during this session then delete it
        if (1 > vp.positionAdjustments[index].id) {

          vp.positionAdjustments.splice(index, 1);
        } else {

          // Flag adjustment for deletion on the server
          const adjustment = new PositionAdjustment(vp.positionAdjustments[index]);
          adjustment.crudState = CrudState.DELETED;
          vp.positionAdjustments[index] = adjustment;
        }
      }

      return new VideoProp(vp);
    });

    // Update Stage
    this._videoNode?.removePositionAdjustment(positionAdjustment);
  }


  /**
   * @param clippingPlane
   */
  async onDeleteClippingPlane(clippingPlane: ClippingPlane): Promise<void> {

    if (0 < this._lock++) {

      return;
    }

    const adjustments = this.localVideoProp().adjustmentsByClippingPlane(clippingPlane);
    if (0 < adjustments.length &&
      !confirm(`Delete clipping plane used in ${adjustments.length} adjustment${1 < adjustments.length ? 's' : ''}?`)) {

      this._lock = 0;
      return;
    }

    // Delete Clipping Assignment references first to maintain referential integrity.
    for (let adjustment of adjustments) {

      const assignment = adjustment.clippingAssignments.find(cpa => cpa.clippingPlaneId === clippingPlane.id);
      if (assignment) {

        await firstValueFrom(this.venueService.deleteVideoClippingAssignment(assignment.id));
      }
    }

    const deletedClippingPlane = await firstValueFrom(this.venueService.deleteVideoClippingPlane(clippingPlane.id));
    this.localVideoProp.update(ip => {

      ip.removeClippingPlane(deletedClippingPlane);

      return new VideoProp(ip);
    });

    this._videoNode?.updateVideoProp(this.localVideoProp());
    this._lock = 0;
  }


  async onDeleteMask(): Promise<void> {

    if (0 < this._lock++) {

      return;
    }

    const updatedProp = await firstValueFrom(this.venueService.deleteVideoPropCustomMask(this.localVideoProp().id));
    this.localVideoProp.update(vp => {

      vp.maskFileName = vp.maskUrl = '';

      return new VideoProp(vp);
    });
    this._videoNode?.updateVideoProp(updatedProp);
    this._lock = 0;
  }


  onDeleteProp() {

    if (0 < this.propReferenceCount() &&
      !confirm(`Delete prop with ${this.propReferenceCount()} assignment${1 < this.propReferenceCount() ? 's' : ''}?`)) {

      return;
    }

    this.DeleteVideoProp.emit(this.localVideoProp());
    this.onClose();
  }


  onDetachTransformControls() {

    if (!this._videoNode || !this._videoNode.transforming) {

      this._videoNode?.detachTransformControls();
    }
  }


  onGoToPosition(positionId: string) {

    this._stage?.goToPosition(positionId, this.localVideoProp().positionObj);
  }


  onHideAdjustments() {

    this._stage?.hideAdjustedPositions(this.localVideoProp());
  }


  onPositionFocus() {

    this._videoNode?.detachTransformControls();
    setTimeout(() => this._videoNode?.attachTransformControls(TransformMode.TRANSLATE, TransformSpace.WORLD));
    this.monitorPositionChanged();
  }


  onRotationFocus() {

    this._videoNode?.detachTransformControls();
    setTimeout(() => this._videoNode?.attachTransformControls(TransformMode.ROTATE, TransformSpace.LOCAL));
    this.monitorRotationChanged();
  }


  async onSave() {

    this.enableCopy.set(false);
    this.lockBaseValues.set(true);
    this._videoNode?.detachTransformControls();

    // Run first to comply with referential entegrity
    await this.purgeDeletedClippingPlaneAssignments();
    // Run last
    await this.purgeDeletedPositionAdjustments();

    const videoProp = this.localVideoProp();
    // If Video Prop is not new.
    if (0 < videoProp.id) {

      await this.upsertNewOrUpdatedClippingPlanes();
      await this.upsertNewOrUpdatedClippingPlaneAssignments();

      for (const adjustment of videoProp.positionAdjustments) {

        // If Adjustment is new then send Assignments with Adjustment for creation on the server.
        if (1 > adjustment.id) {

          continue;
        }
      }
    }

    this.Save.emit(videoProp);
  }


  onScaleFocus() {

    this._videoNode?.detachTransformControls();
    setTimeout(() => this._videoNode?.attachTransformControls(TransformMode.SCALE, TransformSpace.LOCAL));
    this.monitorScaleChanged();
  }


  onSelectClippingPlane(clippingPlaneId: number) {

    this.selectedClippingPlaneId.set(clippingPlaneId);
  }


  onToggleEnableCopy(): void {

    this.enableCopy.set(!this.enableCopy());
  }


  /**
   * Triggered by close event initiated by slider.
   */
  private onSliderClose(): void {

    this._commonSlider?.isOpen.set(false);
  }


  onUnlockBaseValues() {

    this.lockBaseValues.set(false);
  }


  onUndo(): void {

    this.enableCopy.set(false);

    if (1 > this.localVideoProp().id) {

      return;
    }
    // Prop can be switching. Find original Prop from pool of Props.
    const restoredVideoProp = this.venueService.videoProps().find(vp => vp.id === this.localVideoProp().id);
    if (restoredVideoProp) {

      // Update stage
      this._videoNode?.updateVideoProp(restoredVideoProp);

      this.localVideoProp.set(restoredVideoProp);
      this.aspectInitializer.set(restoredVideoProp.aspect);
    }
    this.makeCurrentPositionAdjustmentFirst();
  }


  onVideoPropChanging() {

    this.copyingVideoProp.set(false);
    this._videoNode?.detachTransformControls();
    this._monitorPositionChangedSubscription?.unsubscribe();
    this._monitorRotationChangedSubscription?.unsubscribe();
    this._monitorScaleChangedSubscription?.unsubscribe();

    if (this.enableSave()) {

      this.onUndo();
    }
  }


  /**
   * Clipping assignments join clipping planes to adjustments.
   * Deleting adjustments will cascade to clipping assignments and clipping planes.
   * Deleting clipping planes will not cascade to clipping assignments.
   * To avoid referential integrity failures, delete clipping assignments before deleting clipping planes.
   */
  private async purgeDeletedClippingPlaneAssignments(): Promise<void> {

    for (const adjustment of this.localVideoProp().positionAdjustments) {

      if (0 < adjustment.clippingAssignments.length) {

        for (const clippingAssignment of adjustment.clippingAssignments) {

          if (CrudState.DELETED === clippingAssignment.crudState) {

            // Only call server delete if the clipping assignment is on the server as determined by it's id
            if (0 < clippingAssignment.id) {

              await firstValueFrom(this.venueService.deleteVideoClippingAssignment(clippingAssignment.id));
            }
          }
        }
      }

      // Remove deleted assignments
      this.localVideoProp.update(ip => {

        const adjustmentIndex = ip.positionAdjustments.findIndex(pa => pa.id === adjustment.id)
        if (-1 < adjustmentIndex) {

          ip.positionAdjustments[adjustmentIndex].clippingAssignments =
            ip.positionAdjustments[adjustmentIndex].clippingAssignments
              .filter(cpa => CrudState.DELETED !== cpa.crudState);
        }

        return ip;
      });
    }
  }


  private async purgeDeletedPositionAdjustments(): Promise<void> {

    for (const adjustment of this.localVideoProp().positionAdjustments) {

      if (CrudState.DELETED === adjustment.crudState) {

        if (0 < adjustment.id) {

          await firstValueFrom(this.venueService.deleteVideoAdjustment(adjustment));
        }
      }
    }

    // Remove deleted adjustments
    this.localVideoProp.update(vp => {

      vp.positionAdjustments = vp.positionAdjustments.filter(pa => CrudState.DELETED !== pa.crudState);

      return vp;
    });
  }


  selectTab(index: number): void {

    this.selectedTab.set(index);
    // this._router.navigate([],
    //   {
    //     relativeTo: this._route,
    //     queryParams: { p: `${index}` },
    //     queryParamsHandling: 'merge'
    //   })
  }


  /**
   * Bind this and Aspect to the Showroom common slider.
   */
  async setFocusAspect(focusEvent: any): Promise<void> {

    // Focus appears to get called when receiving focus and when losing focus.
    setTimeout(async () => {
      // Make sure the focus is on this element.
      if (focusEvent.srcElement !== document.activeElement) {

        return;
      }
      this._commonSlider?.reset();
      this._commonSlider?.onChange.set(this.changeAspect.bind(this));
      this._commonSlider?.onClose.set(() => this.onSliderClose.bind(this));
      this._commonSlider?.onBlur.set(() => this.onBlur.bind(this));
      this._commonSlider?.isOpen.set(true);
      this._commonSlider?.setCurrentValue(this.localVideoProp().aspect);
      this._commonSlider?.label.set(`Aspect`);
      this._commonSlider?.multiplier.set(.0005);
    });
  }


  private setInitializers() {

    if (this._updateInitializers) {

      this.aspectInitializer.set(this.localVideoProp().aspect);
    }
  }


  private setVideoNode() {

    this._videoNode = this._stage?.getVideoNode(this.localVideoProp().id);
  }


  setViewPositionId() {

    this.localVideoProp.update(vp => {

      vp.viewPositionId = this.currentPlayerPositionId();
      
      return new VideoProp(vp);
    })
  }


  private async upsertNewOrUpdatedClippingPlanes(): Promise<void> {

    for (const clippingPlane of this.localVideoProp().clippingPlanes) {

      if (CrudState.CREATED === clippingPlane.crudState || CrudState.UPDATED === clippingPlane.crudState) {

        await firstValueFrom(this.venueService.upsertVideoClippingPlane(clippingPlane));
      }
    }
  }


  private async upsertNewOrUpdatedClippingPlaneAssignments(): Promise<void> {

    for (const adjustment of this.localVideoProp().positionAdjustments) {

      // If Adjustment is new then send Assignments with Adjustment for creation on the server.
      if (1 > adjustment.id) {

        continue;
      }
      for (const clippingAssignment of adjustment.clippingAssignments) {

        if (CrudState.CREATED === clippingAssignment.crudState || CrudState.UPDATED === clippingAssignment.crudState) {

          await firstValueFrom(this.venueService.upsertVideoClippingPlaneAssignment(clippingAssignment));
        }
      }
    }
  }


}
