import { AfterViewInit, Component, Input, OnDestroy, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { debounce, equals, isChildElement, Vector3Obj, vector3ZeroObj } from 'projects/my-common/src/util/utils';
import { NumberInputScrollDirective } from 'src/app/shared/directives/number-input-scroll.directive';
import { FloatingSliderComponent } from 'src/app/components/shared/floating-slider/floating-slider.component';
import { CheckboxComponent } from 'src/app/components/shared/checkbox/checkbox.component';
import { ISlider, SliderService } from 'src/app/core/service/ui/slider/slider.service';
import { SHOWROOM_COMMON_SLIDER_ID } from '../common-slider/common-slider.component';
import { NoCommaPipe } from 'src/app/shared/pipes/no-comma.pipe';

enum CurrentFocus { x = 'x', y = 'y', z = 'z' };


@Component({
  selector: 'app-common-vector3-input',
  standalone: true,
  templateUrl: './common-vector3-input.component.html',
  styleUrls: ['./common-vector3-input.component.scss'],
  imports: [CommonModule, FloatingSliderComponent, CheckboxComponent, NoCommaPipe, NumberInputScrollDirective]
})
export class CommonVector3InputComponent implements AfterViewInit, OnDestroy {

  readonly OnChange = output<Vector3Obj>();
  readonly OnFocus = output<object>();
  readonly OnLostFocus = output<object>();

  value = signal(vector3ZeroObj as Vector3Obj);
  @Input() set Value(value: Vector3Obj) {

    if (!value || equals(value, this.value())) {

      return;
    }
    this.value.set(value);

    this.setXYZValues();
  }

  id = crypto.randomUUID();

  private _commonSlider?: ISlider;

  readonly enableEqual = signal(false);
  /**
   * Enable the Equal checkbox
   */
  @Input() set EnableEqual(value: boolean) {

    this.enableEqual.set(value);
    this.setXYZValues()
  }

  readonly keepValuesEqual = signal(false);
  /**
   * Keep XYZ values equal.
   */
  @Input() set Equal(value: boolean) {

    this.keepValuesEqual.set(value);
    this.setXYZValues();
  }

  readonly IsActive = input(true);
  readonly Label = input('')
  readonly currentFocus = signal(CurrentFocus.x);
  readonly openSlider = signal(false);

  private _updateInitializers = true;
  //
  // Input initializers
  //
  readonly xInitializer = signal(0);
  readonly yInitializer = signal(0);
  readonly zInitializer = signal(0);


  constructor(private readonly _logger: NGXLogger,
    private readonly _sliderService: SliderService) { }


  private _hasFocus = false;
  private _handlingBlur = false;
  private readonly handleBlurCallback = this.handleBlur.bind(this);
  /**
   * Slider should close when focus changes away from sliderBasedInputs
   * but not when the focus changes to the slider itself. 
   */
  handleBlur() {

    if (this._handlingBlur) {

      return;
    }
    this._handlingBlur = true;

    const commonSlider = (this._commonSlider?.elementRef.first as any).nativeElement;
    const elementRef = document.getElementById(this.id);
    if (!commonSlider || !elementRef) {

      this._logger.error(`commonSlider or elementRef not found`, commonSlider, elementRef);
      this._handlingBlur = this._hasFocus = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {
      if (!isChildElement(commonSlider, document.activeElement) && !isChildElement(elementRef, document.activeElement)) {

        if (this._hasFocus) {

          this._hasFocus = false;
          this.OnLostFocus.emit({ focus: false });
        }
        this._commonSlider?.isOpen.set(false);
      } else {

        if (!this._hasFocus) {

          this._hasFocus = true;
          this.OnFocus.emit({ focus: true });
        }
      }
      this._handlingBlur = false;
    }, 1);
  }


  private debounceInputX = debounce((newXValue: number) => this.onBlurX(), 2000);
  handleInputX(xEvent: any) {

    this._updateInitializers = false;
    const newValueX = Number(xEvent.target.value);
    this.debounceInputX[0](newValueX);
    if (this.keepValuesEqual()) {

      this.yInitializer.set(newValueX);
      this.zInitializer.set(newValueX);
      this.OnChange.emit({ x: newValueX, y: newValueX, z: newValueX });
    } else {

      this.OnChange.emit({ x: newValueX, y: this.value().y, z: this.value().z });
    }
    setTimeout(() => this._updateInitializers = true);;
  }


  private debounceInputY = debounce((newYValue: number) => this.onBlurY(), 2000);
  handleInputY(yEvent: any) {

    this._updateInitializers = false;
    const newValueY = Number(yEvent.target.value);
    this.debounceInputY[0](newValueY);
    if (this.keepValuesEqual()) {

      this.xInitializer.set(newValueY);
      this.zInitializer.set(newValueY);
      this.OnChange.emit({ x: newValueY, y: newValueY, z: newValueY });
    } else {

      this.OnChange.emit({ x: this.value().x, y: newValueY, z: this.value().z });
    }
  }


  private debounceInputZ = debounce((newZValue: number) => this.onBlurZ(), 2000);
  handleInputZ(zEvent: any) {

    this._updateInitializers = false;
    const newValueZ = Number(zEvent.target.value);
    this.debounceInputZ[0](newValueZ);
    if (this.keepValuesEqual()) {

      this.xInitializer.set(newValueZ);
      this.yInitializer.set(newValueZ);
      this.OnChange.emit({ x: newValueZ, y: newValueZ, z: newValueZ });
    } else {

      this.OnChange.emit({ x: this.value().x, y: this.value().y, z: newValueZ });
    }
  }


  /**
   * Triggered by close event initiated by slider.
   */
  handleSliderClose() {

    this._commonSlider?.isOpen.set(false);
  }


  handleSiderInput(newValue: number) {
    if (this.keepValuesEqual()) {
      this.OnChange.emit({ x: newValue, y: newValue, z: newValue });
      return;
    }

    switch (this.currentFocus()) {
      case CurrentFocus.x:
        this.OnChange.emit({ x: newValue, y: this.value().y, z: this.value().z });
        break;
      case CurrentFocus.y:
        this.OnChange.emit({ x: this.value().x, y: newValue, z: this.value().z });
        break;
      case CurrentFocus.z:
        this.OnChange.emit({ x: this.value().x, y: this.value().y, z: newValue });
        break;
    }
  }


  handleToggleEqual(): void {

    this.keepValuesEqual.set(!this.keepValuesEqual());
    this.setXYZValues();
  }


  ngAfterViewInit(): void {

    const elementRef = document.getElementById(this.id);
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);

    this._commonSlider = this._sliderService.get(SHOWROOM_COMMON_SLIDER_ID);
  }


  ngOnDestroy(): void {

    const elementRef = document.getElementById(this.id);
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  onBlurX() {

    this._updateInitializers = true;
    this.xInitializer.set(this.value().x);
    if (this.keepValuesEqual()) {

      this.yInitializer.set(this.value().x);
      this.zInitializer.set(this.value().x);
    }
  }


  onBlurY() {

    this._updateInitializers = true;
    this.yInitializer.set(this.value().y);
    if (this.keepValuesEqual()) {

      this.xInitializer.set(this.value().y);
      this.zInitializer.set(this.value().y);
    }
  }


  onBlurZ() {

    this._updateInitializers = true;
    this.zInitializer.set(this.value().z);
    if (this.keepValuesEqual()) {

      this.xInitializer.set(this.value().z);
      this.yInitializer.set(this.value().z);
    }
  }


  async onFocusX(focusEvent: any): Promise<void> {

    // Focus appears to get called when receiving focus and when losing focus.
    setTimeout(async () => {
      // Make sure the focus is on this element.
      if (focusEvent.srcElement !== document.activeElement) {

        return;
      }

      await this._commonSlider?.reset();
      this._commonSlider?.onChange.set(this.handleSiderInput.bind(this));
      this._commonSlider?.onClose.set(() => this.handleSliderClose.bind(this));
      this._commonSlider?.onBlur.set(() => this.handleBlur.bind(this));
      this._commonSlider?.isOpen.set(true);
      this._commonSlider?.setCurrentValue(this.value().x);
      this._commonSlider?.label.set(`${this.Label()} ${this.keepValuesEqual() ? 'xyz' : 'x'}`);
      this._commonSlider?.multiplier.set(.0005);

      this.currentFocus.set(CurrentFocus.x);
    });
  }


  async onFocusY(focusEvent: any): Promise<void> {

    // Focus appears to get called when receiving focus and when losing focus.
    setTimeout(async () => {
      // Make sure the focus is on this element.
      if (focusEvent.srcElement !== document.activeElement) {

        return;
      }

      await this._commonSlider?.reset();
      this._commonSlider?.onChange.set(this.handleSiderInput.bind(this));
      this._commonSlider?.onClose.set(() => this.handleSliderClose.bind(this));
      this._commonSlider?.onBlur.set(() => this.handleBlur.bind(this));
      this._commonSlider?.isOpen.set(true);
      this._commonSlider?.setCurrentValue(this.value().y);
      this._commonSlider?.label.set(`${this.Label()} ${this.keepValuesEqual() ? 'xyz' : 'y'}`);
      this._commonSlider?.multiplier.set(.0005);

      this.currentFocus.set(CurrentFocus.y);
    });
  }


  async onFocusZ(focusEvent: any): Promise<void> {

    // Focus appears to get called when receiving focus and when losing focus.
    setTimeout(async () => {
      // Make sure the focus is on this element.
      if (focusEvent.srcElement !== document.activeElement) {

        return;
      }

      await this._commonSlider?.reset();
      this._commonSlider?.onChange.set(this.handleSiderInput.bind(this));
      this._commonSlider?.onClose.set(() => this.handleSliderClose.bind(this));
      this._commonSlider?.onBlur.set(() => this.handleBlur.bind(this));
      this._commonSlider?.isOpen.set(true);
      this._commonSlider?.setCurrentValue(this.value().z);
      this._commonSlider?.label.set(`${this.Label()} ${this.keepValuesEqual() ? 'xyz' : 'z'}`);
      this._commonSlider?.multiplier.set(.0005);

      this.currentFocus.set(CurrentFocus.z);
    });
  }


  private setXYZValues() {

    if (this.keepValuesEqual()
      && (this.value().x !== this.value().y || this.value().y !== this.value().z)) {

      // Try to find the odd number and move to that. If all different then take max.
      let num = 0;
      if (this.value().x == this.value().y && this.value().x != this.value().z) {

        num = this.value().z;
      } else if (this.value().x == this.value().z && this.value().x != this.value().y) {

        num = this.value().y;
      } else if (this.value().y == this.value().z && this.value().x != this.value().y) {

        num = this.value().x;
      } else {

        num = Math.max(this.value().x, this.value().y, this.value().z);
      }

      this.value.set({ x: num, y: num, z: num });
      this.OnChange.emit(this.value());
    }

    this.updateInitializers();

    switch (this.currentFocus()) {

      case CurrentFocus.x:
        this._commonSlider?.setCurrentValue(this.value().x);
        break;
      case CurrentFocus.y:
        this._commonSlider?.setCurrentValue(this.value().y);
        break;
      case CurrentFocus.z:
        this._commonSlider?.setCurrentValue(this.value().z);
        break;
    }
  }


  private updateInitializers() {

    if (this._updateInitializers) {

      this.xInitializer.set(this.value().x);
      this.yInitializer.set(this.value().y);
      this.zInitializer.set(this.value().z);
    }
  }


}