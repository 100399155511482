import { DestroyRef, Injectable, inject, signal } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { DataService } from '../data/data.service';
import { IUser, User } from 'projects/my-common/src/model';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';
import { SecurityService } from '../authentication/security.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Subject } from 'rxjs/internal/Subject';
import { NGXLogger } from 'ngx-logger';
import { Subscriber, firstValueFrom } from 'rxjs';
import { URL_BASE } from 'src/environments/environment';
import { SHOWROOM_ENDPOINT } from 'src/environments/interfaces/IEnvironment';


/**
 * Depends/builds upon SecurityService
 * Supports Administrative inquiries 
 * Is authenticated user a MyOptyx User 
 * If not then create MyOptyx User 
 * Is MyOptx User an Admin 
 * https://www.youtube.com/watch?v=EJey9KP1dZA
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly _destroying$ = new Subject<void>();
  private _destroyRef = inject(DestroyRef);
  private _usersUrl = '';
  private _subscriptions: Subscription[] = [];

  private readonly userSignal = signal(new User());
  readonly user = this.userSignal.asReadonly();

  /**
   * Get Showroom User with Permissions defined by Account.
   */
  // private _gettingShowroomUser = false;                          // Concurrency flag
  // private _showroomUserQueue: Subscriber<ShowroomUser | undefined>[] = [];   // Concurrency queue
  // private _getCurrentUserError: any;    // validateMyOptyxUser() handles this error
  // readonly showroomUser$ = new Observable<ShowroomUser | undefined>((observer) => {

  //   if (this.user().isValid) {

  //     // We already have currentUser
  //     observer.next(this.user());
  //     return;
  //   }
  //   // Dependency check
  //   if (!this.securityService.isAuthenticated) {

  //     this.logger.trace('User not authenticated');
  //     observer.next(undefined);
  //     return;
  //   }
  //   this._showroomUserQueue.push(observer);
  //   if (this._gettingShowroomUser) {

  //     return;
  //   }
  //   this._gettingShowroomUser = true;

  //   let subscription: Subscription | undefined;
  //   const unsubscribe = () => subscription?.unsubscribe();
  //   subscription = this.getMe().subscribe({
  //     next: (user) => {

  //       this.showroomUserSignal.set(user ? new ShowroomUser(user) : new ShowroomUser());
  //       this._showroomUserQueue.forEach(o => o.next(this.user()));
  //       this._showroomUserQueue = [];
  //       this._gettingShowroomUser = false;

  //       unsubscribe();
  //     },
  //     error: (error) => {
  //       this.showroomUserSignal.set(new ShowroomUser());
  //       this._getCurrentUserError = error;
  //       this._showroomUserQueue.forEach(o => o.next(undefined));
  //       this._showroomUserQueue = [];
  //       this._gettingShowroomUser = false;

  //       unsubscribe();
  //     }
  //   })
  // });


  /**
   * Get current User. Plain User with Role but no Permissions defined by Account.
   * If current User undefined then will request from server and return final result.
   * If new User then will create in Showroom Db.
   */
  private _gettingUser = false;                          // Concurrency flag
  private _userQueue: Subscriber<User | undefined>[] = [];   // Concurrency queue
  private _getUserError: any;    // validateMyOptyxUser() handles this error
  readonly user$ = new Observable<User | undefined>((observer) => {

    if (this.user().isValid) {

      // We already have currentUser
      observer.next(this.user());
      return;
    }
    // Dependency check
    if (!this.securityService.isAuthenticated) {

      this.logger.trace('User not authenticated');
      observer.next(undefined);
      return;
    }
    this._userQueue.push(observer);
    if (this._gettingUser) {

      return;
    }
    this._gettingUser = true;

    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.getMe().subscribe({
      next: (user) => {

        this.userSignal.set(user ? new User(user) : new User());
        this._userQueue.forEach(o => o.next(this.user()));
        this._userQueue = [];
        this._gettingUser = false;

        unsubscribe();
      },
      error: (error) => {
        this.userSignal.set(new User());
        this._getUserError = error;
        this._userQueue.forEach(o => o.next(undefined));
        this._userQueue = [];
        this._gettingUser = false;

        unsubscribe();
      }
    })
  });

  private _isReady: boolean = false;
  get isReady(): boolean {
    return this._isReady;
  }
  // Observable that is fired when service is ready
  private readonly _serviceReadySource = new Subject<any>();
  private readonly serviceReady$ = this._serviceReadySource.asObservable();
  private _gettingReady = false;                        // Concurrency flag
  private _whenReadyQueue: Subscriber<unknown>[] = [];  // Concurrency queue
  readonly whenReady$ = new Observable((observer) => {

    if (this._isReady) {

      observer.next();
      return;
    }
    this._whenReadyQueue.push(observer);
    if (this._gettingReady) {

      return;
    }
    this._gettingReady = true;

    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.serviceReady$.subscribe(() => {
        // setUrls() is called in constructor we're waiting for it to finish
        this._whenReadyQueue.forEach(o => o.next());
        this._whenReadyQueue = [];
        this._gettingReady = false;

        unsubscribe();
      });
  });


  constructor(private readonly configurationService: ConfigurationService,
    private readonly dataService: DataService,
    private readonly securityService: SecurityService,
    private readonly logger: NGXLogger
  ) {

    this.setUrls();
    this._destroyRef.onDestroy(() => this._OnDestroy());

    this.monitorAuthenticationChanges();
  }


  createUser(): Observable<User> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.post<IUser>(this._usersUrl, null)
            .pipe(
              map((response: IUser) => new User(response))
            )
        })
      );
  }


  /**
   * Get MyOptyx User for the current, authenticated identity.
   * Without Account information or permissions.
   * @returns IUser interface
   */
  getMe(): Observable<User> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._usersUrl}/me`;

          return this.dataService.get<IUser>(url)
            .pipe(
              map((user) => {

                this.logger.trace('Me', user);
                return new User(user);
              })
            )
        })
      );
  }


  getShowroomUserByEmails(emails: string): Observable<User> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._usersUrl}/email/${emails}`;

          return this.dataService.get<IUser>(url)
            .pipe(
              map((response: IUser) => {

                this.logger.trace('Showroom user', response);
                return new User(response);
              })
            )
        })
      );
  }


  getShowroomUserById(userId: number): Observable<User> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._usersUrl}/${userId}`;

          return this.dataService.get<IUser>(url)
            .pipe(
              map((response: IUser) => {

                this.logger.trace('Showroom user', response);
                return new User(response);
              })
            )
        })
      );
  }


  private monitorAuthenticationChanges() {

    this.validateMyOpyxUser();
    this._subscriptions.push(
      this.securityService.isAuthenticatedUpdate$
        .subscribe((isAuthorized) => {

          this._getUserError = undefined;
          this.userSignal.set(new User());
          this.validateMyOpyxUser()
        })
    );
  }


  private _OnDestroy(): void {

    this._subscriptions.forEach(s => s.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  private async setUrls() {

    // This is the only method that depends on _configurationService.whenReady
    if (!this.configurationService.isReady) {

      await firstValueFrom(this.configurationService.whenReady$);
    }

    this._usersUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.User}`;

    this._isReady = true;

    this.validateMyOpyxUser();
    this._serviceReadySource.next(true);
  }


  updateUser(user: User): Observable<User> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.put<IUser>(this._usersUrl, user)
            .pipe(
              map((response: IUser) => {

                this.logger.trace('Updated user', response);
                return new User(response);
              })
            )
        })
      );
  }


  /**
   * When login occurs get the MyOptyx User.
   * If MyOptyx User not found for login then create MyOptyx User for login.
   */
  private async validateMyOpyxUser(): Promise<void> {

    let user = await firstValueFrom(this.user$);
    if (1 > this.user().id
      && this._getUserError
      && this._getUserError.status
      && 404 === this._getUserError.status) {

      // If existing MyOptyx User not found then create one
      user = await firstValueFrom(this.createUser());
      this.logger.trace('Created user', user);
      this.userSignal.set(new User(user));
    }
  }


}
