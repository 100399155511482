import { PropInputs } from "./matterport.model";
import { ComponentInteractionType } from "projects/mp-common/src";
import { ISubscription, MpSdk, Scene } from "static/sdk";
import { MPObjectPropComponent, OBJECT_PROP_COMPONENT_TYPE } from "projects/mp-common/src/sdk-components/MPObjectProp";
import { IShowroomObjectProp } from "../showroom/object-prop.model";
import { DesignObject, IMaterialBase, IObjectAssignmentMaterial, ObjectAssignment, ObjectProp, PropType } from "projects/my-common/src/model";
import { Subject, Subscription } from "rxjs";
import { DestroyRef } from '@angular/core';
import {
    AnimationType, GltfLoaderComponent, GltfManager, GltfProperties, MaterialData, MaterialDataLoaderComponent, MyOptyxComponent, TextureManager, TransformMode, TransformSpace, Vector3Obj,
    getLogger, vector3OneObj, vector3ZeroObj
} from "projects/my-common/src";
import { ObjectPropComponent } from "projects/my-common/src/component/object-prop.component";
import { EcsWorld } from "projects/my-common/src/ecs/ecs-world";
import { Euler, Vector3 } from "three";
import { ShowroomMode } from "src/app/state/showroom-state";
import { ObjectPropEntity } from "projects/my-common/src/ecs/entity/object-prop.entity";


export class ObjectNodeInputs extends PropInputs {
    public objectPropEnableInteractions = true;
    public objectPropGlbLocalPosition = vector3ZeroObj;

    public orientedBoxColor = 16776960;         // #FFFF00
    public orientedBoxColorSelected = 3394611;  // #33CC33
    public orientedBoxOpacity = 0.1;
    public orientedBoxTransitionTime = 0.4;
    public orientedBoxLineOpacity = 1;
    public orientedBoxLineColor = 16777215;

    public selectionBoxSize = vector3OneObj;
}


const DEFAULT_OBJECT_URL = '/assets/models/myoptyx-glasses.glb';
const DEFAULT_POSITION = { x: 0, y: 1, z: 0 } as const;


/**
 * The Matterport node, components, bindings and events required to implement a Showroom (Model based/3D) Prop.
 * It applies inputs in order with subsequent input applications overriding earlier input applications.
 * 1. Apply Default inputs
 * 2. Apply Object Prop
 * 2. Apply Design Object
 * 3. Apply Object Assignment
 * The Matterport node inserts the object at default position, rotation and scale.
 * The ObjectPropComponent wraps goups the object and related object. The group is the target of position, rotation and scale.
 * DesignObject provides offset, scale and target size vales to adjust the object within the group.
 * ObjectAssignment provide overrides for object position, rotation and materials.
 */
export class ObjectPropNode implements IShowroomObjectProp {

    propId: number = 0;
    readonly type = PropType.OBJECT;

    /** 
     * Provide overrides for object position, rotation and materials.
     */
    private objectAssignment = new ObjectAssignment();
    /**
     * Provides source object glb file and offset, scale and target size vales to adjust the object within the object group.
     */
    private designObject? = new DesignObject();

    //
    // Core prop and components
    //
    inputs!: ObjectNodeInputs;
    node: Scene.INode;
    gltfLoader!: GltfLoaderComponent;
    objectPropComponent!: ObjectPropComponent;
    mpObjectPropComponent!: MPObjectPropComponent;
    //selectionBox!: OrientedBox;
    materialDataLoader!: MaterialDataLoaderComponent;

    private _isSystemDefaultProp = true;
    private readonly _iSubscriptions: ISubscription[] = [];
    private _logger = getLogger();
    private _myOptyxComponents: MyOptyxComponent[] = [];
    private readonly _subscriptions: Subscription[] = [];
    private _world?: EcsWorld;    
    public get world() : EcsWorld | undefined {
        return this._world;
    }

    private readonly _positionUpdated = new Subject<Vector3>();
    readonly positionChanged$ = this._positionUpdated.asObservable();
    private readonly _rotationUpdated = new Subject<Euler>();
    readonly rotationChanged$ = this._rotationUpdated.asObservable();
    private readonly _scaleUpdated = new Subject<Vector3>();
    readonly scaleChanged$ = this._scaleUpdated.asObservable();

    // 
    // Events
    //
    private _objectPropSelectedSource = new Subject<number>();
    objectPropSelected$ = this._objectPropSelectedSource.asObservable();

    

    constructor(readonly destroyRef: DestroyRef,
        private objectProp: ObjectProp,
        private readonly scene: MpSdk.Scene.IObject,
        private readonly gltfManager: GltfManager,
        private readonly textureManager: TextureManager) {

        destroyRef.onDestroy(() => this.onDestroy());

        this.propId = objectProp.id;
        this.node = scene.addNode();

        this.addComponents();

        this.createBindings(scene);
        this.node.start();
        this.applyPropInputs(objectProp, this.objectAssignment, this.designObject);
        this.createInteractionEventSpies();
    }


    private addComponents(): void {

        this._myOptyxComponents.push(
            this.gltfLoader = new GltfLoaderComponent(this.destroyRef, this.gltfManager).init()
        );
        this._myOptyxComponents.push(
            this.objectPropComponent = new ObjectPropComponent(this.destroyRef, this.textureManager.three).init()
        )
        this.mpObjectPropComponent = this.node.addComponent(OBJECT_PROP_COMPONENT_TYPE, {}) as MPObjectPropComponent;
        this._subscriptions.push(this.mpObjectPropComponent.positionUpdated$.subscribe((position) => this._positionUpdated.next(position)));
        this._subscriptions.push(this.mpObjectPropComponent.rotationUpdated$.subscribe((rotation) => this._rotationUpdated.next(rotation)));
        this._subscriptions.push(this.mpObjectPropComponent.scaleUpdated$.subscribe((scale) => this._scaleUpdated.next(scale)));

        this._subscriptions.push(
            this.objectPropComponent.enableInteractionUpdated$.subscribe((isEnabled) => this.mpObjectPropComponent.inputs.enableInteraction = isEnabled)
        )

        this.materialDataLoader = new MaterialDataLoaderComponent(this.destroyRef, this.textureManager).init();
    }


    /**
     * Apply input values that are not otherwise updated elsewhere.
     */
    private applyDefaultInputs(): void {
        if (!this.inputs) this.inputs = new ObjectNodeInputs();
        const inputs = this.inputs;

        // this.selectionBox.inputs.size = vector3OneObj;
        // this.selectionBox.inputs.color = inputs.orientedBoxColor;
        // this.selectionBox.inputs.visible = false;
        // this.selectionBox.inputs.opacity = inputs.orientedBoxOpacity;
        // this.selectionBox.inputs.transitionTime = inputs.orientedBoxTransitionTime;
        // this.selectionBox.inputs.lineOpacity = inputs.orientedBoxLineOpacity;
        // this.selectionBox.inputs.lineColor = inputs.orientedBoxLineColor;
        // this.selectionBox.inputs.enableInteraction = false;
    }


    /**
     * Apply ObjectProp values to inputs, then apply DesignObject and ObjectAssignment values.
     * @param objectProp 
     * @returns 
     */
    private applyObjectInputs(objectProp: ObjectProp): void {

        if (!objectProp) {

            return;
        }
        this.objectProp = objectProp;

        //this.selectionBox.inputs.edgesRotation = objectProp.rotationObj;

        this.gltfLoader.pendingState.gltfsToLoad = [

            { source: DEFAULT_OBJECT_URL, animationType: AnimationType.ROTATE, position: DEFAULT_POSITION }
        ];
        this.gltfLoader.apply();

        this.objectPropComponent.pendingState.position.copy(objectProp.positionObj);
        this.objectPropComponent.pendingState.rotation = objectProp.rotationObj;
        this.objectPropComponent.pendingState.scale = objectProp.scaleObj;
        this.objectPropComponent.apply();

        this.updateDesignObject();
    }


    /**
     * A full application of all available input values in the proper order.
     * @param objectProp 
     * @param objectAssignment 
     * @param designObject 
     */
    private applyPropInputs(objectProp: ObjectProp,
        objectAssignment: ObjectAssignment,
        designObject?: DesignObject): void {
        
        this.objectProp = objectProp;
        this.designObject = designObject;
        this.objectAssignment = objectAssignment;

        // First apply default inputs
        if (!this.inputs) {

            this.applyDefaultInputs();
        }

        // Object prop updates will automatically apply Design Object and Object Assignment.
        this.applyObjectInputs(objectProp);
    }


    attachTransformControls(mode: TransformMode, space: TransformSpace) {

        this.mpObjectPropComponent.attachTransformControls(mode, space);
    }


    private createBindings(scene: MpSdk.Scene.IObject): void {

        this._subscriptions.push(
            this.gltfLoader.gltfUpdated$.subscribe(gltf => {

                this.objectPropComponent.pendingState.gltf = gltf;
                this.objectPropComponent.apply();
            })
        );
        this._subscriptions.push(
            this.gltfLoader.animationTypeUpdated$.subscribe(animationType => {

                this.objectPropComponent.pendingState.otherAnimations = animationType;
                this.objectPropComponent.apply();
                this.updateWorld(this._world);
            })
        );

        this._subscriptions.push(this.objectPropComponent.animationUpdated$.subscribe((animationMixer) => this.updateWorld(this._world)));
        // this._subscriptions.push(
        //     this.objectPropComponent.enableInteractionUpdated$.subscribe((isEnabled) => this.selectionBox.inputs.enableInteraction = isEnabled)
        // );
        this._subscriptions.push(
            this.objectPropComponent.gltfUpdated$.subscribe((gltf) => {

                this.mpObjectPropComponent.inputs.object = gltf;
                this.updateWorld(this._world);
            })
        );
        // this._subscriptions.push(
        //     this.objectPropComponent.viewSelectionBoxUpdated$.subscribe((visible) => this.selectionBox.inputs.visible = visible)
        // );

        // const o_showroomObjectPropSelectionBoxVisible = scene.addOutputPath(this.mpObjectPropComponent, 'selectionBoxVisible');
        // const i_selectionBoxVisible = scene.addInputPath(this.selectionBox, 'visible');
        // o_showroomObjectPropSelectionBoxVisible.bind(i_selectionBoxVisible);
        // const o_showroomObjectPropSelectionBoxEnableInteraction = scene.addOutputPath(this.mpObjectPropComponent, 'enableInteraction');
        // const i_selectionBoxInteractionsEnabled = scene.addInputPath(this.selectionBox, 'enableInteraction');
        // o_showroomObjectPropSelectionBoxEnableInteraction.bind(i_selectionBoxInteractionsEnabled);

        this._subscriptions.push(
            this.materialDataLoader.materialDataUpdated$.subscribe(materialData => {

                this.objectPropComponent.pendingState.materialData = [...materialData];
                this.objectPropComponent.apply();
            })
        )
    }


    private createInteractionEventSpies(): void {

        const that = this;

        //const selectionBoxClickEventPath = this.scene.addEventPath(this.selectionBox, ComponentInteractionType.CLICK);
        // class SelectionBoxClickSpy {
        //     readonly path = selectionBoxClickEventPath;

        //     constructor(private _objectProp: ObjectPropNode) { }

        //     onEvent(payload: any) {

        //         that._objectPropSelectedSource.next(this._objectProp.propId);
        //     }
        // }
        // this._iSubscriptions.push(
        //     this.scene.spyOnEvent(new SelectionBoxClickSpy(this))
        // );

        const objectPropClickEventPath = this.scene.addEventPath(this.mpObjectPropComponent, ComponentInteractionType.CLICK);
        class ObjectPropClickSpy {
            readonly path = objectPropClickEventPath;

            constructor(private _objectProp: ObjectPropNode) { }

            onEvent(payload: any) {

                that._objectPropSelectedSource.next(this._objectProp.propId);
            }
        }
        this._iSubscriptions.push(
            this.scene.spyOnEvent(new ObjectPropClickSpy(this))
        );
    }


    detachTransformControls(): void {

        this.mpObjectPropComponent.detachTransformControls();
    }


    getMaterialData(): MaterialData[] {

        return this.objectPropComponent.getMaterialData();
    }


    onCameraPositionChanged(position: Vector3Obj): void {

        // this.infoActicon.pendingState.playerPosition = new Vector3(position.x, position.y, position.z);
        // this.infoActicon.apply();
        this.objectPropComponent.pendingState.playerPosition.copy(position);
        this.objectPropComponent.apply();
    }


    onDestroy() {

        this._world?.removeObjectProp(this.propId);
        this.node.stop();
        this._iSubscriptions.forEach(_is => _is.cancel());
        this._subscriptions.forEach(s => s.unsubscribe());
        this._myOptyxComponents.forEach(mc => mc.onDestroy());
    }


    setInputs(objectProp: ObjectProp, objectAssignment: ObjectAssignment, designObject?: DesignObject): void {

        this.applyPropInputs(objectProp, objectAssignment, designObject);
    }


    setEnabled(enable: boolean): void {

        if (enable) {

            this.node.start();
        }
        else {

            this.node.stop();
        }
    }


    setEnableInteraction(enableInteraction: boolean): void {

        this.objectPropComponent.pendingState.enableInteraction = enableInteraction;
        this.objectPropComponent.apply();
    }


    setOffset(offset: Vector3Obj): void {

        if (this.designObject) {

            this.designObject.offsetObj = offset;
        }

        this.updateWorld(this._world);
    }


    /**
     * Setting position can be called by Stage Prop or Configure Assignment.
     * The rules for honoring Assignment inheritance or override are managed by those components.
     * We are not a gatekeeper for those rules. Prop, Design and Assignment state is not maintained here.
     * @param position 
     */
    setPosition(position: Vector3Obj): void {

        this.objectPropComponent.pendingState.position.copy(position);
        this.objectPropComponent.apply();
        this.updateWorld(this._world);
    }


    /** */
    setRotation(rotation: Vector3Obj): void {

        this.objectPropComponent.pendingState.rotation = rotation;
        this.objectPropComponent.apply();
        this.updateWorld(this._world);
    }


    setScale(scale: Vector3Obj): void {

        this.objectPropComponent.pendingState.scale = scale;
        this.objectPropComponent.apply();
        this.updateWorld(this._world);
    }


    setSelected(isSelected: boolean): void {

        // if (!isSelected) {

        //     this.selectionBox.inputs.color = this.inputs.orientedBoxColor;
        // } else {

        //     this.selectionBox.inputs.color = this.inputs.orientedBoxColorSelected;
        // }
    }


    setSource(gltfProperties: GltfProperties[]): void {

        this.gltfLoader.pendingState.gltfsToLoad = gltfProperties;
        this.gltfLoader.apply();
    }


    setShowroomMode(mode: ShowroomMode): void {

        this.objectPropComponent.pendingState.showroomMode = mode;
        this.objectPropComponent.apply();
    }


    /**
     * Object Props are not staged. They will only appear on the stage if they are assigned in Configuration mode.
     * @param objectAssignment 
     */
    updateAssignment(objectAssignment?: ObjectAssignment): void {

        const assignment = objectAssignment ?? this.objectAssignment;
        this.objectAssignment = assignment;

        if (assignment && 0 < assignment.id) {

            this.objectPropComponent.pendingState.position.copy(assignment.positionObj);
            this.objectPropComponent.pendingState.rotation = assignment.rotationObj;
            this.objectPropComponent.pendingState.scale = assignment.scaleObj;
            this.objectPropComponent.pendingState.enableInteraction = this._isSystemDefaultProp ? false : assignment.enableInteraction;
            this.objectPropComponent.apply();

            if (0 < assignment.materials.length) {

                // ObjectAssignment materials only additively override any materials already in place.                
                if (!this.materialDataLoader.pendingState.materialDefinitionsToLoad || 1 > this.materialDataLoader.pendingState.materialDefinitionsToLoad.length) {

                    this.materialDataLoader.pendingState.materialDefinitionsToLoad = assignment.materials;
                } else {

                    const currentMaterialDefinitions = ([] as IMaterialBase[]).concat(this.materialDataLoader.pendingState.materialDefinitionsToLoad);
                    let materialDefinition: IObjectAssignmentMaterial;
                    for (materialDefinition of assignment.materials) {

                        const materialDefinitionIndex = currentMaterialDefinitions.findIndex(cmd => cmd.name = materialDefinition.name);
                        if (-1 < materialDefinitionIndex) {

                            currentMaterialDefinitions[materialDefinitionIndex] = materialDefinition;
                        } else {

                            currentMaterialDefinitions.push(materialDefinition);
                        }
                    }

                    this.materialDataLoader.pendingState.materialDefinitionsToLoad = currentMaterialDefinitions;
                }

                this._logger.trace(`materialDefinitionsToLoad`, this.materialDataLoader.pendingState.materialDefinitionsToLoad)
                this.materialDataLoader.apply();
            }
        } else {

            // Without an assignment Prop is the default or empty. Interaction is activated by Admin mode.
            this.objectPropComponent.pendingState.enableInteraction = false;
            this.objectPropComponent.apply();
        }

        this.updateWorld(this._world);
    }


    updateDesignObject(designObject?: DesignObject): void {

        this.designObject = designObject ? designObject : this.designObject;

        if (this.designObject && 0 < this.designObject.id) {


            this.objectPropComponent.pendingState.scale = this.designObject.scaleObj;
            this.objectPropComponent.apply()

            this._isSystemDefaultProp = false;
            this.gltfLoader.pendingState.gltfsToLoad = [
                { source: DEFAULT_OBJECT_URL, animationType: AnimationType.ROTATE, position: DEFAULT_POSITION },
                { source: this.designObject.objectUrl, animationType: AnimationType.NONE, position: this.designObject.offsetObj }
            ];

            this.materialDataLoader.pendingState.materialDefinitionsToLoad = this.designObject.materials;
            //this.selectionBox.inputs.size = design.targetSizeObj;

        } else {

            this._isSystemDefaultProp = true;
            this.gltfLoader.pendingState.gltfsToLoad = [
                { source: DEFAULT_OBJECT_URL, animationType: AnimationType.ROTATE, position: DEFAULT_POSITION }
            ];

            this.materialDataLoader.pendingState.materialDefinitionsToLoad = [];
            //this.selectionBox.inputs.size = this.inputs.selectionBoxSize;
        }

        this.gltfLoader.apply();
        this.materialDataLoader.apply();
        this.updateAssignment();
    }


    // setTargetSize(targetSize: Vector3Obj): void {

    //     this.selectionBox.inputs.size = targetSize;
    // }


    updateWorld(world?: EcsWorld): void {

        if (!world) {

            return;
        }
        if (this._world !== world) {

            this._world = world;
        }

        const objectPropEntity: ObjectPropEntity = {

            id: this.propId,
            animationType: this.objectPropComponent.pendingState.otherAnimations,
            animationRotationY: 0.005,
            animationMixer: this.objectPropComponent.getAnimationMixer(),
            childObject: this.objectPropComponent.getObject(),
            objectGroup: this.objectPropComponent.getObjectGroup(),
            offset: this.designObject ? this.designObject.offsetObj : vector3ZeroObj,
            position: this.objectPropComponent.pendingState.position,
            rotation: this.objectPropComponent.pendingState.rotation,
            scale: this.objectPropComponent.pendingState.scale
        }

        world.upsertObjectProp(objectPropEntity);
    }


}
