import { NgxLoggerLevel } from "ngx-logger";
import { Configuration, IEnvironment, SILENT_REQUEST } from "./interfaces/IEnvironment";

export const URL_BASE = {
  CART: 'https://myoptyx-dev.azure-api.net/b/api/v1',
  PURCHASE: 'https://myoptyx-dev.azure-api.net/o/api/v1',
  SHOWROOM: 'https://myoptyx-dev.azure-api.net/c/api/v1',
  SIGNALR: 'https://myoptyx-signalr--20240617-162222.orangeplant-b5b3a203.eastus.azurecontainerapps.io'
}

export const environment: IEnvironment = {
  configuration: Configuration.STAGE,
  stripePublishableKey: 'pk_test_51NYuSRA1eK3epo4cHtiKhkZJpfXpAPWqrIwa2EfqBw2m7GUz1hc6UvOFagLiwxvSKugdtQiUS62gUHDvzBOCTVEF00o5LaW9EH',
  b2cPolicies: {
    names: {
      signUpSignIn: 'susi_v1'
    },
    authorities: {
      signUpSignIn: {
        // Testing Entra instead of ADB2C
        // Showstopper issue https://github.com/Azure-Samples/ms-identity-ciam-javascript-tutorial/issues/64
        // Warning: The assigned object id connected to email is generated by Entra upon login creation. 
        // Never delete it else new id will be generated, disconnecting that email from data.
        // Else use email address as the Id instead of object id. Then you can completely switch Ciam providers!
        authority: 'https://myoptyxid.ciamlogin.com/2ff00a73-f171-42a2-8705-ecd2b1ec19d6'
      }
    },
    //authorityDomain: 'meauth.b2clogin.com',
  },
  msalGuardConfig: SILENT_REQUEST,
  ngxLogLevel: NgxLoggerLevel.TRACE,
  tslogLevel: 1,  
  logger: undefined,
  // Make sure api calls are only performed with the proper Auth or Anonymous else Guests will be challenged to login
  applyMatterportUpdates: false,
  THREE_SCRIPT_PATH: 'https://cdn.jsdelivr.net/npm/three@^0.160.0/build/three.module.min.js',
  DEFAULT_MODEL_VIEWER_SRC: '/assets/models/MyOptyxGlasses.glb',
};