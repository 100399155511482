@if (IsDefault()) {

<div class="flex flex-col space-y-2">
    <div
        class="group/outer relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
        <div class="w-full max-h-80 min-h-20">

            @if (0 < localAssignment().designImageId) { 
                
                <img [alt]="designImage().uploadFileName"
                    [src]="designImage().imageUrl" class="w-full max-h-80 object-scale-down cursor-pointer" />

            } @else {

                <div>Text assignment</div>

            }

        </div>
        <!-- <div class="absolute inset-0 pt-4 flex translate-y-full overflow-hidden flex-col px-4 transition-all duration-500 group-hover/outer:translate-y-[0%]" -->
        <div
            class="absolute inset-0 pt-4 bg-slate-800 bg-opacity-30 flex overflow-hidden flex-col px-4 transition-all duration-500">
            <div class="flex flex-row">
                <div class="flex items-center text-accent text-xl font-semibold overflow-hidden">
                    From default
                </div>
            </div>
        </div>
    </div>
</div>

} @else {

<div [id]="id" class="flex flex-row w-full justify-between mb-2">
    <div
        class="flex items-center text-accent text-xl font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap">
        {{ 0 < localAssignment().designImageId ? designImage().uploadFileName : localAssignment().name && 0 < localAssignment().name.length ? localAssignment().name : 'Text assignment' }} </div>
            
            <div class="tooltip tooltip-left" data-tip="Delete assignment">
                <button (click)="onDeleteImageAssignment($event)"
                    class="text-sm uppercase font-bold bg-danger hover:bg-dangerhov rounded-lg h-[41px] w-[48px] cursor-pointer">
                    <span class="flex justify-center ">
                        <ng-icon size="22" name="bootstrapTrash" />
                    </span>
                </button>
            </div>
    </div>

    <div
        class="{{ localAssignment().id === currentImageAssignment().id ? 'border-[1px] border-white p-2 rounded-md' : ''}} flex flex-col space-y-2">

        @if (localAssignment().id === currentImageAssignment().id) {

            <div>
                <div class="py-2">
                    <span class="w-[85px] font-bold">Name</span>
                    <p class="italic text-gray-400">Displayed in sidebar and on purchase orders</p>
                </div>
                <textarea rows="3" maxlength="256" [value]="localAssignment().name" (blur)="handleNameInput($event)"
                    (keyup)="handleNameInput($event)"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary">
                </textarea>
            </div>

        <div class="w-full flex flex-row items-center h-[41px]">
            <app-checkbox Label="Enable interaction" [Checked]="localAssignment().enableInteraction"
                (Toggled)="onToggleEnableInteraction()" class="w-1/2" />
            <app-checkbox Label="Hide image in sidebar" [Checked]="localAssignment().suppressSidebarImage"
                (Toggled)="onToggleSuppressSidebarImage()" class="w-1/2" />
        </div>
        <div class="w-full flex flex-row items-center justify-between h-[41px]">
            <app-checkbox Label="Stretch to fit" [Checked]="localAssignment().stretchToFit"
                (Toggled)="onToggleStretchToFit()" class="w-1/2" />

            @if (1 > localAssignment().designImageId) {

            <!-- Upload/Import DesignImage buttons -->
            <div class="flex flex-row justify-center space-x-2">
                <input type="file" id="file-input-add-image" class="hidden"
                    accept="image/png, image/jpeg, image/gif, image/jpg, image/webp" onclick="this.value = null;"
                    (input)="addDesignImage($event)" />
                <div class="cursor-pointer group relative">
                    <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                        <label class="flex justify-center" for="file-input-add-image">
                            <ng-icon class="cursor-pointer" size="25" name="bootstrapPlusLg" />
                        </label>
                    </button>
                    <span
                        class="invisible group-hover:visible md:flex group-hover:opacity-100 transition-opacity bg-gray-800 px-2 border-[1px] border-white text-sm text-gray-100 rounded-md absolute opacity-0 whitespace-nowrap -bottom-[22px] right-0">
                        Upload design image
                    </span>
                </div>
                <div class="cursor-pointer group relative">
                    <button (click)="onImportDesignImage()"
                        class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                        <label class="flex justify-center">
                            <ng-icon class="cursor-pointer" size="25" name="bootstrapArrowLeft" />
                        </label>
                    </button>
                    <span
                        class="invisible group-hover:visible md:flex group-hover:opacity-100 transition-opacity bg-gray-800 px-2 border-[1px] border-white text-sm text-gray-100 rounded-md absolute opacity-0 whitespace-nowrap -bottom-[22px] right-0">
                        Import from Design
                    </span>
                </div>
            </div>

            }
        </div>
            
        @if (0 < uploadingFileName().length) { 
                
            <div class="w-full py-2 flex flex-col justify-center text-center items center">
                <div class="pb-1">Uploading {{uploadingFileName()}}</div>
                <app-progress-bar [Progress]="uploadingProgress()" [EaseToProgress]="1" />
            </div>

        }

        }

        @if (0 < localAssignment().designImageId) { <div
            class="group/outer relative cursor-pointer items-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">

            <!-- Placeholder for image + inset overlay -->
            <div class="flex flex-col w-full max-h-80 min-h-36 items-center">

                @if(0 < localAssignment().designImageId) { 
                    
                    @if (localAssignment().suppressSidebarImage) { 
                        
                        <div class="flex w-full justify-center">
                            <div>
                                {{designImage().uploadFileName}}
                            </div>
                        </div>

                    } @else {

                        <img [alt]="designImage().uploadFileName" [src]="designImage().imageUrl"
                            class="w-full max-h-80 object-scale-down cursor-pointer" />

                    }
            
                }

            </div>


    <!-- <div class="absolute inset-0 pt-4 flex translate-y-full overflow-hidden flex-col px-4 transition-all duration-500 group-hover/outer:translate-y-[0%]" -->
    <div class="absolute inset-0 pt-4 flex overflow-hidden flex-col px-4 transition-all duration-500">
        <div class="flex flex-row justify-between">
            <div class="flex flex-row space-x-2">

                @if (localAssignment().id !== currentImageAssignment().id) {

                <div class="tooltip tooltip-bottom" data-tip="Edit">
                    <button (click)="onSelectAssignment()"
                        class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                        <span class="flex justify-center ">
                            <ng-icon size="22" name="bootstrapPen" />
                        </span>
                    </button>
                </div>

                }

                @if (EnableSwapAssignments()) {

                <div class="tooltip tooltip-right" data-tip="Move up">
                    <button (click)="onSwapImageAssignments($event)"
                        class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px] cursor-pointer">
                        <span class="flex justify-center ">
                            <ng-icon size="22" name="bootstrapArrowUp" />
                        </span>
                    </button>
                </div>

                }

            </div>

            @if (0 < localAssignment().designImageId) {     
                
            <div class="tooltip tooltip-left" data-tip="Remove image">
                <button (click)="onRemoveDesignImage($event)"
                    class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px] cursor-pointer">
                    <span class="flex justify-center ">
                        <ng-icon size="22" name="bootstrapXLg" />
                    </span>
                </button>
            </div>

            }

    </div>
</div>
</div>

} @else {

<div
    class="flex flex-col w-full {{localAssignment().id === currentImageAssignment().id ? '' : 'bg-slate-800 rounded-md p-4'}}">

    <div class="flex flex-row justify-between">
        <div class="flex flex-row space-x-2 mb-2">

            @if (localAssignment().id !== currentImageAssignment().id) {

            <div class="tooltip tooltip-bottom" data-tip="Edit">
                <button (click)="onSelectAssignment()"
                    class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                    <span class="flex justify-center ">
                        <ng-icon size="22" name="bootstrapPen" />
                    </span>
                </button>
            </div>

            }

            @if (EnableSwapAssignments()) {

            <div class="tooltip tooltip-right" data-tip="Move up">
                <button (click)="onSwapImageAssignments($event)"
                    class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px] cursor-pointer">
                    <span class="flex justify-center ">
                        <ng-icon size="22" name="bootstrapArrowUp" />
                    </span>
                </button>
            </div>

            }

        </div>
    </div>

    <div class="flex flex-col w-full">
        <div class="py-2">
            <span class="w-[85px] font-bold">Text</span>
            <p class="italic text-gray-400">Displayed on prop</p>
        </div>
        @if (localAssignment().id === currentImageAssignment().id) {

        <div class="flex flex-col w-full overflow-hidden">
            <div class="flex flex-row w-full space-x-2">
                <div class="flex flex-row w-1/2 space-x-2 items-center overflow-hidden">
                    <select (change)="onSelectFont($event)"
                        class="block w-full mt-1 text-black rounded-md focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500">

                        @for (font of fonts; track $index) {

                        <option [selected]="font == localAssignment().font"> {{ font }} </option>

                        }

                    </select>
                </div>
                <div class="flex flex-row w-1/2 max-w-1/2 space-x-2 items-center overflow-hidden">
                    <input type="number" value="{{ fontSizeInitializer() | number : '1.0' | noComma }}" step="1"
                        (blur)="onBlurFontSize()" (input)="handleFontSizeInput($event)"
                        class="mt-1 w-[150px] text-right block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary" />
                    <span>px</span>
                </div>
            </div>
            <textarea rows="3" maxlength="256" [value]="localAssignment().text" (blur)="handleTextInput($event)"
                (keyup)="handleTextInput($event)"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary">
                </textarea>
        </div>

        } @else {

        <div class="w-full p-4 bg-black rountded-md">{{localAssignment().text}} </div>

        }
    </div>

</div>
}

@if (localAssignment().id === currentImageAssignment().id) {

<div class="w-full flex flex-row justify-between items-center h-[41px]">
    <div>

        @if (localAssignment().enableInteraction) {

        <app-checkbox Label="Enable cart" [Checked]="localAssignment().enableCart" (Toggled)="onToggleEnableCart()" />

        }

    </div>

    @if (enableSave()) {

    <div class="flex flex-row justify-end">
        <div class="flex flex-row mt-2 md:mt-0 space-x-2">
            <button (click)="onUndoChanges()"
                class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                <span class="flex justify-center ">
                    <ng-icon class="cursor-pointer" size="22" name="bootstrapArrowCounterclockwise" />
                </span>
            </button>
            <button (click)="onSave()" class="text-sm uppercase font-bold bg-green-700 rounded-lg h-[41px] w-[48px]">
                <span class="flex justify-center ">
                    <ng-icon class="cursor-pointer" size="22" name="bootstrapCheckLg" />
                </span>
            </button>
        </div>
    </div>

    }

</div>
    

@if (localAssignment().enableInteraction && localAssignment().enableCart) {

    <app-price-options [PriceOptions]="localAssignment().priceOptions" (Add)="onAddPriceOption()"
        (Updated)="handlePriceOptionUpdated($event)" (Delete)="onDeletePriceOption($event)" />

}

<div class="py-2">
    <span class="w-[85px] font-bold">Description</span>
    <p class="italic text-gray-400">Displayed in sidebar</p>
</div>
<app-text-editor [Content]="localAssignment().description" (OnUpdate)="handleImageAssignmentDescriptionInput($event)" />

}

</div>

}