import { Injectable, WritableSignal } from '@angular/core';

/**
 * Modal components should implement this interface and register with ModalService
 * in OnInit and OnDestroy
 */
export interface IModal {
    id: string;
    readonly isOpen: WritableSignal<boolean>;
    close(): void;
    setTarget<T>(target: T): void;
}

  
/**
 * Privide hook between Templates and Modal components that implement IModal
 */
@Injectable({ providedIn: 'root' })
export class ModalService {

    private modals: IModal[] = [];    
    
    /**
     * Return true if a modal is open
     */
    public get isOpen(): boolean {
        
        return this.modals.some(m => m.isOpen());
    }
    

    add(modal: IModal) {

        if (!modal.id || this.modals.find(x => x.id === modal.id)) {

            return;
        }

        // add modal to array of active modals
        this.modals.push(modal);
    }


    remove(modal: IModal) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x === modal);
    }


    open<T>(id: string, data?: T) {
        // open modal specified by id
        const modal = this.modals.find(x => x.id === id);

        if (!modal) {
            throw new Error(`Modal '${id}' not found`);
        }

        if (data) {
            let temp: T = data;
            modal.setTarget<T>(temp);
        }
        
        modal.isOpen.set(true);
    }


    setTarget<T>(id: string, target: T) {

        this.modals.find(modal => modal.id === id)?.setTarget(target);
    }


    close() {
        // close the modal that is currently open
        const modal = this.modals.find(x => x.isOpen);
        modal?.close();
    }
}
