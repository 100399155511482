import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  
  private toastSource = new Subject<string>();
  toastReceived$ = this.toastSource.asObservable();

  constructor(private readonly _logger: NGXLogger) { }

  toastThis(message: string) {

    this._logger.trace('message');
    this.toastSource.next(message);
  }
}
