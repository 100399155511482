<div class="flex flex-col space-y-2 {{ IsActive() ? '':  'bg-slate-700 rounded-md p-2'}}" (click)="onSelect()">
    <div class="flex flex-row w-full items-center justify-between">
        
        @if (0 < Index()) {
            
            <div>{{ clippingPlaneTypes[clippingPlane().planeType] }}&nbsp;{{ Index() }}</div>        
        
        } @else {

            <div>New&nbsp;{{ clippingPlaneTypes[clippingPlane().planeType] }}</div>        

        }

        @if (IsActive()) {
        
            <div class="tooltip tooltip-left" data-tip="Delete">  
                <div (click)="handleDeleteClippingPlane(clippingPlane())" 
                    class="h-10 cursor-pointer group flex relative">
                    <ng-icon class="text-xl" name="bootstrapXLg" />
                </div>
            </div>

        } 
    </div>

    @if (IsActive()) {
        
        <div class="flex flex-row space-x-2 items-center ">
            <div class="w-1/2">Plane shape</div>
            <select (change)="onClippingPlaneTypeChange($event)"
                class="w-1/2 block text-black rounded-md focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                
                @for (clippingPlaneType of clippingPlaneTypes; track $index) {
            
                    <option [selected]="clippingPlaneType === clippingPlaneTypes[clippingPlane().planeType]">
                        {{ clippingPlaneType }}
                    </option>
            
                }

            </select>
        </div>

        <app-checkbox Label="Activate at prop level" [Checked]="clippingPlane().activatePropLevel" (Toggled)="onToggleActivateAtPropLevel()" />

        @if (clippingPlane().activatePropLevel) {
    
            <div class="flex flex-row justify-between items-center">
                <span>Position</span>
                <app-common-vector3-input [Value]="clippingPlane().positionObj" Label="Position"
                    (OnChange)="handlePositionChange($event, clippingPlane())" [IsActive]="IsActive()" />
            </div>
            
            <div #sliderBasedInputs class="flex flex-row space-x-2 items-center">
                <div class="w-1/2">Rotation</div>
                <input
                    class="w-1/2 text-primary text-center placeholder:text-primary h-10 outline-accent"
                    type="number" [value]="rotationInitializer() | number : '1.1-4' | noComma" step="any"
                    (focus)="setFocusRotation($event)" (blur)="onBlur()"
                    (input)="handleRotationInput($event)">
            </div>
    
            <div class="flex flex-row justify-between items-center">
                <span>Scale</span>
                <app-common-vector3-input [Value]="clippingPlane().scaleObj" [EnableEqual]="true"
                    (OnChange)="handleScaleChange($event, clippingPlane())" Label="Scale" [IsActive]="IsActive()" />
            </div>
            
        }

    }
</div>