import { Component, computed, effect, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEventDesign } from 'projects/my-common/src/model';
import { DesignImagesComponent } from "../design-images/design-images.component";
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapPlusLg } from '@ng-icons/bootstrap-icons';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { VenueService } from 'src/app/core/service/venue/venue.service';
import { GuestService } from 'src/app/core/service/venue/guest.service';


@Component({
  selector: 'app-event-design-select',
  standalone: true,
  templateUrl: './event-design-select.component.html',
  styleUrls: ['./event-design-select.component.scss'],
  imports: [CommonModule, DesignImagesComponent, NgIconComponent],
  providers: [provideIcons({ bootstrapPlusLg })]
})
export class EventDesignSelectComponent {

  readonly eventDesignId = signal(0);

  readonly EventDesigns = input.required<IEventDesign[]>();
  readonly SelectedDesign = input.required<IEventDesign>();

  readonly DisplayHelp = input(false);

  readonly OnSelect = output<number>();

  readonly selectedEventDesignId = computed(() => this.eventDesignId() > 0 ? this.eventDesignId() : this.SelectedDesign().id);

  constructor(private readonly _route: ActivatedRoute,
    private readonly _guestService: GuestService,
    private readonly _venueService: VenueService,
    private readonly _router: Router,
    private readonly _logger: NGXLogger) {

    effect(() => {

      const eventDesign = this.EventDesigns().find(ed => ed.id === this.eventDesignId());
      if (eventDesign && this.SelectedDesign().id !== this.eventDesignId()) {

        this.OnSelect.emit(this.eventDesignId());
      }
    });
  }


  selectDesign(eventDesign: IEventDesign): void {

    if (eventDesign.id === this.SelectedDesign().id) {

      return;
    }

    this._router.navigate([],
      {
        relativeTo: this._route,
        queryParams: { did: null },
        queryParamsHandling: 'merge'
      })
    this.eventDesignId.set(0);
    this.OnSelect.emit(eventDesign.id);
  }

}
