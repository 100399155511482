import { Types, defineComponent } from 'bitecs';


/**
 * Video and Image Props change their scale to contain a source video or image when the source aspect
 * is smaller than the Prop's aspect.
 * WHY DO LARGER ASPECT AUTOMATICALL FIT WITHIN THE PROP BOUNDS?
 */
export const DisplayPlaneComponent = defineComponent({
    eid: Types.eid,
    aspect: Types.f32,
    /**
     * The display plane scale factor is always 1 except if the display plane aspect is less than the Prop base aspect.
     * In such cases the display plane is taller than the Prop and will exceed the bounds of the Prop.
     * To keep the display plane within the bounds of the Prop the scale is set to a value less than 1 (displayAspect / baseAspect).
     */
    scaleFactor: Types.f32,
});