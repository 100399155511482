import {
  MeshBasicMaterial, LineSegments, BoxGeometry, Mesh, LineBasicMaterial,
  AnimationMixer, AnimationClip, AnimationAction
} from 'three';
import { SceneComponent, ComponentInteractionType, ISceneNode } from '../SceneComponent';
import { getLogger } from 'projects/my-common/src/util/log';
import * as THREE from 'three';
import { Vector3Obj, disposeScene, vector3OneObj, vector3ZeroObj } from 'projects/my-common/src';

export interface IInteractionEvent {
  type: ComponentInteractionType;
  node: ISceneNode;
  component: SceneComponent;
}

type OrientedBoxInputs = {
  size: Vector3Obj,
  edgesRotation: Vector3Obj,
  color: number,
  visible: boolean,
  opacity: number,
  transitionTime: number,
  lineOpacity: number,
  lineColor: number,
  enableInteraction: boolean
}

const makeMaterialOpacityClip = function (THREE: any, time: number, startOpacity: number, endOpacity: number): AnimationClip {
  const track = new THREE.NumberKeyframeTrack('.material.opacity', [0, time], [startOpacity, endOpacity]);
  return new THREE.AnimationClip(null, time, [track]);
};

const playAnimation = function (THREE: any, mixer: AnimationMixer, clip: AnimationClip, root?: any) {
  const action: AnimationAction = mixer.clipAction(clip, root);
  action.loop = THREE.LoopOnce;
  action.clampWhenFinished = true;
  action.play();
}

export class OrientedBox extends SceneComponent {
  private readonly _logger = getLogger();
  
  private boxGroup: THREE.Group | null = null;
  private mesh: Mesh | null = null;
  private edges: LineSegments | null = null;
  private boxMixer: AnimationMixer | null = null;
  private clipVisible: AnimationClip | null = null;
  private clipNotVisible: AnimationClip | null = null;
  private edgesClipVisible: AnimationClip | null = null;
  private edgesClipNotVisible: AnimationClip | null = null;
  private _boxMaterial!: MeshBasicMaterial;

  override inputs: OrientedBoxInputs = {
    size: vector3OneObj,
    edgesRotation: vector3ZeroObj,
    color: 0xffff00,
    visible: true,
    opacity: 0.1,
    lineOpacity: 1,
    lineColor: 0xffffff,
    transitionTime: 0.4,
    enableInteraction: true
  };

  override events = {
    [ComponentInteractionType.CLICK]: true,
    [ComponentInteractionType.HOVER]: true,
  };

  /**
   * This box server as the interaction component. 
   * To hide it set opacity to 0. Don't set visibility to false else it won't handle interactions.
   */
  createBox() {

    const THREE = this.context.three;
    if (this.mesh) {
      this.boxGroup?.remove(this.mesh);
      disposeScene(this.mesh);
      this.mesh = null;
    }

    // Create an translucent box
    const size = this.inputs.size;
    //this._logger.trace(`size`, size);
    const boxGeometry: BoxGeometry = new THREE.BoxGeometry(this.inputs.size.x, this.inputs.size.y, this.inputs.size.z);
    const opacity = this.inputs.visible ? this.inputs.opacity : 0; // This is how we hidebox.
    this._boxMaterial = new THREE.MeshBasicMaterial({
      color: this.inputs.color,
      opacity: opacity,
      depthWrite: false,
      transparent: true,
      side: THREE.BackSide,
      blending: THREE.AdditiveBlending
    });

    this.mesh = new THREE.Mesh(boxGeometry, this._boxMaterial);
    this.mesh.updateMatrixWorld();

    this.mesh.position.set(0, 1, 0);

    // must be done after the box is created
    this.boxMixer = new THREE.AnimationMixer(this.mesh);
    this.clipVisible = makeMaterialOpacityClip(THREE, this.inputs.transitionTime, 0, this.inputs.opacity);
    this.clipNotVisible = makeMaterialOpacityClip(THREE, this.inputs.transitionTime, this.inputs.opacity, 0);
    this.edgesClipVisible = makeMaterialOpacityClip(THREE, this.inputs.transitionTime, 0, 1);
    this.edgesClipNotVisible = makeMaterialOpacityClip(THREE, this.inputs.transitionTime, 1, 0);

    this.boxGroup?.add(this.mesh);
  }


  /**
   * After Box creation
   * Depend on Object3D position (created in init()) to place itself in the Scene graph
   * @returns
   */
  drawBorder() {

    if (null == this.mesh || null == this.boxGroup) {
      
      return;
    }
    if (this.edges) {
    
      this.context.scene.remove(this.edges);
      disposeScene(this.edges);
      this.edges = null;
    }
    if (!this.inputs.visible) {
      
      return;
    }

    const THREE = this.context.three;
    const geometry = this.mesh.geometry;

    // Add a border of lines
    const edgesGeometry = new THREE.EdgesGeometry(geometry);
    this.edges = new THREE.LineSegments(edgesGeometry, new THREE.LineBasicMaterial({
      transparent: true,
      color: this.inputs.lineColor,
      linewidth: 1,
      opacity: this.inputs.lineOpacity,
    }));

    // Put the edges object directly in the Scene graph so that they dont intercept
    // raycasts. The edges object will need to be removed if this component is destroyed.
    const obj3D = this.boxGroup; //(this.context.root as any).obj3D as Object3D;
    const worldPos = new this.context.three.Vector3();
    obj3D.getWorldPosition(worldPos);
    this.edges.position.copy(worldPos.add(new THREE.Vector3(0, 1, 0)));
    this.edges.rotation.set(this.inputs.edgesRotation.x, this.inputs.edgesRotation.y, this.inputs.edgesRotation.z);
    this.context.scene.add(this.edges);
  }


  override onEvent(eventType: string, eventData: unknown) {
    //this._logger.trace(`${eventType}`);
    //this.notify(eventType, eventData);
    if (eventType === ComponentInteractionType.CLICK) {
      this.notify('clicked');
    } 
  }


  override onInit() {
    const THREE = this.context.three;
    // Establish placeholder for positioning, etc
    // Needs to be Object3D or Group (not mesh) for border positioning to work
    this.boxGroup = new THREE.Group();
    this.outputs.objectRoot = this.boxGroup;

    this.createBox();
    if (null == this.mesh) return;

    // Add the mesh to the Object3D
    this.outputs.objectRoot = this.boxGroup;
    this.outputs.collider = this.boxGroup;

    this.drawBorder();
    this.setVisibility();
  }


  override onInputsUpdated(oldInputs: OrientedBoxInputs) {

    const THREE = this.context.three;
    //this._logger.trace(`enableInteraction: ${this.inputs.enableInteraction}`);

    this.events[ComponentInteractionType.CLICK] = this.inputs.enableInteraction;
    this.setVisibility();

    if (this.boxMixer && oldInputs.visible !== this.inputs.visible) {
      this.boxMixer.stopAllAction();

      if (this.clipVisible && this.edgesClipVisible && this.inputs.visible) {
        playAnimation(THREE, this.boxMixer, this.clipVisible);
        playAnimation(THREE, this.boxMixer, this.edgesClipVisible, this.edges);
      }
      else if (this.clipNotVisible && this.edgesClipNotVisible) {
        playAnimation(THREE, this.boxMixer, this.clipNotVisible);
        playAnimation(THREE, this.boxMixer, this.edgesClipNotVisible, this.edges);
      }
    }

    if (oldInputs.size.x !== this.inputs.size.x ||
      oldInputs.size.y !== this.inputs.size.y ||
      oldInputs.size.z !== this.inputs.size.z) {
      this.createBox();
      this.drawBorder();
    }

    if (oldInputs.color !== this.inputs.color) {
      (this.mesh?.material as MeshBasicMaterial).color.set(this.inputs.color);
    }

    if (oldInputs.opacity !== this.inputs.opacity) {
      (this.mesh?.material as MeshBasicMaterial).opacity = this.inputs.opacity;
    }

    if (oldInputs.lineOpacity !== this.inputs.lineOpacity) {
      (this.edges?.material as LineBasicMaterial).opacity = this.inputs.lineOpacity;
    }

    if (oldInputs.lineColor !== this.inputs.lineColor) {
      (this.edges?.material as LineBasicMaterial).color = new THREE.Color(this.inputs.lineColor);
    }
  }


  override onTick(delta: number) {
    this.boxMixer?.update(delta / 1000);
  }


  private setVisibility() {

    this.drawBorder();  // Will clear border if visible is false.
    if (this.mesh && this._boxMaterial) {

      const opacity = this.inputs.visible ? this.inputs.opacity : 0;
      this._boxMaterial.opacity = opacity;
      // Setting visible false removes object from raycast targeting interactions
      // We want to maintain interactions with invisible box
      //this.mesh.visible = this.inputs.visible;
    }
  }


  override onDestroy() {
    if (this.mesh) {
      
      disposeScene(this.mesh);
    }
    if (this.edges) {
      
      disposeScene(this.edges);
    }
    this.outputs.objectRoot = null;
    this.outputs.collider = null;
  }

}


export const ORIENTED_BOX_COMPONENT_TYPE = 'mp.orientedBox';
export const makeOrientedBox = function () {
  return new OrientedBox();
}
