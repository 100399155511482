import { defineSystem } from 'bitecs'
import { Object3D, Vector3 } from 'three';
import { object3dByEntityId } from '..';
import { environmentQuery } from '../entity/environment.entity';
import { WorldState } from '../ecs-world.state';


export const environmentUpdateSystem = defineSystem(world => {

    const environmentEntities = environmentQuery(world);
    for (let i = 0; i < environmentEntities.length; i++) {

        const object3d = object3dByEntityId[environmentEntities[i]];
        setCommonAdjustments(object3d, environmentEntities[i]);
    }

    return world;
})


const rotationVector = new Vector3(0, 1, 0);
/**
 * Update the position, rotation, scale values of the object group.
 * @param entity 
 * @param entityId 
 */
function setCommonAdjustments(object3d: Object3D, entityId: number) {

    object3d.position.set(
        WorldState.cameraPose.position.x,
        WorldState.cameraPose.position.y - 0.65,
        WorldState.cameraPose.position.z);

    object3d.quaternion.setFromAxisAngle(rotationVector, WorldState.cameraPose.rotation.y * 0.01745329251994329576923690768489);
}