import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {
    MSAL_INSTANCE, MSAL_GUARD_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { InMemoryScrollingOptions, provideRouter, withDisabledInitialNavigation, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { routes } from './config.routes';
import { MSALInstanceFactory, MSALInterceptorConfigFactory } from './config.auth';
import { environment } from 'src/environments/environment';
import { BrowserUtils } from '@azure/msal-browser';
import { MeInterceptor } from './core/service/authentication/me-interceptor';
import { LoggerModule } from 'ngx-logger';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { Configuration } from 'src/environments/interfaces/IEnvironment';


export const bootstrapCopy = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg>'


// If experimenting with new SSR in Angular Universal
// Review initial navigation requirements for Msal + Universal
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/angular-universal.md
const initialNavigation = !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
    ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
    : withDisabledInitialNavigation();
const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
};

export const appConfig: ApplicationConfig = {
    providers: [
        Location,
        importProvidersFrom(BrowserModule, BrowserAnimationsModule, FormsModule, LoggerModule.forRoot({
            //serverLoggingUrl: '/api/logs',
            level: environment.ngxLogLevel,
            //serverLogLevel: NgxLoggerLevel.ERROR
        })),
        provideRouter(routes, initialNavigation, withInMemoryScrolling(scrollConfig)),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            //useClass: MsalInterceptor,
            useClass: MeInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: () => { return environment.msalGuardConfig; }
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideServiceWorker('ngsw-worker.js', {
            //enabled: true,
            enabled: environment.configuration !== Configuration.DEVELOP,
            //registrationStrategy: 'registerImmediately'
            registrationStrategy: 'registerWhenStable:30000'
        })
    ]
};
