import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import { GLTFInstance } from "../my-three/GLTFInstance";
import { AnimationAction, AnimationMixer, Box3, Color, ColorRepresentation, Group, Material, Matrix4, Mesh, MeshPhysicalMaterial, Object3D, Object3DEventMap, Vector3 } from "three";
import { MaterialData, MaterialDataLoaderComponent } from "./material-data-loader.component";
import { Vector3Obj, vector3OneObj } from "../util/utils";
import { disposeMaterial, disposeScene, setMeshMaterialByName } from "../my-three/utils";
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';
import { GltfManager } from "../my-three/gltf-manager";
import * as THREE from 'three';
import { IMaterialBase } from "../model";

export enum ActiconPosition {
    TopLeft,
    TopCenter,
    TopRight,
    RightTop,
    RightCenter,
    RightBottom,
    BottomRight,
    BottomCenter,
    BottomLeft,
    LeftBottom,
    LeftCenter,
    LeftTop
}

export const VerticalActiconPositions = new Set([
    ActiconPosition.RightTop,
    ActiconPosition.RightCenter,
    ActiconPosition.RightBottom,
    ActiconPosition.LeftBottom,
    ActiconPosition.LeftCenter,
    ActiconPosition.LeftTop
])

export const TopActiconPositions = new Set([
    ActiconPosition.TopLeft,
    ActiconPosition.TopCenter,
    ActiconPosition.TopRight
])

export const CenterActiconPositions = new Set([
    ActiconPosition.TopCenter,
    ActiconPosition.RightCenter,
    ActiconPosition.BottomCenter,
    ActiconPosition.LeftCenter
])

export const LeftActiconPositions = new Set([
    ActiconPosition.LeftBottom,
    ActiconPosition.LeftCenter,
    ActiconPosition.LeftTop
])

export const ActiconDefaultOptions = {
    alignment: ActiconPosition.BottomCenter,
    interactionDistance: 5.5,
    margin: .2,
    scale: vector3OneObj,   // { x: 1.5, y: 1.5, z: 1.5 },
    z: .1
}

export enum ActiconAnimationState { None, Hover, Pressed }
const HOVER_DURATION = 60;
const HOVER_SCALE_FACTOR = 1.5;
const PRESSED_DURATION = 30;
const PRESSED_SCALE_FACTOR = .5;

export type ActiconState = {
    /**
     * Where to position Acticon.
     */
    alignment: ActiconPosition,
    animations?: any
    /**
     * Color to apply to the Acticon material.
     */
    colors: ColorRepresentation[]
    enable: boolean
    hovering: boolean
    /**
     * When to load/release Acticon gltf.
     */
    interactionDistance: number
    /**
     * Positioning offset from bounds.
     */
    margin: number;
    materialData: MaterialData[]
    materialDefinitions: IMaterialBase[]
    /**
     * Positioning offset from bounds.
     */
    perpendicularMargin: number
    /**
     * The Acticon gltf source. Material set by Acticon.
     */
    objectSrc: string | undefined
    /**
     * For use in calculating interaction distance.
     */
    playerPosition: Vector3
    scale: Vector3Obj
    /**
     * For use in calculating interaction distance.
     */
    position?: Vector3
    visible: boolean
    /**
     * Acticon z depth from unadjustedPosition
     */
    z: number;
}

export enum ActiconType {
    Info = 0,
    Next = 1,
    Pause = 2,
    Play = 3
}

/**
 * Acticons support multiple colors which are applied to Materials on the object.
 * Materials are named Material-1, Material-2
 * Material colors can be specified in an array.
 * If Material definitions are supplied then they will override any colors.
 */
export class ActiconComponent extends MyOptyxComponent {

    protected override state: ActiconState = {
        alignment: ActiconDefaultOptions.alignment,
        colors: [0xff0200], //new Color(0xffd700),
        enable: true,
        hovering: false,
        interactionDistance: ActiconDefaultOptions.interactionDistance,
        objectSrc: undefined,
        margin: ActiconDefaultOptions.margin,
        materialDefinitions: [],
        perpendicularMargin: 0,
        playerPosition: new Vector3(),
        position: undefined,
        scale: vector3OneObj,
        visible: true,
        z: ActiconDefaultOptions.z,

        animations: undefined,
        materialData: []
    };

    override pendingState: ActiconState = {
        alignment: ActiconDefaultOptions.alignment,
        colors: [0xff0200], //new Color(0xffd700),
        enable: true,
        hovering: false,
        interactionDistance: ActiconDefaultOptions.interactionDistance,
        objectSrc: undefined,
        margin: ActiconDefaultOptions.margin,
        materialDefinitions: [],
        perpendicularMargin: 0,
        playerPosition: new Vector3(),
        position: undefined,
        scale: vector3OneObj,
        visible: true,
        z: ActiconDefaultOptions.z,

        animations: undefined,
        materialData: []
    };

    //private _appliedMaterialData: MaterialData[] = [];
    private _animationAction?: AnimationAction;
    private _animationMixer?: AnimationMixer;
    private _gltf?: GLTFInstance;
    private _gltfSize = new Vector3();
    private _group!: Group;
    private _interactionBox?: Mesh;
    private _loadingGltfSrc?: string;
    private _reloadGltf = false;
    private _scene?: Object3D;
    private _logger = getLogger();
    private _animationState = ActiconAnimationState.None;
    private _three!: typeof THREE;

    public get size(): Vector3 {

        return this._gltfSize;
    }

    //
    // Events
    //
    private readonly _animationUpdated = new Subject<AnimationMixer>();
    readonly animationUpdated$ = this._animationUpdated.asObservable();
    private readonly _gltfUpdatedSource = new Subject<Object3D<Object3DEventMap> | undefined>();
    readonly gltfUpdated$ = this._gltfUpdatedSource.asObservable();
    private readonly _sizeUpdated = new Subject<object>();
    /**
     * Called before object is added to group.
     */
    readonly sizeUpdated$ = this._sizeUpdated.asObservable();

    //private _materialNames: string[] = [];
    private _materials: MeshPhysicalMaterial[] = [];

    // Animation

    private _animationDuration = 0;
    private _scaleTo = this.state.scale;
    private _isHovering = false;

    // End animation

    // Materials


    // End materials

    constructor(destroyRef: DestroyRef,
        private readonly gltfManager: GltfManager,
        private readonly materialDataLoader: MaterialDataLoaderComponent,
        private readonly acticonType: ActiconType) {
        super(destroyRef);

        this._three = gltfManager.textureManager.three;

        switch (acticonType) {
            case ActiconType.Info: this.pendingState = {
                alignment: ActiconDefaultOptions.alignment,
                animations: undefined,
                colors: [0x1E24D0],
                enable: true,
                hovering: false,
                interactionDistance: 15.5,
                margin: ActiconDefaultOptions.margin,
                materialDefinitions: [],
                objectSrc: '/assets/models/zoom-acticon.glb',
                perpendicularMargin: 0,
                playerPosition: new Vector3(),
                position: undefined,
                z: ActiconDefaultOptions.z,
                visible: true,
                scale: ActiconDefaultOptions.scale,
                materialData: []
            };
                break;
            case ActiconType.Next: this.pendingState = {
                alignment: ActiconDefaultOptions.alignment,
                animations: undefined,
                colors: [0xFF0000],
                enable: false,
                hovering: false,
                interactionDistance: ActiconDefaultOptions.interactionDistance,
                margin: ActiconDefaultOptions.margin,
                materialDefinitions: [],
                objectSrc: '/assets/models/next-acticon-anim.glb',
                perpendicularMargin: 0,
                playerPosition: new Vector3(),
                position: undefined,
                z: ActiconDefaultOptions.z,
                visible: true,
                scale: ActiconDefaultOptions.scale,
                materialData: []
            };
                break;
            case ActiconType.Pause: this.pendingState = {
                alignment: ActiconDefaultOptions.alignment,
                animations: undefined,
                colors: [0xff0200],
                enable: false,
                hovering: false,
                interactionDistance: ActiconDefaultOptions.interactionDistance,
                margin: ActiconDefaultOptions.margin,
                materialDefinitions: [],
                objectSrc: '/assets/models/pause-acticon.glb',
                perpendicularMargin: 0,
                playerPosition: new Vector3(),
                position: undefined,
                z: ActiconDefaultOptions.z,
                visible: true,
                scale: ActiconDefaultOptions.scale,
                materialData: []
            };
                break;
            case ActiconType.Play: this.pendingState = {
                alignment: ActiconDefaultOptions.alignment,
                animations: undefined,
                colors: [0x00ff0b],
                enable: false,
                hovering: false,
                interactionDistance: ActiconDefaultOptions.interactionDistance,
                margin: ActiconDefaultOptions.margin,
                materialDefinitions: [],
                perpendicularMargin: 0,
                playerPosition: new Vector3(),
                position: undefined,
                objectSrc: '/assets/models/play-acticon.glb',
                z: ActiconDefaultOptions.z,
                visible: true,
                scale: ActiconDefaultOptions.scale,
                materialData: []
            }
                break;
        }
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {

        //this._logger.error(`applyPendingState type: ${ActiconType[this.acticonType]}, src: ${this.pendingState.objectSrc}`, this.pendingState);

        if (this.state.colors !== this.pendingState.colors
            || this.state.colors.length != this.pendingState.colors.length) {

            this.setColors(this.pendingState);
        }

        const playerDistanceFromActicon = (this.pendingState.position && this.pendingState.playerPosition) ?
            this.pendingState.position.distanceTo(this.pendingState.playerPosition) : Number.MAX_VALUE;

        this._group.visible = this.pendingState.enable
            && this.pendingState.visible
            && this.pendingState.playerPosition !== undefined
            && playerDistanceFromActicon <= this.pendingState.interactionDistance;

        if (this._group.visible) {

            if (!this._scene || this.state.objectSrc !== this.pendingState.objectSrc) {

                this.loadGltf(this.pendingState);
            }
        } else {

            // Setting visibility to false does not dispose from memory. It could just represent the present state of interaction.
            // Disabling or moving out of interaction range are resaonce to dispose from memory.
            if (!this.pendingState.enable || playerDistanceFromActicon <= this.pendingState.interactionDistance) {

                this.disposeGltf();
            }
        }
    }


    private configureAnimations() {

        this.disposeAnimations();
        if (!this._scene || !this._gltf) {

            return;
        }
        if (this._gltf.animations && 0 < this._gltf.animations.length && this._scene) {

            this._animationMixer = new AnimationMixer(this._scene);
            //
            // Choosing animation 0 to play. 
            // TODO: Create Animation Controller logic to switch animations dynamically.
            //
            this._animationAction = this._animationMixer.clipAction(this._gltf.animations[0]);
            this._animationAction.reset();
            this._animationAction.play();
            this._animationUpdated.next(this._animationMixer);
        } 
    }


    /**
     * Hover event yields to pressed event.
     * @param isHovering 
     */
    public hover(isHovering: boolean): void {

        if (isHovering) {

            this._isHovering = true;
            if (this._animationState === ActiconAnimationState.Pressed) {

                return;
            }

            this._animationState = ActiconAnimationState.Hover;
            this._animationDuration = HOVER_DURATION;
            this._scaleTo = {
                x: this.state.scale.x * HOVER_SCALE_FACTOR,
                y: this.state.scale.y * HOVER_SCALE_FACTOR,
                z: this.state.scale.z * HOVER_SCALE_FACTOR
            };
        } else {

            this._isHovering = false;
            if (this._animationState === ActiconAnimationState.Hover) {

                this._animationState = ActiconAnimationState.None;
                this.restoreScale();
            }
        }
    }


    getAnimationDuration(): number {

        return this._animationDuration;
    }


    getAnimationMixer(): AnimationMixer | undefined {

        return this._animationMixer;
    }


    getAnimationState(): ActiconAnimationState {

        return this._animationState;
    }


    getObject(): Object3D {

        return this._group;
    }


    getScaleTo(): Vector3Obj {

        return this._scaleTo;
    }


    getSize(): Vector3 {

        return this._gltfSize;
    }


    override getState(): ActiconState {

        // Might want to deepCopy depending on properties.
        return this.state;
    }


    // If overriding be sure to call base method.
    override init(): ActiconComponent {
        super.init();

        this._group = new this._three.Group();
        this._gltfUpdatedSource.next(this._group);
        return this;
    }


    private loadGltf(state: ActiconState): void {

        this.disposeAnimations();
        this.disposeGltf();

        if (!state.enable
            || !state.objectSrc
            || 1 > state.objectSrc.length) {

            return;
        }

        if (this._loadingGltfSrc) {

            if (this._loadingGltfSrc !== state.objectSrc) {

                this._reloadGltf = true;
            }
            return;
        }

        this._loadingGltfSrc = state.objectSrc;
        this._logger.trace(`loadGltf: ${this._loadingGltfSrc}`);

        const that = this;
        // Load from cache
        this.gltfManager.loadGltf(this._loadingGltfSrc,
            // onLoad callback
            function (gltf: GLTFInstance) {

                that._gltf = gltf;
                const scene = SkeletonUtils.clone(gltf.scene);
                that._loadingGltfSrc = undefined;
                if (that._reloadGltf) {

                    that._logger.warn('Discarding gltf for reload!');
                    that.loadGltf(state);
                    return;
                }

                // that._materials.color = new Color(state.colors);
                // that._materials.needsUpdate = true;
                // (scene.children[0] as any).material = that._materials;
                // (scene.children[0] as any).material.color = new Color(state.color);
                // (scene.children[0] as any).material.needsUpdate = true;
                that._scene = scene;
                //that._materialNames = getMaterials(scene).map(m => m.name);
                that.setColors(state);

                that.setInteractionBox(state);

                that.configureAnimations();

                that._group.add(that._scene);
                that._gltfUpdatedSource.next(that._group);
            },
            // onError callback
            function (err: any) {

                that._logger.error(`error`, err);
                that._loadingGltfSrc = undefined;
                if (that._reloadGltf) {

                    that._logger.error('Discarding gltf for reload!');
                    that.loadGltf(state);
                    return;
                }
            }
        );
    }


    /**
     * Pressed event overrides hover event.
     * @param isPressed 
     */
    pressed(isPressed: boolean): void {

        if (isPressed) {

            this._animationState = ActiconAnimationState.Pressed;
            this._animationDuration = PRESSED_DURATION;
            this._scaleTo = {
                x: this.state.scale.x * PRESSED_SCALE_FACTOR,
                y: this.state.scale.y * PRESSED_SCALE_FACTOR,
                z: this.state.scale.z * PRESSED_SCALE_FACTOR
            };
        } else {

            if (ActiconAnimationState.Pressed === this._animationState) {

                // Deactivate pressed state
                this._animationState = ActiconAnimationState.None;
                // If hovering is in effect then revert to hover state
                if (this._isHovering) {

                    this.hover(this._isHovering);
                } else {

                    this.restoreScale();
                }
            }
        }
    }


    private restoreScale(): void {

        this._scaleTo = this.state.scale;
    }


    /**
     * Acticon materials should be named material-1, material-2, etc
     * If state.colors has values then map them to Acticon materials.
     * @param state 
     */
    private setColors(state: ActiconState): void {

        if (!this._scene) {

            return;
        }

        for (let i = 0; i < state.colors.length; i++) {

            let material = this._materials.find(m => 0 === m.name.toLocaleLowerCase().localeCompare(`material-${i + 1}`));
            if (!material) {

                material = new this._three.MeshPhysicalMaterial({
                    name: `material-${i + 1}`,
                    // blendColor: new Color(0x000000),
                    // blending: NormalBlending,
                    clearcoat: 1,
                    clearcoatRoughness: 0.0,
                    // displacementBias: 0,
                    // displacementMap: null,
                    // displacementScale: 1,
                    // dithering: false,
                    // emissive: new Color(0x000000),
                    // emissiveIntensity: 1,
                    // emissiveMap: null,
                    // envMap: null,
                    envMapIntensity: 1.5,
                    // flatShading: false,
                    // fog: true,
                    // forceSinglePass: false,
                    // lightMap: null,
                    // lightMapIntensity: 1,
                    metalness: 0.6,
                    // metalnessMap: null,
                    // normalMap: null,
                    // premultipliedAlpha: false,
                    reflectivity: 0.99,
                    roughness: 0.0,
                    // roughnessMap: null,
                    // shadowSide: 0,
                    // side: 2,
                    thickness: 0.05,
                    //toneMapped: true,
                    transmission: 0.0,
                    //color: state.colors[i],
                });

                this._materials.push(material);
            }

            if (this.pendingState.colors.length > i) {

                material.color = new Color(this.pendingState.colors[i]);
            }
            setMeshMaterialByName(this._scene, material);
        }
    }


    /**
     * Create an invisible box around the Acticon to improve the available interaction surface.
     * @param state 
     * @returns 
     */
    private setInteractionBox(state: ActiconState) {

        if (!this._scene) {

            return;
        }

        // Get the dimensions of the gltf.
        const box3 = new Box3().setFromObject(this._scene)

        // https://stackoverflow.com/questions/57360183/creating-boxbuffergeometry-from-box3
        // Make a BoxGeometry of the same size as Box3
        const dimensions = new Vector3().subVectors(box3.max, box3.min);
        this._gltfSize.set(dimensions.x, dimensions.y, dimensions.z);
        if (0 < dimensions.x) {

            this._sizeUpdated.next({ size: this._gltfSize });
        }
        const boxGeo = new this._three.BoxGeometry(dimensions.x, dimensions.y, dimensions.z);

        // Move mesh center so it's aligned with the original object
        const matrix = new Matrix4().setPosition(dimensions.addVectors(box3.min, box3.max).multiplyScalar(0.5));
        boxGeo.applyMatrix4(matrix);

        // Create invisible interaction hit box.
        this._interactionBox = new this._three.Mesh(
            boxGeo,
            new this._three.MeshBasicMaterial({
                color: 0xffcc55,
                opacity: 0,
                transparent: true
            }));

        this._interactionBox.scale.set(1.6, 1.6, 1)

        this._group.add(this._interactionBox);
    }


    //
    // Dispose stuff
    //

    private disposeAnimations(): void {

        if (this._animationMixer) {

            this._animationMixer.stopAllAction();
            if (this._animationAction) {

                this._animationAction.stop();
                this._animationMixer.uncacheAction(this._animationAction.getClip());
            }

            this._animationMixer.uncacheRoot(this._animationMixer.getRoot());
            this._animationMixer = undefined;
        }
    }

    private disposeGltf(): void {

        this._group.clear();
        this._interactionBox?.geometry.dispose();
        (this._interactionBox?.material as Material)?.dispose();

        // Dispose of our cloned copy.
        if (this._scene) {

            const disposable = this._scene;
            this._scene = undefined;

            setTimeout(() => {
                disposeScene(disposable);
            });
        }
    }


    override onDestroy(): void {

        this.disposeGltf();
        this._gltfUpdatedSource.next(this._scene);
        // for (let i = 0; i < this._appliedMaterialData.length; i++) {

        //     this._appliedMaterialData[i].alphaMap?.dispose();
        //     this._appliedMaterialData[i].map?.dispose();
        // }
        this._materials.forEach(m => disposeMaterial(m));
    }

}



// storeGlb() {
//   if (this.glb) {
//     const THREE = this.context.three;
//     const gltfExporter = new (THREE as any).GLTFExporter();
//     const that = this;

//     if (gltfExporter) {
//       gltfExporter.parse(
// 				this.glb,
// 				function (result: any) {
// 					if ( result instanceof ArrayBuffer ) {
// 						//saveArrayBuffer( result, 'scene.glb' );
// 					} else {
// 						const output = JSON.stringify( result, null, 2 );
// 						this._logger.trace( output );
//             localStorage.setItem(that.inputs.objectUrl, output)
// 						//saveString( output, 'scene.gltf' );
// 					}
// 				},
// 				function (error: any) {
// 					this._logger.trace( 'An error happened during parsing', error );
// 				},
// 				{
//           trs: false,
//           onlyVisible: true,
//           binary: false
//         }
// 			);
//     }
//   }
// }