import { defineSystem } from 'bitecs'
import { AdjustableComponent } from '../component/adjustable.component';
import { ScaleComponent } from '../component/scale.component';
import { PositionAdjustmentComponent, PositionComponent } from '../component/position.component';
import { Euler } from 'three';
import { RotationComponent } from '../component/rotation.component';
import { DisplayPlaneComponent } from '../component/display-plane.component';
import {
    RotationAdjustmentComponent, ScaleAdjustmentComponent, snapshotPlaneEidByParentEid, object3dByEntityId
} from '..';
import { SnapshotAspectScaleAdjustmentComponent } from '../component/snapshot-aspect-scale-adjustment.component';
import { PropType } from '../../model';
import { vector3ZeroObj } from '../../util/utils';
import { adjustableEntityQuery, clippingPlaneEntityQuery } from '../entity/adjustable.entity';
import { WorldState } from '../ecs-world.state';
import { TransitionPlaneComponent } from '../component/transition-plane.component';


const euler = new Euler(0, 0, 0, 'YXZ');

/**
 * Apply mask, position, rotation, scale values along with adjustments.
 * https://www.youtube.com/watch?v=BVIiAO5-2-Y
 */
export const adjustableUpdateSystem = defineSystem(world => {

    // const exitAdjustableEntities = adjustableEntityExitQuery(world);
    // for (let i = 0; i < exitAdjustableEntities.length; i++) {

    //     // Handle cleanup for deleted entities.
    // }

    let entityId = 0;
    let entities = adjustableEntityQuery(world);
    for (let i = 0; i < entities.length; i++) {

        entityId = entities[i];

        //setMaskAdjustments(entityId);

        setCommonAdjustments(entityId);

        setVideoAdjustments(entityId);
    }
    entities = clippingPlaneEntityQuery(world);
    for (let i = 0; i < entities.length; i++) {

        entityId = entities[i];

        setClippingPlaneAdjustments(entityId);
    }

    return world;
})


function setClippingPlaneAdjustments(entityIdForClippingPlane: number) {

    const clippingPlane = object3dByEntityId[entityIdForClippingPlane];

    clippingPlane.position.set(
        PositionAdjustmentComponent.x[entityIdForClippingPlane],
        PositionAdjustmentComponent.y[entityIdForClippingPlane],
        .005);  // Clipping plane in front of other planes

    clippingPlane.quaternion.setFromEuler(euler.set(
        RotationAdjustmentComponent.x[entityIdForClippingPlane],
        RotationAdjustmentComponent.y[entityIdForClippingPlane],
        (RotationAdjustmentComponent.z[entityIdForClippingPlane] * Math.PI) / 180, 'YXZ'));

    clippingPlane.scale.set(
        ScaleAdjustmentComponent.x[entityIdForClippingPlane],
        ScaleAdjustmentComponent.y[entityIdForClippingPlane],
        ScaleAdjustmentComponent.z[entityIdForClippingPlane]);
}


/**
 * Update the position, rotation, scale values shared by both Image and Video entities.
 * @param entity 
 * @param eidForAdjustable 
 */
function setCommonAdjustments(eidForAdjustable: number) {

    const adjustableGroup = object3dByEntityId[eidForAdjustable];

    adjustableGroup.position.set(
        PositionComponent.x[eidForAdjustable] + PositionAdjustmentComponent.x[eidForAdjustable],
        PositionComponent.y[eidForAdjustable] + PositionAdjustmentComponent.y[eidForAdjustable],
        PositionComponent.z[eidForAdjustable] + PositionAdjustmentComponent.z[eidForAdjustable]);

    // if (!resources.logged) {
    //     resources.logger.error(`${entityId} BASE SCALE x: ${ScaleComponent.x[entityId]}, y: ${ScaleComponent.y[entityId]}, z: ${ScaleComponent.z[entityId]}`);
    //     resources.logger.error(`${entityId} SCALE ADJ  x: ${DestinationScaleAdjustmentComponent.x[entityId]}, y: ${DestinationScaleAdjustmentComponent.y[entityId]}, z: ${DestinationScaleAdjustmentComponent.z[entityId]}`);
    // }

    adjustableGroup.quaternion.setFromEuler(euler.set(
        RotationComponent.x[eidForAdjustable] + RotationAdjustmentComponent.x[eidForAdjustable],
        RotationComponent.y[eidForAdjustable] + RotationAdjustmentComponent.y[eidForAdjustable],
        RotationComponent.z[eidForAdjustable] + RotationAdjustmentComponent.z[eidForAdjustable], 'YXZ'));

    adjustableGroup.scale.set(
        ScaleComponent.x[eidForAdjustable] + ScaleAdjustmentComponent.x[eidForAdjustable],
        ScaleComponent.y[eidForAdjustable] + ScaleAdjustmentComponent.y[eidForAdjustable],
        ScaleComponent.z[eidForAdjustable] + ScaleAdjustmentComponent.z[eidForAdjustable]);

    const displayPlane = object3dByEntityId[DisplayPlaneComponent.eid[eidForAdjustable]];
    if (displayPlane) {
        
        // Adjust the scale of the video plane if required based upon the video aspect
        displayPlane.scale.set(
            DisplayPlaneComponent.scaleFactor[eidForAdjustable],
            DisplayPlaneComponent.scaleFactor[eidForAdjustable],
            DisplayPlaneComponent.scaleFactor[eidForAdjustable]);

        // Prevent overlap with backing plane
        displayPlane.position.copy(vector3ZeroObj);
        displayPlane.translateZ(.002);
    }

    const transitionPlane = object3dByEntityId[TransitionPlaneComponent.eid[eidForAdjustable]];
    if (transitionPlane) {
     
        // Adjust the scale of the video plane if required based upon the video aspect
        transitionPlane.scale.set(
            TransitionPlaneComponent.scaleFactor[eidForAdjustable],
            TransitionPlaneComponent.scaleFactor[eidForAdjustable],
            TransitionPlaneComponent.scaleFactor[eidForAdjustable]);

        // Prevent overlap with backing plane
        transitionPlane.position.copy(vector3ZeroObj);
        transitionPlane.translateZ(.002);
    }

}


// /**
//  * Update Mask Loader values to trigger mask texture recalculation and update.
//  * @param entity 
//  * @param entityId 
//  */
// function setMaskAdjustments(entityId: number) {

//     if (isFromAndDestinationMaskBothNone(entityId)) {

//         return;
//     }

//     const maskLoader = maskLoaderByEntityId[entityId];

//     // Updating and applying masks is kinda expensive so only do it if there are changes.
//     if (maskLoader.pendingState.clipWidth !== HorizontalMaskComponent.width[entityId]
//         || maskLoader.pendingState.clipHeight !== VerticalMaskComponent.height[entityId]
//         || maskLoader.pendingState.horizontalClipMode !== HorizontalMaskComponent.mode[entityId]
//         || maskLoader.pendingState.verticalClipMode !== VerticalMaskComponent.mode[entityId]
//         || maskLoader.pendingState.xOffset !== HorizontalMaskComponent.offset[entityId]
//         || maskLoader.pendingState.yOffset !== VerticalMaskComponent.offset[entityId]) {

//         maskLoader.pendingState.clipWidth = HorizontalMaskComponent.width[entityId];
//         maskLoader.pendingState.clipHeight = VerticalMaskComponent.height[entityId];
//         maskLoader.pendingState.horizontalClipMode = HorizontalMaskComponent.mode[entityId];
//         maskLoader.pendingState.verticalClipMode = VerticalMaskComponent.mode[entityId];
//         maskLoader.pendingState.xOffset = HorizontalMaskComponent.offset[entityId];
//         maskLoader.pendingState.yOffset = VerticalMaskComponent.offset[entityId];
//         maskLoader.apply();
//     }
// }


/**
 * Set video specific adjustments.
 * @param entity 
 * @param entityId 
 * @param videoPlane 
 */
function setVideoAdjustments(entityId: number) {

    if (PropType.VIDEO !== AdjustableComponent.type[entityId]) {

        return;
    }

    const videoPlaneGroup = object3dByEntityId[entityId];
    videoPlaneGroup?.position.set(
        PositionComponent.x[entityId] + PositionAdjustmentComponent.x[entityId],
        PositionComponent.y[entityId] + PositionAdjustmentComponent.y[entityId],
        PositionComponent.z[entityId] + PositionAdjustmentComponent.z[entityId]);
    videoPlaneGroup?.quaternion.setFromEuler(euler.set(
        RotationComponent.x[entityId] + RotationAdjustmentComponent.x[entityId],
        RotationComponent.y[entityId] + RotationAdjustmentComponent.y[entityId],
        RotationComponent.z[entityId] + RotationAdjustmentComponent.z[entityId], 'YXZ'));
    videoPlaneGroup?.scale.set(
        ScaleComponent.x[entityId] + ScaleAdjustmentComponent.x[entityId],
        ScaleComponent.y[entityId] + ScaleAdjustmentComponent.y[entityId],
        ScaleComponent.z[entityId] + ScaleAdjustmentComponent.z[entityId]);

    const snapshotPlane = object3dByEntityId[snapshotPlaneEidByParentEid[entityId]];
    // The Snapshot plane adjusted based upon its aspect
    snapshotPlane?.scale.set(
        SnapshotAspectScaleAdjustmentComponent.scaleFactor[entityId],
        SnapshotAspectScaleAdjustmentComponent.scaleFactor[entityId],
        SnapshotAspectScaleAdjustmentComponent.scaleFactor[entityId]);
    // Prevent overlap with backing plane
    snapshotPlane?.position.copy(vector3ZeroObj);
    snapshotPlane?.translateZ(.002);
    // if (300 === resources.count++) {

    //     resources.count = 0;
    //     resources.logger.error('Backing plane rotation', entity.backingPlane.rotation);
    //     resources.logger.error('Snapshot plane rotation', entity.plane.rotation, entity.plane);
    // }
}


