<div class="flex flex-row justify-between items-center px-4">
    <div class="mt-2 md:mt-0 cursor-pointer group relative">
        <div class="cursor-pointer group relative">
            <button #dropdownTarget
                class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                <label class="flex justify-center">
                    <ng-icon class="cursor-pointer" size="22" name="bootstrapCaretLeft" />
                </label>
            </button>
            <app-button-dropdown [TriggerElement]="dropdownTarget" [Items]="propActions"
                (SelectItem)="onPropAction($event)" />
        </div>
    </div>
    <div class="flex flex-col w-full justify-start px-6 overflow-hidden">
        <p>{{ImageProp().name}}</p>        
    </div>
</div>