import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

/**
 * Disable the default mouse scroll wheel behavior on input type number which, normally, would increase/decrase its value. 
 * https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
 */
@Directive({
	selector: 'input[type=number]',
	standalone: true
})
export class NumberInputScrollDirective implements OnDestroy {
	private subs = new Subscription();

	constructor(elRef: ElementRef<HTMLInputElement>) {
		const el = elRef.nativeElement;
		const focus$ = fromEvent(el, 'focus');
		const blur$ = fromEvent(el, 'blur');

		// when input is focused, start listening to the scroll of element. On this event blur and
		// re-focus on the next tick. This allows for the page scroll to still happen, but the unwanted
		// input number change is prevented.
		// Stop listening to the scroll when focus is lost
		const preventWheel$ = focus$.pipe(
			switchMap(() => {
				return fromEvent(el, 'wheel', { passive: false }).pipe(
					tap(() => {

							el.blur();
							setTimeout(() => {
								el.focus();
							}, 0);
						}),
					takeUntil(blur$)
				);
			})
		);

		this.subs.add(preventWheel$.subscribe());
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}
}
