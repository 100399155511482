<div class="w-full bg-slate-500">
	<div class="main-container">
		<div class="editor-container editor-container_classic-editor" #editorContainerElement>
			<div class="editor-container__editor">

					@if (isLayoutReady()) {

					<ckeditor [editor]="Editor" [config]="config" [data]="initialContent()" [disabled]="!enable()" (ready)="onEditorReady($event)" (change)="onChange($event)" />

					}

			</div>
		</div>
	</div>
</div>
<div class="pt-2" [innerHTML]="displayHtml()"></div>