import { Component, Input, OnDestroy, computed, input, output, signal } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

export type ButtonDropownItem = [group: number, sort: number, value: string];
type groupItem = { group: number, groupItems: ButtonDropownItem[] };
export function equals(expected: ButtonDropownItem, actual: ButtonDropownItem): boolean {
  return expected[0] === actual[0] && expected[1] === actual[1] && expected[2] === actual[2];
}

/**
 * Display list of selectable options.
 */
@Component({
  selector: 'app-button-dropdown',
  standalone: true,
  imports: [],
  templateUrl: './button-dropdown.component.html',
  styleUrl: './button-dropdown.component.scss'
})
export class ButtonDropdownComponent implements OnDestroy {

  id = crypto.randomUUID();

  /**
   * The component that toggles this dropdowns open/closed state.
   */
  private _triggerElement!: HTMLElement;
  @Input() set TriggerElement(value: HTMLElement) {
    if (!value) return;

    this._triggerElement = value;
    value.addEventListener("click", this.toggleStateCallback, true);
    value.addEventListener("blur", this.handleBlurCallback, true);
  }

  readonly isOpen = signal(false);
  readonly isMobile = (/Mobile/i.test(navigator.userAgent));

  readonly items = signal(<ButtonDropownItem[]>[]);
  @Input() set Items(value: ButtonDropownItem[]) {
    if (!value) {
      this.items.set([]);
      return;
    }
    this.items.set(value.sort((i1, i2) => i1[0] - i2[0] || i1[1] - i2[1]));
  }

  readonly groupedItems = computed(() => this.items().reduce((initial, currentItem) => {

    if (!initial.some(i => i.group === currentItem[0])) {

      initial.push({ group: currentItem[0], groupItems: this.items().filter(i => i[0] === currentItem[0]) });
    }
    return initial;
  }, <groupItem[]>[]))


  AlignRight = input(false);

  SelectItem = output<ButtonDropownItem>();


  constructor(private readonly _logger: NGXLogger) { }


  closeOverlay(): void {

    this.isOpen.set(false);
  }


  private readonly handleBlurCallback = this.handleBlur.bind(this);
  handleBlur(blurEvent: any): void {

    if (blurEvent.relatedTarget || this.isMobile) {

      return;
    }
    this.isOpen.set(false);
  }


  ngAfterViewInit(): void {

    const elementRef = document.getElementById(this.id);
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this._triggerElement?.removeEventListener("click", this.toggleStateCallback);
    this._triggerElement?.removeEventListener("blur", this.handleBlurCallback);

    const elementRef = document.getElementById(this.id);
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  selectItem(item: ButtonDropownItem): void {

    this.isOpen.set(false);
    setTimeout(() => this.SelectItem.emit(item));
  }


  private readonly toggleStateCallback = this.onToggleState.bind(this);
  private onToggleState(event: any): void {

    this.isOpen.set(!this.isOpen());
  }


  /**
   * Template needs to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.isOpen()) {

      const elementRef = document.getElementById(this.id);
      elementRef?.focus();

      return true;
    }
    return false;
  })

}
