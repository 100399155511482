<div #stageImagePropSidebarComponent tabindex="0"
    class="fixed h-screen flex flex-col top-0 w-full z-50 md:max-w-[500px] bg-[#0e0f10] shadow-xl 
            {{ isOpen() ? 'right-0' : '-right-full' }} transition-all duration-300 px-4 bg-opacity-80 border-l-[1px] border-accent" (blur)="onBlur()">
    <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row">
            <!-- close icon -->
            <div class=" text-4xl w-11 h-[70px] flex justify-start items-center cursor-pointer transition"
                (click)="onClose()">
                <ng-icon class="text-3xl transition {{ closing() ? 'rotate-[2turn]' : 'rotate-0' }}"
                    name="bootstrapXLg" />
            </div>
            <div class="flex items-center group relative">
                <div class="text-accent text-2xl font-semibold overflow-hidden">
                    Stage image prop
                </div>
                <span
                    class="hidden md:flex group-hover:opacity-100 transition-opacity bg-gray-800 px-2 border-[1px] border-white text-sm text-gray-100 rounded-md absolute opacity-0 whitespace-nowrap -right-1 translate-x-full">
                    {{localImageProp().id}}
                </span>
            </div>
        </div>
        <div class="flex flex-row space-x-2">
            <div class="tooltip tooltip-left" data-tip="Delete forever">
                <button class="text-sm uppercase font-bold bg-error rounded-lg h-[41px] w-[48px]"
                    (click)="onDeleteProp()">
                    <span class="flex justify-center ">
                        <ng-icon size="22" name="bootstrapTrash" />
                    </span>
                </button>
            </div>
            <div class="tooltip tooltip-bottom" data-tip="Create copy">
                <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]"
                    (click)="onCopyImageProp()">

                    @if (copyingImageProp()) {

                    <svg class="animate-spin h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>

                    } @else {

                    <span class="flex justify-center items-center text-center text-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-copy" viewBox="0 0 16 16">
                            <path stroke-width="1.5" fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                        </svg>
                    </span>

                    }
                </button>
            </div>
        </div>
    </div>

    <div class="flex-1 flex overflow-hidden">
        <!-- Image count {{sponsorImageService.originalSponsorImageCount}} -->
        <!-- https://stackoverflow.com/questions/69400560/how-to-change-scrollbar-when-using-tailwind-next-js-react -->
        <!-- https://stackoverflow.com/questions/70539607/how-to-make-scrollable-sidebar-fixed-in-tailwindcss -->
        <div
            class="flex-1 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:[width:15px] [&::-webkit-scrollbar-thumb]:bg-accent">
            <!-- The following padding seems to adjust for stuff at top of sidebar. If scrollable area matched heigh of sidebar, don't think it would be needed -->
            <div class="flex flex-col space-y-2 pb-36 mr-4">
                <textarea rows="3" maxlength="256" [value]="localImageProp().name" (blur)="handleNameInput($event)"
                    (keyup)="handleNameInput($event)"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary">
                </textarea>
                <div class="flex flex-row justify-between">
                    <div #sliderBasedInputs class="flex flex-row space-x-2 items-center">
                        <div class="flex flex-row space-x-2 items-center">
                            <span>Aspect:</span>
                            <input type="number" [value]="aspectInitializer() | number : '1.1-4' | noComma" step="any"
                                (focus)="setFocusAspect($event)" [readonly]="lockBaseValues()" (blur)="onBlur()"
                                (input)="handleAspectInput($event)"
                                class="text-primary text-center placeholder:text-primary h-10 w-24 outline-accent {{ lockBaseValues() ? 'opacity-70' : ''}}">
                        </div>

                        @if (lockBaseValues()) {

                        <div class="tooltip tooltip-right" data-tip="Unlock base values">
                            <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]"
                                (click)="onUnlockBaseValues()">
                                <label class="flex justify-center">
                                    <ng-icon class="cursor-pointer" size="25" name="bootstrapUnlock" />
                                </label>
                            </button>
                        </div>

                        }

                    </div>

                    @if (!isCustomMaskFile() && 1 < localImageProp().id) {

                    <input type="file" [id]="inputId" class="hidden"
                        accept="image/png, image/jpeg, image/gif, image/jpg, image/webp" onclick="this.value = null;"
                        (change)="addCustomMask($event)">
                    <div class="tooltip tooltip-left" data-tip="Upload mask">
                        <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                            <label class="flex justify-center" [for]="inputId">
                                <ng-icon class="cursor-pointer" size="25" name="bootstrapPlusLg" />
                            </label>
                        </button>
                    </div>

                    }

                </div>
                <div class="flex flex-row justify-between items-center">
                    <span>Position:</span>
                    <app-common-vector3-input [Value]="localImageProp().positionObj"
                        (OnChange)="handlePositionChange($event)" [IsActive]="!lockBaseValues()"
                        (OnFocus)="onPositionFocus()" Label="Position" (OnLostFocus)="onDetachTransformControls()" />
                </div>
                <div class="flex flex-row justify-between items-center">
                    <span>Rotation:</span>
                    <app-common-vector3-input [Value]="localImageProp().rotationObj"
                        (OnChange)="handleRotationChange($event)" [IsActive]="!lockBaseValues()"
                        (OnFocus)="onRotationFocus()" Label="Rotation" (OnLostFocus)="onDetachTransformControls()" />
                </div>
                <div class="flex flex-row justify-between items-center">
                    <span class="pt-[27px]">Scale:</span>
                    <app-common-vector3-input [Value]="localImageProp().scaleObj" (OnChange)="handleScaleChange($event)"
                        [IsActive]="!lockBaseValues()" (OnFocus)="onScaleFocus()" Label="Scale" [EnableEqual]="true"
                        [Equal]="isScaleEqual()" (OnLostFocus)="onDetachTransformControls()" />
                </div>

                @if (isCustomMaskFile()) {

                <div class="flex flex-col w-full">
                    {{localImageProp().maskFileName}}
                    <div
                        class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                        <div class="h-96 w-72 mx-auto">
                            <img class="h-full w-full object-contain transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                                [src]="localImageProp().maskUrl" alt="Custom mask" />
                        </div>
                        <div
                            class="absolute inset-0 flex translate-y-[80%] pt-20 overflow-hidden flex-col px-4 transition-all duration-500 group-hover:translate-y-[65%]">
                            <div class="flex flex-row justify-end">
                                <button
                                    class="text-sm uppercase font-bold bg-danger hover:bg-dangerhov rounded-lg h-[41px] w-[48px]"
                                    (click)="onDeleteMask()">
                                    <span class="flex justify-center ">
                                        <ng-icon size="22" name="bootstrapTrash" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                }

                @if (localImageProp().viewPositionId && 0 < localImageProp().viewPositionId.length) { 
                    
                    <div class="flex flex-row w-full justify-between items-center">

                        <div>View position: &nbsp; {{localImageProp().viewPositionId}}</div>
                        <div class="tooltip tooltip-left" data-tip="Clear view position">
                            <button (click)="clearViewPositionId()"
                                class="text-sm uppercase font-bold bg-danger hover:bg-dangerhov rounded-lg h-[41px] w-[48px]">
                                <span class="flex justify-center ">
                                    <ng-icon class="cursor-pointer" size="25" name="bootstrapTrash" />
                                </span>
                            </button>
                        </div>
                    </div>

                }

                @if (enableSave()) {

                    <div class="flex flex-row items-center h-[41px]">

                        <div class="w-1/2 flex flex-row space-x-2">
                            <button (click)="onUndo()"
                                class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                                <span class="flex justify-center ">
                                    <ng-icon class="cursor-pointer" size="22" name="bootstrapArrowCounterclockwise" />
                                </span>
                            </button>
                            <button (click)="onSave()"
                                class="text-sm uppercase font-bold bg-green-700 rounded-lg h-[41px] w-[48px]">
                                <span class="flex justify-center ">
                                    <ng-icon class="cursor-pointer" size="22" name="bootstrapCheckLg" />
                                </span>
                            </button>
                        </div>

                    </div>

                }

            <div
                class="mt-1 flex flex-row space-x-6 border-b-[1px] border-b-slate-600 mb-2 overflow-hidden overflow-x-auto snap-x">
                <div (click)="selectTab(1)" 
                    class="snap-center ml-2 md:ml-0 flex flex-row space-x-4 items-center {{ selectedTab() === 1 ? 'bg-slate-600 rounded-t-md px-4 py-1' : 'cursor-pointer' }}">
                    <h1 class="text-2xl">Adjustments</h1>

                    @if (1 === selectedTab()) {

                    <div class="flex flex-row space-x-2">

                    @if (0 < positionAdjustments().length) { 
                            
                        <div class="tooltip tooltip-right" data-tip="Hide 10 seconds">
                            <button (click)="onHideAdjustments()"
                                class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                                <span class="flex justify-center ">
                                    <ng-icon class="cursor-pointer" size="25" name="bootstrapEyeSlash" />
                                </span>
                            </button>
                        </div>

                    }

                    @if (!hasPositionAdjustment()) {

                    <div class="tooltip tooltip-right" data-tip="Add">
                        <button (click)="onAddAdjustment()"
                            class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                            <span class="flex justify-center ">
                                <ng-icon class="cursor-pointer" size="25" name="bootstrapPlusLg" />
                            </span>
                        </button>
                    </div>

                    }

                </div>

                }

            </div>

            <div (click)="selectTab(2)" 
                class="snap-center flex flex-row space-x-4 items-center {{ selectedTab() === 2 ? 'bg-slate-600 rounded-t-md px-4 py-1' : 'cursor-pointer' }}">
                <h1 class="text-2xl whitespace-nowrap">Clip Planes</h1>

                @if (2 === selectedTab()) {

                <div class="tooltip tooltip-bottom" data-tip="Add">
                    <button (click)="onAddClippingPlane()"
                        class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                        <span class="flex justify-center ">
                            <ng-icon class="cursor-pointer" size="25" name="bootstrapPlusLg" />
                        </span>
                    </button>
                </div>

                }

            </div>
        </div>

        @if (1 === selectedTab()) {

        <div class="flex flex-row w-full justify-between items-center mt-2">
            <div class="w-full">
                <div class="flex flex-row w-full justify-between items-center pr-4">
                    <div class="flex items-center text-accent text-xl font-semibold overflow-hidden">
                        Position adjustments
                    </div>

                    @if (hasPositionAdjustment()) {

                    <app-checkbox Label="Enable copy" [Checked]="enableCopy()" (Toggled)="onToggleEnableCopy()" />

                    }
                    
                </div>
                <div class="text-accent font-semibold">Current: {{currentPlayerPositionId()}}</div>
            </div>
            <div class="flex flex-row space-x-2">

                @if (currentPlayerPositionId() !== localImageProp().viewPositionId) {

                <div class="tooltip tooltip-left" data-tip="Set view position">
                    <button (click)="setViewPositionId()"
                        class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                        <span class="flex justify-center ">
                            <ng-icon class="cursor-pointer" size="25" name="bootstrapEye" />
                        </span>
                    </button>
                </div>

                }

            </div>
        </div>

        @for (positionAdjustment of positionAdjustments(); track $index) {

        <app-position-adjustment [PositionAdjustment]="positionAdjustment" [ParentProp]="localImageProp()" 
            [IsCopyActive]="enableCopy()" [CurrentPlayerPositionId]="currentPlayerPositionId()" 
            (Change)="handleAdjustmentChange($event)" (MaskDeleted)="handleAdjustmentMaskDeleted($event)"
            (Delete)="onDeleteAdjustment($event)" (GoToPosition)="onGoToPosition($event)" />

        }

        } <!-- End tab 1 -->

        @if (2 === selectedTab()) {

        @for (clippingPlane of clippingPlanes(); track $index) {

        <app-clipping-plane [ClippingPlane]="clippingPlane" [IsActive]="clippingPlane.id == selectedClippingPlaneId()"
            (SelectClippingPlane)="onSelectClippingPlane($event)" (DeleteClippingPlane)="onDeleteClippingPlane($event)"
            (ClippingPlaneChange)="handleClippingPlaneChange($event)"
            [Index]="0 > clippingPlane.id ? clippingPlane.id : $index + 1" />

        } @empty {

        <div class="mt-2">No clipping planes defined for this Prop</div>

        }

        }

    </div>
</div>
</div>
</div>