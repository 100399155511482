import { CUSTOM_ELEMENTS_SCHEMA, Component, OnDestroy, signal } from '@angular/core';
import { NGXLogger } from 'ngx-logger';


/**
 * This page is opened in a new browser tab to "pause" Showroom/Matterport which is a resource hog.
 * Pausing Showroom leaves more resources for the AR experience.
 * WARNING: https://github.com/google/model-viewer/discussions/3767
 * model-viewer AR requires a User click to launch so it must be the first thing invoked when it is ready.
 * Therefore, model-viewer src is set to a default so AR can be invoked. Then the src is switched in the background.
 * This component depends on window.opener to pass localStorage data from parent.
 * PWA is disabled by commenting the manifest from index.html because window.open launched the PWA which caused window.opener to be lost/null.
 */
@Component({
  selector: 'app-launch-ar',
  standalone: true,
  imports: [],
  templateUrl: './launchAR.component.html',
  styleUrl: './launchAR.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LaunchARComponent implements OnDestroy {
  readonly objectUrl = signal('/assets/models/MyOptyxGlasses.glb');
  readonly placement = signal('floor');
  //private _renderScale = 1.0;


  constructor(private readonly _logger: NGXLogger) {

    if (!window.opener) {

      this._logger.trace(`window.opener is null`);
      return;
    } if (!window.opener.localStorage) {

      this._logger.trace(`window.opener.localStorage is null`);
      return;
    }

    const passedPlacement = window.opener.localStorage.getItem("placement");
    if (passedPlacement && 0 < passedPlacement.length) {
      this.placement.set(passedPlacement);
    }

    // Object prop urls are static and point to the blob storage server
    const passedObjectUrl = window.opener.localStorage.getItem("objectUrl");
    if (passedObjectUrl && 0 < passedObjectUrl.length) {
      this.objectUrl.set(passedObjectUrl);
    } 
    // This code was for the dynamic Image glb but AR kept failing
    // For now images are launchhing AR directly in Showroom
    // else {
    //   const passedGlb = window.opener.localStorage.getItem("glb");
    //   if (passedGlb && 0 < passedGlb.length) {
    //     const glbBlob = new Blob([passedGlb], { type: 'text/plain' });
    //     URL.revokeObjectURL(this.objectUrl());   // prevent memory leaks
    //     const dataUrl = URL.createObjectURL(glbBlob);
    //     this.objectUrl.set(dataUrl);
    //     //this.save(glbBlob, "test.glb");
    //   }
    // }
    this._logger.trace(`objectUrl: ${this.objectUrl()}`);
  }


  handleReturnToShowroom() {
    window.close();
  }


  ngOnDestroy(): void {
    URL.revokeObjectURL(this.objectUrl());
    window.close();
  }


  /**
   * The model has been loaded, invoke AR
   * @param event 
   */
  onModelViewerLoaded(event: any) {
    const modelViewer = document.querySelector("model-viewer");
    if (modelViewer) {
      if (this.placement() === 'floor') {
        modelViewer.setAttribute('auto-rotate', '');
      }
      (modelViewer as any).activateAR();
    }
  }


  /**
   * Download glb file to local machine.
   * @param blob
   * @param filename 
   */
  save(blob: Blob, filename: string) {
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link); // Firefox workaround, see #6594

    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    // URL.revokeObjectURL( url ); breaks Firefox...
  }


}
