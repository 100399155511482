/**
 * Javascript only has the Number data type, which is implemented as a floating-point type. 
 * Bit mask values are converted to signed 32-bit values for bitwise operations. 
 * As long as you don't try to use more than 31 bits, you'll be fine.
 */
import { MaskHelper } from "../util/bit";


/**
 * Use to test against IUser roleMask
 */
export enum Role {
    NONE = 0,
    ADMIN = 1 << 0,
    SUBSCRIBER = 1 << 1,
    READONLY_SUBSCRIBER = 1 << 2,
    INACTIVE_SUBSCRIBER = 1 << 3,
    CONTENT_MANAGER = 1 << 4
}


export function IsAdmin(mask: number) {

    return new MaskHelper(mask).has(Role.ADMIN);
}


export function IsSubscriber(mask: number) {

    return IsAdmin(mask) || new MaskHelper(mask).has(Role.SUBSCRIBER);
}


/**
 * Everyone is a visitor which is the lowest role
 * @param mask 
 * @returns 
 */
export function IsVisitor(mask: number) {
    
    return true;
}