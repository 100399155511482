import { Component, Input, OnDestroy, OnInit, computed, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Vector3InputComponent } from "../vector3-input/vector3-input.component";
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapCaretRight, bootstrapXLg } from "@ng-icons/bootstrap-icons";
import { HorizontalMask, VerticalMask } from 'projects/my-common/src/scene/image-mask';
import { Subscription } from 'rxjs/internal/Subscription';
import { NGXLogger } from 'ngx-logger';
import { CheckboxComponent } from "../checkbox/checkbox.component";
import { Vector3Obj } from 'projects/my-common/src/util/utils';
import { firstValueFrom } from 'rxjs';
import { VenueService } from 'src/app/core/service/venue/venue.service';
import { CommonVector3InputComponent } from "../../showroom/shared/common-vector3-input/common-vector3-input.component";
import { ClippingPlaneAssignmentComponent } from "../../showroom/admin/clipping-plane-assignment/clipping-plane-assignment.component";
import { ClippingPlane, ClippingPlaneAssignment, CrudState, IPositionAdjustmentUpload, PositionAdjustment, Prop } from 'projects/my-common/src/model';
import { ModalService } from 'src/app/core/service/ui/modal/modal.service';
import { ALERT_MODAL_ID } from '../alert-modal/alert-modal.component';

type PositionAdjustmentSelectedEvent = {
  positionAdjustment: PositionAdjustment
}
const PositionAdjustmentSelectedEventType = 'position-adjustment-selected-event';


@Component({
  selector: 'app-position-adjustment',
  standalone: true,
  templateUrl: './position-adjustment.component.html',
  styleUrls: ['./position-adjustment.component.scss'],
  providers: [provideIcons({ bootstrapCaretRight, bootstrapXLg })],
  imports: [CommonModule, NgIconComponent, Vector3InputComponent, CheckboxComponent, CommonVector3InputComponent, ClippingPlaneAssignmentComponent]
})
export class PositionAdjustmentComponent implements OnDestroy, OnInit {

  inputId = crypto.randomUUID();
  private readonly _subscriptions: Subscription[] = [];

  readonly horizontalMask = signal(new HorizontalMask());
  readonly keepScaleEqual = signal(true);
  readonly positionAdjustment = signal(new PositionAdjustment());
  readonly selectedClippingPlaneId = signal(0);
  readonly selectedTab = signal(1);
  readonly verticalMask = signal(new VerticalMask());

  //
  // Inputs
  //
  readonly CurrentPlayerPositionId = input('');
  readonly ParentProp = input.required<Prop>();
  readonly isCopyActive = signal(false);
  @Input() set IsCopyActive(value: boolean) {

    this.isCopyActive.set(value);
  }
  @Input() set PositionAdjustment(value: PositionAdjustment) {

    // Make sure Vector3Input doesn't automatically change scale that has unequal values to equal BEFORE setting psotionAdjustment value.
    this.keepScaleEqual.set(value.scale[0] === value.scale[1] && value.scale[1] === value.scale[2]);

    const horizontalMask = new HorizontalMask();
    horizontalMask.mode = value.horizontalMaskMode; // Mode must be set first.
    horizontalMask.offset = value.horizontalMaskXOffset;
    horizontalMask.width = value.horizontalMaskWidth;
    this.horizontalMask.set(horizontalMask);

    const verticalMask = new VerticalMask();
    verticalMask.mode = value.verticalMaskMode;     // Mode must be set first.
    verticalMask.height = value.verticalMaskHeight;
    verticalMask.offset = value.verticalMaskYOffset;
    this.verticalMask.set(verticalMask);

    // Give keep scale equal a cycle to set itself.
    this.positionAdjustment.set(new PositionAdjustment(value));
  }
  //
  // End inputs
  //

  //
  // Outputs
  //
  readonly Delete = output<PositionAdjustment>();
  readonly GoToPosition = output<string>();
  readonly Change = output<PositionAdjustment>();
  readonly MaskDeleted = output<PositionAdjustment>();
  //
  // End outputs

  //
  // Selectors
  //
  /**
   * Return existing or placeholder Clipping Plane Assignments bound to the available Clipping Planes.
   */
  readonly clippingPlaneAssignments = computed(() => this.ParentProp().clippingPlanes
    .map(cp => new ClippingPlane(cp))
    .filter(cp => CrudState.NONE === cp.crudState)
    .sort((cp1, cp2) => cp1.id - cp2.id)
    .map(cp => {

      const existingAssignment = this.positionAdjustment().clippingAssignments
        .find(cpa => cpa.clippingPlaneId === cp.id);
      const clippingPlaneAssignment = existingAssignment ? new ClippingPlaneAssignment(existingAssignment) : new ClippingPlaneAssignment();

      clippingPlaneAssignment.clippingPlane = cp;

      return clippingPlaneAssignment;
    })
  );
  readonly hasClippingAssignments = computed(() => this.clippingPlaneAssignments().some(cpa => cpa.isAssigned));
  readonly isActive = computed(() => { return this.positionAdjustment().positionId === this.CurrentPlayerPositionId() });
  readonly isCustomMaskFile = computed(() => 0 < this.positionAdjustment().maskUrl.length);
  //
  // End selectors
  //


  constructor(private readonly logger: NGXLogger,
    private readonly modalService: ModalService) { }

  async addCustomMaskToPositionAdjustment(event: any) {

    if (!event) {

      return;
    }

    const file = event.target.files[0];
    var iFileSize = file.size;
    var iConvert = (file.size / 1048578).toFixed(2);

    if (iFileSize > 29360128) {

      let txt = `File size: ${iConvert} MB. Please make sure your image file is less than 28 MB.`;
      this.modalService.setTarget(ALERT_MODAL_ID, txt);
      this.modalService.open(ALERT_MODAL_ID);

      return;
    }

    const positionAdjustmentUpload = this.positionAdjustment() as IPositionAdjustmentUpload;
    positionAdjustmentUpload.maskFileName = file.name;
    positionAdjustmentUpload.file = file;

    this.Change.emit(positionAdjustmentUpload as PositionAdjustment);
  }


  handleClippingPlaneAssignmentChange(assignment: ClippingPlaneAssignment) {

    this.positionAdjustment.update(pa => {

      const assignmentIndex = pa.clippingAssignments.findIndex(cpa => cpa.id === assignment.id);
      if (-1 < assignmentIndex) {

        if (CrudState.NONE === assignment.crudState) {

          assignment.crudState = CrudState.UPDATED;
        }
        pa.clippingAssignments[assignmentIndex] = assignment;
      }

      return new PositionAdjustment(pa);
    })

    this.Change.emit(this.positionAdjustment());
  }


  handleDeletePositionAdjustment(positionAdjustment: PositionAdjustment): void {

    if (confirm('Delete position adjustment?')) {

      this.Delete.emit(positionAdjustment);
    }
  }


  async handleDeleteCustomMask(): Promise<void> {

    this.MaskDeleted.emit(this.positionAdjustment());
  }


  handleHorizontalMaskChange(horizontalMask: HorizontalMask, target: PositionAdjustment): void {

    target.horizontalMaskMode = horizontalMask.mode;
    target.horizontalMaskWidth = horizontalMask.width;
    target.horizontalMaskXOffset = horizontalMask.offset;
    this.Change.emit(target);
  }


  handlePositionAdjustmentChange(adjustment: Vector3Obj, target: PositionAdjustment): void {

    target.adjust = [adjustment.x, adjustment.y, adjustment.z];
    this.Change.emit(target);
  }

  handleRenderOrderInput(renderOrderInputEvent: Event, target: PositionAdjustment): void {

    target.renderOrder = Number((renderOrderInputEvent.target as HTMLInputElement).value);
    this.Change.emit(target)
  }


  handleRotateAdjustmentChange(rotate: Vector3Obj, target: PositionAdjustment): void {

    target.rotate = [rotate.x, rotate.y, rotate.z];
    this.Change.emit(target);
  }


  handleScaleAdjustmentChange(scale: Vector3Obj, target: PositionAdjustment): void {

    target.scale = [scale.x, scale.y, scale.z];
    this.Change.emit(target);
  }


  handleStencilRefInput(stencilRefInputEvent: Event, target: PositionAdjustment): void {

    const newStencilRef = Number((stencilRefInputEvent.target as HTMLInputElement).value);
    target.stencilRef = 255 < newStencilRef ? 255 : newStencilRef;

    this.Change.emit(target);
  }


  handleVerticalMaskChange(verticalMask: VerticalMask): void {

    this.positionAdjustment.update(pa => {
      pa.verticalMaskMode = verticalMask.mode;
      pa.verticalMaskHeight = verticalMask.height;
      pa.verticalMaskYOffset = verticalMask.offset;
      return pa;
    });

    this.Change.emit(this.positionAdjustment());
  }


  ngOnDestroy(): void {

    this._subscriptions.forEach(s => s.unsubscribe());
    window.removeEventListener(PositionAdjustmentSelectedEventType, this.positionAdjstmentSelectedCallback);
  }


  ngOnInit(): void {

    window.addEventListener(PositionAdjustmentSelectedEventType, this.positionAdjstmentSelectedCallback);
  }


  private _newId = -1;
  onAssignClippingPlane(clippingPlane?: ClippingPlane) {

    if (!clippingPlane) {

      return;
    }

    const index = this.positionAdjustment().clippingAssignments
      .findIndex(cpa => cpa.clippingPlaneId === clippingPlane.id);
    if (-1 < index) {

      const existingAssignment = this.positionAdjustment().clippingAssignments[index];
      if (CrudState.DELETED === existingAssignment.crudState) {

        (existingAssignment as ClippingPlaneAssignment).crudState = CrudState.UPDATED;
        this.positionAdjustment.update(pa => {

          pa.clippingAssignments[index] = existingAssignment;
          return new PositionAdjustment(pa);
        });
      }

      this.selectedClippingPlaneId.set(clippingPlane.id);
      this.Change.emit(this.positionAdjustment());
      return;
    }

    // Create assignment locally to be persisted upon save.
    const newAssignment = new ClippingPlaneAssignment();
    newAssignment.adjustmentId = this.positionAdjustment().id;
    newAssignment.clippingPlaneId = clippingPlane.id;
    newAssignment.clippingPlane = clippingPlane;
    newAssignment.id = this._newId--;
    newAssignment.scale = [.2, .2, .2];
    newAssignment.stencilRef = 1;
    newAssignment.crudState = CrudState.CREATED;

    this.positionAdjustment.update(pa => {

      pa.clippingAssignments.push(newAssignment);

      return new PositionAdjustment(pa);
    })

    this.selectedClippingPlaneId.set(clippingPlane.id);
    this.Change.emit(this.positionAdjustment());
  }


  onDeleteClippingPlaneAssignment(target: ClippingPlaneAssignment) {

    const clippingAssignmentIndex = this.positionAdjustment().clippingAssignments
      .findIndex(cpa => cpa.adjustmentId === target.adjustmentId && cpa.clippingPlaneId === target.clippingPlaneId);

    if (-1 < clippingAssignmentIndex) {

      this.positionAdjustment.update(pa => {

        // Flag for deletion to be handled upstream
        const clippingAssignment = new ClippingPlaneAssignment(pa.clippingAssignments[clippingAssignmentIndex]);
        clippingAssignment.crudState = CrudState.DELETED
        pa.clippingAssignments[clippingAssignmentIndex] = clippingAssignment;

        return new PositionAdjustment(pa);
      });
    } else {

      this.logger.error('Not found target in assignments', target, this.positionAdjustment().clippingAssignments)
    }

    this.Change.emit(this.positionAdjustment());
  }


  onGoToPosition() {

    this.GoToPosition.emit(this.positionAdjustment().positionId);
  }


  private positionAdjstmentSelectedCallback = this.onPositionAdjustmentSelected.bind(this);
  private onPositionAdjustmentSelected(positionAdjustmentEvent: Event) {

    const event = positionAdjustmentEvent as CustomEvent<PositionAdjustmentSelectedEvent>;

    if (!this.isCopyActive()
      || !this.isActive()
      || 1 > event.detail.positionAdjustment.id
      || event.detail.positionAdjustment.id === this.positionAdjustment().id) {

      return;
    }
    this.isCopyActive.set(false);

    const newPositionAdjustment = new PositionAdjustment(event.detail.positionAdjustment);
    newPositionAdjustment.id = this.positionAdjustment().id;
    newPositionAdjustment.positionId = this.positionAdjustment().positionId;
    newPositionAdjustment.parentPropId = this.positionAdjustment().parentPropId;
    newPositionAdjustment.crudState = CrudState.CREATED === this.positionAdjustment().crudState ? CrudState.CREATED : CrudState.UPDATED;
    for (const clippingAssignment of newPositionAdjustment.clippingAssignments) {

      // If this is a new Adjustment its id is 0 until saved.
      clippingAssignment.adjustmentId = newPositionAdjustment.id;
      clippingAssignment.id = this._newId--;
      (clippingAssignment as ClippingPlaneAssignment).crudState = CrudState.CREATED;
    }

    // Make sure Vector3Input doesn't automatically change scale that has unequal values to equal BEFORE setting psotionAdjustment value.
    this.keepScaleEqual.set(newPositionAdjustment.scale[0] === newPositionAdjustment.scale[1] && newPositionAdjustment.scale[1] === newPositionAdjustment.scale[2]);

    // Give keep scale equal a cycle to set itself.
    setTimeout(() => {

      this.positionAdjustment.set(newPositionAdjustment);
      this.Change.emit(this.positionAdjustment());
    });
  }


  onSelect() {

    window.dispatchEvent(new CustomEvent<PositionAdjustmentSelectedEvent>(PositionAdjustmentSelectedEventType, {
      detail: {
        positionAdjustment: this.positionAdjustment()
      }
    }));
  }


  onSelectClippingPlaneAssignment(assignmentId: number) {

    this.selectedClippingPlaneId.set(assignmentId);
  }


  onToggleDisableDepth(): void {

    this.positionAdjustment.update(pa => {

      pa.disableDepth = !pa.disableDepth;

      return new PositionAdjustment(pa);
    })

    this.Change.emit(this.positionAdjustment());
  }


  onToggleHide(positionAdjustment: PositionAdjustment): void {

    positionAdjustment.hide = !positionAdjustment.hide;
    this.Change.emit(positionAdjustment);
  }


  selectTab(index: number): void {

    this.selectedTab.set(index);
    // this._router.navigate([],
    //   {
    //     relativeTo: this._route,
    //     queryParams: { p: `${index}` },
    //     queryParamsHandling: 'merge'
    //   })
  }


}
