import { EntityStatus } from "projects/my-common/src/model";

export function compareNumbers(n1: number, n2: number): number {
    if (n1 < n2) return -1;
    if (n1 === n2) return 0;
    return 1
}

export function compareYMD(d1: Date, d2: Date): number {

    let compareResult = compareNumbers(d1.getFullYear(), d2.getFullYear());

    if (0 !== compareResult) {
        
        return compareResult;
    }
    compareResult = compareNumbers(d1.getMonth(), d2.getMonth());
    if (0 !== compareResult) {
        
        return compareResult;
    }
    return compareNumbers(d1.getDate(), d2.getDate());
}


/**
 * example:  
 * private debounceXInput = debounce((xEvent: any) => {
 *     const newXValue = Number(xEvent.target.value);
 *     if (newXValue === this.x()) return;
 *     const newVector3 = new Vector3(newXValue, this.y(), this.z());
 *     this.OnChange.emit(newVector3);
 *   }, 500);
 * 
 * handleXInput(xEvent: any) {
 *     this.debounceXInput[0](xEvent);
 * }
 * @param fn 
 * @param ms 
 * @returns 
 */
export function debounce<A = unknown, R = void>(
    fn: (args: A) => R,
    ms: number
): [(args: A) => Promise<R>, () => void] {
    let timer: NodeJS.Timeout;

    const debouncedFunc = (args: A): Promise<R> =>
        new Promise((resolve) => {

            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                resolve(fn(args));
            }, ms);
        });

    const teardown = () => clearTimeout(timer);

    return [debouncedFunc, teardown];
}


export function fields<T>() {
    return new Proxy(
        {},
        {
            get: function (_target, prop, _receiver) {
                return prop;
            },
        }
    ) as {
        [P in keyof T]: P;
    };
};


export function isChildElement(element: Element, target: Element | null): boolean {

    if (!target) {
        
        return false;
    }
    if (target === element) {

        return true;
    }
    if (element.children.length === 0) {

        return false;
    }

    for (let i = 0; i < element.children.length; i++) {

        if (isChildElement(element.children[i], target)) {
            
            return true;
        }
    }

    return false;
}


export function isVector3Obj(obj: any): obj is Vector3Obj {

    if (obj && 'x' in obj && 'y' in obj && 'z' in obj) {
        
        return true;
    }
    return false;
}
export type Vector3Tuple = [x: number, y: number, z: number];
export type Vector3Obj = { x: number, y: number, z: number };
export const vector3ZeroObj = { x: 0, y: 0, z: 0 } as const;    // adding 'as const' makes the properties of the const readonly
export const vector3OneObj = { x: 1, y: 1, z: 1 } as const;     // adding 'as const' makes the properties of the const readonly
export function equals(expected: Vector3Tuple | Vector3Obj, actual: Vector3Tuple | Vector3Obj): boolean {

    if (Array.isArray(expected) && Array.isArray(actual)) {

        return expected[0] === actual[0] && expected[1] === actual[1] && expected[2] === actual[2];
    } else if (isVector3Obj(expected) && isVector3Obj(actual)) {

        return expected.x === actual.x && expected.y === actual.y && expected.z === actual.z;
    }

    return false;
}
export function vector3ObjFromTuple(array: Vector3Tuple): Vector3Obj {

    return { x: array[0], y: array[1], z: array[2] };
}

export function compareBool(b1: boolean, b2: boolean, trueFirst: boolean = true) {

    if (trueFirst) {
        
        return b1 === b2 ? 0 : b1 ? -1 : 1;
    }
    return b1 === b2 ? 0 : b1 ? 1 : -1;
}

export function compareEntityStatus(es1: EntityStatus, es2: EntityStatus, ascending: boolean = true) {

    if (ascending) {
        
        return es1 === es2 ? 0 : es1 ? -1 : 1;
    }
    return es1 === es2 ? 0 : es1 ? 1 : -1;
}


/**
 * Download the glb file.
 * @param blob 
 * @param filename 
 */
export function downloadBlob( blob: Blob | MediaSource, filename: string ) {

    const link = document.createElement( 'a' );

    link.style.display = 'none';
    link.href = URL.createObjectURL( blob );
    link.download = filename;
    link.click();

    // URL.revokeObjectURL( url ); breaks Firefox...
}


export function downloadStringAsBlob( text: any, filename: string ) {

    downloadBlob( new Blob( [text], { type: 'text/plain' } ), filename );
}


export function downloadBufferAsBinaryBlob( buffer: any, filename: string ) {

    downloadBlob( new Blob( [buffer], { type: 'application/octet-stream' } ), filename );
}


export function timeout(ms: number = 0) {

    return new Promise(resolve => setTimeout(resolve, ms));
}