import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { IntroductionComponent } from "../../components/introduction/introduction.component";
import { AboutComponent } from "../../components/about/about.component";
import { NavbarComponent } from "../../shared/components/top/navbar/navbar.component";
import { CartSidebarComponent } from "../../components/cart/cart-sidebar/cart-sidebar.component";
import { FooterComponent } from "../../shared/components/bottom/footer/footer.component";
import { AltFooterComponent } from "../../shared/components/bottom/alt-footer/alt-footer.component";
import { ToastComponent } from "../../shared/components/tools/toast/toast.component";

@Component({
    selector: 'app-main',
    standalone: true,
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    imports: [CommonModule, RouterOutlet, IntroductionComponent, AboutComponent, NavbarComponent, CartSidebarComponent, FooterComponent, AltFooterComponent, ToastComponent]
})
export class MainComponent { }
