import { Component, OnDestroy, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalRService } from 'src/app/core/service/signalR/signal-r.service';
import { Subscription } from 'rxjs';
import { ISignalREvent } from 'src/app/core/model/signal-r.model';
import { ToastService } from 'src/app/core/service/ui/toast/toast.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnDestroy {
  private _subscriptions: Subscription[] = [];
  readonly messages: WritableSignal<string[]> = signal([]);
  readonly toast: WritableSignal<boolean> = signal(false);

  duration: number = 5000;

  constructor(private readonly _toastService: ToastService,
    private readonly _signalRService: SignalRService,
    private readonly _logger: NGXLogger) {

    this._subscriptions.push(
      this._signalRService.messageReceived$
        .subscribe((event: ISignalREvent) => {
          this.messages.update(m => m.concat(event.message)) //m.push(event.message));
          this.showToast();
          setTimeout(() => { }, 600);
        })
    );

    this._subscriptions.push(
      this._toastService.toastReceived$
        .subscribe((message: string) => {

          this._logger.trace('Toast received');
          this.messages.update(m => m.concat(message)) //m.push(event.message));
          this.showToast();
          setTimeout(() => { }, 600);
        })
    );
  }


  private showToast() {
    
    this.toast.set(true);
    setTimeout(() => {
      this.hideToast();
    }, this.duration)
  }


  private hideToast() {
    if (2 > this.messages().length) {
      this.toast.set(false)
    }
    setTimeout(() => {
      this.messages.update(m => m.slice(0, m.length - 1)); //.splice(x.length - 1, 1))
    }, 600)
  }


  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }


}
