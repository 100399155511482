export const test: string = 'test';

export { GetSDK } from './Sdk';
//export { Frame } from './components/Frame';
export * from './SceneComponent';
export * from './sdk-components/CanvasRenderer';
export * from './sdk-components/OrientedBox';
export * from './sdk-components/SecurityCamera';
export * from './sdk-components/SceneRenderer';
export * from './sdk-components/Slot';
export * from './sdk-components/VideoStreamCapture';
export * from './sdk-components/NestThermostat';
export * from './sdk-components/RoomShadow';
export * from './sdk-components/ClockPainter';
export * from './sdk-components/SphereSource';
export * from './sdk-components/Tick';
export * from './sdk-components/MPObjectProp';
export * from './sdk-components/MPScene';

import { ORIENTED_BOX_COMPONENT_TYPE, makeOrientedBox } from './sdk-components/OrientedBox';
import { CAMERA_INPUT_COMPONENT_TYPE, makeCameraInput } from './sdk-components/Camera/CameraInput';
import { MpSdk } from 'static/sdk';
import { OBJECT_PROP_COMPONENT_TYPE, makeShowroomObjectProp } from './sdk-components/MPObjectProp';
import { TICK_COMPONENT_TYPE, makeTick } from './sdk-components/Tick';
import { SCENE_COMPONENT_TYPE, makeShowroomScene } from './sdk-components/MPScene';
import { makeScenePainter, scenePainterType } from './sdk-components/ScenePainter';


/**
 * mp-core and mp-common libraries are registered in tsconfig.json and angular.jason
 * https://www.youtube.com/watch?v=hIEbJkP1M5E
 * @param mpSdk 
 */
export const registerSceneComponentTypes = async (mpSdk: MpSdk) => {
  await Promise.all([
    mpSdk.Scene.register(ORIENTED_BOX_COMPONENT_TYPE, makeOrientedBox),
    mpSdk.Scene.register(TICK_COMPONENT_TYPE, makeTick),
    //mpSdk.Scene.register(SLOT_COMPONENT_TYPE, makeSlot),
    //mpSdk.Scene.register(securityCameraType, makeSecurityCamera),
    //mpSdk.Scene.register(LOADING_INDICATOR_COMPONENT_TYPE, makeLoadingIndicator),
    //mpSdk.Scene.register(CANVAS_RENDERER_COMPONENT_TYPE, makeCanvasRenderer),
    //mpSdk.Scene.register(sceneRendererType, makeSceneRenderer),
    //mpSdk.Scene.register(videoStreamCaptureType, makeVideoStreamCapture),
    //mpSdk.Scene.register(nestThermostatType, makeNestThermostat),
    //mpSdk.Scene.register(roomShadowType, makeRoomShadow),
    //mpSdk.Scene.register(clockPainterType, makeClockPainter),
    mpSdk.Scene.register(scenePainterType, makeScenePainter),
    //mpSdk.Scene.register(mjpegPlayerType, makeMjpegPlayer),
    //mpSdk.Scene.register(canvasBorderType, makeCanvasBorder),
    //mpSdk.Scene.register(CANVAS_TEXT_COMPONENT_TYPE, makeCanvasText),
    //mpSdk.Scene.register(CANVAS_IMAGE_COMPONENT_TYPE, makeCanvasImage),
    mpSdk.Scene.register(CAMERA_INPUT_COMPONENT_TYPE, makeCameraInput),
    //mpSdk.Scene.register(sphereSourceType, makeSphereSource(mpSdk)),
    //mpSdk.Scene.register(boxSourceType, makeBoxSource(mpSdk)),
    //mpSdk.Scene.register(cylinderSourceType, makeCylinderSource(mpSdk)),
    mpSdk.Scene.register(OBJECT_PROP_COMPONENT_TYPE, makeShowroomObjectProp),
    mpSdk.Scene.register(SCENE_COMPONENT_TYPE, makeShowroomScene)
  ]);
}

export const assetVersion = '1.0-2-g6b74572';
//export const cdnUrl = `https://static.matterport.com/showcase-sdk/examples/assets-${assetVersion}/assets`;
//export const cdnUrl = `https://stmedev.z13.web.core.windows.net`;

//export const sdkKey = '2d4dfb9fd6414902b663c25a6c767cfa';
export const sdkKey = '10a6bm7npnp814gnguhdiwgia';

export const interfaceVersion = '3.10';
