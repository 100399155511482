import { AnimationMixer, Object3D, Vector3 } from "three";
import { ImagePropEntity } from "./image-prop.entity";
import { Vector3Obj } from "../../util/utils";
import {
    ActiconBaseComponent, ActiconComponent, AdjustableComponent, ChildObjectEntityIdMap, DestinationPositionComponent, DestinationRotationComponent,
    DestinationScaleComponent, FromPositionComponent, FromRotationComponent, FromScaleComponent, IActiconState, PositionAdjustmentComponent, PositionComponent,
    RotationAdjustmentComponent, RotationComponent, ScaleAdjustmentComponent, ScaleComponent, DisplayPlaneComponent, object3dByEntityId,
    ActiconType,
    acticonChildrenEntityMap,
    IAdjustablePropEntity
} from "..";
import { getEidForAdjustableProp, removeAdjustmentsForProp } from "./adjustable.entity";
import {
    getEidForActiconByParentAndType, removeActiconChildEntityIdFromProp, updateActiconComponentValues, upsertActiconEntity
} from "./acticon.entity";
import { SnapshotAspectScaleAdjustmentComponent } from "../component/snapshot-aspect-scale-adjustment.component";
import { AdjustmentInitState } from "../system/adjustment-init.state";
import { IWorld, addComponent, addEntity, removeEntity } from "bitecs";
import { PropType } from "../../model";
import { VideoComponent } from "../component/video.component";
import { WorldState } from "../ecs-world.state";
import { ActiconPosition } from "../..";

/**
 * Extends the foundation established by ImagePropEntity.
 * The base ImageProp plane property represents the video snapshot plane
 */
export interface VideoPropEntity extends IAdjustablePropEntity  {
    
    //
    // From Image Prop Entity
    //
    acticonAlignment: ActiconPosition;
    acticonMargin: number;
    acticonZ: number;
    /**
     * Applies to the group
     */
    baseAspect: number;
    /**
     * The primary display aspect, either image or video
     */
    displayAspect: number;
    /**
     * The primary display aspect, either image or video
     */
    displayPlane: Object3D;
    position: Vector3Obj;   // base
    rotation: Vector3Obj;   // base
    scale: Vector3Obj;      // base
    // maskLoader: MaskLoaderComponent;
    // infoActicon: Object3D;
    // infoActiconAnimationDuration: number;
    // infoActiconPerpendicularMargin: number;
    // infoActiconScale: Vector3Obj;
    // infoActiconSize: Vector3;
    nextActicon: Object3D;
    nextActiconAnimationDuration: number;
    nextActiconAnimationMixer?: AnimationMixer;
    nextActiconPerpendicularMargin: number;
    nextActiconScale: Vector3Obj;
    nextActiconSize: Vector3;
    planeGroup: Object3D;
    // 
    // End from Image Prop entity
    //

    snapshotPlane: Object3D;
    pauseActicon: Object3D;
    pauseActiconAnimationDuration: number;
    pauseActiconPerpendicularMargin: number;
    pauseActiconScale: Vector3Obj;
    pauseActiconSize: Vector3;
    playActicon: Object3D;
    playActiconAnimationDuration: number;
    playActiconPerpendicularMargin: number;
    playActiconScale: Vector3Obj;
    playActiconSize: Vector3;
    snapshotAspect: number;
}

export const snapshotPlaneEidByParentEid: ChildObjectEntityIdMap = {}


export function removeVideoProp(world: IWorld, videoPropId: number): void {

    const eidForAdjustable = getEidForAdjustableProp(world, videoPropId, PropType.VIDEO);
    if (0 > eidForAdjustable) {

        WorldState.logger.error(`Video Prop id: ${videoPropId} not found`);
        return;
    }

    removeAdjustmentsForProp(world, videoPropId, PropType.IMAGE);

    // Find the Acticon entity id
    for (let eidForActicon of acticonChildrenEntityMap[eidForAdjustable]) {

        removeActiconChildEntityIdFromProp(world, eidForAdjustable, eidForActicon);
    }

    // Remove reference
    delete object3dByEntityId[eidForAdjustable];
    // delete maskLoaderByEntityId[eidForAdjustable];
    delete object3dByEntityId[snapshotPlaneEidByParentEid[eidForAdjustable]];
    delete object3dByEntityId[DisplayPlaneComponent.eid[eidForAdjustable]];

    removeEntity(world, eidForAdjustable);
}


/**
 * Update component values for Video Prop entity.
 * @param videoEntity 
 */
function updateVideoPropComponentValues(world: IWorld, videoEntity: VideoPropEntity, eidForAdjustableProp: number) {

    object3dByEntityId[eidForAdjustableProp] = videoEntity.planeGroup;
    // maskLoaderByEntityId[eidForVideoProp] = videoEntity.maskLoader;
    object3dByEntityId[snapshotPlaneEidByParentEid[eidForAdjustableProp]] = videoEntity.snapshotPlane;
    object3dByEntityId[DisplayPlaneComponent.eid[eidForAdjustableProp]] = videoEntity.displayPlane;

    upsertActiconEntity(world, eidForAdjustableProp, videoEntity.nextActicon, ActiconType.Next);
    upsertActiconEntity(world, eidForAdjustableProp, videoEntity.pauseActicon, ActiconType.Pause);
    upsertActiconEntity(world, eidForAdjustableProp, videoEntity.playActicon, ActiconType.Play);

    // Update base component values.
    AdjustableComponent.aspect[eidForAdjustableProp] = videoEntity.baseAspect;
    PositionComponent.x[eidForAdjustableProp] = videoEntity.position.x;
    PositionComponent.y[eidForAdjustableProp] = videoEntity.position.y;
    PositionComponent.z[eidForAdjustableProp] = videoEntity.position.z;
    RotationComponent.x[eidForAdjustableProp] = videoEntity.rotation.x;
    RotationComponent.y[eidForAdjustableProp] = videoEntity.rotation.y;
    RotationComponent.z[eidForAdjustableProp] = videoEntity.rotation.z;
    ScaleComponent.x[eidForAdjustableProp] = videoEntity.scale.x;
    ScaleComponent.y[eidForAdjustableProp] = videoEntity.scale.y;
    ScaleComponent.z[eidForAdjustableProp] = videoEntity.scale.z;

    ActiconBaseComponent.acticonAlignment[eidForAdjustableProp] = videoEntity.acticonAlignment;
    DisplayPlaneComponent.aspect[eidForAdjustableProp] = videoEntity.displayAspect;
    ActiconBaseComponent.acticonMargin[eidForAdjustableProp] = videoEntity.acticonMargin;
    ActiconBaseComponent.acticonZ[eidForAdjustableProp] = videoEntity.acticonZ;

    // Update Acticon component values
    let eidForActicon = getEidForActiconByParentAndType(eidForAdjustableProp, ActiconType.Next);
    if (eidForActicon) {

        const resetAnimationTimer = videoEntity.nextActiconScale.x !== ActiconComponent.scale.x[eidForActicon]
            || videoEntity.nextActiconScale.y !== ActiconComponent.scale.y[eidForActicon]
            || videoEntity.nextActiconScale.z !== ActiconComponent.scale.z[eidForActicon];
        const acticonState: IActiconState = {
            animationDuration: videoEntity.nextActiconAnimationDuration,
            animationTimer: resetAnimationTimer ? 0 : ActiconComponent.animationTimer[eidForActicon],
            perpendicularMargin: videoEntity.nextActiconPerpendicularMargin,
            scaleFrom: {
                x: resetAnimationTimer ? videoEntity.nextActicon.scale.x : ActiconComponent.scaleFrom.x[eidForActicon],
                y: resetAnimationTimer ? videoEntity.nextActicon.scale.y : ActiconComponent.scaleFrom.y[eidForActicon],
                z: resetAnimationTimer ? videoEntity.nextActicon.scale.z : ActiconComponent.scaleFrom.z[eidForActicon]
            },
            scaleTo: {
                x: videoEntity.nextActiconScale.x,
                y: videoEntity.nextActiconScale.y,
                z: videoEntity.nextActiconScale.z
            },
            size: {
                h: videoEntity.nextActiconSize.y / 2,
                w: videoEntity.nextActiconSize.x / 2
            },
            sort: 1
        }
        updateActiconComponentValues(eidForAdjustableProp, eidForActicon, acticonState);
    }

    eidForActicon = getEidForActiconByParentAndType(eidForAdjustableProp, ActiconType.Pause);
    if (eidForActicon) {

        const resetAnimationTimer = videoEntity.pauseActiconScale.x !== ActiconComponent.scale.x[eidForActicon]
            || videoEntity.pauseActiconScale.y !== ActiconComponent.scale.y[eidForActicon]
            || videoEntity.pauseActiconScale.z !== ActiconComponent.scale.z[eidForActicon];
        const acticonState = {
            animationDuration: videoEntity.pauseActiconAnimationDuration,
            animationTimer: resetAnimationTimer ? 0 : ActiconComponent.animationTimer[eidForActicon],
            perpendicularMargin: videoEntity.pauseActiconPerpendicularMargin,
            scaleFrom: {
                x: resetAnimationTimer ? videoEntity.pauseActicon.scale.x : ActiconComponent.scaleFrom.x[eidForActicon],
                y: resetAnimationTimer ? videoEntity.pauseActicon.scale.y : ActiconComponent.scaleFrom.y[eidForActicon],
                z: resetAnimationTimer ? videoEntity.pauseActicon.scale.z : ActiconComponent.scaleFrom.z[eidForActicon]
            },
            scaleTo: {
                x: videoEntity.pauseActiconScale.x,
                y: videoEntity.pauseActiconScale.y,
                z: videoEntity.pauseActiconScale.z
            },
            size: {
                h: videoEntity.pauseActiconSize.y / 2,
                w: videoEntity.pauseActiconSize.x / 2
            },
            sort: 0
        }

        updateActiconComponentValues(eidForAdjustableProp, eidForActicon, acticonState);
    }

    eidForActicon = getEidForActiconByParentAndType(eidForAdjustableProp, ActiconType.Play);
    if (eidForActicon) {

        const resetAnimationTimer = videoEntity.playActiconScale.x !== ActiconComponent.scale.x[eidForActicon]
            || videoEntity.playActiconScale.y !== ActiconComponent.scale.y[eidForActicon]
            || videoEntity.playActiconScale.z !== ActiconComponent.scale.z[eidForActicon];
        const acticonState = {
            animationDuration: videoEntity.playActiconAnimationDuration,
            animationTimer: resetAnimationTimer ? 0 : ActiconComponent.animationTimer[eidForActicon],
            perpendicularMargin: videoEntity.playActiconPerpendicularMargin,
            scaleFrom: {
                x: resetAnimationTimer ? videoEntity.playActicon.scale.x : ActiconComponent.scaleFrom.x[eidForActicon],
                y: resetAnimationTimer ? videoEntity.playActicon.scale.y : ActiconComponent.scaleFrom.y[eidForActicon],
                z: resetAnimationTimer ? videoEntity.playActicon.scale.z : ActiconComponent.scaleFrom.z[eidForActicon]
            },
            scaleTo: {
                x: videoEntity.playActiconScale.x,
                y: videoEntity.playActiconScale.y,
                z: videoEntity.playActiconScale.z
            },
            size: {
                h: videoEntity.playActiconSize.y / 2,
                w: videoEntity.playActiconSize.x / 2
            },
            sort: 0
        }
        updateActiconComponentValues(eidForAdjustableProp, eidForActicon, acticonState);
    }

    if (0 < videoEntity.displayAspect && videoEntity.displayAspect < videoEntity.baseAspect) {

        DisplayPlaneComponent.scaleFactor[eidForAdjustableProp] = videoEntity.displayAspect / videoEntity.baseAspect;
    } else {

        DisplayPlaneComponent.scaleFactor[eidForAdjustableProp] = 1; // Does nothing when multiplies with scale.
    }

    //WorldState.logger.error(`Video display aspect adjustent: ${DisplayAspectScaleAdjustmentComponent.scaleFactor[eidForVideoProp]}`);

    if (0 < videoEntity.snapshotAspect && videoEntity.snapshotAspect < videoEntity.baseAspect) {

        SnapshotAspectScaleAdjustmentComponent.scaleFactor[eidForAdjustableProp] = videoEntity.snapshotAspect / videoEntity.baseAspect;
    } else {

        SnapshotAspectScaleAdjustmentComponent.scaleFactor[eidForAdjustableProp] = 1; // Does nothing when multiplies with scale.
    }
    //WorldState.logger.trace(`Prop aspect: ${videoProp.propAspect}, Video aspect: ${videoProp.videoAspect}, Aspect adjustment: ${VideoAspectScaleAdjustmentComponent.scaleFactor[entityId]}`)

    // Invalidate position id to force analysis of adjustments.
    AdjustmentInitState.currentShowroomPositionId = -1;
}


/**
 * Register ImageProp objects in the ecs system.
 * @param videoProp 
 */
export function upsertVideoProp(world: IWorld, videoProp: VideoPropEntity) {

    let eidForAdjustableProp = getEidForAdjustableProp(world, videoProp.id, PropType.VIDEO);

    if (0 > eidForAdjustableProp) {

        // Define an entity in ecs for the object.
        do eidForAdjustableProp = addEntity(world)
        while (!eidForAdjustableProp || 0 > eidForAdjustableProp);

        // Assume we're focusing on adjustable props like image or video.
        addComponent(world, AdjustableComponent, eidForAdjustableProp);
        addComponent(world, VideoComponent, eidForAdjustableProp);       // Not used. Needed?

        AdjustableComponent.id[eidForAdjustableProp] = videoProp.id;
        AdjustableComponent.type[eidForAdjustableProp] = PropType.VIDEO;

        // Record reference to primary, parent 3dObject
        object3dByEntityId[eidForAdjustableProp] = videoProp.planeGroup;
        // Record reference to related Mask Loader component
        // maskLoaderByEntityId[eidForVideoProp] = videoProp.maskLoader;

        // Create entity Id for snapshot plane
        let eidForSnapshotPlane: number;
        do eidForSnapshotPlane = addEntity(world)
        while (!eidForSnapshotPlane || 0 > eidForSnapshotPlane);

        // Record reference to snapshot plane 3dObject
        object3dByEntityId[eidForSnapshotPlane] = videoProp.snapshotPlane;
        // Record relationship between snapshot plane and video entity
        snapshotPlaneEidByParentEid[eidForAdjustableProp] = eidForSnapshotPlane;

        let eidForVideoPlane: number;
        do eidForVideoPlane = addEntity(world)
        while (!eidForVideoPlane || 0 > eidForVideoPlane);

        object3dByEntityId[eidForVideoPlane] = videoProp.displayPlane;
        DisplayPlaneComponent.eid[eidForAdjustableProp] = eidForVideoPlane;

        addComponent(world, DisplayPlaneComponent, eidForAdjustableProp);
        addComponent(world, PositionAdjustmentComponent, eidForAdjustableProp);
        addComponent(world, FromPositionComponent, eidForAdjustableProp);
        addComponent(world, DestinationPositionComponent, eidForAdjustableProp);
        addComponent(world, PositionComponent, eidForAdjustableProp);
        addComponent(world, RotationComponent, eidForAdjustableProp);
        addComponent(world, RotationAdjustmentComponent, eidForAdjustableProp);
        addComponent(world, FromRotationComponent, eidForAdjustableProp);
        addComponent(world, DestinationRotationComponent, eidForAdjustableProp);
        addComponent(world, ScaleComponent, eidForAdjustableProp);
        addComponent(world, ScaleAdjustmentComponent, eidForAdjustableProp);
        addComponent(world, DestinationScaleComponent, eidForAdjustableProp);
        addComponent(world, FromScaleComponent, eidForAdjustableProp);
        addComponent(world, SnapshotAspectScaleAdjustmentComponent, eidForAdjustableProp);

        addComponent(world, ActiconBaseComponent, eidForAdjustableProp);

        let eidForActicon = upsertActiconEntity(world, eidForAdjustableProp, videoProp.nextActicon, ActiconType.Next);
        ActiconComponent.scaleFrom.x[eidForActicon] = videoProp.nextActicon.scale.x;
        ActiconComponent.scaleFrom.y[eidForActicon] = videoProp.nextActicon.scale.y;
        ActiconComponent.scaleFrom.z[eidForActicon] = videoProp.nextActicon.scale.z;

        eidForActicon = upsertActiconEntity(world, eidForAdjustableProp, videoProp.pauseActicon, ActiconType.Pause);
        ActiconComponent.scaleFrom.x[eidForActicon] = videoProp.pauseActicon.scale.x;
        ActiconComponent.scaleFrom.y[eidForActicon] = videoProp.pauseActicon.scale.y;
        ActiconComponent.scaleFrom.z[eidForActicon] = videoProp.pauseActicon.scale.z;

        eidForActicon = upsertActiconEntity(world, eidForAdjustableProp, videoProp.playActicon, ActiconType.Play);
        ActiconComponent.scaleFrom.x[eidForActicon] = videoProp.playActicon.scale.x;
        ActiconComponent.scaleFrom.y[eidForActicon] = videoProp.playActicon.scale.y;
        ActiconComponent.scaleFrom.z[eidForActicon] = videoProp.playActicon.scale.z;
    }

    updateVideoPropComponentValues(world, videoProp, eidForAdjustableProp);
}