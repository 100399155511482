import { PropInputs } from "./matterport.model";
import { ISubscription, MpSdk, Scene } from "static/sdk";
import { ComponentInteractionType } from "projects/mp-common/src";
import { IShowroomImageProp } from "../showroom/image-prop.model";
import { IStage } from "../showroom/showroom.model";
import { DestroyRef } from '@angular/core';
import { Subscription, Subject } from "rxjs";
import {
  GltfManager, MaskLoaderComponent, PlayListComponent, TextureLoaderComponent, TextureManager,
  getLogger, Vector3Obj, vector3OneObj, vector3ZeroObj, ActiconComponent, PlaneRendererComponent, MaskLoaderSource,
  TransformMode, TransformSpace, ActiconAnimationState, MaterialDataLoaderComponent, ActiconType, MyOptyxComponent
} from "projects/my-common/src";
import { MPObjectPropComponent, OBJECT_PROP_COMPONENT_TYPE } from "projects/mp-common/src/sdk-components/MPObjectProp";
import { Euler, Texture, Vector3 } from "three";
import { ImagePropEntity, EcsWorld } from "projects/my-common/src/ecs";
import {
  ClippingPlaneAssignment, CrudState, DesignImage, ImageAssignment, ImageProp, ImagePropOptions,
  PositionAdjustment, PropType
} from "projects/my-common/src/model";
import { ShowroomMode } from "src/app/state/showroom-state";
import { PropAdjustment } from "projects/my-common/src/scene/prop-adjustment";
import { transitionAnimationComplete$ } from "projects/my-common/src/ecs/system/transition-animation.system";


export class ImageNodeInputs extends PropInputs {
  public textureUrl = '';
  public planeRendererAspect = 1;
  public planeRendererScale = vector3OneObj;
  public planeRendererPosition = vector3ZeroObj;
  public planeRendererTransparent = false;
  public maskLoaderSrc = '';
}

/**
 * The integrated MyOptyx and Matterport components required to implement a Showroom ImageProp.
 * Maintains the current state of the Image Prop.
 * Base position is set at the node. Position adjustments are applied locally on the plane object.
 * Scale and rotation base and adjustments are applied locally on the plane object.
 */
export class ImagePropNode implements IShowroomImageProp {

  propId = 0;
  readonly type = PropType.IMAGE;

  /**
   * Configuration properties
   */
  inputs!: ImageNodeInputs;
  /**
   * Core Matterport node. Base Prop position, scale, rotation/quaternion.
   */
  node!: Scene.INode;
  /**
   * Visible representation of the image. Controls aspect, scale, visibility, alphaMap.
   */
  imageDisplay!: PlaneRendererComponent;
  planeRendererProp!: MPObjectPropComponent;
  /**
   * Retrives, caches, updates textures for plane renderer. Triggers planeRenderer updates.
   */
  textureLoader!: TextureLoaderComponent;
  /**
   * Retrives, caches, updates masks for plane renderer. Triggers planeRenderer updates.
   */
  maskLoader!: MaskLoaderComponent;
  /**
   * Position adjustments to be applied to Prop based on the players PositionId.
   */
  adjustments: PropAdjustment[] = [];
  /**
   * For Acticons
   */
  materialDataLoader!: MaterialDataLoaderComponent;
  /**
   * Control acticons
   */
  infoActicon!: ActiconComponent;
  infoActiconProp!: MPObjectPropComponent;
  nextActicon!: ActiconComponent;
  nextActiconProp!: MPObjectPropComponent;

  /**
   * Manage and communicate current Image Assignment from available Assignments
   */
  assignmentPlayList!: PlayListComponent<ImageAssignment>;

  private readonly _logger = getLogger();
  private readonly _subscriptions: Subscription[] = [];
  private readonly _iSubscriptions: ISubscription[] = [];

  // Events
  private _imagePropClickedSource = new Subject<number>();
  imagePropClicked$ = this._imagePropClickedSource.asObservable();
  private _assignmentChangedSource = new Subject<ImageAssignment>();
  assignmentChanged$ = this._assignmentChangedSource.asObservable();

  private _enableAssignmentChangeNotification: boolean = true;


  /**
   * Track DesignImages to broadcast selected DesignImage events
   */
  private _designImages?: DesignImage[];
  private _transitioning = false;

  private readonly _acticonPressedSource = new Subject<boolean>();
  readonly acticonPressed$ = this._acticonPressedSource.asObservable();
  private readonly _positionChanged = new Subject<Vector3>();
  readonly positionChanged$ = this._positionChanged.asObservable();
  private readonly _rotationChanged = new Subject<Euler>();
  readonly rotationChanged$ = this._rotationChanged.asObservable();
  private readonly _scaleChanged = new Subject<Vector3>();
  readonly scaleChanged$ = this._scaleChanged.asObservable();

  private _myOptyxComponents: MyOptyxComponent[] = [];

  private _transforming = false;
  public get transforming(): boolean {

    return this._transforming;
  }

  get currentAssignment(): ImageAssignment | undefined {

    return this._activeTextureAssignment ? this._activeTextureAssignment : this.assignmentPlayList.getCurrentItem();
  }


  constructor(readonly destroyRef: DestroyRef,
    private imageProp: ImageProp,
    private readonly scene: MpSdk.Scene.IObject,
    private readonly textureManager: TextureManager,
    private readonly gltfManager: GltfManager,
    private readonly stage: IStage,
    private readonly world: EcsWorld) {

    destroyRef.onDestroy(() => this.onDestroy());

    this.propId = imageProp.id;
    this.scene = scene;
    this.node = scene.addNode();
    this.textureManager = textureManager;
    this.gltfManager = gltfManager;

    // The Matterport component should be initialized first
    this.addMPComponents();
    this.node.start();

    // Followed by MyOptyx components and binding definitions
    this.addComponents();
    this.createBindings();

    // Initialie MyOptyx components in dependency order
    this.imageDisplay.init();

    //this.infoActicon.init();
    this.nextActicon.init();
    this.maskLoader.init();

    this.textureLoader.init();
    this.assignmentPlayList.init();

    this.applyPropInputs(imageProp);

    this.createInteractionEventSpies();

    this.updateWorld();
  }


  private addComponents(): void {

    // The image display
    this._myOptyxComponents.push(this.imageDisplay = new PlaneRendererComponent(this.destroyRef, this.textureManager.three, this.world));
    this._subscriptions.push(this.imageDisplay.groupCreated$.subscribe((gltf) => this.planeRendererProp.inputs.object = gltf));
    this._subscriptions.push(this.imageDisplay.enableInteractionUpdated$.subscribe((isEnabled) => this.planeRendererProp.inputs.enableInteraction = isEnabled));

    // Image display texture management
    this._myOptyxComponents.push(this.textureLoader = new TextureLoaderComponent(this.destroyRef, this.textureManager));
    this._subscriptions.push(this.textureLoader.textureLoaded$.subscribe(texture =>

      1 < this.assignmentPlayList.pendingState.playListItems.length ?
        this.handleTextureLoadedMulti(texture) :
        this.handleTextureLoaded(texture)
    ));

    // Image display mask management
    this._myOptyxComponents.push(this.maskLoader = new MaskLoaderComponent(this.destroyRef, this.textureManager));
    this._subscriptions.push(
      this.maskLoader.alphaMapUpdated$.subscribe(alphaMap => {

        this.imageDisplay.pendingState.alphaMap = alphaMap;
        this.imageDisplay.apply();
      })
    );

    // For Acticons
    this._myOptyxComponents.push(this.materialDataLoader = new MaterialDataLoaderComponent(this.destroyRef, this.textureManager).init())

    // Control to transition among multiple images
    // this.infoActicon = new ActiconComponent(this.destroyRef, this.gltfManager, this.materialDataLoader, ActiconType.Info);
    // this._subscriptions.push(this.infoActicon.gltfUpdated$.subscribe((gltf) => this.infoActiconProp.inputs.object = gltf));
    // this._subscriptions.push(this.infoActicon.sizeUpdated$.subscribe((size) => this.updateWorld()));
    this._myOptyxComponents.push(this.nextActicon = new ActiconComponent(this.destroyRef, this.gltfManager, this.materialDataLoader, ActiconType.Next));
    this._subscriptions.push(this.nextActicon.animationUpdated$.subscribe((animationMixer) => this.updateWorld()));
    this._subscriptions.push(this.nextActicon.gltfUpdated$.subscribe((gltf) => this.nextActiconProp.inputs.object = gltf));
    this._subscriptions.push(this.nextActicon.sizeUpdated$.subscribe((size) => this.updateWorld()));

    // Multi-image coordination
    this._myOptyxComponents.push(this.assignmentPlayList = new PlayListComponent(this.destroyRef));
    // Handle changes in current Image Assignment
    this._subscriptions.push(
      this.assignmentPlayList.source$.subscribe((currentAssignment) => {

        // If current assignment is undefined then pass that information downstream and return
        if (!currentAssignment) {

          this.textureLoader.pendingState.source = undefined;
          this.textureLoader.pendingState.textTexture = undefined;
          this.textureLoader.apply();
          return;
        }

        // Apply current assignment interaction setting
        this.imageDisplay.pendingState.enableInteraction = currentAssignment.enableInteraction;
        this.imageDisplay.apply();

        const designImage = this._designImages?.find(di => di.id === currentAssignment.designImageId);
        // If there is no design image for current assignment then treat the assignment as text texture and return
        if (!designImage) {

          this.textureLoader.pendingState.source = undefined;
          this.textureLoader.pendingState.textTexture = {

            font: currentAssignment.font,
            fontSize: currentAssignment.fontSize,
            text: currentAssignment.text
          }

          this._whenTextureIsLoaded = currentAssignment;
          this.textureLoader.apply();

          return;
        }

        // If the design image has changed then load it and return
        if (this.textureLoader.pendingState.source !== designImage.imageUrl) {

          // Set current assignment flag for broadcast after the image is loaded
          this._whenTextureIsLoaded = currentAssignment;
          this.textureLoader.pendingState.source = designImage.imageUrl;
          this.textureLoader.apply();
        } else {

          // Apply current assignment setting to the existing design image
          this.imageDisplay.pendingState.stretchToFit = currentAssignment.stretchToFit;
          this.imageDisplay.apply();

          this.updateWorld();

          /**
           * A Design Image can be used in multiple Props. Broadcasting its selection is not helpful in determining which Prop or Assignment
           * is the focus of the activity.
           * Broadcasting Prop select implies an Interaction with the Prop which is not the case when the Acticon is clicked.
           * The best value to broadcast is Image Assignment which is unique and references the specific context including Prop and the Design.
           */
          if (!this.assignmentPlayList.firstDisplay) {

            if (this._enableAssignmentChangeNotification) {

              this._assignmentChangedSource.next(currentAssignment);
            }
          }
          this._enableAssignmentChangeNotification = true;
        }

        // Cancel Next Acticon pressed state after giving time for pressed animation to complete.
        // if (ActiconAnimationState.Pressed === this.nextActicon.getAnimationState()) {

        //   setTimeout(() => {
        //     this.nextActicon.pressed(false);
        //     this.updateWorld();
        //   }, 450);
        // }
      })
    );
  }

  private _whenTextureIsLoaded?: ImageAssignment;


  /**
   * Initialize Matterport components.
   * Transforms are applied to the object provided. The proper application of the transforms might need to be be mapped.
   */
  private addMPComponents() {

    this.planeRendererProp = this.node.addComponent(OBJECT_PROP_COMPONENT_TYPE, {}) as MPObjectPropComponent;
    // When transform changes the Plane group's position, rotation and scale broadcast it so that inputs and UI will be updated.
    this._subscriptions.push(this.planeRendererProp.positionUpdated$.subscribe((position) => this._positionChanged.next(position)));
    this._subscriptions.push(this.planeRendererProp.rotationUpdated$.subscribe((rotation) => this._rotationChanged.next(rotation)));;
    this._subscriptions.push(this.planeRendererProp.scaleUpdated$.subscribe((scale) => this._scaleChanged.next(scale)));

    // Initiation of a transform change
    this._subscriptions.push(this.planeRendererProp.transformMouseDown$.subscribe((event) => {

      this._transforming = true;
      this.world.stop();    // Transform is taking over

      // Capture original values before transform.
      // switch (this.planeRendererProp.getTransformMode()) {

      //   case TransformMode.TRANSLATE: 
      //     break;
      //   case TransformMode.ROTATE:
      //     break;
      //   case TransformMode.SCALE:
      //     break;
      // }
    }));

    // Completion of a transform change.
    this._subscriptions.push(this.planeRendererProp.transformMouseUp$.subscribe((event) => {

      // Apply/map transforms as required and reset transform from original values if necessary.
      // switch (this.planeRendererProp.getTransformMode()) {

      //   case TransformMode.TRANSLATE:
      //     break;
      //   case TransformMode.ROTATE:
      //     break;
      //   case TransformMode.SCALE:
      //     break;
      // }

      this.updateWorld();
      this._transforming = false;
      this.world.start();   // Restore ECS control
    }));

    this.infoActiconProp = this.node.addComponent(OBJECT_PROP_COMPONENT_TYPE, {}) as MPObjectPropComponent;
    this.nextActiconProp = this.node.addComponent(OBJECT_PROP_COMPONENT_TYPE, {}) as MPObjectPropComponent;
  }


  /**
   * Apply input values that are not otherwise updated elsewhere.
   */
  private applyDefaultInputs(): void {

    if (!this.inputs) {

      this.inputs = new ImageNodeInputs();
    }
    const inputs = this.inputs;

    this.imageDisplay.pendingState.transparent = inputs.planeRendererTransparent;
    this.imageDisplay.pendingState.visible = true;
    this.imageDisplay.apply();
  }


  /**
   * Applies position adjustment to ImageProp based upon specified positionId.
   * If position adjustemnt is not found then default inputs are applied.
   * @param positionId 
   */
  applyPositionAdjustment(): void {

    const positionId = this.stage.currentPlayerPosition?.id;

    if (!positionId || 1 > this.adjustments.length) {

      this.restoreBaseImagePropPosition();
      return;
    }

    const positionAdjustment = this.adjustments.find(pa => pa.playerPositionId === positionId);

    if (positionAdjustment) {

      if (0 < positionAdjustment.maskUrl.length) {

        this.maskLoader.pendingState.enabled = true;
        this.maskLoader.pendingState.source = positionAdjustment.maskUrl;
        this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.ADJUSTMENT;
      } else {

        this.maskLoader.pendingState.enabled = 0 < this.imageProp.maskUrl.length;
        this.maskLoader.pendingState.source = this.inputs.maskLoaderSrc;
        this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.PROP;
      }
      this.maskLoader.apply();

      this.imageDisplay.pendingState.disableDepth = positionAdjustment.disableDepth;
      this.imageDisplay.pendingState.renderOrder = positionAdjustment.renderOrder;
      this.imageDisplay.pendingState.stencilRef = positionAdjustment.stencilRef;
      this.imageDisplay.pendingState.visible = !positionAdjustment.hide;
      this.imageDisplay.apply();

    } else {

      this.restoreBaseImagePropPosition()
    }

    if (!positionAdjustment || 1 > positionAdjustment.clippingAssignments.length) {

      // Evaluate Prop level clipping plane assignments
      this.applyPropLevelClippingAssignments();
    }

    this.updateWorld();
  }


  /**
   * Clipping assignments are handled by ECS through Position Adjustments.
   * Prop level assignments need to be applied there.
   */
  private applyPropLevelClippingAssignments() {

    const clippingAssignments: ClippingPlaneAssignment[] = [];
    for (const clippingPlane of this.imageProp.clippingPlanes) {

      if (clippingPlane.activatePropLevel) {

        const assignment = new ClippingPlaneAssignment(clippingPlane);
        assignment.id = assignment.clippingPlaneId = clippingPlane.id;  // Id gets swapped on save.
        assignment.crudState = CrudState.CREATED;
        clippingAssignments.push(assignment)
      }
    }
  }


  private applyPropInputs(imageProp: ImageProp, imageAssignments?: ImageAssignment[], designImages?: DesignImage[]): void {

    this.imageProp = imageProp;
    this._designImages = designImages;
    if (!this.inputs) {

      this.applyDefaultInputs();
    }
    const inputs = this.inputs;

    // Base position is used to calculate whether or not Acticon should be shown
    // this.infoActicon.pendingState.position = new Vector3(imageProp.positionObj.x, imageProp.positionObj.y, imageProp.positionObj.z);
    // this.infoActicon.pendingState.enable = true;
    // this.infoActicon.apply();

    this._logger.trace('imageProp, imageAssignments, designImages', imageProp, imageAssignments, designImages);
    this.updatePropInputs();

    if (0 < imageProp.maskUrl.length) {

      this.maskLoader.pendingState.enabled = true;
    }
    this.maskLoader.pendingState.source = inputs.maskLoaderSrc = imageProp.maskUrl;
    this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.PROP;
    this.maskLoader.apply();

    this.textureLoader.pendingState.aspect = this.imageProp.aspect;
    this.initializeTransition();
    if (imageAssignments && 0 < imageAssignments.length) {

      const currentPlaylistAssignment = this.assignmentPlayList.getCurrentItem();

      this.assignmentPlayList.pendingState.autoNext = true;
      this.assignmentPlayList.pendingState.playListItems = imageAssignments;
      if (currentPlaylistAssignment && imageAssignments.some(ia => ia.id === currentPlaylistAssignment.id)) {

        this.assignmentPlayList.pendingState.selectItem = currentPlaylistAssignment;
      }
      this.assignmentPlayList.apply();

      this.nextActicon.pendingState.position = new Vector3(imageProp.positionObj.x, imageProp.positionObj.y, imageProp.positionObj.z);
    } else {

      this._enableAssignmentChangeNotification = true;
      this.assignmentPlayList.pendingState.autoNext = false;
      this.assignmentPlayList.pendingState.playListItems = [];
      this.assignmentPlayList.apply();

      this.textureLoader.pendingState.source = ''; // TODO: Could be system default  
    }
    this.textureLoader.apply();

    this.nextActicon.pendingState.enable = 1 < (imageAssignments?.length ?? 0);
    this.nextActicon.apply();

    this.applyPositionAdjustment();
  }


  /**
   * The transform tool takes over the position, scale and rotation of the group containing the ImageProp's Object3D objects (including Edges).
   * When transforming is complete (i.e. mouse up), the updated input values are reported to world maintains the transforms on that group.
   * Position changes are tracked and mapped to the Node upon completion of the transform.
   * @param mode 
   * @param space 
   */
  attachTransformControls(mode: TransformMode, space: TransformSpace) {

    this.planeRendererProp.attachTransformControls(mode, space);
  }


  /**
   * Additional dependencies this node depend on.
   */
  private createBindings(): void {

    this._subscriptions.push(
      this.stage.transitionStarted$.subscribe((transition) => {

        // Sometime Image Props are "zoomed" close to camera to maintain an illusion that would otherwise be blocked by hidden artifacts in the virtual space.
        // A side effect of zooming is the Image Prop can cover Object Props positioned behind them that we don't want to hide.
        // Setting depthWrite to false avoids this but also ruins the Image Pprop transition effects.
        // So we set deptWrite = true (the default) during transitions and can optionally return it to false after the transition when needed to resolve the side effect.
        //this.imageDisplay.setDepthWrite(true);

        // Custom masks create 'holes' in Image Props to make it appear they are behind virtual space items that don't exist.
        // Those holes do not look right during the transition. 
        // This flag works in tandem with custom mask loading to insure the custom mask is not displayed until the transition is complete.
        this._transitioning = true;

        // Disable custom masks during transitions to hide the 'holes' it creates in the texture.
        // Only disable custom masks sourced from Position Adjustments as they vary from Adjustment to Adjustment.
        // Fixed masks assigned to the Image Prop can remain visible during the transition because the look natural and maintain the illusion.
        if (MaskLoaderSource.ADJUSTMENT === this.maskLoader.pendingState.maskOrigin) {

          this.maskLoader.pendingState.suppressCustomMask = true;
        }

        const toPositionAdjustment = this.adjustments.find(pa => pa.playerPositionId === transition.destinationPositionId);

        // Switching the mask source here invokes the mask update async process which clears the mask during the transition 
        // in time for the destination mask, if any, to appear at the end of the transition.
        if (toPositionAdjustment && toPositionAdjustment.maskUrl && 0 < toPositionAdjustment.maskUrl.length) {

          this.maskLoader.pendingState.enabled = true;
          this.maskLoader.pendingState.source = toPositionAdjustment.maskUrl;
          this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.ADJUSTMENT;
        } else {

          this.maskLoader.pendingState.enabled = 0 < this.inputs.maskLoaderSrc.length;
          this.maskLoader.pendingState.source = this.inputs.maskLoaderSrc;
          this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.PROP;
        }

        this.maskLoader.apply();
      })
    );

    this._subscriptions.push(
      this.stage.transitionFinished$.subscribe((currentPositionId) => {

        if (this._transitioning) {

          // Sometime Image Props are "zoomed" close to camera to maintain an illusion that would otherwise be blocked by hidden artifacts in the virtual space.
          // A side effect of zooming is the Image Prop can cover Object Props positioned behind them that we don't want to hide.
          // Setting depthWrite to false avoids this.
          // The simulateDepth flag state property must have been set by evaluating the Position Adjustment for 'zooming'.
          //this.imageDisplay.setDepthWrite(false);
          this._transitioning = false;

          // Restore custom mask that might have been hidded when transition started.
          if (this.maskLoader.pendingState.suppressCustomMask) {

            this.maskLoader.pendingState.suppressCustomMask = false;
            this.maskLoader.apply();
          }
        }

      })
    );
  }


  private _activeTexture?: Texture;
  private _activeTextureAssignment?: ImageAssignment;
  private _lock = 0;
  private _nextRequest = false;
  private _nextTexture?: Texture;
  private _nextTextureAssignment?: ImageAssignment;


  /**
   * Called after async load. 
   * @param texture 
   */
  private handleTextureLoaded(texture?: Texture) {

    this.setActiveTexture(texture);
  }


  /**
   * Called after async load. 
   * @param texture 
   */
  private handleTextureLoadedMulti(texture?: Texture) {

    // Clear potential lock from load next texture
    this._lock = 0;

    // Active texture must be set first
    if (!this._activeTexture || !texture) {

      this.setActiveTexture(this._activeTexture = texture);
      this._nextTexture = undefined;
      this._nextTextureAssignment = undefined;

      // Get the next texture
      this.loadNextTexture();
      return;
    }

    this._nextTexture = texture;
    this._nextTextureAssignment = this._whenTextureIsLoaded;
    if (this._nextRequest) {

      this._nextRequest = false;
      this.initiateTransition()
    }
  }


  private initiateTransition() {

    if (!this._nextTexture) {

      this._nextRequest = true;
      this.loadNextTexture();
      return;
    }

    this.setTransitionTexture(this._nextTexture);
    this.world.transitionImageProp(this.imageProp.id);

    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = transitionAnimationComplete$.subscribe((propId: number) => {

      if (propId !== this.imageProp.id) {

        return;
      }

      // Make sure prop is updated before broadcasting change
      this.imageDisplay.pendingState.texture = this.imageDisplay.pendingState.transitionTexture;
      this.imageDisplay.pendingState.imageAspect = this.imageDisplay.pendingState.transitionImageAspect;
      this.imageDisplay.pendingState.stretchToFit = this.imageDisplay.pendingState.transitionStretchToFit;
      this.imageDisplay.pendingState.transitionTexture = this._nextTexture = undefined;
      this.imageDisplay.pendingState.transitionImageAspect = 0;
      this.imageDisplay.pendingState.transitionStretchToFit = true;
      this.imageDisplay.apply();
      /**
       * Broadcast the current Image Assignment which is unique and references the Prop and the Design Image (if specified).
       * A Design Image can be used in multiple Props so broadcasting it is not helpful as a distinct identifier.
       * Broadcasting Prop select implies an Interaction with the Prop which is not the case when the Acticon is clicked.
       */
      this._activeTextureAssignment = this._nextTextureAssignment;
      if (this._enableAssignmentChangeNotification && this._activeTextureAssignment) {

        this._assignmentChangedSource.next(this._activeTextureAssignment);
      }
      this._nextTextureAssignment = undefined;
      this.updateWorld();
      this.loadNextTexture();

      unsubscribe();
    });
  }


  private initializeTransition() {

    this._activeTexture = undefined;
    this._nextTextureAssignment = this._nextTexture = undefined
    this._lock = 0;
    this._nextRequest = false;
  }


  private loadNextTexture() {

    if (0 < this._lock++) {

      return;
    }

    this.assignmentPlayList.next();
  }


  private setActiveTexture(texture?: Texture) {

    this._activeTextureAssignment = this._whenTextureIsLoaded;

    this.imageDisplay.pendingState.texture = texture;
    this.imageDisplay.pendingState.imageAspect = texture ? (texture.image.width / texture.image.height) : 0;

    // Tasks we want to handle after the image is loaded
    if (this._activeTextureAssignment) {

      this.imageDisplay.pendingState.stretchToFit = this._activeTextureAssignment.stretchToFit;
      /**
       * Broadcast the current Image Assignment which is unique and references the Prop and the Design Image (if specified).
       * A Design Image can be used in multiple Props so broadcasting it is not helpful as a distinct identifier.
       * Broadcasting Prop select implies an Interaction with the Prop which is not the case when the Acticon is clicked.
       */
      if (!this.assignmentPlayList.firstDisplay && this._enableAssignmentChangeNotification) {

        this._assignmentChangedSource.next(this._activeTextureAssignment);
      }

      // Reset default state
      this._enableAssignmentChangeNotification = true;
      this._whenTextureIsLoaded = undefined;
    }

    this.imageDisplay.apply();
    this.updateWorld();
  }


  private setTransitionTexture(texture?: Texture) {

    this.imageDisplay.pendingState.transitionTexture = texture;
    this.imageDisplay.pendingState.transitionImageAspect = texture ? (texture.image.width / texture.image.height) : 0;

    // Tasks we want to handle after the image is loaded
    if (this._nextTextureAssignment) {

      this.imageDisplay.pendingState.transitionStretchToFit = this._nextTextureAssignment.stretchToFit;
    }

    this.imageDisplay.apply();

    this.updateWorld();
  }















  /**
   * Matterport node interactions we need to know about
   */
  private createInteractionEventSpies(): void {

    const that = this;

    const clickEventPath = this.scene.addEventPath(this.planeRendererProp, ComponentInteractionType.CLICK);

    class ImagePropClickSpy {
      readonly path = clickEventPath;

      constructor(private _imageProp: ImagePropNode) { }

      onEvent(payload: any) {

        that._imagePropClickedSource.next(this._imageProp.propId);
      }
    }

    this._iSubscriptions.push(this.scene.spyOnEvent(new ImagePropClickSpy(this)));

    const nextObjectPropClickEventPath = this.scene.addEventPath(this.nextActiconProp, ComponentInteractionType.CLICK);

    class NextObjectPropClickSpy {

      readonly path = nextObjectPropClickEventPath;

      constructor() { }

      onEvent(payload: any) {

        that._acticonPressedSource.next(true);
        that.nextActicon.pressed(true);
        //that.assignmentPlayList.next();
        that.initiateTransition();
      }
    }

    this._iSubscriptions.push(this.scene.spyOnEvent(new NextObjectPropClickSpy()));

    const nextObjectPropHoverEventPath = this.scene.addEventPath(this.nextActiconProp, ComponentInteractionType.HOVER);

    class NextObjectPropHoverSpy {

      readonly path = nextObjectPropHoverEventPath;

      constructor() { }

      onEvent(payload: any) {

        // hover.false is broadcast immediately after click event even when still hovering.
        if (ActiconAnimationState.Pressed === that.nextActicon.getAnimationState()) {

          return;
        }

        if (payload
          && typeof payload === 'object'
          && 'hover' in payload) {

          that.nextActicon.hover(payload.hover as boolean);
        } else {

          that.nextActicon.hover(false);
        }
        that.updateWorld();
      }
    }

    this._iSubscriptions.push(this.scene.spyOnEvent(new NextObjectPropHoverSpy()));
  }


  detachTransformControls() {

    this.planeRendererProp.detachTransformControls();
  }


  getAlphaMapSource(): string | undefined {

    return this.maskLoader.pendingState.source;
  }


  getGlb(callback: (glb: any) => void): any {

    this.imageDisplay.getGltf(callback);
  }


  onCameraPositionChanged(position: Vector3Obj): void {

    // this.infoActicon.pendingState.playerPosition = new Vector3(position.x, position.y, position.z);
    // this.infoActicon.apply();
    this.nextActicon.pendingState.playerPosition.copy(position);
    this.nextActicon.apply();
  }


  onDestroy() {

    this.world.removeImageProp(this.propId);
    this.node.stop();
    this._iSubscriptions.forEach(_is => _is.cancel());
    this._subscriptions.forEach(s => s.unsubscribe());
    this._myOptyxComponents.forEach(mc => mc.onDestroy());
  }


  /**
   * Update world to remove assignments that were removed from a Position Adjustment.
   * @param playerPositionId 
   * @param currentAssignments 
   * @param newAssignments 
   */
  private removeClippingAssignments(playerPositionId: string, currentAssignments: ClippingPlaneAssignment[], newAssignments: ClippingPlaneAssignment[]) {

    for (const currentAssignment of currentAssignments) {

      if (newAssignments.some(na => na.clippingPlaneId === currentAssignment.clippingPlaneId
        && (CrudState.DELETED !== na.crudState))) {

        continue;
      }
      this.world.removeClippingPlaneAssignment(playerPositionId, currentAssignment.clippingPlaneId, PropType.IMAGE);
    }
  }


  removeImageAssignment(assignment: ImageAssignment): void {

    const assignments = this.assignmentPlayList.pendingState.playListItems;
    const assignmentIndex = assignments.findIndex(ai => ai.id === assignment.id);
    if (-1 < assignmentIndex) {

      assignments.splice(assignmentIndex, 1);
    }

    this.setInputs(this.imageProp, assignments, this._designImages);
  }


  removePositionAdjustment(adjustment: PositionAdjustment): void {

    const positionAdjustIndex = this.adjustments.findIndex(pa => pa.playerPositionId === adjustment.positionId);
    if (0 > positionAdjustIndex) {

      return;
    }

    const deletedAdjustments = this.adjustments.splice(positionAdjustIndex, 1);
    if (0 < deletedAdjustments.length) {

      this.world.removeAdjustment(deletedAdjustments[0], this.propId, PropType.IMAGE)
    }
    this.applyPositionAdjustment();
  }


  /**
   * Restore inputs which may have been changed by adjustments.
   * @param imageProp 
   */
  private restoreBaseImagePropPosition(): void {

    this.maskLoader.pendingState.source = this.inputs.maskLoaderSrc;
    this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.PROP;
    this.maskLoader.apply();

    this.imageDisplay.pendingState.disableDepth = false;
    this.imageDisplay.pendingState.visible = true;
    this.imageDisplay.apply();
  }


  setAspect(aspect: number, withWorldUpdate = true): void {

    this.inputs.planeRendererAspect = this.imageDisplay.pendingState.aspect = aspect;
    this.imageDisplay.apply();

    if (withWorldUpdate) {

      this.updateWorld();
    }
  }


  setInputs(imageProp: ImageProp, imageAssignments?: ImageAssignment[], designImages?: DesignImage[]): void {

    this._enableAssignmentChangeNotification = false;
    this.applyPropInputs(imageProp, imageAssignments, designImages);
  }


  setOptions(options: ImagePropOptions): void {

    this.nextActicon.pendingState.alignment = options.controlsPosition;
    this.nextActicon.pendingState.margin = options.controlsMargin;
    this.nextActicon.pendingState.z = options.controlsZ;
    this.nextActicon.pendingState.interactionDistance = options.interactionDistance;
    this.nextActicon.apply();
    this.imageDisplay.pendingState.backingColor = options.backgroundColor;
    this.imageDisplay.apply();
    this.updateWorld();
  }


  setPosition(position: Vector3Obj, withWorldUpdate = true): void {

    // When transforming remove adjusments from the transformed values to avoid it being applied twice.
    if (this._transforming) {

      let currentAdjustment = vector3ZeroObj as Vector3Obj;
      if (this.stage.currentPlayerPosition?.id) {

        const currentPositionAdjustment = this.adjustments.find(pa => pa.playerPositionId === this.stage.currentPlayerPosition?.id);
        if (currentPositionAdjustment) {

          currentAdjustment = currentPositionAdjustment.positionAdjustment;
        }
      }
      this.inputs.position = {
        x: position.x - currentAdjustment.x,
        y: position.y - currentAdjustment.y,
        z: position.z - currentAdjustment.z
      }
    } else {

      this.inputs.position = position;

      if (withWorldUpdate) {

        // World is stopped during transformation.
        this.updateWorld();
      }
    }
  }


  /**
   * Convert PositionAdjustment entity data into ImagePropAdjustment classes with supporting alpha mask.
   * @param imageProp 
   */
  private setPositionAdjustments(imageProp: ImageProp): void {

    // Before replacing existing adjustments remove them from the world.
    for (const adjustment of this.adjustments) {

      this.world.removeAdjustment(adjustment, this.propId, PropType.IMAGE);
    }
    this.adjustments = [];

    let positionAdjustment: PositionAdjustment;
    for (positionAdjustment of imageProp.positionAdjustments) {

      const imagePropAdjustment = new PropAdjustment(this.textureManager);

      imagePropAdjustment.fromPositionAdjustment(positionAdjustment);
      this.adjustments.push(imagePropAdjustment);
    }

    this.upsertAdjustables();
  }


  setRotation(rotation: Vector3Obj, withWorldUpdate = true): void {

    // When transforming, remove adjustments from the transformed values to avoid it being applied twice.
    if (this._transforming) {

      let currentAdjustment = vector3ZeroObj as Vector3Obj;
      if (this.stage.currentPlayerPosition?.id) {

        const currentPositionAdjustment = this.adjustments.find(pa => pa.playerPositionId === this.stage.currentPlayerPosition?.id);
        if (currentPositionAdjustment) {

          currentAdjustment = currentPositionAdjustment.rotateAdjustment;
        }
      }
      this.inputs.rotation = {
        x: rotation.x - currentAdjustment.x,
        y: rotation.y - currentAdjustment.y,
        z: rotation.z - currentAdjustment.z
      }
    } else {

      this.inputs.rotation = rotation;

      if (withWorldUpdate) {

        // World is stopped during transformation.
        this.updateWorld();
      }
    }
  }


  setScale(scale: Vector3Obj, withWorldUpdate = true): void {

    // When transforming, remove adjustments from the transformed values to avoid it being applied twice.
    if (this._transforming) {

      let currentAdjustment = vector3ZeroObj as Vector3Obj;
      if (this.stage.currentPlayerPosition?.id) {

        const currentPositionAdjustment = this.adjustments.find(pa => pa.playerPositionId === this.stage.currentPlayerPosition?.id);
        if (currentPositionAdjustment) {

          currentAdjustment = currentPositionAdjustment.scaleAdjustment;
        }
      }
      this.inputs.scale = {
        x: scale.x - currentAdjustment.x,
        y: scale.y - currentAdjustment.y,
        z: scale.z - currentAdjustment.z
      }
    } else {

      this.inputs.scale = scale;

      if (withWorldUpdate) {

        // World is stopped during transformation.
        this.updateWorld();
      }
    }
  }


  setSelected(isSelected: boolean) {

    if (isSelected) {

      this.stage.deselectAllProps();
    }

    this.imageDisplay.pendingState.selected = isSelected;
    this.imageDisplay.apply();
  }


  setShowroomMode(mode: ShowroomMode): void {

    this.imageDisplay.pendingState.adminMode = ShowroomMode.SHOWROOM !== mode;
    this.imageDisplay.apply();
  }


  setTexture(textureUrl: string): void {

    this.inputs.textureUrl = this.textureLoader.pendingState.source = textureUrl;
    this.textureLoader.apply();
  }


  upsertAssignment(assignment: ImageAssignment, designImages: DesignImage[]): void {

    this._designImages = designImages;
    const assignments = this.assignmentPlayList.pendingState.playListItems;
    const index = assignments.findIndex(ia => ia.id === assignment.id);
    if (-1 < index) {

      assignments[index] = assignment;
      this.assignmentPlayList.pendingState.playListItems = [...assignments];
    } else {

      this.assignmentPlayList.pendingState.playListItems = [assignment, ...assignments];
    }

    this.assignmentPlayList.pendingState.selectItem = assignment;
    this.assignmentPlayList.apply();
  }


  updateWorld(): void {

    const imagePropEntity: ImagePropEntity = {

      id: this.propId,
      acticonAlignment: this.nextActicon.pendingState.alignment,
      acticonMargin: this.nextActicon.pendingState.margin,
      acticonZ: this.nextActicon.pendingState.z,
      baseAspect: this.inputs.planeRendererAspect,
      displayAspect: this.imageDisplay.pendingState.stretchToFit ? 0 : this.imageDisplay.pendingState.imageAspect,
      displayPlane: this.imageDisplay.getImagePlane(),
      position: this.inputs.position,
      rotation: this.inputs.rotation,
      scale: this.inputs.scale,
      //maskLoader: this.maskLoader,
      // infoActicon: this.infoActicon.getObject(),
      // infoActiconAnimationDuration: this.infoActicon.getAnimationDuration(),
      // infoActiconPerpendicularMargin: this.infoActicon.pendingState.perpendicularMargin,
      // infoActiconScale: this.infoActicon.getScaleTo(),
      // infoActiconSize: this.infoActicon.getSize(),
      nextActicon: this.nextActicon.getObject(),
      nextActiconAnimationDuration: this.nextActicon.getAnimationDuration(),
      nextActiconAnimationMixer: this.nextActicon.getAnimationMixer(),
      nextActiconPerpendicularMargin: this.nextActicon.pendingState.perpendicularMargin,
      nextActiconScale: this.nextActicon.getScaleTo(),
      nextActiconSize: this.nextActicon.getSize(),
      planeGroup: this.imageDisplay.getObject(),
      transitionAspect: this.imageDisplay.pendingState.transitionStretchToFit ? 0 : this.imageDisplay.pendingState.transitionImageAspect,
      transitionPlane: this.imageDisplay.getTransitionPlane()
    }


    this.world.upsertImageProp(imagePropEntity);
  }


  updateProp(prop: ImageProp): void {

    this.setInputs(prop, this.assignmentPlayList.pendingState.playListItems, this._designImages);
  }


  private updatePropInputs(): void {

    this.setAspect(this.imageProp.aspect, false)
    this.setPosition(this.imageProp.positionObj, false);
    this.setRotation(this.imageProp.rotationObj, false);
    this.setScale(this.imageProp.scaleObj, false);

    // Let display create the Clipping Planes before we update ECS
    this.imageDisplay.pendingState.clippingPlanes = [...this.imageProp.clippingPlanes];
    this.imageDisplay.apply();

    this.upsertAdjustments();
    this.updateWorld();
  }


  private upsertAdjustables() {

    let adjustment: PropAdjustment;
    for (adjustment of this.adjustments) {

      this.world.upsertAdjustment(adjustment, this.propId, PropType.IMAGE);
    }
  }


  private upsertAdjustments() {

    // Adjustments include Clipping Plane Assignments which depend upon Clipping Planes so apply them last.
    this.setPositionAdjustments(this.imageProp);
  }


  async upsertPositionAdjustment(adjustment: PositionAdjustment): Promise<void> {

    let positionAdjustment = this.adjustments.find(pa => pa.playerPositionId === adjustment.positionId);

    if (positionAdjustment) {

      this.removeClippingAssignments(positionAdjustment.playerPositionId, positionAdjustment.clippingAssignments, adjustment.clippingAssignments);
      positionAdjustment.fromPositionAdjustment(adjustment);
    } else {

      positionAdjustment = new PropAdjustment(this.textureManager);
      positionAdjustment.fromPositionAdjustment(adjustment);
      this.adjustments.push(positionAdjustment);
    }

    this.applyPositionAdjustment();
    this.world.upsertAdjustment(positionAdjustment, this.propId, PropType.IMAGE);
  }


}
