import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartSidebarComponent } from "../../../components/cart/cart-sidebar/cart-sidebar.component";
import { FooterComponent } from "../../../shared/components/bottom/footer/footer.component";
import { AltFooterComponent } from "../../../shared/components/bottom/alt-footer/alt-footer.component";
import { NavbarComponent } from "../../../shared/components/top/navbar/navbar.component";

@Component({
    selector: 'app-tos',
    standalone: true,
    templateUrl: './tos.component.html',
    styleUrls: ['./tos.component.scss'],
    imports: [CommonModule, CartSidebarComponent, FooterComponent, AltFooterComponent, NavbarComponent]
})
export class TosComponent {

    readonly guid = crypto.randomUUID();
}
