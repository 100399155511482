<div #clientObjectPropSidebarComponent tabindex="0" (blur)="onBlur()"
    class="fixed h-screen flex flex-col overflow-hidden top-0 w-full z-50 md:max-w-[500px] bg-[#0e0f10] shadow-xl 
            {{ isOpen() ? 'right-0' : '-right-full' }} transition-all duration-300 px-4 bg-opacity-80 border-l-[1px] border-accent">
    <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row">
            <div (click)="onClose()"
                class=" text-4xl w-11 h-[70px] flex justify-start items-center cursor-pointer transition">
                <ng-icon name="bootstrapXLg"
                    class="text-3xl transition {{ closing() ? 'rotate-[2turn]' : 'rotate-0' }}" />
            </div>
            <div class="flex items-center text-accent text-3xl font-semibold overflow-hidden">
                Configure
            </div>
        </div>

        @if (0 < localAssignment().id) { 

        <div class="flex flex-row space-x-2">
                
            @if (0 < objectPropsAvailable() && 0 < designObject().id) {

            <div class="tooltip tooltip-left" data-tip="Create copy">
                <button (click)="onCopyObjectProp()" 
                    class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">

                    @if (copyingObjectProp()) {

                    <svg class="animate-spin h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>

                    } @else {

                    <span class="flex justify-center items-center text-center text-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-copy" viewBox="0 0 16 16">
                            <path stroke-width="1.5" fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                        </svg>
                    </span>

                    }
                    
                </button>
            </div>

            }
            
            <div class="tooltip tooltip-left" data-tip="Remove from design">
                <button (click)="onRemoveFromDesign()"
                    class="text-sm uppercase font-bold bg-danger hover:bg-dangerhov rounded-lg h-[41px] w-[48px]">
                    <span class="flex justify-center ">
                        <ng-icon size="22" name="bootstrapTrash" />
                    </span>
                </button>
            </div>

        </div>
            
        }

    </div>

    <div>
        <div class="w-full flex flex-row items-center">
            <div
                class="flex flex-row w-full space-x-6 border-b-[1px] border-b-slate-600 mb-2 overflow-hidden overflow-x-auto snap-x">
                <div (click)="onSelectTab(1)" 
                    class="snap-center ml-2 md:ml-0 flex flex-row space-x-4 items-center {{ selectedTab() === 1 ? 'bg-slate-600 rounded-t-md px-4 py-1' : 'cursor-pointer' }}">
                    <h1 class="text-2xl">Assignment</h1>
                </div>
                <div (click)="onSelectTab(2)" 
                    class="snap-center flex flex-row space-x-4 items-center {{ selectedTab() === 2 ? 'bg-slate-600 rounded-t-md px-4 py-1' : 'cursor-pointer' }}">
                    <h1 class="text-2xl">Object</h1>
                </div>
            </div>

            @if (enableSave()) {

            <div class="flex flex-row space-x-2 mb-3">
                <button (click)="onUndo()" class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                    <span class="flex justify-center ">
                        <ng-icon class="cursor-pointer" size="22" name="bootstrapArrowCounterclockwise" />
                    </span>
                </button>
                <button (click)="onSave()" class="text-sm uppercase font-bold bg-green-700 rounded-lg h-[41px] w-[48px]">
                    <span class="flex justify-center ">
                        <ng-icon class="cursor-pointer" size="22" name="bootstrapCheckLg" />
                    </span>
                </button>
            </div>

            }
    
        </div>
        <div class="px-2 md:px-0">

            @if (selectedTab() === 1) {

            <p class="text-lg text-gray-400">
                Location specific information.
            </p>

            @if (1 > designObject().id) {

            <div
                class="flex items-center text-accent text-2xl font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap">
                Unassigned
            </div>

            }

            }
            @if (selectedTab() === 2) {

            <p class="text-lg text-gray-400">
                Object specific information.
            </p>

            }

        </div>
    </div>

    @if (selectedTab() === 1 && !readOnlyDefaultDesignObject()) {

    <div class="flex-1 flex overflow-hidden">
        <!-- https://stackoverflow.com/questions/69400560/how-to-change-scrollbar-when-using-tailwind-next-js-react -->
        <!-- https://stackoverflow.com/questions/70539607/how-to-make-scrollable-sidebar-fixed-in-tailwindcss -->
        <div
            class="flex-1 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:[width:15px] [&::-webkit-scrollbar-thumb]:bg-accent">
            <!-- The following padding seems to adjust for stuff at top of sidebar. If scrollable area matched heigh of sidebar, don't think it would be needed -->
            <div class="flex flex-col space-y-4 pb-36 mr-4 mt-4">

                @if (0 < localAssignment().id) { 
                    
                <div>
                    <div class="whitespace-nowrap">Name</div>
                    <textarea rows="3" maxlength="256" [value]="localAssignment().name" 
                        (blur)="handleObjectAssignmentNameInput($event)" (keyup)="handleObjectAssignmentNameInput($event)"
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary">
                    </textarea>
                </div>

                <div class="flex flex-row justify-between space-x-2 items-center">
                    <span>Position:</span>
                    <app-common-vector3-input [Value]="localAssignment().positionObj" (OnChange)="handlePositionChange($event)"
                        Label="Position" />
                </div>

                <div class="flex flex-row justify-between space-x-2 items-center">
                    <span>Rotation:</span>
                    <app-common-vector3-input [Value]="localAssignment().rotationObj" (OnChange)="handleRotationChange($event)"
                        Label="Rotation" />
                </div>

                <div class="flex flex-row justify-between items-center">
                    <span class="pt-[27px]">Scale:</span>
                    <app-common-vector3-input [Value]="localAssignment().scaleObj"
                        (OnChange)="handleObjectAssignmentScaleChange($event)" Label="Scale" [EnableEqual]="true"
                        [Equal]="isObjectAssignmentScaleEqual()" />
                </div>

                <div class="flex flex-row justify-between">
                    <app-checkbox Label="Enable interaction" [Checked]="localAssignment().enableInteraction"
                        (Toggled)="onToggleEnableInteraction()" />

                    @if (localAssignment().enableInteraction) {

                    <app-checkbox Label="Enable cart" [Checked]="localAssignment().enableCart"
                        (Toggled)="onToggleEnableCart()" />

                    }

                </div>

                @if (localAssignment().enableInteraction && localAssignment().enableCart) {

                <app-price-options [PriceOptions]="localAssignment().priceOptions" (Add)="onAddPriceOption()"
                    (Updated)="handlePriceOptionUpdated($event)" (Delete)="onDeletePriceOption($event)" />

                }

            <div>
            <span class="w-[85px]">Description</span>
            <p class="italic pb-2">Displayed upon interaction with this Prop.</p>
            <app-text-editor [Content]="localAssignment().description"
                (OnUpdate)="handleObjectAssignmentDescriptionInput($event)" />
        </div>

        }

    </div>
</div>
</div>
}

@if (selectedTab() === 2) {

<div class="flex-1 flex overflow-hidden">
    <!-- https://stackoverflow.com/questions/69400560/how-to-change-scrollbar-when-using-tailwind-next-js-react -->
    <!-- https://stackoverflow.com/questions/70539607/how-to-make-scrollable-sidebar-fixed-in-tailwindcss -->
    <div
        class="flex-1 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:[width:15px] [&::-webkit-scrollbar-thumb]:bg-accent">
        <!-- The following padding seems to adjust for stuff at top of sidebar. If scrollable area matched heigh of sidebar, don't think it would be needed -->
        <div class="flex flex-col space-y-4 pb-36 mr-4 mt-4">

            <!-- Design object properties -->
            @if (0 < designObject().id) { 
                
                <div
                    class="flex items-center text-accent text-2xl font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {{readOnlyDefaultDesignObject() ? 'From default' : designObject().uploadFileName}}
                </div>
                <div
                    class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                    <div class="h-96 w-72 mx-auto">
                        <model-viewer [id]="id" [alt]="designObject().uploadFileName" class="h-full w-full"
                            [src]="designObject().objectUrl" ar environment-image="/assets/environments/moon_1k.hdr"
                            shadow-intensity="1" camera-controls touch-action="pan-y" autoplay auto-rotate
                            (load)="onModelViewerLoaded($event)">
                            <button slot="ar-button"
                                style="background-color: white; color: black; border-radius: 4px; border: none; position: absolute; top: 16px; right: 16px; padding: 4px; ">
                                👋 Activate AR
                            </button>
                        </model-viewer>
                    </div>

            @if (!readOnlyDefaultDesignObject()) {

            <div
                class="absolute inset-0 flex pb-4 translate-y-[86%] flex-col px-4 transition-all duration-500">
                <div class="flex flex-row justify-between">
                    <div class="tooltip tooltip-right" data-tip="Remove object">
                        <button (click)="onRemoveDesignObject($event)"
                            class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px] cursor-pointer">
                            <span class="flex justify-center ">
                                <ng-icon size="22" name="bootstrapXLg" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            }

        </div>

        @if (designObject().description && 0 < designObject().description.length) { <div
            class="flex flex-col text-lg overflow-hidden" [innerHTML]="designObject().description">
    </div>

    }

    } @else {

    <div class="w-full flex flex-col items-center">
        <div class="flex flex-row justify-center space-x-2">
            <input type="file" id="file-input-add-video" class="hidden" accept=".glb" onclick="this.value = null;"
                (change)="addDesignObject($event)">
            <div class="tooltip tooltip-left" data-tip="Upload design object">
                <!-- Button invokes file input above -->
                <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                    <label class="flex justify-center" for="file-input-add-video">
                        <ng-icon class="cursor-pointer" size="25" name="bootstrapPlusLg" />
                    </label>
                </button>
            </div>

            <div class="tooltip tooltip-right" data-tip="Import from design">
                <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]"
                    (click)="onImportDesignObject()">
                    <label class="flex justify-center">
                        <ng-icon class="cursor-pointer" size="25" name="bootstrapArrowLeft" />
                    </label>
                </button>
            </div>
        </div>
    </div>

    @if (0 < uploadingObjectFileName().length) { 
        
        <div class="w-full py-2 flex flex-col justify-center text-center items center">
            <div class="pb-1">Uploading {{uploadingObjectFileName()}}</div>
            <app-progress-bar [Progress]="uploadingObjectProgress()" [EaseToProgress]="20" />
        </div>

    }
}

</div>
</div>
</div>

}

</div>