import { defineQuery, defineSystem } from 'bitecs'
import { ChildObjectComponent } from '../component/object.component';
import { AnimationComponent } from '../component/animation.component';
import { AnimationType } from "../../scene/animation";
import { WorldState } from '../ecs-world.state';
import { animationMixerByEntityId } from '../entity/object-prop.entity';
import { object3dByEntityId } from '..';

export const ActiconState = {

    counter: 0
}

export const childObjectEntityQuery = defineQuery([ChildObjectComponent]);

/**
 * Acticon specific position, rotation, scale and animation updates.
 */
export const childObjectSystem = defineSystem(world => {

    const childObjectEntities = childObjectEntityQuery(world);
    for (let i = 0; i < childObjectEntities.length; i++) {

        animateChildObject(childObjectEntities[i])
    }
    
    return world;
})


/**
 * Exploring ECS animation options https://forum.unity.com/threads/dots-animation-options-wiki.1339196/
 * @param eidForChildObject 
 */
function animateChildObject(eidForChildObject: number) {

    // Math based animations
    switch (AnimationComponent.type[eidForChildObject]) {

        case AnimationType.ROTATE:
            object3dByEntityId[eidForChildObject].rotation.y -= AnimationComponent.rotationY[eidForChildObject];
            break;
    }

    // Embedded GTLF animations
    animationMixerByEntityId[eidForChildObject]?.update(WorldState.delta);    
}

