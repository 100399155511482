import { Types, defineComponent } from 'bitecs';

/**
 * Adjustable components have position, scale and rotation values that change
 * when transitioning from position to position. Adjustments are applied by 
 * lerping from the start position values to the destination position values
 * in connection with the movement of the camera from position 1 to position 2.
 * 
 * Entities flagged as Adjustable components are processed by the following systems:
 * 1. Adjustment System
 * 2. Transition Init System
 * 3. Transition System
 * 4. Apply Adjustment System
 */
export const AdjustableComponent = defineComponent({
    id: Types.i32,
    aspect: Types.f32,
    type: Types.i8,
    eidForDisplayPlane: Types.eid,
    eidForTransitionPlane: Types.eid
});