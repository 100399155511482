import { createMongoAbility, subject } from "@casl/ability";
import { AccountState, ISusbscriptionUserSummary, SubscriptionAccount, IVenue } from "..";
import { fields, MaskHelper } from "../..";
import { Action, equalsCaslPermissions, ICaslPermission, Subject } from "../../auth";
import { Role } from "../../auth/role";
import { equalsAddress, IAddress, IGuestUser, IUser, ISubscriptionUser, RequiredInformationMissing, IShowroomUser } from "../interface/IUser";
import { deepCopy } from "projects/mp-core/src/lib/util";


export class SubscriptionUserSummary implements ISusbscriptionUserSummary {

    id = 0;
    roleMask = 0;
    userName = '';
    emails = '';
    accountCount = 0;

    public get isAdmin(): boolean {

        const maskHelper = new MaskHelper(this.roleMask);
        return maskHelper.has(Role.ADMIN);
    }

    public get isSubscriber(): boolean {

        return 0 < this.accountCount;
    }


    constructor(user?: ISusbscriptionUserSummary) {

        if (user) {

            // Copy the IUser properties into this
            subscriptionUserSummaryKeys.forEach((key) => {

                (this as any)[key as keyof ISusbscriptionUserSummary] = user[key as keyof ISusbscriptionUserSummary];
            });
        }
    }
}
const subscriptionUserSummaryKeys = Object.keys(new SubscriptionUserSummary());


/**
 * A Guest User is instantiated by the GuestService when a User with a valid invitation Guid logs in.
 * The Guest User data is managed in the Showroom Db.
 */
export class GuestUser implements IGuestUser {

    accountNumber = '';
    id = 0;
    roleMask = 0;
    userName = '';
    emails = '';

    public get isValid(): boolean { return 0 < this.id }


    constructor(guestUser?: IGuestUser) {

        if (guestUser) {

            // Copy the IUser properties into this
            guestUserKeys.forEach((key) => {

                (this as any)[key as keyof IGuestUser] = guestUser[key as keyof IGuestUser];
            });
        }
    }
}
const guestUserKeys = Object.keys(new GuestUser());


/**
 * The initial MyOptyx User record is created in the Showroom DB and sync'ed with Purchase DB via Integration Events.
 * It only holds basic User data.
 * Showroom Db is the authoritative source for this inital, basic User data.
 */
export class User implements IUser {

    id: number = 0;
    roleMask: number = 0;
    userName: string = '';
    emails: string = '';

    private _maskHelper = new MaskHelper(this.roleMask);

    public get isAdmin(): boolean {

        return this.isValid && this._maskHelper.has(Role.ADMIN);
    }

    public get isValid(): boolean { return 0 < this.id }


    constructor(user?: IUser) {

        if (user) {

            // Copy the IUser properties into this
            userKeys.forEach((key) => {

                (this as any)[key as keyof IUser] = user[key as keyof IUser];
            });

            this._maskHelper = new MaskHelper(this.roleMask);
        }
    }
}
const userKeys = Object.keys(new User());


/**
 * Showroom User extends User to include permissions associated with an Account.
 */
export class ShowroomUser extends User implements IShowroomUser {

    permissions: ICaslPermission[] = [];

    ability = createMongoAbility(this.permissions);

    /**
     * Regardless of Account read/write, verify Invoice access.
     */
    public get canAccessVenues(): boolean {

        return !this.hasPermissions
            || (this.permissions.some(p => Subject.Venue == p.subject)
            && !(this.permissions.some(p => Subject.None === p.subject)));
    }


    public get hasPermissions(): boolean {

        return 0 < this.permissions.length;
    }

    public get isPermissionsNone(): boolean {

        return 1 === this.permissions.length && this.permissions[0].subject === Subject.None;
    }

    public get isShowroomAdmin(): boolean {

        return this.isAdmin || !this.hasPermissions;
    }


    constructor(showroomUser?: IShowroomUser) {
        super(showroomUser);

        if (showroomUser) {

            // Copy the IUser properties into this
            showroomUserKeys.forEach((key) => {

                (this as any)[key as keyof IShowroomUser] = deepCopy(showroomUser[key as keyof IShowroomUser]);
            });
        }

        this.ability = createMongoAbility(this.permissions);
    }


    canVenue(action: Action, venue: IVenue): boolean {

        return this.ability.can(action, subject(Subject.Venue, venue));
    }


    clearPermissions(): void {

        this.permissions = [];
        this.ability = createMongoAbility(this.permissions);
    }


    toggleAccountPermission(action: Action, subject: Subject): void {

        const index = this.permissions.findIndex(p => p.subject === subject);
        if (0 > index) {

            // Adding the None permission. Clear all other permissions.
            if (Subject.None === subject) {
             
                this.clearPermissions();
            }
            this.permissions.push({
                action: action,
                subject: subject
            })
        } else {

            this.permissions.splice(index, 1);
        }
        this.ability = createMongoAbility(this.permissions);
    }


    toggleVenuePermission(action: Action, venue: IVenue): void {

        const index = this.permissions.findIndex(
            p => p.subject === Subject.Venue && p.conditions && (p.conditions as any).id === venue.id);
        if (0 > index) {

            // Adding a Venue. Remove the None permissions if it is defined.
            const noneIndex = this.permissions.findIndex(p => Subject.None === p.subject);
            if (-1 < noneIndex) {

                this.permissions.splice(noneIndex, 1);    
            }
            this.permissions.push({
                action: action,
                subject: Subject.Venue,
                conditions: { id: venue.id}
            })
        } else {

            this.permissions.splice(index, 1);
        }
        this.ability = createMongoAbility(this.permissions);
    }
}
const showroomUserKeys = Object.keys(new ShowroomUser())
    .filter(key => !userKeys.some(userKey => userKey === key));


export const subscriptionUserFields = fields<SubscriptionUser>();
/**
 * The MyOptyx Subscription User is managed in the Purchase Db
 * It extends User data to include Accounts, Account Status', Billing Information and Invoices.
 */
export class SubscriptionUser implements ISubscriptionUser {

    id = 0;
    roleMask = 0;
    userName = '';
    companyName = '';
    firstName = '';
    lastName = '';
    emails = '';
    address = <IAddress>{};
    accounts: SubscriptionAccount[] = [];
    permissions: ICaslPermission[] = [];
    subscribedAccounts: SubscriptionAccount[] = [];
    requiredInformationMissing = RequiredInformationMissing.None;

    ability = createMongoAbility(this.permissions);

    private _maskHelper = new MaskHelper(this.roleMask);

    public get accountState(): AccountState {

        if (this._maskHelper.has(Role.ADMIN | Role.SUBSCRIBER)) {

            return AccountState.Active;
        }
        if (this._maskHelper.has(Role.READONLY_SUBSCRIBER)) {

            return AccountState.ReadOnly;
        }
        return AccountState.Inactive
    }

    /**
     * Regardless of Account read/write, verify Invoice access.
     */
    public get canAccessInvoices(): boolean {

        return !this.hasPermissions
            || this.ability.can(Action.read, Subject.Invoice);
    }

    /**
     * Regardless of Account read/write, verify Purchase Order access.
     */
    public get canAccessPurchaseOrder(): boolean {

        return !this.hasPermissions
        || this.ability.can(Action.read, Subject.PurchaseOrder);
    }

    /**
     * Regardless of Account read/write, verify Subscriber access.
     */
    public get canAccessSubscriber(): boolean {

        return !this.hasPermissions
            || this.ability.can(Action.read, Subject.Subscriber);
    }

    public get canRead(): boolean {

        return this.isAdmin || this.isReadOnlySubscriber;
    }

    public get canWrite(): boolean {

        return this.isAdmin || this.isActiveSubscriber;
    }

    public get hasPermissions(): boolean {

        return 0 < this.permissions.length;
    }

    public get isAccountAdministrator(): boolean {

        return !this.hasPermissions;
    }

    public get isAdmin(): boolean {

        return this.isValid && this._maskHelper.has(Role.ADMIN);
    }

    /**
     * Admin or Active Subscriber status
     */
    public get isActiveSubscriber(): boolean {

        return this.isValid
            && this._maskHelper.has(Role.ADMIN | Role.SUBSCRIBER);
    }

    /**
     * Admin or any Subscriber status
     */
    public get isSubscriber(): boolean {

        return this.isValid
            && this._maskHelper.has(Role.ADMIN | Role.SUBSCRIBER | Role.READONLY_SUBSCRIBER | Role.INACTIVE_SUBSCRIBER);
    }

    /**
     * Admin or Active | ReadOnly Subscriber, excludes Inactive Subscribers 
     */
    public get isReadOnlySubscriber(): boolean {

        return this.isValid
            && this._maskHelper.has(Role.ADMIN | Role.SUBSCRIBER | Role.READONLY_SUBSCRIBER);
    }

    public get isPermissionsNone(): boolean {

        return 1 === this.permissions.length && this.permissions[0].subject === Subject.None;
    }

    public get isValid(): boolean { return 0 < this.id }


    constructor(user?: ISubscriptionUser) {

        if (user) {

            subscriptionUserKeys.forEach((key) => {

                switch (key) {
                    case `${subscriptionUserFields.accounts}`:
                        for (const account of user.accounts) {

                            this.accounts.push(new SubscriptionAccount(account));
                        }
                        break;
                    case `${subscriptionUserFields.subscribedAccounts}`:
                        for (const account of user.subscribedAccounts) {

                            this.subscribedAccounts.push(new SubscriptionAccount(account));
                        }
                        break;
                    default:
                        (this as any)[key as keyof ISubscriptionUser] = deepCopy(user[key as keyof ISubscriptionUser]);
                }
            });

            this._maskHelper = new MaskHelper(this.roleMask);
            this.ability = createMongoAbility(this.permissions);
        }
    }


    clearPermissions(): void {

        this.permissions = [];
        this.ability = createMongoAbility(this.permissions);
    }


    toggleAccountPermission(action: Action, subject: Subject): void {

        const index = this.permissions.findIndex(p => p.subject === subject);
        if (0 > index) {

            this.permissions.push({
                action: action,
                subject: subject
            })
        } else {

            this.permissions.splice(index, 1);
        }
        this.ability = createMongoAbility(this.permissions);
    }
}
const subscriptionUserKeys = Object.keys(new SubscriptionUser());
export function equalsSubscriptionUser(su1: SubscriptionUser, su2: SubscriptionUser): boolean {

    return su1.id === su2.id
        && su1.roleMask === su2.roleMask
        && su1.userName.trim() === su2.userName.trim()
        && su1.companyName.trim() === su2.companyName.trim()
        && su1.firstName.trim() === su2.firstName.trim()
        && su1.lastName.trim() === su2.lastName.trim()
        && su1.emails === su2.emails
        && equalsAddress(su1.address, su2.address)
        //accounts: ISubscriptionAccount[] = [];
        && equalsCaslPermissions(su1.permissions, su2.permissions)
        //subscribedAccounts: ISubscriptionAccount[] = [];
        && su1.requiredInformationMissing === su2.requiredInformationMissing;
}
