import { Injectable, signal } from '@angular/core';
import { DesignObject, IImportableDesignObjects, IImportableObjectSearchResult } from 'projects/my-common/src/model';
import { Subject, firstValueFrom } from 'rxjs';
import { SearchService } from '../search/search.service';
import { NGXLogger } from 'ngx-logger';
import { VenueService } from './venue.service';

/**
 * Support UI based DesignObject interactions
 * Server side persistence is managed in VenueService
 */
@Injectable({
  providedIn: 'root'
})
export class DesignObjectService {

  readonly importableDesignObjects = signal(<IImportableDesignObjects>{
    data: <IImportableObjectSearchResult[]>[]
  });

  readonly currentEventDesign = this.venueService.currentEventDesign
  private _includeThisDesignsImages: boolean = false;

  private _importObjectSource = new Subject<DesignObject>();
  importObject$ = this._importObjectSource.asObservable();

  private _pageSize = 10;


  constructor(private readonly searchService: SearchService,
    private readonly venueService: VenueService,
    private readonly logger: NGXLogger) { }


  broadcastImportDesignObject(designObject: DesignObject): void {

      this._importObjectSource.next(designObject);    
  }


  async loadPage(pageIndex: number): Promise<void> {

    const paginatedResults = await firstValueFrom(
      this.searchService.importableDesignObjects(this.currentEventDesign().id, this._includeThisDesignsImages, this._pageSize, pageIndex)
    );
    this.logger.trace('paginated results', paginatedResults);

    this.importableDesignObjects.set(paginatedResults);
  }


  initialize(includeThisDesignsImages: boolean = false): void {

    this._includeThisDesignsImages = includeThisDesignsImages;
    this.loadPage(0);
  }


}
