import { Component, effect, OnDestroy, signal } from '@angular/core';
import { IModal, ModalService } from 'src/app/core/service/ui/modal/modal.service';

export const ALERT_MODAL_ID = 'd9b2d63d-a233-4123-847a-8e5d8b2b3b5e';

@Component({
  selector: 'app-alert-modal',
  standalone: true,
  imports: [],
  templateUrl: './alert-modal.component.html',
  styleUrl: './alert-modal.component.scss'
})
export class AlertModalComponent implements IModal, OnDestroy {

  id = ALERT_MODAL_ID;
  readonly isOpen = signal(false);
  readonly message = signal('Alert');

  constructor(private readonly modalService: ModalService) {

    modalService.add(this);

    effect(() => {
      if (this.isOpen()) {

        const dialogRef = document.getElementById(this.id);
    
        if (dialogRef) {
    
          (dialogRef as any).showModal();
        }
      }
    })
  }


  close(): void {
    throw new Error('Method not implemented.');
  }


  ngOnDestroy(): void {

    this.modalService.remove(this);
  }


  onClose() {

    this.isOpen.set(false);
  }


  setTarget<T>(target: T): void {

    this.message.set(target as string);
  }

}
