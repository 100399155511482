/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */
import { MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import { LogLevel, BrowserCacheLocation, IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { IdTokenClaims } from '@azure/msal-common';
import { environment, URL_BASE } from 'src/environments/environment';
import { PURCHASE_ENDPOINT, SHOWROOM_ENDPOINT } from 'src/environments/interfaces/IEnvironment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export type IdTokenClaimsWithPolicyId = IdTokenClaims & {
    acr?: string,
    tfp?: string,
};

/**
 * Here we pass the configuration parameters to create an MSAL instance
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {

    return new PublicClientApplication(
        {
            auth: {
                // Entra test - issue tested in their sample app
                // https://github.com/Azure-Samples/ms-identity-ciam-javascript-tutorial/issues/64
                //clientId: '06a54ebf-1d33-485e-9622-57026fb63504', // This is the ONLY mandatory field that you need to supply
                clientId: '2203a394-9dd2-44fc-b5aa-9121ddfa3b32', // This is the ONLY mandatory field that you need to supply
                authority: environment.b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common", could be "https://login.microsoftonline.com/<b2c-tenant-id>" 
                //knownAuthorities: [environment.b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted
                redirectUri: '/auth', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration
                postLogoutRedirectUri: '/', // Points to window.location.origin by default
                // clientCapabilities: ['CP1']  // Support API claim challenges - see end of https://www.youtube.com/watch?v=EJey9KP1dZA
            },
            cache: {
                //cacheLocation: "sessionStorage" // is more secure, but "localStorage" = better user experience with SSO access between browser tabs
                cacheLocation: BrowserCacheLocation.LocalStorage, // This mode breaks Msal in progress auth when Service Worker deploys update - local storage changes
                //storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
                temporaryCacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: false
            },
            system: {
                // The allowNativeBroker flag is now turned on by default in the configurations. 
                // If you're using a B2C authority you can turn it off 
                allowNativeBroker: false,                
                /**
                 * Below you can configure MSAL.js logs. For more information, visit:
                 * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
                 */
                loggerOptions: {
                    loggerCallback(logLevel: LogLevel, message: string) {
                        console.warn(message);
                    },
                    logLevel: LogLevel.Warning,
                    piiLoggingEnabled: false
                },
                //networkClient: new CustomNetworkClient()
            }
        }
    );
}


/**
 * MSAL Angular will automatically retrieve and forward tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {

    /**
     * Secure Controllers or modules. Interceptor will prompt for login.
     */
    const protectedResources = [
        {
            endpoint: `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Account}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.EventDesign}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Search}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Showroom}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.SHOWROOM}/${PURCHASE_ENDPOINT.Subscriber}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.User}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.Account}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Venue}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.VenueEvent}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.Invoice}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.PurchaseOrder}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.Subscriber}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.Subscription}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        },
        {
            endpoint: `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.User}`,    
            scopes: {
                read: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'],
                write: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
            }
        }
    ]

    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

    //
    // Set protected paths
    //
    protectedResources.forEach((resource) => {
        protectedResourceMap.set(resource.endpoint, [
            {
                httpMethod: 'GET',
                scopes: resource.scopes.read
            },
            {
                httpMethod: 'POST',
                scopes: resource.scopes.write
            },
            {
                httpMethod: 'PUT',
                scopes: resource.scopes.write
            },
            {
                httpMethod: 'DELETE',
                scopes: resource.scopes.write
            }
        ]);
    });
    
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
        authRequest: (msalService, httpReq, originalAuthRequest) => {
            if (!environment.configuration) console.log('--> Interceptor invoked for request', httpReq);

            // Can add Guest auth logic here
            // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/multi-tenant.md#dynamic-auth-request
            return { ...originalAuthRequest };
        }
    };
}