import { Component, Input, OnDestroy, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapXLg } from '@ng-icons/bootstrap-icons';
import { ICatalogType } from 'src/app/core/model/catalog.model';
import { SecurityService } from 'src/app/core/service/authentication/security.service';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from 'src/app/core/service/myoptyx/account.service';
import { UserService } from 'src/app/core/service/myoptyx/user.service';


@Component({
  selector: 'app-mobile-menu',
  standalone: true,
  imports: [CommonModule, NgIconComponent, RouterLink, RouterLinkActive],
  providers: [provideIcons({ bootstrapXLg })],
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnDestroy {
  private _subscriptions: Subscription[] = [];

  @Input() visible: boolean = false;
  readonly isAdmin = signal(false);
  readonly isSubscriber = signal(false);
  readonly isAuthenticated = signal(false);
  readonly catalogTypes: WritableSignal<ICatalogType[]> = signal([]);


  constructor(private _securityService: SecurityService,
    readonly account: AccountService,
    readonly user: UserService,
    private readonly _logger: NGXLogger) {

    this.initIsAuthenticated();
  }


  closeMenu() {
    this.visible = false;
  }


  private initIsAuthenticated() {
    this.isAuthenticated.set(this._securityService.isAuthenticated);
    // Handle authetication updates
    this._subscriptions.push(
      this._securityService.isAuthenticatedUpdate$
        .subscribe((isAuthenticated) => {
          this.isAuthenticated.set(isAuthenticated);
        })
    );
  }


  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }


}
