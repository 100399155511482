import { IPurchaseOrder } from "src/app/core/model/purchase.model";
import { AccountState, IInvoice, IPricePlan, IShowroomAccount, ISubscriberInvitationLink, ISubscriptionAccount } from "../interface/IAccount";
import { ISubscriptionUser } from "../interface/IUser";
import { deepCopy } from "projects/mp-core/src/lib/util";
import { IVenue } from "../interface/IShowroom";
import { fields } from "../..";
import { Venue } from "./Showroom";


export const showroomAccountFields = fields<ShowroomAccount>();
export class ShowroomAccount implements IShowroomAccount {

    id = 0;
    accountNumber = '';
    state = AccountState.Inactive;
    accountOwnerId = 0;
    expires = new Date();
    venues: Venue[] = [];

    public get isValid(): boolean { return 0 < this.id }


    constructor(account?: IShowroomAccount) {

        if (account) {

            for (const venue of account.venues) {

                this.venues.push(new Venue(venue));
            }

            showroomAccountKeys.filter(key => key !== showroomAccountFields.venues).forEach((key) => {


                (this as any)[key as keyof IShowroomAccount] = account[key as keyof IShowroomAccount];

            });
        }
    }
}
const showroomAccountKeys = Object.keys(new ShowroomAccount());


export const subsciptionAccountFields = fields<SubscriptionAccount>();
export class SubscriptionAccount implements ISubscriptionAccount {

    accountNumber = '';
    accountOwnerId = 0;
    description = '';
    expires = new Date();
    id = 0;
    invoices: IInvoice[] = [];
    pricePlan: IPricePlan = {
        complimentarySubscriberCount: 0,
        description: '',
        id: 0,
        imagePropMonthlyPrice: 0,
        name: '',
        objectPropMonthlyPrice: 0,
        perGigabyteMonthlyPrice: 0,
        subscriberMonthlyPrice: 0,
        venueMonthlyPrice: 0
    };
    state = AccountState.Inactive;
    subscriberInvitationLinks: ISubscriberInvitationLink[] = [];
    subscribers: ISubscriptionUser[] = [];
    purchaseOrders: IPurchaseOrder[] = [];

    public get isValid(): boolean {

        return 0 < this.id;
    }


    constructor(account?: ISubscriptionAccount) {

        if (account) {

            subscriptionAccountKeys.forEach((key) => {

                (this as any)[key as keyof ISubscriptionAccount] = deepCopy(account[key as keyof ISubscriptionAccount]);
            });
        }
    }

}
const subscriptionAccountKeys = Object.keys(new SubscriptionAccount());