import { MyoptyxGLTFInstance } from './gltf-instance/MyoptyxGLTFInstance';
import { GLTFInstance } from './GLTFInstance';
import { disposeGltf, getMaterials } from './utils';
import { getLogger } from '../util/log';
import { Texture } from 'three';
import { TextureManager } from './texture-manager';

/**
 * Gltf cache management. Downloads and stores Gltf files for subsequent use.
 * Downstream consumers should clone the scene to support mulitple instances and avoid disposal clashes.
 * Dispose to clear cache.
 * TODO: Cache expiration timers
 */
type GltfRequest = {
  onLoad?: ((data: GLTFInstance) => void);
  onError?: ((err: unknown) => void);
}


const DEFAULT_OBJECT_URL = '/assets/models/MyOptyxGlasses.glb';


export class GltfManager {

  private readonly _gltfs: { [src: string]: any } = {};
  private readonly _objectQueues: { [src: string]: GltfRequest[] } = {};
  private readonly _logger = getLogger();

  private _reflectionCube!: Texture;


  /**
   * @param three the three instance supplied by parent.
   * @param _logger 
   */
  constructor(readonly textureManager: TextureManager) {

    //cubemap
    const path = '/assets/images/';
    const format = '.jpg';
    const urls = [
      path + 'MyOptyx_512' + format, path + 'MyOptyx_512' + format,
      path + 'MyOptyx_512' + format, path + 'MyOptyx_512' + format,
      path + 'MyOptyx_512' + format, path + 'MyOptyx_512' + format
    ];

    this._reflectionCube = new textureManager.three.CubeTextureLoader().load(urls);
  }


  /**
   * Load the gltf and respond via callback.
   * To support instancing, be sure to clone the scene of the returned gtlf object.
   * Cloned gltfs still share the original gltfs textures so do not dispose of them.
   * You can replace the textures of your cloned scene.
   * You should dispose of your cloned scene and any textures you created and applied.
   * @param src 
   * @param onLoad callback the loaded gltf
   * @param onError callback
   * @returns 
   */
  async loadGltf(src: string, onLoad?: ((object: GLTFInstance) => void), onError?: ((err: unknown) => void)): Promise<void> {

    if (!onLoad || !src || 5 > src.length) {

      const error = `callback or source not defined`;
      if (onError) {

        onError(error);
      }
      Error(error);
      return;
    }

    // If gltf already loaded, return it
    if (this._gltfs[src]) {

      //this._logger.trace(`returning cached gltf ${src}`);
      onLoad(this._gltfs[src]);
      return;
    }

    // Queue load requests
    if (this._objectQueues[src]) {

      this._objectQueues[src].push({
        onLoad: onLoad,
        onError: onError
      });
      return;
    } else {

      this._objectQueues[src] = [{
        onLoad: onLoad,
        onError: onError
      }];
    }

    /**
     * Experimenting with caching loader. Not working yet
     */

    // const gltf = await this._loader.load(src);
    // this._gltfs[src] = gltf;
    // for (const objectRequest of this._objectQueues[src]) {

    //   if (objectRequest.onLoad) {

    //     objectRequest.onLoad(gltf);
    //   }
    // }

    // delete this._objectQueues[src];

    /**
     * End experimenting
     */

    const dracoLoader = new (this.textureManager.three as any).DRACOLoader();
    dracoLoader.setDecoderPath('static/vendors/three/0.151.3/libs/draco');  //'assets/js/draco/gltf/');
    const gltfLoader = new (this.textureManager.three as any).GLTFLoader();
    gltfLoader.setDRACOLoader(dracoLoader);

    gltfLoader.load(src,
      (gltf: any) => {

        this._logger.trace(`storing gltf: ${src} and processing queue with ${this._objectQueues[src].length} callbacks`, gltf);

        const materials = getMaterials(gltf.scene);
        let material: any;
        for (material of materials) {

          // const newMaterial = applyMaterial({
          //   name: material.name,
          //   map: material.map,
          //   mapFlip: FlipType.None,
          //   alphaMap: material.alphaMap,
          //   alphaMapFlip: FlipType.None
          // }, this.textureManager.three, gltf.scene);

          material.envMap = this._reflectionCube;
          //if (newMaterial) {

            // newMaterial.aoMap = material.aoMap;
            // newMaterial.metalness = material.metalness;
            // newMaterial.metalnessMap = material.metalnessMap;
            // newMaterial.roughnessMap = material.roughnessMap;
            // newMaterial.color = material.color;  
            // newMaterial.envMap = this._reflectionCube;
          //}
          
          //this._logger.trace('original material, new material', material, newMaterial);
        }

        //this._gltfs[src] = gltf;
        let objectRequest: GltfRequest;
        for (objectRequest of this._objectQueues[src]) {

          if (objectRequest.onLoad) {

            objectRequest.onLoad(gltf);
          }
        }

        delete this._objectQueues[src];
      },
      (progress: any) => { },
      (error: any) => {
        console.error(error);
      });
  }


  dispose() {

    let key: string;
    for (key in this._gltfs) {

      this._logger.trace(`${key}`);
      disposeGltf(this._gltfs[key]);
      this._gltfs[key] = undefined;
    }
  }


}
