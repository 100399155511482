import { DestroyRef, Injectable, inject } from '@angular/core';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr';
import { Subject, takeUntil } from 'rxjs';
import { SecurityService } from '../authentication/security.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { ISignalREvent, SignalREntityType } from 'src/app/core/model/signal-r.model';
import { MSALInterceptorConfigFactory } from 'src/app/config.auth';
import { NGXLogger } from 'ngx-logger';
import { URL_BASE } from 'src/environments/environment';

export const SIGNALR_ENDPOINT: string = '/hub/notificationhub';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private readonly _destroying$ = new Subject<void>();
  private _destroyRef = inject(DestroyRef);
  private _hubConnection: HubConnection = <HubConnection>{};
  private _signalRHubUrl: string = '';
  private signalRSource = new Subject<ISignalREvent>();
  messageReceived$ = this.signalRSource.asObservable();

  constructor(
    private _securityService: SecurityService,
    private _configurationService: ConfigurationService,
    private _logger: NGXLogger
  ) {

    this._destroyRef.onDestroy(() => this._OnDestroy());

    this._securityService.isAuthenticatedUpdate$
      .pipe(
        takeUntil(this._destroying$)
      ).subscribe((isAuthorized: boolean) => {

        _configurationService.whenReady$
          .subscribe(() => {

            this._signalRHubUrl = this._configurationService.serverSettings.signalRHubUrl;
            if (isAuthorized) {

              this.init();
            } else {

              this.stop();
            }
          });

        //this.transmitFakeMessages();
      })
  }


  private transmitFakeMessages(index: number = 1) {

    setTimeout(() => {
      this.signalRSource.next({
        entityType: SignalREntityType.Unknown,
        entityId: 0,
        message: `FAKE MESSAGE ${index}`,
        status: 'fake'
      });
      this.transmitFakeMessages(++index);
    }, 10000)
  }


  private stop() {

    this._hubConnection.stop();
  }


  /**
   * Called when user is authenticated.
   */
  private init() {

    this.register();
    this.establishConnection();
    this.registerHandlers();
  }


  /**
   * Manually provide auth token to SignalR service
   * Msal interceptor will not intercept negotiation hub call
   */
  private register(): void {

    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${URL_BASE.SIGNALR}${SIGNALR_ENDPOINT}`, <IHttpConnectionOptions>{

        // https://stackoverflow.com/questions/54153876/signalr-hub-authorize-attribute-doesnt-work
        accessTokenFactory: async () => {

          let scopes: string[] = ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read'];
          // MSALInterceptorConfigFactory().protectedResourceMap.forEach((pr: any) => {

          //   if (pr.endpoint == SIGNALR_ENDPOINT) {

          //     scopes = pr.scopes.read;
          //   }
          // });

          const accessToken = await this._securityService.acquireTokenSilent(scopes);
          return accessToken;
        },
        logger: LogLevel.Warning,
        logMessageContent: true
      })
      .withAutomaticReconnect()
      .build();
  }


  private establishConnection(): void {

    this._hubConnection.start()
      .then(() => {

        console.log('Connection established ...');
      })
      .catch(() => {
        
        // Verify SignalRHubUrl which changes whenever ACA revision changes
        console.warn('Auto reconnect ...')
      });
  }

  private registerHandlers() {

    this._hubConnection.on('UpdatedOrderState', (msg: ISignalREvent) => {

      this._logger.trace('SignalR message', msg);
      if (SignalREntityType.Unknown === msg.entityType) {

        this._logger.trace(msg.message);
        this.signalRSource.next(
          {
            entityType: msg.entityType,
            entityId: msg.entityId,
            message: msg.status,
            status: msg.status
          }
        );
        return;
      }

      const message = `Order ${msg.entityId}: ${msg.status}`;
      this.signalRSource.next(
        {
          entityType: msg.entityType,
          entityId: msg.entityId,
          message: message,
          status: msg.status
        }
      );
    });
  }


  private _OnDestroy(): void {
    
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


}
