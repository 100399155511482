import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import { ZERO } from "../my-three/utils";
import { LinearFilter, RGBAFormat, Vector3, VideoTexture } from "three";
import * as THREE from 'three';

export type VideoPipelineState = {
    audioDistance: number;
    pause: boolean;
    playerPosition: Vector3;
    position?: Vector3;
    src: MediaStream | string | HTMLVideoElement | null;
    volume: number;
}


export class VideoPipelineComponent extends MyOptyxComponent {

    protected override state: VideoPipelineState = {
        audioDistance: 3,
        pause: false,
        playerPosition: new Vector3(),
        position: undefined,
        src: null,
        volume: 1
    };

    // Pending state initialized to match current state.
    override pendingState: VideoPipelineState = {
        audioDistance: 3,
        pause: false,
        playerPosition: new Vector3(),
        position: undefined,
        src: null,
        volume: 1
    };

    // Events
    private readonly _onEnded = new Subject<boolean>();
    readonly onEnded$ = this._onEnded.asObservable();
    private readonly _onPause = new Subject<boolean>();
    readonly onPause$ = this._onPause.asObservable();
    private readonly _onPlay = new Subject<boolean>();
    readonly onPlay$ = this._onPlay.asObservable();
    private readonly _textureCreated = new Subject<VideoTexture | undefined>();
    readonly textureCreated$ = this._textureCreated.asObservable();

    private readonly _logger = getLogger();
    private _video: HTMLVideoElement | null = null;
    private _texture: VideoTexture | null = null;


    constructor(destroyRef: DestroyRef, private readonly three: typeof THREE) {
        super(destroyRef);
    }


    /**
     * Basic volume management by distance from video source
     */
    private applyAudioSettings(state: VideoPipelineState) {

        if (this._video && state.playerPosition && state.position && !state.playerPosition.equals(ZERO)) {

            const newVolume = Math.min(1, state.volume * (state.audioDistance / (state.position.distanceTo(state.playerPosition) * 2.5)));
            this._video.volume = .1 < newVolume ? newVolume : 0;
        }
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {

        if (this.pendingState.src !== this.state.src) {

            this.configureVideo(this.pendingState);
        }

        if (this._video) {

            if (this.pendingState.pause && !this._video.paused) {

                this._video.pause();
                this._onPause.next(true);
            } else if (!this.pendingState.pause && this._video.paused) {

                this._video.play();
                this._onPlay.next(true);
            }

            this.applyAudioSettings(this.pendingState);
        }
    }


    private configureVideo(state: VideoPipelineState) {

        this.releaseTexture();

        if (!state.src) {

            this._textureCreated.next(undefined);
            this._video = null;
            return;
        }

        if (state.src instanceof HTMLVideoElement) {

            this._video = state.src;
        } else {

            this._video = this.createVideoElement();

            if (typeof state.src === 'string') {

                this._video.src = state.src;
            } else {

                this._video.srcObject = state.src;
            }

            this._video.load();
        }

        const that = this;
        this._video.onended = function (e) {

            that.pendingState.pause = that.state.pause = true;
            that._onEnded.next(true);
        }

        this._video.style.objectFit = 'contain';

        this._texture = new this.three.VideoTexture(this._video);
        // this._texture.minFilter = LinearFilter;
        // this._texture.magFilter = LinearFilter;
        // this._texture.format = RGBAFormat;

        (this._texture as any).encoding = (this.three as any).sRGBEncoding

        this._textureCreated.next(this._texture);
    }


    private createVideoElement() {

        const video = document.createElement('video');
        video.crossOrigin = 'anonymous';
        video.autoplay = true;
        video.controls = true;
        video.muted = true;
        video.loop = true;

        return video;
    }


    override getState(): VideoPipelineState {

        return this.state;
    }


    override onDestroy(): void {

        if (this._video) {

            this._video.pause();
            (this._video as any) = null;
        }

        this.releaseTexture();
    }


    private releaseTexture() {

        if (this._texture) {

            this._texture.dispose();
            this._texture = null;
        }
    }


    // If overriding be sure to call base method.
    // override onInit(): void {
    //     super.onInit();
    // }

}