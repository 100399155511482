import { Color, DoubleSide, Material, Mesh, MeshStandardMaterial, Object3D, Object3DEventMap, Quaternion, Vector3 } from "three";
import { Vector3Obj } from "../util/utils";
import { MaterialData } from "../component/material-data-loader.component";
import * as THREE from 'three'

export const FORWARD = { x: 0, y: 0, z: -1 } as const;
export const BACKWARD = { x: 0, y: 0, z: 1 } as const;
export const UP = { x: 0, y: 1, z: 0 } as const;
export const ZERO = { x: 0, y: 0, z: 0 } as const;

export enum TransformMode { ROTATE = 'rotate', SCALE = 'scale', TRANSLATE = 'translate' }
export enum TransformSpace { WORLD = 'local', LOCAL = 'world' }

export type Size = { w: number, h: number }

export function isMeshStandardMaterial(material: Material): material is MeshStandardMaterial {

  return material && material.type === 'MeshStandardMaterial';
}

/**
 * Cloned objects share material references. Only the geometry is cloned.
 * Changing materials for a specific cloned object requires creating and applying a new material.
 * @param materialData 
 */
export function applyMaterial(materialData: MaterialData, three: typeof THREE, scene?: Object3D): MeshStandardMaterial | undefined {

  let applied = false;
  let newMaterial: MeshStandardMaterial | undefined;
  if (scene) {

    const meshes = getMeshesByMaterialName(scene, materialData.name);

    for (let i = 0; i < meshes.length; i++) {

      const meshMaterial = meshes[i].material;

      if (Array.isArray(meshMaterial)) {

        for (let j = 0; j < (meshMaterial as Material[]).length; j++) {

          if (((meshMaterial) as Material[])[j].name === materialData.name) {

            newMaterial = new three.MeshStandardMaterial();  // ((meshes[i].material) as MeshPhysicalMaterial[])[j].clone();
            newMaterial.name = materialData.name;
            //newMaterial.blendColor = new Color(0x000000);
            // newMaterial.blending = NormalBlending;
            // newMaterial.displacementBias = 0;
            // newMaterial.displacementMap = null;
            // newMaterial.displacementScale = 1;
            // newMaterial.dithering = false;
            // newMaterial.emissive = new Color(0x000000);
            // newMaterial.emissiveIntensity = 1;
            // newMaterial.emissiveMap = null;
            // newMaterial.envMap = null;
            // newMaterial.envMapIntensity = 1;
            // newMaterial.flatShading = false;
            // newMaterial.fog = true;
            // newMaterial.forceSinglePass = false;
            // newMaterial.lightMap = null;
            // newMaterial.lightMapIntensity = 1;
            newMaterial.metalness = 0;
            // newMaterial.metalnessMap = null;
            // newMaterial.normalMap = null;
            // newMaterial.premultipliedAlpha = false;
            newMaterial.roughness = 1;  //0.5;
            // newMaterial.roughnessMap = null;
            //newMaterial.shadowSide = 0;
            //newMaterial.side = FrontSide;
            //newMaterial.toneMapped = true;
            //newMaterial.transparent = false;
            newMaterial.map = materialData.map;
            newMaterial.alphaMap = materialData.alphaMap;
            newMaterial.needsUpdate = true;
            (meshes[i].material as Material[])[j] = newMaterial;

            applied = true;
          }
        }
      } else if ((meshMaterial as Material).name === materialData.name) {

        newMaterial = new three.MeshStandardMaterial(); //((meshes[i].material) as MeshPhysicalMaterial).clone();
        newMaterial.name = materialData.name;
        // newMaterial.blendColor = new Color(0x000000);
        // newMaterial.blending = NormalBlending;
        // newMaterial.displacementBias = 0;
        // newMaterial.displacementMap = null;
        // newMaterial.displacementScale = 1;
        // newMaterial.dithering = false;
        // newMaterial.emissive = new Color(0x000000);
        // newMaterial.emissiveIntensity = 1;
        // newMaterial.emissiveMap = null;
        // newMaterial.envMap = null;
        // newMaterial.envMapIntensity = 1;
        // newMaterial.flatShading = false;
        // newMaterial.fog = true;
        // newMaterial.forceSinglePass = false;
        // newMaterial.lightMap = null;
        // newMaterial.lightMapIntensity = 1;
        newMaterial.metalness = 0;
        // newMaterial.metalnessMap = null;
        // newMaterial.normalMap = null;
        // newMaterial.premultipliedAlpha = false;
        newMaterial.roughness = 1;  //0.5;
        // newMaterial.roughnessMap = null;
        //newMaterial.shadowSide = 0;
        newMaterial.side = DoubleSide;    // Needed because we allow flipping the texture
        //newMaterial.toneMapped = true;
        //newMaterial.transparent = false;
        newMaterial.map = materialData.map;
        newMaterial.alphaMap = materialData.alphaMap;
        newMaterial.needsUpdate = true;
        (meshes[i].material as Material) = newMaterial;

        applied = true;
      } 
    }
  }

  return newMaterial;
}


export function disposeGltf(gltf: any) {

  if (gltf && gltf.scene) {

    disposeScene(gltf.scene);
  }
}


export function disposeMaterial(material: any): void {

  material.dispose();

  // dispose textures
  let key: string;
  for (key of Object.keys(material)) {

    const value = material[key];
    if (value && typeof value === 'object' && 'minFilter' in value) {

      value.dispose();
    }
  }
}


export function disposeScene(scene: Object3D<Object3DEventMap>) {

  scene.traverse((object: any) => {
    if (!object.isMesh) return;

    object.geometry.dispose();

    if (object.material.isMaterial) {

      disposeMaterial(object.material);
    } else {

      // an array of materials
      let material: any;
      for (material of object.material) {

        disposeMaterial(material);
      }
    }
  });
}


/**
 * Hex string format '#000000FF'
 */
export function alphaFromHexString(hexString: string): number {

  if (9 > hexString.length || '#' !== hexString[0]) {

    return 1;
  }

  return parseInt(`${hexString[7]}${hexString[8]}`, 16);
}


/**
 * Hex string format '#000000FF'
 */
export function colorFromHexString(hexString: string): Color {

  if (7 > hexString.length || '#' !== hexString[0]) {

    return new Color(Color.NAMES.black);
  }

  return new Color(hexString.substring(0, 7));
}


/**
 * Hex string format '#000000FF'
 */
export function opacityFromHexString(hexString: string): number {

  if (9 > hexString.length || '#' !== hexString[0]) {

    return 1;
  }

  return parseInt(`${hexString[7]}${hexString[8]}`, 16) / 255;
}


/**
 * Returns the material(s) associated with a Glb/Gltf model. 
 * If a model has multiple materials then three creates a Mesh for each material and combines them to produce the unified rendering.
 * Note: Three supports arrays of materials that get mapped to faces of a geometry by index which is out of scope here. 
 * (https://dustinpfister.github.io/2018/05/14/threejs-mesh-material-index/#google_vignette)
 * @param model 
 * @returns 
 */
export function getMaterials(model: Object3D): Material[] {

  let materials: Material[] = [];

  if (!model || 1 > model.children.length) {

    return materials
  };

  for (var child of model.children) {

    getMaterial(child, materials);
  }

  return materials;
}


export function getMaterialByName(model: Object3D, name: string): Material | undefined {

  return getMaterials(model).find(m => m.name === name);
}


function getMaterial(child: any, materials: Material[]): void {

  if (!child.name || 1 > child.name.length) {
    
    return;
  }

  if ('Mesh' === child.type) {

    if (Array.isArray((child as Mesh).material)) {

      materials.concat((child as Mesh).material);
    } else {

      materials.push((child as Mesh).material as Material);
    }
  }

  for (var child of child.children) getMaterial(child, materials);
}


/**
 * Returns the mesh(es) associated with a material by material name. 
 * If a model has multiple materials then three creates a Mesh for each material and combines them to produce the unified rendering.
 * Note: Three supports arrays of materials that get mapped to faces of a geometry by index which is out of scope here. 
 * (https://dustinpfister.github.io/2018/05/14/threejs-mesh-material-index/#google_vignette)
 * @param model 
 * @param materialName 
 * @returns 
 */
export function getMeshesByMaterialName(model: Object3D, materialName: string): Mesh[] {

  let meshes: Mesh[] = [];

  for (var child of model.children) {

    getMeshByMaterialName(child, meshes, materialName);
  }

  return meshes;
}


function getMeshByMaterialName(child: any, meshes: Mesh[], materialName: string): void {

  materialName = materialName.toLocaleLowerCase();
  if ('Mesh' === child.type) {

    if (Array.isArray((child as Mesh).material)) {

      for (let i = 0; i < ((child as Mesh).material as Material[]).length; i++) {

        if (0 === ((child as Mesh).material as Material[])[i].name.toLocaleLowerCase().localeCompare(materialName)) {

          meshes.push(child);
          break;
        }
      }
    } else {

      if (0 === ((child as Mesh).material as Material).name.toLocaleLowerCase().localeCompare(materialName)) {

        meshes.push(child);
      }
    }
  }

  for (var child of child.children) {

    getMeshByMaterialName(child, meshes, materialName);
  }
}


export function setMeshMaterialByName(model: Object3D, material: Material) {

  const materialName = material.name.toLocaleLowerCase();
  for (const mesh of getMeshesByMaterialName(model, material.name)) {

    if ('Mesh' === mesh.type) {

      if (Array.isArray((mesh as Mesh).material)) {
  
        for (let i = 0; i < ((mesh as Mesh).material as Material[]).length; i++) {
  
          if (0 === ((mesh as Mesh).material as Material[])[i].name.toLocaleLowerCase().localeCompare(materialName)) {
  
            (mesh as any).material[i] = material;
            break;
          }
        }
      } else {
  
        if (0 === ((mesh as Mesh).material as Material).name.toLocaleLowerCase().localeCompare(materialName)) {
  
          (mesh as any).material = material;
        }
      }
    }
  }
}


/**
 * Calculate direction vector given a quaternion rotation and normalized direction vector.
 * 
 * @param quaternion 
 * @param direction Forward is -1 in the z direction
 * @returns 
 */
export function getNormalizedDirectionFromQuaternion(quaternion: Quaternion, direction: Vector3Obj = FORWARD): Vector3 {

  return new Vector3().copy(direction).applyQuaternion(quaternion);
}


/**
 * Calculate position vector from current position in a given direction and distance.
 * @param currentPosition 
 * @param direction 
 * @param scalarDistance 
 * @returns Vector3
 */
export function getPosition(currentPosition: Vector3Obj, direction: Vector3Obj, scalarDistance: number): Vector3 {

  const position = new Vector3();

  // Postion = currentPosition + (direction * distance)
  position.addVectors(currentPosition, new Vector3().copy(direction).multiplyScalar(scalarDistance));

  return position;
}


export function translateObject(object: Object3D<Object3DEventMap>, x: number, y: number, z: number): void {

  object.translateX(x);
  object.translateY(y);
  object.translateZ(z);
}