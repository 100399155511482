import { Types, defineComponent } from 'bitecs';

/**
 * Object components represent the group containing the related objects. It has position, rotation and scale values
 */
export const ObjectComponent = defineComponent({
    id: Types.i32
});

/**
 * Child object components are within the parent group and track their own, local values.
 */
export const ChildObjectComponent = defineComponent({
    parentEid: Types.eid,
});