<div #importObjectSidebarComponent tabindex="0" [autofocus]="setFocus()" (blur)="handleBlur()" 
    class="fixed h-screen flex flex-col overflow-hidden top-0 bottom-0 w-full z-50 md:max-w-[500px] bg-[#0e0f10] shadow-xl 
            {{ isOpen() ? 'right-0' : '-right-full' }} transition-all duration-300">
    <!-- close icon -->
    <div class="flex flex-row items-center pl-4 space-x-2">
        <div class="text-4xl w-10 h-[60px] flex justify-start items-center cursor-pointer transition"
            (click)="handleClose()">
            <ng-icon class="text-3xl transition {{ closeClicked() ? 'rotate-[2turn]' : 'rotate-0' }}"
                name="bootstrapXLg" />
        </div>
        <h1 class="text-2xl md:text-3xl font-bold overflow-hidden overflow-ellipsis">Design Objects</h1>
    </div>

    <div class="flex-1 flex overflow-hidden">
        <!-- Image count {{sponsorImageService.originalSponsorImageCount}} -->
        <!-- https://stackoverflow.com/questions/69400560/how-to-change-scrollbar-when-using-tailwind-next-js-react -->
        <!-- https://stackoverflow.com/questions/70539607/how-to-make-scrollable-sidebar-fixed-in-tailwindcss -->
        <div
            class="flex-1 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:[width:15px] [&::-webkit-scrollbar-thumb]:bg-accent px-4">
            <!-- The following padding seems to adjust for stuff at top of sidebar. If scrollable area matched heigh of sidebar, don't think it would be needed -->
            <div class="flex flex-col space-y-4 items-center justify-center pb-32">
                @for (designObject of designObjectService.importableDesignObjects().data; track $index) {

                    <app-import-design-object-card class="w-full" [DesignObject]="designObject" />
                
                }
                <app-pagination [ItemCount]="designObjectService.importableDesignObjects().count"
                    [PageIndex]="designObjectService.importableDesignObjects().pageIndex"
                    [PageSize]="designObjectService.importableDesignObjects().pageSize"
                    (pageSelected)="designObjectService.loadPage($event)" />
            </div>
        </div>
    </div>
</div>