<div (click)="onSelect(clippingPlaneAssignment())" 
    class="flex flex-col space-y-2 {{ IsActive() && clippingPlaneAssignment().isAssigned ? '':  'bg-slate-700 rounded-md p-2'}}" >
    <div class="flex flex-row w-full items-center justify-between">
            
        <div>{{ clippingPlaneTypes[clippingPlaneAssignment().clippingPlane?.planeType ?? 0] }}&nbsp;{{ Index() }}</div>        
        <app-checkbox Label="Active" [Checked]="clippingPlaneAssignment().isAssigned" (Toggled)="onToggleIsAssigned()" />

    </div>


    @if (IsActive() && clippingPlaneAssignment().isAssigned) {
    
        <div class="flex flex-row justify-between items-center">
            <span>Position</span>
            <app-common-vector3-input [Value]="clippingPlaneAssignment().positionObj" Label="Position"
                (OnChange)="handlePositionChange($event, clippingPlaneAssignment())" [IsActive]="IsActive()" />
        </div>
        
        <div #sliderBasedInputs class="flex flex-row space-x-2 items-center">
            <div class="w-1/2">Rotation</div>
            <input
                class="w-1/2 text-primary text-center placeholder:text-primary h-10 outline-accent"
                type="number" [value]="rotationInitializer() | number : '1.1-4' | noComma" step="any"
                (focus)="setFocusRotation($event)" (blur)="onBlur()"
                (input)="handleRotationInput($event)">
        </div>

        <div class="flex flex-row justify-between items-center">
            <span>Scale</span>
            <app-common-vector3-input [Value]="clippingPlaneAssignment().scaleObj" [EnableEqual]="true"
                (OnChange)="handleScaleChange($event, clippingPlaneAssignment())" Label="Scale" [IsActive]="IsActive()" />
        </div>

    }
</div>