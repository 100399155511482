import { PropInputs } from "./matterport.model";
import { ISubscription, MpSdk, Scene } from "static/sdk";
import { ComponentInteractionType } from "projects/mp-common/src";
import { ClippingPlaneAssignment, CrudState, DesignVideo, PositionAdjustment, VideoAssignment, VideoProp, VideoPropOptions } from "projects/my-common/src/model";
import { IShowroomVideoProp } from "../showroom/video-prop.model";
import { IStage } from "../showroom/showroom.model";
import { DestroyRef } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { Euler, Texture, Vector3, VideoTexture } from "three";
import {
  ActiconAnimationState, GltfManager, MaskLoaderComponent, MaskLoaderSource, MaterialDataLoaderComponent, MyOptyxComponent, PlayListComponent,
  PlayerState, ShakaComponent, TextureLoaderComponent, TextureManager, TransformMode, TransformSpace, Vector3Obj, VideoControlsComponent,
  VideoPipelineComponent, getLogger, urlExists, vector3OneObj, vector3ZeroObj
} from "projects/my-common/src";
import { MPObjectPropComponent, OBJECT_PROP_COMPONENT_TYPE } from "projects/mp-common/src/sdk-components/MPObjectProp";
import { EcsWorld, VideoPropEntity } from "projects/my-common/src/ecs";
import { VideoRendererComponent } from "projects/my-common/src/component/video-renderer.component";
import { PropType } from "projects/my-common/src/model";
import { ShowroomMode } from "src/app/state/showroom-state";
import { PropAdjustment } from "projects/my-common/src/scene/prop-adjustment";

const ENCODING_TEST_PATTERN = 'https://stmyoptyxprod1.z19.web.core.windows.net/encoding-test-pattern/46479444-2cdd-481c-ad42-b3ca3a89734f.m3u8';

interface IChildSubscription {

  child: IShowroomVideoProp
  subscription: Subscription
}

export class VideoNodeInputs extends PropInputs {
  public aspect = 1;
  public imageAspect = 0;
  public lookAt?: Vector3;
  public tunerUrls: string[] = [];
  public maskLoaderSrc = '';

  public videoPlaneRendererTransparent = false;
  public videoPlaneRendererScale = vector3OneObj;
  public videoPlaneRendererPosition = vector3ZeroObj;

  public snapshotPlaneRendererTransparent = false;
  public snapshotPlaneRendererScale = vector3OneObj;
  public snapshotPlaneRendererPosition = vector3ZeroObj;
  public snapshotCanvasRendererTextureRes = { "w": 1024, "h": 1024 };
  public snapshotCanvasImageSrcPosition = vector3ZeroObj;
  public snapshotDefaultSrc = DEFAULT_SNAPSHOT_URL;
}

// TODO - Evaluate using SVG
//const DEFAULT_SNAPSHOT_URL = '/assets/svg/MyOptyxLogo_BlackYellow.jpg';
const DEFAULT_SNAPSHOT_URL = '/assets/svg/wood-grain-MyOptyx_16x9.png';

/**
 * The integrated MyOptyx and Matterport components required to implement a Showroom VideoProp.
 *  
 * The primary visual is comprised of 3 planes:
 * 1. Backing plane reflecting the Prop core dimensions, can be transparent or colored.
 * 2. Video plane. Scaled to maintian Aspect while fitting within the bounds of the backing plane.
 * 3. Snapshot plane. An image displayed when the player is Idle. Scaled to maintian Aspect while fitting within the bounds of the backing plane.
 */
export class VideoPropNode implements IShowroomVideoProp {

  propId = 0;
  readonly type = PropType.VIDEO;

  /**
   * Configuration properties
   */
  inputs!: VideoNodeInputs;
  /**
   * Core Matterport node. Base Prop position, scale, rotation/quaternion.
   */
  node!: Scene.INode;

  //
  // MyOptyx components
  //

  /**
   * Retrives, caches, updates masks for plane renderer. Triggers planeRenderer updates.
   */
  maskLoader!: MaskLoaderComponent;
  /**
    * Renders video texture for player to see.
    * Inputs: texture
    */
  videoDisplay!: VideoRendererComponent;
  videoPlaneRendererProp!: MPObjectPropComponent;
  /**
   * Acticons based video control prop.
   */
  nextActiconProp!: MPObjectPropComponent;
  /**
   * Acticons based video control prop.
   */
  pauseActiconProp!: MPObjectPropComponent;
  /**
   * Acticons based video control prop.
   */
  playActiconProp!: MPObjectPropComponent;
  /**
   * Coordinates state across the Acticon video controls.
   */
  videoControls!: VideoControlsComponent;
  /**
   * For the Acticons
   */
  materialDataLoader!: MaterialDataLoaderComponent;

  /**
   * Manage and communicate current Image Assignment from available Assignments
   */
  assignmentPlayList!: PlayListComponent<VideoAssignment>;

  //
  // Shareable MyOptyx components
  //

  /**
   * Retrives, caches, updates textures for snapshotPlaneRenderer. Triggers planeRenderer updates.
   * Inputs: src
   * Outputs: texture
   */
  snapshotTextureLoader!: TextureLoaderComponent;
  /**
   * Downloads hls video stream. Outputs HtmlVideoComponent to videoPipeline.
   * Inputs src -> ouptput video.
   */
  hlsStreamer!: ShakaComponent;
  /**
    * Renders video stream, outputs texture to PlaneRenderer.
    * Controls video audio, play and pause.
    * Inputs: htmlVideoComponent 
    * Ouptput: videoTexture.
    */
  videoPipeline!: VideoPipelineComponent;


  /**
   * Tracks adjustments to be applied to Prop based on the players PositionId.
   */
  adjustments: PropAdjustment[] = [];

  private readonly _logger = getLogger();
  private readonly _subscriptions: Subscription[] = [];
  private readonly _childSubscriptions: IChildSubscription[] = [];
  private readonly _parentSubscriptions: Subscription[] = [];
  private readonly _iSubscriptions: ISubscription[] = [];

  // Events
  private _videoPropSelectedSource = new Subject<number>();
  videoPropSelected$ = this._videoPropSelectedSource.asObservable();
  private _designVideoSelectedSource = new Subject<number>();
  designVideoSelected$ = this._designVideoSelectedSource.asObservable();

  /**
   * Track DesignVideos to broadcast selected DesignVideo events
   */
  private _designVideos?: DesignVideo[];
  private _transforming = false;
  public get transforming(): boolean {

    return this._transforming;
  }
  private _transitioning = false;

  private readonly _playPressedSource = new Subject<boolean>();
  readonly playPressed$ = this._playPressedSource.asObservable();
  private readonly _pausePressedSource = new Subject<boolean>();
  readonly pausePressed$ = this._pausePressedSource.asObservable();
  private readonly _nextPressedSource = new Subject<boolean>();
  readonly nextPressed$ = this._pausePressedSource.asObservable();
  private readonly _positionChanged = new Subject<Vector3>();
  readonly positionChanged$ = this._positionChanged.asObservable();
  private readonly _rotationChanged = new Subject<Euler>();
  readonly rotationChanged$ = this._rotationChanged.asObservable();
  private readonly _scaleChanged = new Subject<Vector3>();
  readonly scaleChanged$ = this._scaleChanged.asObservable();
  private _videoAssignmentChangedSource = new Subject<VideoAssignment>();
  videoAssignmentChanged$ = this._videoAssignmentChangedSource.asObservable();  

  //
  // Parent child video sync
  //
  private readonly _snapshotTextureUpdated = new BehaviorSubject<Texture | undefined>(undefined);
  readonly snapshotTextureUpdated$ = this._snapshotTextureUpdated.asObservable();
  private readonly _videoTextureCreated = new BehaviorSubject<VideoTexture | undefined>(undefined);
  readonly videoTextureCreated$ = this._videoTextureCreated.asObservable();
  private readonly _onVideoEndedSource = new BehaviorSubject<boolean>(false);
  readonly onVideoEnded$ = this._onVideoEndedSource.asObservable();
  private readonly _onVideoPausedSource = new BehaviorSubject<boolean>(false);
  readonly onVideoPaused$ = this._onVideoPausedSource.asObservable();
  private readonly _onVideoPlayingSource = new BehaviorSubject<boolean>(false);
  readonly onVideoPlaying$ = this._onVideoPlayingSource.asObservable();
  private readonly _videoAspectSource = new BehaviorSubject<number>(1);
  readonly onVideoAspect$ = this._videoAspectSource.asObservable();
  //
  // End parent child video sync
  //

  private _myOptyxComponents: MyOptyxComponent[] = [];
  private _currentVideoPropOptions = new VideoPropOptions();

  get currentVideoAssignment(): VideoAssignment | undefined {

    return this.assignmentPlayList.getCurrentItem();
  }


  constructor(readonly destroyRef: DestroyRef,
    private videoProp: VideoProp,
    private readonly scene: MpSdk.Scene.IObject,
    private readonly textureManager: TextureManager,
    private readonly gltfManager: GltfManager,
    private readonly stage: IStage,
    private readonly world: EcsWorld) {

    // Experiment with state machine
    // const videoNodeActor = createActor(videoNodeMachine).start();
    // videoNodeActor.subscribe((state) => {

    //   this._logger.trace(state);
    // });
    // videoNodeActor.send({ type: "user.pressedPlay" });
    // End experiment

    destroyRef.onDestroy(() => this.onDestroy());

    this.propId = videoProp.id;
    this.node = scene.addNode();
    this.textureManager = textureManager;

    // The Matterport component should be initialized first
    this.addMPComponents();
    this.node.start();

    // Followed by MyOptyx components and binding definitions
    this.addComponents();
    this.createBindings(scene);

    // Initialie MyOptyx components in dependency order
    this.videoDisplay.init();
    this.maskLoader.init();

    this.snapshotTextureLoader.init();
    this.hlsStreamer.init();
    this.videoPipeline.init();
    this.videoControls.init();
    this.assignmentPlayList.init();
    this.setVideoDisplayVisibility();

    this.applyPropInputs(videoProp);
    this.setPositionAdjustments(videoProp);
    this.createInteractionEventSpies();

    this.updateWorld();
  }


  private addComponents(): void {

    // Multi-video coordination
    this._myOptyxComponents.push(
      this.assignmentPlayList = new PlayListComponent(this.destroyRef)
    );
    // Handle changes in current Video Assignment
    this._subscriptions.push(
      this.assignmentPlayList.source$.subscribe(async (currentVideoAssignment) => {

        if (!currentVideoAssignment) {

          return;
        }

        // If there is no Design Image for currentImageAssignment then we are done.
        const designVideo = this._designVideos?.find(dv => dv.id === currentVideoAssignment.designVideoId);
        if (!designVideo) {

          this._logger.trace('Design Video for current Video Assignment source not found', currentVideoAssignment);
          return;
        }

        this.videoDisplay.pendingState.enableInteraction = currentVideoAssignment.enableInteraction;
        this.videoDisplay.apply();

        this._logger.trace(`Current Video Assignment changed, setting Design Video: ${designVideo.videoUrl}`, designVideo);
        let source = designVideo.hlsUrl;
        if (!await urlExists(source)) {

          source = ENCODING_TEST_PATTERN;
        }

        if (source !== this.hlsStreamer.pendingState.source) {

          // Pause video pipeline until receipt of new video stream.
          this.videoPipeline.pendingState.pause = false;
          this.videoPipeline.apply()
          // Supply new video source to hlsLoader.
          this.hlsStreamer.pendingState.source = source;
          this.hlsStreamer.apply();
        } else {

          // We must be resuming from a pause state
          this.videoPipeline.pendingState.pause = false;
          this.videoPipeline.apply();
        }

        /**
         * A Design Image can be used in multiple Props. Broadcasting its selection is not helpful in determining which Prop or Assignment
         * is the focus of the activity.
         * Broadcasting Prop select implies an Interaction with the Prop which is not the case when the Acticon is clicked.
         * The best value to broadcast is Image Assignment which is unique and references the specific context including Prop and the Design.
         */
        if (!this.assignmentPlayList.firstDisplay) {  // Invoke when next button clicked, not upon initial load.

          this._logger.trace(`Broadcasting Video Assignment selected`, currentVideoAssignment);
          this._videoAssignmentChangedSource.next(currentVideoAssignment);
        }

        // Cancel Next Acticon pressed state after giving time for pressed animation to complete.
        // if (ActiconAnimationState.Pressed === this.nextActicon.getAnimationState()) {

        //   setTimeout(() => {
        //     this.nextActicon.pressed(false);
        //     this.updateWorld();
        //   }, 450);
        // }
      })
    );

    // Snapshot plane texture management.
    this._myOptyxComponents.push(
      this.snapshotTextureLoader = new TextureLoaderComponent(this.destroyRef, this.textureManager)
    );
    this._subscriptions.push(
      this.snapshotTextureLoader.textureLoaded$.subscribe((texture) => {

        this.handleSnapshotTextureUpdated(texture);
        if (this._currentVideoPropOptions.isOrchestrationParent) {

          this._snapshotTextureUpdated.next(texture);
        }
      })
    );

    // Video display
    this._myOptyxComponents.push(
      this.videoDisplay = new VideoRendererComponent(this.destroyRef, this.textureManager.three, this.world)
    );
    this._subscriptions.push(this.videoDisplay.groupCreated$.subscribe((gltf) => this.videoPlaneRendererProp.inputs.object = gltf));
    this._subscriptions.push(this.videoDisplay.enableInteractionUpdated$.subscribe((isEnabled) => this.videoPlaneRendererProp.inputs.enableInteraction = isEnabled));


    // Mask management
    this._myOptyxComponents.push(
      this.maskLoader = new MaskLoaderComponent(this.destroyRef, this.textureManager)
    );
    this._subscriptions.push(
      this.maskLoader.alphaMapUpdated$.subscribe((alphaMap) => {

        // Once set, Mask Loader maintains and updates the Data Texture efficiently without recreating it which is expensive.
        this.videoDisplay.pendingState.alphaMap = alphaMap;
        this.videoDisplay.apply();
      })
    );

    // Video content flow
    this._myOptyxComponents.push(
      this.videoPipeline = new VideoPipelineComponent(this.destroyRef, this.textureManager.three)
    );
    this._subscriptions.push(
      this.videoPipeline.textureCreated$.subscribe((texture) => {

        this.handleVideoTextureCreated(texture);
        if (this._currentVideoPropOptions.isOrchestrationParent) {

          this._videoTextureCreated.next(texture);
        }
      }))

    // For Acticons
    this._myOptyxComponents.push(
      this.materialDataLoader = new MaterialDataLoaderComponent(this.destroyRef, this.textureManager).init()
    );

    // Next, Pause Play controls
    this._myOptyxComponents.push(
      this.videoControls = new VideoControlsComponent(this.destroyRef, this.gltfManager, this.materialDataLoader)
    );
    this._subscriptions.push(this.videoControls.nextGltfUpdated$.subscribe((gltf) => this.nextActiconProp.inputs.object = gltf));
    this._subscriptions.push(this.videoControls.nextSizeUpdated$.subscribe((size) => this.updateWorld()));
    this._subscriptions.push(this.videoControls.pauseGltfUpdated$.subscribe((gltf) => this.pauseActiconProp.inputs.object = gltf));
    this._subscriptions.push(this.videoControls.pauseSizeUpdated$.subscribe((size) => this.updateWorld()));
    this._subscriptions.push(this.videoControls.playGltfUpdated$.subscribe((gltf) => this.playActiconProp.inputs.object = gltf));
    this._subscriptions.push(this.videoControls.playSizeUpdated$.subscribe((size) => this.updateWorld()));
    this._subscriptions.push(this.videoControls.playerStateChanged$.subscribe((playerState) => {

      this.setVideoDisplayVisibility()
      this.updateWorld();
    }));
    //
    // If this is child node then broadcast to parent
    //
    this._subscriptions.push(this.videoControls.pausePressed$.subscribe((pressed) => {

      if (pressed && !this._currentVideoPropOptions.isChild) {

        this.videoPipeline.pendingState.pause = true;
        this.videoPipeline.apply();
      }
    })
    )

    // Connect video controls with video pipeline
    this._subscriptions.push(this.videoPipeline.onEnded$.subscribe((ended) => {

      this.handleVideoEnded(ended);
      if (this._currentVideoPropOptions.isOrchestrationParent) {

        this._onVideoEndedSource.next(ended);
      }
    }))
    this._subscriptions.push(this.videoPipeline.onPause$.subscribe((paused) => {

      this.handleVideoPaused(paused);
      if (this._currentVideoPropOptions.isOrchestrationParent) {

        this._onVideoPausedSource.next(paused);
      }
    }))
    this._subscriptions.push(this.videoPipeline.onPlay$.subscribe((playing) => {

      this.handleVideoPlaying(playing);
      if (this._currentVideoPropOptions.isOrchestrationParent) {

        this._onVideoPlayingSource.next(playing);
      }
    }))

    // HLS video streamer
    this._myOptyxComponents.push(
      this.hlsStreamer = new ShakaComponent(this.destroyRef)
    );
    this._subscriptions.push(this.hlsStreamer.videoCreated$.subscribe((video) => {

      this.videoPipeline.pendingState.src = video
      this.videoPipeline.apply();
    }))
    this._subscriptions.push(this.hlsStreamer.onVideoAspect$.subscribe((videoAspect) => {

      this.handleVideoAspect((videoAspect));
      if (this._currentVideoPropOptions.isOrchestrationParent) {

        this._videoAspectSource.next(videoAspect);
      }
    }))

    // Handle video control play pressed
    this._subscriptions.push(
      this.videoControls.playPressed$.subscribe(async (source) => this.assignmentPlayList.next())
    )
  }


  /**
   * Initialize Matterport components.
   * Transforms are applied to the object provided. The proper application of the transforms might need to be be mapped.
   */
  private addMPComponents() {

    this.videoPlaneRendererProp = this.node.addComponent(OBJECT_PROP_COMPONENT_TYPE, {}) as MPObjectPropComponent;
    // When transform changes the Video group's position, rotation and scale broadcast it so that inputs and UI will be updated.
    this._subscriptions.push(this.videoPlaneRendererProp.positionUpdated$.subscribe((position) => this._positionChanged.next(position)));
    this._subscriptions.push(this.videoPlaneRendererProp.rotationUpdated$.subscribe((rotation) => this._rotationChanged.next(rotation)));
    this._subscriptions.push(this.videoPlaneRendererProp.scaleUpdated$.subscribe((scale) => this._scaleChanged.next(scale)));
    this._subscriptions.push(this.videoPlaneRendererProp.transformMouseDown$.subscribe((event) => {

      this._transforming = true;
      this.world?.stop();    // Transform is taking over

      // Capture original values before translation.
      // switch (this.videoPlaneRendererProp.getTransformMode()) {

      //   case TransformMode.TRANSLATE:
      //     break;
      //   case TransformMode.ROTATE:
      //     break;
      //   case TransformMode.SCALE:
      //     break;
      // }

    }));
    this._subscriptions.push(this.videoPlaneRendererProp.transformMouseUp$.subscribe((event) => {

      // Apply/map transforms as required and reset transform from original values if necessary.
      // switch (this.videoPlaneRendererProp.getTransformMode()) {

      //   case TransformMode.TRANSLATE:
      //     break;
      //   case TransformMode.ROTATE:
      //     break;
      //   case TransformMode.SCALE:
      //     break;
      // }

      this.updateWorld();
      this._transforming = false;
      this.world?.start();   // Restore ECS control
    }));

    this.nextActiconProp = this.node.addComponent(OBJECT_PROP_COMPONENT_TYPE, {}) as MPObjectPropComponent;
    this.pauseActiconProp = this.node.addComponent(OBJECT_PROP_COMPONENT_TYPE, {}) as MPObjectPropComponent;
    this.playActiconProp = this.node.addComponent(OBJECT_PROP_COMPONENT_TYPE, {}) as MPObjectPropComponent;
  }


  private applyDefaultInputs(): void {

    if (!this.inputs) {

      this.inputs = new VideoNodeInputs();
    }
    const inputs = this.inputs;

    this.snapshotTextureLoader.pendingState.source = inputs.snapshotDefaultSrc;
    this.snapshotTextureLoader.apply();

    this.videoDisplay.pendingState.snapshotAspect = inputs.imageAspect;
    this.videoDisplay.pendingState.videoAspect = inputs.imageAspect;
    this.videoDisplay.pendingState.transparent = inputs.videoPlaneRendererTransparent;
    this.videoDisplay.apply();
  }


  /**
   * Applies position adjustments to VideoProps based upon specified positionId.
   * If position adjustemnt is not found then default inputs are applied.
   * @param positionId 
   */
  applyPositionAdjustment(): void {

    const positionId = this.stage.currentPlayerPosition?.id;

    if (!positionId || 1 > this.adjustments.length) {

      this.restoreBaseVideoPropPosition();
      return;
    }

    const positionAdjustment = this.adjustments.find(pa => pa.playerPositionId === positionId);
    // If matching PositionAdjustment then apply it
    if (positionAdjustment) {

      if (0 < positionAdjustment.maskUrl.length) {

        this.maskLoader.pendingState.enabled = true;
        this.maskLoader.pendingState.source = positionAdjustment.maskUrl;
        this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.ADJUSTMENT;
      } else {

        this.maskLoader.pendingState.enabled = 0 < this.videoProp.maskUrl.length;
        this.maskLoader.pendingState.source = this.inputs.maskLoaderSrc;
        this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.PROP;
      }
      this.maskLoader.apply();

      this.videoDisplay.pendingState.disableDepth = positionAdjustment.disableDepth;
      this.videoDisplay.pendingState.renderOrder = positionAdjustment.renderOrder;
      this.videoDisplay.pendingState.stencilRef = positionAdjustment.stencilRef;
      this.videoDisplay.pendingState.clippingAssignments = positionAdjustment.clippingAssignments;
      this.videoDisplay.pendingState.visible = !positionAdjustment.hide;
      this.videoDisplay.apply();
    } else { // Set default inputs

      this.restoreBaseVideoPropPosition();
    }

    this.updateWorld();
  }


  /**
   * Update dynamic input values.
   * @param videoProp 
   * @param designVideos 
   */
  private applyPropInputs(videoProp: VideoProp, videoAssignments?: VideoAssignment[], designVideos?: DesignVideo[]): void {

    this.videoProp = videoProp;
    this._designVideos = designVideos;
    if (!this.inputs) {

      this.applyDefaultInputs();
    }
    const inputs = this.inputs;

    this._logger.trace('videoProp, videoAssignments, designVideos', videoProp, videoAssignments, designVideos);
    this.updatePropInputs();
    
    if (0 < videoProp.maskUrl.length) {

      this.maskLoader.pendingState.enabled = true;
    }
    this.maskLoader.pendingState.source = inputs.maskLoaderSrc = videoProp.maskUrl;
    this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.PROP;
    this.maskLoader.apply();

    this.videoPipeline.pendingState.position = new Vector3(this.inputs.position.x, this.inputs.position.y, this.inputs.position.z);
    this.videoPipeline.apply();

    this.snapshotTextureLoader.pendingState.source = inputs.snapshotDefaultSrc;
    this.snapshotTextureLoader.apply();

    this.videoControls.pendingState.position = new Vector3(this.inputs.position.x, this.inputs.position.y, this.inputs.position.z);
    if (designVideos) {

      this.videoControls.pendingState.urls = designVideos.map(dv => dv.url);
      this.videoControls.apply();
      this.preloadVideos();
    } else {

      this.videoControls.pendingState.urls = inputs.tunerUrls;
      this.videoControls.apply();
    }

    if (videoAssignments && 0 < videoAssignments.length) {

      this.assignmentPlayList.pendingState.playListItems = videoAssignments;
      this.assignmentPlayList.apply();
    } else {

      this.assignmentPlayList.pendingState.playListItems = [];
      this.assignmentPlayList.apply();
    }
  }


  /**
   * The transform tool takes over the position, scale and rotation of the group containing all of the VideoProp's Object3D objects.
   * When transforming is complete (i.e. mouse up), the updated input values are reported to world which applies the transforms individually 
   * to each Object3D in the group and the group's original values are restored.
   * @param mode 
   * @param space 
   */
  attachTransformControls(mode: TransformMode, space: TransformSpace) {

    this.videoPlaneRendererProp.attachTransformControls(mode, space);
  }


  private createBindings(scene: MpSdk.Scene.IObject): void {

    this._subscriptions.push(
      this.stage.transitionStarted$.subscribe((transition) => {

        this.setVideoDisplayVisibility();

        // Sometime Video Props are "zoomed" close to camera to maintain an illusion that would otherwise be blocked by hidden artifacts in the virtual space.
        // A side effect of zooming is the Image Prop can cover Object Props positioned behind them that we don't want to hide.
        // Setting depthWrite to false avoids this but also ruins the Image Prop transition effects.
        // So we set deptWrite = true (the default) during transitions and can optionally return it to false after the transition when needed to resolve the side effect.
        //this.videoDisplay.setDepthWrite(true)

        // Custom masks create 'holes' in Video Props to make it appear they are behind virtual space items that don't exist.
        // Those holes do not look right during the transition. 
        // This flag works in tandem with custom mask loading to insure the custom mask is not displayed until the transition is complete.
        this._transitioning = true;

        // Disable custom masks during transitions to hide the 'holes' it creates in the texture.
        // Only disable custom masks sourced from Position Adjustments as they vary from Adjustment to Adjustment.
        // Fixed masks assigned to the Image Prop can remain visible during the transition because the look natural and maintain the illusion.
        if (MaskLoaderSource.ADJUSTMENT === this.maskLoader.pendingState.maskOrigin) {

          this.maskLoader.pendingState.suppressCustomMask = true;
          this.maskLoader.apply();
        }

        const toPositionAdjustment = this.adjustments.find(pa => pa.playerPositionId === transition.destinationPositionId);

        // Switching the mask source here invokes the mask update async process which clears the mask during the transition 
        // in time for the destination mask, if any, to appear at the end of the transition.
        if (toPositionAdjustment && toPositionAdjustment.maskUrl && 0 < toPositionAdjustment.maskUrl.length) {

          this.maskLoader.pendingState.enabled = true;
          this.maskLoader.pendingState.source = toPositionAdjustment.maskUrl;
          this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.ADJUSTMENT;
        } else {

          this.maskLoader.pendingState.enabled = 0 < this.inputs.maskLoaderSrc.length;
          this.maskLoader.pendingState.source = this.inputs.maskLoaderSrc;
          this.maskLoader.pendingState.maskOrigin = MaskLoaderSource.PROP;
        }
        this.maskLoader.apply();
      })
    )

    this._subscriptions.push(
      this.stage.transitionFinished$.subscribe((currentPositionId) => {

        this.setVideoDisplayVisibility();

        if (this._transitioning) {

          // Sometime Image Props are "zoomed" close to camera to maintain an illusion that would otherwise be blocked by hidden artifacts in the virtual space.
          // A side effect of zooming is the Image Prop can cover Object Props positioned behind them that we don't want to hide.
          // Setting depthWrite to false avoids this.
          // The simulateDepth flag state property must have been set by evaluating the Position Adjustment for 'zooming'.
          //this.videoDisplay.setDepthWrite(false);
          this._transitioning = false;

          // Restore custom mask that might have been hidded when transition started.
          this.maskLoader.pendingState.suppressCustomMask = false;
          this.maskLoader.apply();
        }
      })
    );
  }


  bindToChildVideoProp(childVideoPropNode: IShowroomVideoProp): void {

    this._childSubscriptions.push({
      child: childVideoPropNode,
      subscription: childVideoPropNode.nextPressed$.subscribe((pressed) => this.videoControls.nextPressed(pressed))
    });
    this._childSubscriptions.push({
      child: childVideoPropNode,
      subscription: childVideoPropNode.pausePressed$.subscribe((pressed) => this.videoControls.pausePressed(pressed))
    });
    this._childSubscriptions.push({
      child: childVideoPropNode,
      subscription: childVideoPropNode.playPressed$.subscribe((pressed) => this.videoControls.playPressed(pressed))
    });
    childVideoPropNode.bindToParentVideoProp(this);
  }

  bindToParentVideoProp(parentVideoPropNode: IShowroomVideoProp): void {

    this._parentSubscriptions.push(
      parentVideoPropNode.snapshotTextureUpdated$.subscribe((texture) => {

        if (texture) {

          this.handleSnapshotTextureUpdated(texture);
        }
      })
    )
    this._parentSubscriptions.push(
      parentVideoPropNode.videoTextureCreated$.subscribe((texture) => this.handleVideoTextureCreated(texture))
    )
    this._parentSubscriptions.push(
      parentVideoPropNode.onVideoEnded$.subscribe((ended) => this.handleVideoEnded(ended))
    )
    this._parentSubscriptions.push(
      parentVideoPropNode.onVideoPaused$.subscribe((paused) => this.handleVideoPaused(paused))
    )
    this._parentSubscriptions.push(
      parentVideoPropNode.onVideoPlaying$.subscribe((playing) => this.handleVideoPlaying(playing))
    )
    this._parentSubscriptions.push(
      parentVideoPropNode.onVideoAspect$.subscribe((videoAspect) => this.handleVideoAspect(videoAspect))
    )
  }


  private createInteractionEventSpies(): void {

    const that = this;

    const clickEventPath = this.scene.addEventPath(this.videoPlaneRendererProp, ComponentInteractionType.CLICK);

    class ImagePropClickSpy {
      readonly path = clickEventPath;

      constructor(private _videoProp: VideoPropNode) { }

      onEvent(payload: any) {

        //that._videoPropSelectedSource.next(this._videoProp.propId);
        that.updateWorld();
      }
    }

    this._iSubscriptions.push(this.scene.spyOnEvent(new ImagePropClickSpy(this)));

    const videoClickEventPath = this.scene.addEventPath(this.videoPlaneRendererProp, ComponentInteractionType.CLICK);
    class VideoClickSpy {
      readonly path = videoClickEventPath;

      constructor(private _videoProp: VideoPropNode) { }

      onEvent(payload: any) {

        that.setSelected(true);
        that._videoPropSelectedSource.next(this._videoProp.propId);
      }
    }
    this._iSubscriptions.push(this.scene.spyOnEvent(new VideoClickSpy(this)));

    const nextObjectPropClickEventPath = this.scene.addEventPath(this.nextActiconProp, ComponentInteractionType.CLICK);
    class NextObjectPropClickSpy {

      readonly path = nextObjectPropClickEventPath;

      constructor() { }

      onEvent(payload: any) {

        if (that._currentVideoPropOptions.isChild) {

          that._nextPressedSource.next(true);
        }
        that.handleNextPressed(true);
      }
    }
    this._iSubscriptions.push(this.scene.spyOnEvent(new NextObjectPropClickSpy()));

    const nextObjectPropHoverEventPath = this.scene.addEventPath(this.nextActiconProp, ComponentInteractionType.HOVER);
    class NextObjectPropHoverSpy {

      readonly path = nextObjectPropHoverEventPath;

      constructor() { }

      onEvent(payload: any) {

        // hover.false is broadcast immediately after click event even when still hovering.
        if (ActiconAnimationState.Pressed === that.videoControls.getNextActicon().getAnimationState()) {

          return;
        }

        if (payload
          && typeof payload === 'object'
          && 'hover' in payload) {

          that.videoControls.nextHover(payload.hover as boolean);
        } else {
          that.videoControls.nextHover(false);
        }
        that.updateWorld();
      }
    }
    this._iSubscriptions.push(this.scene.spyOnEvent(new NextObjectPropHoverSpy()));

    const pauseObjectPropClickEventPath = this.scene.addEventPath(this.pauseActiconProp, ComponentInteractionType.CLICK);
    class PauseObjectPropClickSpy {

      readonly path = pauseObjectPropClickEventPath;

      constructor() { }

      onEvent(payload: any) {

        if (that._currentVideoPropOptions.isChild) {

          that._pausePressedSource.next(true);
        }
        that.videoControls.pausePressed(true);
        that.updateWorld();
      }
    }
    this._iSubscriptions.push(this.scene.spyOnEvent(new PauseObjectPropClickSpy()));

    const pauseObjectPropHoverEventPath = this.scene.addEventPath(this.pauseActiconProp, ComponentInteractionType.HOVER);
    class PauseObjectPropHoverSpy {

      readonly path = pauseObjectPropHoverEventPath;

      constructor() { }

      onEvent(payload: any) {

        // hover.false is broadcast immediately after click event even when still hovering.
        if (ActiconAnimationState.Pressed === that.videoControls.getPauseActicon().getAnimationState()) {

          return;
        }

        if (payload
          && typeof payload === 'object'
          && 'hover' in payload) {

          that.videoControls.pauseHover(payload.hover as boolean);
        } else {
          that.videoControls.pauseHover(false);
        }
        that.updateWorld();
      }
    }
    this._iSubscriptions.push(this.scene.spyOnEvent(new PauseObjectPropHoverSpy()));

    const playObjectPropClickEventPath = this.scene.addEventPath(this.playActiconProp, ComponentInteractionType.CLICK);
    class PlayObjectPropClickSpy {

      readonly path = playObjectPropClickEventPath;

      constructor() { }

      onEvent(payload: any) {

        if (that._currentVideoPropOptions.isChild) {

          that._playPressedSource.next(true);
        }
        that.videoControls.playPressed(true);
        that.updateWorld();
      }
    }
    this._iSubscriptions.push(this.scene.spyOnEvent(new PlayObjectPropClickSpy()));

    const playObjectPropHoverEventPath = this.scene.addEventPath(this.playActiconProp, ComponentInteractionType.HOVER);
    class PlayObjectPropHoverSpy {

      readonly path = playObjectPropHoverEventPath;

      constructor() { }

      onEvent(payload: any) {

        // hover.false is broadcast immediately after click event even when still hovering.
        if (ActiconAnimationState.Pressed === that.videoControls.getPlayActicon().getAnimationState()) {

          return;
        }

        if (payload
          && typeof payload === 'object'
          && 'hover' in payload) {

          that.videoControls.playHover(payload.hover as boolean);
        } else {
          that.videoControls.playHover(false);
        }
        that.updateWorld();
      }
    }
    this._iSubscriptions.push(this.scene.spyOnEvent(new PlayObjectPropHoverSpy()));
  }


  detachTransformControls(): void {

    this.videoPlaneRendererProp.detachTransformControls();
  }


  /**
   * Child Props are responsible for binding to Parent Props
   * @param newOptions 
   */
  private evaluateOrchestration(newOptions: VideoPropOptions) {

    if (newOptions.isOrchestrationParent) {

    } else if (0 < newOptions.orchestrationParentId) {

      const parentVideoPropNode = this.stage.getVideoNode(newOptions.orchestrationParentId);
      if (parentVideoPropNode) {

        parentVideoPropNode.bindToChildVideoProp(this);
      } else {

        this.monitorVideoPropNodeAdded();
      }
    }
  }


  private handleNextPressed(isPressed: boolean): void {

    this.videoControls.nextPressed(isPressed);
    this.updateWorld();
  }


  private handleSnapshotTextureUpdated(texture?: Texture): void {

    if (texture) {

      this.videoDisplay.pendingState.snapshotAspect = texture.source.data.width / texture.source.data.height;
    } else {

      this.videoDisplay.pendingState.snapshotAspect = 0;
    }
    this.videoDisplay.pendingState.snapshotTexture = texture;
    this.videoDisplay.apply();
    this.updateWorld();
  }


  private handleVideoAspect(videoAspect: number): void {

    this.videoDisplay.pendingState.videoAspect = videoAspect;
    this.videoDisplay.apply();
    this.updateWorld();
  }


  private handleVideoEnded(ended: boolean): void {

    if (!ended) {

      return;
    }
    this.videoControls.pendingState.playerState = PlayerState.Finished; // We could switch to idle and display snapshot again.
    this.videoControls.apply();
    this.setVideoDisplayVisibility();
  }


  private handleVideoPaused(paused: boolean): void {

    if (!paused) {

      return;
    }
    this.videoControls.pendingState.playerState = PlayerState.Paused;
    this.videoControls.apply();
    this.setVideoDisplayVisibility();
  }


  private handleVideoPlaying(playing: boolean): void {

    if (!playing) {

      return;
    }
    this.videoControls.pendingState.playerState = PlayerState.Playing;
    this.videoControls.apply();
    this.setVideoDisplayVisibility();
    this.updateWorld();
  }


  private handleVideoTextureCreated(texture?: VideoTexture): void {

    this.videoDisplay.pendingState.videoTexture = texture;
    this.videoDisplay.apply();
  }


  private _videoPropNodeAddedSubscription?: Subscription;
  private monitorVideoPropNodeAdded(): void {

    if (this._videoPropNodeAddedSubscription) {

      return;
    }
    this._videoPropNodeAddedSubscription = this.stage.videoPropAdded$.subscribe((videoPropNode) => {

      if (videoPropNode.propId === this._currentVideoPropOptions.orchestrationParentId) {

        videoPropNode.bindToChildVideoProp(this);
      }
    })
  }


  onCameraPositionChanged(position: Vector3Obj): void {

    this.videoControls.pendingState.playerPosition.copy(position);
    this.videoControls.apply();
    this.videoPipeline.pendingState.playerPosition.copy(position);
    this.videoPipeline.apply();

    this.updateWorld();
  }


  onDestroy() {

    this.world.removeVideoProp(this.propId);
    this.node.stop();
    this._videoPropNodeAddedSubscription?.unsubscribe();
    this._subscriptions.forEach(s => s.unsubscribe());
    this._iSubscriptions.forEach(_is => _is.cancel());
    this._childSubscriptions.forEach(cs => cs.subscription.unsubscribe());
    this._parentSubscriptions.forEach(ps => ps.unsubscribe());
    this._myOptyxComponents.forEach(mc => mc.onDestroy());
  }


  pause() {

    this.videoControls.pausePressed(true);
  }


  async preloadVideos(): Promise<void> {

    if (!this._designVideos || 1 > this._designVideos.length) {

      return;
    }

    for (const designVideo of this._designVideos) {

      await this.hlsStreamer.preload(designVideo.hlsUrl);
    }
  }


  /**
   * Update world to remove assignments that were removed from a Position Adjustment.
   * @param playerPositionId 
   * @param currentAssignments 
   * @param newAssignments 
   */
  private removeClippingAssignments(playerPositionId: string, currentAssignments: ClippingPlaneAssignment[], newAssignments: ClippingPlaneAssignment[]) {

    for (const currentAssignment of currentAssignments) {

      if (newAssignments.some(na => na.clippingPlaneId === currentAssignment.clippingPlaneId
        && CrudState.DELETED !== na.crudState)) {

        continue;
      }
      this.world.removeClippingPlaneAssignment(playerPositionId, currentAssignment.clippingPlaneId, PropType.VIDEO);
    }
  }


  removePositionAdjustment(adjustment: PositionAdjustment): void {

    const positionAdjustIndex = this.adjustments.findIndex(pa => pa.playerPositionId === adjustment.positionId);
    if (0 > positionAdjustIndex) {

      return;
    }

    const deletedAdjustments = this.adjustments.splice(positionAdjustIndex, 1);
    this.applyPositionAdjustment();

    if (0 < deletedAdjustments.length) {

      this.world?.removeAdjustment(deletedAdjustments[0], this.propId, PropType.VIDEO)
    }
  }


  /**
   * Restore inputs which may have been changed by adjustments.
   * @param videoProp 
   */
  private restoreBaseVideoPropPosition(): void {

    this.snapshotTextureLoader.pendingState.source = this.inputs.snapshotDefaultSrc;
    this.snapshotTextureLoader.apply();

    this.videoControls.pendingState.bounds = {
      w: 1 * this.inputs.scale.x,
      h: (1 / this.inputs.aspect) * this.inputs.scale.y
    };
    this.videoControls.apply();

    this.videoDisplay.pendingState.disableDepth = false;
    this.videoDisplay.pendingState.visible = true;
    this.videoDisplay.apply();
  }


  setAspect(aspect: number, withWorldUpdate = true): void {

    this.videoDisplay.pendingState.aspect = this.inputs.aspect = aspect;
    this.videoDisplay.apply();

    if (withWorldUpdate) {

      this.updateWorld();
    }
  }


  setInputs(videoProp: VideoProp, videoAssignments?: VideoAssignment[], designVideos?: DesignVideo[]): void {

    this.applyPropInputs(videoProp, videoAssignments, designVideos);
    this.setPositionAdjustments(videoProp);
    this.applyPositionAdjustment();
    this.updateWorld();
  }


  setOptions(newOptions: VideoPropOptions): void {

    this.videoControls.pendingState.autoPlay = newOptions.autoPlay;
    this.videoControls.pendingState.autoPlayDistance = newOptions.autoPlayDistance;
    this.videoControls.pendingState.alignment = newOptions.controlsPosition;
    this.videoControls.pendingState.interactionDistance = newOptions.interactionDistance;
    this.videoControls.pendingState.loop = newOptions.loop;
    this.videoControls.pendingState.margin = newOptions.controlsMargin;
    this.videoControls.pendingState.z = newOptions.controlsZ;
    this.videoControls.apply();
    this.videoPipeline.pendingState.audioDistance = newOptions.audioDistance;
    this.videoPipeline.apply()
    this.videoDisplay.pendingState.backingColor = newOptions.backgroundColor;
    this.videoDisplay.apply();

    this.evaluateOrchestration(newOptions);
    this.updateWorld();

    this._currentVideoPropOptions = new VideoPropOptions(newOptions);
  }


  setPosition(position: Vector3Obj, withWorldUpdate = true): void {

    // When transforming remove adjusments from the transformed values to avoid it being applied twice.
    if (this._transforming) {

      let currentAdjustment = vector3ZeroObj as Vector3Obj;
      if (this.stage.currentPlayerPosition?.id) {

        const currentPositionAdjustment = this.adjustments.find(pa => pa.playerPositionId === this.stage.currentPlayerPosition?.id);
        if (currentPositionAdjustment) {

          currentAdjustment = currentPositionAdjustment.positionAdjustment;
        }
      }
      this.inputs.position = {
        x: position.x - currentAdjustment.x,
        y: position.y - currentAdjustment.y,
        z: position.z - currentAdjustment.z
      }
    } else {

      this.inputs.position = position;
    }

    position = this.inputs.position;
    this.videoControls.pendingState.position = new Vector3(position.x, position.y, position.z);
    this.videoControls.apply();
    this.videoPipeline.pendingState.position = new Vector3(position.x, position.y, position.z);
    this.videoPipeline.apply();

    // World is stopped during transformation.
    if (!this._transforming && withWorldUpdate) {

      this.updateWorld();
    }
  }


  private setPositionAdjustments(videoProp: VideoProp): void {

    // Before replacing existing adjustments remove them from the world.
    for (const adjustment of this.adjustments) {

      this.world.removeAdjustment(adjustment, this.propId, PropType.VIDEO);
    }
    this.adjustments = [];

    let positionAdjustment: PositionAdjustment;
    for (positionAdjustment of videoProp.positionAdjustments) {

      const propAdjustment = new PropAdjustment(this.textureManager);

      propAdjustment.fromPositionAdjustment(positionAdjustment);
      this.adjustments.push(propAdjustment);
    }

    this.upsertAdjustables();
  }


  setRotation(rotation: Vector3Obj, withWorldUpdate = true): void {

    // When transforming, remove adjustments from the transformed values to avoid it being applied twice.
    if (this._transforming) {

      let currentAdjustment = vector3ZeroObj as Vector3Obj;
      if (this.stage.currentPlayerPosition?.id) {

        const currentPositionAdjustment = this.adjustments.find(pa => pa.playerPositionId === this.stage.currentPlayerPosition?.id);
        if (currentPositionAdjustment) {

          currentAdjustment = currentPositionAdjustment.rotateAdjustment;
        }
      }
      this.inputs.rotation = {
        x: rotation.x - currentAdjustment.x,
        y: rotation.y - currentAdjustment.y,
        z: rotation.z - currentAdjustment.z
      }
    } else {

      this.inputs.rotation = rotation;

      if (withWorldUpdate) {

        // World is stopped during transformation.
        this.updateWorld();
      }
    }
  }


  setScale(scale: Vector3Obj, withWorldUpdate = true): void {

    // When transforming, remove adjustments from the transformed values to avoid it being applied twice.
    if (this._transforming) {

      let currentAdjustment = vector3ZeroObj as Vector3Obj;
      if (this.stage.currentPlayerPosition?.id) {

        const currentPositionAdjustment = this.adjustments.find(pa => pa.playerPositionId === this.stage.currentPlayerPosition?.id);
        if (currentPositionAdjustment) {

          currentAdjustment = currentPositionAdjustment.scaleAdjustment;
        }
      }
      this.inputs.scale = {
        x: scale.x - currentAdjustment.x,
        y: scale.y - currentAdjustment.y,
        z: scale.z - currentAdjustment.z
      }
    } else {

      this.inputs.scale = scale;

      if (withWorldUpdate) {

        // World is stopped during transformation.
        this.updateWorld();
      }
    }
  }


  setSelected(isSelected: boolean) {

    if (isSelected) {

      this.stage.deselectAllProps();
    }

    this.videoDisplay.pendingState.selected = isSelected;
    this.videoDisplay.apply();
  }


  setSource(videoUrl: string): void {

    this.videoControls.pendingState.urls = [videoUrl];
    this.videoControls.apply();
  }


  setShowroomMode(mode: ShowroomMode): void {

    this.videoDisplay.pendingState.adminMode = ShowroomMode.SHOWROOM !== mode;
    this.videoDisplay.apply();
  }


  private setVideoDisplayVisibility(): void {

    const playerState = this.videoControls.pendingState.playerState;

    this.videoDisplay.pendingState.snapshotVisible = PlayerState.Idle === playerState;
    this.videoDisplay.pendingState.videoVisible = PlayerState.Idle !== playerState;
    this.videoDisplay.apply();
  }


  unbindChildVideoProp(childVideoPropNode: VideoPropNode): void {

    this._childSubscriptions.forEach(cs => {

      if (cs.child === childVideoPropNode) {

        cs.subscription.unsubscribe();
      }
    })
  }


  unbindParentVideoProp(): void {

    this._parentSubscriptions.forEach(ps => ps.unsubscribe());
  }


  updateWorld(): void {

    const infoActicon = this.videoControls.getInfoActicon();
    const nextActicon = this.videoControls.getNextActicon();
    const pauseActicon = this.videoControls.getPauseActicon();
    const playActicon = this.videoControls.getPlayActicon();

    const videoPropEntity: VideoPropEntity = {
      id: this.propId,
      acticonAlignment: this.videoControls.pendingState.alignment,
      acticonMargin: this.videoControls.pendingState.margin,
      acticonZ: this.videoControls.pendingState.z,
      baseAspect: this.inputs.aspect,
      displayAspect: this.videoDisplay.pendingState.videoAspect,
      displayPlane: this.videoDisplay.getVideoPlane(),
      // maskLoader: this.maskLoader,
      // infoActicon: nextActicon.getObject(),
      // infoActiconAnimationDuration: infoActicon.getAnimationDuration(),
      // infoActiconPerpendicularMargin: infoActicon.pendingState.perpendicularMargin,
      // infoActiconScale: infoActicon.getScaleTo(),
      // infoActiconSize: infoActicon.getSize(),
      nextActicon: nextActicon.getObject(),
      nextActiconAnimationDuration: nextActicon.getAnimationDuration(),
      nextActiconPerpendicularMargin: nextActicon.pendingState.perpendicularMargin,
      nextActiconScale: nextActicon.getScaleTo(),
      nextActiconSize: nextActicon.getSize(),
      pauseActicon: pauseActicon.getObject(),
      pauseActiconAnimationDuration: pauseActicon.getAnimationDuration(),
      pauseActiconPerpendicularMargin: pauseActicon.pendingState.perpendicularMargin,
      pauseActiconScale: pauseActicon.getScaleTo(),
      pauseActiconSize: pauseActicon.getSize(),
      planeGroup: this.videoDisplay.getGroup(),
      playActicon: playActicon.getObject(),
      playActiconAnimationDuration: playActicon.getAnimationDuration(),
      playActiconPerpendicularMargin: playActicon.pendingState.perpendicularMargin,
      playActiconScale: playActicon.getScaleTo(),
      playActiconSize: playActicon.getSize(),
      position: this.inputs.position,
      rotation: this.inputs.rotation,
      scale: this.inputs.scale,
      snapshotAspect: this.videoDisplay.pendingState.snapshotAspect,
      snapshotPlane: this.videoDisplay.getSnapshotPlane()
    }

    this.world.upsertVideoProp(videoPropEntity);
  }


  private updatePropInputs(): void {

    this.setAspect(this.videoProp.aspect, false)
    this.setPosition(this.videoProp.positionObj, false);
    this.setRotation(this.videoProp.rotationObj, false);
    this.setScale(this.videoProp.scaleObj, false);

    // Reset any existing video aspect changes
    this.videoDisplay.pendingState.videoAspect = 0;
    // Let display create the Clipping Planes before we update ECS
    this.videoDisplay.pendingState.clippingPlanes = [...this.videoProp.clippingPlanes];
    this.videoDisplay.apply();

    this.upsertAdjustments();
    this.updateWorld();
  }


  updateVideoProp(videoProp: VideoProp): void {

    this.setInputs(videoProp, this.assignmentPlayList.pendingState.playListItems, this._designVideos);
  }


  private upsertAdjustables() {

    let adjustment: PropAdjustment;
    for (adjustment of this.adjustments) {

      this.world.upsertAdjustment(adjustment, this.propId, PropType.VIDEO);
    }
  }


  private upsertAdjustments() {

    // Adjustments include Clipping Plane Assignments which depend upon Clipping Planes so apply them last.
    this.setPositionAdjustments(this.videoProp);
  }


  async upsertPositionAdjustment(adjustment: PositionAdjustment): Promise<void> {

    let positionAdjustment = this.adjustments.find(pa => pa.playerPositionId === adjustment.positionId);

    if (positionAdjustment) {

      this.removeClippingAssignments(positionAdjustment.playerPositionId, positionAdjustment.clippingAssignments, adjustment.clippingAssignments);
      positionAdjustment.fromPositionAdjustment(adjustment);
    } else {

      positionAdjustment = new PropAdjustment(this.textureManager);
      positionAdjustment.fromPositionAdjustment(adjustment);
      this.adjustments.push(positionAdjustment);
    }

    this.applyPositionAdjustment();
    this.world.upsertAdjustment(positionAdjustment, this.propId, PropType.VIDEO);
  }



}