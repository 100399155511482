import { Component, computed, input, output, signal } from '@angular/core';

const PAGE_GROUP_SIZE = 5;

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {

  ItemCount = input.required<number>();
  PageIndex = input.required<number>();
  PageSize = input.required<number>();

  pageCount = computed (() => Math.ceil(this.ItemCount()/this.PageSize()));
  currentPage = computed(() => this.PageIndex() + 1);
  pageGroups = computed(() => {
    
    let pageGroups: number[][] = [[]];

    for (let i = 0; i < this.pageCount(); i++) {

      const currentPageGroup = Math.floor(i / PAGE_GROUP_SIZE);
      if (pageGroups.length < currentPageGroup + 1) {

        pageGroups.push([]);
      }
      pageGroups[currentPageGroup].push(i + 1);
    }

    return pageGroups;
  })

  currentPageGroup = signal(0);

  /**
   * The page index which is page - 1
   */
  pageSelected = output<number>()


  onNextPage() {

    if (this.currentPage() === this.pageCount()) {

      return;
    }

    const newPageIndex = this.PageIndex() + 1;
    this.currentPageGroup.set(Math.floor(newPageIndex / PAGE_GROUP_SIZE));
    this.pageSelected.emit(newPageIndex);
  }


  onNextPageGroup() {

    this.currentPageGroup.update(cpg => ++cpg);
    // Set page index to first page in the next page group
    this.pageSelected.emit(this.pageGroups()[this.currentPageGroup()][0] - 1)
  }


  onSelectPage(page: number) {

    if (page === this.currentPage()) {

      return;
    }
    this.pageSelected.emit(page - 1);
  }


  onPreviousPage() {

    if (1 > this.PageIndex()) {

      return;
    }
    
    const newPageIndex = this.PageIndex() - 1;
    this.currentPageGroup.set(Math.floor(newPageIndex / PAGE_GROUP_SIZE));
    this.pageSelected.emit(newPageIndex);
  }


  onPreviousPageGroup() {
    
    this.currentPageGroup.update(cpg => --cpg);
    // Set page index to last page in the previous page group
    this.pageSelected.emit(this.pageGroups()[this.currentPageGroup()][PAGE_GROUP_SIZE - 1] - 1)
  }
  
}
