import { DestroyRef, Injectable, effect, inject, signal } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IShowroomServiceState, IShowroomStateProvider, InitializeShowroomState, ShowroomState, propInteractionSourceUpdate$ } from 'src/app/state/showroom-state';
import { AuthorizedUser } from 'projects/my-common/src/auth/permission';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { AccountService } from '../myoptyx/account.service';
import { IShowroomUser, ShowroomAccount, ShowroomUser } from 'projects/my-common/src/model';
import { firstValueFrom, map, Observable, Subscriber, switchMap } from 'rxjs';
import { MaskHelper } from 'projects/my-common/src';
import { UserService } from '../myoptyx/user.service';
import { DataService } from '../data/data.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { URL_BASE } from 'src/environments/environment';
import { SHOWROOM_ENDPOINT } from 'src/environments/interfaces/IEnvironment';
import { deepCopy } from 'projects/mp-core/src/lib/util';

const amSubscriber = {
  amSubscriber: true, query: (permissionDesc: PermissionDescriptor): Promise<PermissionStatus> => {
    console.log('--> Inside query callback with permissionDesc ', permissionDesc);
    // This query is an optional callback from within authorize
    return new Promise((resolve, reject) => {
      console.log('--> Inside the query with resolve and reject');
      // Supposed to return a PermissionStatus which has hooks and other stuff not sure if I need all of this
      //resolve('Hi back at you!');
      reject('Hi back at you but I don\'t need all of this.');
    })
  }
} as const;
type AmSubscriber = typeof amSubscriber;

/**
 * NOT NEEDED? Permissions need to be represented by a type that can be both unioned AND intersected by each other for compiler enforcement. 
 * That rules out all primitive types including enums. Objects fit the criteria.
 */
//type Permissions = AmSubscriber; // Now a Permission type - could be AmSubsriber | AmAdmin

export interface ShowroomStateRef {

  instance: IShowroomServiceState
}

/**
 * Showroom state management.
 */
@Injectable({
  providedIn: 'root'
})
export class ShowroomService implements IShowroomStateProvider {

  private readonly _destroying$ = new Subject<void>();
  private _destroyRef!: DestroyRef;
  private _showroomSubscribersUrl = '';
  private _subscribersUrl = '';
  private _subscriptions: Subscription[] = [];

  readonly state = signal(<ShowroomStateRef>{
    instance: <IShowroomServiceState>{}
  })

  readonly showroomAccount = this.accountService.showroomAccount;

  private readonly showroomUserSignal = signal(new ShowroomUser());
  readonly showroomUser = this.showroomUserSignal.asReadonly();

  private _gettingShowroomUser = false;     // concurrency flag
  private _showroomUserQueue: Subscriber<ShowroomUser>[] = []; // concurrency queue
  readonly showroomUser$ = new Observable<ShowroomUser>((observer) => {

    if (this.showroomUser().isValid) {

      observer.next(this.showroomUser());
      return;
    }
    // Dependency check
    if (!this.showroomAccount().isValid) {

      this.logger.trace('Showroom Account is not valid');
      this.showroomUserSignal.set(new ShowroomUser());
      observer.next(this.showroomUser());
      return;
    }
    this._showroomUserQueue.push(observer);
    if (this._gettingShowroomUser) {

      return;
    }
    this._gettingShowroomUser = true;

    this.logger.trace('awaiting getShowroomMe');
    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.getShowroomMe().subscribe({

      next: (showroomUser) => {

        this.logger.trace('setting currentSubscriptionAccountUser', showroomUser);
        if (this.userService.user().isAdmin) {

          // Promote to admin
          showroomUser.roleMask = new MaskHelper(showroomUser.roleMask).add(this.userService.user().roleMask).get();
        }
        this.showroomUserSignal.set(showroomUser);
        this._gettingShowroomUser = false;
        this._showroomUserQueue.forEach(o => o.next(this.showroomUser()));
        this._showroomUserQueue = [];

        unsubscribe();
      },
      error: (error) => {

        this.showroomUserSignal.set(new ShowroomUser());
        this._showroomUserQueue.forEach(o => o.next(this.showroomUser()));
        this._showroomUserQueue = [];
        this._gettingShowroomUser = false;

        unsubscribe();
      }
    })
  });


  private _isReady: boolean = false;
  get isReady(): boolean {

    return this._isReady;
  }
  // observable that is fired when urls are set
  private readonly _urlsSetSource = new Subject<any>();
  private readonly urlsSet$ = this._urlsSetSource.asObservable();
  private _gettingReady = false;
  private _whenReadyQueue: Subscriber<unknown>[] = [];
  readonly whenReady$ = new Observable((observer) => {

    if (this._isReady) {

      observer.next();
      return;
    }

    this._whenReadyQueue.push(observer);
    if (!this._gettingReady) {

      this._gettingReady = true;
      let subscription: Subscription | undefined;
      const unsubscribe = () => subscription?.unsubscribe();
      subscription = this.urlsSet$.subscribe(() => {

        this._whenReadyQueue.forEach(o => o.next());
        this._whenReadyQueue = [];
        this._gettingReady = false;

        unsubscribe();
      });
    }
  });


  constructor(private readonly accountService: AccountService,
    private readonly configurationService: ConfigurationService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly userService: UserService) {

    this.setUrls();
    this._destroyRef = inject(DestroyRef);
    this._destroyRef.onDestroy(() => this.onDestroy());
    InitializeShowroomState((newState) => this.setShowroomState(newState));

    effect(() => {

      if (this.accountService.subscriptionUser().isValid) {

        // Let constructors finish before attempting to set state
        setTimeout(() => this.state().instance.setState(this.accountService.subscriptionUser()));
      }
    })

    effect(() => this.validateShowroomUser(this.accountService.showroomAccount()));

    this.monitorShowroomModeChanges();
  }


  /**
   * Can only be called by an AuthorizedUser
   * @param user 
   * @param comment 
   */
  authResultTest(user: AuthorizedUser<AmSubscriber>, comment: string) {

    this.logger.trace(`User is an authorized subscriber! ${comment}`);
  }


  /**
   * Get currently logged in Subscriber with Showroom Account permissions applied to roleMask
   * @returns ShowroomUser
   */
  getShowroomMe(): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscribersUrl}/account/${this.showroomAccount().id}/me`;

          return this.dataService.get<IShowroomUser>(url)
            .pipe(
              map((user) => {
                this.logger.trace('Showroom me', user);
                return new ShowroomUser(user);
              })
            )
        })
      );
  }


  private monitorShowroomModeChanges() {

    // Trigger change detection
    this._subscriptions.push(
      propInteractionSourceUpdate$
        .subscribe((showroomMode) => this.state.update(cs => ({ ...cs })))
    );
  }


  private onDestroy(): void {

    this._subscriptions.forEach(s => s.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }



  /**
   * Pending Showroom User with Account specific Permissions applied
   * @param userEmails 
   * @param accountId if provided, User will have account Permissions applied
   * @returns Subscription User without updating AccountService state
   */
  getPendingShowroomUser(userEmails: string, accountNumber: string): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          let url = `${this._subscribersUrl}/account/pending/${accountNumber}/${userEmails}`;

          return this.dataService.get<IShowroomUser>(url)
            .pipe(
              map((response: IShowroomUser) => {

                this.logger.trace('Showroom user', response);
                return new ShowroomUser(response);
              })
            )
        })
      );
  }


  /**
   * Showroom User with Account specific Permissions applied
   * @param userEmails 
   * @param accountId if provided, User will have account Permissions applied
   * @returns Subscription User without updating AccountService state
   */
  getShowroomUser(userEmails: string, accountNumber: string): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          let url = `${this._subscribersUrl}/account/${accountNumber}/${userEmails}`;

          return this.dataService.get<IShowroomUser>(url)
            .pipe(
              map((response: IShowroomUser) => {

                this.logger.trace('Showroom user', response);
                return new ShowroomUser(response);
              })
            )
        })
      );
  }


  setShowroomState(newState: IShowroomServiceState): void {

    this.logger.trace(`${ShowroomState[newState.showroomState]}`);
    this.state.update(cs => ({ ...cs, instance: newState }));
  }


  private async setUrls(): Promise<void> {

    if (!this.configurationService.isReady) {

      await firstValueFrom(this.configurationService.whenReady$);
    }

    this._showroomSubscribersUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Subscriber}`;
    this._subscribersUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Subscriber}`;

    this._isReady = true;
    this._urlsSetSource.next(true);
  }


  /**
   * @param showroomUser
   * @returns 
   */
  updatePendingShowroomSubscriber(showroomAccountId: number, showroomUser: IShowroomUser): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const user = new ShowroomUser(showroomUser);
          delete (user as any)['ability'];
          const url = `${this._showroomSubscribersUrl}/account/pending/${showroomAccountId}`;

          return this.dataService.put<IShowroomUser>(url, user)
            .pipe(
              map((response: IShowroomUser) => new ShowroomUser(response))
            )
        })
      );
  }


  /**
   * @param showroomUser
   * @returns 
   */
  updateShowroomSubscriber(showroomAccountId: number, showroomUser: IShowroomUser): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const user = new ShowroomUser(showroomUser);
          delete (user as any)['ability'];
          const url = `${this._showroomSubscribersUrl}/account/${showroomAccountId}`;

          return this.dataService.put<IShowroomUser>(url, user)
            .pipe(
              map((response: IShowroomUser) => {

                this.logger.trace('Updated Showroom user', deepCopy(response));
                if (response.id === this.showroomUser().id) {

                  this.showroomUserSignal.set(new ShowroomUser());
                  this.validateShowroomUser(this.showroomAccount());
                }

                return new ShowroomUser(response);
              })
            )
        })
      );
  }


  private _showroomAccount = new ShowroomAccount();
  private async validateShowroomUser(showroomAccount: ShowroomAccount) {

    if (!showroomAccount.isValid && !this.showroomUser().isValid) {

      // Avoid unnecessary server call
      return;
    }
    if (showroomAccount.id === this._showroomAccount.id) {

      this.logger.trace('Ignored duplicate Showroom Account', showroomAccount);
      return;
    }
    this._showroomAccount = showroomAccount;

    await firstValueFrom(this.showroomUser$);
  }


}
