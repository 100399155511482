<div [id]="id" tabindex="0" (blur)="handleBlur()" class="flex flex-col">

    @if(enableEqual()) {

    <app-checkbox [Label]="'Equal'" [Checked]="keepValuesEqual()" (Toggled)="handleToggleEqual()" [IsReadOnly]="!IsActive()" />

    }
    
    <div class="flex flex-row items-center space-x-2">
        <div class="flex flex-row space-x-2 items-center ">
            <span>x:</span>
            <input
                class="text-primary text-right placeholder:text-primary h-10 w-[90px] outline-accent disabled:opacity-70"
                type="number" [value]="xInitializer() | number : '1.1-4' | noComma" step="any" (focus)="onFocusX($event)" (blur)="onBlurX()"
                (input)="handleInputX($event)" [disabled]="!IsActive()">
        </div>
        <div class="flex flex-row space-x-2 items-center ">
            <span>y:</span>
            <input
                class="text-primary text-right placeholder:text-primary h-10 w-[90px] outline-accent disabled:opacity-70"
                type="number" [value]="yInitializer() | number : '1.1-4' | noComma" step="any" (focus)="onFocusY($event)" (blur)="onBlurY()"
                (input)="handleInputY($event)" [disabled]="!IsActive()">
        </div>
        <div class="flex flex-row space-x-2 items-center ">
            <span>z:</span>
            <input
                class="text-primary text-right placeholder:text-primary h-10 w-[90px] outline-accent disabled:opacity-70"
                type="number" [value]="zInitializer() | number : '1.1-4' | noComma" step="any" (focus)="onFocusZ($event)" (blur)="onBlurZ()" 
                (input)="handleInputZ($event)" [disabled]="!IsActive()">
        </div>
    </div>
</div>