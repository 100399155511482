import { CommonModule } from '@angular/common';
import { Component, Input, output, signal } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { debounce } from 'projects/my-common/src';
import { NoCommaPipe } from 'src/app/shared/pipes/no-comma.pipe';

@Component({
  selector: 'app-input-price',
  standalone: true,
  imports: [CommonModule, NoCommaPipe],
  templateUrl: './input-price.component.html',
  styleUrl: './input-price.component.scss'
})
export class InputPriceComponent {

  readonly price = signal(0);
  @Input({ required: true }) set Price(value: number) {
    
    if (this._updateInitializers) {

      this.priceInitializer.set(value);
    }

    this.price.set(value);
  }

  private _updateInitializers = true;
  // Input initializers
  readonly priceInitializer = signal(0);

  readonly Updated = output<number>();


  constructor(private readonly _logger: NGXLogger) {}


  private debounceInputPrice = debounce((newPrice: number) => this.onBlurPrice(), 2000);
  handlePriceInput(aspectInputEvent: any) {

    const newPrice = Number(aspectInputEvent.target.value);

    this._updateInitializers = false;
    this.Updated.emit(newPrice);
    // Provide cycle for updated input to return before reenabling initizer update
    setTimeout(() => this._updateInitializers = true)
    
    this.debounceInputPrice[0](newPrice);
  }


  onBlurPrice() {

    this.priceInitializer.set(this.price());
  }

}
