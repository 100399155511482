import { Component, computed, HostListener, OnDestroy, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartStatusComponent } from '../../../../components/cart/cart-status/cart-status.component';
import { NavbarItemComponent } from '../navbar-item/navbar-item.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapBell, bootstrapCart3, bootstrapChevronDown, bootstrapJustify, bootstrapPerson, bootstrapSearch } from '@ng-icons/bootstrap-icons';
import { MobileMenuComponent } from "../../menus/mobile-menu/mobile-menu.component";
import { AccountMenuComponent } from "../../menus/account-menu/account-menu.component";
import { Subscription } from 'rxjs';
import { SecurityService } from 'src/app/core/service/authentication/security.service';
import { SearchBarComponent } from "../../tools/search-bar/search-bar.component";
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from 'src/app/core/service/myoptyx/account.service';
import { UserService } from 'src/app/core/service/myoptyx/user.service';
import { CartService } from 'src/app/core/service/cart/cart.service';

const TOP_OFFSET: number = 66;

@Component({
    selector: 'app-navbar',
    standalone: true,
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [provideIcons({ bootstrapBell, bootstrapCart3, bootstrapChevronDown, bootstrapJustify, bootstrapPerson, bootstrapSearch })],
    imports: [
        CartStatusComponent,
        CommonModule,
        NavbarItemComponent,
        NgIconComponent,
        MobileMenuComponent,
        AccountMenuComponent,
        SearchBarComponent
    ]
})
export class NavbarComponent implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    readonly isAuthenticated = signal(false);
    readonly isAdmin = signal(false);
    readonly isSubscriber = signal(false);
    readonly mobileMenuVisible = signal(false);
    readonly accountMenuVisible = signal(false);
    readonly showBackground = signal(false);
    readonly logoClicked = signal(false);

    readonly cartItems = computed(() => this.cartService.showroomCart().items.length);

    constructor(private readonly cartService: CartService,
        private readonly securityService: SecurityService,
        private readonly router: Router,
        readonly account: AccountService,
        readonly user: UserService,
        private readonly logger: NGXLogger) {

        this.monitorIsAuthenticatedUpdates();
    }


    editProfile() {
        this.securityService.editProfile();
    }


    handleCloseAccountMenu() {
        this.accountMenuVisible.set(false);
    }


    handleLogoClicked() {
        this.logoClicked.set(true);
        this.router.navigate(['/']);
        setTimeout(() => {
            this.logoClicked.set(false);
        }, 1000);
    }


    @HostListener('window:scroll', [])
    handleScroll() {
        this.showBackground.set(window.scrollY > TOP_OFFSET);
    }


    login() {

        this.securityService.login();
    }


    logout() {
        this.securityService.logout();
    }


    private monitorIsAuthenticatedUpdates() {

        this.setIsAuthenticated();
        // Handle authorization updates
        this._subscriptions.push(
            this.securityService.isAuthenticatedUpdate$
                .subscribe((isAuthenticated) => {

                    this.setIsAuthenticated();
                })
        );
    }


    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }


    private setIsAuthenticated() {

        this.isAuthenticated.set(this.securityService.isAuthenticated);
    }


    showAccountMenu() {

        this.accountMenuVisible.set(true);
    }


    showMobileMenu() {
        this.mobileMenuVisible.set(!this.mobileMenuVisible());
    }


}
