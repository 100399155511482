import {defineComponent, Types} from 'bitecs';

/**
 * Base rotation without any adjustments.
 */
export const PositionComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})

/**
 * Adjustment to the base position. Changes with move from position to position.
 */
export const PositionAdjustmentComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})


/**
 * Track the original position adjustment as it transitions to the destination adjustment.
 */
export const FromPositionComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})

/**
 * The target destination position adjustment.
 */
export const DestinationPositionComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})