import { Injectable, signal } from '@angular/core';
import { IDesignVideo, IEventDesign, IImportableDesignVideos, IImportableVideoSearchResult } from 'projects/my-common/src/model';
import { Subject, firstValueFrom } from 'rxjs';
import { SearchService } from '../search/search.service';
import { NGXLogger } from 'ngx-logger';


@Injectable({
  providedIn: 'root'
})
export class DesignVideoService {
  
  readonly importableDesignVideos = signal(<IImportableDesignVideos>{
    data: <IImportableVideoSearchResult[]>[]
  });

  private _currentEventDesign: IEventDesign = <IEventDesign>{};
  private _includeThisDesignsVideos: boolean = false;

  private _importVideoSource = new Subject<IDesignVideo>();
  importVideo$ = this._importVideoSource.asObservable();

  private _pageSize = 10;


  constructor(private readonly _searchService: SearchService,
    private readonly _logger: NGXLogger) { }


  broadcastImportDesignVideo(designVideo: IDesignVideo) {

      this._importVideoSource.next(designVideo);    
  }


  async loadPage(pageIndex: number) {

    const paginatedResults = await firstValueFrom(
      this._searchService.importableDesignVideos(this._currentEventDesign.id, this._includeThisDesignsVideos, this._pageSize, pageIndex)
    );
    this._logger.trace('paginated results', paginatedResults);

    this.importableDesignVideos.set(paginatedResults);
  }


  setEventDesign(eventDesign: IEventDesign, includeThisDesignsVideos: boolean = false) {

    this._currentEventDesign = eventDesign;
    this._includeThisDesignsVideos = includeThisDesignsVideos;
    this.loadPage(0);
  }


}
