<div class="flex flex-row justify-between items-center">
    <span>Price options</span>
    
    @if (addingPriceOption()) {

        <svg class="animate-spin h-5 w-5 text-primary"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
        </svg>

    } @else {
        
        <div class="tooltip tooltip-left" data-tip="Add price option">
            <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]"
                (click)="onAddPriceOption()">
                <label class="flex justify-center">
                    <ng-icon class="cursor-pointer" size="25" name="bootstrapPlusLg" />
                </label>
            </button>
        </div>

    }
    
</div>
<div class="flex flex-row w-full">
    <div class="flex flex-row w-full overflow-hidden">
        <div class="w-1/2 underline">Label</div>
        <div class="w-1/2 underline">Price</div>
    </div>
    <div class="w-[48px]">
        &nbsp;
    </div>
</div>
<div class="flex flex-col w-full space-y-2">

    @for (priceOption of priceOptions(); track $index) {

        <div class="flex flex-row w-full items-center">
            <div class="flex flex-row w-full">
                <input class="w-1/2 mr-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary"
                    [value]="priceOption.label" (input)="handleLabelInput($event, $index)">            
                <app-input-price class="w-1/2 mr-2" [Price]="priceOption.price" (Updated)="handlePriceUpdate($event, $index)" />
            </div>
            <div class="flex w-[48px] items-center justify-center">
        
                @if (1 < priceOptions().length) {
                              
                <div class="tooltip tooltip-left" data-tip="Showroom options">
                    <div (click)="onDeletePriceOption($index)" 
                        class="h-10 cursor-pointer group flex items-center relative">
                        <ng-icon class="text-xl" name="bootstrapXLg" />  
                    </div>
                </div>

                }
            </div>
        </div>
    }
    
</div>