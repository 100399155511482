<app-video-slider />
<div class="border-t-2 border-accent">
    <div class="flex flex-col px-4 justify-center max-w-4xl mx-auto min-h-[600] ">
        <div class="text-3xl md:text-5xl italic uppercase pt-20 pb-6">
            Is it real or MyOptyx
        </div>
        <div class="h-1 bg-accent w-full"></div>
        <div class="text-xl md:text-3xl italic pt-6 pb-20">
            Decorate your virtual spaces. Create events and designs to share with your clients. 
            Elevate proposals with immersive experiences and interactive options.
        </div>
    </div>
</div>
