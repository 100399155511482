import { AnimationMixer, Object3D } from "three";
import { Vector3Obj } from "../../util/utils";
import { AnimationType } from "../../scene/animation";
import { ChildObjectEntityIdMap, IPropEntity, PositionComponent, RotationComponent, ScaleComponent, object3dByEntityId } from "..";
import { IWorld, addComponent, addEntity, defineQuery, removeEntity } from "bitecs";
import { ChildObjectComponent, ObjectComponent } from "../component/object.component";
import { WorldState } from "../ecs-world.state";
import { AnimationComponent } from "../component/animation.component";

/**
 * Definitions resources and functions supporting Object Props in ECS
 */
export interface ObjectPropEntity extends IPropEntity {
    animationMixer?: AnimationMixer;
    animationType: AnimationType;
    animationRotationY: number;
    /**
     * The group containing the object and related objects.
     */
    objectGroup: Object3D;
    offset: Vector3Obj;   // base
    /**
     * The object within the group which can be positionally offset within the group. 
     * For example, when objects origin is not centered in a desirable manner.
     */
    childObject?: Object3D;
    position: Vector3Obj;   // base
    rotation: Vector3Obj;   // base
    scale: Vector3Obj;      // base
}


export const objectEntityQuery = defineQuery([ObjectComponent]);


/**
 * For scene in Object Prop group
 */
export const childObjectEidByParentEid: ChildObjectEntityIdMap = {}

/**
 * References to data based mask textures which are updated dynamically and applied to game objects.
 * Maybe there is some way to integrate these data arrays directly into ECS Components?
 */
type AnimationMixerMap = { [entityId: number]: AnimationMixer }
export const animationMixerByEntityId: AnimationMixerMap = {}


/**
 * Define an entity for the given child object.
 * @param childObject 
 * @returns child object entity Id
 */
function createChildObjectEntity(world: IWorld, childObject: Object3D): number {

    // Create entity Id to track the child object and its component values
    let eidForChildObject: number;
    do eidForChildObject = addEntity(world)
    while (!eidForChildObject || 0 > eidForChildObject);

    // Record reference to child 3dObject
    object3dByEntityId[eidForChildObject] = childObject;

    // Register component values to track
    addComponent(world, ChildObjectComponent, eidForChildObject);
    addComponent(world, AnimationComponent, eidForChildObject);

    return eidForChildObject;
}


/**
 * @param propId target adjustable prop id
 * @returns the entity id for the related object Prop
 */
function getEntityIdForObjectProp(world: IWorld, propId: number): number {

    return objectEntityQuery(world).find(eid => ObjectComponent.id[eid] === propId) ?? -1;
}


function removeChildObject(world: IWorld, eidForParent: number): void {

    const eidForChildObject = childObjectEidByParentEid[eidForParent];
    if (eidForChildObject && -1 < eidForChildObject) {

        // Remove references
        delete animationMixerByEntityId[eidForChildObject];
        delete object3dByEntityId[eidForChildObject];
        delete childObjectEidByParentEid[eidForParent];

        removeEntity(world, eidForChildObject);
    }
}


export function removeObjectProp(world: IWorld, objectPropId: number): void {

    const eidForObjectProp = getEntityIdForObjectProp(world, objectPropId);
    if (0 > eidForObjectProp) {

        WorldState.logger.error(`Entity Id for Object Prop Id: ${objectPropId} not found`);
        return;
    }

    removeChildObject(world, eidForObjectProp);

    // Remove reference
    delete object3dByEntityId[eidForObjectProp];

    removeEntity(world, eidForObjectProp);
}


/**
 * Component data for the child object of an Object Prop group.
 * @param objectProp 
 * @param eidForObjectProp 
 */
function updateChildObjectComponentValues(world: IWorld, objectProp: ObjectPropEntity, eidForObjectProp: number): void {

    if (0 > eidForObjectProp) {

        WorldState.logger.error('Invalid Object Prop entity id');
        return;
    }

    if (!objectProp.childObject) {

        // Object Prop does not have child object. Delete if exists.
        removeChildObject(world, eidForObjectProp);
        return;
    }

    let eidForChildObject = childObjectEidByParentEid[eidForObjectProp];
    if (!eidForChildObject || 0 > eidForChildObject) {

        eidForChildObject = createChildObjectEntity(world, objectProp.childObject);
        childObjectEidByParentEid[eidForObjectProp] = eidForChildObject;
    }

    // Track references
    object3dByEntityId[eidForChildObject] = objectProp.childObject;
    if (objectProp.animationMixer) {

        animationMixerByEntityId[eidForChildObject] = objectProp.animationMixer;
    }

    // Component data
    AnimationComponent.type[eidForChildObject] = objectProp.animationType;
    AnimationComponent.rotationY[eidForChildObject] = objectProp.animationRotationY;
}


/**
 * Update component values for Object Prop entity.
 * @param objectEntity 
 */
function updateObjectPropComponentValues(world: IWorld, objectEntity: ObjectPropEntity, eidForObjectProp: number) {

    // Update object references
    object3dByEntityId[eidForObjectProp] = objectEntity.objectGroup;

    // Set component values
    PositionComponent.x[eidForObjectProp] = objectEntity.position.x;
    PositionComponent.y[eidForObjectProp] = objectEntity.position.y;
    PositionComponent.z[eidForObjectProp] = objectEntity.position.z;
    RotationComponent.x[eidForObjectProp] = objectEntity.rotation.x;
    RotationComponent.y[eidForObjectProp] = objectEntity.rotation.y;
    RotationComponent.z[eidForObjectProp] = objectEntity.rotation.z;
    ScaleComponent.x[eidForObjectProp] = objectEntity.scale.x;
    ScaleComponent.y[eidForObjectProp] = objectEntity.scale.y;
    ScaleComponent.z[eidForObjectProp] = objectEntity.scale.z;

    updateChildObjectComponentValues(world, objectEntity, eidForObjectProp);
}


/**
 * Register Object Prop objects in the ecs system.
 * @param world 
 * @param objectProp 
 */
export function upsertObjectProp(world: IWorld, objectProp: ObjectPropEntity): void {

    let eidForObject = getEntityIdForObjectProp(world, objectProp.id);

    if (0 > eidForObject) {

        // Get an ECS entity id for the object.
        do eidForObject = addEntity(world)
        while (!eidForObject || 0 > eidForObject);

        // Flag as an object entity for queries.
        addComponent(world, ObjectComponent, eidForObject);

        // Base object component values
        ObjectComponent.id[eidForObject] = objectProp.id;

        // Component data to track
        addComponent(world, PositionComponent, eidForObject);
        addComponent(world, RotationComponent, eidForObject);
        addComponent(world, ScaleComponent, eidForObject);
    }

    updateObjectPropComponentValues(world, objectProp, eidForObject);
}