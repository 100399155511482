import { AfterViewInit, Component, computed, Input, output, signal } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { CommonModule } from '@angular/common';
import { ChangeEvent, CKEditorModule } from '@ckeditor/ckeditor5-angular';

import {
	ClassicEditor,
	AccessibilityHelp,
	Autoformat,
	Autosave,
	BlockQuote,
	Bold,
	Essentials,
	Heading,
	Indent,
	IndentBlock,
	Italic,
	Link,
	List,
	ListProperties,
	Paragraph,
	SelectAll,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	Underline,
	Undo,
	type EditorConfig,
	BalloonToolbar
} from 'ckeditor5';
import { ModalService } from 'src/app/core/service/ui/modal/modal.service';
import { ALERT_MODAL_ID } from '../alert-modal/alert-modal.component';


@Component({
	selector: 'app-text-editor',
	standalone: true,
	imports: [CommonModule, CKEditorModule],
	templateUrl: './text-editor.component.html',
	styleUrl: './text-editor.component.scss'
})
export class TextEditorComponent implements AfterViewInit {

	private _broadcastingUpdate = false;

	readonly enable = signal(true);
	@Input() set Enable(value: boolean) {

		this.enable.set(value);
	}

	readonly initialContent = signal('');
	@Input() set Content(value: string) {

		this.displayHtml.set(value);

		if (this._broadcastingUpdate) {
			
			this._broadcastingUpdate = false
		} else {
			
			this.initialContent.set(value);
			if (this._editor) {

				this._editor.data.set(value);
			}
		}
	}


	private _maxHtmlLength = 5000;
	@Input() set MaxHtmlLength(value: number) {

		if (1 > value) {

			return;
		}

		this._maxHtmlLength = value;
	}

	readonly displayHtml = signal('');
	readonly OnUpdate = output<string>();


	constructor(private readonly logger: NGXLogger,
		private readonly modalService: ModalService) { }


	ngAfterViewInit(): void {

		this.initializeCKEdit();
	}

	public isLayoutReady = signal(false);
	public Editor = ClassicEditor;
	public config: EditorConfig = {}; // CKEditor needs the DOM tree before calculating the configuration.
	private initializeCKEdit() {
		this.config = {
			toolbar: {
				items: [
					'undo',
					'redo',
					// '|',
					// 'heading',
					'|',
					'bold',
					'italic',
					'underline',
					'|',
					'link',
					'insertTable',
					'blockQuote',
					'|',
					'bulletedList',
					'numberedList',
					'outdent',
					'indent'
				],
				shouldNotGroupWhenFull: true
			},
			plugins: [
				AccessibilityHelp,
				Autoformat,
				Autosave,
				BalloonToolbar,
				BlockQuote,
				Bold,
				Essentials,
				//Heading,
				Indent,
				IndentBlock,
				Italic,
				Link,
				List,
				ListProperties,
				Paragraph,
				SelectAll,
				Table,
				TableCaption,
				TableCellProperties,
				TableColumnResize,
				TableProperties,
				TableToolbar,
				TextTransformation,
				Underline,
				Undo
			],
			balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
			heading: {
				options: [
					{
						model: 'paragraph',
						title: 'Paragraph',
						class: 'ck-heading_paragraph'
					},
					{
						model: 'heading1',
						view: 'h1',
						title: 'Heading 1',
						class: 'ck-heading_heading1'
					},
					{
						model: 'heading2',
						view: 'h2',
						title: 'Heading 2',
						class: 'ck-heading_heading2'
					},
					{
						model: 'heading3',
						view: 'h3',
						title: 'Heading 3',
						class: 'ck-heading_heading3'
					},
					{
						model: 'heading4',
						view: 'h4',
						title: 'Heading 4',
						class: 'ck-heading_heading4'
					},
					{
						model: 'heading5',
						view: 'h5',
						title: 'Heading 5',
						class: 'ck-heading_heading5'
					},
					{
						model: 'heading6',
						view: 'h6',
						title: 'Heading 6',
						class: 'ck-heading_heading6'
					}
				]
			},
			// initialData:
			// 	'<h2>Congratulations on setting up CKEditor 5! 🎉</h2>\n',
			link: {
				addTargetToExternalLinks: true,
				defaultProtocol: 'https://',
				decorators: {
					toggleDownloadable: {
						mode: 'manual',
						label: 'Downloadable',
						attributes: {
							download: 'file'
						}
					}
				}
			},
			list: {
				properties: {
					styles: true,
					startIndex: true,
					reversed: true
				}
			},
			placeholder: 'Compose an epic...',
			table: {
				contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
			}
		};

		this.isLayoutReady.set(true);
	}


	onChange({ editor }: ChangeEvent) {

		const data = editor.getData();

		if (this._maxHtmlLength < data.length) {

			this.modalService.setTarget(ALERT_MODAL_ID, 'Maximum content length reached');
			this.modalService.open(ALERT_MODAL_ID);
			this._editor?.execute('undo');
			return;
		}

		this.displayHtml.set(data);
		this._broadcastingUpdate = true;
		this.OnUpdate.emit(data);
		setTimeout(() => this._broadcastingUpdate = false);
	}


	private _editor?: ClassicEditor;
	onEditorReady(editor: ClassicEditor) {

		this._editor = editor;
	}

}

