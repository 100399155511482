import { Component } from '@angular/core';
import { BeforeAfterImageSliderComponent } from "../../../components/shared/before-after-image-slider/before-after-image-slider.component";
import { VideoSliderComponent } from "../../../components/shared/video-slider/video-slider.component";
import { HeroComponent } from "../../../components/hero/hero.component";

@Component({
    selector: 'app-display',
    standalone: true,
    templateUrl: './display.component.html',
    styleUrl: './display.component.scss',
    imports: [BeforeAfterImageSliderComponent, VideoSliderComponent, HeroComponent]
})
export class DisplayComponent {
}
