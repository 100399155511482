export enum Action {

    manage = 'manage',
    create = 'create',
    read = 'read',
    update = 'update',
    delete = 'delete'
}

export enum Subject {

    all = 'all',  
    Venue = 'Venue',  
    ImageProp = 'ImageProp',  
    ObjectProp = 'ObjectProp',  
    VideoProp = 'VideoProp', 
    VenueEvent = 'VenueEvent', 
    GuestLink = 'GuestLink', 
    EventDesign = 'EventDesign', 
    DesignImage = 'DesignImage', 
    DesignObject = 'DesignObject', 
    DesignVideo = 'DesignVideo',
    ImageAssignment = 'ImageAssignment',
    ObjectAssignment = 'ObjectAssignment',
    VideoAssignment = 'VideoAssignment',
    SubscriptionAccount = 'SubscriptionAccount',
    PurchaseOrder = 'PurchaseOrder',
    Subscriber = 'Subscriber',
    Invoice = 'Invoice',
    None = 'None'
}

export interface ICaslPermission {
     /// <summary>
    /// The Actions allowed on the Subject.
    /// i.e. manage, create, read, update, delete
    /// </summary>
    action: string

    /// <summary>
    /// Subject is the entity name as recognized on the client.
    /// i.e. SubscriptionAccount or Venue
    /// </summary>
    subject: string

    /// <summary>
    /// Properties specific to the Subject. Narrows the scope of allowed Subjects based on the Subjects properties.
    /// i.e. new { id = 111 }
    /// </summary>
    conditions?: object;
}
export function equalsCaslPermission(cp1: ICaslPermission, cp2: ICaslPermission, equalsConditions?: (o1?: any, o2?: any) => boolean): boolean {

    return cp1.action === cp2.action && cp1.subject === cp2.subject
        && equalsConditions ? equalsConditions(cp1.conditions, cp2.conditions) : true;
}
export function equalsCaslPermissions(cps1: ICaslPermission[], cps2: ICaslPermission[], equalsConditions?: (o1?: any, o2?: any) => boolean): boolean {

    if (cps1.length !== cps2.length) {

        return false;
    }

    for (const cp1 of cps1) {

        if (!cps2.some(cp => equalsCaslPermission(cp, cp1, equalsConditions))) {

            return false;
        }
    }
    for (const cp2 of cps2) {

        if (!cps1.some(cp => equalsCaslPermission(cp, cp2))) {

            return false;
        }
    }

    return true;
}