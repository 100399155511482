import { SceneComponent, ComponentInteractionType, ComponentOutput } from '../SceneComponent';
import { Camera, Euler, Group, Line, Object3D, Object3DEventMap, PerspectiveCamera, Quaternion, Raycaster, Vector3, XRGripSpace, XRTargetRaySpace } from 'three';
import { TransformMode, TransformSpace, downloadStringAsBlob, getLogger } from 'projects/my-common/src';
import { Subject } from "rxjs/internal/Subject";
import { CameraPose } from './Camera/CameraInput';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { deepCopy } from 'projects/mp-core/src/lib/util';

// @ts-ignore
//import fragment from './shaders/shader/noise.glsl';
// @ts-ignore
//import vertex from './shaders/shader/vertex.glsl';
// @ts-ignore
//import fragmentSun from './shaders/shaderSun/fragment.glsl';
// @ts-ignore
//import vertexSun from './shaders/shaderSun/vertex.glsl';
// @ts-ignore
//import fragmentPenumbra from './shaders/shaderPenumbra/fragment.glsl';
// @ts-ignore
//import vertexPenumbra from './shaders/shaderPenumbra/vertex.glsl';

/**
 * The fundamental 3D object placement within Matterport.
 * Receives Matterport interaction events.
 * Does not dispose of object.
 */
export type MPObjectPropInputs = {
  enableInteraction: boolean;
  object?: Object3D<Object3DEventMap>;
  pose?: CameraPose;
}

type Outputs = {
  selectionBoxVisible: boolean;
  enableInteraction: boolean;
} & ComponentOutput;


export class MPObjectPropComponent extends SceneComponent {
  private readonly _logger = getLogger();
  private _object?: Object3D<Object3DEventMap>;

  override inputs: MPObjectPropInputs = {
    object: undefined,
    enableInteraction: true
  }

  override outputs = {
    selectionBoxVisible: false,
    enableInteraction: false
  } as Outputs;

  override events = {
    [ComponentInteractionType.CLICK]: true,
    /**
     * Enabling drag prevents easy Matterport navigation.
     * Only activate temporarily when transforming in Admin Stage mode to enable transform dragging.
     */
    [ComponentInteractionType.DRAG]: false,
    [ComponentInteractionType.HOVER]: true,
    materialDataUpdated: true
  };

  private readonly _positionUpdated = new Subject<Vector3>();
  readonly positionUpdated$ = this._positionUpdated.asObservable();
  private readonly _rotationUpdated = new Subject<Euler>();
  readonly rotationUpdated$ = this._rotationUpdated.asObservable();
  private readonly _scaleUpdated = new Subject<Vector3>();
  readonly scaleUpdated$ = this._scaleUpdated.asObservable();
  private readonly _transformMouseDown = new Subject<object>();
  readonly transformMouseDown$ = this._transformMouseDown.asObservable();
  private readonly _transformMouseUp = new Subject<object>();
  readonly transformMouseUp$ = this._transformMouseUp.asObservable();

  private _camera!: Camera;
  private _controls?: TransformControls;
  private _controller1!: XRTargetRaySpace;
  private _controller2!: XRTargetRaySpace;
  private _raycaster!: Raycaster;
  private _selectables!: Group;
  private _line!: Line;
  private _controllerGrip1!: XRGripSpace;
  private _controllerGrip2!: XRGripSpace;
  private _transformControlsMode = TransformMode.TRANSLATE;
  private _transformControlsSpace = TransformSpace.LOCAL;


  attachTransformControls(mode: TransformMode, space: TransformSpace) {

    if (!this._object) {

      return;
    }

    // Make sure order is set properly
    this._object.rotation.order = 'YXZ';

    /**
     * Enabling drag prevents easy Matterport navigation.
     * Only activate temporarily when transforming in Admin Stage mode to enable transform dragging.
     */
    this.events[ComponentInteractionType.DRAG] = true;
    this._transformControlsMode = mode
    this._transformControlsSpace = space;
    this._selectables = new this.context.three.Group();

    this._controller1 = this.context.renderer.xr.getController(0);
    this._controller1.addEventListener('select', this.selectCallback);
    this._controller1.addEventListener('selectstart', this.controllerEventCallback);
    this._controller1.addEventListener('selectend', this.controllerEventCallback);
    this._controller1.addEventListener('move', this.controllerEventCallback);
    this._controller1.userData.active = false;
    this.context.scene.add(this._controller1);

    this._controller2 = this.context.renderer.xr.getController(1);
    this._controller2.addEventListener('select', this.selectCallback);
    this._controller2.addEventListener('selectstart', this.controllerEventCallback);
    this._controller2.addEventListener('selectend', this.controllerEventCallback);
    this._controller2.addEventListener('move', this.controllerEventCallback);
    this._controller2.userData.active = true;
    this.context.scene.add(this._controller2);

    const controllerModelFactory = new (this.context.three as any).XRControllerModelFactory() as XRControllerModelFactory;
    this._controllerGrip1 = this.context.renderer.xr.getControllerGrip(0);
    this._controllerGrip1.add(controllerModelFactory.createControllerModel(this._controllerGrip1));
    this.context.scene.add(this._controllerGrip1);
    this._controllerGrip2 = this.context.renderer.xr.getControllerGrip(1);
    this._controllerGrip2.add(controllerModelFactory.createControllerModel(this._controllerGrip2));
    this.context.scene.add(this._controllerGrip2);

    const geometry = new this.context.three.BufferGeometry().setFromPoints([new Vector3(0, 0, 0), new Vector3(0, 0, - 1)]);
    this._line = new this.context.three.Line(geometry);
    this._line.name = 'line';
    this._line.scale.z = 5;

    this._raycaster = new this.context.three.Raycaster();

    this._controls = new (this.context.three as any).TransformControls(this._camera, this.context.renderer.domElement);

    if (this._controls) {

      this._controls.space = space;
      this._controls.setScaleSnap(null);
      this._controls.setRotationSnap(null);

      this._controls.attach(this._object);

      this._controls.addEventListener('change', this.draggingChangedCallback);
      this._controls.addEventListener('mouseDown', this.transformMouseDownCallback);
      this._controls.addEventListener('mouseUp', this.transformMouseUpCallback);
      this.context.scene.add(this._controls);
      this._controls.setMode(mode);
    }
  }


  private configureGltf() {

    this.disposeGltf();

    this._object = this.inputs.object;
    this.outputs.objectRoot = this._object ?? null;
    this.outputs.collider = this._object ?? null;

    // Placeholder for downloading the full scene
    // this.getGltf((result) => {
    //   this._logger.error('export result', result);
    // })
  }


  detachTransformControls() {

    /**
     * Enabling drag prevents easy Matterport navigation.
     * Only activate temporarily when transforming in Admin Stage mode to enable transform dragging.
     */
    this.events[ComponentInteractionType.DRAG] = false;
    if (this._controls) {

      this.context.scene.remove(this._controls);
      this._controls.removeEventListener('change', this.draggingChangedCallback);
      this._controls.removeEventListener('mouseDown', this.transformMouseDownCallback);
      this._controls.removeEventListener('mouseUp', this.transformMouseUpCallback);
      this._controls.detach();
      this._controls.dispose();
      this._controls = undefined;
    }
    if (this._line) {

      this.context.scene.remove(this._line);
      this._line.geometry.dispose();
    }
    if (this._controllerGrip2) {

      this.context.scene.remove(this._controllerGrip2);
      this._controllerGrip2.clear();
    }
    if (this._controllerGrip1) {

      this.context.scene.remove(this._controllerGrip1);
      this._controllerGrip1.clear();
    }
    if (this._controller2) {

      this.context.scene.remove(this._controller2);
      this._controller2.removeEventListener('select', this.selectCallback);
      this._controller2.removeEventListener('selectstart', this.controllerEventCallback);
      this._controller2.removeEventListener('selectend', this.controllerEventCallback);
      this._controller2.removeEventListener('move', this.controllerEventCallback);
      this._controller2.clear();
    }
    if (this._controller1) {

      this.context.scene.remove(this._controller1);
      this._controller1.removeEventListener('select', this.controllerEventCallback);
      this._controller1.removeEventListener('selectstart', this.controllerEventCallback);
      this._controller1.removeEventListener('selectend', this.controllerEventCallback);
      this._controller1.removeEventListener('move', this.controllerEventCallback);
      this._controller1.clear();
    }
  }


  // https://github.com/mrdoob/three.js/blob/master/examples/misc_exporter_gltf.html
  getGltf(callback: (result: any) => void) {

    if (!this._object) {

      callback(undefined);
      return;
    }

    const THREE = this.context.three;
    const gltfExporter = new (THREE as any).GLTFExporter(); // new GLTFExporter(); // 
    const that = this;

    // Go to the whole Matterport scene
    this._logger.error('Getting full Matterport scene from mesh parent', this._object);
    let matterportScene: any = this._object;
    while (matterportScene.parent) {
      matterportScene = matterportScene.parent;
    }

    if (gltfExporter) {
      gltfExporter.parse(
        matterportScene,
        ///this.mesh,
        function (result: any) {
          if (result instanceof ArrayBuffer) {

            //that._logger.error('Binary export');

            // Download glb file
            //downloadBufferAsBinaryBlob( result, 'scene.glb' );

            //callback(new Blob([ result ], { type: 'application/octet-stream' }))
            callback(result);
          } else {

            //that._logger.error('String export');

            const output = JSON.stringify(result, null, 2);

            // Download gltf file
            downloadStringAsBlob(output, 'scene.gltf');

            callback(output);
          }
        },
        function (error: any) {

          that._logger.error('An error happened exporting gltf', error);
          callback(undefined);
        },
        { // gltfExporter options for Matterport = trs: false, onlyVisible: true, binary: false
          trs: false,
          onlyVisible: true,
          binary: false
        }
      );
    }

  }


  getScene() {

    return this.context.scene;
  }


  getTransformMode(): "translate" | "rotate" | "scale" | undefined {

    return this._controls?.mode;
  }


  private readonly controllerEventCallback = this.onControllerEvent.bind(this);
  onControllerEvent(event: any) {

    this._logger.error(event);

    const controller = event.target;

    if (controller.userData.active === false) return;

    this._controls?.getRaycaster().setFromXRController(controller);

    switch (event.type) {

      case 'selectstart':
        this._controls?.pointerDown(null);
        break;

      case 'selectend':
        this._controls?.pointerUp(null);
        break;

      case 'move':
        this._controls?.pointerHover(null);
        this._controls?.pointerMove(null);
        break;
    }

  }


  /**
   * Transform applied to the defined object. Consumer should handle translations/conversions.
   * @param event 
   * @returns 
   */
  private readonly draggingChangedCallback = this.onDraggingChanged.bind(this);
  private onDraggingChanged(event: unknown) {

    if (!this._transforming) {

      return;
    }

    if (this._object) {

      // this._logger.trace(`mode: ${this._transformControlsMode}`)
      switch (this._transformControlsMode) {

        case TransformMode.ROTATE:
          // this._object.rotation.order = 'YXZ';
          // this._logger.trace("rotationUpdated: local", this._object.rotation);
          // this._logger.trace("rotationUpdated: world", new Euler().setFromQuaternion(this._object.getWorldQuaternion(new Quaternion()), 'YXZ'));

          this._rotationUpdated.next(this._transformControlsSpace === TransformSpace.LOCAL ? this._object.rotation :
            new Euler().setFromQuaternion(this._object.getWorldQuaternion(new Quaternion())));
          break;
        case TransformMode.SCALE:
          // this._logger.trace("scaleUpdated: local", this._object.scale);
          // this._logger.trace("scaleUpdated: world", this._object.getWorldScale(new Vector3()));

          this._scaleUpdated.next(this._transformControlsSpace === TransformSpace.LOCAL ? this._object.scale :
            this._object.getWorldScale(new Vector3()))
          break;
        case TransformMode.TRANSLATE:
          // this._logger.trace("positionUpdated: local", this._object.position);
          // this._logger.trace("positionUpdated: world", this._object.getWorldPosition(new Vector3()));

          this._positionUpdated.next(this._transformControlsSpace === TransformSpace.LOCAL ? this._object.position :
            this._object.getWorldPosition(new Vector3()));
          break;
      }
    }
  }


  override onEvent(eventType: string, eventData: unknown): void {

    this.notify(eventType);
  }


  override onInit() {

    this._camera = (this.context.scene.children[0] as any).camera as PerspectiveCamera;
  }


  private readonly selectCallback = this.onSelect.bind(this);
  onSelect(event: any) {

    this._logger.error(event);

    const controller = event.target;

    this._controller1.userData.active = false;
    this._controller2.userData.active = false;

    if (controller === this._controller1) {

      this._controller1.userData.active = true;
      this._controller1.add(this._line);
    }

    if (controller === this._controller2) {

      this._controller2.userData.active = true;
      this._controller2.add(this._line);
    }

    this._raycaster.setFromXRController(controller);

    const intersects = this._raycaster.intersectObjects(this._selectables.children);

    if (intersects.length > 0) {

      this._controls?.attach(intersects[0].object);
    }
  }


  private _transforming = false;
  private readonly transformMouseDownCallback = this.onTransformMouseDown.bind(this);
  private onTransformMouseDown(event: any) {

    if (this._object) {

      this._object.rotation.order = 'YXZ';
    }
    this._logger.trace(`rotation`, this._object?.rotation);
    this._transforming = true;
    this._transformMouseDown.next(event);
  }


  private readonly transformMouseUpCallback = this.onTransformMouseUp.bind(this);
  private onTransformMouseUp(event: any) {

    this._logger.trace(`rotation`, this._object?.rotation);
    this._transformMouseUp.next(event);
    this._transforming = false;
  }


  override onTick(delta: number) { }


  /**
   * Consider the order of input changes when submitting them
   * @param oldInputs 
   * @returns 
   */
  override onInputsUpdated(oldInputs: MPObjectPropInputs) {

    if (this._object !== this.inputs.object) {

      this.configureGltf();
    }

    this.outputs.enableInteraction = this.events[ComponentInteractionType.CLICK] = this.events[ComponentInteractionType.HOVER] = this.inputs.enableInteraction;
  }


  override onDestroy() {

    this.disposeGltf();
  }


  disposeGltf() {

    this.detachTransformControls();
    this.outputs.objectRoot = null;
    this.outputs.collider = null;
    this._object = undefined;
  }


  // disposeSun() {
  //   this.stopAnimation();
  //   if (this.sun) {
  //     if (this.perlin) {
  //       if (this.textureScene) {
  //         this.textureScene.remove(this.perlin);
  //         this.textureScene = null;
  //       }
  //       (this.perlin.material as THREE.ShaderMaterial).dispose();
  //       this.perlin.geometry.dispose();
  //       this.perlin = null;
  //     }
  //     this.propGroup.remove(this.sun);
  //     (this.sun.material as THREE.ShaderMaterial).dispose();
  //     this.sun.geometry.dispose();
  //     this.sun = null;
  //   }
  // }


  // private disposePenumbra() {
  //   if (this.penumbra) {
  //     (this.penumbra.material as THREE.ShaderMaterial).dispose();
  //     this.penumbra.geometry.dispose();
  //     this.penumbra = null;
  //   }
  // }


  // private applyMeshInputs() {
  //   if (!this.sun) return;

  //   this.sun.visible = this.inputs.visible;
  //   // Thought that, if the mesh is invisible then return would save cycles
  //   // but SweepAdjustments should always be processed because you might miss a state
  //   // TODO: State Machine?
  //   // if (!this.mesh.visible) return;
  //   //this.sun.scale.set(this.inputs.planeScale, this.inputs.planeScale, 1);
  //   this.sun.position.set(this.inputs.sunLocalPosition.x, this.inputs.sunLocalPosition.y, this.inputs.sunLocalPosition.z);
  //   this.sun.updateMatrixWorld();
  // }


  //private sun: THREE.Mesh | null = null;
  //private penumbra: THREE.Mesh | null = null;
  //cubeRendererTarget1!: THREE.WebGLCubeRenderTarget;
  //cubeCamera1!: THREE.CubeCamera;
  //materialPerlin!: any;
  //textureScene: THREE.Scene | null = null;
  //geometry!: THREE.SphereGeometry;
  //perlin: THREE.Mesh | null = null;


  // addTexture() {
  //   const THREE = this.context.three;
  //   this.textureScene = new THREE.Scene();
  //   this.cubeRendererTarget1 = new THREE.WebGLCubeRenderTarget(256, {
  //     format: THREE.RGBAFormat,
  //     generateMipmaps: true,
  //     minFilter: THREE.LinearMipMapLinearFilter,
  //     encoding: THREE.sRGBEncoding // temporary to prevent material's shader from recompiling every frame
  //   });

  //   this.cubeCamera1 = new THREE.CubeCamera(0.1, 10, this.cubeRendererTarget1);

  //   this.materialPerlin = new THREE.ShaderMaterial({
  //     extensions: {
  //       derivatives: true
  //     },
  //     side: THREE.DoubleSide,
  //     uniforms: {
  //       time: { value: 0 },
  //       resolution: { value: new THREE.Vector4() }
  //     },
  //     vertexShader: vertex,
  //     fragmentShader: fragment
  //   });

  //   this.geometry = new THREE.SphereGeometry(.249, 30.0, 30.0);
  //   this.perlin = new THREE.Mesh(this.geometry, this.materialPerlin);

  //   this.textureScene.add(this.perlin);
  // }


  // materialPenumbra!: THREE.ShaderMaterial;
  // createPenumbra() {
  //   if (this.penumbra) {
  //     (this.penumbra.material as THREE.ShaderMaterial).dispose();
  //     this.penumbra.geometry.dispose();
  //     this.penumbra = null;
  //   }
  //   const THREE = this.context.three;

  //   const context = this.context;
  //   const test = (context as any).root;
  //   this._logger.trace(`root`, test);

  //   this.materialPenumbra = new THREE.ShaderMaterial({
  //     blending: THREE.AdditiveBlending,
  //     side: THREE.BackSide,
  //     transparent: true,
  //     vertexShader: vertexPenumbra,
  //     fragmentShader: fragmentPenumbra
  //   });

  //   const penumbraGeometry = new THREE.SphereGeometry(.30, 30.0, 30.0);
  //   this.penumbra = new THREE.Mesh(penumbraGeometry, this.materialPenumbra);
  //   this.propGroup.add(this.penumbra);
  // }


  // materialSun!: THREE.ShaderMaterial;
  // time: number = 0.0;
  // createSun() {
  //   const THREE = this.context.three;
  //   // Clear old references
  //   this.disposeSun();

  //   this.addTexture()

  //   // const context = this.context;
  //   // const test = (context as any).root;
  //   // this._logger.trace(`root`, test);
  //   this.materialSun = new THREE.ShaderMaterial({
  //     extensions: {
  //       derivatives: true
  //     },
  //     side: THREE.DoubleSide,
  //     uniforms: {
  //       time: { value: 0 },
  //       uPerlin: { value: null },
  //       resolution: { value: new THREE.Vector4() }
  //     },
  //     // wireframe: true,
  //     // transparent: true,
  //     vertexShader: vertexSun,
  //     fragmentShader: fragmentSun
  //   });

  //   const sunGeometry = new THREE.SphereGeometry(.25, 30.0, 30.0);
  //   this.sun = new THREE.Mesh(sunGeometry, this.materialSun);
  //   this.sun.position.set(this.inputs.sunLocalPosition.x, this.inputs.sunLocalPosition.y, this.inputs.sunLocalPosition.z);

  //   this.propGroup.add(this.sun);
  //   this.playAnimation();
  // }


}


export const OBJECT_PROP_COMPONENT_TYPE = 'mp.ObjectProp';

export function makeShowroomObjectProp() {
  return new MPObjectPropComponent();
}
