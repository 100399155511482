import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { fromEvent, merge } from 'rxjs';
import { filter, take, startWith, map } from 'rxjs/operators';

export enum TypeDrag {
  Move,
  Top,
  Bottom,
  Left,
  Right,
  TopRight,
  BottomRight,
  TopLeft,
  BottomLeft
}

/**
 * https://stackblitz.com/edit/angular-ahhden-dnbj1l?file=src%2Fapp%2Fresize-border%2Fresize-border.component.ts
 */
@Component({
  selector: 'app-resize-border',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './resize-border.component.html',
  styleUrl: './resize-border.component.scss'
})
export class ResizeBorderComponent implements OnInit {
  rect: any;
  currentBottom = 0;
  incr: number[] = [0, 0, 0, 0];
  nativeElement: any;
  typeDrag!: TypeDrag;
  origin: any;
  onDrag: boolean = false;
  moveSubscription: any;
  modalContent: any;

  classNames = [
    'cell-top',
    'cell-border-top',
    'cell-border-bottom',
    'cell-border-left',
    'cell-border-right',
    'cell-top-right',
    'cell-bottom-right',
    'cell-top-left',
    'cell-bottom-left'
  ];

  style: any = null;
  @Input() set dragHolder(value: any) {

    value.classList.add('cell-top');
  }

  private _calcFromBottom = true;
  @Input() set BottomBased(value: boolean) {

    this._calcFromBottom = value;
  }


  constructor(private readonly elementRef: ElementRef,
    private readonly _logger: NGXLogger) { }

  ngOnInit(): void {

    this.modalContent = this.findModalContent(this.elementRef.nativeElement);

    merge(
      fromEvent(this.elementRef.nativeElement, 'mousedown'),
      fromEvent(this.elementRef.nativeElement, 'touchstart').pipe(map((event: any) => {
        this._logger.trace(`touch start`, event);
        event = event as TouchEvent;
        ({
          target: event.target,
          screenX: event.touches[0].screenX,
          screenY: event.touches[0].screenY
        })
      })
      ))
      .pipe(
        filter((event: any) => {
          const mouseEvent = event as MouseEvent;
          const classs = (mouseEvent.target as any).className;
          if (classs && typeof classs === 'string') {
            const className = classs.split(' ');
            const handleMouseEvent = className.indexOf('cell-top') >= 0 ? true : this.classNames.indexOf(classs) >= 0;
            this._logger.trace(`handle mouse event: ${handleMouseEvent}, className: ${className}`);
            return handleMouseEvent;
          }
          return false;
        })
      )
      .subscribe((event: MouseEvent) => {
        // Capture current state before processing mouse events.
        if (this._calcFromBottom) this.currentBottom = Number(this.modalContent.style['bottom'].replace('px', ''));
        this.rect = this.modalContent.getBoundingClientRect();
        this.origin = { x: event.screenX, y: event.screenY };

        this.onDrag = true;
        const className = (event.target as any).className.split(' ');
        this.typeDrag =
          className.indexOf('cell-top') >= 0
            ? TypeDrag.Move
            : (this.classNames.indexOf(className[0]) as TypeDrag);

        // Capture the drag direction
        this.incr =
          this.typeDrag == TypeDrag.Move
            ? [1, 0, 1, 0]
            : this.typeDrag == TypeDrag.Top
              ? [1, -1, 0, 0]
              : this.typeDrag == TypeDrag.Bottom
                ? [0, 1, 0, 0]
                : this.typeDrag == TypeDrag.Right
                  ? [0, 0, 0, 1]
                  : this.typeDrag == TypeDrag.Left
                    ? [0, 0, 1, -1]
                    : this.typeDrag == TypeDrag.TopRight
                      ? [1, -1, 0, 1]
                      : this.typeDrag == TypeDrag.TopLeft
                        ? [1, -1, 1, -1]
                        : this.typeDrag == TypeDrag.BottomRight
                          ? [0, 1, 0, 1]
                          : [0, 1, 1, -1];

        this.onDrag = true;

        // End drag tracking on mouse/touch up
        merge(fromEvent(document, 'mouseup'),
          fromEvent(document, 'touchend')
        ).pipe(take(1))
          .subscribe(() => {
            this._logger.trace(`mouseup or touchend`, event);
            if (this.moveSubscription) {
              this.moveSubscription.unsubscribe();
              this.moveSubscription = undefined;
              this.onDrag = false;
            }
          });

        // Track mouse/touch movement
        if (!this.moveSubscription) {
          this.moveSubscription = merge(
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'touchmove').pipe(map((event: any) => {
              const touchEvent = event as TouchEvent;
              ({
                target: touchEvent.target,
                screenX: touchEvent.touches[0].screenX,
                screenY: touchEvent.touches[0].screenY
              })
            })
            ))
            .pipe(startWith({ screenY: this.origin.y, screenX: this.origin.x }))
            .subscribe((event: any) => {
              const mouseEvent = event as MouseEvent;


              this._logger.trace(`--> distance = mouse screenY: ${mouseEvent.screenY} - origin.y: ${this.origin.y}`);
              const incrTop = mouseEvent.screenY - this.origin.y;
              this._logger.trace(`--> distance result = ${incrTop}`);


              const incrLeft = mouseEvent.screenX - this.origin.x;
              const width = this.rect.width + this.incr[3] * incrLeft;
              const heigth = this.rect.height + this.incr[1] * incrTop;
              const maxWidth = `${(width < 50 ? 50 : width)}px`;
              const maxHeight = `${(heigth < 75 ? 75 : heigth - 1)}px`;
              this.modalContent.style['max-width'] = maxWidth;
              this.modalContent.style['height'] = maxHeight;

              if (this._calcFromBottom) {
                this.modalContent.style['bottom'] = `${this.currentBottom + (-1 * (this.incr[0] * incrTop))}px`;
              } else {
                this.modalContent.style['margin-top'] = `${this.rect.top + this.incr[0] * incrTop}px`;
              }

              this.modalContent.style['margin-left'] = `${this.rect.left + this.incr[2] * incrLeft}px`;
              this.style = {
                width: maxWidth,
                height: maxHeight
              };
            });
        }


      });
  }


  findModalContent(element: HTMLElement): any {
    const classes = element.className.split(' ');
    return classes.some(c => c === 'modal-dialog')
      ? element
      : element.parentElement
        ? this.findModalContent(element.parentElement)
        : null;
  }

}
