import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import { GltfProperties } from "../scene/gltf";
import { AnimationType } from "../scene/animation";
import { GltfManager } from "../my-three/gltf-manager";
import { GLTFInstance } from "../my-three/GLTFInstance";

export type GltfLoaderState = {
    gltfsToLoad: GltfProperties[] | null;
}

const DEFAULT_SNAPSHOT_URL = '/assets/svg/MyOptyxLogo_BlackYellow.jpg';


export class GltfLoaderComponent extends MyOptyxComponent {

    protected override state: GltfLoaderState = {

        gltfsToLoad: null,
    };
    // Pending state initialized to match current state.
    override pendingState: GltfLoaderState = {

        gltfsToLoad: null,
    };

    // Events
    private readonly _gltfUpdated = new Subject<GLTFInstance | undefined>();
    readonly gltfUpdated$ = this._gltfUpdated.asObservable();
    private readonly _animationTypeUpdated = new Subject<AnimationType>();
    readonly animationTypeUpdated$ = this._animationTypeUpdated.asObservable();

    private readonly _logger = getLogger();
    private _loadingGltfs = false;
    private _currentGltfProperties: GltfProperties | null = null;
    private _currentGltfToLoadIndex = -1;
    private _currentGltfsToLoad: GltfProperties[] | null = null;


    constructor(destroyRef: DestroyRef,
        private readonly gltfManager: GltfManager) {
        super(destroyRef);
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {

        //if (this.pendingStateChanges.find(psc => psc === 'urls')) {}
        this.tryLoadGltf(this.pendingState);
    }


    override getState(): GltfLoaderState {

        // Might want to deepCopy depending on properties.
        return this.state;
    }


    private loadGltf(gltfProperties: GltfProperties, state: GltfLoaderState) {

        if (!gltfProperties || 1 > gltfProperties.source.length) {

            this.setDefaultOutputs();
            //disposeGltf(this._gltf);
            this.loadGltfComplete(state);
            return;
        }

        const that = this;
        // Load from cache
        this.gltfManager.loadGltf(gltfProperties.source,
            // onLoad callback
            function (gltf: GLTFInstance) {
                gltf.scene.position.set(gltfProperties.position.x, gltfProperties.position.y, gltfProperties.position.z);

                that._gltfUpdated.next(gltf);
                that._animationTypeUpdated.next(gltfProperties.animationType);
                that.loadGltfComplete(state);
            },
            // onError callback
            function (err: any) {

                that._logger.error(`error`, err);
                that.setDefaultOutputs();
                that.loadGltfComplete(state);
            }
        );
    }


    private loadGltfComplete(state: GltfLoaderState) {

        const gltfToLoad = this.nextGltfProperties(state);
        if (gltfToLoad) {

            this.loadGltf(gltfToLoad, state);
        } else {

            //this._logger.trace(`loading is complete`);
            this._loadingGltfs = false;
        }
    }


    private nextGltfProperties(state: GltfLoaderState): GltfProperties | null {

        if (!state.gltfsToLoad || 0 === state.gltfsToLoad.length) {

            if (this._currentGltfProperties) {

                // Inputs changed. We had GltfProperties, now we don't.
                this._currentGltfToLoadIndex = -1;
                this._currentGltfProperties = null;
                return { source: '', animationType: AnimationType.NONE } as GltfProperties;
            }
            return null;
        }

        if (state.gltfsToLoad !== this._currentGltfsToLoad) {

            // Inputs changed. We have new Gltfs to load.
            this._currentGltfToLoadIndex = -1;
            this._currentGltfsToLoad = state.gltfsToLoad;
        }

        this._currentGltfToLoadIndex++;

        if (this._currentGltfToLoadIndex >= this._currentGltfsToLoad.length) {

            // All of the Gltfs have been loaded
            // Sanity check
            if (this._currentGltfProperties !== this._currentGltfsToLoad[this._currentGltfsToLoad.length - 1]) {

                this._logger.warn('Gltfs to load is finished but current GltfProperties does not match the last Gltf to load');
            }
            return null;
        }

        // Return the next Gltf to load.
        this._currentGltfProperties = this._currentGltfsToLoad[this._currentGltfToLoadIndex];
        return this._currentGltfProperties;
    }


    override onDestroy(): void { }


    // If overriding be sure to call base method.
    override init(): GltfLoaderComponent {
        super.init();
        return this;
    }


    private setDefaultOutputs() {

        this._gltfUpdated.next(undefined);
        this._animationTypeUpdated.next(AnimationType.NONE);
    }


    private tryLoadGltf(state: GltfLoaderState): void {

        if (this._loadingGltfs) {

            return;
        }
        this._loadingGltfs = true;

        const gltfToLoad = this.nextGltfProperties(state);
        if (gltfToLoad) {

            this.loadGltf(gltfToLoad, state);
        } else {

            //this._logger.trace(`no gltfs to load`);
            this._loadingGltfs = false;
        }
    }


}

