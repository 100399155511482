import { SceneComponent, ComponentInteractionType, ComponentOutput } from '../SceneComponent';
import { HalfFloatType, Object3D, Object3DEventMap, PerspectiveCamera, Vector2, WebGLRenderer } from 'three';
import { getLogger } from 'projects/my-common/src';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutputPass } from './postprocessing/OutputPass';
import { RenderPass } from './postprocessing/RenderPass';
import { SSAARenderPass } from './postprocessing/SSAARenderPass';
import { MpSdk } from 'static/sdk';
import * as THREE from 'three';
// @ts-ignore
//import fragment from './shaders/shader/noise.glsl';
// @ts-ignore
//import vertex from './shaders/shader/vertex.glsl';
// @ts-ignore
//import fragmentSun from './shaders/shaderSun/fragment.glsl';
// @ts-ignore
//import vertexSun from './shaders/shaderSun/vertex.glsl';
// @ts-ignore
//import fragmentPenumbra from './shaders/shaderPenumbra/fragment.glsl';
// @ts-ignore
//import vertexPenumbra from './shaders/shaderPenumbra/vertex.glsl';

/**
 * The fundamental 3D object placement within Matterport.
 * Receives Matterport interaction events.
 * Does not dispose of object.
 */
export type MPSceneInputs = {
  object?: Object3D<Object3DEventMap>;
  enableInteraction: boolean;
}

type Outputs = {
  selectionBoxVisible: boolean;
  enableInteraction: boolean;
} & ComponentOutput;


export class MPSceneComponent extends SceneComponent {
  private readonly _logger = getLogger();
  private _object?: Object3D<Object3DEventMap>;

  override inputs: MPSceneInputs = {
    object: undefined,
    enableInteraction: true
  }

  override outputs = {
    selectionBoxVisible: false,
    enableInteraction: false
  } as Outputs;

  override events = {
    [ComponentInteractionType.CLICK]: true,
    [ComponentInteractionType.HOVER]: true,
    materialDataUpdated: true
  };


  configureGltf() {

    this.disposeGltf();

    if (!this.inputs.object || this._object === this.inputs.object) {

      return;
    }

    this._object = this.inputs.object;

    this.context.scene.add(this._object);
    // this.outputs.objectRoot = this._object;
    // this.outputs.collider = this._object
  }


  private _camera!: PerspectiveCamera;
  private _composer!: EffectComposer;
  private _composer2!: EffectComposer;

  override onInit() {

    // this._camera = (this.context.scene.children[0] as any).camera as PerspectiveCamera;

    // const size = this.context.renderer.getDrawingBufferSize(new Vector2());
    // const renderTarget = new this.context.three.WebGLRenderTarget(size.width, size.height, { samples: 4, type: HalfFloatType });

    // const renderPass = new RenderPass(this.context.scene, this._camera);
    // const outputPass = new OutputPass((this.context.three as any).FullScreenQuad);

    // this._composer1 = new (this.context.three as any).EffectComposer(this.context.renderer);
    // this._composer1.addPass(renderPass);
    // this._composer1.addPass(outputPass);

    // this._composer2 = new EffectComposer(this.context.renderer, renderTarget);
    // this._composer2.addPass(renderPass);
    // this._composer2.addPass(outputPass);

    // this._animate = true;
    // this.animate();
  }


  // async configureEffectComposer(mpsdk: MpSdk) {

  //   await mpsdk.Scene.configure((renderer: WebGLRenderer, three: typeof THREE, effectComposer: EffectComposer | null) => {

  //     if (!effectComposer) {

  //       return;
  //     }


  //     this._composer = effectComposer;
  //     const width = window.innerWidth;
  //     const height = window.innerHeight;
  //     const aspect = width / height;

  //     this._composer.setPixelRatio(1);
  //     this._composer.setSize(width, height);

  //     this._camera = (this.context.scene.children[0] as any).camera as PerspectiveCamera;

  //     //const size = renderer.getDrawingBufferSize(new Vector2());
  //     //const renderTarget = new three.WebGLRenderTarget(size.width, size.height, { samples: 4, type: HalfFloatType });

  //     const renderPass = new (this.context.three as any).RenderPass(this.context.scene, this._camera);
  //     this._composer.addPass(renderPass);

  //     const ssaaRenderPass = new SSAARenderPass(this.context.three, this.context.scene, this._camera, 0x000000, 0);
  //     ssaaRenderPass.unbiased = true;
  //     ssaaRenderPass.sampleLevel = 2;
  //     ssaaRenderPass.renderToScreen = true;
  //     this._composer.addPass(ssaaRenderPass);


  //     // const taaRenderPass = new (this.context.three as any).SSAARenderPass(this.context.scene, this._camera);
  //     // taaRenderPass.unbiased = false;
  //     // taaRenderPass.sampleLevel = 2;
  //     // effectComposer.addPass(taaRenderPass);

  //     // const renderPass = new (this.context.three as any).RenderPass(this.context.scene, this._camera);
  //     // renderPass.enabled = true;

  //     const outputPass = new OutputPass(this.context.three, this._camera);
  //     // effectComposer.addPass(renderPass);
  //     this._composer.addPass(outputPass);
  //     // effectComposer.addPass(renderPass);
  //     // effectComposer.addPass(outputPass);

  //     // //this._composer1 = new (this.context.three as any).EffectComposer(this.context.renderer);
  //     // effectComposer?.addPass(renderPass);
  //     // effectComposer?.addPass(outputPass);
  //     // effectComposer?.addPass(renderPass);
  //     // effectComposer?.addPass(outputPass);

  //     // this._composer2 = new EffectComposer(this.context.renderer, renderTarget);
  //     // this._composer2.addPass(renderPass);
  //     // this._composer2.addPass(outputPass);

  //     window.addEventListener('resize', this.windowResizeCallback);
  //   });

  //}


  override onEvent(eventType: string, eventData: unknown): void {

    this.notify(eventType);
  }


  private readonly windowResizeCallback = this.onWindowResize.bind(this);
  onWindowResize() {

    this._logger.error('Window resize');

    const width = window.innerWidth;
    const height = window.innerHeight;

    this._composer.setSize(width, height);
    // this._camera.aspect = container.offsetWidth / container.offsetHeight;
    // this._camera.updateProjectionMatrix();

    // renderer.setSize( container.offsetWidth, container.offsetHeight );
    // composer1.setSize( container.offsetWidth, container.offsetHeight );
    // composer2.setSize( container.offsetWidth, container.offsetHeight );

  }


  //   private _animate = false;
  //   animate() {

  //     if (!this._animate) {

  //       return;    
  //     }

  // this._logger.error('Animate');
  //     const size = new Vector2();
  //     this.context.renderer.getSize(size);
  //     this._composer1.setSize(size.x, size.y);
  //     this._composer2.setSize(size.x, size.y);

  //     this._composer1.render();
  //     this._composer2.render();

  //     requestAnimationFrame( this.animate.bind(this) );
  //   }


  override onTick(delta: number) {

    // const size = new Vector2();
    // this.context.renderer.getSize(size);
    // this._composer1.setSize(size.x, size.y);
    // composer2.setSize( container.offsetWidth, container.offsetHeight );
  }


  /**
   * Consider the order of input changes when submitting them
   * @param oldInputs 
   * @returns 
   */
  override onInputsUpdated(oldInputs: MPSceneInputs) {

    this.configureGltf();

    this.outputs.enableInteraction = this.events[ComponentInteractionType.CLICK] = this.events[ComponentInteractionType.HOVER] = this.inputs.enableInteraction;
  }


  override onDestroy() {

    window.removeEventListener('resize', this.windowResizeCallback);
    //this._animate = false;
    this.disposeGltf();
  }


  disposeGltf() {

    this.outputs.objectRoot = null;
    this.outputs.collider = null;
    this._object = undefined;
  }


  // disposeSun() {
  //   this.stopAnimation();
  //   if (this.sun) {
  //     if (this.perlin) {
  //       if (this.textureScene) {
  //         this.textureScene.remove(this.perlin);
  //         this.textureScene = null;
  //       }
  //       (this.perlin.material as THREE.ShaderMaterial).dispose();
  //       this.perlin.geometry.dispose();
  //       this.perlin = null;
  //     }
  //     this.propGroup.remove(this.sun);
  //     (this.sun.material as THREE.ShaderMaterial).dispose();
  //     this.sun.geometry.dispose();
  //     this.sun = null;
  //   }
  // }


  // private disposePenumbra() {
  //   if (this.penumbra) {
  //     (this.penumbra.material as THREE.ShaderMaterial).dispose();
  //     this.penumbra.geometry.dispose();
  //     this.penumbra = null;
  //   }
  // }


  // private applyMeshInputs() {
  //   if (!this.sun) return;

  //   this.sun.visible = this.inputs.visible;
  //   // Thought that, if the mesh is invisible then return would save cycles
  //   // but SweepAdjustments should always be processed because you might miss a state
  //   // TODO: State Machine?
  //   // if (!this.mesh.visible) return;
  //   //this.sun.scale.set(this.inputs.planeScale, this.inputs.planeScale, 1);
  //   this.sun.position.set(this.inputs.sunLocalPosition.x, this.inputs.sunLocalPosition.y, this.inputs.sunLocalPosition.z);
  //   this.sun.updateMatrixWorld();
  // }


  //private sun: THREE.Mesh | null = null;
  //private penumbra: THREE.Mesh | null = null;
  //cubeRendererTarget1!: THREE.WebGLCubeRenderTarget;
  //cubeCamera1!: THREE.CubeCamera;
  //materialPerlin!: any;
  //textureScene: THREE.Scene | null = null;
  //geometry!: THREE.SphereGeometry;
  //perlin: THREE.Mesh | null = null;


  // addTexture() {
  //   const THREE = this.context.three;
  //   this.textureScene = new THREE.Scene();
  //   this.cubeRendererTarget1 = new THREE.WebGLCubeRenderTarget(256, {
  //     format: THREE.RGBAFormat,
  //     generateMipmaps: true,
  //     minFilter: THREE.LinearMipMapLinearFilter,
  //     encoding: THREE.sRGBEncoding // temporary to prevent material's shader from recompiling every frame
  //   });

  //   this.cubeCamera1 = new THREE.CubeCamera(0.1, 10, this.cubeRendererTarget1);

  //   this.materialPerlin = new THREE.ShaderMaterial({
  //     extensions: {
  //       derivatives: true
  //     },
  //     side: THREE.DoubleSide,
  //     uniforms: {
  //       time: { value: 0 },
  //       resolution: { value: new THREE.Vector4() }
  //     },
  //     vertexShader: vertex,
  //     fragmentShader: fragment
  //   });

  //   this.geometry = new THREE.SphereGeometry(.249, 30.0, 30.0);
  //   this.perlin = new THREE.Mesh(this.geometry, this.materialPerlin);

  //   this.textureScene.add(this.perlin);
  // }


  // materialPenumbra!: THREE.ShaderMaterial;
  // createPenumbra() {
  //   if (this.penumbra) {
  //     (this.penumbra.material as THREE.ShaderMaterial).dispose();
  //     this.penumbra.geometry.dispose();
  //     this.penumbra = null;
  //   }
  //   const THREE = this.context.three;

  //   const context = this.context;
  //   const test = (context as any).root;
  //   this._logger.trace(`root`, test);

  //   this.materialPenumbra = new THREE.ShaderMaterial({
  //     blending: THREE.AdditiveBlending,
  //     side: THREE.BackSide,
  //     transparent: true,
  //     vertexShader: vertexPenumbra,
  //     fragmentShader: fragmentPenumbra
  //   });

  //   const penumbraGeometry = new THREE.SphereGeometry(.30, 30.0, 30.0);
  //   this.penumbra = new THREE.Mesh(penumbraGeometry, this.materialPenumbra);
  //   this.propGroup.add(this.penumbra);
  // }


  // materialSun!: THREE.ShaderMaterial;
  // time: number = 0.0;
  // createSun() {
  //   const THREE = this.context.three;
  //   // Clear old references
  //   this.disposeSun();

  //   this.addTexture()

  //   // const context = this.context;
  //   // const test = (context as any).root;
  //   // this._logger.trace(`root`, test);
  //   this.materialSun = new THREE.ShaderMaterial({
  //     extensions: {
  //       derivatives: true
  //     },
  //     side: THREE.DoubleSide,
  //     uniforms: {
  //       time: { value: 0 },
  //       uPerlin: { value: null },
  //       resolution: { value: new THREE.Vector4() }
  //     },
  //     // wireframe: true,
  //     // transparent: true,
  //     vertexShader: vertexSun,
  //     fragmentShader: fragmentSun
  //   });

  //   const sunGeometry = new THREE.SphereGeometry(.25, 30.0, 30.0);
  //   this.sun = new THREE.Mesh(sunGeometry, this.materialSun);
  //   this.sun.position.set(this.inputs.sunLocalPosition.x, this.inputs.sunLocalPosition.y, this.inputs.sunLocalPosition.z);

  //   this.propGroup.add(this.sun);
  //   this.playAnimation();
  // }


}


export const SCENE_COMPONENT_TYPE = 'mp.Scene';

export function makeShowroomScene() {
  return new MPSceneComponent();
}
