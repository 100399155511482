import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren, computed, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapCaretRight, bootstrapXLg } from "@ng-icons/bootstrap-icons";
import { Subscription } from 'rxjs/internal/Subscription';
import { NGXLogger } from 'ngx-logger';
import { isChildElement, Vector3Obj } from 'projects/my-common/src/util/utils';
import { CommonVector3InputComponent } from '../../shared/common-vector3-input/common-vector3-input.component';
import { CheckboxComponent } from 'src/app/components/shared/checkbox/checkbox.component';
import { MaskConfigurationComponent } from 'src/app/components/shared/mask-configuration/mask-configuration.component';
import { Vector3InputComponent } from 'src/app/components/shared/vector3-input/vector3-input.component';
import { ClippingPlane, ClippingPlaneAssignment, ClippingPlaneType } from 'projects/my-common/src/model';
import { NoCommaPipe } from 'src/app/shared/pipes/no-comma.pipe';
import { ISlider, SliderService } from 'src/app/core/service/ui/slider/slider.service';
import { SHOWROOM_COMMON_SLIDER_ID } from '../../shared/common-slider/common-slider.component';

const clippingPlaneTypes = Object.values(ClippingPlaneType).filter(value => typeof value !== 'number');

@Component({
  selector: 'app-clipping-plane-assignment',
  standalone: true,
  templateUrl: './clipping-plane-assignment.component.html',
  styleUrls: ['./clipping-plane-assignment.component.scss'],
  providers: [provideIcons({ bootstrapCaretRight, bootstrapXLg })],
  imports: [CommonModule, NgIconComponent, Vector3InputComponent, MaskConfigurationComponent, NoCommaPipe, CheckboxComponent, CommonVector3InputComponent]
})
export class ClippingPlaneAssignmentComponent implements AfterViewInit, OnDestroy {

  private readonly _subscriptions: Subscription[] = [];
  readonly clippingPlaneTypes = clippingPlaneTypes;
  private _commonSlider?: ISlider;
  private _handlingBlur = false;
  private _updateInitializers = true;
  // TODO Can this get confused with other "sliderBasedInputs" on the page elsewhere?
  @ViewChildren('sliderBasedInputs') sliderBasedInputs!: QueryList<HTMLInputElement>;

  readonly rotationInitializer = signal(0);

  readonly clippingPlaneAssignment = signal(new ClippingPlaneAssignment());
  @Input() set ClippingPlaneAssignment(value: ClippingPlaneAssignment) {

    this.clippingPlaneAssignment.set(new ClippingPlaneAssignment(value));
    
    this.setInitializers();
  }
  readonly IsActive = input.required<boolean>();
  readonly Index = input.required<number>();

  readonly AssignClippingPlane = output<ClippingPlane | undefined>();
  readonly ClippingPlaneAssignmentChange = output<ClippingPlaneAssignment>();
  readonly DeleteClippingPlaneAssignment = output<ClippingPlaneAssignment>();
  readonly SelectClippingPlaneAssignment = output<number>()

  isAssigned = computed(() => 0 < this.clippingPlaneAssignment().id);


  constructor(private readonly sliderService: SliderService,
    private readonly logger: NGXLogger) { }

  
  private changeRotation(newRotation: number): void {

    if (this._updateInitializers) {

      this.rotationInitializer.set(newRotation);
    }
    this.clippingPlaneAssignment.update(cpa => {

      cpa.rotation = newRotation;
      return cpa;
    });

    this.ClippingPlaneAssignmentChange.emit(this.clippingPlaneAssignment());
  }


  handleDeleteClippingPlane(assignment: ClippingPlaneAssignment): void {

    this.DeleteClippingPlaneAssignment.emit(assignment);
  }


  handlePositionChange(adjustment: Vector3Obj, target: ClippingPlaneAssignment): void {

    target.position = [adjustment.x, adjustment.y, adjustment.z];
    this.ClippingPlaneAssignmentChange.emit(target);
  }


  handleRotationInput(rotationInputEvent: any): void {

    const newRotation = Number(rotationInputEvent.target.value);
    this.clippingPlaneAssignment.update(cpa => {

      cpa.rotation = newRotation;

      return new ClippingPlaneAssignment(cpa);
    })

    this._commonSlider?.setCurrentValue(newRotation);

    this._updateInitializers = false;
    this.ClippingPlaneAssignmentChange.emit(this.clippingPlaneAssignment());
    setTimeout(() => this._updateInitializers = true);
  }


  handleScaleChange(scale: Vector3Obj, target: ClippingPlaneAssignment): void {

    target.scale = [scale.x, scale.y, scale.z];
    this.ClippingPlaneAssignmentChange.emit(target);
  }


  ngAfterViewInit(): void {

    this._commonSlider = this.sliderService.get(SHOWROOM_COMMON_SLIDER_ID);
  }


  ngOnDestroy(): void {

    this._subscriptions.forEach(s => s.unsubscribe());
  }


  private readonly handleBlurCallback = this.onBlur.bind(this);
  /**
   * Slider should close when focus changes away from sliderBasedInputs
   * but not when the focus changes to the slider itself. 
   */
  onBlur() {

    if (this._handlingBlur) {

      return;
    }
    this._handlingBlur = true;

    this.setInitializers();

    const commonSlider = (this._commonSlider?.elementRef.first as any).nativeElement;
    const sliderBasedInputs = (this.sliderBasedInputs.first as any).nativeElement;
    if (!commonSlider || !sliderBasedInputs) {

      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {
      if (!isChildElement(commonSlider, document.activeElement) && !isChildElement(sliderBasedInputs, document.activeElement)) {

        this._commonSlider?.isOpen.set(false);
      }
      this._handlingBlur = false;
    }, 1);
  }


  onSelect(target: ClippingPlaneAssignment) {

    if (this.clippingPlaneAssignment().isAssigned) {

      this.SelectClippingPlaneAssignment.emit(target.clippingPlane?.id ?? -1);
    }
  }


  /**
   * Triggered by close event initiated by slider.
   */
  private onSliderClose(): void {

    this._commonSlider?.isOpen.set(false);
  }


  onToggleIsAssigned() {

    if (!this.clippingPlaneAssignment().isAssigned) {

      this.AssignClippingPlane.emit(this.clippingPlaneAssignment().clippingPlane);
    } else {

      this.DeleteClippingPlaneAssignment.emit(this.clippingPlaneAssignment());
    }
  }


  /**
   * Bind this and Rotation to the Showroom common slider.
   */
  async setFocusRotation(focusEvent: any): Promise<void> {

    // Focus appears to get called when receiving focus and when losing focus.
    setTimeout(async () => {
      // Make sure the focus is on this element.
      if (focusEvent.srcElement !== document.activeElement) {

        return;
      }
      await this._commonSlider?.reset();
      this._commonSlider?.onChange.set(this.changeRotation.bind(this));
      this._commonSlider?.onClose.set(() => this.onSliderClose.bind(this));
      this._commonSlider?.onBlur.set(() => this.handleBlurCallback);
      this._commonSlider?.isOpen.set(true);
      this._commonSlider?.setCurrentValue(this.clippingPlaneAssignment().rotation);
      this._commonSlider?.label.set('Rotation');
      this._commonSlider?.multiplier.set(.1);
    });
  }


  private setInitializers() {

    if (this._updateInitializers) {

      this.rotationInitializer.set(this.clippingPlaneAssignment().rotation);
    }
  }


}
