import { Component, effect, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapCaretLeft } from '@ng-icons/bootstrap-icons';
import { NGXLogger } from 'ngx-logger';
import { ButtonDropownItem, ButtonDropdownComponent } from 'src/app/components/shared/button-dropdown/button-dropdown.component';
import { IShowroomPosition } from 'projects/my-common/src/model';

enum PropAction { GOTO = "Go to position", SELECT = "Select position" }


@Component({
  selector: 'app-position-action-card',
  standalone: true,
  templateUrl: './position-action-card.component.html',
  styleUrls: ['./position-action-card.component.scss'],
  providers: [provideIcons({ bootstrapCaretLeft })],
  imports: [CommonModule, NgIconComponent, ButtonDropdownComponent]
})
export class PositionActionCardComponent {

  id = crypto.randomUUID();

  //
  // Inputs
  //
  readonly CurrentPosition = input('');
  readonly ShowroomPosition = input.required<IShowroomPosition>()
  //
  // End inputs
  //

  //
  // Outputs
  //
  readonly GoToPosition = output<IShowroomPosition>();
  readonly ScrollToPosition = output<string>();
  //
  // End outputs
  //

  propActions: ButtonDropownItem[] = [
    [0, 0, PropAction.GOTO],
    //[0, 1, PropAction.SELECT],
  ];


  constructor(private readonly _logger: NGXLogger) {

    effect(() => { this.scrollIntoView(this.CurrentPosition()) })
  }


  goToPosition(): void {

    this.GoToPosition.emit(this.ShowroomPosition());
  }


  onPropAction(action: ButtonDropownItem): void {

    switch (action[2]) {
      
      case PropAction.GOTO:
        this.goToPosition();
        break;
      // case PropAction.SELECT:
      //   this.SelectImage.emit(this.showroomPosition());
      //   break;
    }
  }


  scrollIntoView(currentPosition: string): void{

    if (this.ShowroomPosition().id === currentPosition) {

      this.ScrollToPosition.emit(this.id);
    }
  }


}
