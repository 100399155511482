import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren, computed, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { ObjectPropActionCardComponent } from '../object-prop-action-card/object-prop-action-card.component';
import { ImagePropActionCardComponent } from "../image-prop-action-card/image-prop-action-card.component";
import { PositionActionCardComponent } from "../position-action-card/position-action-card.component";
import { IShowroomPosition } from 'projects/my-common/src/model';
import { IStage } from 'src/app/core/model/showroom/showroom.model';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

export const POSITIONS_SIDEBAR_ID = '05b7c663-2241-478a-b376-c8b7455ca1d4';


@Component({
  selector: 'app-positions-sidebar',
  standalone: true,
  providers: [provideIcons({ bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
  templateUrl: './positions-sidebar.component.html',
  styleUrls: ['./positions-sidebar.component.scss'],
  imports: [CommonModule, NgIconComponent, ObjectPropActionCardComponent, ImagePropActionCardComponent, PositionActionCardComponent]
})
export class PositionsSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  sidebarId: string = POSITIONS_SIDEBAR_ID;
  id = crypto.randomUUID();
  readonly isOpen = signal(false);
  readonly closeClicked = signal(false);
  readonly currentPosition = signal('');

  _monitorPositionChangedSubscription?: Subscription;

  readonly showroomPositions = signal(<IShowroomPosition[]>[]);
  @Input({ required: true }) set ShowroomPositions(value: IShowroomPosition[]) {

    this.showroomPositions.set(value.sort((sp1, sp2) => Number(sp1.labelId ?? '0') - Number(sp2.labelId ?? '0') || sp1.id.localeCompare(sp2.id)));
  }

  private _stage!: IStage;
  @Input({ required: true }) set Stage(value: IStage) {
    if (!value.playerPositionUpdate$) return;

    this._stage = value;
    this.monitorPositionChanged();
  }

  readonly GoToPosition = output<IShowroomPosition>();

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('positionsSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  /**
   * Template needs to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.isOpen()) {

      const elementRef = (this.elementRef.first as any).nativeElement;
      elementRef?.focus();

      return true;
    }
    return false;
  })


  constructor(private readonly _sidebarService: SidebarService,
    private readonly _logger: NGXLogger) {

    _sidebarService.add(this);
  }


  private readonly handleBlurCallback = this.handleBlur.bind(this);
  handleBlur(blurEvent: any): void {
    // if (blurEvent.relatedTarget) return;  
    // this.isOpen.set(false);
  }


  handleClose(): void {

    this.closeClicked.set(true);
    setTimeout(() => {

      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 200);
  }


  private monitorPositionChanged(): void {

    this._monitorPositionChangedSubscription?.unsubscribe();
    this._monitorPositionChangedSubscription = this._stage.playerPositionUpdate$.subscribe((newPosition) => {

      this.currentPosition.set(newPosition.id)
    });
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this._sidebarService.remove(this);
    this._monitorPositionChangedSubscription?.unsubscribe();

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  onGoToPosition(showroomPosition: IShowroomPosition): void {

    this.GoToPosition.emit(showroomPosition);
  }


  onScrollToPosition(id: string): void {

    const target = document.getElementById(id);
    const scrollContainer = document.getElementById(this.id);
    if (target && scrollContainer) {

      const count = target.offsetTop - scrollContainer.scrollTop - 85;
      scrollContainer.scrollBy({ top: count, left: 0, behavior: 'smooth' });
    }
  }


  // onSelectImage(imageProp: IImageProp) {

  //   this.SelectImage.emit(imageProp);
  // }


}
