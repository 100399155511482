export const ClaimFields = {
  USER_NAME: "name",
  GIVEN_NAME: "given_name",
  SUR_NAME: "family_name",
  STREET_ADDRESS: "streetAddress",
  CITY: "city",
  STATE: "state",
  POSTAL_CODE: "postalCode",
  COUNTRY: "country",
  EMAILS: "emails"
}

export type UserData = {
    userName: string;
    givenName: string;
    surName: string;
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    emails: string[]; 
}

export type Profile = {
  id?: string,
  userPrincipalName?: string,
  businessPhones?: Array<string>,
  displayName?: string,
  givenName?: string,
  jobTitle?: string,
  mail?: string,
  mobilePhone?: string,
  officeLocation?: string,
  preferredLanguage?: string,
  surname?: string
};