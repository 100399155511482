/**
 * The classes
 * The aggregates for the data
 */
import { deepCopy } from "projects/mp-core/src/lib/util";
import {
    ActiconDefaultOptions, equals as equalsTuple, fields, HorizontalClipMode, PLANE_RENDERER_DEFAUT_RENDER_ORDER,
    TextureFont, urlExists, Vector3Obj, Vector3Tuple, VerticalClipMode
} from "../..";
import {
    ClippingPlaneType, CrudState, EntityStatus, equalsClippingAssignments, equalsClippingPlanes, equalsObjectAssignmentMaterials, equalsPriceOptions,
    IAssignmentBase, IClippingPlane, IClippingPlaneAssignment, IDesignImage, IDesignObject, IDesignObjectMaterial, IDesignVideo, IEventDesign, IGuestLink,
    IImageAssignment, IImageProp, IImagePropOptions, IImportableVideoSearchResult, IObjectAssignment, IObjectAssignmentMaterial, IPriceOption, IObjectProp,
    IPositionAdjustment, IProp, isImportableVideoSearchResult, ISpaceProviderDefaultSetting, ISpaceProviderSetting, IVenue, IVenueEvent,
    IVideoAssignment, IVideoProp, IVideoPropOptions, PropClass, PropType, SpaceProvider
} from "..";


//
// Begin abstract
//

const propFields = fields<Prop>();
export class Prop implements IProp {

    protected _type = PropType.PROP;
    public get type(): PropType {
        return this._type;
    }

    id = 0;
    venueId = 0;
    clippingPlanes: ClippingPlane[] = [];
    isActive = true;
    name = '';
    position = [0, 0, 0] as Vector3Tuple;
    positionAdjustments: PositionAdjustment[] = [];
    propClass = PropClass.Floating;
    rotation = [0, 0, 0] as Vector3Tuple;
    scale = [1, 1, 1] as Vector3Tuple;
    viewPositionId = '';


    public get positionObj(): Vector3Obj {
        return { x: this.position[0], y: this.position[1], z: this.position[2] };
    }
    public set positionObj(positionObj: Vector3Obj) {
        this.position[0] = positionObj.x;
        this.position[1] = positionObj.y;
        this.position[2] = positionObj.z;
    }
    public get rotationObj(): Vector3Obj {
        return { x: this.rotation[0], y: this.rotation[1], z: this.rotation[2] };
    }
    public set rotationObj(rotationObj: Vector3Obj) {
        this.rotation[0] = rotationObj.x;
        this.rotation[1] = rotationObj.y;
        this.rotation[2] = rotationObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }


    constructor(prop?: IProp) {

        if (prop) {

            for (const positionAdjustment of prop.positionAdjustments) {

                this.positionAdjustments.push(new PositionAdjustment(positionAdjustment));
            }
            if (prop.clippingPlanes) {  // Object props don't use clipping planes

                for (const clippingPlane of prop.clippingPlanes) {

                    this.clippingPlanes.push(new ClippingPlane(clippingPlane));
                }
            }

            propKeys.forEach((key) => {

                if (propFields.positionAdjustments !== key && propFields.clippingPlanes !== key) {

                    if (Array.isArray(prop[key as keyof IProp])) {

                        (this as any)[key as keyof IProp] = deepCopy(prop[key as keyof IProp]);
                    } else {

                        (this as any)[key as keyof IProp] = prop[key as keyof IProp] ?? (this as any)[key];
                    }
                }
            });
        }
    }


    adjustmentsByClippingPlane(clippingPlane: ClippingPlane): PositionAdjustment[] {

        return this.positionAdjustments
            .filter(pa => pa.clippingAssignments.some(cpa => cpa.clippingPlaneId === clippingPlane.id));
    }


    removeAdjustment(target: IPositionAdjustment): boolean {

        const index = this.positionAdjustments.findIndex(pa => pa.id === target.id);
        if (- 1 < index) {

            this.positionAdjustments.splice(index, 1);
            this.positionAdjustments = [...this.positionAdjustments];

            return true;
        };

        return false;
    }


    /**
     * Remove Clipping Plane and related Clipping Assignment references.
     * @param target 
     * @returns 
     */
    removeClippingPlane(target: IClippingPlane): boolean {

        let deleted = false;;
        const index = this.clippingPlanes.findIndex(cp => cp.id === target.id);
        if (-1 < index) {

            this.clippingPlanes.splice(index, 1);
            this.clippingPlanes = [...this.clippingPlanes];

            deleted = true;
        }

        // Remove Clipping Assignment references to the Clipping Plane
        for (let adjustment of this.positionAdjustments) {

            adjustment.clippingAssignments = adjustment.clippingAssignments
                .filter(cpa => cpa.clippingPlaneId !== target.id);
        }

        return deleted;
    }


    upsertAdjustment(target: PositionAdjustment): PositionAdjustment {

        let adjustment = new PositionAdjustment(target);
        let index = this.positionAdjustments.findIndex(pa => pa.id === target.id);
        if (-1 < index) {

            this.positionAdjustments[index] = adjustment;
        } else {

            this.positionAdjustments.push(adjustment);
        }
        this.positionAdjustments = [...this.positionAdjustments];

        return adjustment;
    }
}
const propKeys = Object.keys(new Prop());
export function equalsProp(p1: Prop, p2: Prop): boolean {

    return p1.type === p2.type
        && p1.id === p2.id
        && p1.venueId === p2.venueId
        && p1.isActive === p2.isActive
        && p1.name.trim() === p2.name.trim()
        && equalsTuple(p1.position, p2.position)
        && p1.propClass === p2.propClass
        && equalsTuple(p1.rotation, p2.rotation)
        && equalsTuple(p1.scale, p2.scale)
        && p1.viewPositionId === p2.viewPositionId
        && equalsClippingPlanes(p1.clippingPlanes, p2.clippingPlanes)
        && equalsAdjustments(p1.positionAdjustments, p2.positionAdjustments);
}

//
// End Abstract
//

const assignmentBaseFields = fields<AssignmentBase>();
export class AssignmentBase implements IAssignmentBase {

    id = 0;
    description = '';
    eventDesignId = 0;
    enableCart = false;
    enableInteraction = true;
    name = '';

    priceOptions: IPriceOption[] = [];


    constructor(assignment?: IAssignmentBase) {

        if (assignment) {

            // Copy the properties into this
            assignmentBaseKeys.forEach((key) => {

                if (assignmentBaseFields.priceOptions === key && assignment.priceOptions) {

                    this.priceOptions = assignment.priceOptions.map(po => deepCopy(po));
                } else {

                    (this as any)[key as keyof IAssignmentBase] = assignment[key as keyof IAssignmentBase] ?? (this as any)[key];
                }
            });
        }
    }


    removePriceOption(target: IPriceOption): boolean {

        const index = this.priceOptions.findIndex(po => po.id === target.id);
        if (-1 < index) {

            this.priceOptions.splice(index, 1);
            this.priceOptions = [...this.priceOptions];

            return true;
        }

        return false;
    }


    upsertPriceOption(target: IPriceOption): IPriceOption {

        const index = this.priceOptions.findIndex(po => po.id === target.id);
        if (-1 < index) {

            this.priceOptions[index] = target;
        } else {

            this.priceOptions.push(target);
        }

        this.priceOptions = [...this.priceOptions];

        return target;
    }

}
const assignmentBaseKeys = Object.keys(new AssignmentBase());
export function equalsAssignment(a1: AssignmentBase, a2: AssignmentBase): boolean {

    return a1.id === a2.id
        && a1.description.trim() === a2.description.trim()
        && a1.eventDesignId === a2.eventDesignId
        && a1.enableCart === a2.enableCart
        && a1.enableInteraction === a2.enableInteraction
        && a1.name.trim() === a2.name.trim();
}


export class ClippingPlane implements IClippingPlane {

    static readonly URI = 'clipping-plane';

    id = 0;
    parentPropId = 0;
    activatePropLevel = false;
    planeType = ClippingPlaneType.Square;
    position = [0, 0, 0] as Vector3Tuple;
    rotation = 0;
    scale = [0.2, 0.2, 0.2] as Vector3Tuple;

    crudState = CrudState.NONE;

    public get positionObj(): Vector3Obj {
        return { x: this.position[0], y: this.position[1], z: this.position[2] };
    }
    public set positionObj(positionObj: Vector3Obj) {
        this.position[0] = positionObj.x;
        this.position[1] = positionObj.y;
        this.position[2] = positionObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }


    constructor(clippingPlane?: IClippingPlane) {

        if (clippingPlane) {

            // Copy the properties into this
            clippingPlaneKeys.forEach((key) => {

                if (Array.isArray(clippingPlane[key as keyof IClippingPlane])) {

                    (this as any)[key as keyof IClippingPlane] = deepCopy(clippingPlane[key as keyof IClippingPlane]);
                } else {

                    (this as any)[key as keyof IClippingPlane] = clippingPlane[key as keyof IClippingPlane] ?? (this as any)[key];
                }
            });
        }
    }
}
const clippingPlaneKeys = Object.keys(new ClippingPlane);


export class ClippingPlaneAssignment implements IClippingPlaneAssignment {

    static readonly URI = 'clipping-assignment';

    id = 0;
    adjustmentId = 0;
    clippingPlaneId = 0;
    planeType = ClippingPlaneType.Square;
    position = [0, 0, 0] as Vector3Tuple;
    rotation = 0;
    scale = [1, 1, 1] as Vector3Tuple;
    stencilRef = 1;

    clippingPlane?: ClippingPlane = undefined;

    crudState = CrudState.NONE;

    /**
     * Has reference to Clipping Plane and matching clippingPlaneId
     */
    public get isAssigned(): boolean {

        return (this.clippingPlane?.id ?? Number.MIN_SAFE_INTEGER) === this.clippingPlaneId
            && CrudState.DELETED !== this.crudState;
    }


    public get positionObj(): Vector3Obj {
        return { x: this.position[0], y: this.position[1], z: this.position[2] };
    }
    public set positionObj(positionObj: Vector3Obj) {
        this.position[0] = positionObj.x;
        this.position[1] = positionObj.y;
        this.position[2] = positionObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }

    constructor(clippingAssignment?: IClippingPlaneAssignment | IClippingPlane) {

        if (clippingAssignment) {

            let assignmentClass = clippingAssignment as ClippingPlaneAssignment;
            // Copy the properties into this
            clippingPlaneAssignmentKeys.forEach((key) => {

                if (Array.isArray(assignmentClass[key as keyof ClippingPlaneAssignment])) {

                    (this as any)[key as keyof ClippingPlaneAssignment] = deepCopy(assignmentClass[key as keyof ClippingPlaneAssignment]);
                } else {

                    (this as any)[key as keyof ClippingPlaneAssignment] = assignmentClass[key as keyof ClippingPlaneAssignment] ?? (this as any)[key];
                }
            });
        }
    }
}
const clippingPlaneAssignmentKeys = Object.keys(new ClippingPlaneAssignment());


export class DesignImage implements IDesignImage {

    static readonly URI = 'design-image';

    id = 0;
    eventDesignId = 0;
    description = '';
    fileName = '';
    fileSize = 0;
    uploadFileName = '';
    imageUrl = '';

    constructor(designImage?: IDesignImage) {

        if (designImage) {

            // Copy the properties into this
            designImageKeys.forEach((key) => {

                (this as any)[key as keyof IDesignImage] = designImage[key as keyof IDesignImage];
            });
        }
    }
}
const designImageKeys = Object.keys(new DesignImage());


/**
 * The glb file to associate with an Object Prop.
 * Offset, scale and target size vales.
 */
export class DesignObject implements IDesignObject {

    static readonly URI = 'design-object';

    id = 0;
    eventDesignId = 0;
    description = '';
    fileName = '';
    fileSize = 0;
    uploadFileName = '';
    offset = [0, 0, 0] as Vector3Tuple;
    scale = [0, 0, 0] as Vector3Tuple;
    targetSize = [1, 1, 1] as Vector3Tuple;
    objectUrl = '';
    materials: IDesignObjectMaterial[] = [];


    public get offsetObj(): Vector3Obj {
        return { x: this.offset[0], y: this.offset[1], z: this.offset[2] };
    }
    public set offsetObj(offsetObj: Vector3Obj) {
        this.offset[0] = offsetObj.x;
        this.offset[1] = offsetObj.y;
        this.offset[2] = offsetObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }
    public get targetSizeObj(): Vector3Obj {
        return { x: this.targetSize[0], y: this.targetSize[1], z: this.targetSize[2] };
    }

    constructor(designObject?: IDesignObject) {

        if (designObject) {

            // Copy the properties into this
            designObjectKeys.forEach((key) => {

                if (Array.isArray(designObject[key as keyof IDesignObject])) {

                    (this as any)[key as keyof IDesignObject] = deepCopy(designObject[key as keyof IDesignObject]);
                } else {

                    (this as any)[key as keyof IDesignObject] = designObject[key as keyof IDesignObject] ?? (this as any)[key];
                }
            });
        }
    }


    removeMaterial(target: IDesignObjectMaterial): IDesignObjectMaterial {

        let material = [target];
        const index = this.materials.findIndex(m => m.id === target.id);
        if (-1 < index) {

            material = this.materials.splice(index, 1);
            this.materials = [...this.materials];
        }

        return material[0];
    }


    upsertMaterial(target: IDesignObjectMaterial): IDesignObjectMaterial {

        const index = this.materials.findIndex(m => m.id === target.id);
        if (-1 < index) {

            this.materials[index] = target;
        } else {

            this.materials.push(target);
        }

        this.materials = [...this.materials];

        return target;
    }
}
const designObjectKeys = Object.keys(new DesignObject());
export function equalsDesignObject(do1: DesignObject, do2: DesignObject): boolean {

    return do1.id === do2.id
        && do1.eventDesignId === do2.eventDesignId
        && do1.description.trim() === do2.description.trim()
        && do1.fileName === do2.fileName
        && do1.uploadFileName === do2.uploadFileName
        && equalsTuple(do1.offset, do2.offset)
        && equalsTuple(do1.scale, do2.scale)
        && equalsTuple(do1.targetSize, do2.targetSize)
        && do1.objectUrl === do2.objectUrl;
}


declare let shaka: any;
export class DesignVideo implements IDesignVideo {

    static readonly URI = 'design-video';

    id = 0;
    eventDesignId = 0;
    description = '';
    fileName = '';
    fileSize = 0;
    uploadFileName = '';
    videoUrl = '';
    hlsUrl = '';
    snapshotFileName = '';
    snapshotFileSize = 0;
    snapshotUrl = '';

    //private _logger = console;
    // https://stackoverflow.com/questions/42285032/how-do-i-import-shaka-player-into-an-angular-2-application
    //private shaka = require('../../../../../node_modules/shaka-player/dist/shaka-player.compiled');
    private _shakaPlayer: any;

    public get data(): IDesignVideo {

        const { _shakaPlayer, ...data } = this;

        return data as IDesignVideo;
    }

    public get url(): string {

        return this.hlsUrl && 0 < this.hlsUrl.trim().length ? this.hlsUrl : this.videoUrl;
    }


    constructor(designVideo?: IDesignVideo | IImportableVideoSearchResult) {

        if (designVideo) {

            if (isImportableVideoSearchResult(designVideo)) {

                // From search results to support import video feature.
                this.id = designVideo.designVideoId;
                this.eventDesignId = designVideo.eventDesignId;
                this.fileName = designVideo.fileName;
                this.uploadFileName = designVideo.uploadFileName;
                this.videoUrl = designVideo.videoUrl;
                this.hlsUrl = designVideo.hlsUrl;
            } else {

                // Standard DesignVideo object
                // Copy the properties into this
                designVideoKeys.forEach((key) => {

                    (this as any)[key as keyof IDesignVideo] = designVideo[key as keyof IDesignVideo];
                });
            }
        }
    }


    private _attachedVideo?: HTMLVideoElement;
    private async loadHls(video: HTMLVideoElement): Promise<boolean> {

        if (!this.hlsUrl || 5 > this.hlsUrl.length) {

            console.trace(`hls url is undefined.`);
            return false;
        }
        if (!video) {

            console.trace(`video element is undefined.`);
            return false;
        }
        if (this._attachedVideo && video !== this._attachedVideo && this._shakaPlayer) {

            await this._shakaPlayer.detach(this._attachedVideo);
            this._attachedVideo.src = '';
            this._attachedVideo.load();
            this._attachedVideo = undefined;
        }

        video.src = '';
        video.load();

        if (!await urlExists(this.hlsUrl)) {

            console.warn(`invalid hls url: ${this.hlsUrl}. Encoding may still be in progress.`);
            return false;
        }

        if (!this._shakaPlayer) {

            this._shakaPlayer = new shaka.Player();
        }

        await this._shakaPlayer.attach(video);
        this._attachedVideo = video;

        // Attach player to the window to make it easy to access in the JS console.
        //(window as any).shakaPlayer = this._shakaPlayer;

        // Listen for error events.
        this._shakaPlayer.addEventListener('error', this.errorEventCallback);

        // Track video dimensions to set aspect downstream.
        const that = this;
        video.onloadedmetadata = function (e) {

            if (video) {

                const aspect = video.videoWidth / video.videoHeight;
                //that._logger.trace(`video aspect: ${aspect}`);
            }
        };

        // Try to load a manifest.
        // This is an asynchronous process.
        try {

            await this._shakaPlayer.load(this.hlsUrl);
        } catch (e) {

            // onError is executed if the asynchronous load fails.
            this.onError(e);
            return false;
        }

        return true;
    }


    private readonly errorEventCallback = this.onErrorEvent.bind(this);
    private onErrorEvent(event: any) {
        // Extract the shaka.util.Error object from the event.
        this.onError(event.detail);
    }


    private onError(error: any) {
        // Log the error.
        console.error('Error code', error.code, 'object', error);
    }


    /**
     * Don't set video source until after initialization so the UI can render before trying to download a possibly large video.
     * @returns 
     */
    async setVideoSource(video: HTMLVideoElement): Promise<void> {

        // Try hls streaming first.
        if (video && await this.loadHls(video)) {

            return;
        }

        video.src = this.videoUrl;
        video.load();
    }


    private releaseResources() {

        if (this._shakaPlayer) {

            if (this._attachedVideo) {

                this._attachedVideo.src = '';
                this._attachedVideo.load();
                this._shakaPlayer.detach(this._attachedVideo);
            }

            this._shakaPlayer.removeEventListener('error', this.errorEventCallback);
            this._shakaPlayer.destroy();
            this._shakaPlayer = null;
        }
    }


    dispose() {

        this.releaseResources();
    }
}
const designVideoKeys = Object.keys(new DesignVideo());


export const eventDesignFields = fields<EventDesign>();
export class EventDesign implements IEventDesign {

    static readonly URI = 'event-design';

    id = 0;
    venueEventId = 0;
    name = '';
    status = EntityStatus.Active;
    isDefault = false;
    designImages = [] as DesignImage[];
    designObjects = [] as DesignObject[];
    designVideos = [] as DesignVideo[];
    imageAssignments = [] as ImageAssignment[];
    imagePropOptions = [] as ImagePropOptions[];
    objectAssignments = [] as ObjectAssignment[];
    videoAssignments = [] as VideoAssignment[];
    videoPropOptions = [] as IVideoPropOptions[];

    constructor(eventDesign?: IEventDesign) {

        if (eventDesign) {

            // Copy the properties into this
            eventDesignKeys.forEach((key) => {

                switch (key) {
                    case `${eventDesignFields.designImages}`:
                        for (const designImage of eventDesign.designImages) {

                            this.designImages.push(new DesignImage(designImage));
                        }
                        break;
                    case `${eventDesignFields.designObjects}`:
                        for (const designObject of eventDesign.designObjects) {

                            this.designObjects.push(new DesignObject(designObject));
                        }
                        break;
                    case `${eventDesignFields.designVideos}`:
                        for (const designVideo of eventDesign.designVideos) {

                            this.designVideos.push(new DesignVideo(designVideo));
                        }
                        break;
                    case `${eventDesignFields.objectAssignments}`:
                        for (const assignment of eventDesign.objectAssignments) {

                            this.objectAssignments.push(new ObjectAssignment(assignment));
                        }
                        break;
                    case `${eventDesignFields.imageAssignments}`:
                        for (const assignment of eventDesign.imageAssignments) {

                            this.imageAssignments.push(new ImageAssignment(assignment));
                        }
                        break;
                    case `${eventDesignFields.imagePropOptions}`:
                        for (const propOptions of eventDesign.imagePropOptions) {

                            this.imagePropOptions.push(new ImagePropOptions(propOptions));
                        }
                        break;
                    case `${eventDesignFields.videoAssignments}`:
                        for (const assignment of eventDesign.videoAssignments) {

                            this.videoAssignments.push(new VideoAssignment(assignment));
                        }
                        break;
                    case `${eventDesignFields.videoPropOptions}`:
                        this.videoPropOptions = [...eventDesign.videoPropOptions];
                        break;
                    default:
                        (this as any)[key as keyof IEventDesign] = eventDesign[key as keyof IEventDesign];
                }
            });
        }
    }


    getDesignImageForAssignment(imageAssignment: ImageAssignment): DesignImage | undefined {

        return this.designImages.find(di => di.id === imageAssignment.designImageId);
    }


    getDesignObjectForAssignment(objectAssignment: ObjectAssignment): DesignObject | undefined {

        return this.designObjects.find(_do => _do.id === objectAssignment.designObjectId);
    }


    getDesignVideoForAssignment(videoAssignment: VideoAssignment): DesignVideo | undefined {

        return this.designVideos.find(dv => dv.id === videoAssignment.designVideoId);
    }


    getImageAssignmentForDesignImage(designImageId: number, imagePropId: number): ImageAssignment | undefined {

        return this.imageAssignments.find(ia => ia.designImageId === designImageId && ia.imagePropId === imagePropId);
    }


    getObjectAssignmentForObjectProp(objectProp: ObjectProp): ObjectAssignment | undefined {

        return this.objectAssignments.find(oa => oa.objectPropId === objectProp.id);
    }


    getVideoAssignmentForDesignVideo(designVideoId: number, videoPropId: number): VideoAssignment | undefined {

        return this.videoAssignments.find(va => va.designVideoId === designVideoId && va.videoPropId === videoPropId);
    }


    isNewObjectAssignmentValid(objectAssignment: ObjectAssignment): boolean {

        // If Prop is already assigned to the Design Object then we can't assign it again.
        const existingAssignment = this.objectAssignments.find(ia =>
            ia.designObjectId === objectAssignment.designObjectId
            && ia.objectPropId === objectAssignment.objectPropId);
        if (existingAssignment) {

            console.error('assignment already exists', existingAssignment);
            return false;
        }

        // If Design Object doesn't exist then assignment is invalid
        const designObject = this.designObjects.find(_do => _do.id === objectAssignment.designObjectId);
        if (!designObject) {

            console.error('Design object does not exist', objectAssignment);
            return false;
        }

        return true;
    }


    removeDesignImage(target: IDesignImage): boolean {

        const index = this.designImages.findIndex(va => va.id === target.id);
        if (- 1 < index) {

            this.designImages.splice(index, 1);
            this.designImages = [...this.designImages];

            return true;
        };

        return false;
    }


    removeDesignObject(target: IDesignObject): boolean {

        const index = this.designObjects.findIndex(va => va.id === target.id);
        if (- 1 < index) {

            this.designObjects.splice(index, 1);
            this.designObjects = [...this.designObjects];

            return true;
        };

        return false;
    }


    removeDesignVideo(target: IDesignVideo): boolean {

        const index = this.designVideos.findIndex(dv => dv.id === target.id);
        if (- 1 < index) {

            this.designVideos.splice(index, 1);
            this.designVideos = [...this.designVideos];

            return true;
        };

        return false;
    }


    removeImageAssignment(target: IImageAssignment): boolean {

        const index = this.imageAssignments.findIndex(ia => ia.id === target.id);
        if (- 1 < index) {

            this.imageAssignments.splice(index, 1);
            this.imageAssignments = [...this.imageAssignments];

            return true;
        };

        return false;
    }


    removeImageAssignmentsForProp(target: IImageProp): void {

        this.imageAssignments = this.imageAssignments.filter(ia => ia.imagePropId !== target.id);
    }


    removeObjectAssignment(target: IObjectAssignment): boolean {

        const index = this.objectAssignments.findIndex(oa => oa.id === target.id);
        if (- 1 < index) {

            this.objectAssignments.splice(index, 1);
            this.objectAssignments = [...this.objectAssignments];

            return true;
        };

        return false;
    }


    removeObjectAssignmentsForProp(target: IObjectProp): void {

        this.objectAssignments = this.objectAssignments.filter(oa => oa.objectPropId !== target.id);
    }


    removeVideoAssignment(target: IVideoAssignment): boolean {

        const index = this.videoAssignments.findIndex(va => va.id === target.id);
        if (- 1 < index) {

            this.videoAssignments.splice(index, 1);
            this.videoAssignments = [...this.videoAssignments];

            return true;
        };

        return false;
    }


    removeVideoAssignmentsForProp(target: IVideoProp): void {

        this.videoAssignments = this.videoAssignments.filter(va => va.videoPropId !== target.id);
    }


    upsertDesignImage(target: IDesignImage): DesignImage {

        let index = this.designImages.findIndex(di => di.id === target.id);
        if (-1 < index) {

            this.designImages[index] = new DesignImage(target);
            return this.designImages[index];
        }

        index = this.designImages.push(new DesignImage(target));
        return this.designImages[index - 1];
    }


    upsertDesignObject(target: IDesignObject): DesignObject {

        let index = this.designObjects.findIndex(_do => _do.id === target.id);
        if (-1 < index) {

            this.designObjects[index] = new DesignObject(target);
            return this.designObjects[index];
        }

        index = this.designObjects.push(new DesignObject(target));
        return this.designObjects[index - 1];
    }


    upsertDesignVideo(target: IDesignVideo): DesignVideo {

        const designVideo = new DesignVideo(target);
        let index = this.designVideos.findIndex(dv => dv.id === target.id);
        if (-1 < index) {

            this.designVideos[index] = designVideo
            this.designVideos = [...this.designVideos];
            return this.designVideos[index];
        }

        this.designVideos = [designVideo, ...this.designVideos];
        return designVideo;
    }


    upsertImageAssignment(target: IImageAssignment): ImageAssignment {

        let assignment = new ImageAssignment(target);
        let index = this.imageAssignments.findIndex(ia => ia.id === target.id);
        if (-1 < index) {

            this.imageAssignments[index] = assignment;
        } else {

            this.imageAssignments.push(assignment);
        }
        this.imageAssignments = [...this.imageAssignments];

        return assignment;
    }


    upsertImagePropOptions(target: IImagePropOptions): ImagePropOptions {

        let options = new ImagePropOptions(target);
        let index = this.imagePropOptions.findIndex(ipo => ipo.id === target.id);
        if (-1 < index) {

            this.imagePropOptions[index] = options;
        } else {

            this.imagePropOptions.push(options);
        }
        this.imagePropOptions = [...this.imagePropOptions];

        return options;
    }


    upsertObjectAssignment(target: IObjectAssignment): ObjectAssignment {

        let assignment = new ObjectAssignment(target);
        let index = this.objectAssignments.findIndex(oa => oa.id === target.id);
        if (-1 < index) {

            this.objectAssignments[index] = assignment;
        } else {

            this.objectAssignments.push(assignment);
        }
        this.objectAssignments = [...this.objectAssignments];

        return assignment;
    }


    upsertVideoAssignment(target: IVideoAssignment): VideoAssignment {

        let assignment = new VideoAssignment(target);
        let index = this.videoAssignments.findIndex(ia => ia.id === target.id);
        if (-1 < index) {

            this.videoAssignments[index] = assignment;
        } else {

            this.videoAssignments.push(assignment);
        }
        this.videoAssignments = [...this.videoAssignments];

        return assignment;
    }


    upsertVideoPropOptions(target: IVideoPropOptions): VideoPropOptions {

        let options = new VideoPropOptions(target);
        let index = this.videoPropOptions.findIndex(ipo => ipo.id === target.id);
        if (-1 < index) {

            this.videoPropOptions[index] = options;
        } else {

            this.videoPropOptions.push(options);
        }
        this.videoPropOptions = [...this.videoPropOptions];

        return options;
    }
}
const eventDesignKeys = Object.keys(new EventDesign());


export class ImageAssignment extends AssignmentBase implements IImageAssignment {

    static readonly URI = 'image-assignment';

    designImageId = 0;
    imagePropId = 0;
    font = TextureFont.Impact;
    fontSize = 250;
    text = 'Text';
    stretchToFit = true;
    suppressSidebarImage = false;


    constructor(assignment?: IImageAssignment) {
        super(assignment);

        if (assignment) {

            // Copy the non-AssignmentBase properties into this
            imageAssignmentKeys.forEach((key) => {

                (this as any)[key as keyof IImageAssignment] = assignment[key as keyof IImageAssignment];
            });
        }
    }
}
const imageAssignmentKeys = Object.keys(new ImageAssignment())
    .filter(k => !assignmentBaseKeys.some(abk => abk === k));
export function equalsImageAssignment(ia1: ImageAssignment, ia2: ImageAssignment): boolean {

    return equalsAssignment(ia1, ia2)
        && ia1.designImageId === ia2.designImageId
        && ia1.imagePropId === ia2.imagePropId
        && ia1.font === ia2.font
        && ia1.fontSize === ia2.fontSize
        && ia1.text.trim() === ia2.text.trim()
        && ia1.stretchToFit === ia2.stretchToFit
        && ia1.suppressSidebarImage === ia2.suppressSidebarImage
        && equalsPriceOptions(ia1.priceOptions, ia2.priceOptions);
}


/**
 * Objectify Image Prop data from the server
 */
export const imagePropFields = fields<ImageProp>();
export class ImageProp extends Prop implements IImageProp {

    static readonly URI = 'image-prop';

    aspect = 1;
    maskFileName = '';
    maskUrl = '';

    get data(): IImageProp {

        return { ...this }
    }


    constructor(imageProp?: IImageProp) {
        super(imageProp);

        this._type = PropType.IMAGE;
        // Handle the properties not handles by base class
        if (imageProp) {

            imagePropKeys.forEach((key) => {

                (this as any)[key as keyof IImageProp] = imageProp[key as keyof IImageProp];
            });
        }
    }

}
const imagePropKeys = Object.keys(new ImageProp()).filter(key => !propKeys.some(propKey => propKey === key));
export function equalsImageProp(ip1: ImageProp, ip2: ImageProp): boolean {

    return ip1.aspect === ip2.aspect
        && ip1.maskFileName === ip2.maskFileName
        && ip1.maskUrl === ip2.maskUrl
        && equalsProp(ip1, ip2);
}


export class ImagePropOptions implements IImagePropOptions {

    static readonly URI = 'image-prop-options';

    id = 0;
    backgroundColor = '#FFFFFF00';
    eventDesignId = 0;
    imagePropId = 0;
    controlsMargin = ActiconDefaultOptions.margin;
    controlsPosition = ActiconDefaultOptions.alignment;
    controlsZ = ActiconDefaultOptions.z;
    interactionDistance = ActiconDefaultOptions.interactionDistance;
    isLocked = false;


    constructor(imagePropOptions?: IImagePropOptions) {

        if (imagePropOptions) {

            // Copy the properties into this
            imagePropOptionsKeys.forEach((key) => {

                (this as any)[key as keyof IImagePropOptions] = imagePropOptions[key as keyof IImagePropOptions];
            });
        }
    }
}
const imagePropOptionsKeys = Object.keys(new ImagePropOptions());
export function equalsImagePropOptions(ipo1: ImagePropOptions, ipo2: ImagePropOptions): boolean {

    return ipo1.id === ipo2.id
        && ipo1.eventDesignId === ipo2.eventDesignId
        && ipo1.backgroundColor === ipo2.backgroundColor
        && ipo1.controlsMargin === ipo2.controlsMargin
        && ipo1.controlsPosition === ipo2.controlsPosition
        && ipo1.controlsZ === ipo2.controlsZ
        && ipo1.imagePropId === ipo2.imagePropId
        && ipo1.interactionDistance === ipo2.interactionDistance
        && ipo1.isLocked === ipo2.isLocked;
}


export class ObjectAssignment extends AssignmentBase implements IObjectAssignment {

    static readonly URI = 'object-assignment';

    designObjectId = 0;
    objectPropId = 0;
    position = [0, 0, 0] as Vector3Tuple;
    rotation = [0, 0, 0] as Vector3Tuple;
    scale = [1, 1, 1] as Vector3Tuple;
    materials = [] as IObjectAssignmentMaterial[];


    public get positionObj(): Vector3Obj {
        return { x: this.position[0], y: this.position[1], z: this.position[2] };
    }
    public set positionObj(positionObj: Vector3Obj) {
        this.position[0] = positionObj.x;
        this.position[1] = positionObj.y;
        this.position[2] = positionObj.z;
    }
    public get rotationObj(): Vector3Obj {
        return { x: this.rotation[0], y: this.rotation[1], z: this.rotation[2] };
    }
    public set rotationObj(rotationObj: Vector3Obj) {
        this.rotation[0] = rotationObj.x;
        this.rotation[1] = rotationObj.y;
        this.rotation[2] = rotationObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }


    constructor(assignment?: IObjectAssignment) {
        super(assignment);

        if (assignment) {

            // Copy the non-AssignmentBase properties into this
            objectAssignmentKeys.forEach((key) => {

                if (Array.isArray(assignment[key as keyof IObjectAssignment])) {

                    (this as any)[key as keyof IObjectAssignment] = deepCopy(assignment[key as keyof IObjectAssignment]);
                } else {

                    (this as any)[key as keyof IObjectAssignment] = (assignment[key as keyof IObjectAssignment]) ?? (this as any)[key];
                }
            });
        }
    }


    removeMaterial(target: IObjectAssignmentMaterial): IObjectAssignmentMaterial {

        let material = [target];
        const index = this.materials.findIndex(m => m.id === target.id);
        if (-1 < index) {

            material = this.materials.splice(index, 1);
            this.materials = [...this.materials];
        }


        return material[0];
    }


    upsertMaterial(target: IObjectAssignmentMaterial): IObjectAssignmentMaterial {

        const index = this.materials.findIndex(m => m.id === target.id);
        if (-1 < index) {

            this.materials[index] = target;
        } else {

            this.materials.push(target);
        }

        this.materials = [...this.materials];

        return target;
    }
}
const objectAssignmentKeys = Object.keys(new ObjectAssignment())
    .filter(k => !assignmentBaseKeys.some(abk => abk === k));
export function equalsObjectAssignment(oa1: ObjectAssignment, oa2: ObjectAssignment, includeMaterials = false): boolean {

    // TODO: add Price Option comparisons
    const result = equalsAssignment(oa1, oa2)
        && oa1.designObjectId === oa2.designObjectId
        && oa1.objectPropId === oa2.objectPropId
        && equalsTuple(oa1.position, oa2.position)
        && equalsTuple(oa1.rotation, oa2.rotation)
        && equalsTuple(oa1.scale, oa2.scale)
        && equalsPriceOptions(oa1.priceOptions, oa2.priceOptions);

    return includeMaterials ? result && equalsObjectAssignmentMaterials(oa1.materials, oa2.materials) : result;
}


export class ObjectProp extends Prop implements IObjectProp {

    static readonly URI = 'object-prop';

    constructor(objectProp?: IObjectProp) {
        super(objectProp);

        this._type = PropType.OBJECT;
    }
}
export function equalsObjectProp(op1: ObjectProp, op2: ObjectProp): boolean {

    return equalsProp(op1, op2);
}


const adjustmentFields = fields<PositionAdjustment>();
export class PositionAdjustment implements IPositionAdjustment {

    static readonly URI = 'adjustment';

    id = 0;
    adjust = [0, 0, 0] as Vector3Tuple;
    clippingAssignments = [] as ClippingPlaneAssignment[]
    disableDepth = false;
    hide = false;
    horizontalMaskMode = HorizontalClipMode.NONE;
    horizontalMaskWidth = 1;
    horizontalMaskXOffset = 0;
    parentPropId = 0;
    positionId = '';
    renderOrder = PLANE_RENDERER_DEFAUT_RENDER_ORDER;
    rotate = [0, 0, 0] as Vector3Tuple;
    scale = [0, 0, 0] as Vector3Tuple;
    stencilRef = 1;
    verticalMaskHeight = 1;
    verticalMaskMode = VerticalClipMode.NONE;
    verticalMaskYOffset = 0;
    maskFileName = '';
    maskUrl = '';

    // Support file upload
    file!: File;

    // Class specific
    crudState = CrudState.NONE;

    public get adjustObj(): Vector3Obj {
        return { x: this.adjust[0], y: this.adjust[1], z: this.adjust[2] };
    }
    public get rotateObj(): Vector3Obj {
        return { x: this.rotate[0], y: this.rotate[1], z: this.rotate[2] };
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }

    constructor(positionAdjustment?: IPositionAdjustment) {

        if (positionAdjustment) {

            // Copy the properties into this
            positionAdjustmentKeys.forEach((key) => {

                if (`${adjustmentFields.clippingAssignments}` !== key) {

                    if (Array.isArray(positionAdjustment[key as keyof IPositionAdjustment])) {

                        (this as any)[key as keyof IPositionAdjustment] = deepCopy(positionAdjustment[key as keyof IPositionAdjustment]);
                    } else {

                        (this as any)[key as keyof IPositionAdjustment] = positionAdjustment[key as keyof IPositionAdjustment] ?? (this as any)[key];
                    }
                }
            });

            if (positionAdjustment.clippingAssignments
                && 0 < positionAdjustment.clippingAssignments.length) {

                for (const clippingAssignment of positionAdjustment.clippingAssignments) {

                    this.clippingAssignments.push(new ClippingPlaneAssignment(clippingAssignment));
                }
            }
        }
    }


    /**
     * Excludes clipping assignments
     * @param positionAdjustment 
     */
    copyTo(positionAdjustment: PositionAdjustment): void {

        positionAdjustmentKeys.forEach((key) => {

            if (`${adjustmentFields.clippingAssignments}` !== key) {

                if (Array.isArray(this[key as keyof IPositionAdjustment])) {

                    (positionAdjustment as any)[key as keyof IPositionAdjustment] = deepCopy(this[key as keyof IPositionAdjustment]);
                } else {

                    (positionAdjustment as any)[key as keyof IPositionAdjustment] = this[key as keyof IPositionAdjustment];
                }
            }
        });

        positionAdjustment.crudState = this.crudState;
    }


    removeClippingAssignment(assignment: IClippingPlaneAssignment): boolean {

        const index = this.clippingAssignments.findIndex(cpa => cpa.id === assignment.id);
        if (-1 < index) {

            this.clippingAssignments.splice(index, 1);
            this.clippingAssignments = [...this.clippingAssignments];

            return true;
        }

        return false;
    }


    upsertClippingAssignment(target: IClippingPlaneAssignment): ClippingPlaneAssignment {

        const assignment = new ClippingPlaneAssignment(target);
        const index = this.clippingAssignments.findIndex(cpa => cpa.id === target.id);
        if (-1 < index) {

            this.clippingAssignments[index] = assignment;
        } else {

            this.clippingAssignments.push(assignment);
        }

        return assignment;
    }

}
const positionAdjustmentKeys = Object.keys(new PositionAdjustment());
//
// Position adjustment utils
//
export function equalsAdjustment(pa1: IPositionAdjustment, pa2: IPositionAdjustment): boolean {

    return pa1.id === pa2.id
        && pa1.hide === pa2.hide
        && pa1.disableDepth === pa2.disableDepth
        && pa1.horizontalMaskMode === pa2.horizontalMaskMode
        && pa1.horizontalMaskWidth === pa2.horizontalMaskWidth
        && pa1.horizontalMaskXOffset == pa2.horizontalMaskXOffset
        && pa1.maskFileName === pa2.maskFileName
        && pa1.maskUrl === pa2.maskUrl
        && pa1.parentPropId === pa2.parentPropId
        && pa1.positionId === pa2.positionId
        && pa1.renderOrder === pa2.renderOrder
        && pa1.stencilRef === pa2.stencilRef
        && pa1.verticalMaskHeight === pa2.verticalMaskHeight
        && pa1.verticalMaskMode === pa2.verticalMaskMode
        && pa1.verticalMaskYOffset === pa2.verticalMaskYOffset
        && equalsTuple(pa1.adjust, pa2.adjust)
        && equalsTuple(pa1.rotate, pa2.rotate)
        && equalsTuple(pa1.scale, pa2.scale)
        && equalsClippingAssignments(pa1.clippingAssignments, pa2.clippingAssignments);
}

export function equalsAdjustments(adjustments1: IPositionAdjustment[], adjustments2: IPositionAdjustment[]) {

    let isEqual = adjustments1.length === adjustments2.length;

    if (isEqual && 0 < adjustments1.length) {

        for (const adjustment1 of adjustments1) {

            const adjustment2 = adjustments2.find(cpa => cpa.id === adjustment1.id);
            if (!adjustment2 || !equalsAdjustment(adjustment1, adjustment2)) {

                return false;
            }
        }
    }

    return isEqual;
}
//
// End position adjustment utils
//


const venueFields = fields<Venue>();
export class Venue implements IVenue {

    id = 0;
    accountId = 0;
    imageProps: ImageProp[] = [];
    name = '';
    objectProps: ObjectProp[] = [];
    spaceId = '';
    spaceProviderId = SpaceProvider.MATTERPORT;
    spaceProviderDefaultSettings: ISpaceProviderDefaultSetting[] = [];
    spaceProviderSettings: ISpaceProviderSetting[] = [];
    status = EntityStatus.Active;
    venueEvents: VenueEvent[] = [];
    videoProps: VideoProp[] = [];


    constructor(venue?: IVenue) {

        if (!venue) {

            return;
        }

        // Copy the properties into this
        venueKeys.forEach((key) => {

            switch (key) {
                case `${venueFields.imageProps}`:
                    for (const imageProp of venue.imageProps) {

                        this.imageProps.push(new ImageProp(imageProp));
                    }
                    break;
                case `${venueFields.objectProps}`:
                    for (const objectProp of venue.objectProps) {

                        this.objectProps.push(new ObjectProp(objectProp));
                    }
                    break;
                case `${venueFields.spaceProviderDefaultSettings}`:
                    this.spaceProviderDefaultSettings = [...venue.spaceProviderDefaultSettings];
                    break;
                case `${venueFields.spaceProviderSettings}`:
                    this.spaceProviderSettings = [...venue.spaceProviderSettings];
                    break;
                case `${venueFields.venueEvents}`:
                    for (const venueEvent of venue.venueEvents) {

                        this.venueEvents.push(new VenueEvent(venueEvent));
                    }
                    break;
                case `${venueFields.videoProps}`:
                    for (const videoProp of venue.videoProps) {

                        this.videoProps.push(new VideoProp(videoProp));
                    }
                    break;
                default:
                    (this as any)[key as keyof IVenue] = venue[key as keyof IVenue];
                    break;
            }
        });
    }


    getImageAdjustment(adjustmentId: number): PositionAdjustment | undefined {

        for (const prop of this.imageProps) {

            const adjustment = prop.positionAdjustments.find(pa => pa.id === adjustmentId);
            if (adjustment) {

                return adjustment;
            }
        }

        return undefined;
    }


    getDesignObject(designObjectId: number): DesignObject | undefined {

        for (const venueEvent of this.venueEvents) {
            for (const eventDesign of venueEvent.eventDesigns) {

                const designObject = eventDesign.designObjects.find(_do => _do.id === designObjectId);
                if (designObject) {

                    return designObject;
                }
            }
        }

        return undefined;
    }


    getEventDesign(eventDesignId: number): EventDesign | undefined {

        let eventDesign: EventDesign | undefined;
        for (const venueEvent of this.venueEvents) {

            eventDesign = venueEvent.getEventDesign(eventDesignId);
            if (eventDesign) {

                return eventDesign;
            }
        }

        return eventDesign;
    }


    getImageAssignment(imageAssignmentId: number): ImageAssignment | undefined {

        for (const venueEvent of this.venueEvents) {
            for (const eventDesign of venueEvent.eventDesigns) {

                const imageAssignment = eventDesign.imageAssignments.find(ia => ia.id === imageAssignmentId);
                if (imageAssignment) {

                    return imageAssignment;
                }
            }
        }

        return undefined;
    }


    getImageProp(propId: number): ImageProp | undefined {

        return this.imageProps.find(ip => ip.id === propId);
    }


    getObjectProp(propId: number): ObjectProp | undefined {

        return this.objectProps.find(op => op.id === propId);
    }


    getVideoProp(propId: number): VideoProp | undefined {

        return this.videoProps.find(vp => vp.id === propId);
    }


    getObjectAssignment(objectAssignmentId: number): ObjectAssignment | undefined {

        for (const venueEvent of this.venueEvents) {
            for (const eventDesign of venueEvent.eventDesigns) {

                const objectAssignment = eventDesign.objectAssignments.find(oa => oa.id === objectAssignmentId);
                if (objectAssignment) {

                    return objectAssignment;
                }
            }
        }

        return undefined;
    }


    getVenueEvent(venueEventId: number): VenueEvent | undefined {

        return this.venueEvents.find(ve => ve.id === venueEventId);
    }


    getVideoAdjustment(adjustmentId: number): PositionAdjustment | undefined {

        for (const prop of this.videoProps) {

            const adjustment = prop.positionAdjustments.find(pa => pa.id === adjustmentId);
            if (adjustment) {

                return adjustment;
            }
        }

        return undefined;
    }


    removeImageProp(target: IImageProp): boolean {

        let deleted = false;
        if (target.venueId === this.id) {

            const index = this.imageProps.findIndex(ip => ip.id === target.id);
            if (-1 < index) {

                this.imageProps.splice(index, 1);
                this.imageProps = [...this.imageProps];

                deleted = true;
            }

            // Remove any assignments associated with Prop
            for (const venueEvent of this.venueEvents) {

                venueEvent.removeImageAssignments(target);
            }
        }

        return deleted;
    }


    removeObjectProp(target: IObjectProp): boolean {

        let deleted = false;
        if (target.venueId === this.id) {

            const index = this.objectProps.findIndex(op => op.id === target.id);
            if (-1 < index) {

                this.objectProps.splice(index, 1);
                this.objectProps = [...this.objectProps];
                deleted = true;
            }

            // Remove any assignments associated with Prop
            for (const venueEvent of this.venueEvents) {

                venueEvent.removeObjectAssignments(target);
            }
        }

        return deleted;
    }


    removeVideoProp(target: IVideoProp): boolean {

        let deleted = false;
        if (target.venueId === this.id) {

            const index = this.videoProps.findIndex(ip => ip.id === target.id);
            if (-1 < index) {

                this.videoProps.splice(index, 1);
                this.videoProps = [...this.videoProps];
                deleted = true;
            }

            // Remove any assignments associated with Prop
            for (const venueEvent of this.venueEvents) {

                venueEvent.removeVideoAssignments(target);
            }
        }

        return deleted;
    }


    removeVenueEvent(target: IVenueEvent): boolean {

        if (target.venueId === this.id) {

            const index = this.venueEvents.findIndex(ve => ve.id === target.id);
            if (-1 < index) {

                this.venueEvents.splice(index, 1);
                this.venueEvents = [...this.venueEvents];

                return true;
            }
        }

        return false;
    }


    upsertImageClippingPlane(target: IClippingPlane): ClippingPlane {

        const clippingPlane = new ClippingPlane(target);
        const prop = this.imageProps.find(ip => ip.id === clippingPlane.parentPropId);
        if (prop) {

            const clippingPlaneIndex = prop.clippingPlanes.findIndex(cp => cp.id === target.id);
            if (-1 < clippingPlaneIndex) {

                prop.clippingPlanes[clippingPlaneIndex] = clippingPlane;
            } else {

                prop.clippingPlanes.push(clippingPlane);
            }
        }

        return clippingPlane;
    }


    upsertImageProp(target: IImageProp): ImageProp {

        const prop = new ImageProp(target);
        const index = this.imageProps.findIndex(ip => ip.id === target.id);
        if (-1 < index) {

            this.imageProps[index] = prop;
        } else {

            this.imageProps.push(prop);
        }

        return prop;
    }


    upsertImagePropAdjustment(target: IPositionAdjustment): PositionAdjustment {

        const adjustment = new PositionAdjustment(target);
        const prop = this.imageProps.find(ip => ip.id === adjustment.parentPropId);
        if (prop) {

            const adjustmentIndex = prop.positionAdjustments.findIndex(pa => pa.id === target.id);
            if (-1 < adjustmentIndex) {

                prop.positionAdjustments[adjustmentIndex] = adjustment;
            } else {

                prop.positionAdjustments.push(adjustment);
            }
        }

        return adjustment;
    }


    upsertObjectProp(target: IObjectProp): ObjectProp {

        const prop = new ObjectProp(target);
        const index = this.objectProps.findIndex(op => op.id === target.id);
        if (-1 < index) {

            this.objectProps[index] = prop;
        } else {

            this.objectProps.push(prop);
        }

        return prop;
    }


    upsertVenueEvent(target: IVenueEvent): VenueEvent {

        const venueEvent = new VenueEvent(target);
        const eventIndex = this.venueEvents.findIndex(ve => ve.id === target.id);
        if (-1 < eventIndex) {

            this.venueEvents[eventIndex] = venueEvent;
        } else {

            this.venueEvents.push(venueEvent);
        }

        return venueEvent;
    }


    upsertVideoClippingPlane(target: IClippingPlane): ClippingPlane {

        const clippingPlane = new ClippingPlane(target);
        const prop = this.videoProps.find(ip => ip.id === clippingPlane.parentPropId);
        if (prop) {

            const clippingPlaneIndex = prop.clippingPlanes.findIndex(cp => cp.id === target.id);
            if (-1 < clippingPlaneIndex) {

                prop.clippingPlanes[clippingPlaneIndex] = clippingPlane;
            } else {

                prop.clippingPlanes.push(clippingPlane);
            }
        }

        return clippingPlane;
    }


    upsertVideoProp(target: IVideoProp): VideoProp {

        const prop = new VideoProp(target);
        const index = this.videoProps.findIndex(op => op.id === target.id);
        if (-1 < index) {

            this.videoProps[index] = prop;
        } else {

            this.videoProps.push(prop);
        }

        return prop;
    }


    upsertVideoPropAdjustment(target: IPositionAdjustment): PositionAdjustment {

        const adjustment = new PositionAdjustment(target);
        const videoProp = this.videoProps.find(ip => ip.id === adjustment.parentPropId);
        if (videoProp) {

            const adjustmentIndex = videoProp.positionAdjustments.findIndex(pa => pa.id === target.id);
            if (-1 < adjustmentIndex) {

                videoProp.positionAdjustments[adjustmentIndex] = adjustment;
            } else {

                videoProp.positionAdjustments.push(adjustment);
            }
        }

        return adjustment;
    }
}
const venueKeys = Object.keys(new Venue());


const venueEventFields = fields<VenueEvent>();
export class VenueEvent implements IVenueEvent {

    id = 0;
    venueId = 0;
    status = EntityStatus.Active;
    isDefault = false;
    name = '';
    eventDesigns: EventDesign[] = [];
    guestLinks: IGuestLink[] = [];


    constructor(venueEvent?: IVenueEvent) {

        if (!venueEvent) {

            return;
        }

        // Copy the properties into this
        venueEventKeys.forEach((key) => {

            switch (key) {
                case `${venueEventFields.eventDesigns}`:
                    for (const eventDesign of venueEvent.eventDesigns) {

                        this.eventDesigns.push(new EventDesign(eventDesign));
                    }
                    break;
                case `${venueEventFields.guestLinks}`:
                    this.guestLinks = [...venueEvent.guestLinks];
                    break;
                default:
                    (this as any)[key as keyof IVenueEvent] = venueEvent[key as keyof IVenueEvent];
                    break;
            }
        });
    }


    getEventDesign(eventDesignId: number): EventDesign | undefined {

        return this.eventDesigns.find(ed => ed.id === eventDesignId);
    }


    removeEventDesign(target: IEventDesign): IEventDesign {

        let design = [target];
        const eventDesignIndex = this.eventDesigns.findIndex(ed => ed.id = target.id);
        if (-1 < eventDesignIndex) {

            design = this.eventDesigns.splice(eventDesignIndex, 1);
            this.eventDesigns = [...this.eventDesigns];
        }

        return design[0];
    }


    removeImageAssignments(target: IImageProp): void {

        for (const eventDesign of this.eventDesigns) {

            eventDesign.removeImageAssignmentsForProp(target);
        }
    }


    removeObjectAssignments(target: IObjectProp): void {

        for (const eventDesign of this.eventDesigns) {

            eventDesign.removeObjectAssignmentsForProp(target);
        }
    }


    removeVideoAssignments(target: IVideoProp): void {

        for (const eventDesign of this.eventDesigns) {

            eventDesign.removeVideoAssignmentsForProp(target);
        }
    }


    upsertEventDesign(target: IEventDesign): EventDesign {

        const existingDesignIndex = this.eventDesigns.findIndex(ed => ed.id === target.id);
        const eventDesign = new EventDesign(target);
        if (-1 < existingDesignIndex) {

            this.eventDesigns[existingDesignIndex] = eventDesign;
        } else {

            this.eventDesigns.push(eventDesign);
        }

        return eventDesign;
    }


    upsertGuestLink(target: IGuestLink): IGuestLink {

        const existingLinkIndex = this.guestLinks.findIndex(gl => gl.id === target.id);
        if (-1 < existingLinkIndex) {

            this.guestLinks[existingLinkIndex] = target;
        } else {

            this.guestLinks = [target, ...this.guestLinks]
        }

        return target;
    }
}
const venueEventKeys = Object.keys(new VenueEvent());


export class VideoAssignment extends AssignmentBase implements IVideoAssignment {

    static readonly URI = 'video-assignment';

    designVideoId = 0;
    videoPropId = 0;
    suppressSidebarVideo = false;


    constructor(assignment?: IVideoAssignment) {
        super(assignment);

        if (assignment) {

            // Copy the properties into this
            videoAssignmentKeys.forEach((key) => {

                (this as any)[key as keyof IVideoAssignment] = assignment[key as keyof IVideoAssignment];
            });
        }
    }
}
const videoAssignmentKeys = Object.keys(new VideoAssignment())
    .filter(key => !assignmentBaseKeys.some(baseKey => baseKey === key));
export function equalsVideoAssignment(va1: VideoAssignment, va2: VideoAssignment): boolean {

    return equalsAssignment(va1, va2)
        && va1.designVideoId === va2.designVideoId
        && va1.videoPropId === va2.videoPropId
        && va1.suppressSidebarVideo === va2.suppressSidebarVideo;
}


/**
 * Objectify Video Prop data from the server
 */
export class VideoProp extends Prop implements IVideoProp {

    static readonly URI = 'video-prop';

    aspect = 1;
    maskFileName = '';
    maskUrl = '';

    get data(): IImageProp {

        return { ...this }
    }


    constructor(videoProp?: IVideoProp) {
        super(videoProp);

        this._type = PropType.VIDEO;
        // Handle the properties not handles by base class
        if (videoProp) {

            videoPropKeys.forEach((key) => {

                (this as any)[key as keyof IVideoProp] = videoProp[key as keyof IVideoProp];
            });
        }
    }

}
const videoPropKeys = Object.keys(new VideoProp()).filter(key => !propKeys.some(propKey => propKey === key));
export function equalsVideoProp(vp1: VideoProp, vp2: VideoProp): boolean {

    return vp1.aspect === vp2.aspect
        && vp1.maskFileName === vp2.maskFileName
        && vp1.maskUrl === vp2.maskUrl
        && equalsProp(vp1, vp2);
}


export class VideoPropOptions implements IVideoPropOptions {

    static readonly URI = 'video-prop-options';

    id = 0
    audioDistance = 3
    autoPlay = false
    autoPlayDistance = 6
    backgroundColor = '#000000FF'
    controlsMargin = ActiconDefaultOptions.margin
    controlsPosition = ActiconDefaultOptions.alignment
    controlsZ = ActiconDefaultOptions.z
    eventDesignId = 0
    interactionDistance = ActiconDefaultOptions.interactionDistance
    isOrchestrationParent = false
    loop = false
    orchestrationParentId = 0
    videoPropId = 0

    public get isChild(): boolean {

        return !this.isOrchestrationParent && 0 < this.orchestrationParentId;
    }

    constructor(videoPropOptions?: IVideoPropOptions) {

        if (videoPropOptions) {

            // Copy the properties into this
            videoPropOptionsKeys.forEach((key) => {

                (this as any)[key as keyof IVideoPropOptions] = videoPropOptions[key as keyof IVideoPropOptions];
            });
        }
    }
}
const videoPropOptionsKeys = Object.keys(new VideoPropOptions());

