/**
 * Showroom Permission types used for authorization
 */
import { SubscriptionUser } from "projects/my-common/src/model";

export const isShowroomAdmin = { isShowroomAdmin: true } as const;
export type IsShowroomAdmin = typeof isShowroomAdmin;

export const isShowroomSubscriber = { isShowroomSubscriber: true } as const;
export type IsShowroomSubscriber = typeof isShowroomSubscriber

export const isShowroomVisitor = { isShowroomVisitor: true } as const;
export type IsShowroomVisitor = typeof isShowroomVisitor

export type ShowroomPermission = IsShowroomAdmin & IsShowroomSubscriber & IsShowroomVisitor // & anything else
declare const phantom: unique symbol;
// https://xetera.dev/article/typescript-permissions
// Phantom Type
// If you have any properties that should only exist for an authenticated user, you could declare them in this type
export type AuthorizedSubscriptionUser<T extends Partial<ShowroomPermission>> = SubscriptionUser & {
  // Phantom is just to allow Typescript to differentiate this type from User
  // As a unique symbol it is otherwise unaccessible and can't be used.
  [phantom]: T
};

// Create a new User type and assign it to a variable for use as an Authorized User
export type ShowroomAuthorizeResult<T extends Partial<ShowroomPermission>> =
  | { type: "ok"; user: AuthorizedSubscriptionUser<T> }
  | { type: "fail"; reason: string };
  // Could add more return types
  // { type: "visitor"; AuthorizedShowroom<}


const isShowroomRole = {
      // query is a required Permission callback accessible from within authorize
    isShowroomRole: true, query: (permissionDesc: PermissionDescriptor): Promise<PermissionStatus> => {
      console.log('--> Inside query callback with permissionDesc ', permissionDesc);
      return new Promise((resolve, reject) => {
        console.log('--> Inside the authorize Permission callback query');
        // Supposed to return a PermissionStatus which has hooks and other stuff
        // Not sure but, perhaps evaluates permissions ongoing ang can revoke?
        //resolve('Hi back at you!');
        reject('--> Not using Permission callback');
      })
    }
  } as const;
  //type IsShowroomRole = typeof ShowroomPermissions;