import { Component, WritableSignal, computed, signal } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom } from 'rxjs';
import { AccountService } from 'src/app/core/service/myoptyx/account.service';
import { UserService } from 'src/app/core/service/myoptyx/user.service';
import { IShowroomServiceState, IShowroomStateProvider, InitializeShowroomState, ShowroomMode, ShowroomState } from 'src/app/state/showroom-state';

@Component({
  selector: 'app-interaction-mode-select',
  standalone: true,
  imports: [],
  templateUrl: './interaction-mode-select.component.html',
  styleUrl: './interaction-mode-select.component.scss'
})
export class InteractionModeSelectComponent implements IShowroomStateProvider {

  currentState: IShowroomServiceState = <IShowroomServiceState>{};
  propInteractionModes: WritableSignal<ShowroomMode[]> = signal([]);
  currentPropInteractionMode = signal(ShowroomMode.SHOWROOM);

  readonly canWrite = computed(() => this.account.subscriptionUser().canWrite);


  constructor(private readonly logger: NGXLogger,
    readonly account: AccountService, 
    readonly user: UserService) {

    InitializeShowroomState((newState) => this.setShowroomState(newState));
  }


  handlePropInteractionModeChanged(propInteractionModeEvent: any) {

    let selectedIndex = Number(propInteractionModeEvent.target['options'].selectedIndex);
    this.currentState.setShowroomMode(this.propInteractionModes()[selectedIndex]);
  }


  async setShowroomState(newState: IShowroomServiceState): Promise<void> {

    this.logger.trace(`${ShowroomState[newState.showroomState]}`);
    this.currentState = newState;
    this.propInteractionModes.set(
      newState.getInteractionModes(await firstValueFrom(this.account.subscriptionUser$))
    );
  }
  

}
