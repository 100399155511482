import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import {
    Color, ColorRepresentation, DoubleSide, FrontSide, GreaterEqualStencilFunc, Group, LineBasicMaterial,
    Material, Mesh, MeshBasicMaterial, MeshStandardMaterial, NotEqualStencilFunc, Object3D,
    ReplaceStencilOp, Side, Texture, Vector3
} from "three";
import { Vector3Obj, vector3OneObj, vector3ZeroObj } from "../util/utils";
import * as THREE from 'three';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';
import { colorFromHexString, disposeScene, opacityFromHexString } from "../my-three/utils";
import { ClippingPlaneType, IClippingPlane, PropType } from "../model";
import { EcsWorld } from "../ecs";
import { ImagePlane } from "./sub-component/image-plane";

export const PLANE_RENDERER_DEFAUT_RENDER_ORDER = 899;
export const EDGES_NAME = 'edges';

export type PlaneRendererState = {

    adminMode: boolean
    /**
     * Sourced from MaskLoader alphaMap output. Texture Manager maintains the Data Texture via arrays.
     * This avoids destruction and recreation of the texture which is expensive. 
     * Setting alphaMap directly can break that relationship and impact performance.
     */
    alphaMap?: Texture
    aspect: number
    backingColor: string
    clippingPlanes: IClippingPlane[]
    color: ColorRepresentation
    disableDepth: boolean
    enableInteraction: boolean
    /**
     * Used to auto scale image to maintain aspect ratio while fitting within the bounds of the Prop
     * Defaults to 0 to ignore
     * Ignored if stretchToFit is true
     */
    imageAspect: number
    logMaterial: boolean
    opacity: number
    /**
     * For use in calculating interaction distance.
     */
    playerPosition?: Vector3
    position: Vector3Obj
    renderOrder: number
    rotation: Vector3Obj
    scale: Vector3Obj
    selected: boolean
    side: Side
    simulateDepth: boolean
    /**
     * If true, then do not attempt to maintain image aspect ratio
     */
    stretchToFit: boolean
    stencilRef: number
    texture?: Texture
    transparent: boolean
    visible: boolean

    //
    // Transition properties
    //
    /**
     * Used to auto scale image to maintain aspect ratio while fitting within the bounds of the Prop
     * Defaults to 0 to ignore
     * Ignored if stretchToFit is true
     */
    transitionImageAspect: number
    /**
     * If true, then do not attempt to maintain image aspect ratio
     */
    transitionStretchToFit: boolean
    //
    // End transition properties
    //
    transitionTexture?: Texture

}


export class PlaneRendererComponent extends MyOptyxComponent {

    protected override state: PlaneRendererState = {

        adminMode: false,
        alphaMap: undefined,
        aspect: 1,
        backingColor: '#FFFFFF00',
        clippingPlanes: [],
        color: 0xffffff,
        disableDepth: false,
        enableInteraction: true,
        imageAspect: 0,
        logMaterial: false,
        opacity: 1,
        position: vector3ZeroObj,
        renderOrder: PLANE_RENDERER_DEFAUT_RENDER_ORDER,
        rotation: vector3ZeroObj,
        scale: vector3OneObj,
        selected: false,
        side: FrontSide,
        simulateDepth: false,
        stencilRef: 1,
        stretchToFit: true,
        texture: undefined,
        transparent: true,
        visible: true,

        transitionImageAspect: 0,
        transitionStretchToFit: true
    };

    // Pending state initialized to match current state.
    override pendingState: PlaneRendererState = {

        adminMode: false,
        alphaMap: undefined,
        aspect: 1,
        backingColor: '#FFFFFF00',
        clippingPlanes: [],
        color: 0xffffff,
        disableDepth: false,
        enableInteraction: true,
        imageAspect: 0,
        logMaterial: false,
        texture: undefined,
        transparent: true,
        opacity: 1,
        position: vector3ZeroObj,
        renderOrder: PLANE_RENDERER_DEFAUT_RENDER_ORDER,
        rotation: vector3ZeroObj,
        scale: vector3OneObj,
        selected: false,
        side: FrontSide,
        simulateDepth: false,
        stencilRef: 1,
        stretchToFit: true,
        visible: true,

        transitionImageAspect: 0,
        transitionStretchToFit: true
    };


    // Events
    private readonly _enableInteractionSource = new Subject<boolean>();
    readonly enableInteractionUpdated$ = this._enableInteractionSource.asObservable();
    private readonly _groupCreated = new Subject<Group>();
    readonly groupCreated$ = this._groupCreated.asObservable();
    private readonly _textureUpdatedSource = new Subject<Texture | undefined>();
    readonly textureUpdated$ = this._textureUpdatedSource.asObservable();

    private _backingPlane?: Mesh;
    private _backingPlaneMaterial?: MeshStandardMaterial;
    private _displayPlane!: ImagePlane;
    private _logger = getLogger();
    private _planeRendererGroup!: Group;
    private _transitionPlane!: ImagePlane;



    constructor(destroyRef: DestroyRef,
        private readonly three: typeof THREE,
        private readonly world: EcsWorld) {
        super(destroyRef);

        this._displayPlane = new ImagePlane(three);
        this._transitionPlane = new ImagePlane(three);
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     * if (this.pendingStateChanges.find(psc => psc === 'urls')) {}
     */
    protected override applyPendingState(): void {

        // if (this.pendingState.alphaMap) {

        //     throw new Error('alphamap')
        // }

        // Changes that require recreation of backing plane
        if (this.state.aspect !== this.pendingState.aspect) {

            this.createBackingPlane();
        }

        // If we have a plane and we shouldn't then hide it.
        // Disposal has to be magaged in onDestroy or externally due to extenal plane references.
        const forceDisplayInvisible = this._displayPlane.plane && !this.pendingState.texture && !this.pendingState.adminMode;

        if (
            // If we have a plane and its dependent properties have changed the recreate it.
            // Recreating plane is the only way to apply aspect changes
            (this._displayPlane.plane &&
                (this.state.aspect !== this.pendingState.aspect
                    || this.state.stretchToFit !== this.pendingState.stretchToFit
                    || this.state.imageAspect !== this.pendingState.imageAspect))

            // If we don't have a plane and we should then create it.
            || (!this._displayPlane.plane && (this.pendingState.texture || this.pendingState.adminMode))
        ) {

            // This can be an expenisve operation so only execute under the right circumstances
            this.createDisplayPlane();
            // Notify node so that world can handle display plane manipulations.
            // TODO: Update world here to reduce unnecessary external references. Node isn't adding value here.
            this._groupCreated.next(this._planeRendererGroup);
        }

        if (
            // If we have a plane and its dependent properties have changed the recreate it.
            // Recreating plane is the only way to apply aspect changes
            (this._transitionPlane.plane &&
                (this.state.aspect !== this.pendingState.aspect
                    || this.state.transitionStretchToFit !== this.pendingState.transitionStretchToFit
                    || this.state.transitionImageAspect !== this.pendingState.transitionImageAspect))

            // If we don't have a plane and we should then create it.
            || (!this._transitionPlane.plane && this.pendingState.transitionTexture)
        ) {

            // This can be an expenisve operation so only execute under the right circumstances
            this.createTransitionPlane();
        }

        this.createClippingPlanes();

        if (this.state.adminMode !== this.pendingState.adminMode
            || this.state.selected !== this.pendingState.selected) {

            if (this.pendingState.adminMode) {

                this.createEdges();
            } else {

                this.disposeEdges();
            }
        }

        if (this.state.texture !== this.pendingState.texture) {

            this._textureUpdatedSource.next(this.pendingState.texture);
        }

        this._enableInteractionSource.next(this.pendingState.enableInteraction || this.pendingState.adminMode);

        if (this._backingPlane) {

            this._backingPlane.visible = this.pendingState.visible && !forceDisplayInvisible;
        }

        if (this._displayPlane.plane) {

            this._displayPlane.visible = this.pendingState.visible && !forceDisplayInvisible;
            this._displayPlane.applyChanges();
        }

        this._planeRendererGroup.traverse((node) => {

            node.renderOrder = 899;
            //node.onBeforeRender = function (renderer) { renderer.clearDepth(); };
        });

        this.updatePlaneMaterial();
    }


    /**
     * The backing plane is the full size of this component and can appear if the inner display plane has an aspect which 
     * requires it be displayed with letter-boxing within the bounds of this component.
     * The backing plane supports transparency along with color selection.
     * three.js renders opaque objects first, followed by transparent objects.
     * This causes the backing plane, which supports transparency, to be rendered in front of the display pane.
     * To mitigate that, the display plane is positioned slightly in front of the backing plane and writes to the stencil buffer.
     * The backing plane will not render to the stencil buffers set by the display plane.
     * @param state 
     */
    private createBackingPlane() {

        this.disposeBackingPlane();

        const backingPlaneRendererGeometry = new this.three.PlaneGeometry(1.0, 1.0 / this.pendingState.aspect);
        this._backingPlaneMaterial = new this.three.MeshStandardMaterial({
            alphaTest: .01,
            color: colorFromHexString(this.pendingState.backingColor),
            opacity: this.pendingState.opacity != 1 ? this.pendingState.opacity : opacityFromHexString(this.pendingState.backingColor),
            transparent: true,
            depthWrite: false,
            stencilFunc: NotEqualStencilFunc,
            stencilRef: this.pendingState.stencilRef,
            stencilWrite: true,
            stencilZPass: ReplaceStencilOp
        });
        this._backingPlane = new this.three.Mesh(backingPlaneRendererGeometry, this._backingPlaneMaterial);
        this._backingPlane.castShadow = false;

        this._planeRendererGroup.add(this._backingPlane);
    }


    /**
     * Clipping planes are affixed to the front of the plane to provide flexible clipping options.
     * Square, triangle and circle shapes are supported 
     * As 2D planes intended to remain infront of the plane renderer, they can only be rotated around the z axis.
     * They can also be scaled to help acieve the desired clipping goals.
     * Clipping Id (0 - 255) is configurable to differentiate and prevent clipping other plane renderers behind this one.
     */
    private createClippingPlanes(): void {

        if (1 > this.pendingState.clippingPlanes.length) {

            this.disposeClippingPlanes();
            return;
        }

        // Remove Clipping Planes that are no longer in the Clipping Planes list
        for (const child of this._planeRendererGroup.children) {

            if (child === this._backingPlane || child === this._displayPlane.group) {

                continue;
            }

            // If Clipping Plane is no longer in the list then dispose it
            if (0 > this.pendingState.clippingPlanes.findIndex(cp => cp.id === Number(child.name))) {

                this.disposeClippingPlaneGroup(child.name);
            }
        }

        let existingClippingPlane: Object3D<THREE.Object3DEventMap> | undefined;
        let newClippingPlanesAdded = false;
        // Add Clipping Planes that are not created yet
        for (const clippingPlane of this.pendingState.clippingPlanes) {

            // If Clipping Plane is already created then update world values and continue
            existingClippingPlane = this._planeRendererGroup.children.find(c => c.name === `${clippingPlane.id}`);
            if (existingClippingPlane) {

                this.world.upsertClippingPlane({
                    properties: clippingPlane,
                    plane: existingClippingPlane
                }, PropType.IMAGE);
                continue;
            }

            let geometry: any;
            let vertices: Float32Array;
            switch (clippingPlane.planeType) {
                case ClippingPlaneType.Square:
                    geometry = new this.three.PlaneGeometry(1.0, 1.0);
                    break;
                case ClippingPlaneType.Triangle:
                    vertices = new Float32Array([
                        -0.5, -0.5, 0.0, // Vertex 1 (x, y, z)
                        0.5, -0.5, 0.0, // Vertex 2 (x, y, z)
                        0.0, 0.5, 0.0, // Vertex 3 (x, y, z)
                    ]);
                    geometry = new this.three.BufferGeometry();
                    // BufferAttribute not supported by Matterport need to use the older Float32BufferAttribute
                    geometry.setAttribute('position', new this.three.Float32BufferAttribute(vertices, 3));
                    break;
                case ClippingPlaneType.Circle:
                    geometry = new this.three.CircleGeometry(0.2);
                    break;
                case ClippingPlaneType.RightTriangle:
                    vertices = new Float32Array([
                        -0.5, -0.5, 0.0, // Vertex 1 (x, y, z)
                        0.5, -0.5, 0.0, // Vertex 2 (x, y, z)
                        0.5, 0.5, 0.0, // Vertex 3 (x, y, z)
                    ]);
                    geometry = new this.three.BufferGeometry();
                    // BufferAttribute not supported by Matterport need to use the older Float32BufferAttribute
                    geometry.setAttribute('position', new this.three.Float32BufferAttribute(vertices, 3));
                    break;
            }

            const planeMaterial = new MeshBasicMaterial({

                // Stencil buffer: https://www.youtube.com/watch?v=X93GxW84t84
                stencilFunc: GreaterEqualStencilFunc,
                stencilRef: this.pendingState.stencilRef,
                stencilWrite: true,
                stencilZPass: THREE.ReplaceStencilOp,

                // Object occlusion: https://www.youtube.com/watch?v=tv_VTWlpE0w&t=52s
                colorWrite: false,

            });
            const clippingPlaneMesh = new this.three.Mesh(geometry, planeMaterial);
            clippingPlaneMesh.name = `${clippingPlane.id}`;

            // Place within Group
            const clippingPlaneGroup = new this.three.Group();
            clippingPlaneGroup.name = `${clippingPlane.id}`;
            clippingPlaneGroup.scale.set(0, 0, 0);
            clippingPlaneGroup.add(clippingPlaneMesh);

            this._clippingPlaneGroups.push(clippingPlaneGroup);
            this._planeRendererGroup?.add(clippingPlaneGroup);
            this.world.upsertClippingPlane({
                properties: clippingPlane,
                plane: clippingPlaneGroup
            }, PropType.IMAGE);

            newClippingPlanesAdded = true;
        }

        if (newClippingPlanesAdded && this.pendingState.adminMode) {

            this.createEdges();
        }
    }

    private readonly _clippingPlaneGroups: Group[] = [];


    /**
     * The display plane is the primary image plane displayed within the bounds of this component/
     * @returns 
     */
    private createDisplayPlane(): void {

        // Clear old references
        this.disposeDisplayPlane();

        this._displayPlane.aspect = this.pendingState.aspect;
        this._displayPlane.imageAspect = this.pendingState.imageAspect;
        this._displayPlane.stretchToFit = this.pendingState.stretchToFit;
        this._displayPlane.applyChanges();

        this._planeRendererGroup.add(this._displayPlane.group);
    }


    private createEdges(): void {

        if (this._backingPlane) {

            if (0 > this._backingPlane.children.findIndex(c => EDGES_NAME === c.name)) {

                // Add a border of lines
                const edgesGeometry = new this.three.WireframeGeometry(this._backingPlane.geometry);
                const edges = new this.three.LineSegments(edgesGeometry);
                edges.name = EDGES_NAME;
                //(edges.material as LineBasicMaterial).transparent = true;
                //(edges.material as LineBasicMaterial).depthTest = false;
                //(edges.material as any).depthTest = false
                //     , new this.three.LineBasicMaterial({
                //     transparent: true,
                //     color: this._lineColor,
                //     linewidth: 1,
                //     opacity: this.lineOpacity
                // }));
                // Make child of plan so transforms are applied.
                this._backingPlane.add(edges);
            }
        }

        //
        // Creating edges around the ALL clipping planes in the scene causes raycasting and selection to become unstable.
        // Only display clipping plane edges if prop selected.
        //
        if (this.pendingState.selected) {

            // Each ClippingPlane assignment has a group added to the plane renderer group
            // That group holds the clipping plane and it's edges.
            for (const clippingPlaneGroup of this._clippingPlaneGroups) {

                const clippingPlaneMesh = clippingPlaneGroup.children.find(c => clippingPlaneGroup.name === c.name) as Mesh;
                if (clippingPlaneMesh) {

                    const edgesGeometry = new this.three.WireframeGeometry(clippingPlaneMesh.geometry);
                    const edges = new this.three.LineSegments(edgesGeometry);
                    (edges.material as LineBasicMaterial).color = new Color(Color.NAMES.green);
                    (edges.material as LineBasicMaterial).needsUpdate = true;
                    edges.name = `${EDGES_NAME}${clippingPlaneGroup.name}`;

                    clippingPlaneGroup.add(edges);
                }
            }
        } else {

            this.disposeClippingPlaneEdges();
        }
    }


    /**
     * The display plane is the primary image plane displayed within the bounds of this component/
     * @returns 
     */
    private createTransitionPlane(): void {

        // Clear old references
        this.disposeTransitionPlane();

        this._transitionPlane.aspect = this.pendingState.aspect;
        this._transitionPlane.imageAspect = this.pendingState.transitionImageAspect;
        this._transitionPlane.stretchToFit = this.pendingState.transitionStretchToFit;
        this._transitionPlane.applyChanges();

        this._planeRendererGroup.add(this._transitionPlane.group);
    }


    // https://github.com/mrdoob/three.js/blob/master/examples/misc_exporter_gltf.html
    getGltf(callback: (result: any) => void) {

        if (!this._displayPlane.plane || !this._displayPlane.material || !this.state.texture) {

            callback(undefined);
            return;
        }

        const gltfExporter = new (this.three as any).GLTFExporter(); // new GLTFExporter(); // 
        const that = this;

        // Temporarily set material to double sided for model-viewer experience.
        this._displayPlane.material.side = DoubleSide;
        this._displayPlane.material.needsUpdate = true;

        const clone = SkeletonUtils.clone(this._displayPlane.plane);
        clone.quaternion.set(0, 0, 0, 1);
        if (gltfExporter) {

            gltfExporter.parse(
                // matterportScene
                clone,
                function (result: any) {

                    // Restore default material settings.
                    if (that._displayPlane.material) {

                        that._displayPlane.material.side = FrontSide;
                        that._displayPlane.material.needsUpdate = true;
                    }

                    if (result instanceof ArrayBuffer) {

                        disposeScene(clone);
                        //that._logger.error('Binary export');

                        // Download glb file
                        //downloadBufferAsBinaryBlob( result, 'scene.glb' );  
                        //callback(new Blob([ result ], { type: 'application/octet-stream' }))
                        callback(result);
                    } else {

                        disposeScene(clone);
                        //that._logger.error('String export');
                        const output = JSON.stringify(result, null, 2);

                        // Download gltf file
                        //downloadStringAsBlob( output, 'scene.gltf' );
                        callback(output);
                        if (that._displayPlane.material) {

                            that._displayPlane.material.side = FrontSide;
                            that._displayPlane.material.needsUpdate = true;
                        }
                    }
                },
                function (error: any) {

                    disposeScene(clone);
                    that._logger.error('An error happened exporting gltf', error);
                    callback(undefined);
                    if (that._displayPlane.material) {

                        that._displayPlane.material.side = FrontSide;
                        that._displayPlane.material.needsUpdate = true;
                    }
                },
                { // gltfExporter options for Matterport = trs: false, onlyVisible: true, binary: false
                    trs: false,
                    onlyVisible: true,
                    binary: false
                }
            );
        }
    }


    getClippingPlane(name: string): Object3D | undefined {

        return this._clippingPlaneGroups.find(c => c.name === name);
    }


    getImagePlane(): Mesh {

        return this._displayPlane.plane!;
    }


    getObject(): Object3D {

        return this._planeRendererGroup;
    }


    override getState(): PlaneRendererState {

        // Might want to deepCopy depending on properties.
        return this.state;
    }


    getTransitionPlane(): Mesh {

        return this._transitionPlane.plane!;
    }


    override onDestroy(): void {

        this.disposeBackingPlane();
        this.disposeClippingPlanes();
        this.disposeDisplayPlane();
        this.disposeTransitionPlane();
    }


    // If overriding be sure to call base method.
    override init(): PlaneRendererComponent {
        super.init();

        this._planeRendererGroup = new this.three.Group();
        // Setting renderOrder prevents the bottom half of plane from getting clipped during transitions.
        this._planeRendererGroup.renderOrder = PLANE_RENDERER_DEFAUT_RENDER_ORDER;
        this._groupCreated.next(this._planeRendererGroup);

        this.createBackingPlane();

        return this;
    }


    private updatePlaneMaterial() {

        if (this._backingPlaneMaterial) {

            this._backingPlaneMaterial.alphaMap = this.pendingState.alphaMap ?? null;
            this._backingPlaneMaterial.depthWrite = !this.pendingState.disableDepth;
            this._backingPlaneMaterial.color = colorFromHexString(this.pendingState.backingColor);
            this._backingPlaneMaterial.opacity = this.pendingState.opacity != 1 ? this.pendingState.opacity : opacityFromHexString(this.pendingState.backingColor);
            this._backingPlaneMaterial.stencilRef = this.pendingState.stencilRef;
            this._backingPlaneMaterial.transparent = true;
            this._backingPlaneMaterial.needsUpdate = true;
        }

        if (this._displayPlane) {

            this._displayPlane.disableDepth = this.pendingState.disableDepth;
            this._displayPlane.opacity = this.pendingState.opacity;
            this._displayPlane.stencilRef = this.pendingState.stencilRef;
            this._displayPlane.transparent = this.pendingState.transparent;
            this._displayPlane.color = this.pendingState.color;
            this._displayPlane.texture = this.pendingState.texture;
            this._displayPlane.alphaMap = this.pendingState.alphaMap;
            this._displayPlane.applyChanges();
        }

        if (this._transitionPlane) {

            this._transitionPlane.disableDepth = this.pendingState.disableDepth;
            this._transitionPlane.opacity = this.pendingState.opacity;
            this._transitionPlane.stencilRef = this.pendingState.stencilRef;
            this._transitionPlane.transparent = this.pendingState.transparent;
            this._transitionPlane.color = this.pendingState.color;
            this._transitionPlane.texture = this.pendingState.transitionTexture;
            this._transitionPlane.alphaMap = this.pendingState.alphaMap;
            this._transitionPlane.visible = this.pendingState.transitionTexture ? true : false;
            this._transitionPlane.applyChanges();
        }

        if (this._backingPlane) {

            const mesh = this._backingPlane.children.find(c => EDGES_NAME === c.name) as Mesh;
            if (mesh) {

                (mesh.material as LineBasicMaterial).color = this.pendingState.selected ? new Color(Color.NAMES.black) : new Color(Color.NAMES.red);
                (mesh.material as LineBasicMaterial).opacity = this.pendingState.selected ? 1 : 0.4;
                (mesh.material as LineBasicMaterial).needsUpdate = true;
            }
        }

        for (const clippingPlaneGroup of this._clippingPlaneGroups) {

            const clippingPlaneMesh = clippingPlaneGroup.children.find(c => !c.name.startsWith(EDGES_NAME)) as Mesh;
            if (clippingPlaneMesh) {

                //(clippingPlaneMesh.material as Material).depthWrite = !this.pendingState.disableDepth;    // Do not apply to clipping planes
                (clippingPlaneMesh.material as Material).stencilRef = this.pendingState.stencilRef;
                (clippingPlaneMesh.material as Material).needsUpdate = true;
            }
        }

    }


    // 
    // Dispose stuff
    //


    private disposeBackingPlane() {

        if (this._backingPlane) {

            this.disposeEdges();
            this._planeRendererGroup.remove(this._backingPlane);
            this._backingPlane.geometry.dispose();
            (this._backingPlane.material as MeshStandardMaterial).dispose();
            this._backingPlane = undefined;
        }
    }


    private disposeEdges() {

        if (this._backingPlane && 0 < this._backingPlane.children.length) {

            const edges = this._backingPlane.children.find(c => EDGES_NAME === c.name) as Mesh;
            if (edges) {

                this._backingPlane.remove(edges);
                edges.geometry.dispose();
                (edges.material as LineBasicMaterial).dispose();
            }
        }

        this.disposeClippingPlaneEdges();
    }


    private disposeClippingPlaneEdges() {

        for (const clippingPlaneGroup of this._clippingPlaneGroups) {

            for (const edges of clippingPlaneGroup.children) {

                if (edges.name.startsWith(EDGES_NAME)) {

                    clippingPlaneGroup.remove(edges);
                    (edges as Mesh).geometry.dispose();
                    ((edges as Mesh).material as LineBasicMaterial).dispose;
                }
            }
        }
    }


    private disposeClippingPlaneGroup(name: string): void {

        const index = this._clippingPlaneGroups.findIndex(c => c.name === name);
        if (-1 < index) {

            const clippingPlaneGroup = this._clippingPlaneGroups[index];
            this._clippingPlaneGroups.splice(index, 1);

            this.world.removeClippingPlane(Number(clippingPlaneGroup.name), PropType.IMAGE);
            this._planeRendererGroup.remove(clippingPlaneGroup);
            for (const child of clippingPlaneGroup.children) {

                (child as Mesh).geometry.dispose();
                ((child as Mesh).material as MeshBasicMaterial).dispose();
            }
        }
    }


    private disposeClippingPlanes() {

        for (const clippingPlaneGroup of [...this._clippingPlaneGroups]) {

            this.disposeClippingPlaneGroup(clippingPlaneGroup.name);
        }
    }


    private disposeDisplayPlane() {

        if (this._displayPlane.plane) {

            this._planeRendererGroup.remove(this._displayPlane.group);
            this._displayPlane.dispose();
        }
    }


    private disposeTransitionPlane() {

        if (this._transitionPlane.plane) {

            this._planeRendererGroup.remove(this._transitionPlane.group);
            this._transitionPlane.dispose();
        }
    }

}