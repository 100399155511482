import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { VideoSliderComponent } from "../../../components/shared/video-slider/video-slider.component";
import { stageObjectCategories, stageObjectOptions } from 'src/app/core/service/matterport/app-state';

async function downloadFileFromUrl(url: string, fileName: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    console.log(`--> blob for ${fileName}`, blob);
    const test = new Blob([blob], {type: 'application/octet-stream}'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(test);
    link.download = fileName;
    link.click();
}

function downloadFile (sUrl: string) {

    //iOS devices do not support downloading. We have to inform user about this.
    // if (/(iP)/g.test(navigator.userAgent)) {
    //    //alert('Your device does not support files downloading. Please try again in desktop browser.');
    //    window.open(sUrl, '_blank');
    //    return false;
    // }

    //If in Chrome or Safari - download via virtual link click
    //if (window.downloadFile.isChrome || window.downloadFile.isSafari) {
        //Creating new link node.
        var link = document.createElement('a');
        link.href = sUrl;
        link.setAttribute('target','_blank');

        if (link.download !== undefined) {
            //Set HTML5 download attribute. This will prevent file from opening if supported.
            var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
            link.download = fileName;
        }

        //Dispatching click event.
        if (document.createEvent) {
            var e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
            return true;
        }
    //}

    // Force file download (whether supported by server).
    if (sUrl.indexOf('?') === -1) {
        sUrl += '?download';
    }

    window.open(sUrl, '_blank');
    return true;
}



async function dumpJson(url: string, fileName: string, downloadedFileName: string) {
    let data = '';
    const baseUrl = url.replace(fileName, '');
    fetch(url)
        .then(function (response) {
            return response.json();
        })
        .then(async (myJson: any) => {
            data = myJson;
            await downloadFileFromUrl(url, getNewFileName(url.split('/')));
            let nextUrl = `${baseUrl}${myJson.buffers[0].uri}`;
            console.log(`downloading ${nextUrl}`);
            await downloadFileFromUrl(nextUrl, getNewFileName(nextUrl.split('/')));
            // for (const image of myJson.images) {
            //     nextUrl = `${baseUrl}${image.uri}`;
            //     console.log(`downloading ${nextUrl}`);
            //     //await downloadFileFromUrl(nextUrl, getNewFileName(nextUrl.split('/')));
            //     downloadFile(nextUrl);
            // }
        });
}


function getNewFileName(splitResult: string[]): string {
    let fileName = '';
    let foundModel = false;
    for (const fragment of splitResult) {
        if (foundModel) {
            if ('' === fileName) fileName = fragment;
            else fileName = `${fileName}_${fragment}`;
        }
        if ('models' === fragment) foundModel = true;
    }

    return fileName;
}


@Component({
    selector: 'app-main-home',
    standalone: true,
    templateUrl: './main-home.component.html',
    styleUrls: ['./main-home.component.scss'],
    imports: [CommonModule, VideoSliderComponent]
})
export class MainHomeComponent implements OnInit {

    constructor(private readonly _logger: NGXLogger) { }
    

    async ngOnInit() {
        //Download assets to avoid CORS issue
        // const category = stageObjectCategories.get('slot0');
        // if (!category) return;
        // const items = stageObjectOptions.get(category);
        // if (!items) return;
        // let i = 0;
        // for (const item of items) {
        //     const splitResult = item.url.split('/');
        //     this._logger.trace(`--> ngOnInit() - url: ${item.url}, file: ${splitResult[splitResult.length - 1]}`)
        //     //downloadFileFromUrl(item.url, `x${i}_${splitResult[splitResult.length - 1]}`);
        //     this._logger.trace(splitResult);
        //     await dumpJson(item.url, splitResult[splitResult.length - 1], getNewFileName(splitResult));
        //     i++;
        // }
    }


}
