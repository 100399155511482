<div (click)="onSelect()" 
    class="flex flex-col space-y-2 {{ isActive() ? 'border-white pb-4 border-[1px] rounded-md p-1':  'bg-base-100 rounded-md p-2'}}">
    <div class="flex flex-row w-full items-center justify-between">
        <app-checkbox Label="{{'Hide ' + positionAdjustment().positionId}}" [Checked]="positionAdjustment().hide"
            (Toggled)="onToggleHide(positionAdjustment())" [IsReadOnly]="!isActive()" />

        @if (isActive()) {
        
        <div class="tooltip tooltip-left" data-tip="Delete">    
            <div (click)="handleDeletePositionAdjustment(positionAdjustment())" 
                class="h-10 cursor-pointer group flex relative">
                <ng-icon class="text-xl" name="bootstrapXLg" />
            </div>
        </div>

        } @else {

        <div class="tooltip tooltip-left" data-tip="Go to">
            <button (click)="onGoToPosition()"
                class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                <label class="flex justify-center">
                    <ng-icon class="cursor-pointer" size="22" name="bootstrapCaretRight" />
                </label>
            </button>
        </div>

        }
    </div>

    @if (!positionAdjustment().hide) {

    @if (isActive()) {

    <div class="flex flex-row space-x-6 border-b-[1px] border-b-slate-600 mb-2 overflow-hidden overflow-x-auto snap-x">
        <div class="snap-center ml-2 md:ml-0 flex flex-row space-x-4 items-center {{ selectedTab() === 1 ? 'bg-slate-600 rounded-t-md px-4 py-1' : 'cursor-pointer' }}"
            (click)="selectTab(1)">
            <h1 class="text-2xl">Adjust</h1>
        </div>
        <div class="snap-center flex flex-row space-x-4 items-center {{ selectedTab() === 2 ? 'bg-slate-600 rounded-t-md px-4 py-1' : 'cursor-pointer' }}"
            (click)="selectTab(2)">
            <h1 class="text-2xl">Clipping</h1>
        </div>
    </div>

    @if (1 === selectedTab()) {

    <div class="flex flex-row justify-between items-center">
        <span>Position</span>
        <app-common-vector3-input [Value]="positionAdjustment().adjustObj" Label="Position"
            (OnChange)="handlePositionAdjustmentChange($event, positionAdjustment())" [IsActive]="isActive()" />
    </div>

    <div class="flex flex-row justify-between items-center">
        <span>Rotation</span>
        <app-common-vector3-input [Value]="positionAdjustment().rotateObj" Label="Rotate"
            (OnChange)="handleRotateAdjustmentChange($event, positionAdjustment())" [IsActive]="isActive()" />
    </div>

    <div class="flex flex-row justify-between items-center">
        <span>Scale</span>
        <app-common-vector3-input [Value]="positionAdjustment().scaleObj"
            (OnChange)="handleScaleAdjustmentChange($event, positionAdjustment())" Label="Scale" [EnableEqual]="true"
            [IsActive]="isActive()" />
    </div>

    <div class="flex flex-row justify-between items-center">

        <app-checkbox Label="Disable depth" [Checked]="positionAdjustment().disableDepth"
            (Toggled)="onToggleDisableDepth()" [IsReadOnly]="!isActive()" />

        @if (!isCustomMaskFile()) {

        <input type="file" [id]="inputId" class="hidden"
            accept="image/png, image/jpeg, image/gif, image/jpg, image/webp" onclick="this.value = null;"
            (change)="addCustomMaskToPositionAdjustment($event)">
        <div class="tooltip tooltip-left" data-tip="Upload mask">
            <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                <label class="flex justify-center" [for]="inputId">
                    <ng-icon class="cursor-pointer" size="25" name="bootstrapPlusLg" />
                </label>
            </button>
        </div>

        }

    </div>

    @if (positionAdjustment().disableDepth) {

    <div class="flex flex-row space-x-2 items-center ">
        <div class="w-1/2">Render order</div>
        <input class="w-1/2 text-primary text-center placeholder:text-primary h-10 outline-accent" type="number" min="0"
            max="999" [value]="positionAdjustment().renderOrder"
            (input)="handleRenderOrderInput($event, positionAdjustment())">
    </div>

    }        

    @if (isCustomMaskFile()) {

        <div class="flex flex-col w-full">
            {{positionAdjustment().maskFileName}}
            <div
                class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                <div class="h-96 w-72 mx-auto">
                    <img class="h-full w-full object-contain transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                        [src]="positionAdjustment().maskUrl" alt="Custom mask" />
                </div>
                <div
                    class="absolute inset-0 flex translate-y-[80%] pt-20 overflow-hidden flex-col px-4 transition-all duration-500 group-hover:translate-y-[65%]">
                    <div class="flex flex-row justify-end">
                        <button
                            class="text-sm uppercase font-bold bg-error rounded-lg h-[41px] w-[48px]"
                            (click)="handleDeleteCustomMask()">
                            <span class="flex justify-center ">
                                <ng-icon size="22" name="bootstrapTrash" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    
        }

    }


    @if (2 === selectedTab()) {

    <div class="flex flex-row w-full space-x-2 items-center border-b-[1px] border-b-slate-600 mb-2 pb-2">
        <div class="w-1/2 group relative">
            <div class="w-1/2">Clipping zone</div>
            <span class="invisible group-hover:visible group-hover:opacity-100 transition-opacity bg-gray-800 px-2 border-[1px] border-white whitespace-nowrap text-sm text-gray-100 rounded-md absolute 
                            opacity-0">
                0 - 255 to prevent clipping other background Props
            </span>
        </div>
        <input class="w-1/2 text-primary text-center placeholder:text-primary h-10 outline-accent" type="number" min="0"
            max="255" [value]="positionAdjustment().stencilRef"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            (input)="handleStencilRefInput($event, positionAdjustment())">
    </div>

    @for (assignment of clippingPlaneAssignments(); track $index) {

    <app-clipping-plane-assignment [ClippingPlaneAssignment]="assignment"
        (SelectClippingPlaneAssignment)="onSelectClippingPlaneAssignment($event)"
        (DeleteClippingPlaneAssignment)="onDeleteClippingPlaneAssignment($event)"
        (ClippingPlaneAssignmentChange)="handleClippingPlaneAssignmentChange($event)" [Index]="$index + 1"
        (AssignClippingPlane)="onAssignClippingPlane($event)"
        [IsActive]="assignment.clippingPlaneId === selectedClippingPlaneId()" />

    } @empty {

    <div>There are no clipping planes defined</div>

    }

    }

    }

    }
</div>