import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignImage, IDesignImage, IImportableImageSearchResult } from 'projects/my-common/src/model';
import { DesignImageService } from 'src/app/core/service/venue/design-image.service';
import { NGXLogger } from 'ngx-logger';
import { SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { IMPORT_IMAGE_SIDEBAR_ID } from '../import-image-sidebar/import-image-sidebar.component';


@Component({
  selector: 'app-import-design-image-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './import-design-image-card.component.html',
  styleUrls: ['./import-design-image-card.component.scss']
})
export class ImportDesignImageCardComponent {

  readonly DesignImage = input.required<IImportableImageSearchResult>();


  constructor(private designImageService: DesignImageService,
    private readonly sidebarService: SidebarService,
    private logger: NGXLogger) { }

  
  onImport(event: any) {

    this.sidebarService.close(IMPORT_IMAGE_SIDEBAR_ID);
    const designImage = this.DesignImage();
    const importDesignImage = <IDesignImage>{
      eventDesignId: designImage.eventDesignId,
      fileName: designImage.fileName,
      id: designImage.designImageId,
      imageUrl: designImage.imageUrl,
      uploadFileName: designImage.uploadFileName
    }
    this.designImageService.broadcastImportDesignImage(new DesignImage(importDesignImage));
  }
}
