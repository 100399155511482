import { getAdjustmentEidForPositionAndAdjustable, getClippingAssignmentEidForPositionAndPlane } from "../entity/adjustable.entity";

export const AdjustableTransitionInitState = {

    destinationShowroomPositionId: -1,
    transitionDistance: 0
}


/**
 * For the states Showroom destination Position and the given Parent Prop entity Id
 * @param entityIdForParentProp 
 * @returns Adjustment entity Id
 */
export function getAdjustmentEidForDestinationPosition(entityIdForParentProp: number): number {

    return getAdjustmentEidForPositionAndAdjustable(AdjustableTransitionInitState.destinationShowroomPositionId, entityIdForParentProp);
}


/**
 * For the states Showroom destination Position and the given Parent Prop entity Id
 * @param entityIdForClippingPlane 
 * @returns Adjustment entity Id
 */
export function getClippingAssignmentEidForDestinationPosition(entityIdForClippingPlane: number): number {

    return getClippingAssignmentEidForPositionAndPlane(AdjustableTransitionInitState.destinationShowroomPositionId, entityIdForClippingPlane);
}