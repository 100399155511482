/**
 * Javascript only has the Number data type, which is implemented as a floating-point type. 
 * Bit mask values are converted to signed 32-bit values for bitwise operations. 
 * As long as you don't try to use more than 31 bits, you'll be fine.
 */


/**
 * Convert bitwise enum to array form
 * @param enumValue 
 * @returns [1, 2, 4, 8, ...]
 */
export function enumToBitValues(enumValue: object): number[] {
    
    return Object.keys(enumValue).map(Number).filter(Boolean);
}


/**
 * Convert selections to combined mask value
 * @param enumeration The enum
 * @param selection Boolean array representing the selection of each enum value
 * @returns The mask/numeric form of the selected values
 */
export function enumSelectionToCombinedMask(enumeration: object, selection: boolean[]): number {

    const bits = enumToBitValues(enumeration);
    return selection.reduce((total, selected, i) => total + (selected ? bits[i] : 0), 0);
}


/**
 * Convert combined bit mask to selections
 * @param enumeration The enum
 * @param mask The numeric form of combined bit values
 * @returns Boolean array representing the selection of each enum value
 */
export function combinedMaskToEnumSelection(enumeration: object, mask: number): boolean[] {

    const bits = enumToBitValues(enumeration);
    return bits.map((b) => (mask & b) === b);
}


export class MaskHelper {

    constructor(private value: number = 0) {
    }

    public get() {
        
        return this.value;
    }

    public set(value: number): this {

        this.value = value;
        return this;
    }

    public has(key: number): boolean {

        return !!(this.value & key);
    }

    public add(key: number): this {

        this.value |= key;
        return this;
    }

    public delete(key: number): this {

        this.value &= ~key;
        return this;
    }

    public toggle(key: number): this {

        this.has(key) ? this.delete(key) : this.add(key);
        return this;
    }
}