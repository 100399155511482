/**
 * Make sure endpoint CORS allows 'HEAD' method
 * @param url 
 * @returns 
 */
export function urlExists(url: string): Promise<boolean> {
    
// const updatedUrl = url.split("?")[0];
// console.error(`UPDATED URL: ${updatedUrl}`);

    return fetch(url, { method: "HEAD" })
        .then((res) => res.ok)
        .catch(() => false);
}
