/**
 * Cart management via SessionId without login
 */
export interface ICart {
    items: ICartItem[];
    sessionId: string;
}


export interface ICartItem {
    id: string;
    productId: number;
    productName: string;
    unitPrice: number;
    oldUnitPrice: number;
    quantity: number;
    pictureUrl: string;
}


/**
 * Cart checkout creates and Order and clears the Cart
 * Users must be logged in to create an Order
 */
export interface ICartCheckout {

    cartSessionId: string
    invitationGuid: string
    requestId: string
}


/**
 * If cart is successfully converted to Order
 */
export interface ICheckoutResponse {

    orderId: number;
}


/**
 * A Showroom item starts with the Prop which represents the specific, interactive item.
 * If Image Prop then this represents the Prop location in the Venue.
 * If Object Prop then this represents a physical item purchase or rental.
 */
export interface IShowroomCartItem {
    venueId: number;
    venueName: string;
    venueEventId: number;
    venueEventName: string;
    eventDesignId: number;
    eventDesignName: string;
    /**
     * The location to be reserved
     */
    propId: number;
    propName: string;
    itemType: ShowroomItemType;
    /**
     * If graphic selection option
     */
    designId: number;
    /**
     * If graphic selection option
     */
    designFileName: string;
    /**
     * If graphic selection option
     */
    designUrl: string;
    /**
     * If graphic selection option, the specific graphic option pricing information associated with Prop
     */
    assignmentId: number;
    /**  
     * If graphic selection option, the specific description associated with Prop 
     */
    assignmentDescription: string;
    assignmentName: string;
    /**
     * One of the following:
     * Graphic price
     * Object price
     * Rental price
     */
    price: number;
    priceOptionLabel: string;
}


export enum ShowroomItemType {
    /** A specific graphic for an Image Prop or Object Prop */
    Graphic,
    /** Ownership of a physical object */
    Object,
    /** Rent a physical object */
    Rental
}


/**
 * Cart management via SessionId without login
 */
export interface IShowroomCart {

    accountNumber: string;
    items: IShowroomCartItem[];
    sessionId: string;
}
