import { IWorld, createWorld } from 'bitecs'
import { Clock, Object3D } from 'three';
import { adjustableUpdateSystem } from './system/adjustable-update.system';
import { getLogger } from '../util/log';
import { Subject } from 'rxjs/internal/Subject';
import {
    ActiconType, IActiconState, ImagePropEntity, VideoPropEntity, adjustmentInitSystem, removeImageProp, removeVideoProp,
    adjustableTransitionInitSystem, adjustableTransitionSystem, upsertImageProp, upsertVideoProp,
    transitionImageProp
} from '.';
import { ShowroomPositionEntity, addShowroomPosition, getEidForShowroomPosition } from './entity/showroom-position.entity';
import { Vector3Obj } from '../util/utils';
import { WorldState } from './ecs-world.state';
import { PropAdjustment } from '../scene/prop-adjustment';
import { ICameraPose, PropType } from '../model';
import { acticonSystem } from './system/acticon.system';
import { ObjectPropEntity, removeObjectProp, upsertObjectProp } from './entity/object-prop.entity';
import { objectUpdateSystem } from './system/object-update.system';
import { childObjectSystem } from './system/child-object.system';
import { inputActiconState } from './entity/acticon.entity';
import {
    getEidForAdjustableProp, removeAdjustment, removeClippingPlaneAssignment, upsertAdjustmentComponentValues
} from './entity/adjustable.entity';
import { EnvironmentEntity, upsertEnvironment } from './entity/environment.entity';
import { environmentUpdateSystem } from './system/environment-update.system';
import { ClippingPlaneEntity, removeClippingPlane, upsertClippingPlane } from './entity/clipping-plane.entity';
import { transitionAnimationSystem } from './system/transition-animation.system';


/**
 * References to the game objects we are manipulating.
 */
type Object3dMap = { [entityId: number]: Object3D }
export const object3dByEntityId: Object3dMap = {}

/**
 * Find child Object3D entityIds by parentEid.
 * Where possible, parentEid is also stored in the child object's respective component type for indexed array lookup
 * of parent values.
 */
export type ChildObjectEntityIdMap = { [parentEid: number]: number }


/**
 * ECS operations on three Object3D objects
 */
export class EcsWorld {

    private _world!: IWorld;

    private _clock: Clock = new Clock();
    private _running = false;

    private readonly _update = new Subject<number>();
    readonly update$ = this._update.asObservable();

    constructor() {

        this._world = createWorld();

        this.initSystemsState();
        this.start();
    }


    /**
     * Track available Showroom positions that a player can occupy when not transitioning from position to position.
     * @param positionId 
     * @param position 
     * @returns entity representing the relationship between the Showroom position id and the ECS entity id
     */
    addShowroomPosition(positionId: string, position: Vector3Obj): ShowroomPositionEntity {

        return addShowroomPosition(this._world, positionId, position);
    }


    /**
     * Animation loop
     */
    private animate(): void {

        if (!this._running) {

            return;
        }

        this.update(this._clock.getDelta());

        requestAnimationFrame(this.animate.bind(this));
    }


    private initSystemsState() {

        WorldState.logger = getLogger();
    }


    /**
     * Update input state of an Acticon.
     * @param parentPropId 
     * @param parentPropType 
     * @param acticonType 
     * @param acticonState 
     */
    inputActiconState(parentPropId: number, parentPropType: PropType, acticonType: ActiconType, acticonState: IActiconState): void {

        inputActiconState(this._world, parentPropId, parentPropType, acticonType, acticonState);
    }


    /**
     * Update input state
     * @param cameraPose 
     */
    inputCameraPose(cameraPose: ICameraPose) {

        WorldState.cameraPose = cameraPose;
    }


    /**
     * Update input state
     * @param currentPositionId 
     * @param destinationPositionId 
     */
    inputShowroomPositions(currentPositionId: string, destinationPositionId: string) {

        WorldState.currentPositionEntityId = getEidForShowroomPosition(currentPositionId);

        const eidForDestinationPosition = getEidForShowroomPosition(destinationPositionId);
        if (-1 < eidForDestinationPosition) {

            WorldState.destinationPositionEntityId = eidForDestinationPosition;
        } else {

            WorldState.destinationPositionEntityId = WorldState.currentPositionEntityId;
        }
    }


    removeAdjustment(adjustment: PropAdjustment, propId: number, propType: PropType) {

        removeAdjustment(this._world, adjustment, propId, propType);
    }


    removeClippingPlane(clippingPlaneId: number, propType: PropType): void {

        removeClippingPlane(this._world, clippingPlaneId, propType);
    }


    removeClippingPlaneAssignment(playerPositionId: string, clippingPlaneId: number, propType: PropType) {

        removeClippingPlaneAssignment(this._world, playerPositionId, clippingPlaneId, propType);
    }


    removeImageProp(imagePropId: number): void {

        removeImageProp(this._world, imagePropId);
    }


    removeObjectProp(objectPropId: number): void {

        removeObjectProp(this._world, objectPropId);
    }


    removeVideoProp(videoPropId: number): void {

        removeVideoProp(this._world, videoPropId);
    }


    /**
     * ECS start
     */
    start(): void {

        if (this._running) {

            return;
        }

        this._running = true;
        this.animate();
    }


    /**
     * ECS stop
     */
    stop(): void {

        this._running = false;
    }


    transitionImageProp(imagePropId: number): void {

        transitionImageProp(this._world, imagePropId);
    }


    /**
     * Invoke the systems
     * @param delta 
     */
    private update(delta: number): void {

        WorldState.delta = delta;
        environmentUpdateSystem(this._world);
        adjustmentInitSystem(this._world);
        adjustableTransitionInitSystem(this._world);
        adjustableTransitionSystem(this._world);
        adjustableUpdateSystem(this._world);
        acticonSystem(this._world);

        objectUpdateSystem(this._world);
        childObjectSystem(this._world);

        transitionAnimationSystem(this._world);

        this._update.next(delta);

    }


    /**
     * Adjustment entities are indexed by Showroom Position entity id and Adjustable entity id
     * For example: 
     *      showroomPositionEntityId 1 might refer to position Id 'a1f4ghhi...'
     *      entityId 80 might refer to Image Prop Id 2034
     *      adjustmentEntityMap[1][80] returns the adjustmentEntityId for the respective AdjustmentComponent properties for that ImageProp at that position
     * @param adjustment 
     * @param propId 
     * @param propType 
     */
    upsertAdjustment(adjustment: PropAdjustment, propId: number, propType: PropType) {

        //WorldState.logger.error('Getting eid for Showroom Position');
        const eidForShowroomPosition = getEidForShowroomPosition(adjustment.playerPositionId);
        //WorldState.logger.error('Getting eid for Adjustable Image Prop');
        const eidForAdjustableEntity = getEidForAdjustableProp(this._world, propId, propType);

        //WorldState.logger.error('Upsert Adjustment');
        upsertAdjustmentComponentValues(this._world, adjustment, eidForShowroomPosition, eidForAdjustableEntity);
    }


    /**
     * Register and update Clipping Plane objects in the ecs system.
     * @param clippingPlaneEntity 
     */
    upsertClippingPlane(clippingPlaneEntity: ClippingPlaneEntity, propType: PropType) {

        upsertClippingPlane(this._world, clippingPlaneEntity, propType);
    }


    upsertEnvironment(environmentEntity: EnvironmentEntity) {

        upsertEnvironment(this._world, environmentEntity);
    }


    /**
     * Register and update Image Prop objects in the ecs system.
     * @param imagePropEntity 
     */
    upsertImageProp(imagePropEntity: ImagePropEntity) {

        upsertImageProp(this._world, imagePropEntity);
    }


    /**
     * Register and update Object Prop objects in the ecs system.
     * @param objectPropEntity 
     */
    upsertObjectProp(objectPropEntity: ObjectPropEntity): void {

        upsertObjectProp(this._world, objectPropEntity);
    }


    /**
     * Register and update Video Prop objects in the ecs system.
     * @param videoPropEntity 
     */
    upsertVideoProp(videoPropEntity: VideoPropEntity) {

        upsertVideoProp(this._world, videoPropEntity);
    }

}