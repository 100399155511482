import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnDestroy, QueryList, ViewChildren, computed, effect, input, signal } from '@angular/core';
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapBagPlus, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { DesignObject, IMaterialBase, IPriceOption, ObjectAssignment, ObjectProp } from 'projects/my-common/src/model';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { MaterialTexture, TextureManager, isChildElement } from 'projects/my-common/src';
import { IShowroomCartItem, ShowroomItemType } from 'src/app/core/model/cart.model';
import { CommonModule } from '@angular/common';
import { IVenueService } from 'src/app/core/service/venue/interface/IVenueService';
import { CartService } from 'src/app/core/service/cart/cart.service';
import { GuestService } from 'src/app/core/service/venue/guest.service';
import * as THREE from 'three';

export const OBJECT_PROP_OPTIONS_SIDEBAR_ID = '83c63456-8adc-41be-80c3-01bb6d605ab8';

type CustomMaterial = {

  modelViewerMaterial: MaterialTexture,
  customMaterial?: IMaterialBase
}

/**
 * Unlike Image Prop, we don't call getGlb from the Object Prop Node because of size limits on URL.createObjectUrl(blob)
 * Object Prop gltf files can get too big. 
 */
@Component({
  selector: 'app-object-prop-options-sidebar',
  standalone: true,
  templateUrl: './object-prop-options-sidebar.component.html',
  styleUrl: './object-prop-options-sidebar.component.scss',
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons({ bootstrapCart3, bootstrapChevronRight, bootstrapBagPlus, bootstrapTrash3, bootstrapXLg })],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ObjectPropOptionsSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  sidebarId: string = OBJECT_PROP_OPTIONS_SIDEBAR_ID;
  id = crypto.randomUUID();
  private _cancelCloseOnBlur = false;
  private _lock = 0;
  private _modelViewer: any;
  private _modelViewerModel: any;
  private _textureManager = new TextureManager(THREE);

  readonly isOpen = signal(false);
  readonly closing = signal(false);
  readonly canActivateAR = signal(false);
  readonly loaded = signal(false);
  readonly loading = signal(false);
  readonly selectedPriceOption = signal(<IPriceOption>{ price: 0 });

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('objectPropOptionsSidebarComponent') elementRef!: QueryList<HTMLInputElement>;

  readonly designObject = computed(() => this.VenueService().designObjects()
    .find(_do => _do.id === this.objectAssignment().designObjectId) ??
    this.VenueService().defaultDesignObjects()
    .find(_do => _do.id === this.objectAssignment().designObjectId) ?? new DesignObject());

  readonly objectAssignment = signal(new ObjectAssignment());
  @Input() set ObjectAssignment(value: ObjectAssignment) {

    if (this._handlingBlur) {

      this.cancelCloseOnBlur();
    }
    // If the assignment has changed then we need to verify materials.
    // This typically occurs after the object has loaded in model viewer.
    const modelAlreadyLoaded = value.designObjectId === this.objectAssignment().designObjectId;
    if (value.id !== this.objectAssignment().id) {

      this.loading.set(true);
      this.loaded.set(false);
    }
    this.objectAssignment.set(value);
    // If the model-viewer object is already loaded then verify the materials now.
    if (modelAlreadyLoaded) {

      this.applyMaterials();
    }
    if (0 < value.priceOptions.length) {

      this.selectedPriceOption.set(value.priceOptions[0]);
    }
  }
  readonly VenueService = input.required<IVenueService>();
  //
  // End inputs
  //

  //
  // Selectors
  //
  readonly approvedOpen = computed(() => this.isOpen() && 0 < this.objectAssignment().id);
  private _customMaterials: CustomMaterial[] = [];
  /**
   * Template needs to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.approvedOpen()) {

      if (this.elementRef && 0 < this.elementRef.length) {

        (this.elementRef.first as any).nativeElement.focus();
      }
      return true;
    }
    return false;
  });
  readonly objectProp = computed(() => this.VenueService().objectProps()
    .find(op => op.id === this.objectAssignment().objectPropId) ?? new ObjectProp());
  //
  // End selectors
  //


  constructor(private readonly cartService: CartService,
    readonly guestService: GuestService,
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly sidebarService: SidebarService) {

    sidebarService.add(this);
  }


  private async applyMaterials(): Promise<void> {

    // First restore original materials
    for (const customMaterial of this._customMaterials) {

      const modelViewerMaterial = this._modelViewer.model.getMaterialByName(customMaterial.modelViewerMaterial.name);

      modelViewerMaterial.pbrMetallicRoughness['baseColorTexture'].setTexture(customMaterial.modelViewerMaterial.map);
      modelViewerMaterial.pbrMetallicRoughness.setAlphaMap(null);
      modelViewerMaterial.setAlphaMode('OPAQUE');
    }
    // Replace with custom material if defined
    for (const customMaterial of this._customMaterials) {

      if (customMaterial.customMaterial) {

        await this._textureManager.applyMaterial(this._modelViewer, customMaterial.customMaterial);
      }
    }

    setTimeout(() => {

      this.loaded.set(true);
      this.loading.set(false);
    }, 500);
  }


  /**
   * If weare receiving content from an new Image Prop then prevent auto close on blur.
   */
  private cancelCloseOnBlur(): void {

    this._cancelCloseOnBlur = true;
  }


  handleClose() {

    this.loaded.set(false);
    setTimeout(() => {

      this.loaded.set(false);
      this.isOpen.set(false);
      this.closing.set(false);
    }, 200);
  }


  // Matterport consumes all available CPU when running.
  // AR (which only works on mobile) is very glitchy as a result.
  // This method spawns and redirects to a new browser tab for the AR experience.
  // Doing so takes advantage of built in browser pausing of background tabs which frees up processor for the AR experience.
  // Closing the AR experience returns them to Showroom right back where they were.
  launchAR() {

    const url = this.router.serializeUrl(this.router.createUrlTree(['/launchAR']));
    window.localStorage.setItem("objectUrl", this.designObject().objectUrl);
    window.localStorage.setItem("placement", "floor");
    //window.localStorage.setItem("objectRenderScale", `${this.designObject().scale}`);
    window.open(url, "_blank");

    // This doesn't pause matterport
    // const dialog = document.querySelector("dialog");
    // (dialog as any).showModal();
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  onAddToCart() {

    const cartItem: IShowroomCartItem = {
      venueId: this.VenueService().venue().id,
      venueName: this.VenueService().venue().name,
      venueEventId: this.VenueService().currentVenueEvent().id,
      venueEventName: this.VenueService().currentVenueEvent().name,
      eventDesignId: this.VenueService().currentEventDesign().id,
      eventDesignName: this.VenueService().currentEventDesign().name,
      designFileName: this.designObject().uploadFileName,
      designId: this.designObject().id,
      designUrl: this.designObject().objectUrl,
      assignmentName: this.objectAssignment().name,
      assignmentDescription: this.objectAssignment().description,
      assignmentId: this.objectAssignment().id,
      itemType: ShowroomItemType.Object,
      price: this.selectedPriceOption().price,
      propName: 0 < this.objectProp().name.length ? this.objectProp().name : this.objectAssignment().name,
      propId: this.objectAssignment().objectPropId,
      priceOptionLabel: this.selectedPriceOption().label
    }

    this.cartService.addShowroomCartItem(cartItem);

    this.handleClose();
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.onBlur.bind(this);
  onBlur() {

    if (0 < this._lock++) {

      return;
    }
    this._handlingBlur = true;

    const elementRef = (this.elementRef.first as any).nativeElement;
    if (!elementRef) {

      this._handlingBlur = false;
      this._lock = 0;
      return;
    }

    this._cancelCloseOnBlur = false;
    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {

      if (!isChildElement(elementRef, document.activeElement)) {

        //this.logger.error('Blur not child - setting loaded to false');
        this.loaded.set(false);
      } else {

        //this.logger.error('Blur is child so ignore');
      }
    });
    setTimeout(() => {

      if (!isChildElement(elementRef, document.activeElement)) {

        //this.logger.error('Active window is not child - slideout should close');
        if (this._cancelCloseOnBlur) {

          //this.logger.error('Cancel close on blur is true');
          this._cancelCloseOnBlur = false;
          (this.elementRef.first as any)?.nativeElement.focus();
        } else {

          //this.logger.error('Closing sidebar');
          this.handleClose();
        }
      } else {

        //this.logger.error('Active window is child - ignore blur');
      }
      this._handlingBlur = false;
      this._lock = 0;
    }, 500);
  }


  async onModelViewerLoaded(event: any): Promise<void> {

    this._modelViewer = document.getElementById(this.id);
    if (!this._modelViewer) {

      this.logger.error(`${this.onModelViewerLoaded.name} - model-viewer not found`)
      return;
    }

    this._modelViewerModel = this._modelViewer.model;
    if (!this._modelViewerModel || 1 > this._modelViewerModel.materials) {

      this.logger.warn(`${this.onModelViewerLoaded.name} - model-viewer.model not found or has no materials`, this._modelViewerModel);
      return;
    }

    const modelViewerTextures: MaterialTexture[] = []
    for (const modelViewerMaterial of this._modelViewerModel.materials) {

      // AlphaMap on source object material almost never happens. 
      // If the use case surfaces then modify model-viewer to expose access alphaMap to store and reapply alphaMap in the same manner as map.
      modelViewerTextures.push(<MaterialTexture>{
        alphaMap: (modelViewerMaterial as any).alphaMap,
        map: modelViewerMaterial.pbrMetallicRoughness['baseColorTexture'].texture ?? null,
        name: modelViewerMaterial.name
      })
    }
    /**
     * Design Objects with multiple assignments are not reloaded in the model-viewer because the src doesn't change
     * We track original materials/textures to we can restore and apply changes to them based upon assignment.
     */
    this._customMaterials = modelViewerTextures.map(omt =>
      <CustomMaterial>{
        modelViewerMaterial: omt,
        customMaterial: this.objectAssignment().materials.find(dom => dom.name === omt.name) ??
          this.designObject().materials.find(dom => dom.name === omt.name)
      });

    this.applyMaterials();

    const canActivateAR = this._modelViewer.canActivateAR;

    this.logger.trace(`Can activate AR: ${canActivateAR}`);
    this.canActivateAR.set(canActivateAR);
  }


  onPriceOptionChange(priceOptionSelectEvent: any) {

    let selectedIndex = Number(priceOptionSelectEvent.target['options'].selectedIndex);
    this.selectedPriceOption.set(this.objectAssignment().priceOptions[selectedIndex]);
  }


  onProgress(event: any) {

    this.logger.error(event);
  }


}
