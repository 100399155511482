import { Size } from 'projects/my-common/src';
import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { Texture, WebGLRenderTarget, WebGLRenderer } from 'three';

export interface IPainter3d {
  paint(renderer: WebGLRenderer): void;
}

type Inputs = {
  painter: IPainter3d | null;
  textureRes: Size;
};

type Outputs = {
  texture: Texture | null;
} & ComponentOutput;

class SceneRenderer extends SceneComponent {
  private renderer: WebGLRenderer | null = null;
  private renderTarget: WebGLRenderTarget | null = null;

  override inputs: Inputs = {
    painter: null,
    textureRes: { w: 512, h: 512 },
  }

  override outputs = {
    texture: null,
  } as Outputs;


  override onInit() {
    const THREE = this.context.three;
    this.renderer = this.context.renderer;

    // create three.js objects to render
    this.renderTarget = new THREE.WebGLRenderTarget(this.inputs.textureRes.w, this.inputs.textureRes.h);

    this.outputs.texture = this.renderTarget.texture;
  }


  override onInputsUpdated(oldInputs: Inputs) {
    if (this.inputs.painter) {
      const prevTarget = this.renderer?.getRenderTarget();
      this.renderer?.setRenderTarget(this.renderTarget);
      if (this.renderer) {
        this.inputs.painter.paint(this.renderer);
      }
      if (prevTarget) {
        this.renderer?.setRenderTarget(prevTarget);
      }
    }
  }


  override onDestroy() {
    this.outputs.texture = null;
    this.renderTarget?.dispose();
  }


}

export interface ISceneRenderer extends SceneComponent {
  inputs: Inputs;
  outputs: Outputs;
}

export const sceneRendererType = 'mp.sceneRenderer';

export function makeSceneRenderer() {
  return new SceneRenderer();
}
