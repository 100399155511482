import { AfterViewInit, Component, OnDestroy, QueryList, ViewChildren, computed, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VenueEventSelectComponent } from "../../venue-event/venue-event-select/venue-event-select.component";
import { EventDesignSelectComponent } from "../../event-design/event-design-select/event-design-select.component";
import { VenueService } from 'src/app/core/service/venue/venue.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NGXLogger } from 'ngx-logger';
import { InteractionModeSelectComponent } from "../../shared/interaction-mode-select/interaction-mode-select.component";
import { isChildElement } from 'projects/my-common/src/util/utils';
import { IMAGE_PROPS_SIDEBAR_ID } from '../../shared/image-props-sidebar/image-props-sidebar.component';
import { OBJECT_PROPS_SIDEBAR_ID } from '../../shared/object-props-sidebar/object-props-sidebar.component';
import { VIDEO_PROPS_SIDEBAR_ID } from '../../shared/video-props-sidebar/video-props-sidebar.component';
import { POSITIONS_SIDEBAR_ID } from '../../shared/positions-sidebar/positions-sidebar.component';
import { ShowroomMode } from 'src/app/state/showroom-state';
import { ShowroomService } from 'src/app/core/service/showroom/showroom.service';


export const SHOWROOM_CLIENT_OPTIONS_SIDEBAR_ID = '6565950f-c4ab-4e2e-babb-33ca4f7a3e71';

/**
 * To maintain a clear separation of concerns and User Experience, the Admins, Subscribers and Guests will have separate sidebars
 */
@Component({
  selector: 'app-showroom-client-options-sidebar',
  standalone: true,
  providers: [provideIcons({ bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
  templateUrl: './showroom-client-options-sidebar.component.html',
  styleUrls: ['./showroom-client-options-sidebar.component.scss'],
  imports: [CommonModule, VenueEventSelectComponent, EventDesignSelectComponent, NgIconComponent, InteractionModeSelectComponent]
})
export class ShowroomClientOptionsSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  sidebarId: string = SHOWROOM_CLIENT_OPTIONS_SIDEBAR_ID;

  private _processing = false;
  readonly isOpen = signal(false);
  readonly closeClicked = signal(false);

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('showroomClientOptionsSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  /**
   * Template need to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.isOpen()) {

      if (this.elementRef && 0 < this.elementRef.length) {

        (this.elementRef.first as any).nativeElement.focus();
      }
      return true;
    }
    return false;
  });

  readonly assignedObjectPropCount = this.venueService.assignedObjectPropCount;
  readonly configActionsEnabled = computed(() => ShowroomMode.CONFIGURATION === this.currentState().instance.showroomMode);
  readonly currentEventDesign = this.venueService.currentEventDesign;
  readonly currentState = this.showroomService.state;
  readonly currentVenueEvent = this.venueService.currentVenueEvent;
  readonly eventDesigns = this.venueService.eventDesigns;
  readonly objectPropCount = this.venueService.objectPropCount;
  readonly venueEvents = this.venueService.venueEvents;
  readonly venue = this.venueService.venue;

  readonly AddObjectProp = output<boolean>();
  readonly CreateImageProp = output<boolean>();
  readonly OnEventDesignSelect = output<number>();
  readonly OnVenueEventSelect = output<number>();


  constructor(private readonly logger: NGXLogger,
    private readonly showroomService: ShowroomService,
    private readonly sidebarService: SidebarService,
    private readonly venueService: VenueService) {

    sidebarService.add(this);
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.handleBlur.bind(this);
  handleBlur() {
    if (this._handlingBlur) return;
    this._handlingBlur = true;

    const elementRef = (this.elementRef.first as any).nativeElement;
    if (!elementRef) {
      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {
      if (!isChildElement(elementRef, document.activeElement)) {
        this.isOpen.set(false);
      }
      this._handlingBlur = false;
    }, 1);
  }


  handleClose() {
    this.closeClicked.set(true);
    setTimeout(() => {
      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 200);
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  /**
   * Add an Object Prop from the pool of unused licensed props onto the stage
   */
  onAddObjectProp() {

    if (this._processing || this.assignedObjectPropCount() >= this.objectPropCount()) {

      return;
    }
    this._processing = true;
    this.AddObjectProp.emit(true);
    this._processing = false;
  }



  onListImageProps() {

    this.sidebarService.open(IMAGE_PROPS_SIDEBAR_ID);
  }


  onListObjectProps() {

    this.sidebarService.open(OBJECT_PROPS_SIDEBAR_ID);
  }


  onListShowroomPositions() {

    this.sidebarService.open(POSITIONS_SIDEBAR_ID);
  }


  onListVideoProps() {

    this.sidebarService.open(VIDEO_PROPS_SIDEBAR_ID);
  }


  selectEventDesign(eventDesignId: number) {

    this.logger.trace(`id: ${eventDesignId}`);
    const eventDesigns = this.eventDesigns();
    const selectedEventDesign = eventDesigns.find(ed => ed.id == eventDesignId);

    if (selectedEventDesign) {

      this.logger.trace(`broadcasting id: ${eventDesignId}`, selectedEventDesign);
      this.OnEventDesignSelect.emit(eventDesignId);
    } else {

      this.logger.error(`EventDesign id: ${eventDesignId} not found in eventDesigns`, eventDesigns);
    }
  }


  selectVenueEvent(venueEventId: number) {

    this.logger.trace(`id: ${venueEventId}`);
    const venueEvents = this.venue().venueEvents;
    const selectedVenueEvent = venueEvents.find(ve => ve.id == venueEventId);

    if (selectedVenueEvent) {

      this.logger.trace(`emit venueEventId: ${venueEventId}`);
      this.OnVenueEventSelect.emit(venueEventId);
    }
  }


}
