import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { deepCopy } from "projects/mp-core/src/lib/util";
import { Subject, Subscription } from "rxjs";
import { getLogger } from "../util/log";
import { ActiconComponent, ActiconDefaultOptions, ActiconPosition, ActiconType } from "./acticon.component";
import { GltfManager } from "../my-three/gltf-manager";
import { Object3D, Object3DEventMap, Vector3 } from "three";
import { Size, ZERO } from "../my-three/utils";
import { MaterialDataLoaderComponent } from "./material-data-loader.component";

export type VideoControlsState = {
    /**
     * Spacing between Acticons when multiple are active.
     */
    acticonSpacing: number
    alignment: ActiconPosition
    autoPlay: boolean
    autoPlayDistance: number
    /**
     * Reference for positioning Acticon.
     */
    bounds: Size
    interactionDistance: number
    loop: boolean
    margin: number
    playerPosition: Vector3
    playerState: PlayerState
    position?: Vector3
    urls: string[]
    z: number
}

export enum PlayerState {
    /**
     * The initial state.
     * The video/controls have not been interacted with. 
     * Snapshot placeholder likely being displayed.
     */
    Idle,
    Finished,
    Playing,
    Paused
}


/**
 * Facilitate the arrangement and interactions with Acticons supporting a VideoProp.
 */
export class VideoControlsComponent extends MyOptyxComponent {

    private _urlIndex = -1;
    private readonly _subscriptions: Subscription[] = [];
    private _enable = false;
    private _enableNext = false;
    private _logger = getLogger();

    protected override state: VideoControlsState = {
        acticonSpacing: .1,
        alignment: ActiconDefaultOptions.alignment,
        autoPlay: false,
        autoPlayDistance: 10,
        bounds: { w: 1, h: 1 },
        interactionDistance: ActiconDefaultOptions.interactionDistance,
        loop: false,
        margin: ActiconDefaultOptions.margin,
        playerPosition: new Vector3(),
        playerState: PlayerState.Idle,
        position: undefined,
        urls: [],
        z: ActiconDefaultOptions.z,
    };

    // Pending state initialized to match current state.
    override pendingState: VideoControlsState = {
        acticonSpacing: .1,
        alignment: ActiconDefaultOptions.alignment,
        autoPlay: false,
        autoPlayDistance: 10,
        bounds: { w: 1, h: 1 },
        interactionDistance: ActiconDefaultOptions.interactionDistance,
        loop: false,
        margin: ActiconDefaultOptions.margin,
        playerPosition: new Vector3(),
        playerState: PlayerState.Idle,
        position: undefined,
        urls: [],
        z: ActiconDefaultOptions.z,
    };

    // Nested components  
    private infoActicon!: ActiconComponent;
    private nextActicon!: ActiconComponent;
    private pauseActicon!: ActiconComponent;
    private playActicon!: ActiconComponent;
    private _awaitingPlayState = false;
    private _userInteraction = false;


    // Events
    private readonly _infoGltfUpdatedSource = new Subject<Object3D<Object3DEventMap> | undefined>();
    readonly infoGltfUpdated$ = this._infoGltfUpdatedSource.asObservable();
    private readonly _infoSizeUpdatedSource = new Subject<object>();
    readonly infoSizeUpdated$ = this._infoSizeUpdatedSource.asObservable();
    private readonly _playPressedSource = new Subject<string>();
    readonly playPressed$ = this._playPressedSource.asObservable();
    private readonly _pausePressedSource = new Subject<boolean>();
    readonly pausePressed$ = this._pausePressedSource.asObservable();
    private readonly _nextGltfUpdatedSource = new Subject<Object3D<Object3DEventMap> | undefined>();
    readonly nextGltfUpdated$ = this._nextGltfUpdatedSource.asObservable();
    /**
     * Called before object is added to group.
     */
    private readonly _nextSizeUpdatedSource = new Subject<object>();
    readonly nextSizeUpdated$ = this._nextSizeUpdatedSource.asObservable();
    private readonly _pauseGltfUpdatedSource = new Subject<Object3D<Object3DEventMap> | undefined>();
    readonly pauseGltfUpdated$ = this._pauseGltfUpdatedSource.asObservable();
    private readonly _pauseSizeUpdatedSource = new Subject<object>();
    /**
     * Called before object is added to group.
     */
    readonly pauseSizeUpdated$ = this._pauseSizeUpdatedSource.asObservable();
    private readonly _playGltfUpdatedSource = new Subject<Object3D<Object3DEventMap> | undefined>();
    readonly playGltfUpdated$ = this._playGltfUpdatedSource.asObservable();
    private readonly _playSizeUpdatedSource = new Subject<object>();
    /**
     * Called before object is added to group.
     */
    readonly playSizeUpdated$ = this._playSizeUpdatedSource.asObservable();
    private readonly _playerStateChangedSource = new Subject<PlayerState>();
    readonly playerStateChanged$ = this._playerStateChangedSource.asObservable();


    constructor(destroyRef: DestroyRef,
        private readonly gltfManager: GltfManager,
        private readonly materialDataLoader: MaterialDataLoaderComponent) {
        super(destroyRef);

        window.addEventListener(`initialInteraction`, this.initialInteractionCallback);
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {

        // If urls were updated then reset.
        if (this.pendingStateChanges.find(psc => psc === 'urls')) {

            this._pausePressedSource.next(true);
            //this._playPressedSource.next('');
            this.pendingState.playerState = PlayerState.Idle;
            this._enable = 0 < this.pendingState.urls.length;
            this._enableNext = 1 < this.pendingState.urls.length;
            this.state.urls = this.pendingState.urls;
            this._urlIndex = -1;
            //this.updateActiconState(this.state);
        }

        // If we were awaiting and have received confirmation of play state the turn off awaiting flag.
        if (this._awaitingPlayState && this.pendingState.playerState === PlayerState.Playing) {

            this._awaitingPlayState = false;
        }

        // If auto-play was turned off and we're playing then stop playing.
        if (this.state.autoPlay !== this.pendingState.autoPlay
            && !this.pendingState.autoPlay
            && this.state.playerState === PlayerState.Playing) {

            this.handlePausePressed(true);
        }


        if (this.pendingState.autoPlay) {

            this.evaluateAutoPlay(this.pendingState);
        } else if (this.pendingState.loop
            && this.state.playerState === PlayerState.Playing               // was playing
            && this.pendingState.playerState === PlayerState.Finished) {    // but now finished

            this.handlePlayPressed();
        } else if (this.pendingState.playerState === PlayerState.Finished) {

            this.pendingState.playerState = PlayerState.Paused;
        }

        this.updateActicons();

        // Make sure Acticons are in the right state before applying updates.
        if (this.state.playerState !== this.pendingState.playerState) {

            this._playerStateChangedSource.next(this.pendingState.playerState);
        }
    }


    /**
     * Basic volume management by distance from video source
     */
    private evaluateAutoPlay(state: VideoControlsState) {

        if (!state.autoPlay || this._userInteraction || this._awaitingPlayState) {

            return;
        }
        // if (state.playerPosition
        //     && state.position) {
            
        //     this._logger.error(`playerDistance: ${state.position.distanceTo(state.playerPosition)}, autoPlayDistance: ${state.autoPlayDistance}`);
        //     }

        if (state.playerState !== PlayerState.Playing
            && state.playerPosition
            && state.position
            && !state.playerPosition.equals(ZERO)
            && state.position.distanceTo(state.playerPosition) < state.autoPlayDistance) {

            this.handlePlayPressed();
        }
    }


    private readonly initialInteractionCallback = this.onInitialInteraction.bind(this);
    private onInitialInteraction() {

        window.removeEventListener(`initialInteraction`, this.initialInteractionCallback);
        this.evaluateAutoPlay(this.pendingState);
    }


    getInfoActicon(): ActiconComponent {

        return this.infoActicon;
    }


    getNextActicon(): ActiconComponent {

        return this.nextActicon;
    }


    getPauseActicon(): ActiconComponent {

        return this.pauseActicon;
    }


    getPlayActicon(): ActiconComponent {

        return this.playActicon;
    }


    override getState(): VideoControlsState {

        return deepCopy(this.state)
    }


    private handleNextPressed(): void {

        this.next();
        this.handlePlayPressed();
    }


    private handlePlayPressed(): void {

        this.nextActicon.pressed(false);
        this.pauseActicon.pressed(false);
        if (1 > this.state.urls.length) {

            return;
        }
        if (this._awaitingPlayState && this.state.playerState !== PlayerState.Playing) {

            return;
        }
        if (0 > this._urlIndex) {

            this.next();
        }

        this._awaitingPlayState = true;
        this._playPressedSource.next(this.state.urls[this._urlIndex]);
        setTimeout(() => this._awaitingPlayState = false, 1000);
    }


    private handlePausePressed(isPressed: boolean): void {

        this.pauseActicon.pressed(isPressed);
        if (isPressed) {

            this.nextActicon.pressed(false);
            this.playActicon.pressed(false);
        }
        this._pausePressedSource.next(isPressed);
    }


    // If overriding be sure to call base method.
    override init(): VideoControlsComponent {
        super.init();

        this.infoActicon = new ActiconComponent(this.destroyRef, this.gltfManager, this.materialDataLoader, ActiconType.Info).init();
        this._subscriptions.push(
            this.infoActicon.gltfUpdated$.subscribe((gltf) => {
                
                this._infoGltfUpdatedSource.next(gltf);
            })
        );
        this._subscriptions.push(this.infoActicon.sizeUpdated$.subscribe((size) => this._infoSizeUpdatedSource.next(size)));

        this.nextActicon = new ActiconComponent(this.destroyRef, this.gltfManager, this.materialDataLoader, ActiconType.Next).init();
        this._subscriptions.push(
            this.nextActicon.gltfUpdated$.subscribe((gltf) => {
                
                this._nextGltfUpdatedSource.next(gltf);
            })
        );
        this._subscriptions.push(this.nextActicon.sizeUpdated$.subscribe((size) => this._nextSizeUpdatedSource.next(size)));

        this.pauseActicon = new ActiconComponent(this.destroyRef, this.gltfManager, this.materialDataLoader, ActiconType.Pause).init();
        this._subscriptions.push(
            this.pauseActicon.gltfUpdated$.subscribe((gltf) => {
                
                this._pauseGltfUpdatedSource.next(gltf);
            })
        );
        this._subscriptions.push(this.pauseActicon.sizeUpdated$.subscribe((size) => this._pauseSizeUpdatedSource.next(size)));

        this.playActicon = new ActiconComponent(this.destroyRef, this.gltfManager, this.materialDataLoader, ActiconType.Play).init();
        this._subscriptions.push(
            this.playActicon.gltfUpdated$.subscribe((gltf) => {
                
                this._playGltfUpdatedSource.next(gltf);
            })
        );
        this._subscriptions.push(this.playActicon.sizeUpdated$.subscribe((size) => this._playSizeUpdatedSource.next(size)));

        this.updateActicons();
        return this;
    }


    private isAutoPlaying(state: VideoControlsState): boolean {

        return PlayerState.Playing === state.playerState && !this._userInteraction;
    }


    nextHover(isHovering: boolean): void {

        this.nextActicon.hover(isHovering);
    }


    nextPressed(isPressed: boolean): void {

        this._userInteraction = true;
        this.nextActicon.pressed(isPressed);
        if (isPressed) {

            this.pauseActicon.pressed(false);
            this.playActicon.pressed(false);
            this.handleNextPressed();
        }
    }


    pauseHover(isHovering: boolean): void {

        this.pauseActicon.hover(isHovering);
    }


    pausePressed(isPressed: boolean): void {

        this._userInteraction = true;
        this.handlePausePressed(isPressed);
    }


    playHover(isHovering: boolean): void {

        this.playActicon.hover(isHovering);
    }


    playPressed(isPressed: boolean): void {

        this._userInteraction = true;
        this.playActicon.pressed(isPressed);
        if (isPressed) {

            this.handlePlayPressed();
        }
    }


    /**
     * Acticons position themselves around their target bounds but do not adjust their postion or order
     * in relation to oher Acticons which might be next to them.
     * @param state 
     */
    private updateActicons() {

        // Prevent this update from calling itself by raising events which call updateActions again with a different state.
        const state = this.pendingState;

        this.nextActicon.pendingState.enable = this._enableNext;
        this.pauseActicon.pendingState.enable = this._enable;
        this.playActicon.pendingState.enable = this._enable;
        this.nextActicon.pendingState.visible = state.playerState !== PlayerState.Idle;
        this.pauseActicon.pendingState.visible = state.playerState === PlayerState.Playing;
        this.playActicon.pendingState.visible = state.playerState !== PlayerState.Playing;

        // 
        // Start by setting Acticon positions to the specified alignment. The will be on top of each other.
        //
        this.nextActicon.pendingState.interactionDistance = this.pauseActicon.pendingState.interactionDistance = this.playActicon.pendingState.interactionDistance = state.interactionDistance;
        this.nextActicon.pendingState.margin = this.pauseActicon.pendingState.margin = this.playActicon.pendingState.margin = state.margin;
        this.nextActicon.pendingState.playerPosition = this.pauseActicon.pendingState.playerPosition = this.playActicon.pendingState.playerPosition = state.playerPosition;
        this.nextActicon.pendingState.position = this.pauseActicon.pendingState.position = this.playActicon.pendingState.position = state.position;
        this.nextActicon.pendingState.z = this.pauseActicon.pendingState.z = this.playActicon.pendingState.z = state.z;

        //
        // If there is no Next Acticon or we are in the Idle stat then only the Play or Pause button will appear.
        //
        if (!this._enableNext || PlayerState.Idle === state.playerState) {

            this.playActicon.pendingState.perpendicularMargin = 0;
            this.pauseActicon.pendingState.perpendicularMargin = 0;

            this.nextActicon.apply();
            this.pauseActicon.apply();
            this.playActicon.apply();
            return;
        }

        //
        // Assuming 2 visible controls max. Adjust their positioning based upon alignment.
        //
        const space = this._enableNext ? state.acticonSpacing : 0;
        const halfSpace = this._enableNext ? space / 2 : 0;
        const sNext = this.nextActicon.size;
        const sPause = this.pauseActicon.size;
        const sPlay = this.playActicon.size;
        const sActive = state.playerState === PlayerState.Playing ? sPause : sPlay;

        switch (state.alignment) {
            case ActiconPosition.TopCenter:
            case ActiconPosition.BottomCenter:
                // Acticon does not apply any additional offset for center aligned objects.
                // Offset equally from a 0 center point by half the desired space plus half of the objects width.
                this.nextActicon.pendingState.perpendicularMargin = halfSpace + sNext.x / 2;
                this.pauseActicon.pendingState.perpendicularMargin = -halfSpace - sPause.x / 2;
                this.playActicon.pendingState.perpendicularMargin = -halfSpace - sPlay.x / 2;
                break;
            case ActiconPosition.TopLeft:
            case ActiconPosition.BottomLeft:
                // Acticon auto adjusts object by half width when aligning left or right.
                // Offset each respectively to the right from a 0 point on the left.
                this.nextActicon.pendingState.perpendicularMargin = sActive.x + space;    // The width of the active 1st Acticon + target spacing (Acticon adjusts by 1/2 width)
                this.pauseActicon.pendingState.perpendicularMargin = 0;   // Acticon adjusts by 1/2 width
                this.playActicon.pendingState.perpendicularMargin = 0;    // Acticon adjusts by 1/2 width
                break;
            case ActiconPosition.TopRight:
            case ActiconPosition.BottomRight:
                // Acticon auto adjusts object by half width when aligning left or right.
                // Offset each respectively to the left from a 0 point on the right.
                this.nextActicon.pendingState.perpendicularMargin = 0;    // Acticon adjusts by 1/2 width
                this.pauseActicon.pendingState.perpendicularMargin = -space - sNext.x;    // The width of the next Acticon + target spacing (Acticon adjusts by 1/2 width)
                this.playActicon.pendingState.perpendicularMargin = -space - sNext.x;     // The width of the next Acticon + target spacing (Acticon adjusts by 1/2 width)    
                break;
            case ActiconPosition.LeftBottom:
            case ActiconPosition.RightBottom:
                this.nextActicon.pendingState.perpendicularMargin = 0;    // Acticon adjusts by 1/2 height
                this.pauseActicon.pendingState.perpendicularMargin = space + sNext.y;
                this.playActicon.pendingState.perpendicularMargin = space + sNext.y;
                break;
            case ActiconPosition.LeftCenter:
            case ActiconPosition.RightCenter:
                this.nextActicon.pendingState.perpendicularMargin = -halfSpace - sNext.y / 2;
                this.pauseActicon.pendingState.perpendicularMargin = halfSpace + sPause.y / 2;
                this.playActicon.pendingState.perpendicularMargin = halfSpace + sPlay.y / 2;
                break;
            case ActiconPosition.LeftTop:
            case ActiconPosition.RightTop:
                this.nextActicon.pendingState.perpendicularMargin = -space - sActive.y;
                this.pauseActicon.pendingState.perpendicularMargin = 0;
                this.playActicon.pendingState.perpendicularMargin = 0;
                break;
        }

        this.nextActicon.apply();
        this.pauseActicon.apply();
        this.playActicon.apply();
    }


    override onDestroy(): void {

        this._subscriptions.forEach(s => s.unsubscribe());
    }


    next(): void {

        if (1 > this.state.urls.length) {

            this._urlIndex = -1;
            return;
        }

        this._urlIndex++;
        if (this._urlIndex >= this.state.urls.length) {

            this._urlIndex = 0;
        }
    }

}