import { getLogger } from 'projects/my-common/src/util/log';
import { SceneComponent, ComponentInteractionType } from '../SceneComponent';

type Inputs = {
  model: string | null;
  size: { x: number; y: number; z: number; };
}

class Slot extends SceneComponent {  
  private readonly _logger = getLogger();
  private model: any = null;
  private box: any = null;

  override inputs: Inputs = {
    model: null,
    size: { x: 1, y: 2, z: 1 },
  };

  override events = {
    [ComponentInteractionType.CLICK]: false,
    [ComponentInteractionType.HOVER]: false,
    [ComponentInteractionType.DRAG]: false
  }


  override onInit() {
    //const root = this.context.root;

    let box = null;
    let model = null;
    // for (const component of root.componentIterator()) {
    //   if (component.componentType === ORIENTED_BOX_COMPONENT_TYPE) {
    //     box = component;
    //   }
    //   else if (component.componentType === 'mp.gltfLoader') {
    //     model = component;
    //   }
    // }

    if (!box || !model) {
      return;
    }

    this.box = box;
    this.model = model;

    this.box.inputs.size = this.inputs.size;
  }


  override onInputsUpdated(oldInputs: Inputs) {
    if (oldInputs.model !== this.inputs.model) {
      //this._logger.trace(`Slot.onInputsUpdated ${this.inputs.model}`)
      this.model.inputs.url = this.inputs.model;
    }

    if (this.box) {
      this.box.inputs.size = this.inputs.size;
    }
  }


}


export const SLOT_COMPONENT_TYPE = 'mp.slot';

export const makeSlot = function() {
  return new Slot();
}
