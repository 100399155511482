import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noComma',
  standalone: true
})
export class NoCommaPipe implements PipeTransform {

  transform(value: string | null): string {

    if (value) {
      
      // Remove commas from the value
      return value.toString().replace(/,/g, '');
    } else {

      return '';
    }
  }

}
