<div #showroomClientOptionsSidebarComponent tabindex="0" [autofocus]="setFocus()" (blur)="handleBlur()" 
    class="fixed h-screen flex-flex-col overflow-hidden top-0 bottom-0 w-full z-50 md:max-w-[500px] bg-[#0e0f10] shadow-xl 
            {{ isOpen() ? 'right-0' : '-right-full' }} transition-all duration-300 bg-opacity-80 border-l-[1px] border-accent">

    <div class="flex flex-row">
        
        <div class=" text-4xl w-16 h-[70px] pl-4 flex justify-start items-center cursor-pointer transition"
            (click)="handleClose()">
            <ng-icon class="text-3xl transition {{ closeClicked() ? 'rotate-[2turn]' : 'rotate-0' }}"
                name="bootstrapXLg" />
        </div>
        <div class="flex items-center text-accent text-3xl font-semibold overflow-hidden">
            Showroom options
        </div>
    
    </div>
    
    <div class="flex flex-col px-6 space-y-2 pb-4">

        <div class="flex items-center text-accent text-2xl font-semibold overflow-hidden">
            {{venue().name}}
        </div>
        <div>
            <div class="text-gray-300 mb-2">Events</div>
            <app-venue-event-select [VenueEvents]="venue().venueEvents" (OnSelect)="selectVenueEvent($event)"
                [DisplayHelp]="false" [SelectedVenueEvent]="currentVenueEvent()" />
        </div>

    </div>

    
    <div class="flex-1 flex overflow-hidden">

        <div class="flex-1 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:[width:15px] [&::-webkit-scrollbar-thumb]:bg-accent">
    
            <div class="flex flex-col px-6 space-y-4 pb-24">          
        
                @if (eventDesigns() && 0 < eventDesigns().length) { 
            
                    <div class="flex flex-col space-y-2">
                        <div class="flex items-center text-accent text-2xl font-semibold overflow-hidden">
                            Designs
                        </div>
                        <app-event-design-select [EventDesigns]="eventDesigns()" [SelectedDesign]="currentEventDesign()"
                            (OnSelect)="selectEventDesign($event)" [DisplayHelp]="false" />
                    </div>

                } @else {

                    <div class="flex items-center text-accent text-2xl font-semibold overflow-hidden">
                        No event designs
                    </div>

                }
        
                <div class="flex items-center text-accent text-2xl font-semibold overflow-hidden">
                    Settings
                </div>
        
                <app-interaction-mode-select />
            
                <div>
                    <div class="flex items-center text-accent text-2xl font-semibold overflow-hidden">
                        Find
                    </div>
        
                    <div class="grid grid-cols-2 w-full gap-2 mt-2">
        
                        @if (venue().imageProps && 0 < venue().imageProps.length) {
                            
                            <button (click)="onListImageProps()"
                                class="btn btn-accent" >
                                Image prop
                            </button>
            
                        }
            
                        @if (venue().objectProps && 0 < venue().objectProps.length) {
                
                            <button (click)="onListObjectProps()" 
                                class="btn btn-accent">
                                Object prop
                            </button>
            
                        }
            
                        @if (venue().videoProps && 0 < venue().videoProps.length) {
                
                            <button (click)="onListVideoProps()" 
                                class="btn btn-accent">
                                Video prop
                            </button>
            
                        }
            
                        <button (click)="onListShowroomPositions()" 
                            class="btn btn-accent">
                            Position
                        </button>
        
                    </div>
                </div>
                
        
                @if (configActionsEnabled()) {
    
                    <div>                
                        <div class="flex items-center text-accent text-2xl font-semibold overflow-hidden">
                            Add
                        </div>
    
                        <div class="flex flex-wrap">

                            <button (click)="onAddObjectProp()" [disabled]="assignedObjectPropCount() >= objectPropCount()"
                                class="btn btn-accent whitespace-nowrap disabled:btn-accent disabled:opacity-70">
                                Object prop ({{assignedObjectPropCount()}}/{{objectPropCount()}})
                            </button>
            
                        </div>
                    </div>
    
                }

        </div>  <!-- End of overflow-y-auto -->
        
    </div> 

</div>
