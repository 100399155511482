import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnDestroy, QueryList, ViewChildren, computed, effect, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapBagPlus, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { DesignImage, ImageAssignment, ImageProp, IPriceOption } from 'projects/my-common/src/model';
import { NGXLogger } from 'ngx-logger';
import { IStage } from 'src/app/core/model/showroom/showroom.model';
import { isChildElement } from 'projects/my-common/src/util/utils';
import { IShowroomCartItem, ShowroomItemType } from 'src/app/core/model/cart.model';
import { IVenueService } from 'src/app/core/service/venue/interface/IVenueService';
import { GuestService } from 'src/app/core/service/venue/guest.service';
import { Subscription } from 'rxjs';
import { TextureManager } from 'projects/my-common/src';
import * as THREE from 'three';
import { CartService } from 'src/app/core/service/cart/cart.service';

export const IMAGE_PROP_OPTIONS_SIDEBAR_ID = 'e954ed13-3707-4fc7-8cc1-f33e75862832';


@Component({
  selector: 'app-image-prop-options-sidebar',
  standalone: true,
  templateUrl: './image-prop-options-sidebar.component.html',
  styleUrl: './image-prop-options-sidebar.component.scss',
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons({ bootstrapBagPlus, bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImagePropOptionsSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  sidebarId: string = IMAGE_PROP_OPTIONS_SIDEBAR_ID;
  id = crypto.randomUUID();
  private _arValidated = false;
  private _cancelCloseOnBlur = false;
  private _textureManager = new TextureManager(THREE);

  readonly isOpen = signal(false);
  readonly closeClicked = signal(false);
  readonly canActivateAR = signal(true);
  readonly loaded = signal(false);
  readonly modelViewerSrc = signal('');
  readonly selectedPriceOption = signal(<IPriceOption>{ price: 0 });

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('imagePropOptionsSidebarComponent') elementRef!: QueryList<HTMLInputElement>;

  //
  // Inputs
  //
  readonly designImage = signal(new DesignImage());
  @Input() set DesignImage(value: DesignImage) {

    if (this._handlingBlur) {

      this.cancelCloseOnBlur();
    }

    this.designImage.set(value);
  }
  readonly imageAssignment = signal(new ImageAssignment());
  @Input({ required: true }) set ImageAssignment(value: ImageAssignment) {

    if (this._handlingBlur) {

      this.cancelCloseOnBlur();
    }
    if (value.id !== this.imageAssignment().id) {

      this.loaded.set(false);
    }

    this.imageAssignment.set(value);
    if (0 < value.priceOptions.length) {

      this.selectedPriceOption.set(value.priceOptions[0]);
    }
    this.monitorActiconPressedEvents();
    this.getGlb();
  }
  readonly Stage = input.required<IStage>()
  readonly VenueService = input.required<IVenueService>();
  //
  // End inputs
  //

  //
  // Selectors
  //
  readonly approvedOpen = computed(() => this.isOpen() && 0 < this.imageAssignment().id);
  readonly imageProp = computed(() => this.VenueService().imageProps()
    .find(ip => ip.id === this.imageAssignment().imagePropId) ?? new ImageProp());
  readonly imageNode = computed(() => this.Stage() && this.Stage().getImageNode ?
    this.Stage().getImageNode(this.imageAssignment().imagePropId) : undefined);
  /**
   * Template needs to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.approvedOpen()) {

      const elementRef = (this.elementRef.first as any).nativeElement;
      elementRef?.focus();

      return true;
    }
    return false;
  });
  //
  // End selectors
  //


  constructor(private readonly cartService: CartService,
    readonly guestService: GuestService,
    private readonly logger: NGXLogger,
    private readonly sidebarService: SidebarService) {

    sidebarService.add(this);
  }


  private async applyAlphaMap(): Promise<void> {

    const alphaMapSource = this.imageNode()?.getAlphaMapSource();
    if (!alphaMapSource || 1 > alphaMapSource.length) {

      return;
    }
    const modelViewer = document.getElementById(this.id) as any;
    if (!modelViewer) {

      this.logger.error(`${this.onModelViewerLoaded.name} - model-viewer not found`)
      return;
    }
    const modelViewerModel = modelViewer.model;
    if (!modelViewerModel || 1 > modelViewerModel.materials) {

      this.logger.warn(`${this.onModelViewerLoaded.name} - model-viewer.model not found or has no materials`, modelViewerModel);
      return;
    }

    this._textureManager.loadTexture(alphaMapSource,
      (alphaMap) => {

        for (const modelViewerMaterial of modelViewerModel.materials) {

          // 'BLEND' or 'MASK' is required to activate transparency on model-viewer's three.js material.
          // 'BLEND' supports greyscale/gradient alpha. 'MASK' + alpha cutoff supports sharp mask cutoff.
          // Transparency is needed to support the setAlphaMap hack in my custom model-viewer implementation.
          modelViewerMaterial.setAlphaMode('MASK');
          modelViewerMaterial.setAlphaCutoff(0.5);
          // Custom model-viewer hack
          modelViewerMaterial.pbrMetallicRoughness.setAlphaMap(alphaMap);
        }
      },
      (err) => this.logger.error(err));
  }


  /**
   * If the Next Acticon is clicked or we are receiving content from an new Image Prop
   * then prevent auto close on blur.
   */
  private cancelCloseOnBlur(): void {

    this._cancelCloseOnBlur = true;
  }


  private disposeModelViewerSource(): void {

    URL.revokeObjectURL(this.modelViewerSrc());   // prevent memory leaks
    this.modelViewerSrc.set('');
  }


  private getGlb(): void {

    if (1 > this.imageAssignment().id) {

      return;
    }

    if (this.imageNode) {

      this.disposeModelViewerSource();

      this.imageNode()?.getGlb((glb) => {

        //const glbBlob = new Blob([ glb ], { type: 'application/octet-stream' })
        const glbBlob = new Blob([glb], { type: 'text/plain' });
        const dataUrl = URL.createObjectURL(glbBlob);

        this.modelViewerSrc.set(dataUrl);
      });
    }
  }


  handleClose() {

    this.closeClicked.set(true);
    setTimeout(() => {

      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 150);
  }


  /** 
   * Unlike objects, Images are more light weight so AR is being launched directly here for them.
   * Besides, image glbs are generated dynamically. The AR viewer was failing when I invoked it on the launchAR page.
   * This discussion https://github.com/google/model-viewer/discussions/3767 gave me the idea to try this approach from a timing perspective.
   * Initially, I was thinking that the AR implementation didn't like the dynamic Url but it works here so timing must be a factor.
   * DOMException: Failed to execute 'requestSession' on 'XRSystem': The requested session requires user activation.
   * I didn't want to require the user to click again but I might have to if peformance is a problem.
   */
  launchAR() {

    const modelViewer = document.getElementById(this.id);
    //(modelViewer as any).scale = `${this._renderScale} ${this._renderScale} ${this._renderScale}`;
    //(modelViewer as any).updateFraming()
    (modelViewer as any).activateAR();

    // const modelViewerTransform = document.querySelector("model-viewer#transform");
    // if (modelViewerTransform) {
    //   (modelViewerTransform as any).orientation = `0deg 0deg 90deg`;
    // }
    // const url = this._router.serializeUrl(this._router.createUrlTree(['/launchAR']));
    // window.open(url);

    // This doesn't pause matterport
    // const dialog = document.querySelector("dialog");
    // (dialog as any).showModal();
  }


  private _acticonPressedSubscription?: Subscription;
  private monitorActiconPressedEvents() {

    if (!this.imageNode()) {

      return;
    }

    this._acticonPressedSubscription?.unsubscribe();
    this._acticonPressedSubscription = this.imageNode()?.acticonPressed$.subscribe((isPressed) => {

      this.cancelCloseOnBlur();
    })
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);
    this.disposeModelViewerSource();

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  onAddToCart() {

    const cartItem: IShowroomCartItem = {
      venueId: 0,
      venueName: '',
      venueEventId: 0,
      venueEventName: '',
      eventDesignId: 0,
      eventDesignName: '',
      designFileName: this.designImage().uploadFileName,
      designId: this.designImage().id,
      designUrl: this.designImage().imageUrl,
      assignmentName: 0 < this.imageAssignment().name.length ? this.imageAssignment().name : 'Unnamed',
      assignmentDescription: this.imageAssignment().description,
      assignmentId: this.imageAssignment().id,
      itemType: ShowroomItemType.Graphic,
      price: this.selectedPriceOption().price,
      propName: 0 < this.imageProp().name.length ? this.imageProp().name : this.imageAssignment().name,
      propId: this.imageAssignment().imagePropId,
      priceOptionLabel: this.selectedPriceOption().label
    }

    // Showroom adds Venue and Venue Event properties.
    //this.AddToCart.emit(cartItem);
    this.cartService.addShowroomCartItem(cartItem);

    this.handleClose();
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.onBlur.bind(this);
  onBlur() {

    if (this._handlingBlur) {

      return;
    }
    this._handlingBlur = true;

    const elementRef = (this.elementRef.first as any).nativeElement;
    if (!elementRef) {

      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    this._cancelCloseOnBlur = false;
    setTimeout(() => {

      if (!this._cancelCloseOnBlur && !isChildElement(elementRef, document.activeElement)) {

        this.isOpen.set(false);
      } 

      this._handlingBlur = false;
    }, 500);
  }


  async onModelViewerLoaded(event: any): Promise<void> {

    await this.applyAlphaMap();
    this.loaded.set(true);
    this.validateAR();
  }


  onPriceOptionChange(priceOptionSelectEvent: any) {

    let selectedIndex = Number(priceOptionSelectEvent.target['options'].selectedIndex);
    this.selectedPriceOption.set(this.imageAssignment().priceOptions[selectedIndex]);
  }


  private validateAR(): void {

    if (this._arValidated) {

      return;
    }

    const modelViewer = document.getElementById(this.id) as any;
    if (!modelViewer) {

      this.canActivateAR.set(false);
      return;
    }

    const canActivateAR = modelViewer.canActivateAR;
    this.logger.trace(`Can activate AR: ${canActivateAR}`);

    this.canActivateAR.set(canActivateAR);
    this._arValidated = true;
  }


}
