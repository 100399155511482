import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChildren, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignVideo, IImportableVideoSearchResult } from 'projects/my-common/src/model';
import { NGXLogger } from 'ngx-logger';
import { DesignVideoService } from 'src/app/core/service/venue/design-video.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapCaretLeft } from '@ng-icons/bootstrap-icons';

@Component({
  selector: 'app-import-design-video-card',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  templateUrl: './import-design-video-card.component.html',
  styleUrls: ['./import-design-video-card.component.scss'],
  providers: [provideIcons({ bootstrapCaretLeft })],
})
export class ImportDesignVideoCardComponent implements AfterViewInit {

  id = crypto.randomUUID();
  readonly importableDesignVideo = signal(<IImportableVideoSearchResult>{});
  readonly designVideo = signal(new DesignVideo());

  @ViewChildren('video') videos!: QueryList<ElementRef>;

  @Input() set DesignVideo(value: IImportableVideoSearchResult) {

    this.importableDesignVideo.set(value);
    this.designVideo.set(new DesignVideo(value));
    this.initializeVideo();
  }


  constructor(private _designVideoService: DesignVideoService,
    private _logger: NGXLogger) { }


  async initializeVideo(): Promise<void> {

    let video = document.getElementById(this.id) as HTMLVideoElement;
    if (!video || 1 > this.designVideo().id) {

      return;
    }

    const that = this;
    // Clicking play does not focus the video element.
    // Clicking play triggers parent auto-close on blur becuse child element is not focused.
    // The following ensures video element is focused as would be expected on clicking play.
    video.addEventListener('play', function () { 

      video.focus();
    });

    this._logger.trace(`Setting video source for: video${this.designVideo().id}`, video, this.designVideo());
    await this.designVideo().setVideoSource(video);
  }


  ngAfterViewInit(): void {

    const video = document.getElementById(this.id);
    if (video) {

      this.initializeVideo();
    }
  }


  onAddToDesign() {

    const importablDesignVideo = this.importableDesignVideo();
    this._designVideoService.broadcastImportDesignVideo(this.designVideo());
  }


}
