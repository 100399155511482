import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClippingPlaneType, IClippingPlane } from 'projects/my-common/src/model';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapCaretRight, bootstrapXLg } from "@ng-icons/bootstrap-icons";
import { Subscription } from 'rxjs/internal/Subscription';
import { NGXLogger } from 'ngx-logger';
import { CommonVector3InputComponent } from '../../shared/common-vector3-input/common-vector3-input.component';
import { CheckboxComponent } from 'src/app/components/shared/checkbox/checkbox.component';
import { MaskConfigurationComponent } from 'src/app/components/shared/mask-configuration/mask-configuration.component';
import { Vector3InputComponent } from 'src/app/components/shared/vector3-input/vector3-input.component';
import { ClippingPlane, CrudState } from 'projects/my-common/src/model';
import { NoCommaPipe } from 'src/app/shared/pipes/no-comma.pipe';
import { ISlider, SliderService } from 'src/app/core/service/ui/slider/slider.service';
import { isChildElement, Vector3Obj } from 'projects/my-common/src';
import { SHOWROOM_COMMON_SLIDER_ID } from '../../shared/common-slider/common-slider.component';

const clippingPlaneTypes = Object.values(ClippingPlaneType).filter(value => typeof value !== 'number');

@Component({
  selector: 'app-clipping-plane',
  standalone: true,
  templateUrl: './clipping-plane.component.html',
  styleUrls: ['./clipping-plane.component.scss'],
  providers: [provideIcons({ bootstrapCaretRight, bootstrapXLg })],
  imports: [CheckboxComponent, CommonModule, CommonVector3InputComponent, NgIconComponent, Vector3InputComponent, MaskConfigurationComponent, NoCommaPipe]
})
export class ClippingPlaneComponent implements AfterViewInit, OnDestroy {

  private _commonSlider?: ISlider;
  private _handlingBlur = false;
  private readonly _subscriptions: Subscription[] = [];
  private _updateInitializers = true;
  // TODO Can this get confused with other "sliderBasedInputs" on the page elsewhere?
  @ViewChildren('sliderBasedInputs') sliderBasedInputs!: QueryList<HTMLInputElement>;

  readonly rotationInitializer = signal(0);

  readonly clippingPlane = signal(new ClippingPlane());
  @Input() set ClippingPlane(value: IClippingPlane) {

    this.clippingPlane.set(new ClippingPlane(value));
    this.setInitializers();
  }

  readonly IsActive = input.required<boolean>();
  readonly Index = input.required<number>();

  readonly ClippingPlaneChange = output<ClippingPlane>();
  readonly DeleteClippingPlane = output<ClippingPlane>();
  readonly SelectClippingPlane = output<number>()

  readonly clippingPlaneTypes = clippingPlaneTypes;


  constructor(private readonly logger: NGXLogger,
    private readonly sliderService: SliderService) { }


  private changeRotation(newRotation: number): void {

    if (this._updateInitializers) {

      this.rotationInitializer.set(newRotation);
    }
    this.clippingPlane.update(cp => {

      cp.rotation = newRotation;
      return cp;
    });

    this.ClippingPlaneChange.emit(this.clippingPlane());
  }


  handleDeleteClippingPlane(clippingPlane: ClippingPlane): void {

    this.DeleteClippingPlane.emit(clippingPlane);
  }


  handlePositionChange(adjustment: Vector3Obj, target: ClippingPlane): void {

    target.position = [adjustment.x, adjustment.y, adjustment.z];
    this.ClippingPlaneChange.emit(target);
  }


  handleRotationInput(rotationInputEvent: any): void {

    const newRotation = Number(rotationInputEvent.target.value);
    this.clippingPlane.update(cp => {

      cp.rotation = newRotation;

      return new ClippingPlane(cp);
    })

    this._commonSlider?.setCurrentValue(newRotation);

    this._updateInitializers = false;
    this.ClippingPlaneChange.emit(this.clippingPlane());
    setTimeout(() => this._updateInitializers = true);
  }


  handleScaleChange(scale: Vector3Obj, target: ClippingPlane): void {

    target.scale = [scale.x, scale.y, scale.z];
    this.ClippingPlaneChange.emit(target);
  }


  ngAfterViewInit(): void {

    this._commonSlider = this.sliderService.get(SHOWROOM_COMMON_SLIDER_ID);
  }


  ngOnDestroy(): void {

    this._subscriptions.forEach(s => s.unsubscribe());
  }


  private readonly handleBlurCallback = this.onBlur.bind(this);
  /**
   * Slider should close when focus changes away from sliderBasedInputs
   * but not when the focus changes to the slider itself. 
   */
  onBlur() {

    if (this._handlingBlur) {

      return;
    }
    this._handlingBlur = true;

    this.setInitializers();

    const commonSlider = (this._commonSlider?.elementRef.first as any).nativeElement;
    const sliderBasedInputs = (this.sliderBasedInputs.first as any).nativeElement;
    if (!commonSlider || !sliderBasedInputs) {

      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {
      if (!isChildElement(commonSlider, document.activeElement) && !isChildElement(sliderBasedInputs, document.activeElement)) {

        this._commonSlider?.isOpen.set(false);
      }
      this._handlingBlur = false;
    }, 1);
  }


  onClippingPlaneTypeChange(event: Event): void {

    const clippingPlaneType: ClippingPlaneType = (event.target as HTMLSelectElement)['options'].selectedIndex;
    this.clippingPlane.update(clippingPlane => {

      if (CrudState.NONE === clippingPlane.crudState) {

        clippingPlane.crudState = CrudState.UPDATED;
      }
      clippingPlane.planeType = clippingPlaneType;

      return clippingPlane;
    });

    this.ClippingPlaneChange.emit(this.clippingPlane());
  }


  onSelect() {

    this.SelectClippingPlane.emit(this.clippingPlane().id)
  }


  /**
   * Triggered by close event initiated by slider.
   */
  private onSliderClose(): void {

    this._commonSlider?.isOpen.set(false);
  }


  onToggleActivateAtPropLevel() {

    this.clippingPlane.update(clippingPlane => {

      if (CrudState.NONE === clippingPlane.crudState) {

        clippingPlane.crudState = CrudState.UPDATED;
      }
      clippingPlane.activatePropLevel = !clippingPlane.activatePropLevel;

      return clippingPlane;
    });

    this.ClippingPlaneChange.emit(this.clippingPlane());
  }


  /**
   * Bind this and Rotation to the Showroom common slider.
   */
  async setFocusRotation(focusEvent: any): Promise<void> {

    // Focus appears to get called when receiving focus and when losing focus.
    setTimeout(async () => {
      // Make sure the focus is on this element.
      if (focusEvent.srcElement !== document.activeElement) {

        return;
      }
      await this._commonSlider?.reset();
      this._commonSlider?.onChange.set(this.changeRotation.bind(this));
      this._commonSlider?.onClose.set(() => this.onSliderClose.bind(this));
      this._commonSlider?.onBlur.set(() => this.handleBlurCallback);
      this._commonSlider?.isOpen.set(true);
      this._commonSlider?.setCurrentValue(this.clippingPlane().rotation);
      this._commonSlider?.label.set('Rotation');
      this._commonSlider?.multiplier.set(.1);
    });
  }


  private setInitializers() {

    if (this._updateInitializers) {

      this.rotationInitializer.set(this.clippingPlane().rotation);
    }
  }


}
