import { Types, defineComponent } from 'bitecs';
import { Vector3Schema } from './shared.component';

/**
 * Adjustable components represent an entity's adjustment values for a given fixed position.
 */
export const AdjustmentComponent = defineComponent({
    hide: Types.i8,
    // horizontalMaskMode: Types.i8,
    // horizontalMaskOffset: Types.f32,
    // horizontalMaskWidth: Types.f32,
    position: Vector3Schema,
    rotation: Vector3Schema,
    scale: Vector3Schema,
    // verticalMaskMode: Types.i8,
    // verticalMaskOffset: Types.f32,
    // verticalMaskHeight: Types.f32    
});