import { Injectable, QueryList, WritableSignal } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * A shared interface for convenience and to conser resources.
 * Make sure you set desired values explicitly or you will inherit vales set by the prior handler with unpredictable results.
 */
export interface ISlider {

  sliderId: string;

  // 
  // Bindables
  //
  isOpen: WritableSignal<boolean>;
  label: WritableSignal<string>;
  onChange: WritableSignal<(value: number) => void>;
  onClose: WritableSignal<() => void>;
  multiplier: WritableSignal<number>;

  /**
   * Join local blur monitoring with Slider to determin hide/show logic.
   */
  elementRef: QueryList<HTMLInputElement>;
  /**
   * Join local blur monitoring with Slider to determin hide/show logic.
   */
  onBlur: WritableSignal<() => void>;


  /**
   * Unbind from thhe common slider and reset its values to default.
   */
  reset(): void;
  setCurrentValue(value: number): void;
}


@Injectable({
  providedIn: 'root'
})
export class SliderService {
  private sliders: ISlider[] = [];

  
  constructor(private _logger: NGXLogger) { }


  add(slider: ISlider) {

    // ensure component has a unique id attribute
    if (!slider.sliderId || this.sliders.find(x => x.sliderId === slider.sliderId)) {
      throw new Error(`Slider id: ${slider.sliderId} must be unique. Slider count: ${this.sliders.length}`);
    }

    // add modal to array of active modals
    this.sliders.push(slider);
    this._logger.trace(`Added slider ${slider.sliderId}`)
  }


  get(id: string): ISlider | undefined {
    
    return this.sliders.find(x => x.sliderId === id);
  }


  remove(slider: ISlider) {

    this._logger.info('--> remove slider', slider.sliderId);
    // remove modal from array of active modals
    this.sliders = this.sliders.filter(x => x !== slider);
  }


  /**
   * Open slider. Call after sidebar inputs have been configured.
   * @param id 
   * @param autoClose if any other sidbar is open close it.
   * @returns 
   */
  open(id: string, autoClose: boolean = true): ISlider {

      const slider = this.sliders.find(x => x.sliderId === id);
      if (!slider) {
        throw new Error(`Slider '${id}' not found`);
      }

      if (autoClose) {

        this.sliders.forEach(sidebar => {

          if (sidebar.sliderId !== id) {
            
            sidebar.isOpen.set(false);
          }
      });
    }

    // setTimeout resolves occasional timing issue when this is called before sidebar Inputs were updated
    setTimeout(() => {

      slider.isOpen.set(true);
    });
    return slider;
  }


  /**
   * Close first open slider.
   * @param id Narrow slider search by sliderId
   */
  close(id?: string) {

    const slider = id ? this.sliders.find(x => x.sliderId === id) : this.sliders.find(x => x.isOpen);

    slider?.isOpen.set(false);
  }


}