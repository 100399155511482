import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapEyeglasses } from '@ng-icons/bootstrap-icons';
import { ShowroomService } from 'src/app/core/service/showroom/showroom.service';

@Component({
  selector: 'app-myoptyx-badge',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  templateUrl: './myoptyx-badge.component.html',
  styleUrls: ['./myoptyx-badge.component.scss'],
  providers: [provideIcons({ bootstrapEyeglasses })]
})
export class MyOptyxBadgeComponent{

  constructor(private readonly showroomService: ShowroomService) { }


  openMatterportAdmin() {

    this.showroomService.state().instance.openShowroomOptions();
  }

}
