import { defineSystem } from 'bitecs'
import { Euler, Object3D} from 'three';
import {
    ActiconBaseComponent,
    ActiconComponent,
    AdjustableComponent,
    DisplayPlaneComponent,
    PositionAdjustmentComponent,
    PositionComponent,
    RotationAdjustmentComponent,
    RotationComponent,
    ScaleAdjustmentComponent,
    ScaleComponent,
    acticonChildrenEntityMap,
    object3dByEntityId
} from '..';
import { ActiconPosition, CenterActiconPositions, LeftActiconPositions, TopActiconPositions, translateObject, VerticalActiconPositions } from '../..';
import { lerp } from "three/src/math/MathUtils";
import { WorldState } from '../ecs-world.state';
import { adjustableEntityQuery } from '../entity/adjustable.entity';
import { animationMixerByEntityId } from '../entity/object-prop.entity';

export const ActiconState = {

    counter: 0
}

const euler = new Euler(0, 0, 0, 'YXZ');

interface BoundingBox {
    eid: number
    height: number
    scale: number
    sort: number
    width: number
    x: number
    y: number
}

//export const acticonEntityQuery = defineQuery([ActiconComponent]);

/**
 * Acticon specific position, rotation, scale and animation updates.
 */
export const acticonSystem = defineSystem(world => {

    let entities = adjustableEntityQuery(world);
    for (let i = 0; i < entities.length; i++) {
        
        const eidForParent = entities[i];
        if (acticonChildrenEntityMap[eidForParent] && 0 < acticonChildrenEntityMap[eidForParent].length) {

            let boundingBoxes = acticonChildrenEntityMap[eidForParent]
                .filter(eid => object3dByEntityId[eid].visible)
                .map(eid => {

                    // Should Acticon scaling remain associated with its base position or the adjusted parent position?
                    const scale = Math.max(1.0, object3dByEntityId[eid].position.distanceTo(WorldState.cameraPose.position) / 4.0);
                    
                    return <BoundingBox>{
                        eid: eid,
                        height: ActiconComponent.size.h[eid] * scale,
                        scale: scale,
                        sort: ActiconComponent.sort[eid],
                        width: ActiconComponent.size.w[eid] * scale,
                        x: 0,
                        y: 0
                    }
                });
            if (1 > boundingBoxes.length || boundingBoxes.some(bb => bb.width < .001)) {

                continue;
            }               

            // Calculate Acticon offsets based upon parents adjusted position and scale.
            const parentScaleX = ScaleComponent.x[eidForParent] + ScaleAdjustmentComponent.x[eidForParent];
            const parentScaleY = ScaleComponent.y[eidForParent] + ScaleAdjustmentComponent.y[eidForParent];
            let contentHeight = (1 / AdjustableComponent.aspect[eidForParent]) * parentScaleY; // Base height
            if (0 < DisplayPlaneComponent.aspect[eidForParent]) {    // If content aspect is defined
    
                if (DisplayPlaneComponent.aspect[eidForParent] >= AdjustableComponent.aspect[eidForParent]) {
    
                    contentHeight = (1 / DisplayPlaneComponent.aspect[eidForParent]) * parentScaleY;
                }
            } 

            const acticonOffsetY = contentHeight / 2;
            const acticonOffsetX = (parentScaleX * DisplayPlaneComponent.scaleFactor[eidForParent]) / 2;
            boundingBoxes = layoutBoundingBoxes(
                boundingBoxes,
                ActiconBaseComponent.acticonAlignment[eidForParent],
                acticonOffsetX, // ActiconBaseComponent.offset.x[eidForParent],
                acticonOffsetY, //ActiconBaseComponent.offset.y[eidForParent],
                ActiconBaseComponent.acticonMargin[eidForParent]); 
            
            for (let j = 0; j < boundingBoxes.length; j++) {

                const eidForActicon = boundingBoxes[j].eid;                
                const acticon = object3dByEntityId[eidForActicon];
                acticon.scale.set(boundingBoxes[j].scale, boundingBoxes[j].scale, boundingBoxes[j].scale);
                // Start by matching the parents base position because Acticons are independent of the parents Object3D group
                acticon.position.set(
                    PositionComponent.x[eidForParent] + PositionAdjustmentComponent.x[eidForParent],   
                    PositionComponent.y[eidForParent] + PositionAdjustmentComponent.y[eidForParent],   
                    PositionComponent.z[eidForParent] + PositionAdjustmentComponent.z[eidForParent]);
                // Match rotation with parent
                acticon.quaternion.setFromEuler(euler.set(
                    RotationComponent.x[eidForParent] + RotationAdjustmentComponent.x[eidForParent],
                    RotationComponent.y[eidForParent] + RotationAdjustmentComponent.y[eidForParent],
                    RotationComponent.z[eidForParent] + RotationAdjustmentComponent.z[eidForParent], 'YXZ'));

                translateObject(
                    acticon,
                    boundingBoxes[j].x,
                    boundingBoxes[j].y,
                    ActiconBaseComponent.acticonZ[eidForParent]);
                
                // Embedded GTLF animations
                animationMixerByEntityId[eidForActicon]?.update(WorldState.delta); 
            }
        }
    }

    return world;
})


function animateActicon(eidForActicon: number, acticon: Object3D) {

    ActiconComponent.animationTimer[eidForActicon] = Math.min(ActiconComponent.animationTimer[eidForActicon] + 1, ActiconComponent.animationDuration[eidForActicon]);
    // if (ActiconComponent.animationTimer[entityId] < ActiconComponent.animationDuration[entityId]) {
    if (acticon.scale.x !== ActiconComponent.scale.x[eidForActicon]) {

        ActiconComponent.animationTimer[eidForActicon]++;
        const ratio = Math.min(1, (ActiconComponent.animationTimer[eidForActicon] / ActiconComponent.animationDuration[eidForActicon]));
        //ActiconState.logger.error(`ratio: ${ratio}, scale x: ${acticon.scale.x}, from x: ${ActiconComponent.scaleFromX[entityId]}, to x: ${ActiconComponent.scaleX[entityId]}, lerp: ${lerp(ActiconComponent.scaleFromX[entityId], ActiconComponent.scaleX[entityId], ratio)}`);
        acticon.scale.set(
            lerp(ActiconComponent.scaleFrom.x[eidForActicon], ActiconComponent.scale.x[eidForActicon], ratio),
            lerp(ActiconComponent.scaleFrom.y[eidForActicon], ActiconComponent.scale.y[eidForActicon], ratio),
            lerp(ActiconComponent.scaleFrom.z[eidForActicon], ActiconComponent.scale.z[eidForActicon], ratio));
    }
}


function layoutBoundingBoxes(boxes: BoundingBox[], alignment: ActiconPosition, xOffset: number, yOffset: number, acticonMargin: number): BoundingBox[] {

    const xOffsetToApply = !VerticalActiconPositions.has(alignment) ? 0 : xOffset + acticonMargin;
    const yOffsetToApply = VerticalActiconPositions.has(alignment) ? 0 : yOffset + acticonMargin;
    const margin = .1275; // Margin between boxes
    // Set the Top/Bottom/Left/Right alignment
    let currentX = LeftActiconPositions.has(alignment) ? -xOffsetToApply : xOffsetToApply;
    let currentY = TopActiconPositions.has(alignment) ? yOffsetToApply : -yOffsetToApply;
      
    const results = boxes.sort((b1, b2) => b1.sort - b2.sort)
        .map(box => {
  
            const positionedBox = {
                ...box,
                x: currentX,
                y: currentY
            };
  
            currentX += VerticalActiconPositions.has(alignment) ? 0 : box.width + (margin * box.scale);
            currentY += VerticalActiconPositions.has(alignment) ? box.height + (margin * box.scale) : 0;
  
            return positionedBox;
        });
    
    // Total size of the laid out bounding boxes along their primary axis
    const totalSize = (VerticalActiconPositions.has(alignment) ? currentY : currentX) - (margin * boxes[boxes.length - 1].scale);
    // Adjustments.
    if (VerticalActiconPositions.has(alignment)) {
        
        let yAdjust = totalSize / 2;    // The default for center position
        if (TopActiconPositions.has(alignment)) { // Align top

            yAdjust = yOffset;
        } else if (!CenterActiconPositions.has(alignment)) { // Align bottom

            yAdjust = totalSize - yOffset;
        } 
        for (let i = 0; i < results.length; i++) {

            results[i].y += yAdjust;
        }

    } else {
        
        let xAdjust = totalSize / 2;    // The default for center position
        if (LeftActiconPositions.has(alignment)) { // Align  left

            xAdjust = xOffset;
        } else if (!CenterActiconPositions.has(alignment)) { // Align right
            
            xAdjust = totalSize - xOffset;
        }
        for (let i = 0; i < results.length; i++) {

            results[i].x -= xAdjust;
        }
    }
    
    return results;
}
  
  // Example usage:
//   const boxes: BoundingBox[] = [
//     { x: 0, y: 0, width: 100, height: 50 },
//     { x: 0, y: 0, width: 80, height: 40 },
//     { x: 0, y: 0, width: 120, height: 60 }
//   ];
  
//   const containerWidth = 300;
//   const containerHeight = 200;
  
//   const laidOutBoxes = layoutBoundingBoxes(boxes, containerWidth, containerHeight);
//   console.log(laidOutBoxes);
  
