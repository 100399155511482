@if (isValid()) {

<app-mp-showroom (StageInitialized)="onStageInitialized($event)" [Venue]="venue()" />
<app-myoptyx-badge />
<app-showroom-guest-options-sidebar (OnEventDesignSelect)="selectEventDesign($event)" [Venue]="venue()" />
<app-prop-select-sidebar [PropOptions]="propOptions()" (onSelect)="handlePropListSelection($event)" />
<app-image-prop-options-sidebar [Stage]="stage()" [ImageAssignment]="currentImageAssignment()"
    [DesignImage]="currentDesignImage()" [VenueService]="guestService" />
<app-object-prop-options-sidebar [ObjectAssignment]="currentObjectAssignment()" [VenueService]="guestService" />
<app-video-prop-options-sidebar [Stage]="stage()" [VideoAssignment]="currentVideoAssignment()"
    [DesignVideo]="currentDesignVideo()" />
<app-image-props-sidebar [ImageProps]="assignedImageProps()" (GoToImage)="onGoToImage($event)"
    (SelectImage)="onSelectImageProp($event)" />
<app-object-props-sidebar (GoToObject)="onGoToObject($event)" (SelectObject)="onSelectObject($event)"
    [ObjectAssignments]="objectAssignments()" />
<app-video-props-sidebar [VideoProps]="assignedVideoProps()" (GoToVideo)="onGoToVideo($event)"
    (SelectVideo)="onSelectVideo($event)" />
<app-positions-sidebar [Stage]="stage()" [ShowroomPositions]="showroomPositions()"
    (GoToPosition)="onGoToPosition($event)" />

}