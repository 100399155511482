import { AuthorizedSubscriptionUser, IsShowroomAdmin, IsShowroomSubscriber, IsShowroomVisitor, ShowroomAuthorizeResult, ShowroomPermission, isShowroomAdmin, isShowroomSubscriber, isShowroomVisitor } from "./showroom-state-permissions";
import { NGXLogger } from "ngx-logger";
import { inject } from "@angular/core";
import { SidebarService } from "../core/service/ui/sidebar/sidebar.service";
import { IMAGE_PROP_OPTIONS_SIDEBAR_ID } from "../components/showroom/shared/options/image-prop-options-sidebar/image-prop-options-sidebar.component";
import { STAGE_IMAGE_PROP_SIDEBAR_ID } from "../components/showroom/admin/stage-image-prop-sidebar/stage-image-prop-sidebar.component";
import { Subject } from "rxjs/internal/Subject";
import { OBJECT_PROP_OPTIONS_SIDEBAR_ID } from "../components/showroom/shared/options/object-prop-options-sidebar/object-prop-options-sidebar.component";
import { CONFIGURE_OBJECT_PROP_SIDEBAR_ID } from "../components/showroom/shared/configure/configure-object-prop-sidebar/configure-object-prop-sidebar.component";
import { STAGE_VIDEO_PROP_SIDEBAR_ID } from "../components/showroom/admin/stage-video-prop-sidebar/stage-video-prop-sidebar.component";
import { CONFIGURE_IMAGE_PROP_SIDEBAR_ID } from "../components/showroom/shared/configure/configure-image-prop-sidebar/configure-image-prop-sidebar.component";
import { CONFIGURE_VIDEO_PROP_SIDEBAR_ID } from "../components/showroom/shared/configure/configure-video-prop-sidebar/configure-video-prop-sidebar.component";
import { Prop, PropType, SubscriptionUser } from "projects/my-common/src/model";
import { VIDEO_PROP_OPTIONS_SIDEBAR_ID } from "../components/showroom/shared/options/video-prop-options-sidebar/video-prop-options-sidebar.component";
import { SHOWROOM_ADMIN_OPTIONS_SIDEBAR_ID } from "../components/showroom/admin/showroom-admin-options-sidebar/showroom-admin-options-sidebar.component";
import { SHOWROOM_CLIENT_OPTIONS_SIDEBAR_ID } from "../components/showroom/client/showroom-client-options-sidebar/showroom-client-options-sidebar.component";
import { SHOWROOM_GUEST_OPTIONS_SIDEBAR_ID } from "../components/showroom/guest/showroom-guest-options-sidebar/showroom-guest-options-sidebar.component";

type SetShowroomServiceStateCallback = (newShowroomState: IShowroomServiceState) => void;

export interface IShowroomStateProvider {

    setShowroomState: SetShowroomServiceStateCallback;
}

export enum ShowroomState { VISITOR, SUBSCRIBER, ADMIN }
let currentState: IShowroomServiceState;
export function InitializeShowroomState(setStateCallback: SetShowroomServiceStateCallback): void {

    if (!currentState) {

        ShowroomAdminState.Initialize();
        ShowroomSubscriberState.Initialize();
        ShowroomVisitorState.Initialize();
        currentState = ShowroomVisitorState.Instance;
    }

    const existingCallback = showroomStateCallbacks.findIndex(ssc => ssc === setStateCallback);
    if (0 > existingCallback) {

        showroomStateCallbacks.push(setStateCallback);
    }

    setStateCallback(currentState);
}
const NOT_INITIALIZED_MESSAGE = 'Showroom state not initialized'
const initializedShowroomStates: IShowroomServiceState[] = [];

const showroomStateCallbacks: SetShowroomServiceStateCallback[] = [];
function invokeCallback(newState: IShowroomServiceState) {

    currentState = newState;
    const existingCallbacks: SetShowroomServiceStateCallback[] = showroomStateCallbacks.slice();

    for (const showroomStateCallback of existingCallbacks) {

        try {

            showroomStateCallback(currentState);
        } catch (ex) {

            console.error('Invalid state callback');
            const invalidCallbackIndex = showroomStateCallbacks.findIndex(ssc => ssc === showroomStateCallback);
            showroomStateCallbacks.splice(invalidCallbackIndex, 1);
        }
    }
}

export enum ShowroomMode { CONFIGURATION = 'Configuration mode', STAGE = 'Stage mode', SHOWROOM = 'Showroom mode' }
const _propInteractionModeSource = new Subject<ShowroomMode>();
export const propInteractionSourceUpdate$ = _propInteractionModeSource.asObservable();

export function getAvailableShowroomModes(user: SubscriptionUser): ShowroomState[] {

    const availableShowroomModes: ShowroomState[] = [];

    let i = 0;
    for (; i < initializedShowroomStates.length; i++) {

        const isAuthorized = initializedShowroomStates[i].isAuthorized(user, initializedShowroomStates[i].showroomPermission);
        if (isAuthorized.type == "ok") {
            availableShowroomModes.push(initializedShowroomStates[i].showroomState);
        };
    }

    return availableShowroomModes;
}

/**
 * https://www.youtube.com/watch?v=gMyRtqwxr10
 * State contract. Define roles and transitions for each state.
 * Implemented as singletons that can stick around and "remember" from transition to transition
 */
export interface IShowroomServiceState {

    showroomState: ShowroomState;
    showroomPermission: Partial<ShowroomPermission>;
    showroomMode: ShowroomMode;
    handlePropClickInteraction(prop: Prop): void;
    /**
     * Transition to Admin state.
     * @param user 
     * @returns true if permitted else returns false
     */
    enterAdminState(user: SubscriptionUser): boolean;
    /**
     * Transition to Subscriber state.
     * @param user 
     * @returns true if permitted else returns false
     */
    enterSubscriberState(user: SubscriptionUser): boolean;
    /**
     * Transition to Visitor state.
     * @param user 
     * @returns true if permitted else returns false
     */
    enterVisitorState(user: SubscriptionUser): boolean;
    /**
     * @returns The PropInteractionModes available in the current state.
     */
    getInteractionModes(user: SubscriptionUser): ShowroomMode[];
    openShowroomOptions(): void;
    /**
     * Activate the specified Showroom Mode
     * @param showroomMode 
     */
    setShowroomMode(showroomMode: ShowroomMode): void;
    /**
     * Activate the highest state available to the specified user.
     * @param user 
     */
    setState(user: SubscriptionUser): void;
    /**
     * Evaluate SubscriptionUser Role, translate to ShowroomPermission for the state
     * @param user 
     * @param showroomPermissionType 
     */
    isAuthorized<T extends Partial<ShowroomPermission>>(user: SubscriptionUser, showroomPermissionType: T): ShowroomAuthorizeResult<T>;
}


abstract class ShowroomServiceState implements IShowroomServiceState {

    protected readonly _sidebarService!: SidebarService;

    abstract showroomState: ShowroomState;
    abstract showroomPermission: Partial<ShowroomPermission>;
    showroomMode: ShowroomMode = ShowroomMode.SHOWROOM;
    abstract enterAdminState(user: SubscriptionUser): boolean;
    abstract enterSubscriberState(user: SubscriptionUser): boolean;
    abstract enterVisitorState(user: SubscriptionUser): boolean;
    abstract getInteractionModes(user: SubscriptionUser): ShowroomMode[];
    abstract handlePropClickInteraction(prop: Prop): void;
    abstract openShowroomOptions(): void;


    constructor() {

        this._sidebarService = inject(SidebarService);
    }


    isAuthorized<T extends Partial<ShowroomPermission>>(user: SubscriptionUser, showroomPermissionType: T): ShowroomAuthorizeResult<T> {

        // Default response, no one is authorized
        return { type: "fail", reason: "Not authorized" }
    }


    setShowroomMode(propInteractionMode: ShowroomMode): void {

        this.showroomMode = propInteractionMode;
        _propInteractionModeSource.next(this.showroomMode);
    }


    setState(user: SubscriptionUser): void {

        if (user.isAdmin) {
            
            this.enterAdminState(user);
        }
        else if (user.isReadOnlySubscriber) {
            
            this.enterSubscriberState(user);
        }
        else {
            
            this.enterVisitorState(user);
        }
    }
}


export class ShowroomAdminState extends ShowroomServiceState {

    override readonly showroomState = ShowroomState.ADMIN;
    override readonly showroomPermission = isShowroomAdmin;
    private static _instance: ShowroomAdminState;
    private static _logger: NGXLogger;


    private constructor() { super(); }


    static Initialize(): void {

        if (this._logger) {
            this._logger.trace('--> Showroom state already initialized');
            return;
        }
        this._logger = inject(NGXLogger);
        this._instance = new this();
        initializedShowroomStates.push(this._instance);
    }


    static get Instance(): ShowroomAdminState {

        if (!this._instance) {

            throw new Error(NOT_INITIALIZED_MESSAGE);
        }

        return this._instance;
    }


    override enterAdminState(user: SubscriptionUser): boolean {

        ShowroomAdminState._logger.trace('--> Already in admin state');
        return true;
    }


    override enterSubscriberState(user: SubscriptionUser): boolean {

        if (ShowroomSubscriberState.Instance.isAuthorized(user, isShowroomSubscriber)) {

            invokeCallback(ShowroomSubscriberState.Instance);
            return true;
        }
        return false;
    }


    override enterVisitorState(user: SubscriptionUser): boolean {

        if (ShowroomVisitorState.Instance.isAuthorized(user, isShowroomVisitor)) {

            invokeCallback(ShowroomVisitorState.Instance);
            return true;
        }
        return false;
    }


    override getInteractionModes(user: SubscriptionUser): ShowroomMode[] {

        return [ShowroomMode.SHOWROOM, ShowroomMode.CONFIGURATION, ShowroomMode.STAGE];
    }


    override handlePropClickInteraction(prop: Prop): void {

        ShowroomAdminState._logger.trace(`current interaction mode ${this.showroomMode}`);
        ShowroomAdminState._logger.trace(`prop type ${prop.type}`);
        if (this.showroomMode === ShowroomMode.STAGE) {

            switch (prop.type) {
                case PropType.IMAGE:
                    this._sidebarService.open(STAGE_IMAGE_PROP_SIDEBAR_ID);
                    break;
                // Object Props are not staged
                // case PropType.OBJECT:
                //     this._sidebarService.open(STAGE_OBJECT_PROP_SIDEBAR_ID);
                //     break;
                case PropType.VIDEO:
                    this._sidebarService.open(STAGE_VIDEO_PROP_SIDEBAR_ID);
                    break;
                case PropType.PLACEHOLDER: break;
            }
        } else if (this.showroomMode === ShowroomMode.CONFIGURATION) {

            switch (prop.type) {
                case PropType.IMAGE:
                    this._sidebarService.open(CONFIGURE_IMAGE_PROP_SIDEBAR_ID);
                    break;
                case PropType.OBJECT:
                    this._sidebarService.open(CONFIGURE_OBJECT_PROP_SIDEBAR_ID);
                    break;
                case PropType.VIDEO:
                    this._sidebarService.open(CONFIGURE_VIDEO_PROP_SIDEBAR_ID);
                    break;
                case PropType.PLACEHOLDER: break;
            }
        } else {

            switch (prop.type) {
                case PropType.IMAGE:
                    this._sidebarService.open(IMAGE_PROP_OPTIONS_SIDEBAR_ID);
                    break;
                case PropType.OBJECT:
                    this._sidebarService.open(OBJECT_PROP_OPTIONS_SIDEBAR_ID);
                    break;
                case PropType.VIDEO:
                    ShowroomAdminState._logger.trace(`Opening video sidebar`);
                    this._sidebarService.open(VIDEO_PROP_OPTIONS_SIDEBAR_ID);
                    break;
                case PropType.PLACEHOLDER:
                    break;
            }

        }
    }


    override isAuthorized<T extends Partial<ShowroomPermission> = IsShowroomAdmin>(user: SubscriptionUser, showroomPermissionType: T): ShowroomAuthorizeResult<T> {

        if (!showroomPermissionType.isShowroomAdmin) {

            return { type: "fail", reason: "Permission type must be isShowroomAdmin" }
        }

        ShowroomAdminState._logger.trace(`-->  Admin isAuthorized roleMask: ${user.roleMask}`);
        if (user.isAdmin) {

            ShowroomAdminState._logger.trace(`-->  AUTHORIZED: Admin isAuthorized roleMask: ${user.roleMask}`);
            return { type: "ok", user: user as AuthorizedSubscriptionUser<T> }
        } else {

            return { type: "fail", reason: "Not authorized" }
        }
    }


    override openShowroomOptions(): void {
        
        this._sidebarService.open(SHOWROOM_ADMIN_OPTIONS_SIDEBAR_ID);
    }

}


export class ShowroomSubscriberState extends ShowroomServiceState {

    override readonly showroomState = ShowroomState.SUBSCRIBER;
    override readonly showroomPermission = isShowroomSubscriber;
    private static _instance: ShowroomSubscriberState;
    private static _logger: NGXLogger;


    private constructor() { super() }


    static Initialize(): void {

        if (this._logger) {

            this._logger.trace('--> Showroom state already initialized');
            return;
        }

        this._logger = inject(NGXLogger);
        this._instance = new this();
        initializedShowroomStates.push(this._instance);
    }


    static get Instance(): ShowroomSubscriberState {

        if (!this._instance) {

            throw new Error(NOT_INITIALIZED_MESSAGE);
        }
        return this._instance;
    }


    override enterAdminState(user: SubscriptionUser): boolean {

        if (ShowroomAdminState.Instance.isAuthorized(user, isShowroomSubscriber)) {

            invokeCallback(ShowroomAdminState.Instance);
            return true;
        }
        return false;
    }


    override enterSubscriberState(user: SubscriptionUser): boolean {

        ShowroomSubscriberState._logger.trace('--> Already in subscriber state');
        return true;
    }


    override enterVisitorState(user: SubscriptionUser): boolean {

        if (ShowroomVisitorState.Instance.isAuthorized(user, isShowroomVisitor)) {

            invokeCallback(ShowroomVisitorState.Instance);
            return true;
        }
        return false;
    }


    override getInteractionModes(user: SubscriptionUser): ShowroomMode[] {

        if (user.isActiveSubscriber) {

            return [ShowroomMode.SHOWROOM, ShowroomMode.CONFIGURATION];
        } else {

            return [ShowroomMode.SHOWROOM];
        }
    }


    override handlePropClickInteraction(prop: Prop): void {

        ShowroomSubscriberState._logger.trace(`current interaction mode ${this.showroomMode}`);
        ShowroomSubscriberState._logger.trace(`prop type ${prop.type}`);
        if (this.showroomMode === ShowroomMode.CONFIGURATION) {

            switch (prop.type) {
                case PropType.IMAGE:
                    this._sidebarService.open(CONFIGURE_IMAGE_PROP_SIDEBAR_ID);
                    break;
                case PropType.OBJECT:
                    this._sidebarService.open(CONFIGURE_OBJECT_PROP_SIDEBAR_ID);
                    break;
                case PropType.VIDEO:
                    this._sidebarService.open(CONFIGURE_VIDEO_PROP_SIDEBAR_ID);
                    break;
                case PropType.PLACEHOLDER: break;
            }
        } else {

            switch (prop.type) {
                case PropType.IMAGE:
                    this._sidebarService.open(IMAGE_PROP_OPTIONS_SIDEBAR_ID);
                    break;
                case PropType.OBJECT:
                    this._sidebarService.open(OBJECT_PROP_OPTIONS_SIDEBAR_ID);
                    break;
                case PropType.VIDEO:
                    this._sidebarService.open(VIDEO_PROP_OPTIONS_SIDEBAR_ID);
                    break;
                case PropType.PLACEHOLDER: break;
            }
        }
    }


    override isAuthorized<T extends Partial<ShowroomPermission> = IsShowroomSubscriber>(user: SubscriptionUser, showroomPermissionType: T): ShowroomAuthorizeResult<T> {

        if (!showroomPermissionType.isShowroomSubscriber) {

            return { type: "fail", reason: "Permission type must be isShowroomSubscriber" }
        }
        if (user.isReadOnlySubscriber) {

            return { type: "ok", user: user as AuthorizedSubscriptionUser<T> }
        } else {

            return { type: "fail", reason: "Not authorized" }
        }
    }


    override openShowroomOptions(): void {
        
        this._sidebarService.open(SHOWROOM_CLIENT_OPTIONS_SIDEBAR_ID);
    }

}


export class ShowroomVisitorState extends ShowroomServiceState {

    override readonly showroomState = ShowroomState.VISITOR;
    override readonly showroomPermission = isShowroomVisitor;
    private static _instance: ShowroomVisitorState;
    private static _logger: NGXLogger;

    private constructor() { super() }


    static Initialize(): void {

        if (this._logger) {

            this._logger.trace('--> Showroom state already initialized');
            return;
        }
        this._logger = inject(NGXLogger);
        this._instance = new this();
        initializedShowroomStates.push(this._instance);
    }


    static get Instance(): ShowroomVisitorState {

        if (!this._instance) {

            throw new Error(NOT_INITIALIZED_MESSAGE);
        }
        return this._instance;
    }


    override enterAdminState(user: SubscriptionUser): boolean {

        const targetState = ShowroomAdminState.Instance;
        if (targetState.isAuthorized(user, isShowroomAdmin)) {

            invokeCallback(targetState);
            return true;
        }
        return false;
    }


    override enterSubscriberState(user: SubscriptionUser): boolean {

        const targetState = ShowroomSubscriberState.Instance;
        if (targetState.isAuthorized(user, isShowroomSubscriber)) {

            invokeCallback(targetState);
            return true;
        }
        return false;
    }


    override enterVisitorState(): boolean {

        ShowroomVisitorState._logger.trace('--> Already in visitor state');
        return true;
    }


    override getInteractionModes(user: SubscriptionUser): ShowroomMode[] {

        return [ShowroomMode.SHOWROOM];
    }


    override handlePropClickInteraction(prop: Prop): void {

        ShowroomVisitorState._logger.trace(`current interaction mode ${this.showroomMode}`);
        ShowroomVisitorState._logger.trace(`prop type ${prop.type}`);

        switch (prop.type) {
            case PropType.IMAGE:
                this._sidebarService.open(IMAGE_PROP_OPTIONS_SIDEBAR_ID);
                break;
            case PropType.OBJECT:
                this._sidebarService.open(OBJECT_PROP_OPTIONS_SIDEBAR_ID);
                break;
            case PropType.VIDEO:
                this._sidebarService.open(VIDEO_PROP_OPTIONS_SIDEBAR_ID);
                break;
            case PropType.PLACEHOLDER: break;
        }
    }


    override isAuthorized<T extends Partial<ShowroomPermission> = IsShowroomVisitor>(user: SubscriptionUser, showroomPermissionType: T): ShowroomAuthorizeResult<T> {

        if (!showroomPermissionType.isShowroomVisitor) {

            return { type: "fail", reason: "Permission type must be isShowroomVisitor" }
        }
        // Everyone is an authorized Visitor
        return { type: "ok", user: user as AuthorizedSubscriptionUser<T> }
    }


    override openShowroomOptions(): void {
        
        this._sidebarService.open(SHOWROOM_GUEST_OPTIONS_SIDEBAR_ID);
    }


}
