import { IWorld, addComponent, addEntity, defineQuery, removeEntity } from "bitecs"
import { AdjustableComponent } from "../component/adjustable.component"
import { CrudState, ClippingPlaneAssignment, PropType } from "../../model"
import { AdjustmentComponent } from "../component/adjustment.component"
import { WorldState } from "../ecs-world.state"
import { PropAdjustment } from "../../scene/prop-adjustment"
import { getEidForShowroomPosition } from "./showroom-position.entity"
import { AdjustmentInitState } from "../system/adjustment-init.state"
import { ClippingAssignmentComponent } from "../component/clipping-assignment.component"
import { ClippingPlaneComponent } from "../component/clipping-plane.component"
import { ScaleAdjustmentComponent } from "../component/scale.component"

/**
 * This type establishes a relationship between two entities.
 * e.g. const childEntityId = childEntityMap[parentEntityId]
 */
export type ChildEntityMap = {
    [entityId: number]: number
}
/**
 * This type maps a composite relationship between two entities and another.
 * e.g. const targetEntityId = parenChildEntityMap[ParentEntityId][ChildEntityId]
 */
export type ParentChildEntityMap = {
    [parentEntityId: number]: ChildEntityMap
}

/**
 * Prop Adjustments are defined for a specific Prop at a specific Showroom Position
 * Composite key lookup of target "Adjustment entityIds" for a given [Prop entityId] at a given [position entityId].
 * Position => Prop => Adjustment
 * e.g. const adjustmentEntityId = positionPropAdjustmentEntityMap[positionEntityId][propEntityId]
 */
export const positionPropAdjustmentEntityMap: ParentChildEntityMap = {}

/**
 * Adjustment level clipping plane assignments are defined for a specific Clipping Plane at a specific Showroom Position.
 * Composite key lookup of target "Adjustment level Clipping Assignment entityIds" for a given [Clipping Plane entityId] at a given [position entityId].
 * Position => Clipping Plane => adjustment level clipping assignment
 * e.g. const clippingAssignmentEntityId = positionClippingAssignmentEntityMap[positionEntityId][clippingPlaneEntityId]
 */
export const positionClippingAssignmentEntityMap: ParentChildEntityMap = {}

/**
 * Prop level clipping plane assignments are defined for a specific [Clipping Plane entityId] of a specific [Prop entityId]
 * Composite key lookup of target "Prop level Clipping Assignment entityIds" for a given [Clipping Plane entityId] of a given [Prop entityId].
 * Prop => Clipping Plane => prop level clipping assignment
 * e.g. const clippingAssignmentEntityId = propClippingAssignmentEntityMap[propEntityId][clippingPlaneEntityId]
 */
export const propClippingAssignmentEntityMap: ParentChildEntityMap = {}

/**
 * References to data based mask textures which are updated dynamically and applied to game objects.
 * Maybe there is some way to integrate these data arrays directly into ECS Components?
 */
// type MaskLoaderMap = { [entityId: number]: MaskLoaderComponent }
// export const maskLoaderByEntityId: MaskLoaderMap = {}

//
// Queries
//
export const adjustableEntityQuery = defineQuery([AdjustableComponent]);
export const clippingPlaneEntityQuery = defineQuery([ClippingPlaneComponent]);
//export const adjustableEntityExitQuery = exitQuery(adjustableEntityQuery);


/**
 * Lookup the entityId for the AdjustmentComponent data associated with an entity at a given position.
 * @param eidForShowroomPosition the target fixed position id
 * @param eidForParentAdjustableProp the owner of the adjustment
 * @returns the entityId for thhe AdjustmentComponent or -1
 */
export function getAdjustmentEidForPositionAndAdjustable(eidForShowroomPosition: number, eidForParentAdjustableProp: number): number {

    return positionPropAdjustmentEntityMap[eidForShowroomPosition]
        && positionPropAdjustmentEntityMap[eidForShowroomPosition][eidForParentAdjustableProp] ?
        positionPropAdjustmentEntityMap[eidForShowroomPosition][eidForParentAdjustableProp] : -1;
}


/**
 * Lookup the entityId for the AdjustmentComponent data associated with an entity at a given position.
 * @param eidForShowroomPosition the target fixed position id
 * @param eidForParentClippingPlane the owner of the adjustment
 * @returns the entityId for thhe AdjustmentComponent or -1
 */
export function getClippingAssignmentEidForPositionAndPlane(eidForShowroomPosition: number, eidForParentClippingPlane: number): number {

    return positionClippingAssignmentEntityMap[eidForShowroomPosition]
        && positionClippingAssignmentEntityMap[eidForShowroomPosition][eidForParentClippingPlane] ?
        positionClippingAssignmentEntityMap[eidForShowroomPosition][eidForParentClippingPlane] : -1;
}


/**
 * @param propId target adjustable prop id
 * @param propType target adjustable prop type
 * @returns the entity id for the related adjustable Prop
 */
export function getEidForAdjustableProp(world: IWorld, propId: number, propType: PropType): number {

    return adjustableEntityQuery(world).find(eid => AdjustableComponent.id[eid] === propId
        && propType === AdjustableComponent.type[eid]) ?? -1;
}


/**
 * @param clippingPlaneId target adjustable prop id
 * @returns the entity id for the related adjustable Clipping Plane
 */
export function getEidForClippingPlane(world: IWorld, clippingPlaneId: number, propType: PropType): number {

    return clippingPlaneEntityQuery(world).find(eid => ClippingPlaneComponent.id[eid] === clippingPlaneId
            && ClippingPlaneComponent.type[eid] === propType) ?? -1;
}


export function removeAdjustment(world: IWorld, adjustment: PropAdjustment, propId: number, propType: PropType) {

    const eidForShowroomPosition = getEidForShowroomPosition(adjustment.playerPositionId);

    if (-1 < eidForShowroomPosition
        && positionPropAdjustmentEntityMap[eidForShowroomPosition]) {

        const eidForAdjustableEntity = getEidForAdjustableProp(world, propId, propType);
        if (-1 < eidForAdjustableEntity) {

            const eidForAdjustment = positionPropAdjustmentEntityMap[eidForShowroomPosition][eidForAdjustableEntity];
            removeEntity(world, eidForAdjustment);
            // Removing the entity does not remove or reset exiting Component values but thier space is available for reuse.
            // Exit query can capture the removal and handle resetting ov values if desired.
            // For now, we are just removing the eid from existance and leaving old Component values as they are.
            delete positionPropAdjustmentEntityMap[eidForShowroomPosition][eidForAdjustableEntity];

            // Remove Clipping Assignments associated with the Adjustment            
            if (0 < adjustment.clippingAssignments.length) {

                for (const clippingAssignment of adjustment.clippingAssignments) {

                    removeClippingPlaneAssignment(world, adjustment.playerPositionId, clippingAssignment.clippingPlaneId, AdjustableComponent.type[eidForAdjustableEntity]);
                }

            }

            // Invalidate position id to force analysis of Adjustments.
            AdjustmentInitState.currentShowroomPositionId = -1;
        }
    }
}


export function removeClippingPlaneAssignment(world: IWorld, playerPositionId: string, clippingPlaneId: number, propType: PropType) {

    const eidForShowroomPosition = getEidForShowroomPosition(playerPositionId);

    if (-1 < eidForShowroomPosition
        && positionClippingAssignmentEntityMap[eidForShowroomPosition]) {

        const eidForClippingPlaneEntity = getEidForClippingPlane(world, clippingPlaneId, propType);
        if (-1 < eidForClippingPlaneEntity) {

            const eidForClippingAssignment = positionClippingAssignmentEntityMap[eidForShowroomPosition][eidForClippingPlaneEntity];
            removeEntity(world, eidForClippingAssignment);
            // Removing the entity does not remove or reset exiting Component values but thier space is available for reuse.
            // Exit query can capture the removal and handle resetting ov values if desired.
            // For now, we are just removing the eid from existance and leaving old Component values as they are.
            delete positionClippingAssignmentEntityMap[eidForShowroomPosition][eidForClippingPlaneEntity];

            // Invalidate position id to force analysis of adjustments.
            AdjustmentInitState.currentShowroomPositionId = -1;
        }

        // Reset this Clipping Plane.
        ScaleAdjustmentComponent.x[eidForClippingPlaneEntity] =
            ScaleAdjustmentComponent.y[eidForClippingPlaneEntity] =
            ScaleAdjustmentComponent.z[eidForClippingPlaneEntity] = 0;

        // Invalidate position id to force analysis of Adjustments.
        AdjustmentInitState.currentShowroomPositionId = -1;
    }
}


export function removeAdjustmentsForProp(world: IWorld, propId: number, propType: PropType) {

    const eidForAdjustableEntity = getEidForAdjustableProp(world, propId, propType);

    if (-1 < eidForAdjustableEntity) {

        let eidForAdjustment = 0;
        for (const eidForShowroomPosition of Object.keys(positionPropAdjustmentEntityMap)) {

            eidForAdjustment = positionPropAdjustmentEntityMap[Number(eidForShowroomPosition)][eidForAdjustableEntity];
            removeEntity(world, eidForAdjustment);
            // Removing the entity does not remove or reset exiting Component values but thier space is available for reuse.
            // Exit query can capture the removal and handle resetting ov values if desired.
            // For now, we are just removing the eid from existance and leaving old Component values as they are.
            delete positionPropAdjustmentEntityMap[Number(eidForShowroomPosition)][eidForAdjustableEntity];

        }

        // Invalidate position id to force analysis of adjustments.
        AdjustmentInitState.currentShowroomPositionId = -1;
    }
}


export function removeClippingAssignmentForClippingPlane(world: IWorld, clippingPlaneId: number, propType: PropType) {

    const eidForClippingPlaneEntity = getEidForClippingPlane(world, clippingPlaneId, propType);

    if (-1 < eidForClippingPlaneEntity) {

        let eidForClippingAssignment = 0;
        for (const eidForShowroomPosition of Object.keys(positionClippingAssignmentEntityMap)) {

            eidForClippingAssignment = positionClippingAssignmentEntityMap[Number(eidForShowroomPosition)][eidForClippingPlaneEntity];
            removeEntity(world, eidForClippingAssignment);
            // Removing the entity does not remove or reset exiting Component values but thier space is available for reuse.
            // Exit query can capture the removal and handle resetting ov values if desired.
            // For now, we are just removing the eid from existance and leaving old Component values as they are.
            delete positionClippingAssignmentEntityMap[Number(eidForShowroomPosition)][eidForClippingPlaneEntity];

        }

        // Invalidate position id to force analysis of adjustments.
        AdjustmentInitState.currentShowroomPositionId = -1;
    }
}


export function upsertAdjustmentComponentValues(world: IWorld, adjustment: PropAdjustment, eidForShowroomPosition: number, eidForAdjustableProp: number) {

    if (0 > eidForShowroomPosition || 0 > eidForAdjustableProp) {

        //WorldState.logger.error(`Invalid Position entity id: ${eidForShowroomPosition} or Adjustable enitiy id: ${eidForAdjustableEntity}`);
        return;
    }

    let eidForAdjustment = getAdjustmentEidForPositionAndAdjustable(eidForShowroomPosition, eidForAdjustableProp);

    // If AdjustmentComponent not created for the specified position and belonging to the specified entity then create it.
    if (0 > eidForAdjustment) {

        do eidForAdjustment = addEntity(world)
        while (!eidForAdjustment || 0 > eidForAdjustment);

        if (!positionPropAdjustmentEntityMap[eidForShowroomPosition]) {

            positionPropAdjustmentEntityMap[eidForShowroomPosition] = {}
        }

        positionPropAdjustmentEntityMap[eidForShowroomPosition][eidForAdjustableProp] = eidForAdjustment;
        addComponent(world, AdjustmentComponent, eidForAdjustment);
    }

    // Update the adjustment data
    AdjustmentComponent.hide[eidForAdjustment] = adjustment.hide ? 1 : 0;
    // AdjustmentComponent.horizontalMaskMode[eidForAdjustment] = adjustment.horizontalMask.mode;
    // AdjustmentComponent.horizontalMaskOffset[eidForAdjustment] = adjustment.horizontalMask.offset;
    // AdjustmentComponent.horizontalMaskWidth[eidForAdjustment] = adjustment.horizontalMask.width;
    AdjustmentComponent.position.x[eidForAdjustment] = adjustment.positionAdjustment.x;
    AdjustmentComponent.position.y[eidForAdjustment] = adjustment.positionAdjustment.y;
    AdjustmentComponent.position.z[eidForAdjustment] = adjustment.positionAdjustment.z;
    AdjustmentComponent.rotation.x[eidForAdjustment] = adjustment.rotateAdjustment.x;
    AdjustmentComponent.rotation.y[eidForAdjustment] = adjustment.rotateAdjustment.y;
    AdjustmentComponent.rotation.z[eidForAdjustment] = adjustment.rotateAdjustment.z;
    AdjustmentComponent.scale.x[eidForAdjustment] = adjustment.scaleAdjustment.x;
    AdjustmentComponent.scale.y[eidForAdjustment] = adjustment.scaleAdjustment.y;
    AdjustmentComponent.scale.z[eidForAdjustment] = adjustment.scaleAdjustment.z;
    // AdjustmentComponent.verticalMaskHeight[eidForAdjustment] = adjustment.verticalMask.height;
    // AdjustmentComponent.verticalMaskMode[eidForAdjustment] = adjustment.verticalMask.mode;
    // AdjustmentComponent.verticalMaskOffset[eidForAdjustment] = adjustment.verticalMask.offset;

    if (adjustment.clippingAssignments && 0 < adjustment.clippingAssignments.length) {

        for (const clippingAssignment of adjustment.clippingAssignments) {

            if (CrudState.DELETED === clippingAssignment.crudState) {

                continue;
            }
            const eidForShowroomPosition = getEidForShowroomPosition(adjustment.playerPositionId);
            if (0 > eidForShowroomPosition) {

                WorldState.logger.error(`Invalid Position entity id: ${eidForShowroomPosition}`);
                continue;
            }
            const eidForClippingPlane = getEidForClippingPlane(world, clippingAssignment.clippingPlaneId, AdjustableComponent.type[eidForAdjustableProp]);
            if (0 > eidForClippingPlane) {

                WorldState.logger.trace(`Invalid Clipping Plane enitiy id: ${eidForClippingPlane}, maybe not loaded yet ...`);
                continue;
            }
            updateClippingAssignmentComponentValues(world, clippingAssignment, eidForShowroomPosition, eidForClippingPlane);
        }
    }

    // Invalidate position id to force analysis of adjustments.
    AdjustmentInitState.currentShowroomPositionId = -1;
}


export function updateClippingAssignmentComponentValues(world: IWorld, clippingAssignment: ClippingPlaneAssignment, eidForShowroomPosition: number, eidForClippingPlaneEntity: number) {

    if (0 > eidForShowroomPosition || 0 > eidForClippingPlaneEntity) {

        WorldState.logger.error(`Invalid Position entity id: ${eidForShowroomPosition} or Clipping Plane enitiy id: ${eidForClippingPlaneEntity}`);
        return;
    }

    let eidForClippingAssignment = getClippingAssignmentEidForPositionAndPlane(eidForShowroomPosition, eidForClippingPlaneEntity);

    // If ClippingAssignmentComponent not created for the specified position and belonging to the specified entity then create it.
    if (0 > eidForClippingAssignment) {

        do eidForClippingAssignment = addEntity(world)
        while (!eidForClippingAssignment || 0 > eidForClippingAssignment);

        if (!positionClippingAssignmentEntityMap[eidForShowroomPosition]) {

            positionClippingAssignmentEntityMap[eidForShowroomPosition] = {}
        }

        positionClippingAssignmentEntityMap[eidForShowroomPosition][eidForClippingPlaneEntity] = eidForClippingAssignment;
        addComponent(world, ClippingAssignmentComponent, eidForClippingAssignment);
    }

    // Update the Clipping Assignment data
    ClippingAssignmentComponent.position.x[eidForClippingAssignment] = clippingAssignment.position[0];
    ClippingAssignmentComponent.position.y[eidForClippingAssignment] = clippingAssignment.position[1];
    ClippingAssignmentComponent.rotationZ[eidForClippingAssignment] = clippingAssignment.rotation;
    ClippingAssignmentComponent.scale.x[eidForClippingAssignment] = clippingAssignment.scale[0];
    ClippingAssignmentComponent.scale.y[eidForClippingAssignment] = clippingAssignment.scale[1];
}