import { HorizontalMask, VerticalMask } from "./image-mask";
import { ClippingPlaneAssignment, PositionAdjustment } from "projects/my-common/src/model";
import { Vector3Obj, vector3ObjFromTuple, vector3ZeroObj } from "../util/utils";
import { getLogger } from "../util/log";
import { deepCopy } from "projects/mp-core/src/lib/util";
import { TextureManager } from "../my-three/texture-manager";
import { PLANE_RENDERER_DEFAUT_RENDER_ORDER } from "../component/plane-renderer.component";
//import { Canvg } from 'canvg';

/**
 * The data required to apply adjustments to Image and Video Props based upon the players Position.
 */
export class PropAdjustment {

    playerPositionId = '';
    hide = false;
    positionAdjustment = vector3ZeroObj as Vector3Obj;
    rotateAdjustment = vector3ZeroObj as Vector3Obj;
    scaleAdjustment = vector3ZeroObj as Vector3Obj;
    disableDepth = false;
    renderOrder = PLANE_RENDERER_DEFAUT_RENDER_ORDER
    stencilRef = 1;
    clippingAssignments: ClippingPlaneAssignment[] = []


    protected readonly _logger = getLogger();

    protected _horizontalMask: HorizontalMask = new HorizontalMask();
    public get horizontalMask(): HorizontalMask {
        return this._horizontalMask;
    }
    public set horizontalMask(v: HorizontalMask) {
        if (!this._horizontalMask.equals(v)) {
            this._horizontalMask = v;
        }
    }

    protected _verticalMask: VerticalMask = new VerticalMask();
    public get verticalMask(): VerticalMask {
        return this._verticalMask;
    }
    public set verticalMask(v: VerticalMask) {
        if (!this._verticalMask.equals(v)) {
            this._verticalMask = v;
        }
    }

    maskUrl = '';

    
    constructor(protected readonly textureManager: TextureManager,
        horizontalMask: HorizontalMask = new HorizontalMask(),
        verticalMask: VerticalMask = new VerticalMask()) {

        if (!textureManager || !textureManager.three) throw new Error('TextureManager is undefined');

        this._horizontalMask = horizontalMask;
        this._verticalMask = verticalMask;
    }


    clone(): PropAdjustment {

        const adjustment = new PropAdjustment(this.textureManager);
        adjustment.clippingAssignments = 0 < this.clippingAssignments.length ? deepCopy(this.clippingAssignments) : [];
        adjustment.disableDepth = this.disableDepth;
        adjustment.hide = this.hide;
        adjustment.horizontalMask.copy(this.horizontalMask);
        adjustment.playerPositionId = this.playerPositionId;
        adjustment.positionAdjustment = deepCopy(this.positionAdjustment);
        adjustment.renderOrder = this.renderOrder;
        adjustment.rotateAdjustment = deepCopy(this.rotateAdjustment);
        adjustment.scaleAdjustment = deepCopy(this.scaleAdjustment);
        adjustment.stencilRef = this.stencilRef;
        adjustment.verticalMask.copy(this.verticalMask);

        return adjustment;
    }


    fromPositionAdjustment(adjustment: PositionAdjustment): void {

        this.clippingAssignments = 0 < adjustment.clippingAssignments.length ? deepCopy(adjustment.clippingAssignments) : [];
        this.hide = adjustment.hide;
        this.disableDepth = adjustment.disableDepth;
        this._horizontalMask.mode = adjustment.horizontalMaskMode;
        this._horizontalMask.offset = adjustment.horizontalMaskXOffset;
        this._horizontalMask.width = adjustment.horizontalMaskWidth;
        this._verticalMask.mode = adjustment.verticalMaskMode;
        this._verticalMask.height = adjustment.verticalMaskHeight;
        this._verticalMask.offset = adjustment.verticalMaskYOffset;
        this.playerPositionId = adjustment.positionId;
        this.positionAdjustment = vector3ObjFromTuple(adjustment.adjust);
        this.renderOrder = adjustment.renderOrder;
        this.rotateAdjustment = vector3ObjFromTuple(adjustment.rotate);
        this.scaleAdjustment = vector3ObjFromTuple(adjustment.scale);
        this.stencilRef = adjustment.stencilRef;

        this.maskUrl = adjustment.maskUrl;
    }

}
