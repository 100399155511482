<div class="flex flex-col space-y-4">
    <div class="flex flex-row space-x-4">
        <select (change)="handlePropInteractionModeChanged($event)" [disabled]="!canWrite()"
            class="block w-full mt-1 text-black rounded-md focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-70">

            @for (propInteractionMode of propInteractionModes(); track $index) {

            <option [selected]="propInteractionMode === currentPropInteractionMode()">
                {{ propInteractionMode }}
            </option>

            }

        </select>
    </div>
</div>