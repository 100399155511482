import { Component, Input, WritableSignal, input, signal } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { timeout } from 'projects/my-common/src';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {

  id = crypto.randomUUID();

  progress = signal(0);
  @Input({ required: true }) set Progress(value: number) {

    if (0 > value) {

      value = 0;
    }
    if (100 < value) {

      value = 100;
    }

    this.updateProgress(value, this.progress)
  }

  EaseToProgress = input(0);

  private _lock = 0;
  private _lastReportedProgress = 0;
  private _currentProgress = 0;


  constructor(private readonly logger: NGXLogger) { }


  /**
   * Smooth out the transitions in upload progress.  Allow time for server to process upload and respond.
   * @param progress 
   * @param targetSignal 
   * @returns 
   */
  private async updateProgress(progress: number, targetSignal: WritableSignal<number>): Promise<void> {

    this._lastReportedProgress = progress;
    if (0 < this._lock++) {

      return;
    }

    // Animate progress to the specified value.
    while (this._currentProgress < this._lastReportedProgress) {

      this._currentProgress++;
      targetSignal.set(this._currentProgress);
      await timeout(this.EaseToProgress());
    }

    this._lock = 0;
    // If progress not set at 100% yet then set new target and continue progress.
    // if (this._lastReportedProgress < 100) {

    //   this.updateProgress(Math.min(this._lastReportedProgress + 10, 100), targetSignal);
    // }
  }

}
