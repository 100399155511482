<div #configureImagePropSidebarComponent tabindex="0"
    class="fixed h-screen flex flex-col overflow-hidden top-0 w-full z-50 md:max-w-[500px] bg-[#0e0f10] shadow-xl 
            {{ isOpen() ? 'right-0' : '-right-full' }} transition-all duration-300 px-4 bg-opacity-80 border-l-[1px] border-accent" (blur)="onBlur()">
    <div class="flex flex-row justify-between items-center pt-3">
        <div class="flex flex-row w-full">
            <!-- close icon -->
            <div (click)="onClose()"
                class="text-4xl w-11 flex justify-start items-center cursor-pointer transition">
                <ng-icon name="bootstrapXLg" 
                    class="text-3xl transition {{ closing() ? 'rotate-[2turn]' : 'rotate-0' }}" />
            </div>
            <div class="flex flex-row w-full group relative">
                <div
                    class="text-accent text-xl font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap w-full">
                    {{imageProp().name}}
                </div>
                <!-- Tooltip -->
                <span
                    class="invisible group-hover:visible md:flex group-hover:opacity-100 transition-opacity bg-gray-800 px-2 border-[1px] border-white text-sm text-gray-100 rounded-md absolute opacity-0 whitespace-nowrap top-1/2 -translate-y-1/2 right-0">
                    Design Id: {{eventDesign().id}}, Prop Id: {{imageProp().id}}
                </span>
            </div>
        </div>
    </div>


    @if (isLocked() && !showroomUser().isShowroomAdmin) {

    <div>Locked by administrator</div>

    } @else {
        
    @if (showroomUser().isShowroomAdmin) {
    
    <div class="flex flex-row justify-between">
        <div>{{ isLockedInDefault() && !isConfiguringDefault() ? 'Locked in default' : '' }}</div>
        <app-checkbox Label="Lock prop" [Checked]="isLocked()"
            (Toggled)="onToggleLockProp()" [IsReadOnly]="isLockedInDefault() && !isConfiguringDefault()" />
    </div>

    }

    <div class="flex-1 flex overflow-hidden">
        <!-- https://stackoverflow.com/questions/69400560/how-to-change-scrollbar-when-using-tailwind-next-js-react -->
        <!-- https://stackoverflow.com/questions/70539607/how-to-make-scrollable-sidebar-fixed-in-tailwindcss -->
        <div [id]="id" 
            class="flex-1 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:[width:15px] [&::-webkit-scrollbar-thumb]:bg-accent">
            <!-- The following padding seems to adjust for stuff at top of sidebar. If scrollable area matched heigh of sidebar, don't think it would be needed -->
            <div class="flex flex-col space-y-3 pb-36 mr-4 pt-2">

                @if (0 < imageAssignmentsForProp().length) { 
                    
                <div class="w-full flex flex-row justify-between items-center">
                    <div class="w-1/2">Background color</div>
                    <input [style.background]="localImagePropOptions().backgroundColor"
                        [(colorPicker)]="localImagePropOptions().backgroundColor" (colorPickerClose)="onSelectBackgroundColor($event)"
                        (cpInputChange)="onSelectBackgroundColor($event.color)"
                        (cpSliderDragStart)="onSelectBackgroundColor($event.color)"
                        (cpSliderDragEnd)="onSelectBackgroundColor($event.color)" cpOutputFormat="hex" cpAlphaChannel="always"
                        cpFallbackColor="#000000FF" />
                </div>

                }

                @if (1 < imageAssignmentsForProp().length) { 
                    
                    <div class="w-full flex flex-row items-center">
                    
                        <div class="w-1/2">Controls</div>
                    
                        <select (change)="handleSelectControlsAlignment($event)"
                            class="block w-1/2 text-black rounded-md focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        
                            @for (alignment of controlAlignment; track $index) {

                                <option [selected]="alignment === acticonPosition[localImagePropOptions().controlsPosition]">                            
                                    {{camelCaseToSpaced(alignment)}}                        
                                </option>
                        
                            }
                    
                        </select>            
                
                    </div>

                    <div class="w-full flex flex-row justify-between items-center">
                        <div class="w-1/2">Interaction distance (meters)</div>
                        <input type="number" value="{{ interactionDistanceInitializer() | number : '1.1-2' | noComma }}"
                            step="0.01" (blur)="handleBlurInteractionDistance()" (input)="handleInteractionDistanceInput($event)"
                            class="w-1/2 text-right block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary">
                    </div>

                    <div class="w-full flex flex-row items-center">
                        <div class="w-1/2">Margin (meters)</div>
                        <input type="number" value="{{ marginInitializer() | number : '1.1-4' | noComma }}" step="any"
                            (blur)="handleBlurMargin()" (input)="handleControlsMarginInput($event)"
                            class="w-1/2 text-right block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary">
                    </div>

                    <div class="w-full flex flex-row items-center">
                        <div class="w-1/2">Depth (meters)</div>
                        <input type="number" value="{{ depthInitializer() | number : '1.1-4' | noComma }}" step="any"
                            (blur)="handleBlurDepth()" (input)="handleControlsDepthInput($event)"
                            class="w-1/2 text-right block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary">
                    </div>

                }

                @if (enableSave()) {

                    <div class="flex flex-row justify-end">
                        <div class="flex flex-row h-full items-end space-x-2">
                            <button (click)="onUndo()"
                                class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                                <span class="flex justify-center ">
                                    <ng-icon class="cursor-pointer" size="22" name="bootstrapArrowCounterclockwise" />
                                </span>
                            </button>
                            <button (click)="onSave()"
                                class="text-sm uppercase font-bold bg-green-700 rounded-lg h-[41px] w-[48px]">
                                <span class="flex justify-center ">
                                    <ng-icon class="cursor-pointer" size="22" name="bootstrapCheckLg" />
                                </span>
                            </button>
                        </div>
                    </div>

                }

                <!-- Upload/Import DesignImage buttons -->
                <div class="w-full flex flex-row items-center justify-between">
                    <div class="text-accent text-2xl font-semibold overflow-hidden">
                        Assignments (Aspect: {{imageProp().aspect}})
                    </div>
                    <div class="flex flex-row justify-center space-x-2">
                        <div class="cursor-pointer group relative">
                            <button (click)="onCreateAssignment()" class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                                <label class="flex justify-center">
                                    <ng-icon class="cursor-pointer" size="25" name="bootstrapPlusLg" />
                                </label>
                            </button>
                            <span
                                class="invisible group-hover:visible md:flex group-hover:opacity-100 transition-opacity bg-gray-800 px-2 border-[1px] border-white text-sm text-gray-100 rounded-md absolute opacity-0 whitespace-nowrap top-1/4 -left-[155px]">
                                Add assignment
                            </span>
                        </div>
                    </div>
                </div>

                @if (0 < uploadingFileName().length) { 
                    
                    <div class="w-full py-2 flex flex-col justify-center text-center items center">
                        <div class="pb-1">Uploading {{uploadingFileName()}}</div>
                        <app-progress-bar [Progress]="uploadingProgress()" [EaseToProgress]="1" />
                    </div>
        
                } 
                
                <!-- If no Image assignments -->
                @if (1 > imageAssignmentsForProp().length) {
        
                    <!-- Try default Image Assignments -->
                    @if (eventDesign().id !== defaultEventDesign().id && 0 < defaultImageAssignmentsForProp().length) { 
                        
                        @for (defaultAssignment of defaultImageAssignmentsForProp(); track $index; let first=$first) { 
                            
                            <app-configure-image-assignment [EnableSwapAssignments]="false" [ImageAssignment]="defaultAssignment"
                                [IsDefault]="true" [Stage]="Stage()" /> 
            
                        }
            
                    } @else {
                
                        <p class="mx-2 md:mx-0 mt-1 md:mt-2 text-lg text-gray-400">
                            No image assignments ...
                        </p>
            
                    }
            
                }

                <!-- Assigned DesignImages -->
                @for (assignment of imageAssignmentsForProp(); track $index; let first = $first) {
            
                    <app-configure-image-assignment [EnableSwapAssignments]="!$first" [ImageAssignment]="assignment"
                                [IsDefault]="false" [Stage]="Stage()" (ScrollToPosition)="onScrollToPosition($event)"/> 
        
                }
    
            </div>
        </div>
    </div>
   
    }

</div>