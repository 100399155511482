import { Component, Input, Signal, computed, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapDash, bootstrapPlusLg, bootstrapX } from "@ng-icons/bootstrap-icons";
import { Router } from '@angular/router';
import { QuantityButtonsComponent } from "../quantity-buttons/quantity-buttons.component";
import { QuantitySelectComponent } from "../quantity-select/quantity-select.component";
import { IShowroomCartItem, ShowroomItemType } from 'src/app/core/model/cart.model';
import { NGXLogger } from 'ngx-logger';

export interface IChangeQuantityEvent {
  itemIndex: number;
  newQuantity: number;
}

@Component({
    selector: 'app-showroom-cart-item',
    standalone: true,
    providers: [provideIcons({ bootstrapDash, bootstrapPlusLg, bootstrapX })],
    templateUrl: './showroom-cart-item.component.html',
    styleUrls: ['./showroom-cart-item.component.scss'],
    imports: [CommonModule, NgIconComponent, QuantityButtonsComponent, QuantitySelectComponent]
})
export class ShowroomCartItemComponent {

  readonly isMobile = (/Mobile/i.test(navigator.userAgent));
  
  readonly item = signal(<IShowroomCartItem>{});
  @Input() set product(value: IShowroomCartItem) {

    this._logger.trace(value);
    this.item.set(value);
  }
  get product(): IShowroomCartItem { return this.item() }
  
  index = input(0);
  onRemoveItem = output<number>();
  onDecreaseQuantity = output<number>();
  onIncreaseQuantity = output<number>();
  onChangeQuantity = output<IChangeQuantityEvent>();

  readonly subTotal: Signal<number> = computed(() => {
    return this.item().price;
  });

  showroomItemType = ShowroomItemType;


  constructor(private readonly _router: Router,
      private readonly _logger: NGXLogger
  ) { }


  changeQuantity(newQuantity: number) {
    this.onChangeQuantity.emit({
      itemIndex: this.index(),
      newQuantity: newQuantity
    })
  }


  decreaseQuantity() { 
    this.onDecreaseQuantity.emit(this.index());
  }


  displayItemDetails() {  
         
    //this._router.navigate([`/product-details/${this.item().productId}`]); 
  }


  increaseQuantity() {
    this.onIncreaseQuantity.emit(this.index());
  }


  removeItem() {
    this.onRemoveItem.emit(this.index());
  }


}
