@if (visible()) {
<!-- #accountMenu and tabindex support the auto close on blur -->
<div #accountMenu tabindex="0"
    class="bg-black w-80 absolute z-50 top-20 right-0 p-6 border-2 border-gray-800 rounded-md"
    (blur)="handleBlur($event)">
    <div class="flex flex-col gap-y-8">
        <div (click)="editProfile()" class="px-3 group/item flex flex-row gap-3 w-full opacity-50">
            <p class="text-sm group-hover/item:underline">
                {{account.subscriptionUser().emails}}
            </p>
        </div>
        @for (a of account.subscriptionUser().subscribedAccounts; track $index) {

        @if (a.id === account.subscriptionAccount().id) {

            <div (click)="onViewAccount(a.accountNumber)" class="p-5 text-sm hover:underline bg-slate-800 rounded-md">
                Account {{a.accountNumber}}
            </div>

        } @else {

            <div (click)="onViewAccount(a.accountNumber)" class="px-3 text-sm hover:underline">
                Account {{a.accountNumber}}
            </div>

        }
        
        }
        <!-- <div routerLink="orders" class="px-3 text-sm hover:underline">
            Orders
        </div> -->
        <div class="bg-white w-full h-[1px]"></div>
        <div (click)="logout()" class="px-3 text-sm hover:underline">
            Sign out
        </div>
    </div>
</div>
}