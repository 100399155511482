<div (click)="openCartSidebar()"
    class="text-gray-200 hover:text-gray-300 cursor-pointer transition relative inline-flex w-fit">

    @if (0 < badge()) { 

        <span class="absolute bottom-auto left-auto -right-2 -top-2 h-5 w-5">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span class="absolute inline-flex rounded-full h-5 w-5 items-center justify-center bg-sky-500 text-xs">{{badge()}}</span>
        </span>
    
    }
    
    <ng-icon class="text-white transition" size="30" name="bootstrapCart3" />
</div>