import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import { GLTFInstance } from "../my-three/GLTFInstance";
import { AnimationAction, AnimationMixer, Box3, Group, Material, Matrix4, Mesh, MeshBasicMaterial, Object3D, Object3DEventMap, Texture, Vector3 } from "three";
import { AnimationType } from "../scene/animation";
import { MaterialData } from "./material-data-loader.component";
import { Vector3Obj, vector3OneObj, vector3ZeroObj } from "../util/utils";
import { applyMaterial, disposeScene } from "../my-three/utils";
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';
import { ShowroomMode } from "src/app/state/showroom-state";
import * as THREE from 'three';

export enum ObjectPropMode { UNASSIGNED = "Unassigned", ASSIGNED = "Assigned", CONFIGURATION = "Stage Administration" }

export type ObjectPropState = {

    alphaMap?: Texture
    disposeDistance: number
    enableInteraction: boolean
    gltf?: GLTFInstance
    materialData: MaterialData[]
    opacity: number
    otherAnimations: AnimationType
    /**
     * For use in calculating interaction distance.
     */
    playerPosition: Vector3
    position: Vector3
    rotation: Vector3Obj
    scale: Vector3Obj
    transparent: boolean
    visible: boolean
    showroomMode: ShowroomMode
}


/**
 * Host and manage the three gltf representation of a Showroom Object Prop.
 */
export class ObjectPropComponent extends MyOptyxComponent {

    protected override state: ObjectPropState = {

        alphaMap: undefined,
        disposeDistance: 50,
        enableInteraction: true,
        gltf: undefined,
        opacity: 1,
        otherAnimations: AnimationType.NONE,
        materialData: [],
        playerPosition: new Vector3(),
        position: new Vector3(),
        rotation: vector3ZeroObj,
        scale: vector3OneObj,
        showroomMode: ShowroomMode.SHOWROOM,
        transparent: true,
        visible: true
    };

    override pendingState: ObjectPropState = {

        alphaMap: undefined,
        disposeDistance: 50,
        enableInteraction: true,
        gltf: undefined,
        opacity: 1,
        otherAnimations: AnimationType.NONE,
        materialData: [],
        playerPosition: new Vector3(),
        position: new Vector3(),
        rotation: vector3ZeroObj,
        scale: vector3OneObj,
        showroomMode: ShowroomMode.SHOWROOM,
        transparent: true,
        visible: true
    };

    private _animationAction?: AnimationAction;
    private _animationMixer?: AnimationMixer;
    private _appliedMaterialData: MaterialData[] = [];
    private _currentMode = ObjectPropMode.UNASSIGNED;
    private _gltf?: GLTFInstance;
    private _logger = getLogger();
    private _objectGroup!: Group;
    private _scene?: Object3D;

    //
    // Events
    //
    private readonly _animationUpdated = new Subject<AnimationMixer>();
    readonly animationUpdated$ = this._animationUpdated.asObservable();
    private readonly _gltfUpdated = new Subject<Object3D<Object3DEventMap> | undefined>();
    readonly gltfUpdated$ = this._gltfUpdated.asObservable();
    // private readonly _viewSelectionBoxUpdated = new Subject<boolean>();
    // readonly viewSelectionBoxUpdated$ = this._viewSelectionBoxUpdated.asObservable();
    private readonly _enableInteractionUpdated = new Subject<boolean>();
    readonly enableInteractionUpdated$ = this._enableInteractionUpdated.asObservable();


    constructor(destroyRef: DestroyRef,
        private readonly three: typeof THREE) {
        super(destroyRef);
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {

        const enableInteraction = this.pendingState.enableInteraction
            || ShowroomMode.CONFIGURATION === this.pendingState.showroomMode
            || ShowroomMode.STAGE === this.pendingState.showroomMode;

        this._enableInteractionUpdated.next(enableInteraction);

        const playerDistanceFromObject = (this.pendingState.position && this.pendingState.playerPosition) ?
            this.pendingState.position.distanceTo(this.pendingState.playerPosition) : Number.MAX_VALUE;

        // If the object is beyond the specified dispose distance then remove it from the group.
        // TODO - We need tighter, direct integration with ECS because it has references to objects.
        if (playerDistanceFromObject > this.pendingState.disposeDistance) {

            this.disposeAnimations();
            this.disposeGltf();
            return;
        }

        // Disposing or configuring objects is handled by setMode
        if (!this.pendingState.gltf) {

            this.setMode(ObjectPropMode.UNASSIGNED);
            if (this.state.gltf) {

                this._gltfUpdated.next(undefined);
            }
        } else if (this.state.showroomMode !== this.pendingState.showroomMode
            || this.pendingState.gltf !== this._gltf
            || this.pendingState.otherAnimations !== this.state.otherAnimations) {

            // Handle state change or object change. This recreates the Glb and should be debounced
            if (ShowroomMode.CONFIGURATION === this.pendingState.showroomMode
                || ShowroomMode.STAGE === this.pendingState.showroomMode) {

                this.setMode(ObjectPropMode.CONFIGURATION);
            } else {

                this.setMode(ObjectPropMode.ASSIGNED);
            }
        }

        if (this.pendingStateChanges.find(psc => psc === 'materialData')
            || this.state.materialData.length !== this.pendingState.materialData.length) {

            this.updateGltfMaterials();
        }

        this.updateGltfPositionAndScale();
    }


    private configureAnimations() {

        const state = this.pendingState;

        this.disposeAnimations();
        if (!this._scene || !this._gltf) {

            return;
        }
        if (this._gltf.animations && 0 < this._gltf.animations.length) {

            this._animationMixer = new AnimationMixer(this._scene);
            //
            // Choosing animation 0 to play. 
            // TODO: Create Animation Controller logic to switch animations dynamically.
            //
            this._animationAction = this._animationMixer.clipAction(this._gltf.animations[0]);
            this._animationAction.reset();
            this._animationAction.play();
            this._animationUpdated.next(this._animationMixer);
        }
    }


    private _gltfSize = new Vector3();
    private _boundingSphere?: Mesh;
    configureGltf(state: ObjectPropState) {

        // If the object is unchanged then there is nothing to do
        if (this._gltf === state.gltf) {

            return;
        }

        // Something has changed so clean up in preparation for what's next
        this.disposeAnimations();
        this.disposeGltf();

        // If we no longer have an object then broadcast that and we're done
        if (!state.gltf) {

            this._gltfUpdated.next(undefined);
            return;
        }

        // Start tracking gltf for subsequent changes
        this._gltf = state.gltf;

        // We are responsible for cloning an instance of the gltf which may be used in multiple object props.
        // We manage the cloned lifecycle, materials and disposal.
        this._scene = SkeletonUtils.clone(this._gltf.scene);
        this._scene.traverse(function (node) {
            if ("castShadow" in node) {
                node.castShadow = true;
                node.receiveShadow = true;
            }
        });
        this.createBoundingSphere();

        this.updateGltfMaterials();

        this._objectGroup.add(this._scene);

        // The following logic allows the object to be rendered in front of Image Props which might be in front of it.
        // TODO: Make this behavior configurable by position adjustment.
        //this._scene.renderOrder = 999;
        //this._scene.onBeforeRender = function (renderer) { renderer.clearDepth(); };
        this._objectGroup.traverse((node) => {

            node.renderOrder = 999;
            //node.onBeforeRender = function (renderer) { renderer.clearDepth(); };
        });

        this._gltfUpdated.next(this._objectGroup);
    }


    /**
     * Create an invisible sphere at the center of the object to provide a minimal click surface.
     * @param state 
     * @returns 
     */
    private createBoundingSphere() {

        if (this._boundingSphere) {

            this._objectGroup.remove(this._boundingSphere);
            this._boundingSphere?.geometry.dispose();
            (this._boundingSphere?.material as Material)?.dispose();
        }

        if (!this._scene) {

            return;
        }

        const box3 = new Box3().setFromObject(this._scene);
        const dimensions = new Vector3().subVectors(box3.max, box3.min);
        const sphereGeo = new this.three.SphereGeometry(.28);
        const position = dimensions.addVectors(box3.min, box3.max).multiplyScalar(0.5);
        position.y = Math.max(0.9999, position.y);
        const matrix = new Matrix4().setPosition(position);
        sphereGeo.applyMatrix4(matrix);

        // Create invisible interaction hit box.
        this._boundingSphere = new this.three.Mesh(
            sphereGeo,
            new this.three.MeshBasicMaterial({
                color: 0xffcc55,
                opacity: 0.0,
                transparent: true,
                depthWrite: true
            }));

        this._objectGroup.add(this._boundingSphere);
    }


    getAnimationMixer(): AnimationMixer | undefined {

        return this._animationMixer;
    }


    getMaterialData(): MaterialData[] {

        return this.pendingState.materialData;
    }


    getObject(): Object3D | undefined {

        return this._scene;
    }


    getObjectGroup(): Object3D {

        return this._objectGroup;
    }


    override getState(): ObjectPropState {

        // Might want to deepCopy depending on properties.
        return this.state;
    }


    // If overriding be sure to call base method.
    override init(): ObjectPropComponent {
        super.init();

        this._objectGroup = new this.three.Group();
        // this._objectGroup.renderOrder = 999;
        // this._objectGroup.onBeforeRender = function (renderer) { renderer.clearDepth(); };
        return this;
    }


    private setMode(newMode: ObjectPropMode) {

        const state = this.pendingState;

        this._currentMode = newMode;
        switch (this._currentMode) {
            case ObjectPropMode.UNASSIGNED:
            case ObjectPropMode.ASSIGNED:
                //this._viewSelectionBoxUpdated.next(false);
                break;
            case ObjectPropMode.CONFIGURATION:
                //this._viewSelectionBoxUpdated.next(true);
                break;
        }

        this.configureGltf(state);
        this.configureAnimations();
    }


    private updateGltfMaterials(): void {

        if (!this._scene) {

            return;
        }

        let material: MaterialData;
        for (material of this.pendingState.materialData) {

            applyMaterial(material, this.three, this._scene);
        }

        // Dispose of any prior material definitions.
        for (let i = 0; i < this._appliedMaterialData.length; i++) {

            this._appliedMaterialData[i].alphaMap?.dispose();
            this._appliedMaterialData[i].map?.dispose();
        }

        this._appliedMaterialData = [...this.pendingState.materialData];
    }


    private updateGltfPositionAndScale(): void {

        if (this._scene) {

            this._scene.scale.set(this.pendingState.scale.x, this.pendingState.scale.y, this.pendingState.scale.z);
        }
        if (this._boundingSphere) {

            if (ShowroomMode.CONFIGURATION === this.pendingState.showroomMode
                || ShowroomMode.STAGE === this.pendingState.showroomMode) {

                (this._boundingSphere.material as MeshBasicMaterial).opacity = 0.3
            } else {

                (this._boundingSphere.material as MeshBasicMaterial).opacity = 0.0
            }
        }
    }


    //
    // Dispose stuff
    //

    private disposeAnimations(): void {

        if (this._animationMixer) {

            this._animationMixer.stopAllAction();
            if (this._animationAction) {

                this._animationAction.stop();
                this._animationMixer.uncacheAction(this._animationAction.getClip());
            }

            this._animationMixer.uncacheRoot(this._animationMixer.getRoot());
            this._animationMixer = undefined;
        }
    }


    private disposeGltf(): void {

        if (this._gltf) {

            this._gltf = undefined;
            if (this._scene) {

                this._objectGroup.remove(this._scene);
                const clonedSceneToDisposeOf = this._scene;
                this._scene = undefined;

                setTimeout(() => {
                    disposeScene(clonedSceneToDisposeOf);
                });
            }
        }
        if (this._boundingSphere) {

            this._objectGroup.remove(this._boundingSphere);
            this._boundingSphere.geometry.dispose();
            (this._boundingSphere.material as Material)?.dispose();
        }
    }


    override onDestroy(): void {

        this._objectGroup.clear();
        this.disposeAnimations();
        this.disposeGltf();
        for (let i = 0; i < this._appliedMaterialData.length; i++) {

            this._appliedMaterialData[i].alphaMap?.dispose();
            this._appliedMaterialData[i].map?.dispose();
        }
    }

}



// storeGlb() {
//   if (this.glb) {
//     const THREE = this.context.three;
//     const gltfExporter = new (THREE as any).GLTFExporter();
//     const that = this;

//     if (gltfExporter) {
//       gltfExporter.parse(
// 				this.glb,
// 				function (result: any) {
// 					if ( result instanceof ArrayBuffer ) {
// 						//saveArrayBuffer( result, 'scene.glb' );
// 					} else {
// 						const output = JSON.stringify( result, null, 2 );
// 						this._logger.trace( output );
//             localStorage.setItem(that.inputs.objectUrl, output)
// 						//saveString( output, 'scene.gltf' );
// 					}
// 				},
// 				function (error: any) {
// 					this._logger.trace( 'An error happened during parsing', error );
// 				},
// 				{
//           trs: false,
//           onlyVisible: true,
//           binary: false
//         }
// 			);
//     }
//   }
// }