import { AfterViewInit, Component, ElementRef, Input, OnDestroy, QueryList, ViewChildren, computed, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapArrowCounterclockwise, bootstrapArrowLeft, bootstrapArrowUp, bootstrapCheckLg, bootstrapTrash, bootstrapPlusLg, bootstrapXLg, bootstrapPen } from "@ng-icons/bootstrap-icons";
import { DesignVideo, EventDesign, IDesignVideo, IDesignVideoUpload, VideoAssignment, IVideoOrchestrationParent, IVideoProp, IVideoPropOptions, VideoPropOptions } from 'projects/my-common/src/model';
import { Vector3InputComponent } from "../../../../shared/vector3-input/vector3-input.component";
import { PositionAdjustmentComponent } from "../../../../shared/position-adjustment/position-adjustment.component";
import { Subscription } from 'rxjs/internal/Subscription';
import { NGXLogger } from 'ngx-logger';
import { IStage } from 'src/app/core/model/showroom/showroom.model';
import { VenueService } from 'src/app/core/service/venue/venue.service';
import { firstValueFrom } from 'rxjs';
import { FloatingSliderComponent } from 'src/app/components/shared/floating-slider/floating-slider.component';
import { deepCopy } from 'projects/mp-core/src/lib/util';
import { NumberInputScrollDirective } from 'src/app/shared/directives/number-input-scroll.directive';
import { debounce } from 'projects/my-common/src/util/utils';
import { IShowroomVideoProp } from 'src/app/core/model/showroom/video-prop.model';
import { DesignVideoService } from 'src/app/core/service/venue/design-video.service';
import { IMPORT_VIDEO_SIDEBAR_ID } from '../../import-video-sidebar/import-video-sidebar.component';
import { ActiconPosition } from 'projects/my-common/src';
import { ProgressBarComponent } from "../../../../shared/progress-bar/progress-bar.component";
import { CheckboxComponent } from "../../../../shared/checkbox/checkbox.component";
import { NoCommaPipe } from 'src/app/shared/pipes/no-comma.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { TextEditorComponent } from "../../../../shared/text-editor/text-editor.component";
import { VideoProp } from 'projects/my-common/src/model';
import { ModalService } from 'src/app/core/service/ui/modal/modal.service';
import { ALERT_MODAL_ID } from 'src/app/components/shared/alert-modal/alert-modal.component';

export const CONFIGURE_VIDEO_PROP_SIDEBAR_ID = '97e0c0ea-fd54-4ced-90c1-749e7e6d3bfa';

enum CurrentFocus { aspect = 'aspect' };

type DesignVideoAssignment = {
  designVideo: DesignVideo,
  videoAssignment: VideoAssignment
}


/**
 * Configure the design and interactions of the specified VideoProp in the specified EventDesign.
 * We don't delete DesignVideos because they might be referenced by other VideoProps.
 * To remove a DesignVideo we delete the VideoAssignment.
 */
@Component({
  selector: 'app-configure-video-prop-sidebar',
  standalone: true,
  templateUrl: './configure-video-prop-sidebar.component.html',
  styleUrls: ['./configure-video-prop-sidebar.component.scss'],
  providers: [provideIcons({ bootstrapArrowCounterclockwise, bootstrapArrowLeft, bootstrapArrowUp, bootstrapCheckLg, bootstrapPen, bootstrapPlusLg, bootstrapTrash, bootstrapXLg })],
  imports: [ColorPickerModule, CommonModule, FloatingSliderComponent, NgIconComponent, NoCommaPipe, NumberInputScrollDirective, Vector3InputComponent,
    PositionAdjustmentComponent, ProgressBarComponent, CheckboxComponent, TextEditorComponent]
})
export class ConfigureVideoPropSidebarComponent implements ISidebar, OnDestroy, AfterViewInit {

  private readonly _subscriptions: Subscription[] = [];
  private _lock = 0;
  readonly sidebarId: string = CONFIGURE_VIDEO_PROP_SIDEBAR_ID;
  readonly isOpen = signal(false);
  readonly closeClicked = signal(false);
  readonly enableSave = signal(false);
  readonly enableVideoAssignmentSave = signal(false);
  readonly audioDistanceInitializer = signal(0);
  readonly autoPlayDistanceInitializer = signal(0);
  readonly interactionDistanceInitializer = signal(0);

  readonly controlAlignment = Object.values(ActiconPosition).filter(value => typeof value !== 'number');
  acticonPosition = ActiconPosition;

  /**
   * Setup for slider close on blur when user clicks away from this component.
   */
  @ViewChildren('clientConfigureDesignImageSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  @ViewChildren('video') videos!: QueryList<ElementRef>;

  private _videoNode?: IShowroomVideoProp;
  readonly videoProp = signal(new VideoProp());
  @Input({ required: true }) set VideoProp(value: IVideoProp) {
    if (!value.id || 1 > value.id) {

      return;
    }

    this.enableVideoAssignmentSave.set(false)

    // New Design Videos with their own shaka instances will be created upon signal update.
    // Clear existing shaka attachments and release resources.
    this.designVideoAssignments().forEach((dva) => dva.designVideo.dispose());

    this.videoProp.set(new VideoProp(value));

    this.setVideoNode();
    this.setVideoPropOptions();
    this.initializeVideos();
  }

  readonly eventDesign = signal(new EventDesign());
  @Input({ required: true }) set EventDesign(value: EventDesign) {

    if (!value.id || 1 > value.id) {

      return;
    }

    this.enableVideoAssignmentSave.set(false)

    // New Design Videos with their own shaka instances will be created upon signal update.
    // Clear existing shaka attachments and release resources.
    this.designVideoAssignments().forEach((dva) => dva.designVideo.dispose());

    this.eventDesign.set(value);

    this.setVideoPropOptions();
    this.initializeVideos();
    this.loadOrchestrationParents();
  }


  /**
   * The DesignVideos in EventDesign that are assigned to ImageProp ordered by assignment id
   */
  readonly designVideoAssignments = computed(() =>
    this.eventDesign().videoAssignments.filter(va => va.videoPropId === this.videoProp().id)
      .sort((va1, va2) => va1.id < va2.id ? -1 : 1)
      .map((va) => <any>{
        designVideo: this.eventDesign().designVideos.find(dv => dv.id === va.designVideoId),
        videoAssignment: va
      })
      .filter((dva) => dva.designVideo)
      .map((dva) => <DesignVideoAssignment>{
        designVideo: new DesignVideo(dva.designVideo),
        videoAssignment: dva.videoAssignment
      })
  );


  readonly defaultEventDesign = signal(new EventDesign());
  @Input({ required: true }) set DefaultEventDesign(value: EventDesign) {
    // New Design Videos with their own shaka instances will be created upon signal update.
    // Clear existing shaka attachments and release resources.
    this.defaultVideoAssignments().forEach((dva) => dva.designVideo.dispose());

    this.defaultEventDesign.set(value);

    this.setVideoPropOptions();
    this.initializeVideos();
  }
  /**
   * The DesignVideos in Default EventDesign that are assigned to VideoProp, ordered by assignment id
   */
  readonly defaultVideoAssignments = computed(() =>
    this.defaultEventDesign().videoAssignments.filter(ia => ia.videoPropId === this.videoProp().id)
      .sort((va1, va2) => va1.id < va2.id ? -1 : 1)
      .map((va) => <DesignVideoAssignment>{
        designVideo: new DesignVideo(this.defaultEventDesign().designVideos.find(di => di.id === va.designVideoId)),
        videoAssignment: va
      })
      .filter((dva) => 0 < dva.designVideo.id)
  );

  readonly selectedDesignVideo = signal(new DesignVideo());
  private _originalVideoAssignment!: VideoAssignment;
  readonly selectedVideoAssignment = signal(new VideoAssignment());
  private _originalVideoPropOptions!: IVideoPropOptions;
  readonly videoPropOptions = signal(new VideoPropOptions());

  private _stage!: IStage;
  @Input({ required: true }) set Stage(value: IStage) {
    if (!value.playerPositionUpdate$) return;

    this._stage = value;
  }

  readonly orchestrationParents = signal(<IVideoOrchestrationParent[]>[])

  readonly EventDesignChanged = output<number>();

  readonly currentFocus = signal(CurrentFocus.aspect);
  readonly currentValue = signal(0);
  readonly openSlider = signal(false);

  color1: string = '#2889e9';
  public onEventLog(event: string, data: any): void {
    console.log(event, data);
  }


  constructor(private readonly designVideoService: DesignVideoService,
    private readonly logger: NGXLogger,
    private readonly modalService: ModalService,
    private readonly sidebarService: SidebarService,
    private readonly venueService: VenueService) {

    sidebarService.add(this);
    this.monitorImportVideoRequests();
  }


  camelCaseToSpaced(camelCase: any): string {

    return camelCase.replace(/([a-z])([A-Z])/g, '$1 $2');
  }


  uploadingProgress = signal(0);
  uploadingFileName = signal('');
  /**
   * Handle Design Image creation here. 
   * Emit create Image Assignment to invoke the data refresh.
   * @param event 
   * @returns 
   */
  async addDesignVideo(event: any): Promise<void> {

    if (!event || 0 < this._lock++) {
      
      return;
    }

    const file = event.target.files[0];
    var sFileName = file.name;

    let designVideo = this.eventDesign().designVideos.find(dv => dv.uploadFileName === sFileName);
    if (designVideo) {

      this._lock = 0;
      this.importDesignVideo(designVideo);
      return;
    }

    var iFileSize = file.size;
    var iConvert = (file.size / 1048578).toFixed(2);

    if (iFileSize > 160000000) {

      let txt = `File size: ${iConvert} MB. Please make sure your video file is less than 150 MB.`;
      this.modalService.setTarget(ALERT_MODAL_ID, txt);
      this.modalService.open(ALERT_MODAL_ID);
      
      this._lock = 0;
      return;
    }

    // When the upload it 100% complete the server is still copying the file to blob storage before responding.
    this.uploadingProgress.set(0);
    this.uploadingFileName.set(sFileName);
    designVideo = await firstValueFrom(this.venueService.createDesignVideo(<IDesignVideoUpload>{
      id: 0,
      eventDesignId: this.eventDesign().id,
      file: file
    },
      (percentDone: number) => this.uploadingProgress.set(percentDone)
    ));
    this.uploadingFileName.set('');
    this.uploadingProgress.set(0);

    const assignment = new VideoAssignment();
    assignment.name = this.videoProp().name;
    assignment.designVideoId = designVideo.id;
    assignment.eventDesignId = designVideo.eventDesignId;
    assignment.videoPropId = this.videoProp().id;

    await firstValueFrom(this.venueService.createVideoAssignment(assignment));

    this._lock = 0;
    this.EventDesignChanged.emit(this.eventDesign().id);
  }


  private applyVideoPropOptions() {

    if (!this._stage) {

      return;
    }

    const videoPropNode = this._stage.getVideoNode(this.videoProp().id);
    videoPropNode?.setOptions(this.videoPropOptions());
  }


  private detectChanges(): boolean {

    const videoPropOptions = this.videoPropOptions();

    return videoPropOptions.controlsMargin !== this._originalVideoPropOptions.controlsMargin
      || videoPropOptions.controlsPosition !== this._originalVideoPropOptions.controlsPosition
      || videoPropOptions.controlsZ !== this._originalVideoPropOptions.controlsZ
      || videoPropOptions.autoPlay !== this._originalVideoPropOptions.autoPlay
      || videoPropOptions.loop !== this._originalVideoPropOptions.loop
      || videoPropOptions.audioDistance !== this._originalVideoPropOptions.audioDistance
      || videoPropOptions.autoPlayDistance !== this._originalVideoPropOptions.autoPlayDistance
      || videoPropOptions.interactionDistance !== this._originalVideoPropOptions.interactionDistance
      || videoPropOptions.isOrchestrationParent !== this._originalVideoPropOptions.isOrchestrationParent
      || videoPropOptions.orchestrationParentId !== this._originalVideoPropOptions.orchestrationParentId
      || videoPropOptions.backgroundColor !== this._originalVideoPropOptions.backgroundColor;
  }


  private detectVideoAssignmentChanges(): boolean {

    const videoAssignment = this.selectedVideoAssignment();
    if (1 > videoAssignment.id) {

      return false;
    }

    return videoAssignment.name !== this._originalVideoAssignment.name
      || videoAssignment.enableInteraction !== this._originalVideoAssignment.enableInteraction
      || videoAssignment.suppressSidebarVideo !== this._originalVideoAssignment.suppressSidebarVideo;
  }


  private _handlingBlur = false;
  private readonly handleBlurCallback = this.handleBlur.bind(this);
  /**
   * Auto close is a little annoying on highly interactive, configuration type slide outs.
   */
  handleBlur() {

    // if (this.enableSave() || this.enableDesignImageSave() || this._handlingBlur) {

    //   return
    // };
    // this._handlingBlur = true;

    // const elementRef = (this.elementRef.first as any).nativeElement;
    // if (!elementRef) {
    //   this._handlingBlur = false;
    //   return;
    // }

    // // Timeout give cycle for focusIn/Out combination to complete
    // setTimeout(() => {
    //   if (!isChildElement(elementRef, document.activeElement)) {
    //     this.isOpen.set(false);
    //   }
    //   this._handlingBlur = false;
    // }, 1);
  }


  handleBlurAudioDistance() {

    this.audioDistanceInitializer.set(this.videoPropOptions().audioDistance);
  }


  handleAudioDistanceInput(audioDistanceInputEvent: any) {

    const newAudioDistance = Number(audioDistanceInputEvent.target.value);

    this.videoPropOptions.update(vpo => {

      vpo.audioDistance = newAudioDistance
      return vpo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  handleBlurAutoPlayDistance() {

    this.audioDistanceInitializer.set(this.videoPropOptions().audioDistance);
  }


  handleBlurInteractionDistance() {

    this.interactionDistanceInitializer.set(this.videoPropOptions().interactionDistance);
  }


  handleAutoPlayDistanceInput(autoPlayDistanceInputEvent: any) {

    const newAutoPlayDistance = Number(autoPlayDistanceInputEvent.target.value);

    this.videoPropOptions.update(vpo => {

      vpo.autoPlayDistance = newAutoPlayDistance
      return vpo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  /**
   * Should we warn of unsaved changes before closing?
   * @returns 
   */
  handleClose() {

    if (this.detectVideoAssignmentChanges()) {

      return;
    }
    this.closeClicked.set(true);
    setTimeout(() => {
      this.isOpen.set(false);
      this.closeClicked.set(false);
    }, 200);
  }


  private readonly _debounceDepthInput = debounce((newDepthValue: number) =>
    this.setControlsDepth(newDepthValue)
    , 900);
  handleControlsDepthInput(event: any): void {

    const value = Number(event.target.value);
    this._debounceDepthInput[0](value);
  }


  private readonly _debounceMarginInput = debounce((newMarginValue: number) =>
    this.setControlsMargin(newMarginValue)
    , 900);
  handleControlsMarginInput(event: any): void {

    const value = Number(event.target.value);
    this._debounceMarginInput[0](value);
  }


  handleImportDesignVideo() {

    this.designVideoService.setEventDesign(this.eventDesign(), true);
    this.sidebarService.open(IMPORT_VIDEO_SIDEBAR_ID, false);
  }


  handleInteractionDistanceInput(interactionDistanceInputEvent: any) {

    const newInteractionDistance = Number(interactionDistanceInputEvent.target.value);

    this.videoPropOptions.update(vpo => {

      vpo.interactionDistance = newInteractionDistance
      return vpo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  async handleSwapVideoAssignments(event: any, ia1Index: number, ia2Index: number) {

    event.stopPropagation();
    this.enableSave.set(false);

    // New Design Videos with their own shaka instances will be created upon signal update.
    // Clear existing shaka attachments and release resources.
    this.designVideoAssignments().forEach((dva) => dva.designVideo.dispose());

    const swappedAssignments = await firstValueFrom(this.venueService.swapVideoAssignments([
      this.designVideoAssignments()[ia1Index].videoAssignment,
      this.designVideoAssignments()[ia2Index].videoAssignment
    ]));

    this.EventDesignChanged.emit(this.eventDesign().id);
  }


  handleNameInput(nameEvent: any): void {

    const newName = nameEvent.target.value;

    this.selectedVideoAssignment.update(va => {

      va.name = newName;

      return new VideoAssignment(va);
    });

    // Server changes upon Save clicked
    this.enableVideoAssignmentSave.set(this.detectVideoAssignmentChanges());
  }


  handleVideoAssignmentDescriptionInput(newDescription: string) {

    this.selectedVideoAssignment.update(ia => {

      ia.description = newDescription;
      return ia;
    });

    // Server changes upon Save clicked
    this.enableVideoAssignmentSave.set(this.detectVideoAssignmentChanges());
  }


  async handleVideoAssignmentSave(): Promise<void> {

    this.enableVideoAssignmentSave.set(false);
    this.selectedDesignVideo.set(new DesignVideo());

    await firstValueFrom(this.venueService.updateVideoAssignment(this.selectedVideoAssignment()));
    this.selectedVideoAssignment.set(<VideoAssignment>{ id: 0 });

    this.EventDesignChanged.emit(this.eventDesign().id);
  }


  handleVideoAssignmentUndo() {

    this.enableVideoAssignmentSave.set(false);

    this.selectedDesignVideo.set(new DesignVideo());
    this.selectedVideoAssignment.set(<VideoAssignment>{ id: 0 });
  }


  async handleRemoveVideoAssignment(event: any, designVideoAssignment: DesignVideoAssignment): Promise<void> {

    // Prevent button click from passing to underlying div which selects the DesignVideo and Assignment.
    event.stopPropagation();
    this.handleVideoAssignmentUndo();

    await firstValueFrom(this.venueService.deleteVideoAssignment(designVideoAssignment.videoAssignment));
    this.EventDesignChanged.emit(this.eventDesign().id);
  }


  handleSelectControlsAlignment(controlsAlignmentEvent: any): void {

    let selectedIndex = Number(controlsAlignmentEvent.target['options'].selectedIndex);
    this.logger.trace(`selected: ${ActiconPosition[selectedIndex]}`);

    this.videoPropOptions.update(ipo => {

      ipo.controlsPosition = selectedIndex;
      return ipo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  /**
   * Import and assign the specified DesignVideo to this Prop.
   * @param designVideo 
   */
  private async importDesignVideo(designVideo: IDesignVideo) {

    // If the Design Image FileName exists in the current Event Design then try to assign it
    const existingDesignVideo = this.eventDesign().designVideos.find(dv => dv.fileName === designVideo.fileName);
    if (existingDesignVideo) {

      this.importDesignVideoFromThisEventDesign(existingDesignVideo);
      return;
    }

    const newDesignVideo = await firstValueFrom(this.venueService.importDesignVideo(designVideo.id, this.eventDesign().id));
    const assignment = new VideoAssignment();
    assignment.name = this.videoProp().name;
    assignment.designVideoId = newDesignVideo.id;
    assignment.eventDesignId = newDesignVideo.eventDesignId;
    assignment.videoPropId = this.videoProp().id;

    await firstValueFrom(this.venueService.createVideoAssignment(assignment));

    this.EventDesignChanged.emit(this.eventDesign().id);
    this.selectedDesignVideo.set(new DesignVideo(newDesignVideo));
  }


  private async importDesignVideoFromThisEventDesign(existingDesignVideo: IDesignVideo): Promise<void> {

    // If matching designImage is already assigned to this Prop then there's nothing to do.
    const existingVideoAssignment = this.eventDesign().videoAssignments
      .find(va => va.designVideoId === existingDesignVideo.id && va.videoPropId === this.videoProp().id);
    if (existingVideoAssignment) {

      return;
    }

    const assignment = new VideoAssignment()
    assignment.name = this.videoProp().name;
    assignment.designVideoId = existingDesignVideo.id;
    assignment.eventDesignId = existingDesignVideo.eventDesignId;
    assignment.videoPropId = this.videoProp().id;

    await firstValueFrom(this.venueService.createVideoAssignment(assignment));

    this.EventDesignChanged.emit(this.eventDesign().id);
    this.selectedDesignVideo.set(new DesignVideo(existingDesignVideo));
  }


  async initializeVideos(): Promise<void> {

    // LENGTHS EVALUATE TO NOT EQUAL EVENT WHEN THEY ARE EQUAL !!!
    if (!this.videos || 1 > this.videos.length) { // || this.videos.length !== this.designVideoAssignments.length) {

      this.logger.trace('No video elements to bind to', this.designVideoAssignments(), this.defaultVideoAssignments())
      return;
    }

    let designVideoAssignments = this.designVideoAssignments();
    if (1 > this.designVideoAssignments().length) {

      this.logger.trace('Binding to default video assignments');
      designVideoAssignments = this.defaultVideoAssignments()
    }

    for (let i = 0; i < designVideoAssignments.length; i++) {

      const video = this.videos.get(i);
      if (video) {

        this.logger.trace(`Setting video source for: video${designVideoAssignments[i].designVideo.id}`, video.nativeElement, designVideoAssignments[i]);
        await designVideoAssignments[i].designVideo.setVideoSource(video.nativeElement as HTMLVideoElement);
      }
    }
  }


  private async loadOrchestrationParents(): Promise<void> {

    if (1 > this.eventDesign().id) {

      this.orchestrationParents.set([]);
      return;
    }

    const orchestrationParents = await firstValueFrom(this.venueService.getVideoOrchestrationParents(this.eventDesign().id));
    this.orchestrationParents.set(orchestrationParents);
  }


  private monitorImportVideoRequests() {

    this._subscriptions.push(
      this.designVideoService.importVideo$
        .subscribe((designVideo: IDesignVideo) => {

          this.logger.trace(`import design video`, designVideo);
          this.sidebarService.close(IMPORT_VIDEO_SIDEBAR_ID);
          this.importDesignVideo(designVideo);
        })
    );
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);

    this.videos.changes.subscribe((r) => {

      this.initializeVideos();
    });
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);
    this._subscriptions.forEach(s => s.unsubscribe());

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);

    this.designVideoAssignments().forEach(dva => dva.designVideo.dispose());
  }


  async onSave(): Promise<void> {

    this.enableSave.set(false);

    const videoPropOptions = this.videoPropOptions();

    videoPropOptions.eventDesignId = this.eventDesign().id;
    videoPropOptions.videoPropId = this.videoProp().id;
    const options = await firstValueFrom(this.venueService.upsertVideoPropOptions(this.videoPropOptions()));

    this.eventDesign.update(ed => {

      const optionsIndex = ed.videoPropOptions.findIndex(vpo => vpo.id === options.id);
      if (-1 < optionsIndex) {

        ed.videoPropOptions[optionsIndex] = options;
      } else {

        ed.videoPropOptions.push(options);
      }

      return ed;
    })

    this.EventDesignChanged.emit(this.eventDesign().id);
  }


  onSelectBackgroundColor(hexColor: string) {

    this.videoPropOptions.update(vpo => {

      vpo.backgroundColor = hexColor;
      return vpo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  onSelectOrchestrationParent(orchestrationParentEvent: any): void {

    let selectedIndex = Number(orchestrationParentEvent.target['options'].selectedIndex);
    this.logger.trace(`selected: ${selectedIndex === 0 ? 0 : this.orchestrationParents()[selectedIndex - 1].description}`);

    this.videoPropOptions.update(ipo => {

      ipo.orchestrationParentId = selectedIndex === 0 ? 0 : this.orchestrationParents()[selectedIndex - 1].videoPropId;
      return ipo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  onToggleAutoPlay() {

    this.videoPropOptions.update(ipo => {

      ipo.autoPlay = !ipo.autoPlay;
      return ipo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  onToggleEnableInteraction() {

    this.selectedVideoAssignment.update(va => {

      va.enableInteraction = !va.enableInteraction;
      return va;
    });

    // TODO: Figure out implementation of per-assignment enable interaction.
    //this._imageNode?.setEnableInteraction(this.selectedImageAssignment().enableInteraction);

    this.enableVideoAssignmentSave.set(this.detectVideoAssignmentChanges());
  }


  onToggleIsOrchestrationParent() {

    this.videoPropOptions.update(ipo => {

      ipo.isOrchestrationParent = !ipo.isOrchestrationParent;
      if (ipo.isOrchestrationParent) {

        // Orchestration Parents cannot be a Child of an Orchestration Parent
        ipo.orchestrationParentId = 0;
      }
      return ipo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  onToggleLoop() {

    this.videoPropOptions.update(ipo => {

      ipo.loop = !ipo.loop;
      return ipo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  onToggleSuppressSidebarVideo() {

    this.selectedVideoAssignment.update(va => {

      va.suppressSidebarVideo = !va.suppressSidebarVideo;
      return va;
    });

    this.enableVideoAssignmentSave.set(this.detectVideoAssignmentChanges());
  }


  onUndo() {

    this.enableSave.set(false);

    this.videoPropOptions.set(deepCopy(this._originalVideoPropOptions));
    this.applyVideoPropOptions();
  }


  selectDesignVideo(designVideoAssignment: DesignVideoAssignment) {

    this.selectedDesignVideo.set(new DesignVideo(designVideoAssignment.designVideo));
    this.selectedVideoAssignment.set(deepCopy(designVideoAssignment.videoAssignment));
    this._originalVideoAssignment = designVideoAssignment.videoAssignment;
  }


  setControlsDepth(newControlsDepth: number): void {

    this.videoPropOptions.update(ipo => {

      ipo.controlsZ = newControlsDepth;
      return ipo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  setControlsMargin(newControlsMargin: number): void {

    this.videoPropOptions.update(ipo => {

      ipo.controlsMargin = newControlsMargin;
      return ipo;
    });
    this.applyVideoPropOptions();

    // Server changes upon Save clicked
    this.enableSave.set(this.detectChanges());
  }


  private setVideoNode() {

    if (!this._videoNode || this._videoNode.propId !== this.videoProp().id) {

      this._videoNode = this._stage?.getVideoNode(this.videoProp().id);
    }
  }


  private setVideoPropOptions() {

    const videoPropOptions = this.eventDesign().videoPropOptions.find(vpo => vpo.videoPropId === this.videoProp().id);
    if (videoPropOptions) {

      this._originalVideoPropOptions = videoPropOptions;
      this.videoPropOptions.set(new VideoPropOptions(videoPropOptions));
    } else {

      this._originalVideoPropOptions = new VideoPropOptions();
      this.videoPropOptions.set(new VideoPropOptions());
    }
    this.audioDistanceInitializer.set(this.videoPropOptions().audioDistance);
    this.autoPlayDistanceInitializer.set(this.videoPropOptions().autoPlayDistance);
    this.interactionDistanceInitializer.set(this.videoPropOptions().interactionDistance);

    this.applyVideoPropOptions();
  }


}
