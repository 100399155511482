import { Component, computed, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IVenueEvent } from 'projects/my-common/src/model';
import { NGXLogger } from 'ngx-logger';
import { compareBool } from 'projects/my-common/src/util/utils';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-venue-event-select',
  standalone: true,
  templateUrl: './venue-event-select.component.html',
  styleUrls: ['./venue-event-select.component.scss'],
  imports: [CommonModule]
})
export class VenueEventSelectComponent {

  readonly venueEventId = signal(0);

  readonly DisplayHelp = input(false);
  readonly SelectedVenueEvent = input.required<IVenueEvent>();
  readonly VenueEvents = input.required<IVenueEvent[]>();

  readonly sortedVenueEvents = computed(() => this.VenueEvents() && 0 < this.VenueEvents().length ?
    this.VenueEvents().sort((ve1, ve2) => compareBool(ve1.isDefault, ve2.isDefault) || ve1.name.localeCompare(ve2.name)) : [])

  readonly OnSelect = output<number>();

  readonly selectedVenueEventId = computed(() => this.venueEventId() > 0 ? this.venueEventId() : this.SelectedVenueEvent().id);


  constructor(private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _logger: NGXLogger) { }


  onChange(event: any): void {

    let selectedIndex = event.target['options'].selectedIndex;
    const venueEvents = this.sortedVenueEvents();

    //let selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
    if (-1 < selectedIndex && selectedIndex < venueEvents.length) {

      this._router.navigate([],
        {
          relativeTo: this._route,
          queryParams: { eid: null, did: null },
          queryParamsHandling: 'merge'
        })
      this.venueEventId.set(0);
      this.OnSelect.emit(venueEvents[selectedIndex].id);
    }
  }

}
