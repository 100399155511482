import { RepeatWrapping, Texture } from 'three';
import * as THREE from "three";
import { getLogger } from '../util/log';
import * as htmlToImage from 'html-to-image';
import { FlipType, IMaterialBase } from '../model';

export interface ITextTexture {

  font: TextureFont
  fontSize: number
  text: string
}
export function equalsTextTexture(tt1?: ITextTexture, tt2?: ITextTexture): boolean {

  return tt1?.font === tt2?.font
    && tt1?.fontSize === tt2?.fontSize
    && tt1?.text.trim() === tt2?.text.trim()
}

/**
 * Texture cache management
 * TODO: Cache expiration timers
 */
type TextureRequest = {
  onLoad: ((data: Texture) => void);
  onError?: ((err: unknown) => void);
}
type MaskRequest = {
  onLoad: ((data: ImageData) => void);
  onError?: ((err: unknown) => void);
}

export function getImageUrlFromBitmapData(data: any, canvas?: HTMLCanvasElement): string {

  if (!data) {

    console.error(`data is undefined.`, data);
    return '';
  }
  if (!canvas) {

    canvas = document.createElement('canvas');
  }
  if (!canvas) {

    console.error('canvas is undefined.', data);
    return '';
  }

  canvas.width = data.width;
  canvas.height = data.height;
  const context = canvas.getContext('2d');

  if (!context) {

    console.error('Unable to obtain 2D context from canvas.', data);
    return '';
  }
  context.drawImage(data, 0, 0);

  return canvas.toDataURL();
}


export async function getImageFromTextureData(data: any): Promise<HTMLImageElement> {

  const canvas = new OffscreenCanvas(data.width, data.height);
  const context = canvas.getContext('2d');
  if (!context) {

    console.error('Unable to obtain 2D context from canvas.', data);
    return new Image();
  }

  context.drawImage(data, 0, 0);

  const blob = await canvas.convertToBlob();
  const image = new Image();
  image.src = URL.createObjectURL(blob);

  return image;
}


export enum TextureFont {
  Arial = 'Arial',
  Baskerville = 'Baskerville',
  Bodoni = 'Bodoni',
  Calibri = 'Calibri',
  Calisto = 'Calisto',
  Cambria = 'Cambria',
  Candara = 'Candara',
  CenturyGothic = 'Century Gothic',
  Consolas = 'Consolas',
  CopperplateGothic = 'Copperplate Gothic',
  CourierNew = 'Courier New',
  DejavuSans = 'Dejavu Sans',
  Didot = 'Didot',
  FranklinGothic = 'Franklin Gothic',
  Garamond = 'Garamond',
  Georgia = 'Georgia',
  GillSans = 'Gill Sans',
  GoudyOldStyle = 'Goudy Old Style',
  Helvetica = 'Helvetica',
  Impact = 'Impact',
  LucidaBright = 'Lucida Bright',
  LucidaSans = 'Lucida Sans',
  MicrosoftSansSerif = 'Microsoft Sans Serif',
  Optima = 'Optima',
  Palatino = 'Palatino',
  Perpetua = 'Perpetua',
  Rockwell = 'Rockwell',
  SegoeUI = 'Segoe UI',
  Tahoma = 'Tahoma',
  TrebuchetMS = 'Trebuchet MS',
  Verdana = 'Verdana'
}

type TextureFontMap = {

  [key in TextureFont]: string
}

export const TextureFonts: TextureFontMap = {
  [TextureFont.Arial]: 'Arial, Helvetica Neue, Helvetica, sans-serif',
  [TextureFont.Baskerville]: 'Baskerville, Baskerville Old Face, Garamond, Times New Roman, serif',
  [TextureFont.Bodoni]: 'Bodoni MT, Bodoni 72, Didot, Didot LT STD, Hoefler Text, Garamond, Times New Roman, serif',
  [TextureFont.Calibri]: 'Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif',
  [TextureFont.Calisto]: 'Calisto MT, Bookman Old Style, Bookman, Goudy Old Style, Garamond, Hoefler Text, Bitstream Charter, Georgia, serif',
  [TextureFont.Cambria]: 'Cambria, Georgia, serif',
  [TextureFont.Candara]: 'Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif',
  [TextureFont.CenturyGothic]: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif',
  [TextureFont.Consolas]: 'Consolas, monaco, monospace',
  [TextureFont.CopperplateGothic]: 'Copperplate, Copperplate Gothic Light, fantasy',
  [TextureFont.CourierNew]: 'Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace',
  [TextureFont.DejavuSans]: 'Dejavu Sans, Arial, Verdana, sans-serif',
  [TextureFont.Didot]: 'Didot, Didot LT STD, Hoefler Text, Garamond, Calisto MT, Times New Roman, serif',
  [TextureFont.FranklinGothic]: 'Franklin Gothic, Arial Bold',
  [TextureFont.Garamond]: 'Garamond, Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif',
  [TextureFont.Georgia]: 'Georgia, Times, Times New Roman, serif',
  [TextureFont.GillSans]: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
  [TextureFont.GoudyOldStyle]: 'Goudy Old Style, Garamond, Big Caslon, Times New Roman, serif',
  [TextureFont.Helvetica]: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  [TextureFont.Impact]: 'Impact, Charcoal, Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif',
  [TextureFont.LucidaBright]: 'Lucida Bright, Georgia, serif',
  [TextureFont.LucidaSans]: 'Lucida Sans, Helvetica, Arial, sans-serif',
  [TextureFont.MicrosoftSansSerif]: 'MS Sans Serif, sans-serif',
  [TextureFont.Optima]: 'Optima, Segoe, Segoe UI, Candara, Calibri, Arial, sans-serif',
  [TextureFont.Palatino]: 'Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif',
  [TextureFont.Perpetua]: 'Perpetua, Baskerville, Big Caslon, Palatino Linotype, Palatino, serif',
  [TextureFont.Rockwell]: 'Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman, serif',
  [TextureFont.SegoeUI]: 'Segoe UI, Frutiger, Dejavu Sans, Helvetica Neue, Arial, sans-serif',
  [TextureFont.Tahoma]: 'Tahoma, Verdana, Segoe, sans-serif',
  [TextureFont.TrebuchetMS]: 'Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, sans-serif',
  [TextureFont.Verdana]: 'Verdana, Geneva, sans-serif'
}


export class TextureManager {
  private _textures: { [src: string]: Texture } = {};
  private _textureQueues: { [src: string]: TextureRequest[] } = {};
  private _masks: { [src: string]: ImageData } = {};
  private _maskQueues: { [src: string]: MaskRequest[] } = {};
  private readonly _logger = getLogger();


  constructor(readonly three: typeof THREE) { }


  /**
   * Update model-viewer textures with those displayed in Showroom by obtaining them from the Showroom Object Node.
   * NOTE: Those Textures were rendered and applied to Materials using the version of Three required of the Space Provider.
   * Use the only Material.Map.Source.data the from those textures to update the Material Textures generated by Model-Viewer.
   * https://modelviewer.dev/examples/scenegraph/#swapTexturesExample
   * @param materialData 
   */
  async applyMaterial(modelViewer: any, customMaterial: IMaterialBase): Promise<void> {

    if (!modelViewer) {

      return;
    }
    const modelViewerMaterial = modelViewer.model.getMaterialByName(customMaterial.name);
    if (!modelViewerMaterial) {

      this._logger.error(`model-viewer material '${customMaterial.name}' not found on model`);
      return;
    }
    if (0 < customMaterial.mapUrl.length) {

      await new Promise<void>((resolve, reject) => {

        this.loadTexture(customMaterial.mapUrl,
          async (map) => {

            //Model-viewer must create its own textures
            const texture = await modelViewer.createTexture(this.getImageUrlFromBitmapData(map.source.data));
            if (texture) {

              modelViewerMaterial.pbrMetallicRoughness['baseColorTexture'].setTexture(texture);
              // In model-viewer, flipped textures are implemented as flipped objects and become invisible from the back side
              //modelViewerMaterial.setDoubleSided(false);
              if (customMaterial.alphaMapUrl && 0 < customMaterial.alphaMapUrl.length) {

                // We hack alphaMap into model-viewer model
                this.loadTexture(customMaterial.alphaMapUrl,
                  async (alphaMap) => {

                    alphaMap.flipY = (FlipType.Vertical === customMaterial.alphaMapFlip
                      || FlipType.Both === customMaterial.alphaMapFlip);

                    if (FlipType.Horizontal === customMaterial.alphaMapFlip
                      || FlipType.Both === customMaterial.alphaMapFlip) {

                      alphaMap.wrapS = RepeatWrapping;
                      alphaMap.repeat.x = -1;
                    }
                    alphaMap.needsUpdate = true;
                    //
                    // 'BLEND' or 'MASK' is required to activate transparency on model-viewer's three.js material.
                    // 'BLEND' supports greyscale/gradient alpha. 'MASK' + alpha cutoff supports sharp mask cutoff.
                    // Transparency is needed to support the setAlphaMap hack in my custom model-viewer implementation.
                    //
                    modelViewerMaterial.setAlphaMode('MASK'); // Required to enable three.js transparency in model-viewer
                    modelViewerMaterial.setAlphaCutoff(0.5);
                    //
                    // Custom model-viewer hack directly to three.js material
                    //
                    modelViewerMaterial.pbrMetallicRoughness.setAlphaMap(alphaMap);
                  })
              } else {

                modelViewerMaterial.setAlphaMode('OPAQUE');
              }

              // Texture can only be manipulated using model-viewer api after it is applied to material.
              if (FlipType.Vertical === customMaterial.mapFlip
                || FlipType.Both === customMaterial.mapFlip) {

                texture.sampler.setScale({ u: texture.sampler.scale.u, v: -1 });
              }
              if (FlipType.Horizontal === customMaterial.mapFlip
                || FlipType.Both === customMaterial.mapFlip) {

                texture.sampler.setWrapS(RepeatWrapping);
                texture.sampler.setScale({ u: -1, v: texture.sampler.scale.v });
              }
            }

            resolve();
          },
          (err) => {
            this._logger.error(err)
            reject();
          });
      })
    }
  }


  private _canvas!: HTMLCanvasElement;
  private getImageUrlFromBitmapData(data: any): string {

    if (!this._canvas) {

      this._canvas = document.createElement('canvas');
    }
    if (!this._canvas) {

      this._logger.error('Canvas undefined and unable to create canvas.');
      return '';
    }

    return getImageUrlFromBitmapData(data, this._canvas);
  }


  loadMask(src: string,
    onLoad: ((data: ImageData) => void),
    onError?: ((err: unknown) => void)): void {

    if (!src || 8 > src.length) {

      const error = `mask source not defined`;
      if (onError) {

        onError(error);
      }
      Error(error);
      return;
    }

    // If mask already loaded, return it
    if (this._masks[src]) {

      this._logger.trace(`returning cached mask ${src}`);
      onLoad(this._masks[src]);
      return;
    }

    // Queue mask requests
    if (this._maskQueues[src]) {

      this._maskQueues[src].push({
        onLoad: onLoad,
        onError: onError
      });
      return;

    } else {

      this._maskQueues[src] = [{
        onLoad: onLoad,
        onError: onError
      }];
    }

    const maskImage = new Image();
    maskImage.crossOrigin = 'Anonymous';
    const that = this;

    // When the image is loaded
    maskImage.onload = () => {

      const canvas = new OffscreenCanvas(maskImage.width, maskImage.height);
      const context2D = canvas.getContext("2d");
      if (!context2D) {

        return;
      }
      context2D.drawImage(maskImage, 0, 0, maskImage.width, maskImage.height);

      // Store and return mask image data
      const maskData = context2D.getImageData(0, 0, maskImage.width, maskImage.height);

      // Texture.flipY is true by default. 
      // This data is merged with procedural masking.
      // Flip it manually for now.
      // TODO: Add paramter to control how mask should be flipped independent of procedural masking which works as is.
      // This flipping is flipping me out.
      var halfHeight = maskImage.height / 2 | 0;
      for (var y = 0; y < halfHeight; y++) {

        var topOffset = y * maskImage.width * 4;
        var bottomOffset = (maskImage.height - y - 1) * maskImage.width * 4;
        for (var x = 0; x < maskImage.width * 4; x++) {

          var temp = maskData.data[topOffset + x];
          maskData.data[topOffset + x] = maskData.data[bottomOffset + x];
          maskData.data[bottomOffset + x] = temp;
        }
      }

      //this._masks[src] = maskData;
      let maskRequest: MaskRequest;
      for (maskRequest of this._maskQueues[src]) {

        maskRequest.onLoad(maskData);
      }
      delete this._maskQueues[src];
    }

    // Load the image
    maskImage.src = src;
  }


  createImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.decode = () => resolve(img) as any
      img.onload = () => resolve(img)
      img.onerror = reject
      img.crossOrigin = 'anonymous'
      img.decoding = 'async'
      img.src = url
    })
  }


  async createText(textTexture: ITextTexture, aspect: number): Promise<Texture | undefined> {

    const width = aspect < 1 ? 2048 * aspect : 2048;
    const height = aspect < 1 ? 2048 : 2048 / aspect;

    const div = document.createElement('div');

    div.style.fontSize = `${textTexture.fontSize}px`;
    div.style.textAlign = 'center';
    div.style.fontFamily = TextureFonts[textTexture.font];
    div.style.display = 'flex';
    div.style.alignItems = 'center';
    div.style.justifyContent = 'center';

    const textDiv = document.createElement('div')
    textDiv.textContent = textTexture.text;
    div.appendChild(textDiv);

    // https://github.com/bubkoo/html-to-image/blob/master/src/index.ts#L15
    const svg = await htmlToImage.toSvg(div,
      {
        backgroundColor: '#ffffff',
        width: width,
        height: height,
        skipFonts: true
      }
    );
    const img = await this.createImage(svg);
    const canvas = new OffscreenCanvas(width, height);
    const context = canvas.getContext("2d");
    if (!context) {

      return;
    }

    // Draw a white background
    context.fillStyle = "#ffffff"; // White color
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.drawImage(img, 0, 0, width, height);

    // // Draw filled text
    // context.fillStyle = "#000000"; // Black color
    // //Set the font style
    // //context.font = "400px Helvetica";
    // //context.font = "400px Arial";
    // //context.font = "400px Verdana";
    // //context.font = "400px Tahoma";
    // context.font = "400px Impact";
    // //context.textBaseline = 'top';    
    // context.textBaseline = "middle";
    // context.textAlign = "center";
    // // Fill the canvas with text at the specified position
    // context.fillText(text, canvas.width / 2, canvas.height / 2);

    const texture = new this.three.CanvasTexture(canvas);
    (texture as any).encoding = (this.three as any).sRGBEncoding;

    return texture;
  }


  /**
   * Load, cache and return a Three image texture.
   * If greyscale mask url is specified then load and apply the mask to the image 
   * texture before returning it.
   * The original image and the masked image will be cached.
   * @param source 
   * @param onLoad 
   * @param onError 
   * @param maskSource 
   * @returns 
   */
  loadTexture(source: string,
    onLoad: ((data: Texture) => void),
    onError?: ((err: unknown) => void),
    maskSource?: string): void {

    if (!source || 8 > source.length) {

      const error = 'Image source not defined';
      if (onError) {

        onError(error);
      }
      Error(error);
      return;
    }

    // If texture already loaded, return it
    if (this._textures[source]) {

      if (maskSource && 8 < maskSource.length) {

        this.applyMaskToTexture(source, this._textures[source], onLoad, maskSource, onError);
      } else {

        onLoad(this._textures[source]);
      }
      return;
    }

    // Queue texture requests without masks
    if (!maskSource || 8 > maskSource.length) {

      if (this._textureQueues[source]) {

        this._textureQueues[source].push({
          onLoad: onLoad,
          onError: onError
        });
        return;

      } else {

        this._textureQueues[source] = [{
          onLoad: onLoad,
          onError: onError
        }];
      }
    }

    //
    // The following CanvasTexture did not work on Object Prop materials
    //
    // const textureImage = new Image();
    // textureImage.crossOrigin = 'Anonymous';
    // const that = this;

    // textureImage.onload = () => {

    //   if (maskSource && 8 < maskSource.length) {

    //     this.applyMaskToTexture(source, textureImage, onLoad, maskSource, onError);
    //   } else {

    //     const canvas = new OffscreenCanvas(textureImage.width, textureImage.height);
    //     const context = canvas.getContext("2d");
    //     if (!context) {

    //       return;
    //     }
    //     context.drawImage(textureImage, 0, 0, textureImage.width, textureImage.height);

    //     const texture = new this.three.CanvasTexture(canvas);
    //     (texture as any).encoding = (this.three as any).sRGBEncoding;

    //     let textureRequest: TextureRequest;
    //     for (textureRequest of this._textureQueues[source]) {

    //       textureRequest.onLoad(texture);
    //     }
    //     delete this._textureQueues[source];
    //   }
    // }

    // // Load texture
    // textureImage.src = source;

    //
    // Use three.js TextureLoader
    //
    const loader = new this.three.TextureLoader();
    loader.load(source,
      async (texture) => {

        (texture as any).encoding = (this.three as any).sRGBEncoding;
        //this._textures[source] = texture;   // Stopped caching textures. Depending upon Service Worker local disk caching.
        if (maskSource && 8 < maskSource.length) {

          this.applyMaskToTexture(source, texture, onLoad, maskSource, onError);
        } else {

          let textureRequest: TextureRequest;
          for (textureRequest of this._textureQueues[source]) {

            textureRequest.onLoad(texture);
          }
          delete this._textureQueues[source];
        }
      },
      (err) => {

        let textureRequest: TextureRequest;
        for (textureRequest of this._textureQueues[source]) {

          if (textureRequest.onError) {

            textureRequest.onError(err);
          }
        }
        delete this._textureQueues[source];
      })
  }


  private async applyMaskToTexture(textureSource: string,
    texture: Texture,
    onLoad: ((data: Texture) => void),
    maskSource: string,
    onError?: ((err: unknown) => void)): Promise<void> {

    const textureImage = await getImageFromTextureData(texture.source.data);
    const source = `${textureSource}${maskSource}`;
    // If masked image texture is already loaded then return it
    if (this._textures[source]) {

      this._logger.trace(`Returning cached texture ${textureSource}`);
      onLoad(this._textures[source]);
      return;
    }

    // Queue texture requests
    if (this._textureQueues[source]) {

      this._textureQueues[source].push({
        onLoad: onLoad,
        onError: onError
      });
      return;
    } else {

      this._textureQueues[source] = [{
        onLoad: onLoad,
        onError: onError
      }];
    }

    // const image: InstanceType<typeof Image> = textureImage.image;
    // if (!image) {

    //   return;
    // }

    this.getMask(textureImage, onLoad, maskSource, onError);

    // const worker = new Worker('/assets/js/workers/image-mask-worker.js');

    // if (!worker) {
    //   this._logger.error(`unable to create worker`, worker);
    //   return;
    // }

    // const canvas = document.createElement('canvas');
    // const offscreenCanvas = canvas.transferControlToOffscreen();

    // // Wire up worker callback.
    // worker.onmessage = (response: MessageEvent<any>) => {
    //   const texture = new this.three.CanvasTexture(canvas);

    //   that._textures[srcIndex] = texture;
    //   for (const textureRequest of that._textureQueues[srcIndex]) {
    //     if (textureRequest.onLoad) textureRequest.onLoad(texture);
    //   }
    // };

    // // Invoke worker
    // // If we already have the base image texture then pass it.
    // if (this._textures[src]) {

    //   createImageBitmap(this._textures[src].image)
    //     .then(imageBitmap => {

    //       worker.postMessage({
    //         offscreenCanvas: offscreenCanvas,
    //         maskUrl: maskSrc,
    //         imageUrl: src,
    //         image: imageBitmap
    //       }, [offscreenCanvas])
    //     });

    // } else {

    //   worker.postMessage({
    //     offscreenCanvas: offscreenCanvas,
    //     maskUrl: maskSrc,
    //     imageUrl: src,
    //     image: null
    //   }, [offscreenCanvas])
    // }
  }


  private getMask(image: HTMLImageElement,
    onLoad: ((data: Texture) => void),
    maskSrc: string,
    onError?: ((err: unknown) => void)): void {

    const maskImage = new Image();
    maskImage.crossOrigin = 'Anonymous';
    const that = this;

    // When image is loaded
    maskImage.onload = () => that.applyMask(image, onLoad, maskImage, onError);

    // Load image
    maskImage.src = maskSrc;
  }


  /**
   * Create new image by applying mask image to the base image.
   * @param image 
   * @param onLoad 
   * @param maskImage 
   * @param onError 
   * @returns 
   */
  private applyMask(image: HTMLImageElement,
    onLoad: ((data: Texture) => void),
    maskImage: InstanceType<typeof Image>,
    onError?: ((err: unknown) => void)): void {

    const canvas = new OffscreenCanvas(image.width, image.height);
    const context2D = canvas.getContext("2d");
    if (!context2D) {

      return;
    }
    context2D.drawImage(maskImage, 0, 0, image.width, image.height);

    // Convert greyscale mask into alpha.
    var data = context2D.getImageData(0, 0, image.width, image.height);
    var i = 0;
    while (i < data.data.length) {

      // Set the alpha bit by summing and averaging the greyscale bits
      var rgb = data.data[i++] + data.data[i++] + data.data[i++];
      data.data[i++] = rgb / 3;
    }
    context2D.putImageData(data, 0, 0);
    context2D.globalCompositeOperation = 'source-in';

    // Render final image
    context2D.drawImage(image, 0, 0, image.width, image.height);

    const texture = new this.three.CanvasTexture(canvas);
    //texture.colorSpace = SRGBColorSpace;

    onLoad(texture);
    // const srcIndex = decodeURI(`${image.src}${maskImage.src}`);
    // //this._textures[srcIndex] = texture;
    // let textureRequest: TextureRequest;
    // for (textureRequest of this._textureQueues[srcIndex]) {

    //   textureRequest.onLoad(texture);
    // }
  }


  dispose(): void {

    const dispose = this._textures;
    this._textures = {};

    let key: string;
    for (key in dispose) {

      dispose[key].dispose();
    }
  }

}
