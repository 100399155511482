import { IWorld, addComponent, addEntity, removeEntity } from "bitecs";
import { Object3D } from "three";
import { acticonChildrenEntityMap, ActiconComponent, ActiconType, ChildObjectEntityIdMap, IActiconState, object3dByEntityId } from "..";
import { PropType } from "../../model";
import { WorldState } from "../ecs-world.state";
import { getEidForAdjustableProp } from "./adjustable.entity";

/**
 * Define an entity for the given Acticon object.
 * @param acticonObject 
 * @returns acticon entity Id
 */
export function createActiconEntity(world: IWorld, acticonObject: Object3D): number {

    // Create entity Id to track the Acticon object and its component values
    let eidForActicon: number;
    do eidForActicon = addEntity(world)
    while (!eidForActicon || 0 > eidForActicon);

    // Record reference to Acticon 3dObject
    object3dByEntityId[eidForActicon] = acticonObject;

    // Component values to track
    addComponent(world, ActiconComponent, eidForActicon);

    return eidForActicon;
}


export function getActiconEntityIdByParentId(eidForParent: number, acticonLookup: ChildObjectEntityIdMap): number {

    const sEidForParent = `${eidForParent}`;
    let record: [key: string, value: number];
    for (record of Object.entries(acticonLookup)) {

        if (sEidForParent === record[0]) {

            return record[1];
        }
    }

    return -1;
}


/**
 * Update input state of an Acticon.
 * @param parentPropId 
 * @param parentPropType 
 * @param acticonType 
 * @param acticonState 
 */
export function inputActiconState(world: IWorld, parentPropId: number, parentPropType: PropType, acticonType: ActiconType, acticonState: IActiconState): void {

    // Find the parent entity id
    const eidForParent = getEidForAdjustableProp(world, parentPropId, parentPropType);
    if (0 > eidForParent) {

        WorldState.logger.error(`Parent entity type: ${PropType[parentPropType]}, id: ${parentPropId} not found`);
        return;
    }

    const eidForActicon = acticonChildrenEntityMap[eidForParent]
        .find(eid => ActiconComponent.type[eid] === acticonType);
    if (eidForActicon) {

        updateActiconComponentValues(eidForParent, eidForActicon, acticonState);
    } else {

        WorldState.logger.error(`Acticon entity Id for parent entity Id: ${eidForParent} not found`);
    }    
}


export function getEidForActiconByParentAndType(eidForParent: number, acticonType: ActiconType): number | undefined {

    return acticonChildrenEntityMap[eidForParent]
        .find(eid => ActiconComponent.type[eid] === acticonType);
}


export function removeActiconChildEntityIdFromProp(world: IWorld, eidForParent: number, eidForActicon: number): void {

    if (acticonChildrenEntityMap[eidForParent]) {

        const index = acticonChildrenEntityMap[eidForParent].findIndex(eid => eid === eidForActicon);
        if (-1 < index) {

            acticonChildrenEntityMap[eidForParent].splice(index, 1);
        }
    }
    removeActiconEntity(world, eidForActicon);
}


/**
 * Remove Acticon entity and object.
 * @param eidForActicon 
 */
export function removeActiconEntity(world: IWorld, eidForActicon: number): void {

    removeEntity(world, eidForActicon);

    // Remove reference to Acticon 3dObject
    delete object3dByEntityId[eidForActicon];
}


export function updateActiconComponentValues(eidForParent: number, eidForActicon: number, acticonState: IActiconState) {

    ActiconComponent.parentEid[eidForActicon] = eidForParent;
    ActiconComponent.animationDuration[eidForActicon] = acticonState.animationDuration;
    ActiconComponent.animationTimer[eidForActicon] = acticonState.animationTimer;
    ActiconComponent.perpendicularMargin[eidForActicon] = acticonState.perpendicularMargin;
    ActiconComponent.scale.x[eidForActicon] = acticonState.scaleTo.x;
    ActiconComponent.scale.y[eidForActicon] = acticonState.scaleTo.y;
    ActiconComponent.scale.z[eidForActicon] = acticonState.scaleTo.z;
    ActiconComponent.scaleFrom.x[eidForActicon] = acticonState.scaleFrom.x;
    ActiconComponent.scaleFrom.y[eidForActicon] = acticonState.scaleFrom.y;
    ActiconComponent.scaleFrom.z[eidForActicon] = acticonState.scaleFrom.z;
    ActiconComponent.size.h[eidForActicon] = acticonState.size.h;
    ActiconComponent.size.w[eidForActicon] = acticonState.size.w;
    ActiconComponent.sort[eidForActicon] = acticonState.sort;
}


/**
 * An adjustable Prop can have 0 to many Acticons.
 * Acticons under an adjustable Prop are distinct by Acticon type (i.e. Next, Pause, Play, etc.)
 * @param world 
 * @param adjustableProp 
 */
export function upsertActiconEntity(world: IWorld, eidForParent: number, acticonObject: Object3D, acticonType: ActiconType): number {

    // Ensure parent Prop is setup to track child Acticons
    if (!acticonChildrenEntityMap[eidForParent]) {

        acticonChildrenEntityMap[eidForParent] = [];
    }

    // If we are already tracking an Acticon of the specified type then return it.
    let eidForActicon = acticonChildrenEntityMap[eidForParent]
        .find(eid => ActiconComponent.type[eid] === acticonType);
    if (eidForActicon) {

            object3dByEntityId[eidForActicon] = acticonObject;
            return eidForActicon;
    }

    // Create the Ecs entity for the Acticon
    eidForActicon = createActiconEntity(world, acticonObject);
    // Set the Acticon Type
    ActiconComponent.type[eidForActicon] = acticonType;
    // Set the relationship between the parent Prop and the Acticon
    acticonChildrenEntityMap[eidForParent].push(eidForActicon);
    // Add reference to the Acticon object
    object3dByEntityId[eidForActicon] = acticonObject;

    return eidForActicon;
}