import { OrientedBox } from 'projects/mp-common/src';
import { Scene } from 'static/sdk';

export const SCENE_UNDEFINED_MESSAGE = 'Scene not defined';
import { Vector3Obj, vector3OneObj, vector3ZeroObj } from 'projects/my-common/src/util/utils';


export enum Category {
  Sofa = 'Sofa',
  EndTable = 'End Table',
  ArmChair = 'Arm Chair',
  CoffeTable = 'Coffee Table',
  EventSponsor = 'Event Sponsor',
  VenueEvent = 'Venue Event',
  EventDesign = 'Event Design'
}

export type ItemDesc = {
  name: string;
  url: string;
  position: Vector3Obj;
  rotation: Vector3Obj;
  scale: Vector3Obj;
  categories: Category[];
};

export type SlotDesc = {
  name: string;
  category: Category;
};

export type Instance<T> = {
  desc: T;
  sceneObject: any;
};



/**
 * Default Prop Matterport input values
 */
export abstract class PropInputs {
  public position: Vector3Obj = vector3ZeroObj;
  public rotation: Vector3Obj = vector3ZeroObj;
  public scale: Vector3Obj = vector3OneObj;
}




export const SelectedColor = 0xffff00;
export const SelectedOpacity = 0.1;
export const SelectedLineOpacity = 1.0;
export const UnselectedColor = 0xffffff;
export const UnselectedOpacity = 0.04;
export const UnselectedLineOpacity = 0.4;





/**
 * The Matterport elements required to implement a Showroom (Model based/3D) Prop
 */
export type MP_PlaceholderProp = {
  inputs: PropInputs;
  node: Scene.INode;
  modelComponent: Scene.IComponent;
  boxComponent: OrientedBox;
}
