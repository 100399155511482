import { CameraRig } from "./CameraRig";
import { Controls } from "./ControlBase";


// @ts-ignore
import THREE from '/static/vendors/three/0.151.3/three.min.js';
//declare const THREE: any;


export class FreeControls extends Controls {
  private localVelocity = new THREE.Vector3();
  private localRotation = new THREE.Euler();
  private localOrientation = new THREE.Quaternion();

  constructor(private cameraRig: CameraRig) {
    super(0.5, 2);
  }

  override tick(deltaMsec: number) {
    super.tick(deltaMsec);

    this.localVelocity.copy(this.velocity).applyQuaternion(this.cameraRig.quaternion);
    this.cameraRig.position.add(this.localVelocity);

    this.localRotation.set(this.angularVelocity.x, this.angularVelocity.y, this.angularVelocity.z, 'YXZ');
    this.localOrientation.setFromEuler(this.localRotation);
    this.cameraRig.quaternion.multiply(this.localOrientation);

    // remove roll
    const euler = new THREE.Euler(0,0,0,'YXZ').setFromQuaternion(this.cameraRig.quaternion);
    euler.z = 0;
    this.cameraRig.quaternion.setFromEuler(euler);
  }
}
