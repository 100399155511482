import {defineComponent, Types} from 'bitecs';

/**
 * Base scale without any adjustments.
 */
export const ScaleComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})

/**
 * Adjustment to the base scale. Changes with move from position to position.
 */
export const ScaleAdjustmentComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})


/**
 * Track the original scale adjustment as it transitions to the destination adjustment.
 */
export const FromScaleComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})


/**
 * The target destination scale adjustment.
 */
export const DestinationScaleComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})