import { Component, Input, output, signal } from '@angular/core';
import { IPriceOption } from 'projects/my-common/src/model';
import { InputPriceComponent } from "../input-price/input-price.component";
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapPlusLg } from '@ng-icons/bootstrap-icons';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-price-options',
  standalone: true,
  templateUrl: './price-options.component.html',
  styleUrl: './price-options.component.scss',
  imports: [InputPriceComponent, NgIconComponent],
  providers: [provideIcons({ bootstrapPlusLg })]
})
export class PriceOptionsComponent {

  readonly priceOptions = signal(<IPriceOption[]>[])
  @Input({ required: true }) set PriceOptions(value: IPriceOption[]) {

    this.priceOptions.set(value);
    this.addingPriceOption.set(false);
  }

  readonly addingPriceOption = signal(false);

  readonly Add = output<void>();
  readonly Delete = output<number>();
  readonly Updated = output<IPriceOption>();


  constructor(private readonly _logger: NGXLogger) { }


  handleLabelInput(labelInputEvent: any, priceOptionIndex: number) {

    const newLabel = labelInputEvent.target.value

    this.priceOptions.update(pos => {

      // Update the label without changing the array reference
      pos[priceOptionIndex].label = newLabel;
      return pos;
    })

    this.Updated.emit(this.priceOptions()[priceOptionIndex]);
  }


  handlePriceUpdate(newPrice: number, priceOptionIndex: number) {

    this.priceOptions.update(pos => {

      // Update the price without changing the array reference
      pos[priceOptionIndex].price = newPrice;
      return pos;
    })

    this.Updated.emit(this.priceOptions()[priceOptionIndex]);
  }


  onAddPriceOption() {

    if (this.addingPriceOption()) {

      return;
    }
    this.addingPriceOption.set(true);

    this.Add.emit();
  }


  onDeletePriceOption(priceOptionIndex: number) {

    this.Delete.emit(this.priceOptions()[priceOptionIndex].id);
  }

}
