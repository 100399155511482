<!-- 'init' property must be false here in order for prperties and init to be set in code -->
<swiper-container #swiperRef class="videoSlider" init="false">
    @for (slide of sliderData; track $index) {
    <swiper-slide>
        <video [id]="slide.id" aria-hidden="true" class="w-screen h-screen object-cover" autoplay playsinline loop muted oncanplay="this.play()"
            onloadedmetadata="this.muted = true" [src]="slide.img">
            Your browser does not support the video tag.
        </video>
        <div class="absolute bottom-3 md:bottom-[calc(20%)] left-2 md:left-20">
            <div
                class="text-3xl md:text-[46px] font-semibold uppercase leading-none lg:text-left bg-zinc-900 bg-opacity-0 md:bg-opacity-60 rounded-md px-6 py-4">
                {{slide.titlePart1}}<br />
                {{slide.titlePart2}}
            </div>
        </div>
    </swiper-slide>
    }
</swiper-container>
<!-- <div class="absolute top-1/2 right-20 bg-slate-500 z-10" (click)="handleNext()">next</div>
<div class="absolute top-1/2 left-20 bg-slate-500 z-10" (click)="handlePrev()">prev</div> -->
