import { Size } from 'projects/my-common/src';
import { SceneComponent, ComponentOutput } from '../SceneComponent';
import type { Texture } from 'three';

export interface IPainter2d {
  paint(context2d: CanvasRenderingContext2D, size: Size): void;
}

export type Inputs = {
  painter: IPainter2d | null;
  textureRes: Size;
}

export type Outputs = {
  texture: Texture | null;
} & ComponentOutput;

export type Events = {
  repaint: boolean;
};

class CanvasRenderer extends SceneComponent {
  private canvas: HTMLCanvasElement | null = null;
  private renderContext2D: CanvasRenderingContext2D | null = null;

  override inputs: Inputs = {
    painter: null,
    textureRes: { w: 256, h: 256 },
  }

  override outputs = {
    texture: null,
  } as Outputs;

  override events = {
    repaint: true,
  } as Events;


  override onInit() {
    const THREE = this.context.three;

    // set up canvas 2d context
    this.canvas = document.createElement('canvas');
    this.renderContext2D = this.canvas.getContext('2d');
    this.outputs.texture = new THREE.CanvasTexture(this.canvas);

    this.resize(this.inputs.textureRes);
    this.repaint();
  }


  override onInputsUpdated(oldInputs: Inputs) {
    if (oldInputs.textureRes.w !== this.inputs.textureRes.w ||
      oldInputs.textureRes.h !== this.inputs.textureRes.h) {
      this.resize(this.inputs.textureRes);
    }

    if (oldInputs.painter !== this.inputs.painter) {
      this.repaint();
    }
  }


  override onEvent(eventType: string) {
    if (eventType === 'repaint') {
      this.repaint();
    }
  }


  override onDestroy() {
    this.outputs.texture?.dispose();
    this.outputs.texture = null;
  }


  private resize(size: Size) {
    if (!this.canvas) return;
    this.canvas.width = size.w;
    this.canvas.height = size.h;
  }

  
  private repaint() {
    if (this.inputs.painter && this.renderContext2D) {
      this.inputs.painter.paint(this.renderContext2D, this.inputs.textureRes);
      if (this.outputs.texture) {
        this.outputs.texture.needsUpdate = true;
      }
    }
  }


}


export interface ICanvasRenderer extends SceneComponent {
  inputs: Inputs;
  outputs: Outputs;
}

export const CANVAS_RENDERER_COMPONENT_TYPE = 'mp.canvasRenderer';

export function makeCanvasRenderer() {
  return new CanvasRenderer();
}
