import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnDestroy, WritableSignal, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemDesc } from 'src/app/core/model/matterport/matterport.model';
import { PropSelectSidebarComponent } from "../../showroom/prop-select-sidebar/prop-select-sidebar.component";
import { MatterportService } from 'src/app/core/service/matterport/matterport.service';
import { IImageProp, IVenue } from 'projects/my-common/src/model';
import { NGXLogger } from 'ngx-logger';
import { IStage } from 'src/app/core/model/showroom/showroom.model';


const MATTERPORT_DEVELOPER_SDK_KEY = '10a6bm7npnp814gnguhdiwgia';

enum MatterportSetting { Dollhouse = 1, Play = 2, Quickstart = 3, StartPosition = 4, StartRotation = 5, Help = 6 }


@Component({
  selector: 'app-mp-showroom',
  standalone: true,
  templateUrl: './mp-showroom.component.html',
  styleUrls: ['./mp-showroom.component.scss']
  , providers: [MatterportService]
  , schemas: [CUSTOM_ELEMENTS_SCHEMA]
  , imports: [CommonModule, PropSelectSidebarComponent]
})
export class MpShowroomComponent implements OnDestroy {
  readonly propOptions: WritableSignal<ItemDesc[]> = signal([]);
  readonly currentImageProp = signal(<IImageProp>{});
  readonly enableSave = signal(false);
  private _mpViewer?: HTMLElement;

  private _venue!: IVenue;
  @Input() set Venue(value: IVenue) {

    this._venue = value;

    this.setUrlParameters();
    if (!this._mpViewer) {
      
      this.createMatterportViewer();
    }
  }

  private _urlParams = { dh: '1', play: '1', qs: '1', ss: '25', sr: '-.15', help: '1' };

  StageInitialized = output<IStage>();


  constructor(private readonly _matterportService: MatterportService,
    private readonly _logger: NGXLogger) { }

  /**
   * Insert the Matterport viewer into the DOM configured with the model and developer SDK key.
   * This was the only approach for dynamically configuring the model that I could get to work.
   * The following Template HTML works but binding [m] to a class property was not accepted (e.g. [m]="model" doesn't work)
   * <matterport-viewer m="Hvmp4aAYtHp" 
   * Also, passing ?m=Hvmp4aAYtHp&... as a URL parameter was ignored by the matterport viewer.
   * URL parameters like "play", "qs", "sr" and "ss" only work as URL parameters. Not as attributes on the viewer. 
   * <matterport-viewer m="Hvmp4aAYtHp" play="1" qs="1" sr="-.15" ss="25" application-key="10a6bm7npnp814gnguhdiwgia" (mpSdkPlaying)="onMatterportSdkLoaded($event)"></matterport-viewer>
   */
  private createMatterportViewer() {

    if (!this._venue || 1 > this._venue.spaceId.length) {

      return;
    }
    // Create Matterport-viewer component
    // https://support.matterport.com/s/article/URL-Parameters?language=en_US#:~:text=Just%20add%20the%20URL%20parameter%20to%20the%20end,URL%20before%20you%20embed%20it%20in%20an%20iframe.
    // parameters: 'm', 'applicationKey', 'useLgacyId' and 'play' should remain at they are set
    // 'tour': I tried 0, 1, 2, 3 and really didn't notice anything
    // 'brand': 0 = hide presented by on start and contact details in top left About panel, 1 = (default) display all
    // 'help': 0 = (default) no help on start, 1 = some help on start, 2 = detailed help on start
    // 'dh': 0 = fly in from doll house view on start, 1 = (default) stay in doll house view on start
    // 'qs': 0 = (default) disable quick start (stick with doll house fly in), 1 = jump straight into view (if Start Position is defined in view)
    // 'gt': 0 = hide guided tour buttons in bottom left, 1 = (default) show guided tour buttons
    // 'hr': 0 = hide highlight reel at bottom, 1 = (default) show highlight reel
    // 'mls': 0 = (default) show branding info, links in Mattertab Posts, and VR, 1 = MLS-frienndly remove stuff, 2 = same as 1 but also removes About
    // 'mt': 0 = hide hattertag content, 1 = (default) show mattertag posts
    // 'tagNav': 0 = disable navigating through tags, 1 = (default) allow tag navigation using "," and "." keys
    // 'pin': 0 = hide place 360 Views pins in dollhouse and floor plan, 1 = (default) shows them
    // 'portal': 0 = hide placed 260 views connection in portals from inside view, 1 = (default) show them
    // 'f': 0 = remove option to switch floors, 1 = (default) allow floor navigation
    // 'fp': 0 = remove floor plan view button, 1 = (default) show it
    // 'lang': languages
    // 'nozoom': 0 = (default) Allow zooming, 1 = disable zooming
    // 'search': 0 = remove search icon from upper left, 1 = (default) show it
    // 'wh': 0 = Ignore scroll wheel zooming, scroll page instead, 1 = (default) handle scroll wheel zooming
    // 'title': 0 = Space title + top left About are hidden, 1 - (default) show them
    // 'tourcta': 0 = No call to action panel after tour, 1 = (default) large call to action panel, 2 = Small panel
    // 'ts': # start tour automatically after # seconds
    // 'hl': 0 (default if highlight reel has no 360 view) briefly show before hiding, (default if reel has 360 view) keep visible on start, 2 = if no 360 view collapse (else 1)
    // For the following see https://matterport.github.io/showcase-sdk/docs/reference/current/modules/sweep.conversion.html#getlabelfromid
    // 'ss': Scan/sweep index - Sweep # to start on (deep link) - if specified then 'dh' and 'qs' are ignored
    // 'sr': Sweep rotation (deep link) - if specified then 'dh' and 'qs' are ignored
    // 'sm': Sweep mode
    this._mpViewer = document.createElement('iframe');
    this._mpViewer.setAttribute('id', 'showcase');
    this._mpViewer.setAttribute('width', '100%');
    this._mpViewer.setAttribute('class', 'w-screen h-screen');
    this._mpViewer.setAttribute('frameborder', '0');
    //this._mpViewer.setAttribute('allow', 'geolocation;fullscreen;vr;autoplay;xr-spatial-tracking');
    this._mpViewer.setAttribute('allow', 'geolocation;fullscreen;autoplay;xr-spatial-tracking');
    //this._mpViewer.setAttribute('allowvr', 'yes');
    this._mpViewer.setAttribute('allowFullScreen', 'yes');

    let src = `/showcase.html?applicationKey=${MATTERPORT_DEVELOPER_SDK_KEY}`.concat(
      `&m=${this._venue.spaceId}`
      , `&play=${this._urlParams.play}`
      , `&dh=${this._urlParams.dh}`
      , `&qs=${this._urlParams.qs}`
      , `&ss=${this._urlParams.ss}`
      , `&sr=${this._urlParams.sr}`
      , `&help=${this._urlParams.help}`
      , '&aa=1'
      , '&newtags=1'
      //, '&xruntested=1'
      , '&title=0'
      , '&brand=0'
      , '&hl=0'
      //, '&tourcta=1'
      , '&nozoom=0'
      , '&pin=0'
      , '&mls=0'
      , '&gt=1'
      , '&portal=0'
      , '&f=1'
      , '&wh=1'
      , '&kb=1'
      , '&lp=0'
      //, '&vr=1'
      , '&nt=0'
      , '&search=0'
      , '&hidetags=0'
    );
    this._logger.trace(src);
    this._mpViewer.setAttribute('src', src);

    this._mpViewer.onload = async ($event: any) => {
      this._logger.trace(`iFrame loaded`);

      const showcaseWindow = (this._mpViewer as any).contentWindow;
      let mpSdk;
      try {
        mpSdk = await showcaseWindow.MP_SDK.connect(showcaseWindow);
        await this._matterportService.inititialize(mpSdk);

        this.StageInitialized.emit(this._matterportService);
        (this._mpViewer as any).contentWindow.addEventListener('DOMActivate', this.initialInteractionCallback);
      }
      catch (e) {
        console.error(e);
        return;
      }
    };

    const placeholderDiv = document.getElementById("mp-viewer-placeholder");
    if (null !== placeholderDiv && null != placeholderDiv.parentNode) {
      
      this._logger.trace(`-->placeholderDiv, this._mpViewer`, placeholderDiv, this._mpViewer);
      placeholderDiv.parentNode.insertBefore(this._mpViewer, placeholderDiv);
    }
  }


  private deleteMatterportViewer() {

    if (this._mpViewer) {
      this._mpViewer.remove();
      this._mpViewer = undefined;
    }
  }


  private readonly initialInteractionCallback = this.onInitialInteraction.bind(this);
  private onInitialInteraction() {

    this._mpViewer?.removeEventListener('DOMActivate', this.initialInteractionCallback);
    window.dispatchEvent(new Event('initialInteraction'));
  }


  //?title=0&hr=0&brand=0&help=0&play=1&qs=1&ss=25&sr=-.15
  // &title=0&qs=1&hr=0&brand=0&help=0'
  // Route http://.../matterport?play=1&qs=1&ss=25&sr=-.15
  async onMatterportSdkLoaded(event: any) {
    // this._logger.trace(`--> OnMatterportSdkLoaded() - model details`, await event.detail.mpSdk.Model.getDetails());
    // this._logger.trace(`--> OnMatterportSdkLoaded() - model data`, await event.detail.mpSdk.Model.getData());
    await this._matterportService.inititialize(event.detail.mpSdk);

    this.StageInitialized.emit(this._matterportService);

    // TODO - Why does this call only work after emit?
    // The *.venue.component receiving the emit adds Props just like below
    // If I move the following addProps before the emit thhen it doesn't work
    // Some kind of "race" condition?
    //await this.addProps();    // hard coded object Prop tests
    // await this.addImageProps();   // hard coded tests
  }


  private setUrlParameters() {
    const venue = this._venue;

    let i = 0;
    for (; i < venue.spaceProviderDefaultSettings.length; i++) {
      switch (venue.spaceProviderDefaultSettings[i].id) {
        case MatterportSetting.Dollhouse:
          this._urlParams.dh = venue.spaceProviderDefaultSettings[i].value;
          break;
        case MatterportSetting.Play:
          this._urlParams.play = venue.spaceProviderDefaultSettings[i].value;
          break;
        case MatterportSetting.Quickstart:
          this._urlParams.qs = venue.spaceProviderDefaultSettings[i].value;
          break;
        case MatterportSetting.StartPosition:
          this._urlParams.ss = venue.spaceProviderDefaultSettings[i].value;
          break;
        case MatterportSetting.StartRotation:
          this._urlParams.sr = venue.spaceProviderDefaultSettings[i].value;
          break;
        case MatterportSetting.Help:
          this._urlParams.help = venue.spaceProviderDefaultSettings[i].value;
          break;
      }
    }

    for (i = 0; i < venue.spaceProviderSettings.length; i++) {
      switch (venue.spaceProviderSettings[i].spaceProviderDefaultSettingId) {
        case MatterportSetting.Dollhouse:
          this._urlParams.dh = venue.spaceProviderSettings[i].value;
          break;
        case MatterportSetting.Play:
          this._urlParams.play = venue.spaceProviderSettings[i].value;
          break;
        case MatterportSetting.Quickstart:
          this._urlParams.qs = venue.spaceProviderSettings[i].value;
          break;
        case MatterportSetting.StartPosition:
          this._urlParams.ss = venue.spaceProviderSettings[i].value;
          break;
        case MatterportSetting.StartRotation:
          this._urlParams.sr = venue.spaceProviderSettings[i].value;
          break;
        case MatterportSetting.Help:
          this._urlParams.help = venue.spaceProviderSettings[i].value;
          break;
      }
    }
  }


  ngOnDestroy(): void {

    this.deleteMatterportViewer();
  }


}
