import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnDestroy, QueryList, ViewChildren, computed, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapArrowCounterclockwise, bootstrapArrowLeft, bootstrapCheckLg, bootstrapPlusLg, bootstrapTrash, bootstrapXLg } from "@ng-icons/bootstrap-icons";
import { DesignObject, FlipType, IDesignObjectUpload, IMaterialBase, IPriceOption, ObjectAssignment, ObjectProp, equalsObjectAssignment } from 'projects/my-common/src/model';
import { Vector3InputComponent } from "../../../../shared/vector3-input/vector3-input.component";
import { PositionAdjustmentComponent } from "../../../../shared/position-adjustment/position-adjustment.component";
import { Subscription } from 'rxjs/internal/Subscription';
import { NGXLogger } from 'ngx-logger';
import { IStage } from 'src/app/core/model/showroom/showroom.model';
import { Vector3Obj } from 'projects/my-common/src/util/utils';
import { FloatingSliderComponent } from 'src/app/components/shared/floating-slider/floating-slider.component';
import { CheckboxComponent } from "../../../../shared/checkbox/checkbox.component";
import { TextEditorComponent } from "../../../../shared/text-editor/text-editor.component";
import { CommonVector3InputComponent } from "../../common-vector3-input/common-vector3-input.component";
import { ISlider, SliderService } from 'src/app/core/service/ui/slider/slider.service';
import { SHOWROOM_COMMON_SLIDER_ID } from '../../common-slider/common-slider.component';
import { VenueService } from 'src/app/core/service/venue/venue.service';
import { firstValueFrom } from 'rxjs';
import { PriceOptionsComponent } from "../../../../shared/price-options/price-options.component";
import { ActivatedRoute, Router } from '@angular/router';
import { getImageUrlFromBitmapData, MaterialTexture } from 'projects/my-common/src';
import { RepeatWrapping } from 'three';
import { DesignObjectService } from 'src/app/core/service/venue/design-object.service';
import { IMPORT_OBJECT_SIDEBAR_ID } from '../../../event-design/import-object-sidebar/import-object-sidebar.component';
import { ShowroomService } from 'src/app/core/service/showroom/showroom.service';
import { ModalService } from 'src/app/core/service/ui/modal/modal.service';
import { ALERT_MODAL_ID } from 'src/app/components/shared/alert-modal/alert-modal.component';
import { ProgressBarComponent } from 'src/app/components/shared/progress-bar/progress-bar.component';

export const CONFIGURE_OBJECT_PROP_SIDEBAR_ID = '13111649-995a-4d61-a25b-352fd22d9db1';

/**
 * Event Designs:
 *    - are a plan/layout to facilitate collaboration around the design for a Venue
 *    - have a _default Design:
 *        > where base values for Object Props can be defined
 *        > whose values (if defined) will be inserted into a Design without assigned Design Objects
 * Object Props are:
 *    - the Showroom representation of a Glb/Gltf object
 *    - created when Staging a venue
 *    - assigned an initial position and rotation which represent the base for subsequent Design Objects
 *    - editable if this EventDesign is _default OR DesignObject is not defined for this EventDesign
 * Design Objects are:
 *    - Glb files that get Assigned to an Object Prop
 *    - specific to an Event Design
 *    - able to override the position and rotation values of the Object Prop
 *    - are only editable if it belongs to this EventDesign
 */
@Component({
  selector: 'app-configure-object-prop-sidebar',
  standalone: true,
  templateUrl: './configure-object-prop-sidebar.component.html',
  styleUrls: ['./configure-object-prop-sidebar.component.scss'],
  providers: [provideIcons({ bootstrapArrowCounterclockwise, bootstrapArrowLeft, bootstrapCheckLg, bootstrapPlusLg, bootstrapTrash, bootstrapXLg })],
  imports: [CommonModule, FloatingSliderComponent, NgIconComponent, TextEditorComponent, Vector3InputComponent, PositionAdjustmentComponent, CheckboxComponent,
    CommonVector3InputComponent, PriceOptionsComponent, ProgressBarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConfigureObjectPropSidebarComponent implements ISidebar, OnDestroy {

  readonly sidebarId: string = CONFIGURE_OBJECT_PROP_SIDEBAR_ID;

  id = crypto.randomUUID();
  private _commonSlider?: ISlider;
  private _lock = 0;
  private _modelViewer: any;
  private _modelViewerModel: any;
  private _originalModelViewerTextures: MaterialTexture[] = [];
  private readonly _subscriptions: Subscription[] = [];

  readonly assignedObjectPropCount = this.venueService.assignedObjectPropCount;
  readonly canActivateAR = signal(false);
  readonly closing = signal(false);
  readonly copyingObjectProp = signal(false);
  readonly eventDesign = this.venueService.currentEventDesign;
  readonly isOpen = signal(false);
  readonly objectPropCount = this.venueService.objectPropCount;
  readonly selectedTab = signal(1);
  readonly uploadingObjectProgress = signal(0);
  readonly uploadingObjectFileName = signal('');

  /**
   * Setup for auto-close on blur when user clicks away from sidebar
   */
  @ViewChildren('clientObjectPropSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  @ViewChildren('sliderBasedInputs') sliderBasedInputs!: QueryList<HTMLInputElement>;

  //
  // Inputs
  //
  readonly objectProp = signal(new ObjectProp());
  @Input({ required: true }) set ObjectProp(value: ObjectProp) {

    // Changing Props in the middle of edits should undo changes first.
    if (this.enableSave()) {

      if (value.id !== this.objectProp().id) {

        this.onUndo();
      } else {

        // Receiving the same object while editing it should be ignored.
        return;
      }
    }

    // Prior objectProp is no longer selected
    //this.setSelectedState(this.objectProp(), false);

    this.objectProp.set(new ObjectProp(value));
    //this.setSelectedState(this.objectProp(), true);
  }

  /**
   * id can be 0 if ObjectProp has no assignments.
   */
  readonly localAssignment = signal(new ObjectAssignment());
  @Input({ required: true }) set ObjectAssignment(value: ObjectAssignment) {

    // Changing Assignments in the middle of edits should undo changes first???
    if (this.enableSave()) {

      if (value.id !== this.localAssignment().id) {

        this.onUndo();
      } else {

        // Receiving the same object while editing it should be ignored.
        return;
      }
    }

    this.localAssignment.set(new ObjectAssignment(value));
    this._deletedPriceOptions = [];
  }

  readonly Stage = input.required<IStage>();
  //
  // End inputs
  //

  // 
  // Computed selectors
  //
  readonly defaultDesignObjects = this.venueService.defaultDesignObjects;
  readonly designObject = computed(() => this.designObjects()
    .find(_do => _do.id === this.localAssignment().designObjectId) ??
    this.defaultDesignObjects()
      .find(_do => _do.id === this.localAssignment().designObjectId) ??
    new DesignObject());
  readonly designObjects = this.venueService.designObjects;
  readonly enableSave = computed(() =>
    !this.readOnlyDefaultDesignObject()
    && 0 < this.localAssignment().id
    && !equalsObjectAssignment(this.localAssignment(), this.objectAssignment()));
  readonly isObjectAssignmentScaleEqual = computed(() => this.localAssignment().scale[0] === this.localAssignment().scale[1]
    && this.localAssignment().scale[1] === this.localAssignment().scale[2]);
  readonly objectAssignment = computed(() => this.venueService.objectAssignments()
    .find(oa => oa.id == this.localAssignment().id) ?? new ObjectAssignment());
  readonly objectNode = computed(() => this.Stage() && this.Stage().getObjectNode ?
    this.Stage().getObjectNode(this.localAssignment().objectPropId) : undefined);
  readonly objectPropsAvailable = computed(() => this.objectPropCount() - this.assignedObjectPropCount())
  /**
   * If the Design Object references an Event Design other than this eventDesign then it is referencing the _default Event Design.
   */
  readonly readOnlyDefaultDesignObject = computed(() => this.designObject().eventDesignId !== this.eventDesign().id);
  //
  // End computed selectors
  //

  constructor(private readonly designObjectService: DesignObjectService,
    private readonly logger: NGXLogger,
    private readonly modalService: ModalService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly showroomService: ShowroomService,
    private readonly sidebarService: SidebarService,
    private readonly sliderService: SliderService,
    private readonly venueService: VenueService) {

    sidebarService.add(this);
    this.monitorImportObjectRequests();
  }


  async addDesignObject(event: any) {

    if (!event || 0 < this._lock++) {

      return;
    }

    const file = event.target.files[0];
    var sFileName = file.name;
    var iFileSize = file.size;
    var iConvert = (file.size / 1048578).toFixed(2);

    if (iFileSize > 29360128) {

      let txt = `File size: ${iConvert} MB. Please make sure your glb file is less than 28 MB.`;
      this.modalService.setTarget(ALERT_MODAL_ID, txt);
      this.modalService.open(ALERT_MODAL_ID);

      this._lock = 0;
      return;
    }

    const designObjectUpload: IDesignObjectUpload = {
      id: 0,
      eventDesignId: this.eventDesign().id,
      file: file,
    };

    // When the upload it 100% complete the server is still copying the file to blob storage before responding.
    this.uploadingObjectProgress.set(0);
    this.uploadingObjectFileName.set(sFileName);
    const newDesignObject = await firstValueFrom(this.venueService.createDesignObject(designObjectUpload,
      (percentDone: number) => this.uploadingObjectProgress.set(percentDone)
    ));
    this.uploadingObjectFileName.set('');
    this.uploadingObjectProgress.set(0);

    this.localAssignment.update(oa => {

      oa.designObjectId = newDesignObject.id;

      return new ObjectAssignment(oa);
    })

    this.objectNode()?.setInputs(this.objectProp(), this.localAssignment(), newDesignObject);
    this._lock = 0;
  }


  private async applyDesignObjectMaterial() {

    if (!this._modelViewerModel || 1 > this.designObject().id) {

      return;
    }

    let modelViewerMaterial: any;
    for (modelViewerMaterial of this._modelViewerModel.materials) {

      let designObjectMaterial = this.designObject().materials.find(dom => dom.name == modelViewerMaterial.name);
      if (designObjectMaterial) {

        // This will retrieve the materials from the object prop node and apply them.
        this.applyMaterial(modelViewerMaterial, designObjectMaterial);
      }
    }

    this.applyObjectAssignmentMaterial();
  }


  private applyObjectAssignmentMaterial() {

    if (!this._modelViewer || 1 > this._modelViewerModel.materials.length
      || 1 > this.designObject().id || 1 > this.objectAssignment().id || 1 > this.objectAssignment().materials.length) {

      return;
    }

    let modelViewerMaterial: any;
    for (modelViewerMaterial of this._modelViewerModel.materials) {

      let objectAssignmentMaterial = this.objectAssignment().materials.find(oam => oam.name == modelViewerMaterial.name);
      if (objectAssignmentMaterial) {

        this.logger.trace(`Applying ObjectAssignment material: ${modelViewerMaterial.name}`, objectAssignmentMaterial);
        // This will retrieve the materials from the object prop node and apply them.
        this.applyMaterial(modelViewerMaterial, objectAssignmentMaterial);
      } else {

        const originalTextures = this._originalModelViewerTextures.find(omvt => omvt.name === modelViewerMaterial.name);

        // Restore original texture, remove any alphaMap.
        if (originalTextures && originalTextures.map) {

          modelViewerMaterial.pbrMetallicRoughness['baseColorTexture'].setTexture(originalTextures.map);
        }
        modelViewerMaterial.pbrMetallicRoughness.setAlphaMap(null);
        modelViewerMaterial.setAlphaMode('OPAQUE');
      }
    }
  }


  /**
   * Update model-viewer textures with those displayed in Showroom by obtaining them from the Showroom Object Node.
   * NOTE: Those Textures were rendered and applied to Materials using the version of Three required of the Space Provider.
   * Use the only Material.Map.Source.data the from those textures to update the Material Textures generated by Model-Viewer.
   * https://modelviewer.dev/examples/scenegraph/#swapTexturesExample
   * @param materialData 
   */
  private async applyMaterial(modelViewerMaterial: any, customMaterial: IMaterialBase): Promise<void> {

    this.logger.trace(`model-viewer.model material: ${customMaterial.name}`, modelViewerMaterial, customMaterial);
    if (!this._modelViewer) {

      return;
    }

    if (!this.objectNode()) {

      this.logger.error(`Object Prop Node id: ${this.objectAssignment().objectPropId} not found`)
      return;
    }

    // Get the MaterialData already used by the Showroom Object Prop Node
    const objectPropNodeMaterialData = this.objectNode()?.getMaterialData() ?? [];
    const objectPropMaterialData = objectPropNodeMaterialData.find(opmd => opmd.name === modelViewerMaterial.name);
    if (!objectPropMaterialData || !objectPropMaterialData.map) {

      if (0 < this._originalModelViewerTextures.length) {

        this.logger.error(`Object Prop Node material data for material: ${customMaterial.name} not found`, objectPropMaterialData)
      }
      return;
    }

    // Showroom three.js textures are not compatible with model-viewer's three.js, must convert
    const imageMapUrl = getImageUrlFromBitmapData(objectPropMaterialData.map.source.data);
    const texture = await this._modelViewer.createTexture(imageMapUrl);

    modelViewerMaterial.pbrMetallicRoughness['baseColorTexture'].setTexture(texture);
    if (objectPropMaterialData.alphaMap) {

      this.logger.error('Alpha mad texture available', objectPropMaterialData.alphaMap)
    }
    modelViewerMaterial.setDoubleSided(false);
    //modelViewerMaterial.setAlphaMode('BLEND');  // Support textures with transparency.

    if (FlipType.Vertical === customMaterial.mapFlip
      || FlipType.Both === customMaterial.mapFlip) {

      texture.sampler.setScale({ u: texture.sampler.scale.u, v: -1 });
    }
    if (FlipType.Horizontal === customMaterial.mapFlip
      || FlipType.Both === customMaterial.mapFlip) {

      texture.sampler.setWrapS(RepeatWrapping);
      texture.sampler.setScale({ u: -1, v: texture.sampler.scale.v });
    }
  }


  private async assignDesignObjectFromThisEventDesign(existingDesignObject: DesignObject): Promise<void> {

    // If matching Design Image is already assigned to this Prop then there's nothing to do
    if (0 < this.localAssignment().designObjectId) {

      this.logger.error('Design object already assigned to this prop', existingDesignObject);
      return;
    }

    this.localAssignment.update(oa => {

      oa.designObjectId = existingDesignObject.id

      return new ObjectAssignment(oa);
    });

    this.objectNode()?.setInputs(this.objectProp(), this.localAssignment(), this.designObject());
  }


  private getCopyName(currentName: string): string {

    let nameBase = currentName.replace(/\(\d+\)$/, '').trim(); // Remove numbers from end of current name
    let index = 1;
    let assignments = this.venueService.objectAssignments();
    let suffix = ` (${index++})`;
    let copyName = `${nameBase.substring(0, 255 - suffix.length)}${suffix}`;

    do {

      if (!assignments.some(oa => oa.name === copyName)) {

        return copyName;
      }
      suffix = ` (${index++})`;
      copyName = `${nameBase.substring(0, 255 - suffix.length)}${suffix}`;
    } while (index < assignments.length)

    return copyName;
  }


  handleObjectAssignmentDescriptionInput(newDescription: string): void {

    this.localAssignment.update(oa => {

      oa.description = newDescription;

      return new ObjectAssignment(oa);
    });
  }


  handleObjectAssignmentNameInput(nameEvent: any): void {

    const newName = nameEvent.target.value;

    this.localAssignment.update(oa => {

      oa.name = newName;

      return new ObjectAssignment(oa);
    });
  }


  // handleOffsetChange(offset: Vector3Obj): void {

  //   this.designObject.update(_do => {
  //     _do.offset = [offset.x, offset.y, offset.z];
  //     return _do;
  //   });

  //   // Display change on Stage
  //   this._objectNode?.setOffset(offset);

  //   // Server changes upon Save clicked
  //   this.enableSave.set(this.detectChanges());
  // }


  /**
   * Override default Object prop position for this Design.
   * @param position 
   */
  handlePositionChange(position: Vector3Obj): void {

    // Update client
    this.localAssignment.update(oa => {

      oa.position = [position.x, position.y, position.z];
      return new ObjectAssignment(oa);
    });

    // Display change on Stage
    this.objectNode()?.updateAssignment(this.localAssignment());
  }


  handlePriceOptionUpdated(priceOption: IPriceOption) {

    const index = this.localAssignment().priceOptions.findIndex(po => po.id === priceOption.id);
    if (0 > index) {

      return;
    }

    this.localAssignment.update(oa => {

      oa.priceOptions[index].label = priceOption.label;
      oa.priceOptions[index].price = priceOption.price;

      return new ObjectAssignment(oa);
    });
  }


  /**
   * Override default Object Prop rotation for this Design.
   * @param rotation 
   */
  handleRotationChange(rotation: Vector3Obj): void {

    this.localAssignment.update(oa => {

      oa.rotation = [rotation.x, rotation.y, rotation.z];

      return new ObjectAssignment(oa);
    });

    // Display change on Stage
    this.objectNode()?.updateAssignment(this.localAssignment());
  }


  handleObjectAssignmentScaleChange(scale: Vector3Obj): void {

    this.localAssignment.update(oa => {

      oa.scale = [scale.x, scale.y, scale.z];

      return new ObjectAssignment(oa);
    });

    // Display change on Stage
    this.objectNode()?.updateAssignment(this.localAssignment());
  }


  /**
   * Triggered by close event initiated by slider.
   */
  handleSliderClose() {

    this._commonSlider?.isOpen.set(false);
  }


  handleSiderInput(newValue: number) {
    // switch (this.currentFocus()) {
    //   case CurrentFocus.scale:
    //     this.changeScale(newValue);
    //     break;
    // }
  }


  // handleTargetSizeChange(targetSize: Vector3Obj): void {

  //   this.designObject.update(_do => {
  //     _do.targetSize = [targetSize.x, targetSize.y, targetSize.z];
  //     return _do;
  //   });

  //   // Display change on Stage
  //   //this._objectNode?.setTargetSize(targetSize);

  //   // Server changes upon Save clicked
  //   this.enableSave.set(this.detectChanges());
  // }


  private async importDesignObject(designObject: DesignObject): Promise<void> {

    if (0 < this._lock++) {

      return;
    }

    // If the Design Object FileName exists in the current Event Design then try to assign it
    const existingDesignObject = this.designObjects().find(_do => _do.uploadFileName === designObject.uploadFileName);
    if (existingDesignObject) {

      await this.assignDesignObjectFromThisEventDesign(existingDesignObject);
      this._lock = 0;
      return;
    }

    const newDesignObject = await firstValueFrom(this.venueService.importDesignObject(designObject.id, this.eventDesign().id));
    await this.assignDesignObjectFromThisEventDesign(newDesignObject);
    this._lock = 0;
  }


  private monitorImportObjectRequests(): void {

    this._subscriptions.push(
      this.designObjectService.importObject$
        .subscribe((designObject: DesignObject) => {

          this.sidebarService.close(IMPORT_OBJECT_SIDEBAR_ID);
          this.importDesignObject(designObject);
        })
    );
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.handleBlurCallback, false);
    elementRef?.addEventListener("focusout", this.handleBlurCallback, false);

    this._commonSlider = this.sliderService.get(SHOWROOM_COMMON_SLIDER_ID);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);
    this._subscriptions.forEach(s => s.unsubscribe());

    const elementRef = (this.elementRef.first as any).nativeElement;
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.removeEventListener("focusin", this.handleBlurCallback);
    elementRef?.removeEventListener("focusout", this.handleBlurCallback);
  }


  private readonly handleBlurCallback = this.onBlur.bind(this);
  /**
   * Slider should close when focus changes away from sliderBasedInputs
   * but not when the focus changes to the slider itself. 
   */
  onBlur() {

    // if (this._handlingBlur) {

    //   return;
    // }
    // this._handlingBlur = true;

    // const commonSlider = (this._commonSlider?.elementRef.first as any)?.nativeElement;
    // const sliderBasedInputs = (this.sliderBasedInputs.first as any)?.nativeElement;
    // if (!commonSlider || !sliderBasedInputs) {

    //   this._handlingBlur = false;
    //   return;
    // }

    // // Timeout give cycle for focusIn/Out combination to complete
    // setTimeout(() => {
    //   if (!isChildElement(commonSlider, document.activeElement) && !isChildElement(sliderBasedInputs, document.activeElement)) {

    //     this._commonSlider?.isOpen.set(false);
    //   }
    //   this._handlingBlur = false;
    // }, 1);
  }


  private _newPriceOptionId = -1; // Negative id value denotes new entity
  onAddPriceOption(): void {

    const priceOption: IPriceOption = {
      id: this._newPriceOptionId--,
      label: '',
      parentId: this.localAssignment().id,
      price: 0.0
    }

    this.localAssignment.update(oa => {

      oa.priceOptions.push(priceOption);

      return new ObjectAssignment(oa);
    })
  }


  onClose(): void {

    this.closing.set(true);
    this._commonSlider?.isOpen.set(false);
    setTimeout(() => {

      this.isOpen.set(false);
      this.closing.set(false);
    }, 200);
  }


  async onCopyObjectProp(): Promise<void> {

    if (0 < this._lock++) {

      return;
    }

    // Verify Object Prop license availablility
    if (1 > this.venueService.unassignedObjectProps().length) {

      this.logger.error('Unassigned Object Prop not found. Need to add more Object Props to the licene pool.')
      this._lock = 0;
      return;
    }
    const newObjectProp = this.venueService.unassignedObjectProps()[0];

    // Create copy of Assignment
    let objectAssignment = new ObjectAssignment();
    objectAssignment.name = this.getCopyName(this.objectAssignment().name);
    objectAssignment.eventDesignId = this.objectAssignment().eventDesignId;
    objectAssignment.designObjectId = this.objectAssignment().designObjectId;
    objectAssignment.objectPropId = newObjectProp.id;
    objectAssignment.position = this.objectAssignment().position;
    objectAssignment.rotation = this.objectAssignment().rotation;
    objectAssignment.scale = this.objectAssignment().scale;

    const newAssignment = await firstValueFrom(this.venueService.createObjectAssignment(objectAssignment));

    // Create Object Node on stage for the new Object Prop
    const objectNode = this.Stage().addObjectProp(newObjectProp);
    const world = this.objectNode()?.world;
    if (world) {

      objectNode.updateWorld(world);
    }
    objectNode.setInputs(newObjectProp, newAssignment, this.designObject());

    // Select the new Object Prop
    this.venueService.setCurrentObjectProp(newObjectProp.id);
    this.venueService.setCurrentObjectAssignment(newAssignment);

    setTimeout(() => this.showroomService.state().instance.handlePropClickInteraction(newObjectProp));
    this._lock = 0;
  }


  private _deletedPriceOptions: IPriceOption[] = [];
  onDeletePriceOption(priceOptionId: number): void {

    const index = this.localAssignment().priceOptions.findIndex(po => po.id === priceOptionId);
    if (0 > index) {

      return;
    }

    this.localAssignment.update(oa => {

      // If Price Option originated on the server then record deletion for execution upon save.
      if (0 < oa.priceOptions[index].id) {

        this._deletedPriceOptions.push(oa.priceOptions[index]);
      }
      oa.priceOptions.splice(index, 1);

      return new ObjectAssignment(oa);
    });
  }


  onImportDesignObject() {

    this.designObjectService.initialize(true);
    this.sidebarService.open(IMPORT_OBJECT_SIDEBAR_ID, false);
  }


  async onModelViewerLoaded(event: any): Promise<void> {

    this._modelViewer = document.getElementById(this.id);
    if (!this._modelViewer) {

      this.logger.error(`${this.onModelViewerLoaded.name} - model-viewer not found`)
      return;
    }

    this._modelViewerModel = this._modelViewer.model;
    if (!this._modelViewerModel || 1 > this._modelViewerModel.materials) {

      this.logger.warn(`${this.onModelViewerLoaded.name} - model-viewer.model not found or has no materials`, this._modelViewerModel);
      return;
    }

    this._originalModelViewerTextures = [];
    let modelViewerMaterial: any;
    for (modelViewerMaterial of this._modelViewerModel.materials) {

      // AlphaMap on source object material almost never happens. 
      // If the use case surfaces then modify model-viewer to expose access alphaMap to store and reapply alphaMap in the same manner as map.
      this._originalModelViewerTextures.push(<MaterialTexture>{
        alphaMap: (modelViewerMaterial as any).alphaMap,
        map: modelViewerMaterial.pbrMetallicRoughness['baseColorTexture'].texture ?? null,
        name: modelViewerMaterial.name
      })
    }

    const canActivateAR = this._modelViewer.canActivateAR;

    this.logger.trace(`Can activate AR: ${canActivateAR}`);
    this.canActivateAR.set(canActivateAR);
    await this.applyDesignObjectMaterial();
  }


  onRemoveDesignObject(event: any) {

    // Prevent button click from passing to underlying div which selects the DesignImage and Assignment.
    event.stopPropagation();
    if (1 > this.objectAssignment().id || 0 < this._lock++) {

      return;
    }

    this.localAssignment.update(oa => {

      oa.designObjectId = 0

      return new ObjectAssignment(oa);
    });

    this.objectNode()?.setInputs(this.objectProp(), this.localAssignment(), this.designObject());
    this._lock = 0;
  }


  async onRemoveFromDesign(): Promise<void> {

    if (0 < this._lock++) {

      return;
    }
    this.onClose();
    this.Stage().removeObjectProp(this.objectProp());
    await firstValueFrom(this.venueService.deleteObjectAssignment(this.localAssignment()));
    this._lock = 0;
  }


  async onSave(): Promise<void> {

    if (0 < this._lock++) {

      return;
    }

    // Delete deleted price options first
    for (const deletedPriceOption of this._deletedPriceOptions) {

      if (0 < deletedPriceOption.id) {

        await firstValueFrom(this.venueService.deleteObjectPriceOption(deletedPriceOption.id));
      }
    }
    // Remove deleted price options from assignment
    this.localAssignment.update(oa => {

      oa.priceOptions = oa.priceOptions.filter(ia => !this._deletedPriceOptions.some(dpo => dpo.id === ia.id));

      return oa;
    });
    this._deletedPriceOptions = [];

    const updatedAssignment = await firstValueFrom(this.venueService.updateObjectAssignment(this.localAssignment()));
    // Sync updates as saved
    this.localAssignment.set(new ObjectAssignment(updatedAssignment));

    this._lock = 0;
  }


  onSelectTab(index: number): void {

    this.selectedTab.set(index);
    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams: { cot: `${index}` },
        queryParamsHandling: 'merge'
      })
  }


  async onToggleEnableCart(): Promise<void> {

    this.localAssignment.update(oa => {

      oa.enableCart = !oa.enableCart;

      return new ObjectAssignment(oa);
    });

    if (1 > this.localAssignment().priceOptions.length) {

      await this.onAddPriceOption();
    }
  }


  onToggleEnableInteraction() {

    this.localAssignment.update(oa => {

      oa.enableInteraction = !oa.enableInteraction;

      return new ObjectAssignment(oa);
    });

    // Display change on Stage
    this.objectNode()?.setEnableInteraction(this.localAssignment().enableInteraction);
  }


  onUndo(): void {

    if (0 < this._lock++) {

      return;
    }

    if (this.enableSave()) {

      this.localAssignment.set(new ObjectAssignment(this.objectAssignment()));

      // Update stage with original data
      this.objectNode()?.setInputs(this.objectProp(), this.localAssignment(), this.designObject());
    }

    this._lock = 0;
  }


}
