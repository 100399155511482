import { Component, Input, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
   
  readonly checked = signal(false);
  @Input() set Checked(value: boolean) {
    this.checked.set(value);
  }
  
  readonly Label = input('');
  readonly IsReadOnly = input(false);
  
  readonly Toggled = output<boolean>();
  

  toggle() {
    this.checked.set(!this.checked());
    this.Toggled.emit(this.checked());
  }
}
