import { Component, Input, computed, effect, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapArrowCounterclockwise, bootstrapArrowLeft, bootstrapArrowUp, bootstrapCheckLg, bootstrapTrash, bootstrapPlusLg, bootstrapXLg, bootstrapPen } from "@ng-icons/bootstrap-icons";
import { DesignImage, equalsImageAssignment, IDesignImage, IDesignImageUpload, ImageAssignment, ImageProp, IPriceOption } from 'projects/my-common/src/model';
import { Vector3InputComponent } from "../../../../shared/vector3-input/vector3-input.component";
import { PositionAdjustmentComponent } from "../../../../shared/position-adjustment/position-adjustment.component";
import { Subscription } from 'rxjs/internal/Subscription';
import { NGXLogger } from 'ngx-logger';
import { IStage } from 'src/app/core/model/showroom/showroom.model';
import { VenueService } from 'src/app/core/service/venue/venue.service';
import { firstValueFrom } from 'rxjs';
import { FloatingSliderComponent } from 'src/app/components/shared/floating-slider/floating-slider.component';
import { NumberInputScrollDirective } from 'src/app/shared/directives/number-input-scroll.directive';
import { TextEditorComponent } from "../../../../shared/text-editor/text-editor.component";
import { DesignImageService } from 'src/app/core/service/venue/design-image.service';
import { IMPORT_IMAGE_SIDEBAR_ID } from '../../import-image-sidebar/import-image-sidebar.component';
import { CheckboxComponent } from "../../../../shared/checkbox/checkbox.component";
import { NoCommaPipe } from 'src/app/shared/pipes/no-comma.pipe';
import { ProgressBarComponent } from "../../../../shared/progress-bar/progress-bar.component";
import { ColorPickerModule } from 'ngx-color-picker';
import { TextureFont } from 'projects/my-common/src';
import { PriceOptionsComponent } from "../../../../shared/price-options/price-options.component";
import { ModalService } from 'src/app/core/service/ui/modal/modal.service';
import { ALERT_MODAL_ID } from 'src/app/components/shared/alert-modal/alert-modal.component';


/**
 * Configure the design and interactions of the specified Image Prop Assignment.
 * We don't delete DesignImages because they might be referenced by other ImageProps.
 */
@Component({
  selector: 'app-configure-image-assignment',
  standalone: true,
  templateUrl: './configure-image-assignment.component.html',
  styleUrls: ['./configure-image-assignment.component.scss'],
  providers: [provideIcons({ bootstrapArrowCounterclockwise, bootstrapArrowLeft, bootstrapArrowUp, bootstrapCheckLg, bootstrapPen, bootstrapPlusLg, bootstrapTrash, bootstrapXLg })],
  imports: [CommonModule, ColorPickerModule, FloatingSliderComponent, NgIconComponent, NoCommaPipe, NumberInputScrollDirective, Vector3InputComponent, PositionAdjustmentComponent, TextEditorComponent,
    CheckboxComponent, ProgressBarComponent, PriceOptionsComponent]
})
export class ConfigureImageAssignmentComponent {

  private readonly _subscriptions: Subscription[] = [];
  id = crypto.randomUUID();
  private _lock = 0;
  readonly localAssignment = signal(new ImageAssignment());

  readonly fonts = Object.values(TextureFont).filter(value => typeof value !== 'number');
  readonly fontKeys = Object.keys(TextureFont).filter(key => typeof key !== 'number');

  //
  // Inputs
  //
  readonly EnableSwapAssignments = input.required<boolean>();
  readonly IsDefault = input.required<boolean>();
  @Input({ required: true }) set ImageAssignment(value: ImageAssignment) {

    // We want to retain our local state while editing so that we can undo.
    // Adding a Design Image triggers a change in the aggregate state which we want to ignore from a configure assignment perspective.
    // Swapping assignments updates the aggregate state and can be triggered from this or other assignments while editing is in progress.
    // Swapping assignment changes must be allow to flow through for the UI to properly reflect the new state.
    // Pending local changes will be lost when swapping assignments is triggered.
    if (!this.venueService.isSwappingAssignments
      && 0 < this.localAssignment().id
      && this.localAssignment().id === this.currentImageAssignment().id) {

      return;
    }

    this.localAssignment.set(new ImageAssignment(value));
    if (this._updateInitializers) {

      this.fontSizeInitializer.set(value.fontSize);
    } else {

      this._updateInitializers = true;
    }
  }
  readonly Stage = input.required<IStage>()
  //
  // End inputs
  //

  //
  // Outputs
  //
  /**
   * Broadcast data changes as Event Design changes so that Showroom will apply aggregate updates to the stage
   */
  readonly EventDesignChanged = output<number>();
  readonly ScrollToPosition = output<string>();
  //
  // End outputs
  //

  //
  // Selectors
  //
  readonly enableSave = computed(() => 0 < this.localAssignment().id
    && !equalsImageAssignment(this.localAssignment(), this.imageAssignment()));
  readonly eventDesign = this.venueService.currentEventDesign;
  readonly currentDesignImage = this.venueService.currentDesignImage;
  readonly currentImageAssignment = this.venueService.currentImageAssignment;
  readonly defaultDesignImages = this.venueService.defaultDesignImages;
  readonly defaultEventDesign = this.venueService.defaultEventDesign;
  readonly defaultImageAssignments = this.venueService.defaultImageAssignments;
  readonly designImage = computed(() => {

    if (this.localAssignment().designImageId && 0 < this.localAssignment().designImageId) {

      if (this.IsDefault()) {

        return this.defaultDesignImages().find(ddi => ddi.id === this.localAssignment().designImageId) ?? new DesignImage();
      } else {

        return this.designImages().find(di => di.id === this.localAssignment().designImageId) ?? new DesignImage()
      }
    }

    return new DesignImage();
  });
  readonly designImages = this.venueService.designImages;
  readonly designImagesForProp = computed(() => this.designImages()
    .filter(di => this.localAssignment().designImageId === di.id
      || this.imageAssignmentsForProp().some(ia => ia.designImageId === di.id)));
  readonly imageAssignment = computed(() => (this.IsDefault() ?
    this.defaultImageAssignments().find(dia => dia.id === this.localAssignment().id) :
    this.imageAssignments().find(ia => ia.id === this.localAssignment().id)) ?? new ImageAssignment())
  readonly imageAssignments = computed(() => this.venueService.imageAssignments()
    .sort((ia1, ia2) => ia1.id - ia2.id));
  readonly imageAssignmentsForProp = computed(() =>  // Exlcudes local changes
    this.imageAssignments().filter(ia => ia.imagePropId === this.imageProp().id)
      .sort((ia1, ia2) => ia1.id - ia2.id));
  readonly imageProp = computed(() => this.venueService.imageProps()
    .find(ip => ip.id === this.localAssignment().imagePropId) ?? new ImageProp());
  readonly imageNode = computed(() => this.Stage() && this.Stage().getImageNode ?
    this.Stage().getImageNode(this.localAssignment().imagePropId) : undefined);
  //
  // End selectors
  //



  //
  // Input initializers
  //
  private _updateInitializers = true;
  readonly fontSizeInitializer = signal(0);

  readonly uploadingProgress = signal(0);
  readonly uploadingFileName = signal('');


  constructor(private readonly designImageService: DesignImageService,
    private readonly logger: NGXLogger,
    private readonly modalService: ModalService,
    private readonly sidebarService: SidebarService,
    private readonly venueService: VenueService) {

    this.monitorImportImageRequests();
  }


  camelCaseToSpaced(camelCase: any): string {

    return camelCase.replace(/([a-z])([A-Z])/g, '$1 $2');
  }


  /**
   * Handle Design Image creation here. 
   * Emit create Image Assignment to invoke the data refresh.
   * @param event 
   * @returns 
   */
  async addDesignImage(event: any): Promise<void> {

    if (!event || 0 < this._lock++) {

      return;
    }

    const file = event.target.files[0];
    var sFileName = file.name;

    let designImage = this.designImages().find(di => di.uploadFileName === sFileName);
    if (designImage) {

      this._lock = 0;
      await this.importDesignImage(designImage);
      return;
    }

    var iFileSize = file.size;
    var iConvert = (file.size / 1048578).toFixed(2);

    if (iFileSize > 29360128) {

      let txt = `File size: ${iConvert} MB. Please make sure your image file is less than 28 MB.`;
      this.modalService.setTarget(ALERT_MODAL_ID, txt);
      this.modalService.open(ALERT_MODAL_ID);
      
      this._lock = 0;
      return;
    }

    const newDesignImage = <IDesignImageUpload>{
      id: 0,
      eventDesignId: this.eventDesign().id,
      file: file,
      fileName: sFileName
    }

    // When the upload it 100% complete the server is still copying the file to blob storage before responding.
    this.uploadingProgress.set(0);
    this.uploadingFileName.set(sFileName);
    designImage = await firstValueFrom(this.venueService.createDesignImage(newDesignImage as IDesignImageUpload,
      (percentDone: number) => this.uploadingProgress.set(percentDone)
    ));
    this.uploadingFileName.set('');
    this.uploadingProgress.set(0);

    await this.assignDesignImageFromThisEventDesign(designImage);
    this._lock = 0;
  }


  handleFontSizeInput(fontSizeInputEvent: any) {

    const newFontSize = Number(fontSizeInputEvent.target.value);

    this.localAssignment.update(ia => {

      ia.fontSize = Math.floor(newFontSize);

      return new ImageAssignment(ia);
    });

    this.imageNode()?.upsertAssignment(this.localAssignment(), this.designImagesForProp());
  }


  handleImageAssignmentDescriptionInput(newDescription: string) {

    this.localAssignment.update(ia => {

      ia.description = newDescription;

      return new ImageAssignment(ia);
    });
  }


  handleNameInput(nameEvent: any): void {

    const newName = nameEvent.target.value;

    this.localAssignment.update(ia => {
      
      ia.name = newName;

      return new ImageAssignment(ia);
    });
  }


  handleTextInput(textEvent: any): void {

    const newText = textEvent.target.value;

    this.localAssignment.update(ia => {

      ia.text = newText;
      
      return new ImageAssignment(ia);
    });

    this.imageNode()?.upsertAssignment(this.localAssignment(), this.designImagesForProp());
  }


  private async assignDesignImageFromThisEventDesign(existingDesignImage: DesignImage): Promise<void> {

    // If matching Design Image is already assigned to this Prop then there's nothing to do
    const existingAssignment = this.imageAssignments()
      .find(ia => ia.designImageId === existingDesignImage.id && ia.imagePropId === this.imageProp().id);
    if (existingAssignment) {

      this.logger.error('Already assigned to this prop', existingDesignImage);
      return;
    }

    this.localAssignment.update(ia => {
      
      ia.designImageId = existingDesignImage.id

      return new ImageAssignment(ia);
    });

    this.imageNode()?.upsertAssignment(this.localAssignment(), this.designImagesForProp());
  }


  handlePriceOptionUpdated(priceOption: IPriceOption) {

    const index = this.localAssignment().priceOptions.findIndex(po => po.id === priceOption.id);
    if (0 > index) {

      return;
    }

    this.localAssignment.update(oa => {

      oa.priceOptions[index].label = priceOption.label;
      oa.priceOptions[index].price = priceOption.price;

      return new ImageAssignment(oa);
    });
  }


  /**
   * Import and assign the specified DesignImage to this Prop.
   * @param designImage 
   */
  private async importDesignImage(designImage: IDesignImage) {

    if (0 < this._lock++) {

      return;
    }

    // If the Design Image FileName exists in the current Event Design then try to assign it
    const existingDesignImage = this.designImages().find(di => di.uploadFileName === designImage.uploadFileName);
    if (existingDesignImage) {

      this._lock = 0;
      await this.assignDesignImageFromThisEventDesign(existingDesignImage);
      return;
    }

    const newDesignImage = await firstValueFrom(this.venueService.importDesignImage(designImage.id, this.eventDesign().id));
    await this.assignDesignImageFromThisEventDesign(newDesignImage);
    this._lock = 0;
  }


  private monitorImportImageRequests() {

    this._subscriptions.push(
      this.designImageService.importImage$
        .subscribe((designImage: IDesignImage) => {

          if (this.localAssignment().id === this.venueService.currentImageAssignment().id) {

            this.sidebarService.close(IMPORT_IMAGE_SIDEBAR_ID);
            this.importDesignImage(designImage);
          }
        })
    );
  }


  ngOnDestroy(): void {

    this._subscriptions.forEach(s => s.unsubscribe());
  }


  private _newPriceOptionId = -1; // Negative id value denotes new entity
  onAddPriceOption(): void {

    const priceOption: IPriceOption = {
      id: this._newPriceOptionId--,
      label: '',
      parentId: this.localAssignment().id,
      price: 0.0
    }

    this.localAssignment.update(oa => {

      oa.priceOptions.push(priceOption);

      return new ImageAssignment(oa);
    })
  }


  /**
   * Auto close is a little annoying on highly interactive, configuration type slide outs.
   */
  private readonly handleBlurCallback = this.onBlur.bind(this);
  onBlur() {

    // if (this.enableSave() || this.enableDesignImageSave() || this._handlingBlur) {

    //   return
    // };
    // this._handlingBlur = true;

    // const elementRef = (this.elementRef.first as any).nativeElement;
    // if (!elementRef) {
    //   this._handlingBlur = false;
    //   return;
    // }

    // // Timeout give cycle for focusIn/Out combination to complete
    // setTimeout(() => {
    //   if (!isChildElement(elementRef, document.activeElement)) {
    //     this.isOpen.set(false);
    //   }
    //   this._handlingBlur = false;
    // }, 1);
  }


  onBlurFontSize() {

    this.fontSizeInitializer.set(this.localAssignment().fontSize);
  }


  async onDeleteImageAssignment(event: any): Promise<void> {

    // Prevent button click from passing to underlying div which selects the DesignImage and Assignment.
    event.stopPropagation();
    if (0 < this._lock++) {

      return;
    }
    this.onUndoChanges();
    if (1 > this.imageAssignment().id) {

      this._lock = 0;
      return;
    }

    await firstValueFrom(this.venueService.deleteImageAssignment(this.imageAssignment()));
    this.imageNode()?.setInputs(this.imageProp(), this.imageAssignmentsForProp(), this.designImagesForProp());

    this._lock = 0;
  }


  private _deletedPriceOptions: IPriceOption[] = [];
  onDeletePriceOption(priceOptionId: number): void {

    const index = this.localAssignment().priceOptions.findIndex(po => po.id === priceOptionId);
    if (0 > index) {

      return;
    }

    this.localAssignment.update(oa => {

      // If Price Option originated on the server then record deletion for execution upon save.
      if (0 < oa.priceOptions[index].id) {

        this._deletedPriceOptions.push(oa.priceOptions[index]);
      }
      oa.priceOptions.splice(index, 1);

      return new ImageAssignment(oa);
    });
  }


  onImportDesignImage() {

    this.designImageService.initialize(true);
    this.sidebarService.open(IMPORT_IMAGE_SIDEBAR_ID, false);
  }


  onRemoveDesignImage(event: any): void {

    // Prevent button click from passing to underlying div which selects the DesignImage and Assignment.
    event.stopPropagation();
    if (1 > this.imageAssignment().id || 0 < this._lock++) {

      return;
    }

    this.localAssignment.update(ia => {

      ia.designImageId = 0;

      return new ImageAssignment(ia);
    })

    this.imageNode()?.upsertAssignment(this.localAssignment(), this.designImagesForProp());
    this._lock = 0;
  }


  async onSave(): Promise<void> {

    if (0 < this._lock++) {

      return;
    }

    // Delete deleted price options first
    for (const deletedPriceOption of this._deletedPriceOptions) {

      if (0 < deletedPriceOption.id) {

        await firstValueFrom(this.venueService.deleteImagePriceOption(deletedPriceOption.id));
      }
    }
    // Remove deleted price options from assignment
    this.localAssignment.update(ia => {

      ia.priceOptions = ia.priceOptions.filter(ia => !this._deletedPriceOptions.some(dpo => dpo.id === ia.id));

      return ia;
    });
    this._deletedPriceOptions = [];

    const updatedAssignment = await firstValueFrom(this.venueService.updateImageAssignment(this.localAssignment()));
    // Sync updates as saved
    this.localAssignment.set(new ImageAssignment(updatedAssignment));

    // Remove this as selected Image Assignment
    this.venueService.setCurrentImageAssignment(new ImageAssignment());

    this._lock = 0;
  }


  onSelectAssignment() {

    this.venueService.setCurrentImageAssignment(this.localAssignment());
    this.imageNode()?.upsertAssignment(this.localAssignment(), this.designImagesForProp());
    setTimeout(() => this.ScrollToPosition.emit(this.id));
  }


  onSelectFont(logLevelEvent: any) {

    let selectedIndex = Number(logLevelEvent.target['options'].selectedIndex);

    this.localAssignment.update(ia => {

      ia.font = this.fontKeys[selectedIndex] as TextureFont;

      return new ImageAssignment(ia);
    })

    this.imageNode()?.upsertAssignment(this.localAssignment(), this.designImages());
  }


  private _swapping = false;
  async onSwapImageAssignments(event: any) {

    event.stopPropagation();
    if (0 < this._lock++) {

      return;
    }

    // This Image Assignment muse be real
    // There must be at lease 2 Image Assignments in order to swap
    // There must be an Image Assignment BEFORE this Image Assignment (by Id) to swap up into
    if (1 > this.imageAssignment().id
      || 2 > this.imageAssignmentsForProp().length
      || this.imageAssignment().id === this.imageAssignmentsForProp()[0].id) {

      this._lock = 0;
      return;
    }

    // Find the Image Assignment before this Image Assignment (by Id)
    let index = 0;
    let priorImageAssignment = this.imageAssignmentsForProp()[index++];
    while (index < this.imageAssignmentsForProp().length
      && this.imageAssignment().id !== this.imageAssignmentsForProp()[index].id) {

      priorImageAssignment = this.imageAssignmentsForProp()[index++];
    }

    // If end of Image Assignments was reached then we couldn't find an Image Assignment before this.
    if (index >= this.imageAssignmentsForProp().length) {

      this._lock = 0;
      return;
    }

    await firstValueFrom(this.venueService.swapImageAssignments([
      priorImageAssignment,
      this.imageAssignment()
    ]));

    this.imageNode()?.setInputs(this.imageProp(), this.imageAssignmentsForProp(), this.designImagesForProp());
    this._lock = 0;
  }


  async onToggleEnableCart(): Promise<void> {

    this.localAssignment.update(ia => {

      ia.enableCart = !ia.enableCart

      return new ImageAssignment(ia);
    });

    if (1 > this.localAssignment().priceOptions.length) {

      this.onAddPriceOption();
    }
  }


  onToggleEnableInteraction() {

    this.localAssignment.update(ia => {

      ia.enableInteraction = !ia.enableInteraction;
      if (!ia.enableInteraction) {

        ia.enableCart = false;
      } else {

        ia.enableCart = this.imageAssignment().enableCart;
      }

      return new ImageAssignment(ia);
    });
  }


  onToggleStretchToFit() {

    this.localAssignment.update(ia => {

      ia.stretchToFit = !ia.stretchToFit

      return new ImageAssignment(ia);
    });

    this.imageNode()?.upsertAssignment(this.localAssignment(), this.designImages());
  }


  onToggleSuppressSidebarImage() {

    this.localAssignment.update(ia => {

      ia.suppressSidebarImage = !ia.suppressSidebarImage

      return new ImageAssignment(ia);
    });
  }


  onUndoChanges() {

    this.localAssignment.set(new ImageAssignment(this.imageAssignment()));
    this.imageNode()?.upsertAssignment(this.imageAssignment(), this.designImages());
    this.venueService.setCurrentImageAssignment(new ImageAssignment());
  }
}
