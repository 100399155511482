import { Component, effect, Input, input, output, signal } from '@angular/core';
import { ResizeBorderComponent } from "../resize-border/resize-border.component";
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapArrowsMove, bootstrapXLg } from "@ng-icons/bootstrap-icons";
import { NGXLogger } from 'ngx-logger';
import { timeout } from 'projects/my-common/src';

export type SliderCurrentValue = { value: number };

@Component({
    selector: 'app-floating-slider',
    standalone: true,
    templateUrl: './floating-slider.component.html',
    styleUrl: './floating-slider.component.scss',
    imports: [CommonModule, NgIconComponent, ResizeBorderComponent],
    providers: [provideIcons({ bootstrapArrowsMove, bootstrapXLg })]
})
export class FloatingSliderComponent {

    readonly closeClicked = signal(false);

    currentValue = 0;
    readonly CurrentValue = input<SliderCurrentValue>({ value: 0 })
    
    readonly Label = input('');
    
    readonly isOpen = signal(false);
    //readonly IsOpen = input(false);
    // We update the signal later and using effect is unstable
    @Input() set IsOpen(value: boolean) {

        this.isOpen.set(value);
    }
    

    readonly OnChange = output<number>();
    readonly OnClose = output<boolean>();

    readonly inputValue = signal(0);
    readonly Multiplier = input(0);
    private _multiplier = .0003;
    private _processingInput = false;


    constructor(private readonly _logger: NGXLogger) { 

        effect(() => {
            this.currentValue = this.CurrentValue().value;
        })
    }


    handleClose() {

        this.closeClicked.set(true);
        this.OnClose.emit(true);
        setTimeout(() => {

            this.isOpen.set(false);
            this.closeClicked.set(false);
        }, 200);
    }


    handleInputChange(event: any) {

        this.inputValue.set(Number(event.target.value));
        this.processInput();
    }


    handleMouseUp() {

        this.inputValue.set(0);
    }


    private async processInput() {

        if (this._processingInput) {
            
            return
        };
        this._processingInput = true;

        // Continue to emit updates while slider input is not 0
        while (0 !== this.inputValue()) {

            this.currentValue = this.currentValue + (Math.max(this._multiplier, this.Multiplier()) * this.inputValue());
            this.OnChange.emit(this.currentValue);
            await timeout();
        }
        this._processingInput = false;
    }


}
