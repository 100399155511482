<div class="flex w-full overflow-hidden">
    
    @if (DisplayHelp()) {

        <p>Designs provide the ability to share multiple Showroom options with your Guests.</p>

    }

    @if (0 < EventDesigns().length) { 

    <fieldset class="block">

            @for (eventDesign of EventDesigns(); track $index) {

                <label class="flex flex-row items-center py-2 w-full min-w-0">
                    <input class="form-radio" type="radio" [checked]="eventDesign.id === selectedEventDesignId()"
                        name="radio-direct" [value]="$index" (click)="selectDesign(eventDesign)" />
                    <div class="ml-2 overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {{ eventDesign.name }}
                    </div>
                </label>

            }

    </fieldset>

    } @else() {

        <div class="w-full text-center">No Designs defined for Event</div>

    }
</div>