import { Object3D } from "three";
import { Vector3Obj } from "../../util/utils";
import { PositionComponent, RotationComponent, ScaleComponent, object3dByEntityId } from "..";
import { IWorld, addComponent, addEntity, defineQuery, removeEntity } from "bitecs";
import { WorldState } from "../ecs-world.state";
import { EnvironmentComponent } from "../..";

/**
 * Definitions resources and functions supporting Envirnment Props in ECS
 * Right now we are reusing Object Prop logic
 */
export interface EnvironmentEntity {
    /**
     * The group containing the environment objects.
     */
    objectGroup: Object3D;
    /**
     * The object within the group which can be positionally offset within the group. 
     * For example, when objects origin is not centered in a desirable manner.
     */
    position: Vector3Obj;   // base
    rotation: Vector3Obj;   // base
    scale: Vector3Obj;      // base
}


export const environmentQuery = defineQuery([EnvironmentComponent]);


/**
 * @returns the single entity id for the Environment
 */
function getEntityIdForEnvironment(world: IWorld): number {

    const environmentEntities = environmentQuery(world);
    for (let i = 0; i < environmentEntities.length; i++) {

            return environmentEntities[i];
    }

    return -1;
}


export function removeEnvironment(world: IWorld): void {

    const eidForEnvironment = getEntityIdForEnvironment(world);
    if (0 > eidForEnvironment) {

        WorldState.logger.error('Environment entity id not found.');
        return;
    }

    // Remove reference
    delete object3dByEntityId[eidForEnvironment];
    removeEntity(world, eidForEnvironment);
}


/**
 * Update component values for Object Prop entity.
 * @param environmentEntity 
 */
function updateEnvironmentComponentValues(world: IWorld, environmentEntity: EnvironmentEntity, eidForEnvironment: number) {

    // Update object references
    object3dByEntityId[eidForEnvironment] = environmentEntity.objectGroup;

    // Set component values
    PositionComponent.x[eidForEnvironment] = environmentEntity.position.x;
    PositionComponent.y[eidForEnvironment] = environmentEntity.position.y;
    PositionComponent.z[eidForEnvironment] = environmentEntity.position.z;
    RotationComponent.x[eidForEnvironment] = environmentEntity.rotation.x;
    RotationComponent.y[eidForEnvironment] = environmentEntity.rotation.y;
    RotationComponent.z[eidForEnvironment] = environmentEntity.rotation.z;
    ScaleComponent.x[eidForEnvironment] = environmentEntity.scale.x;
    ScaleComponent.y[eidForEnvironment] = environmentEntity.scale.y;
    ScaleComponent.z[eidForEnvironment] = environmentEntity.scale.z;
}


/**
 * Register Environment in the ecs system.
 * @param world 
 * @param environment 
 */
export function upsertEnvironment(world: IWorld, environment: EnvironmentEntity): void {

    let eidForEnvironment = getEntityIdForEnvironment(world);

    if (0 > eidForEnvironment) {

        setTimeout(() => {
            
            // Get an ECS entity id for the object. Sometimes addEntity returns undefined.
            do eidForEnvironment = addEntity(world)
            while (!eidForEnvironment || 0 > eidForEnvironment);

            // Flag as an object entity for queries.
            addComponent(world, EnvironmentComponent, eidForEnvironment);

            // Component data to track
            addComponent(world, PositionComponent, eidForEnvironment);
            addComponent(world, RotationComponent, eidForEnvironment);
            addComponent(world, ScaleComponent, eidForEnvironment);

            updateEnvironmentComponentValues(world, environment, eidForEnvironment);
        });
    }

    updateEnvironmentComponentValues(world, environment, eidForEnvironment);
}