import { Types, defineComponent } from 'bitecs';
import { ChildrenEntityIdMap, SizeSchema, Vector3Schema } from './shared.component';
import { Vector3Like } from 'three';

/**
 * acticonChildrenEntityMap[parentEntityId] = [acticonEntityId1, acticonEntityId2, etc]
 */
export const acticonChildrenEntityMap: ChildrenEntityIdMap = {};

/**
 * Values common across group of acticons by parent entity.
 * i.e. Video entity has next, pause and play Acticons associated with it.
 */
export const ActiconBaseComponent = defineComponent({
    acticonAlignment: Types.i8,
    acticonMargin: Types.f32,
    acticonZ: Types.f32
});


export enum ActiconType { Info, Next, Pause, Play }

/**
 * Acticon specific state data around.
 */
export interface IActiconState {

    animationDuration: number
    animationTimer: number
    perpendicularMargin: number
    scaleFrom: Vector3Like
    scaleTo: Vector3Like
    size: { h: number, w: number }
    sort: number
}


/**
 * Acticon specific component values.
 */
export const ActiconComponent = defineComponent({

    parentEid: Types.eid,
    animationDuration: Types.i32,
    animationTimer: Types.i32,
    perpendicularMargin: Types.f32,
    scale: Vector3Schema,
    scaleFrom: Vector3Schema,
    size: SizeSchema,
    sort: Types.i32,
    /**
     * Next, Pause, Info, Play, etc
     */
    type: Types.i32 
});
