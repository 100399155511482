import { AfterViewInit, Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import * as THREE from 'three';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent implements AfterViewInit {

  private _camera!: THREE.PerspectiveCamera;
  private _scene!: THREE.Scene;
  private _renderer!: THREE.Renderer;
  private _material!: THREE.PointsMaterial
  private _windowHalfX = window.innerWidth / 2;
  private _windowHalfY = window.innerHeight / 2;
  private _mouseX = 0;
  private _mouseY = 0;


  constructor(private readonly logger: NGXLogger) { }


  private animate() {
    requestAnimationFrame(this.animate.bind(this));

    this.render();
  }


  private init(): void {

    this._camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 2, 2000);
    this._camera.position.z = 1000;

    this._scene = new THREE.Scene();
    this._scene.fog = new THREE.FogExp2(0x000000, 0.001);

    const geometry = new THREE.BufferGeometry();
    const vertices = [];

    const sprite = new THREE.TextureLoader().load('assets/sprites/disc.png');
    (sprite as any).colorSpace = THREE.SRGBColorSpace;

    for (let i = 0; i < 10000; i++) {

      const x = 2000 * Math.random() - 1000;
      const y = 2000 * Math.random() - 1000;
      const z = 2000 * Math.random() - 1000;

      vertices.push(x, y, z);

    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    this._material = new THREE.PointsMaterial({ size: 35, sizeAttenuation: true, map: sprite, alphaTest: 0.5, transparent: true });
    this._material.color.setHSL(1.0, 0.3, 0.7, THREE.SRGBColorSpace);

    const particles = new THREE.Points(geometry, this._material);
    this._scene.add(particles);

    this._renderer = new THREE.WebGLRenderer();
    (this._renderer as any).setPixelRatio(window.devicePixelRatio);
    this._renderer.setSize(window.innerWidth, window.innerHeight);

    const placeholderDiv = document.getElementById("placeholder");
    if (null !== placeholderDiv && null != placeholderDiv.parentNode) {

      this.logger.trace(`placeholderDiv, three domElement`, placeholderDiv, this._renderer.domElement);
      placeholderDiv.parentNode.insertBefore(this._renderer.domElement, placeholderDiv);
    }

    document.body.style.touchAction = 'none';
    const that = this;
    document.body.addEventListener('pointermove', (event) => {              //this.onPointerMove );
      if (event.isPrimary === false) return;

      that._mouseX = event.clientX - that._windowHalfX;
      that._mouseY = event.clientY - that._windowHalfY;
    });
    window.addEventListener('resize', (event) => {
      that._windowHalfX = window.innerWidth / 2;
      that._windowHalfY = window.innerHeight / 2;

      that._camera.aspect = window.innerWidth / window.innerHeight;
      that._camera.updateProjectionMatrix();

      that._renderer.setSize(window.innerWidth, window.innerHeight);
    });

    this.animate();
  }


  ngAfterViewInit(): void {

    this.init();
  }


  render() {
    const time = Date.now() * 0.00005;

    this._camera.position.x += (this._mouseX - this._camera.position.x) * 0.05;
    this._camera.position.y += (- this._mouseY - this._camera.position.y) * 0.05;

    this._camera.lookAt(this._scene.position);

    const h = (360 * (1.0 + time) % 360) / 360;
    this._material.color.setHSL(h, 0.5, 0.5);

    this._renderer.render(this._scene, this._camera);
  }


}
