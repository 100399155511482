import { MatterportService } from '../../core/service/matterport/matterport.service';
import { ComponentInteractionType, SceneComponent } from 'projects/mp-common/src';
import { CAMERA_INPUT_COMPONENT_TYPE, CameraInput } from 'projects/mp-common/src/sdk-components/Camera/CameraInput';
import { Subscription } from 'rxjs/internal/Subscription';
import { ISubscription, Scene } from 'static/sdk';
import { getLogger } from 'projects/my-common/src/util/log';
import { Euler, Matrix4, Quaternion, Vector3 } from 'three';



export class ThreeHelper {
  private cameraInputComponent: Scene.IComponent = <Scene.IComponent>{};
  private _subscriptions: Subscription[] = [];
  private _iSubscriptions: ISubscription[] = [];
  public eventType = ComponentInteractionType.HOVER;
  private readonly _logger = getLogger();


  constructor(private _matterportService: MatterportService) { }


  /**
   * Setup the THREE Scene camera
   * @param theSdk the Matterport sdk
   */
  async createCameraControl() {
    const that = this;

    // New camera in the Scene which tracks against the existing Matterport camera
    const cameraNode = await this._matterportService.addNode('ThreeCamera');

    // Custom camera input component
    // Receives inputs and updates camera
    this.cameraInputComponent = cameraNode.addComponent(CAMERA_INPUT_COMPONENT_TYPE, {
      matterportService: this._matterportService
    });

    this._iSubscriptions.push(
      this._matterportService.getCameraPose()
        .subscribe((cameraPose) => {
          // Convert sdk pose to THREE.js objects
          if (this.cameraInputComponent.inputs) {
            const inputs = (this.cameraInputComponent as CameraInput).inputs;
            inputs.startPose = {
              position: new Vector3(cameraPose.position.x, cameraPose.position.y, cameraPose.position.z),
              quaternion: new Quaternion().setFromEuler(new Euler(
                cameraPose.rotation.x * Math.PI / 180,
                cameraPose.rotation.y * Math.PI / 180,
                0,
                'YXZ')),
              projection: new Matrix4().fromArray(cameraPose.projection).transpose()
            };

            //this._logger.error(inputs.startPose);
          }
        })
    );

    // Sdk camera
    const cameraComponent = cameraNode.addComponent('mp.camera');
    // This camera's nodes output
    // const o_cameraInputCamera = this._matterportService.addOutputPath(this.cameraInputComponent as Scene.IComponent, 'camera');
    // const i_mpCameraCamera = this._matterportService.addInputPath(cameraComponent, 'camera');

    // Ogiginal implementation was a two-way bind between sdk camera and custom camera input component
    // To turn off main camera click detection when Prop was hovered
    // Also, to set camera focus when Prop is clicked
    // o_cameraInputCamera.bind(i_mpCameraCamera);

    // Spying for debug purposes
    // Original implementation was a two-way bind between sdk camera and custom camera input component
    // class CameraSpy {
    //   readonly path = o_cameraInputCamera;
    //   constructor(private _myThreeService: ThreeHelper) { }
    //   onEvent(cameraInput: SceneComponent) {
    //     this._myThreeService.setCameraInput(cameraInput);
    //   }
    // }
    // this._iSubscriptions.push(this._matterportService.spyOnEvent(new CameraSpy(this)));

    // InputComponent can be it's own Node. There should only be 1 per app.
    const inputComponent = cameraNode.addComponent('mp.input', {
      eventsEnabled: true,
      userNavigationEnabled: true
    });

    // const eventPath = this._matterportService.addEventPath(inputComponent, ComponentInteractionType.CLICK);
    // // Define a click event spy
    // class InputClickSpy {
    //   readonly path = eventPath;
    //   public onEvent(payload: unknown) {
    //   }
    // }
    // // Spy on the click event
    // this._iSubscriptions.push(this._matterportService.spyOnEvent((new InputClickSpy())));

    cameraNode.start();
  }


  setCameraInput(cameraInput: SceneComponent) {
    this.cameraInputComponent = cameraInput;
  }


  getCameraFocus(): Vector3 | null {
    if (this.cameraInputComponent.inputs) return this.cameraInputComponent.inputs['focus'] as Vector3 | null;
    return null;
  }


  setCameraFocus(position: Vector3 | null) {
    if (this.cameraInputComponent.inputs) {
      this.cameraInputComponent.inputs['focus'] = position;
    }
  }


  dispose(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._iSubscriptions.forEach(is => is.cancel());
  }


}
