import { DestroyRef } from "@angular/core";
import { MyOptyxComponent } from "./myoptyx.component";
import { Subject } from "rxjs/internal/Subject";
import { getLogger } from "../util/log";
import { BoxGeometry, Color, DirectionalLight, Group, Material, Mesh, MeshBasicMaterial, Object3D, PointLight } from "three";
import * as THREE from 'three';
import { Vector3Obj, vector3OneObj, vector3ZeroObj } from "../util/utils";

interface Box {
    position: [number, number, number];
    rotation: number;
    scale: [number, number, number];
}

interface Light {
    intensity: number;
    position: [number, number, number];
    scale: [number, number, number];
}

interface Env {
    topLight: {
        intensity: number,
        position: [number, number, number],
    };
    room: {
        position: [number, number, number],
        scale: [number, number, number],
    };
    boxes: Box[];
    lights: Light[];
}

const legacy = {
    topLight: {
        intensity: 500,
        position: [0.418, 16.199, 0.300],
    },
    room: {
        position: [-0.757, 13.219, 0.717],
        scale: [31.713, 28.305, 28.591],
    },
    boxes: [
        {
            position: [-10.906, 2.009, 1.846],
            rotation: -0.195,
            scale: [2.328, 7.905, 4.651],
        },
        {
            position: [-5.607, -0.754, -0.758],
            rotation: 0.994,
            scale: [1.970, 1.534, 3.955],
        },
        {
            position: [6.167, 0.857, 7.803],
            rotation: 0.561,
            scale: [3.927, 6.285, 3.687],
        },
        {
            position: [-2.017, 0.018, 6.124],
            rotation: 0.333,
            scale: [2.002, 4.566, 2.064],
        },
        {
            position: [2.291, -0.756, -2.621],
            rotation: -0.286,
            scale: [1.546, 1.552, 1.496],
        },
        {
            position: [-2.193, -0.369, -5.547],
            rotation: 0.516,
            scale: [3.875, 3.487, 2.986],
        },
    ],
    lights: [
        {
            intensity: 50,
            position: [-16.116, 14.37, 8.208],
            scale: [0.1, 2.428, 2.739],
        },
        {
            intensity: 50,
            position: [-16.109, 18.021, -8.207],
            scale: [0.1, 2.425, 2.751],
        },
        {
            intensity: 17,
            position: [14.904, 12.198, -1.832],
            scale: [0.15, 4.265, 6.331],
        },
        {
            intensity: 43,
            position: [-0.462, 8.89, 14.520],
            scale: [4.38, 5.441, 0.088],
        },
        {
            intensity: 20,
            position: [3.235, 11.486, -12.541],
            scale: [2.5, 2.0, 0.1],
        },
        {
            intensity: 100,
            position: [0.0, 20.0, 0.0],
            scale: [1.0, 0.1, 1.0],
        },
    ]
} as Env;

const neutral = {
    topLight: {
        intensity: 400,
        position: [0.5, 14.0, 0.5],
    },
    room: {
        position: [0.0, 13.2, 0.0],
        scale: [31.5, 28.5, 31.5],
    },
    boxes: [
        {
            position: [-10.906, -1.0, 1.846],
            rotation: -0.195,
            scale: [2.328, 7.905, 4.651],
        },
        {
            position: [-5.607, -0.754, -0.758],
            rotation: 0.994,
            scale: [1.970, 1.534, 3.955],
        },
        {
            position: [6.167, -0.16, 7.803],
            rotation: 0.561,
            scale: [3.927, 6.285, 3.687],
        },
        {
            position: [-2.017, 0.018, 6.124],
            rotation: 0.333,
            scale: [2.002, 4.566, 2.064],
        },
        {
            position: [2.291, -0.756, -2.621],
            rotation: -0.286,
            scale: [1.546, 1.552, 1.496],
        },
        {
            position: [-2.193, -0.369, -5.547],
            rotation: 0.516,
            scale: [3.875, 3.487, 2.986],
        },
    ],
    lights: [
        {
            intensity: 80,
            position: [-14.0, 10.0, 8.0],
            scale: [0.1, 2.5, 2.5],
        },
        {
            intensity: 80,
            position: [-14.0, 14.0, -4.0],
            scale: [0.1, 2.5, 2.5],
        },
        {
            intensity: 23,
            position: [14.0, 12.0, 0.0],
            scale: [0.1, 5.0, 5.0],
        },
        {
            intensity: 16,
            position: [0.0, 9.0, 14.0],
            scale: [5.0, 5.0, 0.1],
        },
        {
            intensity: 80,
            position: [7.0, 8.0, -14.0],
            scale: [2.5, 2.5, 0.1],
        },
        {
            intensity: 80,
            position: [-7.0, 16.0, -14.0],
            scale: [2.5, 2.5, 0.1],
        },
        {
            intensity: 1,
            position: [0.0, 20.0, 0.0],
            scale: [0.1, 0.1, 0.1],
        },
    ]
} as Env;


export type EnvironmentState = {

    position: Vector3Obj
    rotation: Vector3Obj
    scale: Vector3Obj
}


export class EnvironmentComponent extends MyOptyxComponent {

    protected override state: EnvironmentState = {
        
        position: vector3ZeroObj,
        rotation: vector3ZeroObj,
        scale: vector3OneObj
    };

    // Pending state initialized to match current state.
    override pendingState: EnvironmentState = {
        
        position: vector3ZeroObj,
        rotation: vector3ZeroObj,
        scale: vector3OneObj
    };


    // Events
    private readonly _src = new Subject<string>();
    readonly src$ = this._src.asObservable();

    private _logger = getLogger();
    private _environmentGroup!: Group;


    constructor(destroyRef: DestroyRef,
        private readonly three: typeof THREE) {
        super(destroyRef);
    }


    /**
     * Primitive state values can be compared with pendingState values directly to evaluate changes.
     * pendingStateChanges tracks all pendingState properties that have changed since the last call to applyPendingState().
     * Use that to evaluate if shallow reference values have changed.
     */
    protected override applyPendingState(): void {
    }


    getObjectGroup(): Object3D {

        return this._environmentGroup;
    }


    override getState(): EnvironmentState {

        // Might want to deepCopy depending on properties.
        return this.state;
    }


    override onDestroy(): void {

        this._environmentGroup.traverse(node => {

            if ((node as any).dispose) {

                (node as any).dispose();
            }
        })
    }


    // If overriding be sure to call base method.
    override init(): EnvironmentComponent {
        super.init();

        this._environmentGroup = new this.three.Group();
        
        // Where I left off

        const ambientLight = new this.three.AmbientLight(0xffffff, 2.0);
        this._environmentGroup.add(ambientLight);        

        //
        // The spot light
        //


        // Position directional light in front of playe looking back at player.
        // Goal is to have it behave as back lighting to avoid glare on image surfaces.
        const dLight = new this.three.SpotLight(0xffffff, 1.5);
        dLight.decay = 0;           // Never decrease in intensity
        dLight.angle = 1.1;
        dLight.position.set(-5.0, 5.0, -5.0);
        dLight.target.position.set(0.0, 0.0, 0.0);
        dLight.castShadow = true;
        dLight.shadow.bias = -0.01;
        // The area within which shadows get rendered
        dLight.shadow.camera.near = 0.5;
        dLight.shadow.camera.far = 15;
        // dLight.shadow.camera.left = -10;
        // dLight.shadow.camera.right = 10;
        // dLight.shadow.camera.top = 10;
        // dLight.shadow.camera.bottom = -10;
        // The resolution of the rendered shadows
        dLight.shadow.mapSize.width = 2048;
        dLight.shadow.mapSize.height = 2048;
        this._environmentGroup.add(dLight, dLight.target);

        //
        // End the spot light
        //

        //const helper = new MyPointLightHelper(dLight, .25, new Color(Color.NAMES.royalblue), this.three);
        //helper.position.set(1.0, 4.0, -2.0);
        //helper.lookAt(dLight.target.position);
        //const helper = new this.three.CameraHelper(dLight.shadow.camera);
        //helper.position.set(6.0, 1.0, -6.0);
        //this._environmentGroup.add(helper);
        //this._environmentGroup.add(dLight.target);
        //this._logger.error(`left: ${dLight.shadow.camera.left}, right: ${dLight.shadow.camera.right}, top: ${dLight.shadow.camera.top}, bottom: ${dLight.shadow.camera.bottom},`)
        // const planeGeo = new this.three.PlaneGeometry(5.0, 5.0);
        // const planeMat = new this.three.MeshStandardMaterial({ color: 0xffffff, transparent: false });
        // const planeMesh = new this.three.Mesh(planeGeo, planeMat);
        // planeMesh.position.set(-34, 0, 9)
        // planeMesh.lookAt(directionalLight1.position);
        // planeMesh.receiveShadow = true;
        // this._environmentGroup.add(planeMesh);
        
        
        // const sphereGeo1 = new this.three.SphereGeometry(1);
        // const sphereMat1 = new this.three.MeshStandardMaterial({ color: 0xffff00, transparent: false });
        // const sphereMesh1 = new this.three.Mesh(sphereGeo1, sphereMat1);
        //sphereMesh1.castShadow = true;
        // sphereMesh1.position.set(6.0, 0, -6.0);     
        // this._environmentGroup.add(sphereMesh1);
        // const sphereGeo2 = new this.three.SphereGeometry(.5);
        // const sphereMat2 = new this.three.MeshStandardMaterial({ color: 0xffff00, transparent: false });
        // const sphereMesh2 = new this.three.Mesh(sphereGeo2, sphereMat2);
        // sphereMesh2.castShadow = true;
        // sphereMesh2.position.set(-34.5, 0.75, 9.5);
        // this._environmentGroup.add(sphereMesh2);
        // const sphereGeo3 = new this.three.SphereGeometry(.5);
        // const sphereMat3 = new this.three.MeshStandardMaterial({ color: 0xffff00, transparent: false });
        // const sphereMesh3 = new this.three.Mesh(sphereGeo3, sphereMat3);
        // sphereMesh3.castShadow = true;
        // sphereMesh3.position.set(-33.5, 1, 8.5);
        // this._environmentGroup.add(sphereMesh3);
        // const sphereGeo4 = new this.three.SphereGeometry(.5);
        // const sphereMat4 = new this.three.MeshStandardMaterial({ color: 0xffff00, transparent: false });
        // const sphereMesh4 = new this.three.Mesh(sphereGeo4, sphereMat4);
        // sphereMesh4.castShadow = true;
        // sphereMesh4.position.set(-34.0, 0.8, 9.0);
        // this._environmentGroup.add(sphereMesh4);

        // const directionalLight2Target = new this.three.Object3D();
        // directionalLight2Target.position.set(0, 0, 2);
        // this._environmentGroup.add(directionalLight2Target);
        // const directionalLight2 = new this.three.DirectionalLight(0xffffff, 0.1);
        // directionalLight2.position.set(0, 2, 8);
        // directionalLight2.lookAt(5, 1, -13.7);
        // directionalLight2.target = directionalLight2Target;
        // directionalLight2.castShadow = true;
        // directionalLight2.shadow.camera.near = .1;
        // directionalLight2.shadow.camera.far = 5;
        // directionalLight2.shadow.camera.right = 2;
        // directionalLight2.shadow.camera.left = - 2;
        // directionalLight2.shadow.camera.top = 2;
        // directionalLight2.shadow.camera.bottom = - 2;
        // directionalLight2.shadow.mapSize.width = 2048;
        // directionalLight2.shadow.mapSize.height = 2048;
        // directionalLight2.shadow.bias = - 0.001;
        // this._environmentGroup.add(directionalLight2);

        // My experimentation

        // const width = 200.0;
        // const height = 200.0;
        // const light = new this.three.RectAreaLight(0xFFFFFF, 1.0, width, height);
        // light.position.set(0, 14.0, 0);
        // light.lookAt(0, 0, 0);
        // this._environmentGroup.add(light);




        // From Model-viewer

        // const data = neutral;
        // const mainLight = new PointLight(0xffffff, data.topLight.intensity, 28, 2);
        // mainLight.position.set(...data.topLight.position);
        // this._environmentGroup.add(mainLight);

        // const geometry = new BoxGeometry();
        // geometry.deleteAttribute('uv');
        // for (const light of data.lights) {
        //     const light1 =
        //         new Mesh(geometry, this.createAreaLightMaterial(light.intensity));
        //     light1.position.set(...light.position);
        //     light1.scale.set(...light.scale);
        //     this._environmentGroup.add(light1);
        // }

        return this;
    }


    createAreaLightMaterial(intensity: number): MeshBasicMaterial {
        const material = new MeshBasicMaterial();
        material.color.setScalar(intensity);
        return material;
    }

}


class MyPointLightHelper extends Mesh {

    constructor(private light: DirectionalLight, sphereSize: number, private color: Color, three: typeof THREE) {
        
        const geometry = new three.PlaneGeometry( );
        const material = new three.MeshBasicMaterial( { wireframe: true, fog: false, toneMapped: false } );

        super( geometry, material );

        light.add( this );

        this.light = light;
        this.color = color;
        (this as any).type = 'MyPointLightHelper';
        this.update();
    }


    dispose() {

        this.geometry.dispose();
        (this.material as Material).dispose();
    }


    update() {

        if (this.color !== undefined) {
         
            (this.material as MeshBasicMaterial).color.set(this.color);
        } else {
            
            (this.material as MeshBasicMaterial).color.copy(this.light.color);
        }
    }
}
