@if (isInIFrame) {

<app-guest-showroom />

} @else {

<router-outlet />
<app-toast />
<app-alert-modal />

}