import { defineComponent, Types } from "bitecs";
import { Vector2Schema } from "./shared.component";


/**
 * Clipping Plane Assignment components represent an Clipping Plane's adjustment values for a given fixed position.
 */
export const ClippingAssignmentComponent = defineComponent({
    position: Vector2Schema,
    rotationZ: Types.f32,
    scale: Vector2Schema,
});