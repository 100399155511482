import { IWorld, addComponent, addEntity } from "bitecs";
import { Vector3Obj } from "../..";
import { ShowroomPositionComponent } from "../component/showroom-position.component";
import { WorldState } from "../ecs-world.state";

/**
 * Relationship between a Showroom position id and its assigned entity id.
 */
export interface ShowroomPositionEntity {
    positionId: string;
    entityId: number;
}

/**
 * References to the Showroom positions that players transition to/from
 */
type ShowroomPositionMap = { [entityId: number]: ShowroomPositionEntity }
export const showroomPositionByEntityId: ShowroomPositionMap = {}


/**
 * Track available Showroom positions that a player can occupy when not transitioning from position to position.
 * @param positionId 
 * @param position 
 * @returns entity representing the relationship between the Showroom position id and the ECS entity id
 */
export function addShowroomPosition(world: IWorld, positionId: string, position: Vector3Obj): ShowroomPositionEntity {

    let eidForPosition: number;
    do eidForPosition = addEntity(world)
    while (!eidForPosition || 0 > eidForPosition);

    // Record relationship
    const positionEntity: ShowroomPositionEntity = {
        entityId: eidForPosition,
        positionId: positionId
    }
    showroomPositionByEntityId[eidForPosition] = positionEntity;

    // Component values to track
    addComponent(world, ShowroomPositionComponent, eidForPosition);

    // Set component values
    ShowroomPositionComponent.x[eidForPosition] = position.x;
    ShowroomPositionComponent.y[eidForPosition] = position.y;
    ShowroomPositionComponent.z[eidForPosition] = position.z;

    return positionEntity;
}


/**
 * @param positionId target Showroom position id
 * @returns the entity id for the related Showroom position
 */
export function getEidForShowroomPosition(positionId: string): number {

    const entityId = (Object.keys(showroomPositionByEntityId))
        .find((key) => showroomPositionByEntityId[Number(key)].positionId === positionId);

    return entityId ? Number(entityId) : -1;
}