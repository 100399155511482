import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, catchError, filter, last, map, tap, throwError } from 'rxjs';

/**
 * MsalInterceptor automatically acquires and adds access tokens headers for outgoing requests 
 * that use the Angular http client to known protected resources.
 * 
 * Angular Universal SSR does not support http calls from the server
 * If using SSR wrap http calls with 'if (typeof window !== "undefined") so they don't execute on the server
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private readonly http: HttpClient,
    private readonly logger: NGXLogger) { }


  /**
   * Auth headers are automatically applied by Msal libraries based upon Environment.apiConfigs
   * @param url 
   * @param data 
   * @returns The deleted entity
   */
  delete<Type>(url: string, data: any): Observable<Type> {
    const httpOptions = {
      // Headers are so the body can be posted with Delete
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    };

    return this.http.delete<Type>(url, httpOptions)
      .pipe(
        tap((response: Type) => {
          return response;
        }),
        catchError(this._handleError)
      );
  }


  get<Type>(url: string, params?: any): Observable<Type> {
    const options = {};
    this.setHeaders(options, url);

    return this.http.get<Type>(url, options)
      .pipe(
        // retry(3), // retry a failed request up to 3 times
        tap((response: Type) => {
          return response;
        })
      );
  }


  /**
   * Auth headers are automatically applied by Msal libraries based upon Environment.apiConfigs
   * @param url 
   * @param data 
   * @param params 
   * @returns 
   */
  post<T>(url: string, data: any, params?: HttpParams): Observable<T> {
    
    const options = {
      params: <HttpParams>{}
    };
    this.setHeaders(options, url);

    if (params) {

      options.params = params
    };

    return this.http.post(url, data, options)
      .pipe(
        tap((response: any) => { }),
        catchError(this._handleError)
      );
  }


  /**
   * Interim type guard for post with progess to enable evaluation of events and response type.
   * Assumes response is an entity with an id property.
   * @param event 
   * @returns true if type check matches else false
   */
  isEntityType<T>(event: any): event is T {

    if (event && event.hasOwnProperty('id')) return true;
    return false;
  }


  /**
   * Post large file upload with progress callback.
   * @param url 
   * @param data 
   * @param params 
   * @param progressCallback 
   * @returns T
   */
  postWithProgress<T>(url: string, data: any, params?: HttpParams, progressCallback?: ((percentDone: number) => void)): Observable<T> {

    const options = {
      reportProgress: true,
      observe: 'events',
      params: params ?? <HttpParams>{}
    };
    this.setHeaders(options, url);

    const req = new HttpRequest('POST', url, data, options);
    req.headers.set('ngsw-bypass', '1');

    return this.http.request(req)
      .pipe(
        map(event => this.callbackProgressOrResponse<T>(event, progressCallback)),
        //tap(message => this._logger.trace('message', message)), 
        filter((event: any) => this.isEntityType<T>(event)),  // Type guard
        last(),                                               // return last message (the entity response) to caller
        catchError(this._handleError)
      );
  }


  /**
   * For large uploads, process http post progress events to enable indicator until upload completes.
   * @param event http post progress event
   * @param progressCallback 
   * @returns upload progress status or response 
   */
  private callbackProgressOrResponse<T>(event: any, progressCallback?: ((percentDone: number) => void)): string | T {

    switch (event.type) {

      case HttpEventType.Sent:
        if (progressCallback) progressCallback(0);
        return `Uploading started`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:    
        this.logger.trace(`loaded so far: ${event.loaded}, target amount: ${event.total}`);
        const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
        if (progressCallback) {

          progressCallback(percentDone);
        }
        return `${percentDone}% uploaded`;

      case HttpEventType.Response:
        this.logger.trace('Upload complete', event.body);
        if (progressCallback) {

          progressCallback(100);
        }
        return event.body as T;

      default:
        return `Unexpected upload event: ${event.type}.`;
    }
  }


  /**
   * Auth headers are automatically applied by Msal libraries based upon Environment.apiConfigs
   * @param url 
   * @param data 
   * @param params 
   * @returns 
   */
  put<Type>(url: string, data: any, params?: any): Observable<Type> {
    let options = {};
    this.setHeaders(options, url);

    return this.http.put<Type>(url, data, options)
      .pipe(
        tap((response: Type) => {
          return response;
        }),
        catchError(this._handleError)
      );
  }


  private _handleError(error: any) {

    console.error(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Client side network error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend - status: ${error.status}, statusText: ${error.statusText}, message: ${error.error.message}`, error);
    }

    // return an observable with a user-facing error message
    return throwError(() => new Error(error || 'server error'));
  }


  /**
   * Set the 'headers' option in the provided httpOptions
   * @param httpOptions 
   * @param url 
   */
  private setHeaders(httpOptions: any, url?: string): void {

    httpOptions["headers"] = new HttpHeaders()
      .append('x-requestid', crypto.randomUUID())
      .append('ngsw-bypass', '1');
  }


}
