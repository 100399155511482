import { Component, OnDestroy, QueryList, ViewChildren, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapTrash3, bootstrapXLg, bootstrapCart3 } from "@ng-icons/bootstrap-icons";
import { ISidebar, SidebarService } from 'src/app/core/service/ui/sidebar/sidebar.service';
import { CartService } from 'src/app/core/service/cart/cart.service';
import { Subscription } from 'rxjs';
import { ICartCheckout } from 'src/app/core/model/cart.model';
import { CartItemComponent, IChangeQuantityEvent } from "../cart-item/cart-item.component";
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { isChildElement } from 'projects/my-common/src';
import { NGXLogger } from 'ngx-logger';
import { ShowroomCartItemComponent } from "../showroom-cart-item/showroom-cart-item.component";
import { GuestService } from 'src/app/core/service/venue/guest.service';

export const CART_SIDEBAR_ID = 'f0e0bc8c-e355-4b47-8fe7-51d1a0ebad52';

/**
 * The Showroom Cart supports Purchase Order automation between MyOptyx clients and their customers.
 */
@Component({
  selector: 'app-cart-sidebar',
  standalone: true,
  providers: [provideIcons({ bootstrapCart3, bootstrapChevronRight, bootstrapTrash3, bootstrapXLg })],
  templateUrl: './cart-sidebar.component.html',
  styleUrls: ['./cart-sidebar.component.scss'],
  imports: [CommonModule, NgIconComponent, CartItemComponent, RouterLink, RouterLinkActive, ShowroomCartItemComponent]
})
export class CartSidebarComponent implements ISidebar, OnDestroy {

  readonly sidebarId: string = CART_SIDEBAR_ID;
  readonly isOpen = signal(false);
  readonly closing = signal(false);
  private _handlingBlur = false;

  /**
   * Setup for close on blur when user clicks away from sidebar
   */
  @ViewChildren('cartSidebarComponent') elementRef!: QueryList<HTMLInputElement>;
  /**
   * Template need to reference this somewhere for it to get triggered.
   */
  readonly setFocus = computed(() => {

    if (this.isOpen()) {

      const elementRef = (this.elementRef.first as any).nativeElement;
      elementRef?.focus();

      return true;
    }
    return false;
  });

  private _subscriptions: Subscription[] = [];

  readonly totalPrice = computed(() => {
    let totalPrice = 0;
    this.cartService.showroomCart().items.forEach(item => {
      totalPrice += item.price;
    });

    return totalPrice;
  });


  constructor(private readonly sidebarService: SidebarService,
    readonly cartService: CartService,
    private readonly guestService: GuestService,
    private readonly router: Router,
    private readonly logger: NGXLogger) {

    sidebarService.add(this);
  }


  clearCart() {
    this.cartService.clearShowroomCart();
  }


  changeQuantity(event: IChangeQuantityEvent) {
    this.cartService.changeItemQuantity(event.itemIndex, event.newQuantity);
  }


  decreaseQuantity(itemIndex: number) {
    this.cartService.decreaseItemQuantity(itemIndex);
  }


  increaseQuantity(itemIndex: number) {
    this.cartService.increaseItemQuantity(itemIndex);
  }


  ngAfterViewInit(): void {

    const elementRef = (this.elementRef.first as any).nativeElement
    // These event fire rapidly together on focus change and bubble up with no need to add 'onblur' events.
    elementRef?.addEventListener("focusin", this.blurCallback, false);
    elementRef?.addEventListener("focusout", this.blurCallback, false);
  }


  ngOnDestroy(): void {

    this.sidebarService.remove(this);
    this._subscriptions.forEach(s => s.unsubscribe());

    const elementRef = (this.elementRef.first as any).nativeElement
    elementRef?.removeEventListener("focusin", this.blurCallback);
    elementRef?.removeEventListener("focusout", this.blurCallback);
  }


  private readonly blurCallback = this.onBlur.bind(this);
  onBlur() {

    if (this._handlingBlur) {

      return;
    }
    this._handlingBlur = true;

    const elementRef = (this.elementRef.first as any).nativeElement;
    if (!elementRef) {

      this._handlingBlur = false;
      return;
    }

    // Timeout give cycle for focusIn/Out combination to complete
    setTimeout(() => {

      if (!isChildElement(elementRef, document.activeElement)) {

        this.isOpen.set(false);
      }
      this._handlingBlur = false;
    }, 1);
  }


  /**
   * Transition Showroom Cart to Purchase Order for further processing
   */
  async onCheckout(): Promise<void> {

    this.router.navigate([`/customer/create-purchase-order/${this.guestService.invitationGuid}`]);
    // const cartCheckout: ICartCheckout = {
    //   cartSessionId: this.cart().sessionId,
    //   requestId: crypto.randomUUID()
    // };    

    // this._logger.trace('Creating PO');
    // this._orderService.createPurchaseOrder(cartCheckout)
    //   .subscribe((response: any) => {

    //     // Cart gets cleared on the server upon succesful conversion to Purchase Order
    //     // Call the server to pull the empty cart to update the client
    //     this._cartService.getCart();
    //     this._router.navigate([`/customer/purchase-order-details/${response.orderId}`]);
    //     this.onClose();
    //   });
  }


  onClose() {
    this.closing.set(true);
    setTimeout(() => {
      this.isOpen.set(false);
      this.closing.set(false);
    }, 200);
  }


  removeItem(itemIndex: number) {
    this.cartService.removeItem(itemIndex);
  }


}
