import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, OnDestroy, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { register } from 'swiper/element/bundle'
import { ICatalogItem } from 'src/app/core/model/catalog.model';
import { NGXLogger } from 'ngx-logger';

const VIDEO1_URL = 'https://stmyoptyxprod1.z19.web.core.windows.net/swa_images/before%20footage/before%20footage.m3u8';
const VIDEO2_URL = 'https://stmyoptyxprod1.z19.web.core.windows.net/swa_images/after%20footage/after%20footage.m3u8';

@Component({
  selector: 'app-video-slider',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './video-slider.component.html',
  styleUrls: ['./video-slider.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VideoSliderComponent implements AfterViewInit, OnDestroy {
  id = crypto.randomUUID();
  video1_id = `v1${this.id}`;
  video2_id = `v2${this.id}`;

  readonly items: WritableSignal<ICatalogItem[]> = signal([]);

  // https://stackoverflow.com/questions/42285032/how-do-i-import-shaka-player-into-an-angular-2-application
  private _shaka = require('../../../../../node_modules/shaka-player/dist/shaka-player.compiled');
  private _shakaPlayer1: any;
  private _shakaPlayer2: any;

  sliderData = [
    {
      id: this.video1_id,
      img: '',
      preTitle: 'Before',
      titlePart1: 'Before',
      titlePart2: 'MyOptyx Showroom',
      titlePart3: 'Before',
      btnText: 'Ignore',
    },
    {
      id: this.video2_id,
      img: '',
      preTitle: 'After',
      titlePart1: 'After',
      titlePart2: 'MyOptyx Showroom',
      titlePart3: 'After',
      btnText: 'Contact us',
    }];


  constructor(private readonly _logger: NGXLogger) {
    register();
  }


  handleNext() {
    
    const swiperEl = document.querySelector('swiper-container');
    swiperEl?.swiper.slideNext();
  }


  handlePrev() {
    
    const swiperEl = document.querySelector('swiper-container');
    swiperEl?.swiper.slidePrev();
  }


  private initSwiper() {
    const swiperEl = document.querySelector('swiper-container');

    if (null != swiperEl) {
      swiperEl.direction = 'horizontal';
      swiperEl.slidesPerView = 1;
      swiperEl.initialSlide = 0;
      swiperEl.init = true;
      swiperEl.autoplay = {
        delay: 5000
      };
      swiperEl.initialize();
    }

    // const params: SwiperOptions = {
    //   direction: 'horizontal',
    //   slidesPerView: 1,
    //   initialSlide: 0,
    //   autoplay: {
    //     delay: 5000,
    //     disableOnInteraction: true
    //   },
    // injectStyles: [`
    // .swiper-pagination-bullet {
    //   width: 20px;
    //   height: 20px;
    //   text-align: center;
    //   line-height: 20px;
    //   font-size: 12px;
    //   color: #000;
    //   opacity: 1;
    //   background: rgba(0, 0, 0, 0.2);
    //   margin-bottom: 20px;
    // }

    // .swiper-pagination-bullet-active {
    //   color: #fff;
    //   background: #007aff;
    // }
    // `],
    // Navigation arrows
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    // If we need pagination
    // pagination: {
    //   clickable: true,
    //   el: '.swiper-pagination',
    //   renderBullet: function (index: number, className: string) {
    //     return '<span class="' + className + '">' + (index + 1) + "</span>";
    //   },
    // },
    // };
  }


  private async loadHls(): Promise<void> {

    const video1 = document.getElementById(this.video1_id) as HTMLVideoElement;
    const video2 = document.getElementById(this.video2_id) as HTMLVideoElement;

    if (!video1 || !video2) {
      this._logger.trace(`video element is undefined.`);
      return;
    }

    this.releaseResources();

    this._shakaPlayer1 = new this._shaka.Player();
    await this._shakaPlayer1.attach(video1);
    this._shakaPlayer2 = new this._shaka.Player();
    await this._shakaPlayer2.attach(video2);

    // Attach player to the window to make it easy to access in the JS console.
    (window as any).player = this._shakaPlayer1;

    // Listen for error events.
    this._shakaPlayer1.addEventListener('error', this.onErrorEvent);
    this._shakaPlayer2.addEventListener('error', this.onErrorEvent);

    // Try to load a manifest.
    // This is an asynchronous process.
    try {
      await this._shakaPlayer1.load(VIDEO1_URL);
      await this._shakaPlayer2.load(VIDEO2_URL);
    } catch (e) {
      // onError is executed if the asynchronous load fails.
      this.onError(e);
      return;
    }
  }


  ngAfterViewInit() {
    this.loadHls();
    this.initSwiper();
  }


  ngOnDestroy(): void {
    
    this.releaseResources();
  }


  private onErrorEvent(event: any) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }


  private onError(error: any) {
    // Log the error.
    this._logger.error('Error code', error.code, 'object', error);
  }


  private releaseResources() {
    
    const video1 = document.getElementById(this.video1_id) as HTMLVideoElement;
    const video2 = document.getElementById(this.video2_id) as HTMLVideoElement;

    if (video1) {

      video1.pause();
      video1.removeAttribute('src');
      video1.load();
    }
    if (video2) {
      
      video2.pause();
      video2.removeAttribute('src');
      video2.load();
    }

    if (this._shakaPlayer1) {

      this._shakaPlayer1.destroy();
      this._shakaPlayer1 = null;
    }
    if (this._shakaPlayer2) {

      this._shakaPlayer2.destroy();
      this._shakaPlayer2 = null;
    }
  }


}
