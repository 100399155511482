import { SceneComponent, ComponentOutput, DragBeginEvent, DragEvent, KeyEvent, KeyState, ScrollEvent } from "../../SceneComponent";
import { Vector3, Quaternion, Camera, Matrix4 } from "three";
import { CameraInputMap, Keys, HasControls } from "./InputMap";
import { CameraRig } from "./CameraRig";
import { ICameraController } from "./ICameraController";
import { ControlMode } from "./ControlMode";
import { FreeControls } from "./FreeControls";
import { FlightControls } from "./FlightControls";
import { Axis } from "./Axis";
import { getLogger } from "projects/my-common/src/util/log";

export type CameraPose = {
  position: Vector3;
  quaternion: Quaternion;
  projection: Matrix4;
};

export type Outputs = {
  camera: null | Camera;
} & ComponentOutput;


export type Inputs = {
  startPose: CameraPose | null;
  focus: Vector3 | null;
  suppressClick: boolean;
}

export enum CameraInputEvent {
  DragBegin = 'DRAG_BEGIN',
  Drag = 'DRAG',
  DragEnd = 'DRAG_END',
  Key = 'KEY',
  Scroll = 'SCROLL',
};

type Events = {
  [CameraInputEvent.DragBegin]: boolean,
  [CameraInputEvent.Drag]: boolean,
  [CameraInputEvent.DragEnd]: boolean,
  [CameraInputEvent.Key]: boolean,
  [CameraInputEvent.Scroll]: boolean,
};

export class CameraInput extends SceneComponent {
  private camera: Camera | null = null;
  private cameraRig: CameraRig | null = null;
  private controlMap: CameraInputMap;
  private activeControls: ICameraController | null = null;
  private controls: ICameraController[] = [];
  private readonly _logger = getLogger();

  override inputs: Inputs = {
    startPose: null as null | CameraPose,
    focus: null as null | Vector3,
    suppressClick: false
  };

  override outputs = {
    camera: null,
  } as Outputs;

  override events: Events = {
    [CameraInputEvent.DragBegin]: true,
    [CameraInputEvent.Drag]: true,
    [CameraInputEvent.DragEnd]: true,
    [CameraInputEvent.Key]: true,
    [CameraInputEvent.Scroll]: true,
  };


  constructor() {
    super();
    
    class Controls implements HasControls {
      constructor(private cameraInput: CameraInput) { }

      get controls() {
        return this.cameraInput.activeControls;
      }
    }

    this.controlMap = new CameraInputMap({
      [Keys.W]: [Axis.Z, 'accelerate', -1],
      [Keys.A]: [Axis.X, 'accelerate', -1],
      [Keys.S]: [Axis.Z, 'accelerate', 1],
      [Keys.D]: [Axis.X, 'accelerate', 1],
      [Keys.SPACE]: [Axis.Y, 'accelerate', 1],
      [Keys.SHIFT]: [Axis.Y, 'accelerate', -1],
      [Keys.LEFT]: [Axis.Y, 'accelerateAround', 1],
      [Keys.RIGHT]: [Axis.Y, 'accelerateAround', -1],
      [Keys.UP]: [Axis.X, 'accelerateAround', 1],
      [Keys.DOWN]: [Axis.X, 'accelerateAround', -1],
      [Keys.Q]: [Axis.Z, 'accelerateAround', 1],
      [Keys.E]: [Axis.Z, 'accelerateAround', -1],
    }, new Controls(this));
  }


  override onInit() {
    this.camera = new this.context.three.PerspectiveCamera();
    this.cameraRig = new CameraRig(this.camera);

    if (this.inputs.startPose) {
      this.cameraRig.position.copy(this.inputs.startPose.position);
      this.cameraRig.quaternion.copy(this.inputs.startPose.quaternion);
      this.camera.projectionMatrix.copy(this.inputs.startPose.projection);
      this.cameraRig.updateMatrixWorld();
    }

    this.controls[ControlMode.FREE] = new FreeControls(this.cameraRig);
    this.controls[ControlMode.FLIGHT] = new FlightControls(this.cameraRig);
    this.activeControls = this.controls[ControlMode.FREE];
    this.changeFocus(this.inputs.focus);

    this.outputs.camera = this.camera;
  }


  override onInputsUpdated(oldInputs: Inputs) {
    // if the focus switched from null or to null OR the focus changed to a new vector
    if (this.inputs.focus && (!oldInputs.focus || !this.inputs.focus.equals(oldInputs.focus))) {
      this.changeFocus(this.inputs.focus);
    }
    if (!this.inputs.focus && !!oldInputs.focus) {
      this.changeFocus(null);
    }
  }


  override onEvent(eventType: string, eventData: unknown) {
    this._logger.trace(`CameraInput Event ${eventType}`, eventData);
    switch (eventType) {
      case CameraInputEvent.DragBegin:
        {
          const event = eventData as DragBeginEvent;
          this.controlMap.onMouseDown(event.position.x, event.position.y);
        }
        break;
      case CameraInputEvent.Drag:
        {
          const event = eventData as DragEvent;
          this.controlMap.onMouseMove(event.delta.x, event.delta.y);
        }
        break;
      case CameraInputEvent.DragEnd:
        {
          this.controlMap.onMouseUp();
        }
        break;
      case CameraInputEvent.Key:
        {
          const event = eventData as KeyEvent;
          if (event.state === KeyState.DOWN) {
            this.controlMap.onKeyDown(event.key);
          }
          else {
            this.controlMap.onKeyUp(event.key);
          }
        }
        break;
      case CameraInputEvent.Scroll:
        {
          const event = eventData as ScrollEvent;
          this.controlMap.onScroll(event.delta.y);
        }
        break;
    }
  }


  lastPosition: { x: number; y: number; z: number; } = { x: 0, y: 0, z: 0 };
  override onTick(deltaMSec: number) {
    this.activeControls?.tick(deltaMSec);
    this.cameraRig?.updateMatrixWorld();
    //this.checkPosition();
  }


  private changeFocus(focus: Vector3 | null) {
    this.activeControls?.halt();
    if (!focus) {
      this.cameraRig?.clearFocus();
      this.activeControls = this.controls[ControlMode.FREE];
    } else {
      if (this.inputs.focus) {
        this.cameraRig?.setFocus(this.inputs.focus);
      }
      this.activeControls = this.controls[ControlMode.FLIGHT];
    }
  }

}

export const CAMERA_INPUT_COMPONENT_TYPE = 'camera.input';
export function makeCameraInput() {
  return new CameraInput();
}
