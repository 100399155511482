import { Injectable, WritableSignal } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * Sidebarcomponents should implement this interface and register with SidebarService
 * in OnInit and OnDestroy
 */
export interface ISidebar {
  sidebarId: string;
  readonly isOpen: WritableSignal<boolean>;
}


@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private sidebars: ISidebar[] = [];


  constructor(private logger: NGXLogger) { }


  add(sidebar: ISidebar): void {

    // ensure component has a unique id attribute
    if (!sidebar.sidebarId || this.sidebars.find(x => x.sidebarId === sidebar.sidebarId)) {
      
      throw new Error(`Sidebar id: ${sidebar.sidebarId} must be unique. Sidebar count: ${this.sidebars.length}`);
    }

    // add modal to array of active modals
    this.sidebars.push(sidebar);
  }


  remove(sidebar: ISidebar): void {

    this.logger.trace('--> remove sidebar', sidebar.sidebarId);
    // remove modal from array of active modals
    this.sidebars = this.sidebars.filter(x => x !== sidebar);
  }


  /**
   * Open sidebar. Call after sidebar inputs have been configured.
   * @param id 
   * @param autoClose if any other sidbar is open close it.
   * @returns 
   */
  open(id: string, autoClose: boolean = true): ISidebar {

    const sidebar = this.sidebars.find(x => x.sidebarId === id);
    if (!sidebar) {

      throw new Error(`sidebar '${id}' not found`);
    }

    if (autoClose) {

      this.sidebars.forEach(sidebar => {

        if (sidebar.sidebarId !== id) {

          sidebar.isOpen.set(false);
        }
      });
    }

    // setTimeout resolves occasional timing issue when this is called before sidebar Inputs were updated
    sidebar.isOpen.set(true);
    return sidebar;
  }


  /**
   * Close first open sidebar.
   * @param id Narrow sidebar search by sidebar Id
   */
  close(id?: string): void {

    const sidebar = id ? this.sidebars.find(x => x.sidebarId === id) : this.sidebars.find(x => x.isOpen);

    sidebar?.isOpen.set(false);
  }


}