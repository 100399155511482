import { DestroyRef } from "@angular/core";

declare const _: unique symbol;
type Forbidden = { [_]: typeof _; }
type NoOverride<T = void> = T & Forbidden;

export interface IMyOptyxComponent {

  destroyRef: DestroyRef;

  /**
   * Accumulate and prepare updated state. All values will be applied at one time in applyPendingState().
   * @param newState 
   */
  pendingState: object;

  /**
  * @returns Copy of current state.
  */
  getState(): object;
  onDestroy(): void;
  init(): void;
}


export abstract class MyOptyxComponent implements IMyOptyxComponent {

  /**
   * Override but keep protected in subclass for change tracking.
   */
  protected state!: object;
  pendingState!: object;

  protected pendingStateChanges: string[] = [];

  /**
   * Properties are initialized in onInit().
   * They are undefined within the constructor.
   * @param destroyRef 
   */
  constructor(readonly destroyRef: DestroyRef) {

    this.destroyRef.onDestroy(() => this.onDestroy());
  }


  /**
   * Override but keep protected, evaluate and react to changes.
   * Base will reset change tracking and update state from pendingState after.
   */
  protected applyPendingState(): void { }


  /**
   * Apply the pending state and update current state. 
   */
  apply(): NoOverride {

    this.applyPendingState();

    // Update current state
    Object.keys(this.state).forEach((key) => {

        (this.state as any)[key] = (this._pendingState as any)[key];
    });
    this.pendingStateChanges = [];
  }


  abstract getState(): object;
  abstract onDestroy(): void;

  private _pendingState!: object;
  /**
   * If overriding be sure to call base method.
   * PedndingState is initialized here and change tracking is activated.
   */
  init(): MyOptyxComponent {

    const that = this;
    const proxyHandler = {
      set(obj: any, prop: any, value: any) {

        if (!that.pendingStateChanges.find(psc => psc === prop)) {

          that.pendingStateChanges.push(prop);
        }

        return Reflect.set(obj, prop, value);
      }
    };

    this._pendingState = this.pendingState;
    this.pendingState = new Proxy(this.pendingState, proxyHandler);

    return this;
  }


}

