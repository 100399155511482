import { addComponent, addEntity, IWorld, removeEntity } from "bitecs";
import { Object3D } from "three";
import { getEidForClippingPlane, removeClippingAssignmentForClippingPlane } from "./adjustable.entity";
import {
    ChildObjectEntityIdMap, ClippingPlaneComponent, DestinationPositionComponent, DestinationRotationComponent, DestinationScaleComponent, FromPositionComponent,
    FromRotationComponent, FromScaleComponent, object3dByEntityId, PositionAdjustmentComponent, RotationAdjustmentComponent, ScaleAdjustmentComponent
} from "..";
import { WorldState } from "../ecs-world.state";
import { AdjustmentInitState } from "../system/adjustment-init.state";
import { IClippingPlane, PropType } from "../../model";
import { AdjustableTransitionInitState } from "../system/adjustable-transition-init.state";

export interface ClippingPlaneEntity {
    properties: IClippingPlane;
    plane: Object3D;
}


/**
 * For child clipping planes within group
 */
export const clippingPlaneEidByParentEid: ChildObjectEntityIdMap = {}


export function removeClippingPlane(world: IWorld, clippingPlaneId: number, propType: PropType): void {

    const eidForClippingPlane = getEidForClippingPlane(world, clippingPlaneId, propType);
    if (0 > eidForClippingPlane) {

        return;
    }

    removeClippingAssignmentForClippingPlane(world, clippingPlaneId, propType);
    delete object3dByEntityId[eidForClippingPlane];
    removeEntity(world, eidForClippingPlane);
}


/**
 * Update component values for Clipping plane entity.
 * @param clippingPlane 
 */
function updateClippingPlaneComponentValues(clippingPlane: ClippingPlaneEntity, eidForClippingPlane: number) {

    // Record reference to Clipping Plane as 3dObject
    object3dByEntityId[eidForClippingPlane] = clippingPlane.plane;

    ClippingPlaneComponent.activatePropLevel[eidForClippingPlane] = clippingPlane.properties.activatePropLevel ? 1 : 0;
    if (clippingPlane.properties.activatePropLevel) {

        // Prop level clipping is active, set its values
        ClippingPlaneComponent.position.x[eidForClippingPlane] = clippingPlane.properties.position[0];
        ClippingPlaneComponent.position.y[eidForClippingPlane] = clippingPlane.properties.position[1];
        ClippingPlaneComponent.rotationZ[eidForClippingPlane] = clippingPlane.properties.rotation;
        ClippingPlaneComponent.scale.x[eidForClippingPlane] = clippingPlane.properties.scale[0];
        ClippingPlaneComponent.scale.y[eidForClippingPlane] = clippingPlane.properties.scale[1];
    } else {

        // Prop level clipping is not active, remove clipping plane
        ScaleAdjustmentComponent.x[eidForClippingPlane] = ScaleAdjustmentComponent.y[eidForClippingPlane] = ScaleAdjustmentComponent.z[eidForClippingPlane] = 0;
    }
}


/**
 * Update component values for Clipping Plane entity.
 * If entity is not yet registered then register entity in ECS system.
 * @param clippingPlane 
 */
export function upsertClippingPlane(world: IWorld, clippingPlane: ClippingPlaneEntity, propType: PropType) {

    let eidForClippingPlane = getEidForClippingPlane(world, clippingPlane.properties.id, propType);

    if (0 > eidForClippingPlane) {

        // Get an ECS entity id for the Clipping Plane object.
        do eidForClippingPlane = addEntity(world)
        while (!eidForClippingPlane || 0 > eidForClippingPlane);

        // Flag as an adjustable entity for queries.
        addComponent(world, ClippingPlaneComponent, eidForClippingPlane);

        // Base adjustable component values.
        ClippingPlaneComponent.id[eidForClippingPlane] = clippingPlane.properties.id;
        ClippingPlaneComponent.type[eidForClippingPlane] = propType;

        addComponent(world, PositionAdjustmentComponent, eidForClippingPlane);
        addComponent(world, FromPositionComponent, eidForClippingPlane);
        addComponent(world, DestinationPositionComponent, eidForClippingPlane);

        addComponent(world, RotationAdjustmentComponent, eidForClippingPlane);
        addComponent(world, FromRotationComponent, eidForClippingPlane);
        addComponent(world, DestinationRotationComponent, eidForClippingPlane);

        addComponent(world, ScaleAdjustmentComponent, eidForClippingPlane);
        addComponent(world, FromScaleComponent, eidForClippingPlane);
        addComponent(world, DestinationScaleComponent, eidForClippingPlane);
    }

    updateClippingPlaneComponentValues(clippingPlane, eidForClippingPlane);

    // Invalidate position id to force analysis of Clipping Planes.
    AdjustmentInitState.currentShowroomPositionId = -1;
    AdjustableTransitionInitState.destinationShowroomPositionId = -1;
}