import { environment } from "src/environments/environment";

export enum TsLogLevel {
    trace = 1, 
    debug = 2, 
    info = 3, 
    warn = 4, 
    error = 5, 
    fatal = 6
}

export interface ILogger {
    trace(message?: any | (() => any), ...additional: any[]): void;
    debug(message?: any | (() => any), ...additional: any[]): void;
    info(message?: any | (() => any), ...additional: any[]): void;
    log(message?: any | (() => any), ...additional: any[]): void;
    warn(message?: any | (() => any), ...additional: any[]): void;
    error(message?: any | (() => any), ...additional: any[]): void;
}


export function getLogger(): ILogger {
    return environment && environment.logger ? environment.logger : console;
}