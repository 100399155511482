<div #clientConfigureDesignImageSidebarComponent tabindex="0"
    class="fixed h-screen  flex flex-col overflow-hidden top-0 w-full z-50 md:max-w-[500px] bg-[#0e0f10] shadow-xl 
            {{ isOpen() ? 'right-0' : '-right-full' }} transition-all duration-300 px-4 bg-opacity-80 border-l-[1px] border-accent" (blur)="handleBlur()">
    <div class="flex flex-row justify-between items-center pt-3">
        <div class="flex flex-row w-full">
            <!-- close icon -->
            <div class=" text-4xl w-11 flex justify-start items-center cursor-pointer transition"
                (click)="handleClose()">
                <ng-icon class="text-3xl transition {{ closeClicked() ? 'rotate-[2turn]' : 'rotate-0' }}"
                    name="bootstrapXLg" />
            </div>
            <div class="tooltip tooltip-bottom" [attr.data-tip]="'Design Id: ' + eventDesign().id + ', Prop Id: ' + videoProp().id">
                <div
                    class="text-accent text-xl font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap w-full">
                    {{videoProp().name}}
                </div>
            </div>
        </div>
    </div>

    <div class="flex-1 flex overflow-hidden">
        <!-- https://stackoverflow.com/questions/69400560/how-to-change-scrollbar-when-using-tailwind-next-js-react -->
        <!-- https://stackoverflow.com/questions/70539607/how-to-make-scrollable-sidebar-fixed-in-tailwindcss -->
        <div
            class="flex-1 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:[width:15px] [&::-webkit-scrollbar-thumb]:bg-accent">
            
            <div class="flex flex-col space-y-3 pb-20 mr-4 pt-2">
                
                @if (1 > videoPropOptions().orchestrationParentId) {

                    <app-checkbox class="w-1/2" Label="Orchestration parent" [Checked]="videoPropOptions().isOrchestrationParent"
                        (Toggled)="onToggleIsOrchestrationParent()" />

                }

                @if (!videoPropOptions().isOrchestrationParent) {

                    <div class="w-full flex flex-row items-center">
                        <div class="w-1/2">Orchestration parents</div>
                        <select (change)="onSelectOrchestrationParent($event)"
                            class="block w-1/2 text-black rounded-md focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        
                            <option value="">
                                Optional parent ...
                            </option>
                            @for (orchestrationParent of orchestrationParents(); track $index) {

                                <option [selected]="orchestrationParent.videoPropId === videoPropOptions().orchestrationParentId">
                                    {{orchestrationParent.description}}
                                </option>
                            }

                        </select>
                    </div>

                }

                <div class="w-full flex flex-row justify-between items-center">
                    <div class="w-1/2">Background color</div>
                    <input [style.background]="videoPropOptions().backgroundColor" [(colorPicker)]="videoPropOptions().backgroundColor" 
                        (colorPickerClose)="onSelectBackgroundColor($event)" (cpInputChange)="onSelectBackgroundColor($event.color)" 
                        (cpSliderDragStart)="onSelectBackgroundColor($event.color)" (cpSliderDragEnd)="onSelectBackgroundColor($event.color)" 
                        cpOutputFormat="hex" cpAlphaChannel="always" cpFallbackColor="#000000FF" />
                </div>

                @if (0 < designVideoAssignments().length || 0 < videoPropOptions().orchestrationParentId) { 

                    <div class="w-full flex flex-row items-center">
                        <div class="w-1/2">Controls</div>
                        <select (change)="handleSelectControlsAlignment($event)"
                            class="block w-1/2 text-black rounded-md focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        
                            @for (alignment of controlAlignment; track $index) {

                                <option [selected]="alignment === acticonPosition[videoPropOptions().controlsPosition]">
                                    {{camelCaseToSpaced(alignment)}}
                                </option>
                            }

                        </select>
                    </div>
                    
                    <div class="w-full flex flex-row justify-between items-center">
                        <div class="w-1/2">Interaction distance (meters)</div>
                        <input
                            class="w-1/2 text-right block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary"
                            type="number" value="{{ interactionDistanceInitializer() | number : '1.1-2' | noComma }}" step="0.01"
                            (blur)="handleBlurInteractionDistance()" (input)="handleInteractionDistanceInput($event)">
                    </div>

                    <div class="w-full flex flex-row items-center">
                        <div class="w-1/2">Margin (meters)</div>
                        <input
                            class="w-1/2 text-right block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary"
                            type="number" [value]="videoPropOptions().controlsMargin"
                            (keyup)="handleControlsMarginInput($event)">
                    </div>

                    <div class="w-full flex flex-row items-center">
                        <div class="w-1/2">Depth (meters)</div>
                        <input
                            class="w-1/2 text-right block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary"
                            type="number" [value]="videoPropOptions().controlsZ" (keyup)="handleControlsDepthInput($event)">
                    </div>

                    <div class="w-full flex flex-row justify-between items-center">
                        <div class="w-1/2">Audio distance (meters)</div>
                        <input
                            class="w-1/2 text-right block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary"
                            type="number" value="{{ audioDistanceInitializer() | number : '1.1-2' | noComma }}" step="0.01"
                            (blur)="handleBlurAudioDistance()" (input)="handleAudioDistanceInput($event)">
                    </div>

                    <div class="w-full flex flex-row items-center h-[41px]">

                        <app-checkbox class="w-1/2" Label="Auto play" [Checked]="videoPropOptions().autoPlay"
                            (Toggled)="onToggleAutoPlay()" />
                        
                        @if (1 > videoPropOptions().orchestrationParentId) {
                        
                            <app-checkbox class="w-1/2" Label="Loop" [Checked]="videoPropOptions().loop"
                                (Toggled)="onToggleLoop()" />
                                
                        }
                    
                        </div>

                    @if (videoPropOptions().autoPlay) {

                        <div class="w-full flex flex-row items-center">
                            <div class="w-1/2">Auto play distance (meters)</div>
                            <input
                                class="w-1/2 text-right block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary"
                                type="number" value="{{ autoPlayDistanceInitializer() | number : '1.2-2' | noComma }}" step="0.01"
                                (blur)="handleBlurAutoPlayDistance()" (input)="handleAutoPlayDistanceInput($event)">
                        </div>

                    }

                }

                @if (enableSave()) {

                    <div class="flex flex-row justify-end">
                        <div class="flex flex-row h-full items-end space-x-2">

                            <button (click)="onUndo()"
                                class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                                <span class="flex justify-center ">
                                    <ng-icon class="cursor-pointer" size="22" name="bootstrapArrowCounterclockwise" />
                                </span>
                            </button>

                            <button (click)="onSave()"
                                class="text-sm uppercase font-bold bg-green-700 rounded-lg h-[41px] w-[48px]">
                                <span class="flex justify-center ">
                                    <ng-icon class="cursor-pointer" size="22" name="bootstrapCheckLg" />
                                </span>
                            </button>

                        </div>
                    </div>
                }

                <!-- Upload/Import DesignVideo buttons -->                

                @if (1 > videoPropOptions().orchestrationParentId) {

                    <div class="w-full flex flex-col items-center">

                        <div class="flex flex-row justify-center space-x-2">
                        
                            <input type="file" id="file-input-add-video" class="hidden" accept="video/*"
                                onclick="this.value = null;" (change)="addDesignVideo($event)">
                        
                            <div class="tooltip tooltip-left" data-tip="Upload design video">
                                <!-- Button invokes file input above -->
                                <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                                    <label class="flex justify-center" for="file-input-add-video">
                                        <ng-icon class="cursor-pointer" size="25" name="bootstrapPlusLg" />
                                    </label>
                                </button>
                            </div>

                            <div class="tooltip tooltip-right" data-tip="Import from design">
                                <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]"
                                    (click)="handleImportDesignVideo()">
                                    <label class="flex justify-center">
                                        <ng-icon class="cursor-pointer" size="25" name="bootstrapArrowLeft" />
                                    </label>
                                </button>
                            </div>

                        </div>

                        <div class="pt-2 text-gray-300">Aspect: {{videoProp().aspect}}</div>

                    </div>

                    @if (0 < uploadingFileName().length) { 
             
                        <div class="w-full py-2 flex flex-col justify-center text-center items center">
                            <div class="pb-1">Uploading {{uploadingFileName()}}</div>
                            <app-progress-bar [Progress]="uploadingProgress()" [EaseToProgress]="20" />
                        </div>

                    } 
                    
                    @if (1 > designVideoAssignments().length) {

                        @if (eventDesign().id !== defaultEventDesign().id && 0 < defaultVideoAssignments().length) { 
                       
                            @for (designVideoAssignment of defaultVideoAssignments(); track $index; let first=$first) { 
                            
                                <!-- Default DesignVideos -->
                                 
                                <div
                                    class="{{designVideoAssignment.designVideo.id === selectedDesignVideo().id ? 'border-[1px] border-white p-2 rounded-md' : ''}}">
                                
                                    <div
                                        class="group/outer relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                    
                                        <div class="w-full max-h-80 overflow-hidden">
                        
                                            <video #video aria-hidden="true" class="w-full max-h-80 object-scale-down" loop controls
                                                crossorigin="anonymous">
                                                <source src="">
                                            </video>

                                        </div>
                    
                                        <!-- <div class="absolute inset-0 pt-20 flex -translate-y-full overflow-hidden flex-col px-4 transition-all duration-500 group-hover/outer:-translate-y-16" -->
                                        <div
                                            class="absolute inset-0 pt-20 bg-slate-800 bg-opacity-30 flex overflow-hidden flex-col px-4 transition-all duration-500 -translate-y-16">
                    
                                            <div class="flex flex-row items-center text-accent text-xl font-semibold overflow-hidden">
                                                Default video
                                            </div>
                                        
                                        </div>
                
                                    </div>
            
                                </div>
            
                            }
            
                        } @else {
            
                            <p class="mx-2 md:mx-0 mt-1 md:mt-2 text-lg text-gray-400">
                                No video assignments ...
                            </p>
                
                        }
            
                    }
            
                    @for (designVideoAssignment of designVideoAssignments(); track $index; let first = $first) {
            
                        <!-- Assigned DesignVideos -->
                        <div class="flex items-center text-accent text-xl font-semibold overflow-hidden">
                            {{designVideoAssignment.designVideo.uploadFileName}}
                        </div>
            
                        <div
                            class="{{designVideoAssignment.designVideo.id === selectedDesignVideo().id ? 'border-[1px] border-white p-2 rounded-md' : ''}}">

                            @if (designVideoAssignment.designVideo.id === selectedDesignVideo().id) {

                                <div class="w-full flex flex-row justify-between items-center h-[41px]">
                                    <app-checkbox Label="Enable interaction" [Checked]="designVideoAssignment.videoAssignment.enableInteraction"
                                        (Toggled)="onToggleEnableInteraction()" />
                                    <app-checkbox Label="Hide image in sidebar"
                                        [Checked]="designVideoAssignment.videoAssignment.suppressSidebarVideo"
                                        (Toggled)="onToggleSuppressSidebarVideo()" />
                                </div>

                            }
                
                            <div
                                class="group/outer relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                    
                                <div class="w-full max-h-80 overflow-hidden">
                        
                                    <video #video aria-hidden="true" class="w-full max-h-80 object-scale-down" loop controls
                                        crossorigin="anonymous">
                                        <source src="">
                                    </video>
                    
                                </div>
                    
                                <!-- <div class="absolute inset-0 pt-20 flex -translate-y-full overflow-hidden flex-col px-4 transition-all duration-500 group-hover/outer:-translate-y-16" -->
                                <div class="absolute inset-0 pt-20 flex overflow-hidden flex-col px-4 transition-all duration-500 -translate-y-16">
                        
                                    <div class="flex flex-row">
                            
                                        <div class="flex flex-row">

                                            @if (designVideoAssignment.designVideo.id !== selectedDesignVideo().id) {
                                            
                                            <div class="tooltip tooltip-right" data-tip="Edit">
                                                <button class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]"
                                                    (click)="selectDesignVideo(designVideoAssignment)">
                                                    <span class="flex justify-center ">
                                                        <ng-icon size="22" name="bootstrapPen" />
                                                    </span>
                                                </button>
                                            </div>

                                            }

                                            @if (!first) {
                            
                                            <div class="{{designVideoAssignment.designVideo.id === selectedDesignVideo().id ? 'ml-0' : 'ml-2' }}">                                
                                                <div class="tooltip tooltip-right" data-tip="Move up">
                                                    <button
                                                        class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px] cursor-pointer"
                                                        (click)="handleSwapVideoAssignments($event, $index, $index - 1)">
                                                        <span class="flex justify-center ">
                                                            <ng-icon size="22" name="bootstrapArrowUp" />
                                                        </span>
                                                    </button>
                                                </div>                            
                                            </div>
                        
                                            }

                                        </div>
                            
                                        <div class="flex justify-end w-full">                                                                
                                            <div class="tooltip tooltip-left" data-tip="Remove assignment">
                                                <button (click)="handleRemoveVideoAssignment($event, designVideoAssignment)"
                                                    class="text-sm uppercase font-bold bg-danger hover:bg-dangerhov rounded-lg h-[41px] w-[48px] cursor-pointer">
                                                    <span class="flex justify-center ">
                                                        <ng-icon size="22" name="bootstrapTrash" />
                                                    </span>
                                                </button>                           
                                            </div>                        
                                        </div>
                                    </div>
                    
                                </div>
                
                            </div>                              
                            
                            @if (designVideoAssignment.designVideo.id === selectedDesignVideo().id) {    
                        
                                <div class="pt-2">

                                @if (enableVideoAssignmentSave()) {
                            
                                    <div class="flex flex-row justify-end">
                                
                                        <div class="flex flex-row space-x-2">
                                            
                                            <button (click)="handleVideoAssignmentUndo()"
                                                class="text-sm uppercase font-bold btn-accent rounded-lg h-[41px] w-[48px]">
                                                <span class="flex justify-center ">
                                                    <ng-icon class="cursor-pointer" size="22" name="bootstrapArrowCounterclockwise" />
                                                </span>
                                            </button>
                                            <button (click)="handleVideoAssignmentSave()"
                                                class="text-sm uppercase font-bold bg-green-700 rounded-lg h-[41px] w-[48px]">
                                                <span class="flex justify-center ">
                                                    <ng-icon class="cursor-pointer" size="22" name="bootstrapCheckLg" />
                                                </span>
                                            </button>
                                
                                        </div>
                            
                                    </div>
                                }

                                <div>
                                    <div class="py-2">
                                        <span class="w-[85px] font-bold">Name</span>
                                        <p class="italic text-gray-400">Displayed in sidebar and on purchase orders</p>
                                    </div>
                                    <textarea rows="3" maxlength="256" [value]="designVideoAssignment.videoAssignment.name" (blur)="handleNameInput($event)"
                                        (keyup)="handleNameInput($event)"
                                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-primary">
                                    </textarea>
                                </div>

                                <div class="pb-2">
                                    <span class="w-[85px] font-bold">Description</span>
                                    <p class="italic">Displayed upon Guest interaction</p>
                                </div>

                                <app-text-editor [Content]="designVideoAssignment.videoAssignment.description"
                                    (OnUpdate)="handleVideoAssignmentDescriptionInput($event)" />

                                </div>
                            }
            
                        </div>
                
                    }
            
                }
    
            </div>

        </div>

    </div>

</div>