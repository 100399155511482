import { Types, defineComponent } from 'bitecs';
import { Vector2Schema } from './shared.component';

/**
 * Clipping Plane components have position, scale and rotation values that change
 * when transitioning from position to position. Clipping Assignments are applied by 
 * lerping from the start position values to the destination position values
 * in connection with the movement of the camera from position 1 to position 2.
 * 
 * Entities flagged as Clipping Plane components are processed by the following systems:
 * 1. Adjustment Init System
 * 2. Transition Init System
 * 3. Transition System
 * 4. Update Adjustable System
 */
export const ClippingPlaneComponent = defineComponent({
    id: Types.i32,
    type: Types.i8,
    activatePropLevel: Types.i8,
    position: Vector2Schema,
    rotationZ: Types.f32,
    scale: Vector2Schema,
});