import {defineComponent, Types} from 'bitecs';

/**
 * Showroom position (i.e. Matterport sweep)
 */
export const ShowroomPositionComponent = defineComponent({
    x: Types.f32,
    y: Types.f32,
    z: Types.f32
})
