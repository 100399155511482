import { Injectable, signal } from '@angular/core';
import { DesignImage, IImportableDesignImages, IImportableImageSearchResult } from 'projects/my-common/src/model';
import { Subject, firstValueFrom } from 'rxjs';
import { SearchService } from '../search/search.service';
import { NGXLogger } from 'ngx-logger';
import { VenueService } from './venue.service';


@Injectable({
  providedIn: 'root'
})
export class DesignImageService {

  readonly importableDesignImages = signal(<IImportableDesignImages>{
    data: <IImportableImageSearchResult[]>[]
  });

  readonly currentEventDesign = this.venueService.currentEventDesign
  private _includeThisDesignsImages: boolean = false;

  private _importImageSource = new Subject<DesignImage>();
  importImage$ = this._importImageSource.asObservable();

  private _pageSize = 10;


  constructor(private readonly searchService: SearchService,
    private readonly venueService: VenueService,
    private readonly logger: NGXLogger) { }


  broadcastImportDesignImage(designImage: DesignImage): void {

      this._importImageSource.next(designImage);    
  }


  async loadPage(pageIndex: number): Promise<void> {

    const paginatedResults = await firstValueFrom(
      this.searchService.importableDesignImages(this.currentEventDesign().id, this._includeThisDesignsImages, this._pageSize, pageIndex)
    );
    this.logger.trace('paginated results', paginatedResults);

    this.importableDesignImages.set(paginatedResults);
  }


  initialize(includeThisDesignsImages: boolean = false): void {

    this._includeThisDesignsImages = includeThisDesignsImages;
    this.loadPage(0);
  }
}
