import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ToastComponent } from "./shared/components/tools/toast/toast.component";
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { Configuration } from 'src/environments/interfaces/IEnvironment';
import { NGXLogger } from 'ngx-logger';
import { AbilityModule } from '@casl/angular';
import { AlertModalComponent } from "./components/shared/alert-modal/alert-modal.component";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GuestShowroomComponent } from "./pages/guest/guest-showroom/guest-showroom.component";

//declare const gtag: Function;   // Google analytics

declare global {
  interface Window {
    dataLayer: any[];
  }
}

function gtag(key: string, ...values: any): void {

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

// const apiUrl = '/api';
// const headers = new HttpHeaders().set('ngsw-bypass', '1');

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterOutlet,
    ToastComponent,
    AbilityModule,
    AlertModalComponent,
    GuestShowroomComponent
  ]
})
export class AppComponent {

  readonly isInIFrame: boolean;


  constructor(//private http: HttpClient,
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly swUpdate: SwUpdate) {

    environment.logger = logger;
    if (this.isInIFrame = window.self !== window.top) {

      console.error(`In iFrame: ${window.location.href}`);
    }

    this.monitorRouterEvents();

    if (environment.configuration === Configuration.PRODUCTION) {
      
      gtag('js', new Date());
      gtag('config', 'G-HVZMVYPD4C');
    }

    // Service worker registration is setup in config.app.ts
    // this.registerServiceWorker();

    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    // const appIsStable$ = appRef.isStable.pipe(
    //   tap(isStable => console.log(`--> isStable: ${isStable}`)),
    //   first(isStable => isStable === true)
    //   );
    //const everySixHours$ = interval(6 * 60 * 60 * 1000);
    // const everySixHours$ = interval(60 * 1000);
    //const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    // const everySixHoursOnceAppIsStable$ = concat(everySixHours$);

    // everySixHoursOnceAppIsStable$.subscribe(async () => {
    //   try {
    //     const updateFound = await _updates.checkForUpdate();
    //     console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
    //   } catch (err) {
    //     console.error('Failed to check for updates:', err);
    //   }
    // });

    if (environment.configuration !== Configuration.DEVELOP) {

      this.monitorApplicationUpdates();
    }

    //headers.append('ContentType', 'text/plain');

    // http.get<string>(`${apiUrl}/MyHttpTrigger`, { headers: headers, observe: 'response', responseType: 'text' as 'json' })
    //   .subscribe(response => console.log(response.body));
  }


  private async registerServiceWorker() {
    // Added service worker here to make it load in Edge.
    // The provider registration was enough for Chrome but not Edge
    if ("serviceWorker" in navigator) {// && environment.configuration !== Configuration.DEVELOP) {
      this.logger.trace('--> Registering service worker ...')
      try {
        const registration = await navigator.serviceWorker.register("/ngsw-worker.js", {
          scope: "/",
        });
        if (registration.installing) {
          console.log("Service worker installing");
        } else if (registration.waiting) {
          console.log("Service worker installed");
        } else if (registration.active) {
          console.log("Service worker active");
          //this.monitorApplicationUpdates();
        }
      } catch (error) {
        console.error(`Registration failed with ${error}`);
      }
    }

  }


  private async monitorApplicationUpdates() {

    // SecurityService also checks before initial attempt at Silent login
    this.swUpdate.versionUpdates.subscribe(async event => {

      switch (event.type) {

        case 'VERSION_DETECTED':
          console.warn(`Downloading new app version: ${event.version.hash}`);
          break;

        case 'VERSION_READY':
          console.warn(`Current app version: ${event.currentVersion.hash}`);
          console.warn(`New app version ready for use: ${event.latestVersion.hash}`);
          if (confirm('A new version of Showroom is available. Reload?')) {

            console.warn('Installing update');
            this.swUpdate.activateUpdate().then(() => window.location.reload());
          }
          break;

        case 'VERSION_INSTALLATION_FAILED':
          console.warn(`${event.version.hash}: ${event.error}`);
          break;
      }
    });
  }


  monitorRouterEvents() {

    if (environment.configuration === Configuration.PRODUCTION) {

      // Report page navigations to Google analytics
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'G-HVZMVYPD4C', { 'page_path': event.urlAfterRedirects });
        }
      })
    }
  }


}
