import { SceneComponent, ComponentInteractionType } from '../SceneComponent';
import { Subject } from "rxjs/internal/Subject";

type Inputs = { requiredInput: boolean}

export class TickComponent extends SceneComponent {  

  override inputs: Inputs = { requiredInput: true };

  override events = {
    [ComponentInteractionType.CLICK]: false,
    [ComponentInteractionType.HOVER]: false,
    [ComponentInteractionType.DRAG]: false
  }


  override onInit() { }


  override onInputsUpdated(oldInputs: Inputs) { }

  private readonly _update = new Subject<number>();
  readonly update$ = this._update.asObservable();
  override onTick(delta: number) {

    this._update.next(delta);
  }

}


export const TICK_COMPONENT_TYPE = 'mp.tick';

export const makeTick = function() {
  return new TickComponent();
}
