<div [id]="id" class="flex flex-row justify-between items-center {{ ShowroomPosition().id === CurrentPosition() ? 'rounded-md mx-4 bg-slate-700 py-2 px-2 font-semibold' : 'mx-6' }} cursor-pointer 
    hover:rounded-md hover:mx-4 hover:bg-slate-700 hover:py-2 hover:px-2 hover:font-semibold">
    <!-- <div class="mt-2 md:mt-0 cursor-pointer group relative">
        <div class="cursor-pointer group relative">
            <button #dropdownTarget
                class="text-sm uppercase font-bold btn-accent rounded-lg w-[48px] h-[41px]">
                <label class="flex justify-center">
                    <ng-icon class="cursor-pointer" size="22" name="bootstrapCaretLeft" />
                </label>
            </button>
            <app-button-dropdown [TriggerElement]="dropdownTarget" [Items]="propActions"
                (SelectItem)="onPropAction($event)" />
        </div>
    </div> -->
    <div class="flex flex-row w-full justify-between overflow-hidden"
        (click)="goToPosition()">
        <div>Position {{ShowroomPosition().labelId}}</div>
        <div>{{ShowroomPosition().id}}</div>
    </div>
</div>